<template>
    <div class="template-client">
        <PageLoader v-if="!clients"/>
        <div v-else-if="client">
            <PageBack v-if="!hideBack"/>
            <PageHeader v-if="title || subtitle" :title="title || client.name" :subtitle="subtitle" :text="text">
                <template v-slot:actions v-if="$slots.actions">
                    <slot name="actions"/>
                </template>
            </PageHeader>
            <slot name="default"/>
        </div>
        <PageError v-else title="404" :subtitle="$t('client.not-found')" :button="$t('client.return')" :to="{name: 'clients'}"/>
    </div>
</template>

<script>
import PageLoader from '@/components/ui/page/PageLoader';
import PageError from '@/components/ui/page/PageError';
import PageHeader from '@/components/ui/page/PageHeader';
import PageBack from '@/components/ui/page/PageBack';
import { mapState, mapActions } from 'vuex';

export default {
    name: 'TemplateClient',
    components: {PageBack, PageHeader, PageError, PageLoader},
    props: {
        hideBack: Boolean,
        title: String,
        subtitle: String,
        text: String,
    },
    computed: {
        ...mapState(['client', 'clients']),
    },
    methods: {
        ...mapActions(['getClient'])
    },
    watch: {
        clients() {
            this.getClient();
        }
    },
    beforeMount() {
        this.getClient();
    }
}
</script>

<style lang="scss">
.template-client {
    .checkerboard {
        gap: 20px;

        @media screen and (max-width: 500px) {
            gap: 10px;
        }
    }
}
</style>