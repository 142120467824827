<template>
    <TemplateDetails :get-function="getWallet" :data="wallet" :loading="walletLoading">

        <PageBack/>

        <Form ref="form"
              :title="$t('wallet.add')"
              :model="model"
              :fields="fields"
              :action="addToWallet"
              :successMsg="$t('wallet.add_success')"
              @success="onSuccess"
              :init-on-success="true" />

    </TemplateDetails>
</template>

<script>
import TemplateDetails from '@/components/templates/TemplateDetails';
import { mapActions, mapState } from 'vuex';
import PageBack from '@/components/ui/page/PageBack';
import Form from '@/components/ui/form/Form';

export default {
    name: 'WalletAdd',
    components: {Form, PageBack, TemplateDetails},
    data() {
        return {
            model: {
                amount: null,
                description: null,
                type: 'sav',
                country: 'FR'
            },
            fields: [
                {name: 'amount', type: 'number', required: true},
                {name: 'description', type: 'textarea'},
                {name: 'type', type: 'select', required: true,
                    options: [
                        {name: this.$t('wallet.types.cancel'), value: 'cancel'},
                        {name: this.$t('wallet.types.return'), value: 'return'},
                        {name: this.$t('wallet.types.sav'), value: 'sav'},
                    ]
                },
                {name: 'country', type: 'select', required: true,
                    options: [
                        {name: this.$t('countries_code.FR'), value: 'FR'},
                        {name: this.$t('countries_code.BE'), value: 'BE'},
                        {name: this.$t('countries_code.DE'), value: 'DE'},
                        {name: this.$t('countries_code.IT'), value: 'IT'},
                        {name: this.$t('countries_code.NL'), value: 'NL'},
                        {name: this.$t('countries_code.ES'), value: 'ES'},
                        {name: this.$t('countries_code.CH'), value: 'CH'},
                    ]
                }
            ]
        }
    },
    computed: {
        ...mapState(['wallet', 'walletLoading'])
    },
    methods: {
        ...mapActions(['getWallet', 'addToWallet']),
        onSuccess() {
            this.model = {};
            this.$nextTick(() => {
                this.model = {
                    amount: null,
                    description: null,
                    type: 'sav',
                    country: 'FR'
                }
            });
        }
    }
}
</script>