<template>
    <div class="menu">

        <ul class="menu__list">
            <li v-for="link in allowedMenu" :key="link.name">
                <LayoutMenuLink v-if="link.links?.length === 1" :to="link.links[0].name" :title="link.meta.title" :icon="link.meta.icon" @click.native="$emit('close')"/>
                <LayoutMenuCollapse v-else-if="link.links" :title="link.meta.title" :icon="link.meta.icon" :to="link.name">
                    <ul>
                        <li v-for="(sublink, i) in link.links" :key="i">
                            <LayoutMenuLink :to="sublink.name" :title="sublink.meta.title" @click.native="$emit('close')" :data-testid="`${sublink.name}-submenu`"/>
                        </li>
                    </ul>
                </LayoutMenuCollapse>
                <LayoutMenuLink v-else :to="link.name" :title="link.meta.title" :icon="link.meta.icon" @click.native="$emit('close')"/>
            </li>
            <li v-if="storeLink">
                <LayoutMenuLink :href="storeLink" title="resaleForm" icon="form" @click.native="$emit('close')"/>
            </li>
            <li v-if="false">
                <LayoutMenuLink href="https://faume.zendesk.com/hc/fr" title="faq" icon="faq" @click.native="$emit('close')"/>
            </li>
            <li class="menu__academy">
                <LayoutMenuLink :href="academyUrl" title="academy" icon="academy"/>
            </li>
        </ul>

    </div>
</template>

<script>
import LayoutMenuLink from '@/components/ui/layouts/LayoutMenuLink';
import LayoutMenuCollapse from '@/components/ui/layouts/LayoutMenuCollapse';
import {mapState, mapGetters} from 'vuex';

export default {
    name: 'LayoutMenu',
    components: {LayoutMenuCollapse, LayoutMenuLink},
    computed: {
        ...mapState(['user']),
        ...mapGetters(['allowedMenu', 'storeLink']),
        academyUrl() {
            return this.$i18n.locale === 'en'
                    ? 'https://www.notion.so/Faume-Academy-EN-7e2967f257104a06bd2a36e18ac50f23'
                    : 'https://www.notion.so/faume/Faume-Academy-c4947dfa7cfc4776810bcecef65d50b9';
        }
    }
}
</script>

<style lang="scss">
.menu {
    padding: 30px 0;
    flex-grow: 1;
    overflow-y: auto;

    @media screen and (min-width: 1025px) {
        border-top: var(--border-1);
    }

    @media screen and (max-width: 1024px) {
        .layout-menu-link {
            min-height: 40px;
        }
    }

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background: var(--color-bg-light);
    }

    &::-webkit-scrollbar-thumb {
        background: var(--color-bg-grey);
    }

    &::-webkit-scrollbar-thumb:hover {
        background: var(--color-bg-grey);
    }
    & > p {
        margin-bottom: 10px;
        margin-left: 7px;
    }

    &__list {
        display: flex;
        flex-direction: column;
        min-height: 100%;

        & > li {
            & > .router-link-active {
                background-color: var(--color-bg-grey);
            }
        }
    }

    &__academy {
        margin-top: auto;
    }
}
</style>