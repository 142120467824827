import CatalogueAPI from '@/api/api-catalogue';
import API from '@/api/api-admin';
import listRoutes from '@/api/list-routes';
import Vue from 'vue';
import i18n from '@/i18n';

const state = {

    catalogueCollection:  {
        "identification": {},
        "markets": [],
        "parameters": {
            "source": '',
            "conditions": [] 
        },
        "tags": [],
    },
    
    catalogueCollectionMappedFieldsFromSchema:null,
    catalogueCollectionMappedFieldsFromSchemaLoading:null,

    catalogueCollectionLoading: null,
    catalogueCollections: [],
    catalogueCollectionsLoading: null,

    collectionsClients: null,
    marketsClient: null

}
const mutations = {
    SET_CATALOGUE_COLLECTIONS(state,data){
        state.catalogueCollections = data
    },
    SET_CATALOGUE_COLLECTIONS_LOADING(state,data){
        state.catalogueCollectionsLoading = data
    },
    SET_CATALOGUE_COLLECTION_MAPPED_FIELDS_FROM_SCHEMA(state,data){
        state.catalogueCollectionMappedFieldsFromSchema = data
    },
    SET_CATALOGUE_COLLECTION_MAPPED_FIELDS_FROM_SCHEMA_LOADING(state,data){
        state.catalogueCollectionMappedFieldsFromSchemaLoading = data
    },

    SET_CATALOGUE_COLLECTION_UPDATE_STATE_IDENTIFICATION(state, data) {
        const { type, payload } = data;    
    
        if (typeof type !== 'string') {
            return; 
        }
        else {
            // Handle other types
            Vue.set(state.catalogueCollection, type, { ...state.catalogueCollection[type], ...payload });
        }
    },
    SET_CATALOGUE_COLLECTION_UPDATE_STATE_MARKETS(state, data) {
        const { type, payload } = data;    
        if (type === 'markets') {
            if (payload.addItem && !state.catalogueCollection.markets.includes(payload.addItem)) {
                state.catalogueCollection.markets.push(payload.addItem);
            } else if (payload.removeItem) {
                const indexToRemove = state.catalogueCollection.markets.indexOf(payload.removeItem);
                if (indexToRemove !== -1) {
                    state.catalogueCollection.markets.splice(indexToRemove, 1);
                }
            }
        } 
    },
    SET_CATALOGUE_COLLECTION_UPDATE_STATE_SOURCE(state, data) {
        const { type, payload } = data;   
        if (type === 'source') {
            Vue.set(state.catalogueCollection.parameters, type, payload.data_source);
        } 
    },
    SET_CATALOGUE_COLLECTION_UPDATE_STATE_CONDITIONS(state, data) {
        const { type, payload } = data;
        if (type === 'conditions') {

            
            if (!state.catalogueCollection.parameters.conditions) {
                Vue.set(state.catalogueCollection.parameters, 'conditions', []);
            }
    
            const {index,...rest} = payload;
            
            if(index >= 0 && state.catalogueCollection.parameters.conditions.length > index){
                Vue.set(state.catalogueCollection.parameters.conditions, index, rest);
            }

        }
    },
    // 
    REMOVE_CATALOGUE_COLLECTION_CONDITION(state, index) {
        state.catalogueCollection.parameters.conditions.splice(index, 1);
    },
    EMPTY__CATALOGUE_COLLECTION_CONDITION(state, index) {
        // state.catalogueCollection.parameters.conditions[index]= {}
        Vue.set(state.catalogueCollection.parameters.conditions, index, {})
    },
    SET_CATALOGUE_COLLECTION_LOADING(state,data){
        state.catalogueCollectionLoading = data
    },
    SET_CATALOGUE_COLLECTION_FETCH(state, payload){
        state.catalogueCollection = payload
    },
    SET_CATALOGUE_COLLECTION_DEFAULT_ITEM(state){
        state.catalogueCollection.parameters.conditions = [...state.catalogueCollection.parameters.conditions,{}]
    },
    SET_CATALOGUE_COLLECTION_STATE(state,payload){
        if(payload === 0) {
            return state.catalogueCollection.state = false
        }
        return state.catalogueCollection.state = true
    },
    CREATE_CATALOGUE_COLLECTION(state,payload){
        state.catalogueCollection = payload
    },

    SET_COLLECTIONS_CLIENTS(state, data) {
        Vue.set(state, 'collectionsClients', data);
    },
    SET_MARKETS_CLIENT(state, data) {
        Vue.set(state, 'marketsClient', data);
    },

    SET_CATALOGUE_COLLECTION_CUSTOM_TAGS(state, data){
        // Vérifie si 'data' est une chaîne vide
        const tags = data === '' ? [] : [data];

        // Définit 'tags' dans 'catalogueCollection'
        Vue.set(state.catalogueCollection, 'tags', tags);
    },
    UPDATE_FIELD(state, updatedField) {
        const index = state.catalogueCollectionMappedFieldsFromSchema.findIndex(field => field.name === updatedField.name);
        if (index !== -1) {
          Vue.set(state.catalogueCollectionMappedFieldsFromSchema, index, updatedField);
        }
      },

}
const actions = {

    catalogueGetFieldListValue({commit}, payload){
        const {locale,brand_id,field} = payload
        commit('SET_CATALOGUE_FIELD_LIST_VALUE', null)

        if(field.length && locale.length){
        
        return CatalogueAPI.get(`${listRoutes.catalogueFieldListValue.replace(':lang', locale).replace(':id', brand_id).replace(':field', field)}`)
            .then(res => {
                commit('SET_CATALOGUE_FIELD_LIST_VALUE', {[field]:res.data})
                return res
            })
            .catch(err => {
                commit('SET_NOTIFICATION_MESSAGE', {text:i18n.t(err.response.data.message), color:'red', confirmation: true})
            })
        }
        
    },
    catalogueGetAllCollections({commit},id){
        commit('SET_CATALOGUE_COLLECTIONS_LOADING', true)
        CatalogueAPI.get(`${listRoutes.collectionsGetAllCollections.replace(':id', id)}`)
            .then(res => {
                commit('SET_CATALOGUE_COLLECTIONS', res.data)
            })
            .catch(err => {
                commit('SET_NOTIFICATION_MESSAGE', {text:i18n.t(err.response.data.message), color:'red', confirmation: true})
            })
            .finally(()=> {
                commit('SET_CATALOGUE_COLLECTIONS_LOADING', false)
            })
    },
    catalogueGetCollection({commit},payload){
        const {id, collectionID} = payload
        commit('SET_CATALOGUE_COLLECTION_LOADING', false)
        commit('SET_CATALOGUE_COLLECTION_FETCH', null)
        CatalogueAPI.get(`${listRoutes.collectionsGetCollection.replace(':id', id).replace(':collectionID',collectionID)}`)
            .then(res => {
                commit('SET_CATALOGUE_COLLECTION_FETCH', res.data)
            })
            .catch(err => {
                commit('SET_NOTIFICATION_MESSAGE', {text:i18n.t(err.message), color:'red', confirmation: true})
            })
            .finally(()=> {
                commit('SET_CATALOGUE_COLLECTION_LOADING', true)
            })
    },
    catalogueCollectionGetMappedFieldsFromSchema({commit},payload){
        const {lang,id} = payload

        commit('SET_CATALOGUE_COLLECTION_MAPPED_FIELDS_FROM_SCHEMA_LOADING', true)
        commit('SET_CATALOGUE_COLLECTION_MAPPED_FIELDS_FROM_SCHEMA', null)
        
        CatalogueAPI.get(`${listRoutes.catalogueGetSchema.replace(':lang',lang).replace(':id', id)}`)
            .then(res => {
                const mappedFields = res?.data?.schema?.filter(elem => elem.operators)
                if (mappedFields && mappedFields.length) {
                    commit('SET_CATALOGUE_COLLECTION_MAPPED_FIELDS_FROM_SCHEMA', mappedFields)
                }
            })
            .catch(err => {
                commit('SET_NOTIFICATION_MESSAGE', {text:i18n.t(err.message), color:'red', confirmation: true})
            })
            .finally(()=> {
                commit('SET_CATALOGUE_COLLECTION_MAPPED_FIELDS_FROM_SCHEMA_LOADING', false)
            })
        
    },
    catalogueInitCollection({commit},payload){

        commit('SET_CATALOGUE_COLLECTION_FETCH', {
            "identification": payload,
            "markets": [],
            "parameters": {
                "source": 'catalog_data',
                "conditions": [{}]
            },
            "tags": [],
        })
        commit('SET_CATALOGUE_COLLECTION_LOADING', true)
    },
    catalogueCollectionAddConditions({commit}){
        commit('SET_CATALOGUE_COLLECTION_DEFAULT_ITEM')
    },
    catalogueCollectionRemoveCondition({commit},index){
        commit('REMOVE_CATALOGUE_COLLECTION_CONDITION',index)
    },
    catalogueCollectionUpdateStateIdentification({commit},payload){
        commit('SET_CATALOGUE_COLLECTION_UPDATE_STATE_IDENTIFICATION',payload)
    },
    catalogueCollectionUpdateStateMarkets({commit},payload){
        commit('SET_CATALOGUE_COLLECTION_UPDATE_STATE_MARKETS',payload)
    },
    catalogueCollectionUpdateStateSource({commit},payload){
        commit('SET_CATALOGUE_COLLECTION_UPDATE_STATE_SOURCE',payload)
    },
    catalogueCollectionUpdateStateConditions({commit},payload){
        commit('SET_CATALOGUE_COLLECTION_UPDATE_STATE_CONDITIONS',payload)
    },

    catalogueCollectionCreate({commit,state},{ id, router }){

        const stateWithoutEmptyObjects = prepareStateForCollectionsAPI(state.catalogueCollection);
        if (!stateWithoutEmptyObjects) {
            return;
        }
        return CatalogueAPI.post(listRoutes.collectionsPostCollection.replace(':id',id),stateWithoutEmptyObjects)
            .then(res => {
                commit('CREATE_CATALOGUE_COLLECTION', res.data)
                if (router && res.data.id) {
                    router.push({
                        name: 'config-website-catalogue-collections-edit',
                        params: { id: id, collectionID: res.data.id }
                    });
                }
                return res.status
            })
            .catch(err => {
                commit('SET_NOTIFICATION_MESSAGE', {text:i18n.t(err.response.data), color:'red', confirmation: true})
            })
    },
    catalogueCollectionUpdate({commit,state},payload){
        const {id, collectionID} = payload
        
        const stateWithoutEmptyObjects = prepareStateForCollectionsAPI(state.catalogueCollection);
        if (!stateWithoutEmptyObjects) {
            return;
        }

        return CatalogueAPI.put(listRoutes.collectionsUpdateCollection.replace(':id',id).replace(':collectionID',collectionID),stateWithoutEmptyObjects)
            .then(res => {
                return res.status
            })
            .catch(err => {
                commit('SET_NOTIFICATION_MESSAGE', {text:i18n.t(err.response.data), color:'red', confirmation: true})
            })
    },
    catalogueDeleteCollection({commit},payload){
        const {id, collectionID} = payload
        return CatalogueAPI.delete(`${listRoutes.collectionsDeleteCollection.replace(':id',id).replace(':collectionID',collectionID)}`)
            .then(res => {
                return res.status
            })
            .catch(err => {
                    commit('SET_NOTIFICATION_MESSAGE', {text:i18n.t(err.response.data.message), color:'red', confirmation: true})
            })
    },
    catalogueCollectionsEmptyCondition({commit},index){
        commit('EMPTY__CATALOGUE_COLLECTION_CONDITION',index)
    },
    catalogueCollectionStateManagement({commit},payload){
        const {id, collectionID, state} = payload
        return CatalogueAPI.patch(`${listRoutes.collectionsUpdateState.replace(':id',id).replace(':collectionID',collectionID).replace(':state',parseInt(state))}`)
            .then(res => {
                return res.status
            })
            .catch(err => {
                    commit('SET_NOTIFICATION_MESSAGE', {text:i18n.t(err.response.data.message), color:'red', confirmation: true})
            })
            .finally(()=> {
                commit('SET_CATALOGUE_COLLECTION_STATE', parseInt(state))
            })
    },

    fetchCollectionsClients({ commit }) {
        CatalogueAPI.get(`${listRoutes.collectionsClients}`)
            .then(res => {
                commit('SET_COLLECTIONS_CLIENTS', res.data);
            })
            .catch(err => {
                commit('SET_NOTIFICATION_MESSAGE', {text:i18n.t(err.response.data.message), color:'red', confirmation: true})
            });
    },
    catalogueCollectionUpdateTags({commit}, data){
        commit('SET_CATALOGUE_COLLECTION_CUSTOM_TAGS',data)
    },
    updateField({ commit }, updatedField) {
        commit('UPDATE_FIELD', updatedField);
    },
    fetchMarketsForClient({ commit },payload) {
        API.get(`${listRoutes.MarketsForclient.replace(':brandID', payload.brandID)}`)
            .then(res => {
                commit('SET_MARKETS_CLIENT', res.data);
            })
            .catch(err => {
                commit('SET_NOTIFICATION_MESSAGE', {text:i18n.t(err.response.data.message), color:'red', confirmation: true})
            });
    }


}
const getters = {}


const collections =  {
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default collections

// Helper functions
function prepareStateForCollectionsAPI(catalogueCollection) {
    // Vérifie si catalogueCollection est défini
    if (!catalogueCollection || !catalogueCollection.parameters || !catalogueCollection.parameters.conditions) {
        // Gère le cas où catalogueCollection n'est pas défini ou mal structuré
        // Vous pouvez choisir de retourner un objet vide, null, ou lever une erreur
        return null;
    }

    // Filtre les conditions pour exclure les objets vides
    const conditionsWithoutEmptyObjects = catalogueCollection.parameters.conditions.filter(condition => Object.keys(condition).length > 0);

    // Retourne l'état modifié, excluant les objets vides
    return {
        ...catalogueCollection,
        parameters: {
            ...catalogueCollection.parameters,
            conditions: conditionsWithoutEmptyObjects
        }
    };
}
