<template>
    <section>
        <PageBack/>
        <PageHeader :title="$t('invoice.add_line_to_invoice')"/>

        <Form
                :init-on-success="true"
                :fields="fields"
                :model="model"
                ref="form"
                :action="addLineToInvoice"
                @success="next"
        />

    </section>
</template>

<script>
import PageHeader from "@/components/ui/page/PageHeader";
import Form from "@/components/ui/form/Form";
import PageBack from "@/components/ui/page/PageBack";
import { mapActions } from "vuex";

export default {
    name: "invoiceAdd",
    components: {PageBack, Form, PageHeader},
    data() {
        return {
            user: null,
            loading: false,
            model: {
                product: null,
                quantity: null,
                unitPrice: null,
                amountWithoutTax: null,
                taxAmount: null,
                amountWithTax: null,
            },
            fields: [
                {name: 'product', type: 'text', label:'description', required: true},
                //{name: 'quantity', type: 'number'},
                //{name: 'unitPrice', type: 'number'},
                {name: 'amountWithoutTax', type: 'number', required: true, step: 0.01},
                //{name: 'taxAmount', type: 'number', step: 0.01},
                //{name: 'amountWithTax', type: 'number', required: false, step: 0.01},
            ]
        }
    },
    methods: {
        ...mapActions(['addLineToInvoice', 'getInvoice']),
        next(line) {
            if (line?.id) this.$router.push({name: 'invoice-line', params: {id: line.id}})
            else this.$router.go(-1)
        }
    }
};
</script>