<template>
    <div class="images-lightbox">
        <div class="images-lightbox__list">
            <div v-for="(img, i) in images" :key="i" @click="image = img; $refs.popin.open()">
                <img :src= "img" :alt="i">
                <SvgIcon name="search"/>
            </div>
        </div>
        <Popin ref="popin">
            <template v-slot:content>
                <img :src= "image" alt="image">
            </template>
        </Popin>
    </div>
</template>

<script>
import SvgIcon from '@/components/ui/svg/SvgIcon';
import Popin from '@/components/ui/popin/Popin';
export default {
    name: 'ImagesWithLightbox',
    components: {Popin, SvgIcon},
    props: {
        images: Array
    },
    data() {
        return {
            image: this.images[0]
        }
    }
}
</script>

<style lang="scss">
.images-lightbox {

    img {
        width: 100%;
        height: auto;
    }

    &__list {
        display: flex;
        gap: 10px;

        img {
            max-width: 150px;
        }

        div {
            position: relative;
            overflow: hidden;
            border-radius: var(--border-radius-1);

            &:not(:hover) .icon {
                opacity: 0;
                transform: scale(1.2);
            }
        }

        .icon {
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: opacity ease-in-out .4s, transform ease-in-out .4s;
            background-color: rgba(0,0,0, .3);
            --svg-color: #fff;
            cursor: pointer;

            svg {
                width: 20px;
                height: 20px;
            }
        }
    }

    .popin {
        &__inner {
            max-width: 600px;
        }

        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }
}
</style>