import API from '@/api/api-admin';
import listRoutes from '@/api/list-routes';
import i18n from '@/i18n';
import moment from 'moment';

const update = (commit, state, url) => {
    if (state.voucher) {
        API.get(url.replace(':id', state.voucher.id))
            .then(res => res.data)
            .then(data => {
                if (data && data.id) commit('SET_VOUCHER', data)
            })
    }
}

const get = (commit, state, id, url) => {
    if (!state.voucher || state.voucher.id !== id) {
        commit('SET_VOUCHER_LOADING', true);
        commit('SET_VOUCHER', null);
        API.get(url.replace(':id', id))
            .then(res => res.data)
            .then(data => {
                if (data && data.id) commit('SET_VOUCHER', data)
            })
            .finally(() => commit('SET_VOUCHER_LOADING', false))
    } else {
        commit('SET_VOUCHER_LOADING', false);
    }
}

const edit = (commit, state, voucher, url) => {
    return new Promise((resolve, reject) => {
        API.put(url.replace(':id', state.voucher.id), {
            code: voucher.code
        })
            .then(res => res.data)
            .then(data => {
                if (data && data.code) {
                    commit('SET_VOUCHER_CODE', data.code);
                    resolve()
                } else {
                    reject('Une erreur est survenue')
                }
            })
            .catch(() => reject('Une erreur est survenue'))
    })
}

const state = {
    voucher: null,
    voucherLoading: null,
    promotionCode: null
};

const mutations = {
    SET_VOUCHER(state, data) {
        state.voucher = data
    },
    SET_VOUCHER_LOADING(state, value) {
        state.voucherLoading = value
    },
    SET_VOUCHER_CODE(state, code) {
        state.voucher.code = code
    },
    SET_PROMOTIONCODE(state, data) {
        state.promotionCode = data
    }
};

const actions = {
    unsetVoucher({commit}) {
        commit('SET_VOUCHER', null)
    },
    getVoucher({commit, state}, id) {
        get(commit, state, id, listRoutes.voucher)
    },
    getRecomVoucher({commit, state}, id) {
        get(commit, state, id, listRoutes.recomVoucher)
    },
    updateVoucher({commit, state}) {
        update(commit, state, listRoutes.voucher)
    },
    updateRecomVoucher({commit, state}) {
        update(commit, state, listRoutes.recomVoucher)
    },
    refundVoucher({state}) {
        return new Promise((resolve, reject) => {
            API.post(listRoutes.voucherRefund.replace(':id', state.voucher.id))
                .then(data => resolve(data.data))
                .catch(() => reject('Une erreur est survenue'))
        })    
    },
    async refundRecomVoucher({state}) {
        return await API.put(listRoutes.recomVoucherRefund.replace(':id', state.voucher.id));
    },
    retryVoucher({commit,state}) {
        return API.post(listRoutes.voucherRetry.replace(':id', state.voucher.id))
            .then(res => {
                if(res.status === 204) commit('SET_NOTIFICATION_MESSAGE', {text:i18n.t('voucher_messages.success'), confirmation: false})                
            })
            .catch((err) => {
                if (err) commit('SET_NOTIFICATION_MESSAGE', { text: i18n.t(`voucher_messages.${err}`), confirmation: false })
            }) 
    },
    editVoucher({commit, state}, voucher) {
        return edit(commit, state, voucher, listRoutes.voucherEdit)
    },
    editRecomVoucher({commit, state}, voucher) {
        return edit(commit, state, voucher, listRoutes.recomVoucherEdit)
    },
    createRecomPromotionCode(_, data) {
        return new Promise((resolve, reject) => {
            data = {
                ...data,
                brand: data.client ? Number(data.client) : null,
                value: data.value && data.type === 'flat' ? Number(data.value) * 100 : data.value && data.type !== 'flat' ? Number(data.value) : null,
                minimumAmountItemsTotal: data.minimumAmountItemsTotal ? Number(data.minimumAmountItemsTotal) : 0,
                startedAt: data.startedAt ? moment(data.startedAt).local().utc().format('YYYY-MM-DD HH:mm:ss') : null,
                endedAt: data.endedAt ? moment(data.endedAt).local().utc().format('YYYY-MM-DD HH:mm:ss') : null,
            };

            API.post(listRoutes.recomPromoCodes, data)
                .then(res => res.data)
                .catch(err => reject(err.message || err))
                .finally(() => resolve(i18n.t('recom_promocodes.success-create')))
        })
    },
    editRecomPromotionCode(_, data) {
        return new Promise((resolve, reject) => {
            const currentPromotionCodeId = data.currentPromotionCodeId;
            data = {
                appliedTo: data.appliedTo ? data.appliedTo : null,
                code: data.code ? data.code : null,
                value: data.value && data.type === 'flat' ? Number(data.value) * 100 : data.value && data.type !== 'flat' ? Number(data.value) : null,
                minimumAmountItemsTotal: data.minimumAmountItemsTotal ? Number(data.minimumAmountItemsTotal) : 0,
                startedAt: data.startedAt ? moment(data.startedAt).local().utc().format('YYYY-MM-DD HH:mm:ss') : null,
                type: data.type ? data.type : null,
                endedAt: data.endedAt ? moment(data.endedAt).local().utc().format('YYYY-MM-DD HH:mm:ss') : null,
            };

            API.put(listRoutes.recomPromoCodesEdit.replace(':id', currentPromotionCodeId), data)
                .then(res => res.data)
                .catch(err => reject(err.message || err))
                .finally(() => resolve(i18n.t('recom_promocodes.success-edit')))
        })
    },
    getRecomPromotionCode({commit}, id) {
        return new Promise((resolve, reject) => {
            API.get(listRoutes.recomPromoCodesEdit.replace(':id', id))
                .then(res => {
                    commit('SET_PROMOTIONCODE', res.data)
                })
                .then(data => resolve(data))
                .catch(err => reject(err.message || err))
        })
    },
    removeRecomPromotionCode(_, id) {
        return new Promise((resolve, reject) => {
            API.delete(listRoutes.recomPromoCodesEdit.replace(':id', id))
                .then(data => resolve(data))
                .catch(err => reject(err.message || err))
        })
    }
};

const getters = {
    
};

const vouchers = {state, actions, mutations, getters};

export default vouchers;