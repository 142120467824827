<template>
    <div class="mailbuilder-edit">
        <PageBack/>
        <PageHeader :title="brandEmailsPreview?.title" :text="$t('global.editAt') + ' ' + formatDate(brandEmailsPreview?.updated_at)">
            <template v-if="!emailPublishmentState" v-slot:text>
                <TagType :value="$t('global.ready_to_publish')" />
            </template>
            <template v-slot:actions>
                <FieldSelect
                :multiple="false"
                :border="true"
                :selected="langueSelected"
                :options="avaibleLocales"
                :label="$t('global.locale')"
                @input="e => changeLangue(e)" />
                <Button size="m" color="black" :disabled="emailPublishmentState" @click.native.prevent="handlePublishNewContent" icon="publish">{{ $t('global.publish') }}</Button>
            </template>
        </PageHeader>
        <div class="mailbuilder-edit__container">
            <div class="mailbuilder-edit__container__content">
                <div class="mailbuilder-edit__container__content--title">
                    <p>{{ $t('mailbuilder.labelEdit_content') }} <span class="tooltip" @click="openTagPopin">?</span></p>
                </div>
                <div class="mailbuilder-edit__container__content--text">
                    <div class="mailbuilder-edit__container__content--html-container" v-for="(key,i) in defaultEmailContentKeySort" :key="i">
                        <p>{{ $t(`mailbuilder.edit.${i.split('.')[i.split('.').length - 1]}`) }}</p> <!-- Get only the last part of the key -->
                        <HtmlEditor 
                            :keyContent="i" 
                            :content="key"
                            :toolbar="false"
                            :autoSubmit="true"
                            :loading="false"
                            @submit="putMailById(i, $event)"
                        />
                    </div>
                </div>
            </div>
            <div class="mailbuilder-edit__container__preview">
                <iframe :srcdoc="brandEmailsPreview?.html"></iframe>
            </div>
        </div>
        <Popin
            ref="contentTagPopin"
            :center="true"
            :fullScreen="true"
            :title="$t('mailbuilder.title_variable_popin')"
            class="popin-email-variable"
            >
            <template v-slot:content>
                <TableSticky v-if="variables" :fields="fields" :data="variables" />
            </template>
        </Popin>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import PageBack from '@/components/ui/page/PageBack';
import PageHeader from '@/components/ui/page/PageHeader';
import Button from '@/components/ui/button/Button';
import FieldSelect from '@/components/ui/form/fields/FieldSelect';
import HtmlEditor from '@/components/ui/html-editor/HtmlEditor';
import Popin from '@/components/ui/popin/Popin';
import TableSticky from '@/components/ui/table-sticky/TableSticky';
import TagType from '@/components/ui/tag/TagType';
import moment from 'moment';

export default {
    name: 'ClientMailbuilderEdit',
    components: {PageHeader, Button, FieldSelect, HtmlEditor, Popin, PageBack, TableSticky, TagType},
    data() {
        return {
            langueSelected: null,
            fields: [
                { key: 'description', size: "xxl" },
                { key: 'variable', size: "xxl"}
            ],
            variables: [
                {
                    'description': "Prénom de l'utilisateur",
                    'variable': "{{firstname}}",
                },
                {
                    'description': "Nom de l'utilisateur",
                    'variable': "{{lastname}}",
                },
                {
                    'description': "Email de l'utilisateur",
                    'variable': "{{email}}",
                },
                {
                    'description': "Nom de la marque",
                    'variable': "{{clientName}}",
                },
                {
                    'description': "Url du site de seconde main",
                    'variable': "{{url_front}}",
                },
                {
                    'description': "Numéro de commande",
                    'variable': "{{order_id}}",
                },
                {
                    'description': "Raison d'annulation de la commande",
                    'variable': "{{order_cancel_reason}}",
                },
                {
                    'description': "Numéro de reprise",
                    'variable': "{{trade-in_cart_id}}",
                }
            ]
        }
    },
    computed: {
        ...mapState(['client', 'clientLocales', 'emailByKey', 'brandEmailsPreview']),
        ...mapGetters(['roles']),
        id() {
            return this.$route.params.id
        },
        mailKey() {
            return this.$route.params.mailKey
        },
        defaultLocale() {
            return this.clientLocales.find(locale => locale.isDefault === true)?.code ? this.clientLocales.find(locale => locale.isDefault === true)?.code : 'fr_FR';
        },
        avaibleLocales() {
            return this.clientLocales.map(locale => {
                return {
                    label: this.$t('locales.' + locale.code.replace(/_.*/, '')) || locale.code.replace(/_.*/, ''),
                    id: locale.code
                }
            })
        },
        emailPublishmentState() {
            return this.brandEmailsPreview?.state === 'published';
        },
        defaultEmailContentKeySort() { // Sort the email content input by order
            const order = ['subject', 'title', 'subtitle', 'content', 'subtitle_buy', 'text_buy', 'cta_buy', 'cta_buy_url', 'subtitle_sell', 'text_sell', 'cta_sell', 'cta_sell_url', 'btn', 'btn_url', 'cta'];
            const sortedData = {};
            order.forEach(key => {
                for (const dataKey in this.emailByKey) {
                    if (dataKey.endsWith(key)) {
                        sortedData[dataKey] = this.emailByKey[dataKey];
                        break;
                    }
                }
            });
            return sortedData;
        }
    },
    methods: {
        ...mapActions(['getClientLocales', 'getEmailByKey', 'putEmailByKey', 'getBrandEmailsPreview', 'getEmailsModificationsPublish']),
        putMailById(key, data) {
            if(this.emailByKey[data.key] !== data.content && data.content !== ""){
                this.putEmailByKey({idClient: this.id, locale: this.langueSelected, wordingKey: key, data: {content: data.content}})
                    .then(() => {
                        this.getBrandEmailsPreview({idClient: this.id, locale: this.langueSelected, mailKey: this.mailKey});
                    });
            }
        },
        formatDate(date) {
            return moment(date).format(`DD/MM/YY ${this.$t('global.at')} HH:mm`);
        },
        handlePublishNewContent(){
            this.getEmailsModificationsPublish({idClient: this.id, locale: this.langueSelected})
                .then(() => {
                    this.getBrandEmailsPreview({idClient: this.id, locale: this.langueSelected, mailKey: this.mailKey});
                });
        },
        openTagPopin(){
            this.$refs.contentTagPopin.open();
        },
        changeLangue(e){
            this.langueSelected = e;
            this.getEmailByKey({idClient: this.id, locale: this.langueSelected, key: this.mailKey});
            this.getBrandEmailsPreview({idClient: this.id, locale: this.langueSelected, mailKey: this.mailKey});
        }
    },
    mounted() {
        this.langueSelected = this.defaultLocale;
        this.getEmailByKey({idClient: this.id, locale: this.langueSelected, key: this.mailKey});
        this.getBrandEmailsPreview({idClient: this.id, locale: this.langueSelected, mailKey: this.mailKey});
    },
    async created() {
        if(this.$route.params.id) {
            await this.getClientLocales(this.$route.params.id);
        }
    },
}
</script>

<style lang="scss">
.mailbuilder-edit{
    .page-header{
        &__actions {
            .field-select__label{
                background-color: var(--color-bg-light);
            }

            @media screen and (max-width: 500px){
                margin-left: 0px;
                margin-top: 20px;
            }
        }

        @media screen and (max-width: 500px){
            flex-wrap: wrap;
        }
    }

    &__container{
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 50px;
        justify-content: space-between;
        align-items: flex-start;

        @media screen and (max-width: 1024px){
            flex-wrap: wrap;
        }

        &__content{
            background-color: white;
            border: var(--border-2);
            border-radius: var(--border-radius-2);
            flex-grow: 1;
            width: 100%;
            overflow: hidden;

            &--title{
                border-bottom: var(--border-2);
                padding: var(--field-padding);
                background-color: var(--color-bg-light);
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                p{
                    font-size: 1.5rem;
                    font-weight: 500;
                }

                .tooltip {
                    cursor: pointer;
                    padding: 0px 4px;
                    border: 1px solid var(--color-text-grey);
                    margin-left: 5px;
                    border-radius: 50px;
                    font-size: 1rem;
                    color: var(--color-text-grey);
                }
            }

            &--text{
                padding: var(--field-padding);
                display: flex;
                flex-direction: column;

                p{
                    margin-bottom: calc(var(--field-padding) / 2);
                }
            }

            &--html-container{
                margin: var(--field-padding) 0;
            }
        }

        &__preview{
            background-color: white;
            border: var(--border-2);
            border-radius: var(--border-radius-2);
            position: sticky;
            top: var(--field-padding);
            flex-shrink: 1;
            min-width: 450px;
            height: calc(100vh - var(--field-padding) * 2);
            overflow: hidden;

            @media screen and (max-width: 500px) {
                min-width: 275px;
                max-width: 275px;
                height: calc(75vh - var(--field-padding) * 2);
            }

            iframe{
                width: 600px;
                height: calc((100vh - var(--field-padding) * 2) * 1.336);
                border: none;
                transform: scale(0.746);
                transform-origin: top left;

                @media screen and (max-width: 500px) {
                    transform: scale(0.46);
                    height: calc((100vh - var(--field-padding) * 2) * 1.6);
                }
            }
        }
    }

    .popin-email-variable{
        .title{
            margin: 50px 0px;
        }

        .table-sticky{
            width: 90%;
            margin: 0 auto;
            text-align: left;
        }
    }
}
</style>

