import axios from 'axios';

const datasMonitoringAPI = axios.create({
    baseURL: `${process.env.VUE_APP_DATASMONITORING_API}`,
    headers: {
        Accept: "application/json",
        'Content-Type': 'application/json'
    },
});

export default datasMonitoringAPI;

