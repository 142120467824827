const state = {
    notificationMessage: null
}

const mutations = {
    SET_NOTIFICATION_MESSAGE(state, value) {
        state.notificationMessage = value
    }
}

const actions = {
    setNotificationMessage({commit}, value) {
        commit('SET_NOTIFICATION_MESSAGE', value)
    }
}

const getters = {};

const notifications = {state, mutations, actions, getters};
export default notifications;