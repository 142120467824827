import ApiAdmin from '@/api/api-admin';
import ListRoutes from '@/api/list-routes';

let countriesInStorage = sessionStorage.getItem('countries');
if (countriesInStorage && countriesInStorage[0] === '[') countriesInStorage = JSON.parse(countriesInStorage)

const state = {
    countries: countriesInStorage
}

const mutations = {
    SET_COUNTRIES(state, data) {
        state.countries = data;
        sessionStorage.setItem('countries', JSON.stringify(data))
    }
};

const actions = {
    getCountries({commit}) {
        return new Promise(resolve => {
            ApiAdmin.get(ListRoutes.countries)
                .then(res => res.data)
                .then(data => commit('SET_COUNTRIES', data))
                .finally(() => resolve())
        })
    }
};

const getters = {};

const countries = {state, mutations, actions, getters};

export default countries