<template>
    <div class="warehouse-dashboard">
        <PageHeader :title="$t('warehouse.dashboard')"/>

        <PageFilters>
            <template v-slot:left>
                <FieldSelect v-if="warehouseOptions?.length > 1"
                             :multiple="true"
                             :selected="warehouses"
                             :options="warehouseOptions"
                             :label="$t('global.warehouse')"
                             @input="e => warehouses = e"/>

                <FieldSelect v-if="false"
                             :multiple="true"
                             :selected="clients"
                             :options="clientsOptions"
                             :label="$t('global.clients')"
                             @input="e => clients = e"/>

                <FieldPeriod v-if="activeView !== 'live'"
                             :start="periodStart"
                             :end="periodEnd"
                             :label="$t('warehouse.period')"
                             @change="e => updatePeriodDate(e)"
                />
            </template>
            <template v-slot:right>
                <FieldSwitch
                        :title_1="$t('warehouse.live')"
                        value_1="live"
                        :title_2="$t('warehouse.recap')"
                        value_2="recap"
                        :model="activeView"
                        @change="e => activeView = e"/>
            </template>
        </PageFilters>

        <PageLoader v-if="!warehouseStats"/>

        <div v-else>

            <div v-if="activeView === 'live'">

                <SectionTable :title="$t('warehouse.articles_waiting')">
                    <TableItem>
                        <Thead :fields="warehouseLive.treatment.categories.map(c => ({translation: `warehouse.treatment.${c}`}))"/>
                        <Row>
                            <TableCell v-for="(value, i) in warehouseLive.treatment.data" :key="i" :value="value.count"/>
                        </Row>
                    </TableItem>
                </SectionTable>

                <SectionTable :title="$t('warehouse.articles_waiting_treatment')">
                    <TableItem>
                        <Thead :fields="[{}, ...warehouseLive.order_and_stock.categories.map(c => ({translation: `warehouse.${c}`}))]"/>
                        <Tbody>
                        <Row v-for="(row, i) in warehouseLive.order_and_stock.data" :key="i">
                            <TableCell :value="$t(`warehouse.${i}`)"/>
                            <TableCell v-for="(value, y) in row" :key="y" :value="value.count"/>
                        </Row>
                        </Tbody>
                    </TableItem>
                </SectionTable>

                <SectionTable :title="$t('warehouse.pending')">
                    <TableItem>
                        <Thead :fields="[{key: 'since'}, ...warehousePending.categories.map(c => ({translation: `warehouse.${c}`}))]"/>
                        <Tbody>
                        <Row v-for="(row, i) in warehousePending.data[0]" :key="i">
                            <TableCell :value="$t(`warehouse.${i}`)"/>
                            <TableCell :value="warehousePending.data[0][i]"/>
                            <TableCell :value="warehousePending.data[1][i]"/>
                        </Row>
                        </Tbody>
                    </TableItem>
                </SectionTable>

            </div>


            <div v-else>

                <SectionTable :title="$t('warehouse.resume_treatment')">
                    <TableItem>
                        <Thead :fields="[{key: 'since'}, ...warehouseRecap.treatment.categories.map(c => ({translation: `warehouse.${c}`}))]"/>
                        <Tbody>
                        <Row v-for="(row, i) in warehouseRecap.treatment.data" :key="i">
                            <TableCell :value="$t(`warehouse.${i}`)"/>
                            <TableCell v-for="(value, y) in row" :key="y" :value="value.count"/>
                        </Row>
                        <Row v-if="warehousePeriod && periodStart && periodEnd">
                            <td>{{ periodStart | momentDate }} - {{ periodEnd | momentDate }}</td>
                            <TableCell v-for="(category, i) in warehouseRecap.treatment.categories" :key="i" :value="warehousePeriod.treatment.data.recap[i].count"/>
                        </Row>
                        </Tbody>
                    </TableItem>
                </SectionTable>

                <SectionTable :title="$t('warehouse.resume_stock')">
                    <TableItem>
                        <Thead :fields="[{key: 'since'}, ...warehouseRecap.order_and_stock.categories.map(c => ({translation: `warehouse.${c}`}))]"/>
                        <Tbody>
                        <Row v-for="(row, i) in warehouseRecap.order_and_stock.data" :key="i">
                            <TableCell :value="$t(`warehouse.${i}`)"/>
                            <TableCell v-for="(value, y) in row" :key="y" :value="value.count"/>
                        </Row>
                        <Row v-if="warehousePeriod && periodStart && periodEnd">
                            <td>{{ periodStart | momentDate }} - {{ periodEnd | momentDate }}</td>
                            <TableCell v-for="(category, i) in warehouseRecap.order_and_stock.categories" :key="i" :value="warehousePeriod.order_and_stock.data.recap[i].count"/>
                        </Row>
                        </Tbody>
                    </TableItem>
                </SectionTable>

            </div>


        </div>

    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import PageHeader from '@/components/ui/page/PageHeader';
import SectionTable from '@/components/sections/SectionTable';
import Thead from '@/components/ui/table/thead/Thead';
import Row from '@/components/ui/table/row/Row';
import TableCell from '@/components/ui/table/cell/TableCell';
import Tbody from '@/components/ui/table/body/Tbody';
import TableItem from '@/components/ui/table/Table';
import PageLoader from '@/components/ui/page/PageLoader';
import PageFilters from '@/components/ui/page/PageFilters';
import FieldPeriod from '@/components/ui/form/fields/FieldPeriod';
import FieldSwitch from '@/components/ui/form/fields/FieldSwitch';
import FieldSelect from '@/components/ui/form/fields/FieldSelect';

export default {
    name: 'WarehouseDashboardPage',
    components: {FieldSelect, FieldSwitch, FieldPeriod, PageFilters, PageLoader, TableItem, Tbody, TableCell, Row, Thead, SectionTable, PageHeader},
    data() {
        return {
            activeView: 'live',
            periodStart: null,
            periodEnd: null,
            warehouses: [],
            clients: []
        }
    },
    computed: {
        ...mapState(['warehouseStats', 'warehousePeriod', 'warehouseStatsFilters']),
        ...mapGetters(['warehouseRecap', 'warehouseLive', "warehousePending"]),
        activePeriod() {
            return {
                start: this.periodStart,
                end: this.periodEnd
            }
        },
        warehouseOptions() {
            const list = this.warehouseStatsFilters?.find(x => x.key === 'warehouse');
            return list?.options || []
        },
        clientsOptions() {
            return this.warehouseStatsFilters?.find(x => x.key === 'client')?.options
        }
    },
    watch: {
        warehouses() {
            this.getStats()
        },
        clients() {
            this.getStats()
        }
    },
    methods: {
        ...mapActions(['getWarehouseStats', 'getWarehouseStatsPerPeriod', 'getWarehouses', 'getWarehouseStatsFilters']),
        updatePeriodDate(e) {
            this.periodStart = e.start;
            this.periodEnd = e.end;
            this.getPeriodStats()
        },
        getPeriodStats() {
            if (this.periodStart && this.periodEnd) this.getWarehouseStatsPerPeriod({
                date: this.activePeriod,
                warehouses: this.warehouses
            })
        },
        getStats() {
            const warehouses = this.warehouses?.length ? this.warehouses : this.warehouseOptions.map(w => w.id);
            this.getWarehouseStats({warehouses: warehouses, clients: this.clients});
        }
    },
    async beforeMount() {
        if (!this.warehouseStatsFilters) await this.getWarehouseStatsFilters();
        if (!this.warehouseStats) this.getStats();
    }
}
</script>

<style lang="scss">
.warehouse-dashboard {

    .field-select {
        margin-top: 0;
        width: auto;
    }

    .form-switch {
        width: 180px;
    }

    &__tables {
        display: flex;
        gap: 30px;
        flex-wrap: wrap;

        .section-table {
            width: auto;
        }

        thead:nth-of-type(2) th {
            border-top: solid 1px #fff;
        }
    }

}
</style>