<template>
    <div class="tooltip">
        <i :class="isDark ? 'dark' : 'light'" class="checkerboard-item__infos">i</i>
        <span class="tooltip__infos">
            <TextSimple size="xxs">{{ infos }}</TextSimple>
        </span>
    </div>
</template>

<script>
import TextSimple from '@/components/ui/text/TextSimple.vue'
export default {
    props: {
        infos:String,
        isDark: {
            type: Boolean,
            default: true
        }
    },
    components: {
        TextSimple,
    },
}
</script>

<style lang="scss">
.tooltip {
    position: relative;
    &:hover {
        .tooltip__infos {
            opacity: 1;
        }
    }
    i {
        margin-left: .5rem;
        font-style: initial;
        &.dark {
            background: #000;
            color: #fff;
            }
        &.light {
            background: var(--color-bg-light);
            color: #000;
        }
    }
    &__infos {
        position: absolute;
        z-index: 5;
        background-color: #fff;
        box-shadow: var(--box-shadow-1);
        border-radius: var(--border-radius-2);
        width: max-content;
        max-width: 200px;
        padding: 20px;
        top: 120%;
        left: 0;
        opacity: 0;
        transform: translateX(-10px);
        pointer-events: none;
        transition: opacity ease-out .3s, transform ease-out .3s;
        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 20px;
            top: -5px;
            background-color: #fff;
            width: 10px;
            height: 10px;
            transform: rotate(45deg);
            z-index: 2;
        }
    }
}
</style>