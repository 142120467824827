<template>
    <div v-if="!merchandisingRandomProductLoading" class="random-product">
        
        <PageHeader :title="item_title">
            <template v-slot:actions>
                <Field type="select" :options="Object.keys(random_product).map(item => ({ label: item, value: item, disabled:nullKeys.includes(item) ? true  : false }))" :model="selectedLang" @change="e=> selectedLang = e"/>
            </template>
        </PageHeader>

        <GridContainer>
            <GridCard>
                <GridContent class="random-product__description" v-if="item_description.length">
                    <GridHeader size="s" :title="this.$t('merchandising.description')" />
                    <div v-for="(item, index) in formattedDescriptionArray" :key="index">
                        <div v-if="item.key">
                            <strong>{{ getFirstAlias(existingDatas.merchandising,item.key) }}:</strong> {{ item.value }}
                        </div>
                        <div v-else>
                            {{ item.value }}
                        </div>
                    </div>
                </GridContent>
            </GridCard>
            <div class="random-product__row">
                <GridCard>
                    <GridContent>
                        <img v-if="item_images.length === 0" class="" src="@/assets/images/merchandising_default_image.svg" alt="">
                        <div v-else>
                            <div class="random-product__gallery">
                                <img v-for="(image,index) in item_images" :key="index" :src="image" alt="">
                            </div>
                        </div>

                    </GridContent>
                </GridCard>
                <GridCard>
                    <GridContent>
                        <div v-for="(value,key) in item_infos_aliases" :key="key">
                            <strong>{{key}} :</strong> {{value ? value : `${$t('merchandising.no_value_assigned')}`}}
                        </div>
                    </GridContent>
                </GridCard>
            </div>
            <div class="random-product__row">
                <GridCard>
                    <GridContent>
                        <GridHeader size="s" title="Metafields" />
                            <MerchandisingItemAliases :item="item_metafields_aliases" />
                    </GridContent>
                </GridCard>
                <GridCard >
                    <GridContent>
                        <GridHeader size="s" title="Tags" />
                            <MerchandisingItemAliases :item="item_tags_aliases" />
                    </GridContent>
                </GridCard>
            </div>
            <div class="random-product__row">
                <Button @click.prevent.native="sendMerchDatas()" icon="right" color="black" size="m">{{ $t('merchandising.load_random_product') }}</Button>
            </div>
        </GridContainer> 
        
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import PageHeader from '@/components/ui/page/PageHeader';
import GridContainer from '@/components/ui/grid/GridContainer.vue'
import GridCard from '@/components/ui/grid/GridCard';
import GridContent from '@/components/ui/grid/GridContent';
import GridHeader from '@/components/ui/grid/GridHeader';
import Button from '@/components/ui/button/Button';
import Field from '@/components/ui/form/fields/Field.vue';
import MerchandisingItemAliases from '@/components/ui/merchandising/MerchandisingItemAliases.vue';

export default {
    props: {
        random_product:Object,
        merchandising_datas:Object,
        existingDatas:Object
    },
    components: {
        PageHeader,
        GridContainer,
        GridCard,
        GridContent,
        GridHeader,
        Button,
        Field,
        MerchandisingItemAliases
    },
    data() {
        return {
            selectedLang: null
        }
    },
    computed: {
        ...mapState(['merchandisingDefaultLang', 'merchandisingRandomProductLoading']),
        id() {
            return this.$route.params.id
        },
        lang(){
            return this.selectedLang ?? this.merchandisingDefaultLang
        },
        nullKeys() {
            const nullKeysArray = [];
            for (const key in this.random_product) {
                if (this.random_product[key] === null) {
                    nullKeysArray.push(key); // Push the null key into the array
                }
            }
            return nullKeysArray; // Return an array of null keys
        },
        item(){
            return  this.random_product[this.lang].item
        },
        merchandising(){
            return  this.random_product[this.lang].merchandising
        },
        tagsByCategory(){
            return  this.merchandising.tagsByCategory
        },
        tags(){
            return this.merchandising.tags
        },
        tags_active(){
            return this.tags.filter(elem => Object.values(elem)[0].active)
        },
        metafields(){
            return this.merchandising.metafields
        },
        metafields_active(){
            return this.metafields.filter(elem => Object.values(elem)[0].active)
        },
        active_tags_and_metafields(){
            return [...this.tags_active,...this.metafields_active]
        },
        active_tags_and_metafields_without_duplicates(){
            const uniqueKeysSet = new Set();
            const uniqueArray = this.active_tags_and_metafields.filter(obj => {
                const key = Object.keys(obj)[0];
                    if (uniqueKeysSet.has(key)) {
                        return false;
                    }
                    uniqueKeysSet.add(key);
                    return true;
                });
            return uniqueArray
        },
        item_title(){
            return this.merchandising.title
        },
        item_description(){
            return this.merchandising.description
        },
        description_asArray() {
            return this.item_description.split(';')
        },
        formattedDescriptionArray() {
            return this.description_asArray.map(segment => {
                const parts = segment.split(':').map(part => part.trim());
                // Vérifier si la partie après ":" existe
                if (parts.length === 2 && parts[1]) {
                    return { key: parts[0], value: parts[1] };
                } else if (parts.length === 1 && parts[0]) {
                    // Dans le cas où il n'y a pas de ":", traiter tout le segment comme une valeur
                    return { value: parts[0] };
                }
            }).filter(item => item !== undefined); // Filtrer les éléments indéfinis ou vides
        },
        item_images(){
            return this.merchandising.images
        },   

        item_cleaned(){
            const originalObject = { ...this.item };
            const propertiesToRemove = ["cohort", "title", "description", "price"];

            for (const key in originalObject) {
                if (propertiesToRemove.includes(key) || key.startsWith("photo_")) {
                delete originalObject[key];
                }
            }
            return originalObject
        },
        item_infos(){

            const {ean, sku, color, size, state } = this.item_cleaned
            const {price_origin, price_resale } = this.merchandising

            const item_infos = {ean, sku, color, size, state, price_origin, price_resale}

            return item_infos
            
        },
        item_infos_aliases(){
            const result = {};

            for (const key in this.item_infos) {
                const aliasInfo = this.active_tags_and_metafields_without_duplicates.find(aliasItem => aliasItem[key]);

                if (aliasInfo && aliasInfo[key].alias !== null) {
                    result[aliasInfo[key].alias] = this.item_infos[key];
                } else {
                    result[key] = this.item_infos[key];
                }
            }

            return result
        },
        // Utilisation de la méthode commune dans 'item_metafields_aliases'
        item_metafields_aliases() {
            const excludeItemInfoskeys = new Set(Object.keys(this.item_infos))
            const excludeTheseKeys = ['title', 'description', 'price']
            return this.extractAliases(this.item, this.metafields_active,excludeItemInfoskeys,excludeTheseKeys, this.tagsByCategory, this.selectedLang);
        },
        // Utilisation de la même logique pour 'item_tags_aliases'
        item_tags_aliases() {
            const excludeItemInfoskeys = new Set(Object.keys(this.item_infos))
            const excludeTheseKeys = ['title', 'description', 'price']
            return this.extractAliases(this.item, this.tags,excludeItemInfoskeys,excludeTheseKeys, this.tagsByCategory, this.selectedLang);
        }
        
    },
    methods: {
        ...mapActions(['fetchMerchandisingRandomProduct',]),
        sendMerchDatas(){
            this.fetchMerchandisingRandomProduct(this.id)
                .catch(err => console.log('sendMerchDatas',err.response.data))
        },
        extractAliases(item, dataActive, itemInfosKeys, excludedKeys, tagsByCategory, lang) {
            let aliases = {};

            for (const key in item) {
            if (!itemInfosKeys.has(key) && !excludedKeys.includes(key)) {
                const entry = dataActive.find(field => field[key] && field[key].active);

                if (entry) {
                const alias = entry[key].alias;
                let value = item[key];

                // Filtrer les tags par la langue spécifiée et chercher une correspondance
                const tag = tagsByCategory.find(tag => tag.name === `${key}_${value}` && tag.lang === lang);
                if (tag) {
                    // Utiliser le label du tag correspondant
                    value = tag.label.split('_').pop(); // Enlever le préfixe du label
                }

                // Assigner la valeur à l'alias ou, à défaut, à la clé
                aliases[alias || key] = value;
                }
            }
            }

            return aliases;
        },
        getFirstAlias(data, key) {
            // Vérifier si la clé existe dans les tags
            if (data.tags && data.tags[key] && data.tags[key].alias) {
                return data.tags[key].alias.split('_')[0];
            }
            // Vérifier si la clé existe dans les metafields
            if (data.metafields && data.metafields[key] && data.metafields[key].alias) {
                return data.metafields[key].alias.split('_')[0];
            }
            // Retourner null si la clé n'est pas trouvée
            return null;
        }
    },
    mounted () {
        this.selectedLang = this.lang;
    },
}
</script>

<style lang="scss" scoped>
.random-product {
    background: var(--color-bg-light);
    padding: 20px;
    strong {
        text-transform: capitalize;
    }
    
    &__description {
        padding-top: 20px;
    }
    &__row {
        display:flex;
        gap: 20px;
        justify-content: center;
        .grid-card {
            flex-basis: 50%;
        }
        
    }
    &__gallery {
        display: flex;
        overflow-y: scroll;
        img {
            max-width: 100%;
            aspect-ratio: 1;
        }
    }
}

</style>