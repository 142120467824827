<template>
    <form class="translations-key" :class="{'is-success': success, 'is-error': error, 'is-loading': loading}">
        <div v-show="false" v-if="translation?.syntax === 'text'" class="translations-key__input">
            <input type="text" v-model="value"/>
            <Button @click.native.prevent="submit" :loading="loading">{{ $t('global.edit') }}</Button>
        </div>
        <HtmlEditor :key="translation.value" :content="translation.value" @submit="submit" :loading="loading"/>
        <PopinNotification v-if="!templateMail" ref="popinSuccess" :text="$t('translations.success')"/>
        <PopinNotification ref="popinError" :text="$t('translations.error')"/>
        <TextSimple color="grey" size="xxs" class="translations-key__updated">{{ $t('global.updatedAt') }}  {{ translation.updatedAt | dateHour }}</TextSimple>
    </form>
</template>

<script>
import HtmlEditor from '@/components/ui/html-editor/HtmlEditor';
import { mapActions } from 'vuex';
import PopinNotification from '@/components/ui/popin/PopinNotification';
import TextSimple from '@/components/ui/text/TextSimple';
import Button from '@/components/ui/button/Button';

export default {
    name: 'TranslationsKey',
    components: {Button, TextSimple, PopinNotification, HtmlEditor},
    props: {
        translation: Object,
        templateMail: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isChanged: false,
            success: false,
            error: false,
            loading: false,
            htmlContent: this.translation.html,
            value: ''
        }
    },
    methods: {
        ...mapActions(['editTranslation']),
        currentHtmlContent(content) {
            this.htmlContent = content;
            this.$emit('change', this.htmlContent);
        },
        submit(content) {
            this.loading = true;
            this.success = false;
            this.error = false;
            this.editTranslation({id: this.translation.id, content: content})
                .then(() => this.templateMail ? this.success = false : this.success = true)
                .catch(() => this.error = true)
                .finally(() => this.loading = false)
        }
    },
    watch: {
        error(x) {
            if (x) this.$refs.popinError.open();
            else this.$refs.popinError.close();
        },
        success(x) {
            if (x) this.$refs.popinSuccess.open();
            else this.$refs.popinSuccess.close();
        },
        'translation.value': {
            handler(value){
                this.value = value
            },
            deep: true, immediate: true
        },
    }
}
</script>

<style lang="scss">
.translations-key {
    position: relative;

    &__updated {
        @media screen and (min-width: 501px) {
            bottom: 40px;
            right: 10px;
            position: absolute;
            z-index: 2;
        }

        @media screen and (max-width: 500px) {
            width: 100%;
            text-align: center;
        }
    }

    .button {
        display: inline-block;
        margin: 15px;

        @media screen and (max-width: 500px) {
            margin: 15px auto;
            display: block;
        }
    }

    .vueditor {
        border: 0;
    }

    &__input {
        input {
            width: 100%;
            background-color: #fff;
            border: var(--border-1);
            border-radius: var(--border-radius-2);
            overflow: hidden;
            padding: 15px;
        }
    }
}
</style>