<template>
    <div class="catalogue-status">
        <Status :text="$t('catalogue.catalogueSetup')" :valid="values.configuration"/>
        <Status :text="$t('catalogue.catalogueValidation')" :valid="values.configuration"/>
        <!-- <Status :text="$t('cohorts.pricing')" :valid="values.pricing"/> -->
        <Status :text="$t('catalogue.catalogueGeneration')" :valid="values.generation.isGenerated"/>
        <Status :text="$t('catalogue.catalogueActive')" :valid="values.active"/>
    </div>
</template>

<script>
import Status from '@/components/ui/status/Status.vue'
export default {
    components: {
        Status,
    },
    props: {
        status: Object
    },
    computed: {
        values() {
            const {configuration, active, pricing } = this.status
            const { validation, generation } = this.status.status
            const values = {configuration, validation, generation, active, pricing }
            return values
        }
    },
}
</script>

<style lang="css" scoped>

</style>