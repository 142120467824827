<template>
    <span class="tag-list">
        <slot/>
    </span>
</template>

<script>
export default {
    name: 'TagList'
}
</script>

<style lang="scss">
.tag-list {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    .tag {
        margin: 0;
    }
}
</style>