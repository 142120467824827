<template>
    <TemplateDetails :get-function="getAdmin" :data="admin" :loading="adminLoading">

        <AdminForm
                :title="$t('admin.edit.title')"
                :action="editAdmin"
                :model="adminFormated"
                :edit="true"
                :success-msg="$t('admin.edit.success')"
                :error-msg="$t('admin.edit.error')"/>

    </TemplateDetails>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import TemplateDetails from '@/components/templates/TemplateDetails';
import AdminForm from '@/components/ui/admin/AdminForm';

export default {
    name: 'AdminEdit',
    components: {AdminForm, TemplateDetails},
    computed: {
        ...mapState(['admin', 'adminLoading']),
        adminFormated() {
            if (!this.admin) return null;
            return {...this.admin, defaultLocale: this.admin.defaultLocale?.code}
        }
    },
    methods: {
        ...mapActions(['getAdmin', 'editAdmin'])
    }
}
</script>