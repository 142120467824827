<template>
    <div>
        <div v-if="isObject">
            <div v-for="(translation, key, i) in translations" :key="i" class="translations-group"
                :data-translation-group-name="key">

                <template v-if="templateMail">
                    <template v-for="(content, i) in translation">
                        <TranslationsKeys v-if="translation && content.locale === initLocale"
                            :translations="translation" :all_translations="all_translations"
                            :languageSelector="languageSelector" :init-locale="initLocale" :templateMail="templateMail"
                            :templateMailFooter="templateMailFooter" :key="i" />
                    </template>
                </template>

                <template v-else>
                    <TranslationTab :selected="selected === key"
                        :button="Array.isArray(translation) ? selected === key ? $t('global.close') : $t('global.see') : null"
                        :icon="Array.isArray(translation) ? null : 'chevron'" :text="key | translationsKey"
                        @click.native.prevent="selected = key === selected ? null : key" />
                    <div v-if="selected === key">
                        <TranslationsKeys v-if="translation.id && translation.value" :translations="translation"
                            :all_translations="all_translations" />
                        <TranslationsGroup v-else :translations="translation" :all_translations="all_translations" />
                    </div>
                </template>
            </div>

        </div>

        <div v-else-if="isArray">
            <TranslationsKeys :translations="translations" :all_translations="all_translations" />
        </div>

        <div v-else>
            <p>{{ translations.value }}</p>
        </div>
    </div>
</template>

<script>
import TranslationsKeys from '@/components/ui/translations/TranslationKeys';
import TranslationTab from '@/components/ui/translations/TranslationTab';

export default {
    name: 'TranslationsGroup',
    components: { TranslationTab, TranslationsKeys },
    props: {
        translations: {
            type: [Object, Array],
            required: true,
        },
        all_translations: [Object, Array],
        templateMail: {
            type: Boolean,
            default: false
        },
        templateMailFooter: {
            type: Boolean,
            default: false
        },
        initLocale: String,
        languageSelector: Boolean
    },
    data() {
        return {
            selected: null
        }
    },
    computed: {
        isArray() {
            return Array.isArray(this.translations);
        },
        isObject() {
            return typeof this.translations === 'object' && !this.isArray && !this.translations.createdAt;
        },
        isListOfKeys() {
            return Object.keys(this.translations).find(key => Array.isArray(this.translations[key]) && this.translations[key][0] && this.translations[key][0].id)
        }
    },
};
</script>

<style lang="scss">
.translations-group {

    .translations-group {
        margin: 0;
        padding-left: 30px;

        &:last-of-type {
            margin-bottom: 30px;
        }
    }

    &[data-translation-group-name="subject"] {
        order: 1;
    }

    &[data-translation-group-name="title"] {
        order: 2;
    }

    &[data-translation-group-name="content"] {
        order: 3;
    }

    &[data-translation-group-name="btn"] {
        order: 4;
    }

    &[data-translation-group-name="btn_url"] {
        order: 5;
    }
}
</style>