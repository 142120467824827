<template>
    <TemplateTranslations :title="'{{name}}'"
                          ref="page"
                          :display-menu="true"
                          :subtitle="$t('client.config_text')"
                          :get-translations="getMarketTranslations"
                          :translations="marketTranslations"
                          :error-msg="$t('client.not-found')"
                          :export-route="$listRoutes.websiteTranslationsExport"
                          :import-route="$listRoutes.websiteTranslationsImport"
                          :publish-route="$listRoutes.websiteTranslationsPublish"/>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import TemplateTranslations from '@/components/templates/TemplatesTranslations';

export default {
    name: 'ClientTranslations',
    components: { TemplateTranslations },
    computed: {
        ...mapState(['marketTranslations'])
    },
    methods: {
        ...mapActions(['getMarketTranslations'])
    },
    beforeRouteLeave(to, from, next) {
        this.$refs.page.routeLeaving(to, from, next)
    }
}
</script>
