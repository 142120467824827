import CatalogueAPI from '@/api/api-catalogue';
import listRoutes from '@/api/list-routes';
import Vue from 'vue';
import i18n from '@/i18n';

const state = {
    productMerchandising: {
        "title": "#title",
        "description": ["#description"],
        "fields": [],
        "images": []
    },
    productMerchandisingLoading: null
}

const mutations = {
    SET_PRODUCT_MERCHANDISING(state,payload){
        Vue.set(state, 'productMerchandising', payload)
    },
    SET_PRODUCT_MERCHANDISING_LOADING(state,payload){
        Vue.set(state, 'productMerchandisingLoading', payload)
    },
    SET_PRODUCT_MERCHANDISING_TITLE(state,payload){
        Vue.set(state.productMerchandising, 'title', payload)
    },
    SET_PRODUCT_MERCHANDISING_DESCRIPTION(state,payload){
        Vue.set(state.productMerchandising, 'description', payload)
    },
    SET_PRODUCT_MERCHANDISING_FIELDS(state, payload) {
        // Trouver l'index de l'élément dans le tableau qui a le même id que le payload
        const index = state.productMerchandising.fields.findIndex(item => item.name === payload.name);
    
        if (index !== -1) {
            // Si un élément avec le même id est trouvé, mettre à jour cet élément
            Vue.set(state.productMerchandising.fields, index, payload);
        } else {
            // Si aucun élément correspondant n'est trouvé, ajouter le nouveau payload
            state.productMerchandising.fields.push(payload);
        }
    },
    
    SET_PRODUCT_MERCHANDISING_IMAGES(state,payload){
        Vue.set(state.productMerchandising, 'images', payload)
    }
}

const actions = {
    setProductMerchandising({commit},brandID){
        commit('SET_PRODUCT_MERCHANDISING_LOADING',true)
        CatalogueAPI.get(`${listRoutes.merchandisingGetSchema.replace(':brandID', brandID)}`)
        .then(response => {
            const merchandising = response.data.merchandising
            console.log('setProductMerchandising',merchandising)
            commit('SET_PRODUCT_MERCHANDISING', merchandising)
        })
        .finally(()=> {
            commit('SET_PRODUCT_MERCHANDISING_LOADING',false)
        })
    },
    
    updateProductMerchandising({commit},payload){
        const {brandID, values} = payload
        console.log(brandID,values)
        
        return CatalogueAPI.put(`${listRoutes.merchandisingUpdateSchema.replace(':brandID', brandID)}`,values)
            .then(response => {
                const merchandisingStatus = response.status
                commit('SET_PRODUCT_MERCHANDISING', values)
                if (merchandisingStatus >= 200 && merchandisingStatus < 300) return true
            })
            .catch(err => {
                commit('SET_NOTIFICATION_MESSAGE', {text:i18n.t(err.response.data.message), color:'red', confirmation: true})
            })
    },
    setProductMerchandisingTitle({commit},payload){
        commit('SET_PRODUCT_MERCHANDISING_TITLE', payload)
    },
    setProductMerchandisingDescription({commit},payload){
        commit('SET_PRODUCT_MERCHANDISING_DESCRIPTION', payload)
    },
    setProductMerchandisingFields({commit},payload){
        commit('SET_PRODUCT_MERCHANDISING_FIELDS', payload)
    },
    setProductMerchandisingImages({commit},payload){
        commit('SET_PRODUCT_MERCHANDISING_IMAGES', payload)
    }
}

const getters = {}

const productsMerchandising =  {
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default productsMerchandising