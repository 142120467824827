<template>
    <section class="filters-popin" :class="{'is-open': visible}" @click.self="$emit('close')">
        <div class="filters-popin__container">
            <div class="filters-popin__header">
                <TextTitle size="s">{{ $t('global.filter') }}</TextTitle>
                <Button icon="cross" color="white" @click.native.prevent="$emit('close')"/>
            </div>
            <div class="filters-popin__inner">
                <div>
                    <FilterField 
                        v-for="(filter, i) in filtersSelected"
                        :key="`${i}-${filter.key}-${filter.operator}-${filter.value}`"
                        :model="filter"
                        :filters="filters"
                        :no-translate="noTranslate"
                        :testid="`${filter.key}`"
                        @delete="removeField(i)"
                        @change="value => updateField(i, value)"/>
                        
                    <Button size="m" color="grey" icon="plus" @click.native.prevent="addFilter">{{ $t('global.add_filter') }}</Button>
                </div>
            </div>
            <div class="filters-popin__bottom">
                <!--
                <div class="filters-popin__selected" v-if="Object.keys(selectedFilters).length">
                    <Button v-for="(value, key) in selectedFilters" v-show="value !== null && value !== undefined" @click.native.prevent="e => $emit('change', {key: key, value: null})" icon="cross" size="s" color="grey" :key="key">{{$t(`global.${key}`)}}</Button>
                </div>
                -->
                <Button @click.native.prevent="$emit('close')" data-testid="filter-btn-validate">{{ $t('global.validate') }}</Button>
                <Button color="white" size="s" @click.native.prevent="resetFilters">{{ $t('global.reset') }}</Button>
            </div>
        </div>
    </section>
</template>

<script>
import TextTitle from '@/components/ui/text/TextTitle';
import Button from '@/components/ui/button/Button';
import FilterField from '@/components/ui/filters/FilterField';
import { mapState, mapActions } from 'vuex';

export default {
    name: 'FiltersPopin',
    components: {FilterField, Button, TextTitle},
    props: {
        visible: Boolean,
        filters: Array,
        noTranslate: Boolean
    },
    data() {
        return {
            otherUrlParams: ['limit', 'page', 'sortDirection', 'sortBy', 'search', 'dateStock'],
        }
    },
    computed: {
        ...mapState(['filtersSelected'])
    },
    watch:{
        filtersSelected() {
            this.updateRouteQuery();
        },
    },
    methods: {
        ...mapActions(['getFiltersFromUrl', 'updateFilter', 'resetFilters', 'addFilter', 'deleteFilter']),
        removeField(i) {
            this.deleteFilter(i);
        },
        updateField(i, field) {
            this.updateFilter({i, field});
        },
        updateRouteQuery() {
            let query = {...this.$route.query};
            query = Object.fromEntries(Object.entries(query).filter(([key]) => !key.includes(':')));
            this.filtersSelected.forEach(f => {
                const key = `${f.key}:${f.operator}`;
                const value = f.value;
                if (value === undefined || value === null || (Array.isArray(value) && !value.length)) delete query[key]
                // Cast to string because this.$route.query use string
                else query[key+''] = value;
            });
            if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
                this.$router.push({query: {...query}})
            }
        }
    },
    mounted() {
        this.getFiltersFromUrl({query: this.$route.query, filters: this.filters});

        window.addEventListener('popstate', () => {
            this.getFiltersFromUrl({query: this.$route.query, filters: this.filters});
        })
    }
}
</script>

<style lang="scss">
.filters-popin {
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    //background-color: rgba(247, 246, 242, 0.8);
    //backdrop-filter: blur(0.5rem);
    --popin-padding: 30px;
    transition: background-color ease-out .4s, backdrop-filter ease-out .4s;

    @media screen and (max-width: 800px) {
        --popin-padding: 15px;
    }

    &:not(.is-open) {
        pointer-events: none;
        background-color: transparent;
        backdrop-filter: none;

        .filters-popin__container {
            transform: translateX(calc(100% + 40px));
        }
    }

    &__selected {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
        margin-bottom: 10px;
        //margin: 15px var(--popin-padding);
    }

    &__container {
        background-color: #fff;
        width: 100%;
        max-width: 600px;
        display: flex;
        flex-direction: column;
        box-shadow: var(--box-shadow-2);
        transition: transform ease-out .4s;
    }

    &__header {
        padding: 15px var(--popin-padding);
        border-bottom: var(--border-1);
        text-align: left;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .button {
            min-width: 35px;
            min-height: 35px;
            padding: 0;
            border: var(--border-1);

            @media screen and (min-width: 1025px) {
                &:hover {
                    border: var(--border-2);
                }
            }

            span {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0;
            }

            svg {
                width: 12px;
            }
        }
    }

    &__inner {
        flex-grow: 1;
        overflow-y: auto;

        & > div {
            padding: var(--popin-padding);
            display: flex;
            flex-direction: column;
            gap: 15px
        }
    }

    .field {
        &__input {
            border: var(--border-1);
            transition: border-color ease-out .3s;
        }

        &__label {
            background-color: #fff;
            padding: 0 3px;
        }

        @media screen and (min-width: 1025px) {
            &:hover .field__input {
                border-color: #ccc;
            }
        }
    }

    &__bottom {
        padding: 15px var(--popin-padding);
        border-top: var(--border-1);
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        gap: 5px;

        & > * {
            width: 100%;
        }
    }

    .vc-pane {
        min-width: auto;
        width: 100% !important;
    }

    .input-calendar,
    .popin-calendar__inner {
        width: 100%;
    }
}
</style>