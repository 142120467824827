<template>
  <div class="cohorts-card__content">
    <TextTitle v-if="catalogueTitle" size="s" >{{ catalogueTitle }} </TextTitle>
    <div v-for="(line,index) in lines" :key="index"><strong>{{ fields[index] }} :</strong> {{ Object.values(line)[0]  }}</div>
  </div>
</template>

<script>
import TextTitle from '@/components/ui/text/TextTitle';

export default {
    props: {
        lines: {
            type: Array,
        },
        catalogueTitle: String
    },
    components: {
        TextTitle,
    },
    data() {
        return {
            
        }
    },
    computed: {
        fields() {
            return [
                `${this.$t('catalogue.lang')}`,
                `${this.$t('catalogue.file_name')}`,
                `${this.$t('catalogue.last_updated_at')}`,
            ]
        }
    },
}
</script>

<style lang="scss" scoped>
.cohorts-card {
    &__content {
        flex:1;
        margin-top: 5px;
        border-radius: var(--border-radius-1);
        div {padding: 7px;}
        div:nth-child(odd) { background: var(--color-bg-very-light);}
        div:nth-child(even) { background: var(--color-bg-light);}

        & > div:first-of-type {
            margin-top: 10px;
        }
    }
}
</style>