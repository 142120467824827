import API from '@/api/api-admin';
import listRoutes from '@/api/list-routes';
import router from '@/router';
import moment from 'moment';
import i18n from '@/i18n.js'

const state = {
    invoicesFolder: null,
    invoicesFolderLoading: false,

    invoice: null,
    invoiceLoading: false,

    invoiceLine: null,
    invoiceLineLoading: false,

    financeFolderGenerating: false,
}

const mutations = {
    SET_FINANCE_FOLDER_GENERATION(state, value) {
        state.financeFolderGenerating = value;
    },
    SET_INVOICES_FOLDER(state, data) {
        state.invoicesFolder = data
    },
    SET_INVOICES_FOLDER_LOADING(state, value) {
        state.invoicesFolderLoading = value
    },
    SET_INVOICE(state, data) {
        state.invoice = data
    },
    SET_INVOICE_LOADING(state, value) {
        state.invoiceLoading = value
    },
    SET_INVOICE_LINE(state, data) {
        if (data) {
            Object.keys(data).forEach(key => {
                let value = data[key];
                if (value && typeof value === 'number' && key !== 'id') data[key] = value / 100
            })
        }
        state.invoiceLine = data;
    },
    SET_INVOICE_LINE_LOADING(state, value) {
        state.invoiceLineLoading = value;
    }
}

const actions = {
    getInvoicesFolder({commit}, id) {
        commit('SET_INVOICES_FOLDER_LOADING', true);
        commit('SET_INVOICES_FOLDER', null)
        return new Promise((resolve, reject) => {
            API.get(listRoutes.invoicesFolder.replace(':id', id))
                .then(res => res.data)
                .then(data => {
                    commit('SET_INVOICES_FOLDER', data)
                    resolve()
                })
                .catch(err => reject(err))
                .finally(() => commit('SET_INVOICES_FOLDER_LOADING', false))
        })
    },
    
    getInvoice({commit}, id) {
        commit('SET_INVOICE_LOADING', true);
        commit('SET_INVOICE', null);
        API.get(listRoutes.invoice.replace(':id', id))
            .then(res => res.data)
            .then(data => commit('SET_INVOICE', data))
            .finally(() => commit('SET_INVOICE_LOADING', false))
    },
    
    createInvoice({commit},payload) {
        commit('SET_INVOICE_LOADING', true);
        commit('SET_INVOICE', null);
        return new Promise((resolve, reject) => {
            API.post(listRoutes.invoices, payload)
                .then(res => res.data)
                .then(data => {
                    commit('SET_INVOICE', data)
                    resolve(data)
                })
                .catch(() => reject('Une erreur est survenue'))
                .finally(() => commit('SET_INVOICE_LOADING', false))
            })
    },

    editInvoice({commit}, formData) {
        return new Promise((resolve, reject) => {
            commit('SET_INVOICE_LINE_LOADING', true);
            let payload = {};
            ['clientName', 'clientAddress', 'clientPostalCode', 'clientCity', 'clientCountry', 'clientAgent', 'invoiceType', 'status'].forEach(key => {
                payload[key] = formData[key] || '';
            });
            payload.invoiceDate = formData.invoiceDate ? moment.utc(formData.invoiceDate).format('YYYY-MM-DD HH:mm:ss') : ''
            payload.client = formData.client?.id;
            API.put(listRoutes.invoice.replace(':id', router.currentRoute.params.id), {...payload})
                .then(res => res.data)
                .then(data => {
                    commit('SET_INVOICE', data)
                    resolve(data)
                })
                .catch(err => reject(err.message || 'Une erreur est survenue'))
                .finally(() => commit('SET_INVOICE_LOADING', false))
        })
    },
    
    deleteInvoice({commit}, id){
        commit('SET_INVOICE_LOADING', true);
        return new Promise((resolve, reject) => {
            API.delete(listRoutes.invoice.replace(':id', id))
                .then(res => res.data)
                .then(data => {
                    commit('SET_INVOICE', null)
                    resolve(data)
                })
                .catch(err => reject(err.message || 'Une erreur est survenue'))
                .finally(() => commit('SET_INVOICE_LOADING', false))
            })

    }, 

    getInvoiceLine({commit, state}, id) {
        if (!state.invoiceLine || state.invoiceLine.id != id) {
            commit('SET_INVOICE_LINE_LOADING', true);
            commit('SET_INVOICE_LINE', null);
            API.get(listRoutes.invoicesLineEdition.replace(':id', id))
                .then(res => res.data)
                .then(data => {
                    commit('SET_INVOICE_LINE', data)
                })
                .finally(() => commit('SET_INVOICE_LINE_LOADING', false))
        } else {
            commit('SET_INVOICE_LINE_LOADING', false);
        }
    },

    editInvoiceLine({state,commit}, data) {
        return new Promise((resolve, reject) => {
            commit('SET_INVOICE_LINE_LOADING', true);
            const editLinePayload = {}
            for (const key in data) {
                const value = data[key];
                editLinePayload[key] = key === 'product' ? value : (Number(value) || 0) * 100
            }
            editLinePayload.taxAmount = editLinePayload.amountWithoutTax * 0.2;
            editLinePayload.amountWithTax = editLinePayload.amountWithoutTax * 1.2;
            delete editLinePayload.invoiceStatus;
            delete editLinePayload.invoiceType;
            API.put(listRoutes.invoicesLineEdition.replace(':id', state.invoiceLine.id), {...editLinePayload})
                .then(res =>  res.data)
                .then(editedLine => {
                    commit('SET_INVOICE_LINE', editedLine);
                    resolve();
                })
                .catch(() => {
                    reject("L'api ne fonctionne pas:", listRoutes.invoicesLineEdition)
                })
                .finally(() => commit('SET_INVOICE_LINE_LOADING', false))
        })
    },
 
    deleteInvoiceLine({commit}, id){
        return new Promise((resolve, reject) => {
            commit('SET_INVOICE_LINE_LOADING', true);
            //commit('SET_INVOICE_LINE', null);
            API.delete(listRoutes.invoicesLineEdition.replace(':id', id))
                .then(res => res.data)
                .then(data => {
                    //commit('SET_INVOICE_LINE', data)
                    return resolve(data)
                })
                .catch(() => reject('Une erreur est survenue'))
                .finally(() => commit('SET_INVOICE_LINE_LOADING', false))
            })

    },

    addLineToInvoice({commit}, data) {
        return new Promise((resolve, reject) => {
            commit('SET_INVOICE_LINE_LOADING', true);
            const payload = {}
            for (const key in data) {
                const value = data[key];
                payload[key] = key === 'product' ? value : (Number(value) || 0) * 100
            }
            API.post(listRoutes.invoiceLineAdd.replace(':id', router.currentRoute.params.id), payload)
                .then(res => res.data)
                .then(line => {
                    commit('SET_INVOICE_LINE', line);
                    resolve(line)
                })
                .catch(error => reject(error.message))
                .finally(() => {
                    commit('SET_INVOICE_LINE_LOADING', false)
                })
        })
    },

    regenerateFinanceFolder({commit}) {
        commit('SET_FINANCE_FOLDER_GENERATION', true);
        const id = router.currentRoute.params.id;
        API.get(listRoutes.invoicesFolderRegenerate.replace(':id', id))
            .finally(() => {
                API.get(listRoutes.invoicesFolder.replace(':id', id))
                    .then(res => res.data)
                    .then(data => commit('SET_INVOICES_FOLDER', data))
                    .finally(() => {
                        commit('SET_FINANCE_FOLDER_GENERATION', false);
                        commit('SET_NOTIFICATION_MESSAGE', i18n.t('finance_invoices_folder.regenerate_success'))
                    })
            })
    }
}

const getters = {

}

const invoices =  {
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default invoices