<template>
    <TemplateTable
            :title="$t('menu.resales-not-published')"
            name="resalesNotPublished"
            :url="$listRoutes.resalesWaitingPublication"
            :table="resalesNotPublished"
            :filters="resalesNotPublishedFilters"
            :fields="fields"
    />
</template>

<script>
import { mapState } from 'vuex';
import TemplateTable from '@/components/templates/TemplateTable';
export default {
    name: 'ResalesWaitingPublication',
    components: {TemplateTable},
    data() {
        return {
            fields: [
                {key: 'id', type: 'eye', route: 'resale', size: 'xs'},
                {key: 'client', type: 'client', size: 'l', noSortable: true},
                {key: 'user', type: 'userEmail', size: 'xl'},
                {key: 'shopifyId', type: 'shopifyProduct', size: 'm'},
                {key: 'createdAt', type: 'date'},
                {key: 'tradeinPrice', type: 'formatPrice'},
                {key: 'logisticsInformations.warehouseReceiveDate', type: 'date'},
                {key: 'logisticsInformations.warehouseOpenDate', type: 'date'},
                {key: 'logisticsInformations.warehouseValidationDate', type: 'date'},
                {key: 'logisticsInformations.warehouseShootingDate', type: 'date'},
                {key: 'hasCompo', type: 'boolean', noSortable: true},
                {key: 'logisticianCondition', type: 'tagType', noSortable: true},
                {key: 'irl', type: 'boolean'},
                {key: 'realStore', type: 'realStore', size: 'xxl'},
                {key: 'postShooting', noSortable: true},
                {key: 'country', size: 'xs', noSortable: true},
            ]
        }
    },
    computed: {
        ...mapState(['resalesNotPublished', 'resalesNotPublishedFilters'])
    }
}
</script>