<template>
    <div>
        <div class="filter-field">
            <FieldSelect 
                :border="true" 
                :selected="computedSelectedFilterName" 
                :options="availableFilterOptions" 
                @input="selectedFilterName = $event" />

                            
                <FieldSelect 
                    :disabled="!computedSelectedFilterName?.length"  
                    :selected="computedSelectedFilterOperator" 
                    :border="true"  
                    :options="operatorsForSelectedFilter" 
                    @input="selectedFilterOperator = $event" 
                />
                <span v-if="loading">LOADING</span>

                <FieldSelect 
                    v-if="computedSelectedFilterType === 'value_select'"
                    :disabled="!operatorsForSelectedFilter?.length && !loading"
                    :border="true" 
                    :selected="computedSelectedFilterValue"
                    :options="formattedValuesForSelectedFilter" 
                    @input="selectedValue = $event" />
            
                <Field
                    v-else
                    :disabled="!selectedFilterName?.length || computedSelectedFilterType === 'present_select'"
                    :placeholder="computedSelectedFilterType === 'present_select' ? this.$t('catalogue.present') : ''"
                    type="text"
                    :model="computedSelectedFilterTextValue"
                    @change="e => textValue = e"
                />
            <SvgIcon name="trash" 
                @click.native.prevent="emitDelete" />
            
        </div>
    </div>
    
</template>

<script>
import FieldSelect from '@/components/ui/form/fields/FieldSelect.vue';
import Field from '@/components/ui/form/fields/Field.vue';
import SvgIcon from '../svg/SvgIcon.vue';
import { mapActions, mapState } from 'vuex';

export default {
    name: 'CatalogueFilter',
    components: {
        FieldSelect,
        SvgIcon,
        Field
    },
    props: {
        model: Array,
        options: Array,
        apiLanguage: String,
        currentIndex: Number,
        filter: Object
    },
    data() {
        return {
            selectedFilterName:  '',
            selectedFilterOperator:  '',
            selectedValue:  '',
            textValue:  '',
            loading:false,
            debouncedChangeHandler: null,
        }
    },
    watch: {
        selectedFilterName(newValue) {
            if(newValue && this.currentFilterTypeIsValueSelect) {
                this.loadFilterValues(this.payloadForCatalogueFieldsListValues)
            }
        },
        payloadToEmit: {
            handler(newValue) {
                if(newValue?.value !== '' || newValue?.textValue !== 'null') {this.setCatalogueFilter(newValue)}
            },immediate:false,
        },
    },
    computed: {
        ...mapState([
            'catalogueFieldsListValues',
            'catalogueFieldsListValuesLoading',
        ]),
        computedFilter(){
            return this.filter
        },
        computedSelectedFilterName(){
            return this.computedFilter.name
        },
        computedSelectedFilterType(){
            return this.computedFilter.type
        },
        computedSelectedFilterOperator(){
            return this.computedFilter.operator
        },
        computedSelectedFilterValue(){
            return this.computedFilter.value
        },
        computedSelectedFilterTextValue(){
            return this.computedFilter.textValue
        },
        payloadForCatalogueFieldsListValues(){
            return {
                locale: this.apiLanguage,
                brand_id: this.$route.params.id,
                field: this.selectedFilterName
            }
        },
        availableFilterOptions() {
            return this.options?.map(elem => ({label:elem.label, id:elem.name, disabled:elem.disabled})) 
        },
        currentFilterDetails(){
            return this.model?.find(elem => elem.name === this.selectedFilterName)
        },
        operatorsForSelectedFilter(){
            return this.currentFilterDetails?.operators.map(elem => ({label:this.$t(`catalogue.${elem}`), id:elem})) 
        },
        typeOfSelectedFilter(){
            return this.selectedFilterName.length
                ? this.currentFilterDetails.filter_type
                : ''
        },
        valuesForSelectedFilter() {
            return this.catalogueFieldsListValues
        },
        valueArrayForSelectedFilter(){
            return this.selectedFilterName?.length 
                ? this.valuesForSelectedFilter?.[this.apiLanguage]?.[this.selectedFilterName]
                : []
        },
        formattedValuesForSelectedFilter(){
            return this.valueArrayForSelectedFilter?.map((elem) => {
                if(this.selectedFilterName === 'sellable' || this.selectedFilterName === 'tradable'){
                    return ({
                        label: elem === 0 || elem === '0' ? this.$t('global.no') : elem === 1 || elem === '1' ? this.$t('global.yes') : elem,
                        id: elem === 0 || elem === '0' ? this.$t('global.no') : elem === 1 || elem === '1' ? this.$t('global.yes') : elem
                    })
                } else {
                    return ({label:elem, id:elem})                    
                }
            })
        },

        valueToSend(){
            return this.formattedValuesForSelectedFilter?.find(elem => elem.id === this.selectedValue)?.label
        },
        payloadToEmit(){
            return {
                index:this.currentIndex,
                name:this.selectedFilterName,
                type:this.typeOfSelectedFilter,
                operator:this.selectedFilterOperator,
                value: this.valueToSend,
                textValue: this.textValue
            }
        },
        currentFilterTypeIsValueSelect(){
            return this.selectedFilterName?.length
                ? this.model?.find(elem => elem.name === this.selectedFilterName).filter_type === 'value_select' || this.model?.find(elem => elem.name === this.selectedFilterName).filter_type === 'present_select'
                : false
        },
    
    },
    methods: {
        ...mapActions([
            'catalogueGetFieldsListValues',
            'setCatalogueFilter'
        ]),
        loadFilterValues(){
            this.loading = true
            this.catalogueGetFieldsListValues(this.payloadForCatalogueFieldsListValues)
                .then(() => this.loading = false)
        },
        emitDelete() {
            this.$emit('delete', this.currentIndex);
        },
    },
    mounted () {
        this.selectedFilterName = this.computedSelectedFilterName || ''
        this.selectedFilterOperator = this.computedSelectedFilterOperator || ''
        this.selectedValue = this.computedSelectedFilterValue || ''
        this.textValue = this.computedSelectedFilterTextValue || ''
    },
    updated () {
        this.selectedFilterName = this.computedSelectedFilterName || ''
        this.selectedFilterOperator = this.computedSelectedFilterOperator || ''
        this.selectedValue = this.computedSelectedFilterValue || ''
        this.textValue = this.computedSelectedFilterTextValue || ''
    },

}
</script>

<style lang="scss" scoped>
.filter-field {
    & > * {
        margin-top: 0;
    }
    .field__input {
        padding: 0;
    }
    .disabled {
        pointer-events: none;
        opacity: 0.5;
    }
}
</style>