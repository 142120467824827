<template>
    <SectionCategories :categories="selectedCategories"
                       :selected="selectedCategory.name"
                       :legend1="$t('chart.period1')"
                       :legend2="legend2"
                       :display-legend="false"
                       @change="e => $emit('change', e)">

        <ChartLines :datasets="datasets" :axis="axis" data-testid="chart-lines-categories">

            <Field :options="periodOptions" :no-sort="true" :border="true" type="select" name="period" :model="period" @change="e => period = e"/>

        </ChartLines>

    </SectionCategories>
</template>

<script>
import ChartLines from '@/components/ui/chart/ChartLines';
import Field from '@/components/ui/form/fields/Field';
import SectionCategories from '@/components/sections/SectionCategories';
export default {
    name: 'GoldenmetricsChartLines',
    components: {SectionCategories, Field, ChartLines},
    props: {
        stats: Array,
        category: String,
        selectedCategories: Array,
        selectedCategory: Object,
        legend2: String
    },
    data() {
        return {
            period: 'days'
        }
    },
    computed: {
        axis() {
            if (!this.stats || !this.stats.length) return [];
            let keys = [];
            this.stats.forEach(stat => {
                const values = Object.keys(stat.details[this.period]);
                if (values.length > keys.length) keys = values
            });
            return keys
        },
        datasets() {
            if (!this.stats || !this.stats.length) return [];
            let options = [];
            this.stats.forEach((stat, i) => {
                const period = {
                    name: i === 1 && this.legend2 ? this.legend2 : `${this.$t('goldenmetrics.period')} ${i+1}`,
                    data: []
                }
                Object.values(stat.details[this.period]).forEach(day => {
                    period.data.push(day[this.category])
                });
                options.push(period)
            });
            return options
        },
        options() {
            return {
                ...this.parameters,
                xaxis: {
                    categories: this.axis
                }
            }
        },
        periodOptions() {
            if (!this.stats || !this.stats.length) return [];
            let options = [];
            ['days', 'weeks', 'quarters', 'months'].forEach(period => {
                if (Object.keys(this.stats[0].details[period]).length) {
                    options.push({
                        name: this.$t(`chart.${period}`),
                        value: period
                    })
                }
            })
            return options
        }
    },
    watch: {
        period(x) {
            this.$emit('updatePeriod', x)
        }
    }
}
</script>