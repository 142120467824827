<template>
    <div>
      <div
        v-show="menuOptions"
        class="ContextualMenu"
        :style="style"
        ref="menu"
        v-click-outside="resetCtx"
      >
        <template v-if="menuOptions">
          <span class="ContextualMenu__title" v-if="menuOptions.title">
            {{ menuOptions.title }}
          </span>
          <template v-for="(menuOption, index) in menuOptions.options">
            <div
              v-if="menuOption.type !== 'divider'"
              :key="index"
              :ref="`menu_option_item_${index}`"
              @click.prevent.stop="handleClick($refs[`menu_option_item_${index}`][0], menuOption )"
              class="ContextualMenu__option"
              :class="{
                'ContextualMenu__option--warning': menuOption.warning,
                'ContextualMenu__option--disabled': menuOption.disabled
              }"
            >
              <SvgIcon class="ContextualMenu__option--icon" v-if="menuOption.icon" :name="menuOption.icon"/>
              <span>{{ menuOption.title }}</span>
            </div>
            <div v-else :key="index+'i'" class="ContextualMenu__divider"><span></span></div>
          </template>
        </template>
      </div>
      <ContextualMenu v-if="hasGroup" :level="level+1" />
    </div>
  </template>
  
  <script>
  import SvgIcon from '@/components/ui/svg/SvgIcon.vue';
  
  export default {
    name: "ContextMenu",
    components: {
      "ContextualMenu":() => import('@/components/ui/contextuals/ContextualMenu'),
      SvgIcon
    },
    props:{
      level: {
        type: Number,
        default: 0
      }
    },
    data: () => ({
      style: null,
      menuOptions: null,
      payload: null,
      scrollY: window.scrollY,
      elTarget: null,
    }),
    computed: {
      hasGroup(){
          return this.menuOptions?.options?.some((item) => {
            return item.content
          });
      }
    },
    methods: {
  
      handleClick(el, menuOption){
        if( menuOption.handler ){
          menuOption.handler(this.payload);
          this.$root.$emit("ctx-menu-close");
        }else if( menuOption.content ){
          this.$root.$emit(`ctx-menu-${this.level+1}`, {
            el, 
            menuOptions: menuOption.content,
            payload: this.payload
          });
        }
      },
  
      resetCtx() {
        this.menuOptions = null;
        this.elTarget = null;
      },
  
      updateMenuData(data) {
        const { el, menuOptions, payload } = data;
        this.menuOptions = menuOptions;
        this.payload = payload;
        this.elTarget = el;
        this.computePosition();
      },
  
      computePosition(){
        if( !this.elTarget || !this.menuOptions ) return;
        window.requestAnimationFrame(() => {
          const targetBounding = this.elTarget.getBoundingClientRect();
          const menuBouding = this.$refs.menu.getBoundingClientRect();
          
          // check if the menu will be out of the window on Y axis
          const yReverse = menuBouding.height + targetBounding.top > window.innerHeight;
          const xReverse = menuBouding.width + targetBounding.left > window.innerWidth;
    
          const x = xReverse ? (targetBounding.x - menuBouding.width) : targetBounding.x + targetBounding.width;
          const y = yReverse ? (targetBounding.y + targetBounding.height -menuBouding.height) : targetBounding.y
    
          const offset = (xReverse ? -1 : 1) * 8;
  
          if( this.level == 0 ){
            //  For first level menu, place it the right of the element
            this.style = { 
              left: x + offset + "px", 
              top: y + this.scrollY + "px" ,
            };
          }else{
            // For submenus, open it anchored to right of the element (parent menu)
            this.style = { 
              left: x + "px", 
              top: y + this.scrollY + "px",
              transform: "translateX(-100%)"
            };
          }
        });
      },
  
      onKeyboardRelease(event){
        // if escape key is pressed, close the menu
        if( event.key === "Escape" ){
          this.resetCtx();
        }
      },
  
      handleScroll() {
        this.scrollY = window.scrollY;
        this.computePosition();
      },
    },
    mounted() {
      // Listen on contextmenu event through the $root instance
      const eventName = this.level ? `ctx-menu-${this.level}` : `ctx-menu`;
      this.$root.$on(eventName, data => {
        // if the data is null reset and handler the action
        if (data === null) {
          this.resetCtx();
        }else{
          this.updateMenuData({...data});
        } 
      });
  
      this.$root.$on('ctx-menu-close', ()=>{
        this.resetCtx();
      });
  
      document.body.addEventListener("keyup", this.onKeyboardRelease);
      
      // Add scroll event listener
      window.addEventListener("scroll", this.handleScroll);
    },
    beforeDestroy() {
      const eventName = this.level ? `ctx-menu-${this.level}` : `ctx-menu`;
      this.$root.$off(eventName);
      document.removeEventListener("keyup", this.onKeyboardRelease);
  
      // Remove scroll event listener
      window.removeEventListener("scroll", this.handleScroll);
    }
  };
  </script>
  
  <style lang="scss">
  .ContextualMenu {
    min-width: 150px;
    max-width: 300px;
    height: fit-content;
    position: absolute;
    background-color: #fff;
    box-shadow: 0 4px 24px 0 rgba(25, 25, 25, 0.1);
    border-radius: var(--border-radius-2);
    z-index: 1000;
    padding: 10px 8px;
  
    &__title{
      padding: 0 5px;
      margin-bottom: 10px;
      display: block;
      color: grey;
    }
  
    &__option {
      display: flex;
      column-gap: 5px;
      align-items: center;
      height: 35px;
      cursor: pointer;
      padding: 0 5px;
      border-radius: var(--border-radius-1);
      gap: 10px;
      margin-bottom: 5px;
  
      &--warning{
        color: var(--color-red2);
        stroke: var(--color-red2);
      }
      &--disabled{
        opacity: 0.5;
        pointer-events: none;
      }
  
      &--icon{
        svg {
          height: 16px;
          width: 16px;
        }
      }
    
      &:hover {
        background-color: #F8F8F8;
      }
    }
  
    &__divider {
      height: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      & > span {
        width: 50%;
        height: 1px;
        background-color: #dcdfe6;
      }
    }
  }
  </style>