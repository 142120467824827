<template>
    <TemplateDetails :data="catalogueCollections" :loading="catalogueCollectionsLoading" :get-function="getDatas">
        <div class="catalogue-collections">
            <PageBack/>
            <PageHeader :title="title">
                <template v-slot:actions>
                    <FieldSelect 
                        v-if="isAdmin && isRouteIdIsUndefined"
                        :options="optionsForClientsSelectField" 
                        :selected="computedClientID" 
                        @input="clientID = $event"/>
                    <ButtonLink 
                        :to="linkToCollections"  
                        icon="right" 
                        color="grey" 
                        size="m">{{ $t('collections.collections_create') }}</ButtonLink>
                </template>
            </PageHeader>
            <div v-if="catalogueCollections && catalogueCollections.length > 0">
                <CollectionsListing
                    v-for="(collection,index) in catalogueCollections" 
                    :key="index" :index="index" :collection="collection"
                    :defaultLocale="defaultLocale"
                    :brandID="computedClientID"
                />
            </div>
            <div v-else>
                <TextSimple weight="bold" size="l">{{ $t('collections.no_collections') }}</TextSimple>
            </div>
        </div>
    </TemplateDetails>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import TemplateDetails from '@/components/templates/TemplateDetails.vue';
import PageBack from '@/components/ui/page/PageBack.vue';
import PageHeader from '@/components/ui/page/PageHeader.vue';
import ButtonLink from '@/components/ui/button/ButtonLink.vue';
import CollectionsListing from '@/components/ui/collections/CollectionsListing.vue';
import TextSimple from '@/components/ui/text/TextSimple.vue';
import FieldSelect from '@/components/ui/form/fields/FieldSelect.vue';
export default {
    name:'CatalogueCollections',
    components: {
        TemplateDetails,
        PageBack,
        PageHeader,
        ButtonLink,
        CollectionsListing,
        TextSimple,
        FieldSelect
    },
    watch: {
        clientID(newValue) {
            if(newValue) {
                this.catalogueGetAllCollections(newValue)
                this.getCatalogueDefaultLocale(newValue)
                this.$router.push({name:'config-website-catalogues-collections-listing', params:{brandID:this.computedClientID}})
            }
        }
    },
    data() {
        return {
            clientID: null
        }
    },
    computed: {
        ...mapState([
            'catalogueCollection',
            'catalogueCollections',
            'catalogueCollectionsLoading',
            'catalogueDefaultLocale',
            'collectionsClients',
            'user',
            'temporaryUser'
        ]),
        ...mapGetters([
            'isAdmin'
        ]),
        id() {
            return this.$route.params.brandID || this.user?.clients?.[0]?.id || this.temporaryUser?.clients?.[0]?.id || 1
        },
        isRouteIdIsUndefined() {
            return typeof this.$route?.params?.id === 'undefined'
        },
        computedClientID(){
            return this.clientID || this.id
        },
        client() {
            return this.$store.getters.getClientById(this.computedClientID)
        },
        title(){
            return `${this.$t('collections.collections_list')} ${this.client.name}`
        },
        defaultLocale(){
            return this.catalogueDefaultLocale?.defautLocale
        },
        optionsForClientsSelectField(){
            return this.collectionsClients?.length 
                ? this.collectionsClients.map(client => ({id: client.id, label: client.name})).sort((a,b) => a.label.localeCompare(b.label))
                : []
        },
        linkToCollections(){
            return {name:'config-website-catalogue-collections-create', params:{lang:this.defaultLocale, id:this.computedClientID}}
        }
    },
    methods: {
        ...mapActions([
            'catalogueGetAllCollections',
            'getCatalogueDefaultLocale',
            'catalogueCollectionRemoveCondition',
            'fetchCollectionsClients'
        ]),
        async getDatas(){
            await this.getCatalogueDefaultLocale(this.id)
            await this.catalogueGetAllCollections(this.id);
            this.isRouteIdIsUndefined && this.isAdmin && this.fetchCollectionsClients()
        }
    },
    
}
</script>

<style lang="scss" scoped>
.catalogue-collections {
    .field-select {
        margin-top: 0;
    }
}
</style>>
