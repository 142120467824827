<template>
    <TemplateTable
            :title="$t('menu.stock-bad')"
            name="warehouseStocksRefused"
            :url="$listRoutes.warehouseStocks.replace(':stage', 'refused')"
            :table="warehouseStocksRefused"
            :filters="warehouseStocksRefusedFilters"
            :fields="fields"
    />
</template>

<script>
import { mapState } from 'vuex';
import TemplateTable from '@/components/templates/TemplateTable';
export default {
    name: 'WarehouseStocks',
    components: {TemplateTable},
    data() {
        return {
            fields: [
                {key: 'id', type: 'eye', route: 'resale', size: 'xs'},
                {key: 'client', type: 'client', size: 'l'},
                {key: 'user', type: 'userEmail', size: 'xl'},
                {key: 'shopifyId', type: 'shopifyProduct', size: 'm'},
                {key: 'createdAt', type: 'date'},
                {key: 'logisticsInformations.warehouseStockDate', type: 'date'},
                {key: 'logisticianCondition', type: 'tagType'},
                {key: 'tradeinPrice', type: 'formatPrice'},
                {key: 'warehouse.name', translationKey: 'warehouse', size: 'm'},
                {key: 'slot', translationKey: 'location'},
                {key: 'country', size: 'xs'},
            ]
        }
    },
    computed: {
        ...mapState(['warehouseStocksRefused', 'warehouseStocksRefusedFilters'])
    }
}
</script>