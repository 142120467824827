import Products from '@/components/pages/admin/products/Products';
import Collections from '@/components/pages/admin/products/Collections';

const productsRoutes = [
    {
        path: '/admin/produits/tous-les-produits',
        name: 'products',
        component: Products,
        meta: {
            title: 'products',
            icon: 'tshirt',
            roles: ['ROLE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/produits/collections',
        name: 'collections',
        component: Collections,
        meta: {
            title: 'collections',
            roles: ['ROLE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/produits/*',
        redirect: {
            name: 'products'
        }
    },
]

export default productsRoutes