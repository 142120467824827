<template>
    <TemplateDetails :data="client" :loading="clientLoading" :get-function="getCatalogueCreationDatas">
        <PageBack/>
        <PageHeader :title="title"></PageHeader>         
        <Form class="catalogue-create-form" 
            :fields="reorderedFields" 
            :model="model" 
            :action="createCatalogue" 
            :filetext="$t('catalogue.form_csv')"
            :required="true"
            @success="e => createCatalogueSuccess(e)" 
            @form-before-submit="$refs.popinProgressCatalogue.open()"
        />

        <Popin :title="catalogueCreation ? $t('catalogue.create_success') : $t('catalogue.fail')" ref="popinCreateCatalogue">
            <template v-slot:bottom>
                <ButtonLink :to="{name: 'config-website-catalogue-mapping', params:{lang:catalogueLang, catalogueID: catalogueCreationID}}" :loading="addLoading" color="green" size="m" >{{ $t('catalogue.next_step') }}</ButtonLink>
            </template>
        </Popin>

        <Popin :title="$t('catalogue.catalogue_upload')" ref="popinProgressCatalogue">
            <template v-slot:bottom>
                <ProgressBar 
                    :start_value="catalogueProgress.totalLinesProcessed" 
                    :start_text="'Total MB chargé'" 
                    :total_value="catalogueProgress.totalLines" 
                    :total_text="'Total MB'" 
                    :progression="getCatalogueProgressStateValues" 
                />
                <InPopinLoader
                    v-if="getCatalogueProgressStateValues === '100%'"
                    :msg="$t('catalogue.please_wait_copy')" 
                    :loading="catalogueLoading" 
                />
            </template>
        </Popin>
    </TemplateDetails>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import TemplateDetails from '@/components/templates/TemplateDetails.vue';
import PageHeader from '@/components/ui/page/PageHeader.vue';
import PageBack from '@/components/ui/page/PageBack.vue';
import Popin from '@/components/ui/popin/Popin.vue';
import InPopinLoader from '@/components/ui/popin/InPopinLoader.vue';
import ButtonLink from '@/components/ui/button/ButtonLink.vue';
import Form from '@/components/ui/form/Form.vue';
import ProgressBar from '@/components/ui/progress/ProgressBar.vue';

export default {
    name: 'ConfigWebsiteCatalogueCreate',
    components: { 
        TemplateDetails,
        PageHeader,
        PageBack,
        Popin,
        InPopinLoader,
        ButtonLink,
        Form,
        ProgressBar,
    },
    data() {
        return {
            addLoading:false,
            model: {
                file: null,
                title: null,
                lang: null,
                delimiter: null,
                nature: 'reference',  
                header:1,
                client_id:parseInt(this.$route.params.id),
            },
            errorFileFormat:false,
            catalogueCreation:false,
            catalogueCreationID:null,
            catalogueLang:null,
        }
    },
    computed: {
        ...mapState([
            'clients',
            'catalogueProgress',
            'catalogueLoading',
            'catalogueLocales'
        ]),
        ...mapGetters([
            'getCatalogueProgressStateValues',
            'isAdmin'
        ]),
        id() {
            return this.$route.params.id
        },
        client() {
            return this.$store.getters.getClientById(this.id)
        },
        clientLoading(){
            return Object.keys(this.client).length ? true : false
        },
        title(){
            return !this.catalogueID ? `${this.$t('catalogue.create')} ${this.client.name}` : `${this.$t('catalogue.update')} ${this.client.name}`
        },
        clientLocalesOptions(){
            return this.catalogueLocales 
                ? this.catalogueLocales.map(elem => {
                    return {name:`${this.$t('locales')[elem.split('_')[0]]}`, value:elem}
                })
                : null
        },
        clientSelectLang(){
            return {
                name: "lang",
                label:"lang",
                type: "select",
                required: true,
                options: this.clientLocalesOptions
                }
        },
        baseFields(){ 
            return [
                {
                    name: "title", type: 'text', label:"title", required: true
                },
                {
                    name: "delimiter",
                    label:"delimiter",
                    type: "select",
                    required: true,
                    options: [
                        {name:`${this.$t('catalogue.create_comma')}`, value:','},
                        {name:`${this.$t('catalogue.create_semicolon')}`, value:';'},
                        {name:`${this.$t('catalogue.create_tab')}`, value:'\t'},
                        {name:`${this.$t('catalogue.create_pipe')}`, value:'|'},
                    ],
                },
                {
                    name: "file",  
                    type: 'file', 
                    label:"title", 
                    required: true,
                    file_text: `${this.$t('catalogue.form_csv')}`,
                    accept:['.csv']
                },
            ]
        },
        natureFieldsIfIsAdmin(){
            return {
                    name: "nature",
                    label:"nature",
                    type: "select",
                    required: true,
                    options: [
                        {name:`${this.$t('catalogue.reference')}`, value:'reference'},
                        {name:`${this.$t('catalogue.segment')}`, value:'segment'} ,
                    ],
                }
        },
        fieldsForForm(){
            return this.isAdmin
                ? [this.natureFieldsIfIsAdmin,...this.baseFields,this.clientSelectLang]
                : [...this.baseFields,this.clientSelectLang]
        },
        reorderedFields() {
            // Find the index of the "title" object
            const titleIndex = this.fieldsForForm.findIndex(item => item.name === "title");
            // Find the index of the "lang" object
            const langIndex = this.fieldsForForm.findIndex(item => item.name === "lang");

            if (titleIndex !== -1 && langIndex !== -1) {
            // Create a copy of the original array
            const newArray = [...this.fieldsForForm];

            // Remove the "lang" object from its current position
            const langObject = newArray.splice(langIndex, 1)[0];

            // Insert the "lang" object after the "title" object
            newArray.splice(titleIndex + 1, 0, langObject);

            return newArray;
            }

            // Return the original array if either "title" or "lang" is not found
            return this.originalArray;
        }
        },
    methods: {
        ...mapActions([
            'fetchCatalogues',
            'createCatalogue',
            'getClient',
            'getCatalogueLocales'
        ]),
        createCatalogueSuccess(data){
            if(data){
                this.$refs.popinProgressCatalogue.close()
                this.catalogueCreation = true
                this.$refs.popinCreateCatalogue.open();
                this.catalogueCreationID = data.id
                this.catalogueLang = data.lang
            }
        },
        getCatalogueCreationDatas() {
            this.getClient()
            this.getCatalogueLocales(this.id)
        },

    },
    mounted () {
        this.$store.commit('SET_CATALOGUE_PROGRESS_BAR', {totalLines:0, totalLinesProcessed:0})
    },

}
</script>
<style lang="scss" scoped>
    .catalogue-create-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 440px;
    }
</style>