<template>
    <div class="template-goldenmetrics">

        <PageHeader :title="title"/>

        <PageFilters>
            <template v-slot:left>

                <FieldSearchSelect v-if="countriesOptions && countriesOptions.length"
                        :model="selectecCountries"
                        :options="countriesOptions"
                        :label="$t('global.country')"
                        @change="e => selectecCountries = !e.length ? ['ALL-COUNTRIES'] : e"/>

                <FieldDate v-if="!isPeriod" :disabled-today="true" :label="$t('global.date')" :model="period1Start" @change="e => {period1Start = e; getMetrics()}"/>

                <FieldPeriod v-if="isPeriod"
                        :start="period1Start"
                        :end="period1End"
                        :highlight="rangePeriod2"
                        :label="$t('goldenmetrics.period1')"
                        :disabled-today="true"
                        :dataTestid="'popin-calendar1'"
                        @change="e => {period1Start = e.start; period1End = e.end; updatePeriod2(e.comparaison); getMetrics()}"/>

                <FieldPeriod v-if="isPeriod"
                        :start="period2Start"
                        :end="period2End"
                        :highlight="rangePeriod1"
                        :label="$t('goldenmetrics.compare')"
                        :disabled-today="true"
                        :hide-suggestion="true"
                        :dataTestid="'popin-calendar2'"
                        :text="type !== 'period' ? $t(`${type === 'none' ? 'global' : 'goldenmetrics'}.${type}`) : null"
                        @change="e => {period2Start = e.start; period2End = e.end; getMetrics()}">
                    <li :class="{'is-active': type === 'period'}" @click.prevent="type = 'period'">{{ $t('global.custom') }}</li>
                    <li v-if="false" :class="{'is-active': type === 'benchmark'}" @click.prevent="type = 'benchmark'">{{ $t('goldenmetrics.benchmark') }}</li>
                    <li :class="{'is-active': type === 'none'}" @click.prevent="type = 'none'">{{ $t('global.none') }}</li>
                </FieldPeriod>

            </template>

            <template v-slot:right>
                <Button icon="config" color="grey" @click.native.prevent="$refs.popinMetrics.open()" data-testid="popin-filter-categorie">{{ $t('metrics.title') }}</Button>
                <Button icon="download" color="grey" @click.native.prevent="exportData" data-testid="popin-filter-export"/>
            </template>

        </PageFilters>

        <PopinMetrics ref="popinMetrics" :fields="selectedCategories" :all-fields="allCategories" @change="e => selectedCategories = e"/>

        <FieldSearchSelect v-if="allowedClients && !isClient" :model="selectedClients" :label="$t('global.client')"
                           :options="allowedClients.map(c => ({name: c.name, value: c.id}))"
                           @change="e => selectedClients = e"/>

        <PageLoader v-if="!goldenMetrics"/>

        <div v-else>

            <Checkerboard data-testid="checkerboard">
                <CheckerboardItem
                        v-for="category in selectedCategories"
                        :key="category.name"
                        :set="stat = getEvolutionStatistic(category.name)"
                        :statistic="stat"
                        :statistic-color="category.name.includes('return') ? stat > 0 ? 'red' : 'green' : null"
                        :image="category.icon ? `/img/goldenmetrics/${category.icon}.svg` : null"
                        :info="getInfo(category.name)"
                        :title="goldenMetrics && goldenMetrics.sum && goldenMetrics.sum[0] ? goldenMetrics.sum[0][category.name] : null | goldenMetrics(category.format)"
                        :subtitle="getTitle(category.name)"/>
            </Checkerboard>


            <GoldenmetricsChartLines v-if="allowedGoldenMetricsClients && allowedGoldenMetricsClients.length === 1 && isPeriod"
                                     :selected-categories="selectedCategories"
                                     :selected-category="selectedCategory"
                                     :stats="allowedGoldenMetricsClients[0][selectecCountries[0]].stats.slice(0, displayStatistic ? 2 : 1)"
                                     :category="selectedCategory?.name"
                                     :legend2="legend2"
                                     ref="clientChart"
                                     @updatePeriod="e => chartPeriodSelected = e"
                                     @change="e => selectedCategory = allCategories.find(c => c.name === e)"/>

            <SectionCategories v-else-if="isPeriod || (!isPeriod && allowedGoldenMetricsClients.length > 1)"
                               :categories="selectedCategories"
                               :selected="selectedCategory.name"
                               :legend1="$t('chart.period1')"
                               :legend2="legend2"
                               @change="e => selectedCategory = allCategories.find(c => c.name === e)">

                <ChartBarRow v-for="client in allowedGoldenMetricsClients"
                             :key="client.client.name + selectecCountries[0] + selectedCategory.name"
                             :text="client.client.name"
                             :statistic="getClientStatistic(client)"
                             :max="categoryMaxValue"
                             :format="selectedCategory.format"
                             :category="selectedCategory.title"
                             :legend1="$t('chart.period1')"
                             :legend2="legend2"
                             :value1="getPeriodValue(client,0)"
                             :value2="displayStatistic ? getPeriodValue(client,1) : null"/>

            </SectionCategories>

            <TableSticky v-if="allowedGoldenMetricsClients?.length === 1" class="template-goldenmetrics__sticky--client" :class="{'template-goldenmetrics__sticky--periods': displayStatistic && isPeriod}" :fields="[{key: 'client', size: 'l'},{key: 'period', translation: 'goldenmetrics.period'},...selectedCategories.map(c => ({key: c.name, translation: `goldenmetrics.${c.name}`}))]">
                <div>
                    <TableStickyRow>
                        <Cell :value="allowedGoldenMetricsClients[0].client.name" tag="div" class="is-size--l"/>
                        <Cell :value="$t('goldenmetrics.period1')" tag="div"/>
                        <Cell v-for="category in selectedCategories" :key="category.name" :value="getClientsStatSum(0, category.name)" :type="category.format" tag="div"/>
                    </TableStickyRow>
                    <TableStickyRow v-if="isPeriod && displayStatistic">
                        <Cell value=" " class="is-size--l" tag="div"/>
                        <Cell v-if="false" :value="$t(`goldenmetrics.${type === 'benchmark' ? 'benchmark' : 'period2'}`)" tag="div"/>
                        <Cell v-for="category in selectedCategories" :key="category.name" :value="getClientsStatSum(1, category.name)" :type="category.format" tag="div"/>
                    </TableStickyRow>
                </div>
                <div v-for="(day, i) in Object.keys(allowedGoldenMetricsClients[0][selectecCountries[0]].stats[0].details[chartPeriodSelected]).slice(0, 31)" :key="i">
                    <TableStickyRow>
                        <Cell value=" " tag="div" class="is-size--l"/>
                        <Cell :value="day" tag="div"/>
                        <Cell v-for="category in selectedCategories" :key="category.name" :value="getClientStatDetails(0, day, category.name)" :type="category.format" tag="div"/>
                    </TableStickyRow>
                    <TableStickyRow v-if="isPeriod && displayStatistic" :set="date = Object.keys(allowedGoldenMetricsClients[0][selectecCountries[0]].stats[1].details[chartPeriodSelected])[i]">
                        <Cell value=" " tag="div" class="is-size--l"/>
                        <Cell v-if="false" :value="type === 'benchmark' ? $t('goldenmetrics.benchmark') : date" tag="div"/>
                        <Cell v-for="category in selectedCategories" :key="category.name" :value="!date ? null : getClientStatDetails(1, date, category.name)" :type="date ? category.format : null" tag="div"/>
                    </TableStickyRow>
                </div>
            </TableSticky>

            <TableSticky v-else-if="allowedGoldenMetricsClients.length" :class="{'template-goldenmetrics__sticky--periods': displayStatistic && isPeriod}" :fields="[{key: 'client', size: 'l'},{key: 'period', translation: 'goldenmetrics.period'},...selectedCategories.map(c => ({key: c.name, translation: `goldenmetrics.${c.name}`}))]">
                <div v-for="(client, i) in allowedGoldenMetricsClients" :key="i">
                    <TableStickyRow>
                        <Cell :value="client.client.name" tag="div" class="is-size--l"/>
                        <Cell :value="period1Start" type="date" tag="div"/>
                        <Cell v-for="category in selectedCategories" :key="category.name" :value="getClientStatSum(client, 0, category.name)" :type="category.format" tag="div"/>
                    </TableStickyRow>
                    <TableStickyRow v-if="displayStatistic && isPeriod">
                        <Cell value=" " class="is-size--l" tag="div"/>
                        <Cell v-if="type === 'benchmark'" :value="$t('goldenmetrics.benchmark')" tag="div"/>
                        <Cell v-else :value="period2Start" type="date" tag="div"/>
                        <Cell v-for="category in selectedCategories" :key="category.name" :value="getClientStatSum(client, 1, category.name)" :type="category.format" tag="div"/>
                    </TableStickyRow>
                </div>
            </TableSticky>


        </div>
    </div>
</template>

<script>
import moment from 'moment';
import PageHeader from '@/components/ui/page/PageHeader';
import Checkerboard from '@/components/ui/checkerboard/Checkerboard';
import CheckerboardItem from '@/components/ui/checkerboard/CheckerboardItem';
import { mapActions, mapState, mapGetters } from 'vuex';
import PageLoader from '@/components/ui/page/PageLoader';
import PageFilters from '@/components/ui/page/PageFilters';
import ChartBarRow from '@/components/ui/chart/ChartBarRow';
import SectionCategories from '@/components/sections/SectionCategories';
import FieldPeriod from '@/components/ui/form/fields/FieldPeriod';
import FieldSearchSelect from '@/components/ui/form/fields/FieldSearchSelect';
import PopinMetrics from '@/components/ui/popin/PopinMetrics';
import TableSticky from '@/components/ui/table-sticky/TableSticky';
import TableStickyRow from '@/components/ui/table-sticky/TableStickyRow';
import Cell from '@/components/ui/table/cell/TableCell';
import FieldDate from '@/components/ui/form/fields/FieldDate';
import Button from '@/components/ui/button/Button';
import GoldenmetricsChartLines from '@/components/ui/goldenmetrics/GoldenMetricsChartLines';

export default {
    name: 'TemplateGoldenmetrics',
    components: {GoldenmetricsChartLines, Button, FieldDate, Cell, TableStickyRow, TableSticky, PopinMetrics, FieldSearchSelect, FieldPeriod, SectionCategories, ChartBarRow, PageFilters, PageLoader, CheckerboardItem, Checkerboard, PageHeader},
    props: {
        title: String,
        categories: Array,
        route: String,
        isPeriod: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            chartPeriodSelected: 'days',
            type: 'period',
            selectecCountries: ['ALL-COUNTRIES'],
            period1Start: this.isPeriod ? moment().subtract(7, 'd').startOf('isoWeek').format('YYYY-MM-DD') : moment().subtract(1, 'd').format('YYYY-MM-DD'),
            period1End: moment().subtract(7, 'd').endOf('isoWeek').format('YYYY-MM-DD'),
            period2Start:  moment().subtract(14, 'd').startOf('isoWeek').format('YYYY-MM-DD'),
            period2End: moment().subtract(14, 'd').endOf('isoWeek').format('YYYY-MM-DD'),
            selectedCategory: this.categories[0],
            allCategories: this.categories.map(c => ({...c, title: this.$t(`goldenmetrics.${c.name}`)})),
            selectedClients: [],
            selectedCategories: this.categories.map(c => ({...c, title: this.$t(`goldenmetrics.${c.name}`)})).slice(0, 15)
        }
    },
    computed: {
        ...mapState(['goldenMetrics', 'clients', 'goldenMetricsListClients', 'countries']),
        ...mapGetters(['isClient']),
        locale() {
            return this.$i18n.locale
        },
        countriesOptions() {
            return this.countries ? [...this.countries.map(country => ({value: country.code, name: this.$t(`countries_code.${country.code}`)})), {name: this.allCountries, value: 'ALL-COUNTRIES'},] : []
        },
        allCountries() {
          return this.$t('global.all_countries')
        },
        displayStatistic() {
            return this.type !== 'none' && this.isPeriod
        },
        legend2() {
            return this.type === 'none' ? null : this.type === 'benchmark' ? this.$t('goldenmetrics.benchmark') : this.$t('chart.period2')
        },
        evolution() {
            return this.$store.getters.sumEvolPercent
        },
        goldenMetricsClients() {
            return this.goldenMetrics ? this.goldenMetrics.clients : []
        },
        allowedGoldenMetricsClients() {
            return this.goldenMetricsClients?.filter(c => this.selectecCountries.some(country => c[country])) || [];
        },
        allowedClients() {
            if (!this.goldenMetricsListClients) return [];
            if (!this.selectecCountries || (this.selectecCountries.length === 1 && this.selectecCountries[0] === 'ALL-COUNTRIES')) return this.goldenMetricsListClients;
            return this.goldenMetricsListClients.filter(c => c.countries.find(c => this.selectecCountries.includes(c.code)))
        },
        categoryMaxValue() {
            let max = 0;
            let value;
            this.allowedGoldenMetricsClients?.forEach(client => {
                if (client) {
                    this.selectecCountries.forEach(country => {
                        [0, 1].forEach(i => {
                            if (client[country]?.stats[i]?.sum[this.selectedCategory.name]) {
                                value = client[country].stats[i].sum[this.selectedCategory.name];
                                if (value && value > max) max = value;
                            }
                        });
                    });
                }
            });
            return max * 1.2;
        },
        rangePeriod1() {
            return {
                start: moment(this.period1Start, 'YYYY-MM-DD').toDate(),
                end: moment(this.period1End, 'YYYY-MM-DD').toDate()
            }
        },
        rangePeriod2() {
            return {
                start: moment(this.period2Start, 'YYYY-MM-DD').toDate(),
                end: moment(this.period2End, 'YYYY-MM-DD').toDate()
            }
        },
        allCountriesSelected() {
            return this.selectecCountries?.length === 1 && this.selectecCountries[0] === 'ALL-COUNTRIES'
        }
    },
    methods: {
        ...mapActions(['getGoldenMetrics', 'exportGoldenMetrics', 'getGoldenMetricsClients', 'getCountries']),
        getFilters() {
            let filters = [];
            const clients = this.selectedClients.length ? this.selectedClients : this.allowedClients.map(c => c.id);
            filters.push(`clients=${clients.join(',')}`);
            filters.push(`comparison[type]=${this.type}`);
            filters.push(`countries=${this.selectecCountries.join(',')}`);
            filters.push(`start=${this.period1Start}`);
            filters.push(`end=${this.isPeriod ? this.period1End : this.period1Start}`);
            if (this.type === 'period' && this.isPeriod) {
                filters.push(`comparison[start]=${this.period2Start}`);
                filters.push(`comparison[end]=${this.period2End}`);
            }
            return filters.join('&')
        },
        getTitle(key) {
            return key ? this.$t(`goldenmetrics.${key}`) : null;
        },
        getInfo(key) {
            return key ? this.$t(`goldenmetrics.${key}Desc`) : null;
        },
        getEvolutionStatistic(key) {
            return this.evolution && this.displayStatistic ? this.evolution[key] : null
        },
        getPeriodValue(client, i) {
            if (!client) return null;
            let sum = 0;
            this.selectecCountries.forEach(country => {
                if (client[country]?.stats[i]?.sum) {
                    const value = client[country].stats[i].sum[this.selectedCategory.name];
                    if (value !== undefined) {
                        sum += value;
                    }
                }
            });
            return sum;
        },
        getClientStatistic(client) {
            if (!client || !this.displayStatistic) return null;
            let sumEvolPercent = 0;
            this.selectecCountries.forEach(country => {
                if (client[country]?.evol_percent[this.selectedCategory.name]) {
                    sumEvolPercent += client[country].evol_percent[this.selectedCategory.name];
                }
            });
            return sumEvolPercent;
        },
        getMetrics() {
            this.getGoldenMetrics({
                route: this.route,
                filters: this.getFilters()
            })
        },
        exportData() {
            this.exportGoldenMetrics({
                route: this.route,
                filters: this.getFilters(),
                lang: `${this.$i18n.locale}_${this.$i18n.locale.toUpperCase()}`
            })
        },
        updatePeriod2(comparaison) {
            if (comparaison && comparaison.start && comparaison.end) {
                this.period2Start = comparaison.start;
                this.period2End = comparaison.end;
            } else {
                const start = moment(this.period1Start, 'YYYY-MM-DD');
                const end = moment(this.period1End, 'YYYY-MM-DD');
                const days = end.diff(start, 'days') + 1;
                this.period2Start = start.clone().subtract(days, 'd').format('YYYY-MM-DD');
                this.period2End = start.clone().subtract(1, 'd').format('YYYY-MM-DD');
            }
        },
        getClientStatSum(client, index, category) {
            let value = 0;
            this.selectecCountries.forEach(c => {
                if (client[c]?.stats[index]?.sum[category]) value += client[c].stats[index].sum[category]
            });
            return value
        },
        getClientStatDetails(stat, day, category) {
            let value = 0;
            this.selectecCountries.forEach(c => {
                if (this.allowedGoldenMetricsClients[0][c]?.stats[stat]?.details) {
                    if (category) value += this.allowedGoldenMetricsClients[0][c].stats[stat].details[this.chartPeriodSelected][day][category]
                    else value += this.allowedGoldenMetricsClients[0][c].stats[stat].details[this.chartPeriodSelected][day]
                }
            })
            return value
        },
        getClientsStatSum(stat, category) {
            let value = 0;
            this.selectecCountries.forEach(c => {
                if (this.allowedGoldenMetricsClients[0] && this.allowedGoldenMetricsClients[0][c]?.stats[stat]?.sum[category]) {
                    value += this.allowedGoldenMetricsClients[0][c].stats[stat].sum[category]
                }
            })
            return value
        }
    },
    watch: {
        selectecCountries() {
            this.getMetrics();
        },
        selectedClients() {
            this.getMetrics()
        },
        type() {
            if (this.type !== 'period') {
                this.period2Start = null;
                this.period2End = null;
            } else {
                this.updatePeriod2();
            }
            this.getMetrics()
        },
        locale() {
            this.allCategories = this.categories.map(c => ({...c, title: this.$t(`goldenmetrics.${c.name}`)}));
            this.selectedCategories = this.categories.map(c => ({...c, title: this.$t(`goldenmetrics.${c.name}`)})).slice(0, 15)
        }
    },
    async beforeMount() {
        if (!this.countries) await this.getCountries();
        await this.getGoldenMetricsClients()
        this.getMetrics();
    }
}
</script>

<style lang="scss">
.template-goldenmetrics {

    .filters__left .form-field-search-select {
        width: 180px;

        .field__input {
            display: flex;
        }

        input {
            display: none;
        }

        &.field.has-icon .field__input {
            padding-right: calc(var(--field-padding) + 13px);
        }

        @media screen and (min-width: 1300px) {
            width: 270px;
        }
    }

    &__table {
        .table-head {
            @media screen and (min-width: 1200px) {
                position: sticky;
                top: 80px;
                z-index: 2;
            }
        }

        .table-head td, .table-head th {
            white-space: normal;
        }

        tr:first-of-type td {
            padding-top: 30px;
            padding-bottom: 30px;
        }

        tr:last-of-type td {
            padding-top: 20px;
            padding-bottom: 20px;
        }

        .table-row:nth-of-type(even) td {
            background-color: #fff;
        }

        @media screen and (max-width: 1199px) {
            .table-item {
                overflow-x: auto;
                width: calc(100% + (2 * var(--page-margin)));
                margin-left: calc(0px - var(--page-margin));
                padding: 0 var(--page-margin);
            }
        }
    }

    .table-sticky-row {
        & > *:first-child {
            font-size: 1.3rem;
            font-weight: 500;
            justify-content: flex-start;
            padding-left: 15px;

            & + * {
                color: var(--color-text-grey);
            }
        }
    }

    &__sticky--client {
        .table-sticky__body {
            & > div {
                &:first-of-type {
                    border-bottom: solid 5px var(--color-bg-light);
                    padding: 10px 0;

                    .table-sticky-row:first-of-type:last-of-type {
                        border-bottom: 0;
                    }

                    & ~ div {
                        .table-sticky-row,
                        .table-cell {
                            //height: 35px;
                        }

                        .table-sticky-row:nth-of-type(2) .table-cell {
                            padding-top: 0;
                            font-size: 1.1rem;
                        }
                    }
                }
            }
        }

        .table-sticky-row {
            &:nth-of-type(2) .table-cell {
                color: var(--color-text-grey);
            }
        }
    }

    &__sticky--periods {
        .table-sticky-row:nth-of-type(even) > * {
            background-color: transparent;
        }

        .table-sticky__body > div:nth-of-type(even) {
            border-top: solid 1px var(--color-bg-light);
            border-bottom: solid 1px var(--color-bg-light);

             .table-sticky-row > * {
                background-color: var(--color-bg-very-light);
            }
        }

        .table-sticky-row {
            border-bottom: 0;
        }
    }
}
</style>