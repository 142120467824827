import CatalogueAPI from '@/api/api-catalogue';
import listRoutes from '@/api/list-routes';
import i18n from '@/i18n';

const state = {
    cohorts: null,
    cohortsLoading:null,
    
    cohortsSample: null,
    cohortsSampleLoading:null

}

const mutations = {
    SET_COHORTS(state, data){
        state.cohorts = data
    },
    SET_COHORTS_LOADING(state, data){
        state.cohortsLoading = data
    },
    SET_COHORTS_SAMPLE(state, data){
        state.cohortsSample = data
    },
    SET_COHORTS_SAMPLE_LOADING(state, data){
        state.cohortsSampleLoading = data
    },
}

const actions = {
    fetchCohorts({commit},id){
        commit('SET_COHORTS_LOADING', true);
        commit('SET_COHORTS', null);
        
        return new Promise((resolve, reject) => {
            CatalogueAPI.get(`${listRoutes.cohorts.replace(':id',id)}`)
                .then(res => {
                    return res.data
                })
                .then(data => {
                    commit('SET_COHORTS', data);
                    resolve(data)
                })
                .catch(err => reject(err))
                .finally(()=> {
                    commit('SET_COHORTS_LOADING', false);
                })
        })
    },

    createCohort({commit},data){
        const {brandID, ...rest} = data
        return new Promise((resolve, reject) => {
            CatalogueAPI.post(`${listRoutes.pricingImport.replace(':brandID',brandID)}`,rest,
            {
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'multipart/form-data',
                }
            })
            .then(res => {
                resolve(res)
            })
            .then(data => resolve(data))
            .catch(err => {
                commit('SET_NOTIFICATION_MESSAGE', {text:i18n.t(err.response.data), color:'red', confirmation: true})
                reject(err)
            })
        })
    },

    createCohorts({dispatch},data){
        
        const { file, ...brandID } = data;
                
        const promises = brandID.brandID.map((brand) => {
            return dispatch('createCohort', {
              file: file,
              brandID: brand
            })
              .then(() => {
                return { brandID: brand, success: true };
              })
              .catch((error) => {
                return { brandID: brand, success: false, error };
              });
          });
        
        return Promise.all(promises)
            .then((results) => {
                const successfulCatalogs = results.filter((result) => result.success).map((result) => result.catalog_id);
                const failedCatalogs = results.filter((result) => !result.success).map((result) => result.catalog_id);
        
                return { successfulCatalogs, failedCatalogs };
            })
            .catch((error) => {
                console.log('An error occurred:', error);
            });

    },

    fetchCohortsSample({commit},catalogueID){
        commit('SET_COHORTS_SAMPLE_LOADING', true);
        commit('SET_COHORTS_SAMPLE', null);
        return new Promise((resolve, reject) => {
            CatalogueAPI.get(`${listRoutes.catalogueSample.replace(':catalogueID',catalogueID)}`)
                .then(res => {
                    return res.data
                })
                .then(data => {
                    commit('SET_COHORTS_SAMPLE', data);
                    resolve(data)
                })
                .catch(err => reject(err))
                .finally(()=> {
                    commit('SET_COHORTS_SAMPLE_LOADING', false);
                })
        })
    },
    downloadCohortsTemplateFile(_,extension){
        CatalogueAPI.get(`${listRoutes.cohortsDownloadTemplateFile.replace(':extension',extension)}`, {
            responseType: 'blob' 
        })
        .then(response => {             
            const url = window.URL.createObjectURL(new Blob([response.data]));

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `FAUME_template.${extension}`); 

            document.body.appendChild(link);
            link.click();

            window.URL.revokeObjectURL(url);
        })
        .catch(error => {
            console.error('Error downloading file:', error);
        });
    }
    
}

const getters = {
    cohortsExists: state => {
        return state?.cohorts?.length 
    },
    getCohortsSampleStateValue: state => value =>  {
        return value 
            ? state.cohortsSample[value] ? state.cohortsSample[value] : null
            : state.cohortsSample ? state.cohortsSample : null
    },
}

const cohorts =  {
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default cohorts