<!-- Based on ConfigWebsite.vue -->
<template>
    <TemplateDetails :data="catalogues" :loading="cataloguesLoading" :get-function="this.getCatalogues">
        
        <PageBack/>

        <PageHeader :title="title">
            <template v-slot:actions>
                <Button icon="download" size="m" color="grey" @click.native.prevent="downloadFile('csv')">{{$t('cohorts.download_csv')}}</Button>
                <Button icon="download" size="m" color="grey" @click.native.prevent="downloadFile('json')">{{$t('cohorts.download_json')}}</Button>
            </template>
        </PageHeader>

        <Form class="catalogue-form" 
            :fields="fields" 
            :model="model" 
            :action="createCohort" 
            @success="e => createCohortSuccess(e)" 
        />


      <Popin :title="$t('catalogue.about_to_launch')" ref="aboutToLaunchCohort">
        <template v-slot:bottom>
            <Button v-if="!loading" @click.native.prevent="$refs.aboutToLaunchCohort.close" color="red" size="m">{{ $t('global.cancel') }}</Button>
            <Button v-if="currentCatalogue.status.validation.isValidated && !loading" color="green" size="m" @click.native.prevent="generateThisCatalogue">{{ $t('catalogue.generation_start') }}</Button>
            <InPopinLoader
                v-if="loading"
                :msg="$t('catalogue.please_wait_setup')" 
                :loading="loading" 
            />
        </template>
      </Popin>

      <Popin :title="getCatalogueProgressStateValues !== '100%' ? $t('catalogue.generation') : $t('catalogue.generation_end')" ref="popinProgression">
        <template v-slot:content>
            <div v-if="catalogueProgress && !validationSuccess">
                <ProgressBar 
                v-if="showProgressBar" 
                :start_value="catalogueProgress.totalLinesProcessed" 
                :start_text="$t('catalogue.totalProductsProcessed')" 
                :total_value="catalogueProgress.totalLines" 
                :total_text="$t('catalogue.totalProducts')" 
                :progression="getCatalogueProgressStateValues" 
                />
                <InPopinLoader 
                v-if="catalogueProgressStatus" 
                :msg="$t('catalogue.please_wait_end')" 
                :loading="catalogueProgressStatus" 
                />
            </div>
            <TextTitle v-if="title && isFinished" tag="h2" size="xs">{{ popinValidationTitle }}</TextTitle>
            
        </template>
        <template v-slot:bottom v-if="isFinished || isPublished">
            <ButtonLink v-if="isFinished" color="grey" size="m" :to="{name:'config-website-catalogues-update'}">{{ $t('global.back') }}</ButtonLink>
            <ButtonLink v-if="isFinished" color="grey" size="m" :to="{name:'config-website-cohorts-validation'}">Recetter</ButtonLink>
            <Button v-if="isFinished" color="green" size="m" @click.native.prevent="launchThisCatalogue">{{ $t('catalogue.publish') }}</Button>
            <TextTitle v-if="title && isPublished && getCatalogueProgressStateValues === '100%'" tag="h2" size="xs">{{ $t('catalogue.publish_success') }}</TextTitle>
            <ButtonLink v-if="isPublished && getCatalogueProgressStateValues === '100%'" color="grey" size="m" :to="{name:'client-catalog'}">{{ $t('global.back') }}</ButtonLink>
        </template>

      </Popin>
    </TemplateDetails>
</template>
<script>
import TemplateDetails from '@/components/templates/TemplateDetails';
import { mapState, mapGetters, mapActions } from 'vuex';
import PageHeader from '@/components/ui/page/PageHeader';
import Button from '@/components/ui/button/Button';
import ButtonLink from '@/components/ui/button/ButtonLink';
import Popin from '@/components/ui/popin/Popin';
import InPopinLoader from '@/components/ui/popin/InPopinLoader';
import PageBack from '@/components/ui/page/PageBack';
import Form from '@/components/ui/form/Form';
import ProgressBar from '@/components/ui/progress/ProgressBar';
import TextTitle from '@/components/ui/text/TextTitle';

export default {
    name: 'ConfigWebsiteCohortsCreate',
    components: {
        TemplateDetails,
        PageBack,
        ButtonLink,
        Button,
        Popin,
        InPopinLoader,
        PageHeader,
        ProgressBar,
        TextTitle,
        Form
    },
    data() {
        return {
            deleteLoading: false,
            show:true,
            model: {
                file: null,
                brandID:this.$route.params.id,
                catalog_id:this.$route.params.catalogueID
            },
            fields: [
                {
                    name: "file",  
                    type: 'file', 
                    label:"title",
                    translation_1: `${this.$t('cohorts.form_csv_json')}`,
                    translation_2: `${this.$t('cohorts.form_csv_json')}`,
                    required: true,
                    accept:['.csv','.json']
                },
            ],
            showProgressBar:false,
            validationSuccess:false,
            loading:false
        }
    },
    computed: {
        ...mapState(['catalogueProgress','catalogues','cataloguesLoading','catalogueProgressStatus']),
        ...mapGetters(['getCatalogueProgressStateValues','getCurrentCatalogue']),
        id() {
            return this.$route.params.id
        },
        lang() {
            return this.$route.params.lang
        },
        catalogueID(){
            return this.$route.params.catalogueID
        },
        client() {
            return this.$store.getters.getClientById(this.id)
        },
        currentCatalogue(){
            const getCurrentCatalogue = this.getCurrentCatalogue;
            return getCurrentCatalogue 
                ? getCurrentCatalogue(this.lang)(this.catalogueID)
                : null;
        },
        title(){
            return this.currentCatalogue ? `${this.$t('cohorts.step_1')} : ${this.currentCatalogue.title}` : null
        },
        popinValidationTitle(){
            return `${this.$t('cohorts.import_success')} ${this.$route.params.lang}`
        },
        isFinished(){
            return this.getCatalogueProgressStateValues === '100%' && !this.validationSuccess && !this.catalogueProgressStatus
        },
        isPublished(){
            return this.validationSuccess && !this.catalogueProgressStatus
        }
    },
    methods: {
        ...mapActions(['createCohort','catalogueValidationOrGeneration', 'catalogueLaunch','fetchCatalogues']),
        getCatalogues(){
            this.fetchCatalogues(this.id)
        },
        createCohortSuccess(data){
            if(data){
                this.$refs.aboutToLaunchCohort.open();
            }
        },
        generateThisCatalogue(){
            this.loading = true
            this.catalogueValidationOrGeneration({id:this.catalogueID, clientID:parseInt(this.id), type:'generation',fetchAfter:false})
            .then(response => {
                if(response){
                    this.$refs.aboutToLaunchCohort.close()
                    this.$refs.popinProgression.open()
                    this.showProgressBar = true
                    this.loading = false
                }
            })
            .catch(err => {
                this.$store.commit('SET_NOTIFICATION_MESSAGE', {text:this.$t(err.response.data), color:'red', confirmation: true})
            });
        },
        launchThisCatalogue(){
            this.catalogueLaunch({id:this.id, catalogueID:this.catalogueID,fetchAfter:false})
                .catch(err => {
                    this.$store.commit('SET_NOTIFICATION_MESSAGE', {text:this.$t(err.response.data), color:'red', confirmation: true})
                })
                .finally(()=> this.validationSuccess = true)
        },
        downloadFile(e) {
            this.$store.dispatch('downloadCohortsTemplateFile', e);
        }
    },
}
</script>
<style lang="scss" scoped>
.popin {
    &__content--catalogues {
        display: flex;
        flex-direction: column;
        padding-top: 10px;
        gap: 10px;
        align-items: stretch;
        border-top: var(--border-2);
        margin-top: 20px;
    }
    &__content--row {
        display:flex;
        align-items:center;
        justify-content:space-between;
        & > .button:is(:only-child) {
            margin-left: auto;
            margin-right: auto;
        }
        .button + .button {
            margin-top: 0;
        }
    }
}

</style>