<template>
    <img class="svg-flag" :src="require(`../../../assets/images/${lang}.svg`)" :alt="lang"/>
</template>

<script>
export default {
    name: 'SvgFlag',
    props: {
        lang: String
    }
}
</script>

<style lang="scss">
.svg-flag {
    height: auto !important;
    width: 25px !important;
    border-radius: 0 !important;
    border: 0 !important;
}
</style>