<template>
    <component :is="collectionID ? 'TemplateDetails' : 'div'"
            :data="catalogueCollection"
            :loading="true"
            :get-function="setCollection"
    >
        <div class="collections-create">
            <PageBackWithLink
                    :to="{
          name: 'config-website-catalogues-collections-listing',
          params: { brandID: id },
        }"
                    :text="$t('global.back')"
            />
            <PageHeader :title="title">
                <template v-slot:actions>
                    <Button
                            v-if="isCollectionExists"
                            @click.native.prevent="
              openPopinOnContext(collectionState ? 'disabled' : 'enabled')
            "
                            :icon="collectionState ? 'cross' : 'right'"
                            color="grey"
                            size="m"
                    >{{
                            collectionState
                                    ? $t("collections.disable")
                                    : $t("collections.enable")
                        }}</Button
                    >
                    <Button
                            v-if="isCollectionExists"
                            @click.native.prevent="openPopinOnContext('deleted')"
                            icon="cross"
                            color="red"
                            size="m"
                    >{{ $t("global.delete") }}</Button
                    >
                    <Button
                            :disabled="!doMandotoryFieldsExists"
                            @click.native.prevent="
              openPopinOnContext(isCollectionExists ? 'updated' : 'created')
            "
                            icon="right"
                            color="black"
                            size="m"
                    >{{
                            isCollectionExists ? $t("global.edit") : $t("market.btnSave")
                        }}</Button
                    >
                </template>
            </PageHeader>
            <GridContainer
                    class="collections-create__container"
                    :class="{ 'edit-translations': modalOpened }"
            >
                <GridCard>
                    <GridContent>
                        <CollectionsIdentification
                                @modalOpened="(e) => (modalOpened = e)"
                                @emitOtherLocales="markets = $event"
                        />
                    </GridContent>
                </GridCard>
                <GridCard>
                    <GridContent>
                        <CollectionsDataSources />
                    </GridContent>
                </GridCard>
                <template v-slot:aside v-if="catalogueCollection?.markets?.length">
                    <CollectionsMarketsList :model="catalogueCollection?.markets" />
                </template>
                <GridCard v-if="products?.length">
                    <GridContent>
                        <div class="grid-4-cols">
                            <ProductCard
                                    v-for="product in products"
                                    :key="product.id"
                                    :product="product"
                            />
                        </div>
                    </GridContent>
                </GridCard>
            </GridContainer>
        </div>
        <Popin
                :title="$t('collections.config')"
                :subtitle="$t(statuses.title)"
                :center="true"
                :large="true"
                ref="popinCollectionConfig"
        >
            <template #bottom>
                <div class="collections-create__popin">
                    <div class="collections-create__confirm">
                        <div class="collections-create__confirm-btns" v-if="!loading">
                            <ButtonLink
                                    v-if="success && statuses.redirect"
                                    size="m"
                                    color="grey"
                                    :to="{
                  name: 'config-website-catalogues-collections-listing',
                  params: { brandID: id },
                }"
                            >{{ $t("global.back") }}</ButtonLink
                            >
                            <Button
                                    v-if="!(success && statuses.redirect)"
                                    @click.native.prevent="closePopinAndResetSucess"
                                    color="grey"
                                    size="m"
                            >{{ $t("global.close") }}</Button
                            >
                            <Button
                                    v-if="!success"
                                    @click.native.prevent="statuses.handler"
                                    :color="statuses.color"
                                    size="m"
                            >{{ $t(statuses.btn_text) }}</Button
                            >
                        </div>
                        <InPopinLoader
                                v-if="loading"
                                :msg="$t('collections.please_wait_setup')"
                                :loading="loading"
                        />
                    </div>
                </div>
            </template>
        </Popin>
    </component>
</template>

<script>
import TemplateDetails from "@/components/templates/TemplateDetails.vue";
import Button from "@/components/ui/button/Button.vue";
import ButtonLink from "@/components/ui/button/ButtonLink.vue";
import CollectionsDataSources from "@/components/ui/collections/CollectionsDataSources.vue";
import CollectionsIdentification from "@/components/ui/collections/CollectionsIdentification.vue";
import CollectionsMarketsList from "@/components/ui/collections/CollectionsMarketsList.vue";
import GridCard from "@/components/ui/grid/GridCard.vue";
import GridContainer from "@/components/ui/grid/GridContainer.vue";
import GridContent from "@/components/ui/grid/GridContent.vue";
import PageBackWithLink from "@/components/ui/page/PageBackWithLink.vue";
import PageHeader from "@/components/ui/page/PageHeader.vue";
import InPopinLoader from "@/components/ui/popin/InPopinLoader.vue";
import Popin from "@/components/ui/popin/Popin.vue";
import ProductCard from "@/components/ui/product/ProductCard.vue";
import { mapActions, mapState } from "vuex";
export default {
    name: "CatalogueCollectionsConfig",
    components: {
        TemplateDetails,
        PageBackWithLink,
        PageHeader,
        Button,
        ButtonLink,
        GridContainer,
        GridCard,
        GridContent,
        // GridHeader,
        CollectionsIdentification,
        CollectionsMarketsList,
        CollectionsDataSources,
        Popin,
        InPopinLoader,
        ProductCard,
    },
    watch: {
        markets(newValue) {
            const defaultIdentificationDatas = newValue.reduce(
                    (accumulator, elem) => {
                        accumulator[elem] = {
                            title: "",
                            description: "",
                        };
                        return accumulator;
                    },
                    {}
            );
            if (newValue?.length && typeof this.collectionID === "undefined") {
                this.catalogueInitCollection(defaultIdentificationDatas);
            }
        },
        catalogueCollection: {
            immediate: true,
            handler(newVal) {
                if (newVal && newVal.slugName) {
                    this.loadProducts();
                }
            },
        },
    },
    data() {
        return {
            payload: {},
            status: "",
            success: false,
            modalOpened: false,
            markets: [],
            loading: false,
            products: [],
        };
    },
    created() {
        if (this.collectionID) {
            this.catalogueGetCollection({
                id: this.id,
                collectionID: this.collectionID,
            }).then(() => {
                if (this.catalogueCollection && this.catalogueCollection.slugName) {
                    this.loadProducts();
                }
            });
        }
    },
    computed: {
        ...mapState(["catalogueCollection", "catalogueCollectionLoading"]),
        id() {
            return this.$route.params.id;
        },
        client() {
            return this.$store.getters.getClientById(this.id);
        },
        title() {
            return this.collectionID
                    ? `${this.$t("collections.collections_edit")} ${this.client.name}`
                    : `${this.$t("collections.collections_create")} ${this.client.name}`;
        },
        collectionID() {
            return this.$route.params.collectionID;
        },
        isCollectionExists() {
            return !!this.catalogueCollection.id;
        },
        collectionState() {
            return this.catalogueCollection?.state;
        },
        doMandotoryFieldsExists() {
            const { identification, parameters, tags } =
            this.catalogueCollection || {};

            // Vérifie si les titres existent
            const titlesExist =
                    identification &&
                    Object.values(identification).every((item) => item?.title);

            // Détermine si la source est 'manual_tags' ou 'catalog_data'
            const isManualTags = parameters?.source === "manual_tags";
            const isCatalogData = parameters?.source === "catalog_data";

            // Vérifie la condition pour 'manual_tags'
            const tagsValid = isManualTags ? tags && tags.length > 0 : true;

            // Vérifie la condition pour 'catalog_data'
            const conditionsValid = isCatalogData
                    ? parameters?.conditions?.every(
                            (condition) =>
                                    Object.keys(condition).length === 0 ||
                                    condition?.value?.length > 0
                    )
                    : true;

            return !!titlesExist && tagsValid && conditionsValid;
        },
        statuses() {
            const conditions = {
                deleted: {
                    title: !this.success
                            ? "collections.about_to_deleted"
                            : "collections.deleted",
                    btn_text: "global.delete",
                    color: "red",
                    redirect: true,
                    handler: () => {
                        this.loading = true;
                        this.performCatalogueCollectionsAction(
                                "catalogueDeleteCollection",
                                { id: this.id, collectionID: this.collectionID }
                        ).then(() => (this.loading = false));
                    },
                },
                updated: {
                    title: !this.success
                            ? "collections.about_to_updated"
                            : "collections.updated",
                    btn_text: "global.validate",
                    color: "green",
                    redirect: false,
                    handler: () => {
                        this.loading = true;
                        this.performCatalogueCollectionsAction(
                                "catalogueCollectionUpdate",
                                { id: this.id, collectionID: this.collectionID }
                        ).then(() => (this.loading = false));
                    },
                },
                created: {
                    title: !this.success
                            ? "collections.about_to_created"
                            : "collections.created",
                    btn_text: "global.validate",
                    color: "green",
                    redirect: false,
                    handler: () => {
                        this.loading = true;
                        this.performCatalogueCollectionsAction(
                                "catalogueCollectionCreate",
                                { id: this.id, router: this.$router }
                        ).then(() => (this.loading = false));
                    },
                },
                disabled: {
                    title: !this.success
                            ? "collections.about_to_disable"
                            : "collections.disabled",
                    btn_text: "global.validate",
                    color: "green",
                    redirect: false,
                    handler: () => {
                        this.loading = true;
                        this.performCatalogueCollectionsAction(
                                "catalogueCollectionStateManagement",
                                { id: this.id, collectionID: this.collectionID, state: 0 }
                        ).then(() => (this.loading = false));
                    },
                },
                enabled: {
                    title: !this.success
                            ? "collections.about_to_enable"
                            : "collections.enabled",
                    btn_text: "global.validate",
                    color: "green",
                    redirect: false,
                    handler: () => {
                        this.loading = true;
                        this.performCatalogueCollectionsAction(
                                "catalogueCollectionStateManagement",
                                { id: this.id, collectionID: this.collectionID, state: 1 }
                        ).then(() => (this.loading = false));
                    },
                },
            };
            if (this.status.length) {
                return conditions[this.status];
            }
            return { handler: () => {} }; // Retourne une fonction sans opération si l'on a pas de status
        },
        collectionSlugName() {
            return this.catalogueCollection?.slugName;
        },
    },
    methods: {
        ...mapActions([
            "catalogueGetCollection",
            "catalogueInitCollection",
            "catalogueCollectionCreate",
            "catalogueCollectionUpdate",
            "catalogueDeleteCollection",
            "catalogueCollectionStateManagement",
        ]),
        setCollection() {
            if (this.collectionID) {
                this.catalogueGetCollection({
                    id: this.id,
                    collectionID: this.collectionID,
                });
            }
        },
        openPopinOnContext(context) {
            this.status = context;
            this.$refs.popinCollectionConfig.open();
        },
        closePopinAndResetSucess() {
            this.$refs.popinCollectionConfig.close();
            this.success = false;
        },
        async performCatalogueCollectionsAction(action, payload) {
            try {
                const res = await this[action](payload);
                if (res >= 200 && res < 300) {
                    this.success = true;
                } else {
                    console.error(`Error: Received status code ${res.status}`);
                }
            } catch (error) {
                console.error("Error during catalogue action:", error);
            }
        },
        async loadProducts() {
            if (!this.collectionSlugName) {
                console.error("Nom de la collection non défini");
                return;
            }
            try {
                const response = await fetch(
                        `${process.env.VUE_APP_API}/api/v3/customer/collections/${this.collectionSlugName}?itemsPerPage=12`,
                        {
                            headers: {
                                "Content-Type": "application/ld+json",
                                "X-Brand-ID": this.id,
                            },
                        }
                );

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                this.products = data["hydra:member"].map((product) => ({
                    id: product.id,
                    title: product.title,
                    price: product.price ? product.price / 100 : null,
                    size: Array.isArray(product.size)
                            ? product.size.join(", ")
                            : product.size,
                    photo1: product.frontPhoto || product.photo1,
                    photo2: product.backPhoto || product.photo2,
                }));

                console.log("Products:", this.products);
            } catch (error) {
                console.error("Erreur lors de la récupération des produits:", error);
            }
        },
    },
};
</script>

<style lang="scss">
.collections-create {
    padding-bottom: 10%;
    &__container {
        .grid__content:first-of-type {
            z-index: 0;
        }
    }
    &__confirm {
        &-btns {
            margin: 10px;
            align-items: center;
            display: flex;
            gap: 10px;
            & * {
                margin: 0;
            }
        }
    }

    .grid__content {
        overflow: visible;
    }

    .grid-4-cols {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 10px;
        grid-auto-rows: minmax(100px, auto);
    }
}
</style>