import DashboardAPI from '@/api/api-dahsboard';

const state = {
    dashboardData: null,
    dashboardFilters: null,
    dashboardIcons: {
        tradeinCreditAccepted: "tshirt-check",
        tradeinCreditAcceptedPriceAvg: "money",
        tradeinCreditAcceptedTotal: "money",
        tradeinTotal: "tshirt-check",
        tradeinCreditAcceptedPercent: "percent-border",
        tradeinCreditAcceptedPerStockPercent: "percent-border",
        tradeinBad: "cross",
        tradeinBadPercent: "percent-border",
        sales: "box",
        salesAmount: "bag",
        salesAvg: "bag-empty",
        returns: "arrow-return-hashtag",
        returnsAmount: "arrow-return-money",
        returnsAvg: "arrow-return-money",
        net: "box",
        netAmount: "money",
        netAvg: "money",
        returnRate: "arrow-return-percent",
        stockRate: "tshirt",
        margin: "money",
        reconditioningAmountWithoutTax: "money",
        commission: "money",
        directMargin: "money",
        users: "users",
        customers: "users",
        newUsers: "new-user",
        returningUsers: "heart",
        retentionRate: "percent-border",
        returningTradeinsPercent: "arrow-return-percent",
        orders: "box",
        newOrders: "box",
        ordersAmount: "bag",
        newOrdersAmount: "bag-empty",
        avgOrderPerCustomer: "bag",
        ttlCustomer: "user-border",
        returningOrdersAmount: "arrow-return-money",
        newTradeins: "tshirt",
        tradeins: "hashtag-border",
        returningOrders: "hashtag-border",
        newTradeinsAmount: "money",
        returningTradeinsAmount: "money",
        avgTradeinsNbPerUser: "tshirt",
        ttlUser: "refresh",
        cltv: "money",
        avgTradeinsAmountPerUser: "calendar-money",
        avgOrderAmountPerCustomer: "calendar-money",
        returningTradeins: "arrow-return-hashtag",
        tradeinsAmount: "money",
        newCustomers: "users",
        returningCustomers: "heart",
        returningOrdersPercent: "percent-border",
        averageTimeToControl: "time",
        controlled: "search-valid-border",
        averageTimeToPublish: "time",
        devaluedTradeinsPercent: "arrow-return-percent",
        opened: "tshirt-box",
        published: "tshirt-check",
        refusedTradeinsPercent: "percent-border",
    }
}

const mutations = {
    SET_DASHBOARD_DATA(state, value) {
        state.dashboardData = value
    },
    SET_DASHBOARD_FILTERS(state, value) {
        state.dashboardFilters = value
    }
}

const actions = {
    getDashboardData({commit}, params) {
        return new Promise((resolve, reject) => {
            commit('SET_DASHBOARD_DATA', null);
            const {route, playload} = params;
            DashboardAPI.post(route, {...playload})
                .then(res => res.data)
                .then(data => {
                    commit('SET_DASHBOARD_DATA', data)
                    resolve()
                })
                .catch(err => reject(err?.message || err))
        })
    },
    getDashboardFilters({commit}, route) {
        return new Promise((resolve, reject) => {
            commit('SET_DASHBOARD_FILTERS', null);
            DashboardAPI.get(route)
                .then(res => res.data)
                .then(data => {
                    commit('SET_DASHBOARD_FILTERS', data)
                    resolve()
                })
                .catch(err => reject(err?.message || err))
        })
    }
}

const getters = {

}

const dashboard = {state, mutations, actions, getters};

export default dashboard;