import PasswordForgot from '@/components/pages/PasswordForgot';
import PasswordReset from '@/components/pages/PasswordReset';

const passwordRoutes = [
    {
        path: '/mot-de-passe',
        name: 'password',
        component: PasswordForgot,
        meta: {
            public: true
        }
    },
    {
        path: '/password-reset/:id',
        name: 'password-reset',
        component: PasswordReset,
        meta: {
            public: true
        }
    }
]

export default passwordRoutes