import { render, staticRenderFns } from "./CatalogueFilter.vue?vue&type=template&id=1245497c&scoped=true"
import script from "./CatalogueFilter.vue?vue&type=script&lang=js"
export * from "./CatalogueFilter.vue?vue&type=script&lang=js"
import style0 from "./CatalogueFilter.vue?vue&type=style&index=0&id=1245497c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1245497c",
  null
  
)

export default component.exports