<template>
    <Button class="filters-btn" color="black" icon="plus" data-testid="filter-btn">{{ $t('global.filter') }}</Button>
</template>
<script>
import Button from '@/components/ui/button/Button';
export default {
    components: {Button}
}
</script>

<style lang="scss">
.filters-btn {
    min-width: auto;
    padding: 0 25px;
}
</style>