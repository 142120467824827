import { render, staticRenderFns } from "./ConfigWebsiteCohortsValidation.vue?vue&type=template&id=14fe256f&scoped=true"
import script from "./ConfigWebsiteCohortsValidation.vue?vue&type=script&lang=js"
export * from "./ConfigWebsiteCohortsValidation.vue?vue&type=script&lang=js"
import style0 from "./ConfigWebsiteCohortsValidation.vue?vue&type=style&index=0&id=14fe256f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14fe256f",
  null
  
)

export default component.exports