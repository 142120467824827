<template>
    <PageLoader v-if="loading"/>
    <TemplateClient v-else :subtitle="$t('client.recommerce.subtitle')" :text="$t('client.recommerce.text')">
        <CheckerboardContainer v-if="client">
            <CheckerboardItem v-for="(item, i) in allowed" :key="i" :title="$t(item.title)" title-size="s">
                <ButtonLink size="s" icon="right" color="black" :to="{name: item.route.name, params: { mail_id: item.route.params.mail_id }, query: { emails_category: item.route.params.mail_id }}">{{ $t('client.emails.cta') }}</ButtonLink>
            </CheckerboardItem>
        </CheckerboardContainer>
    </TemplateClient>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import PageLoader from '@/components/ui/page/PageLoader';
import CheckerboardContainer from '@/components/ui/checkerboard/Checkerboard';
import CheckerboardItem from '@/components/ui/checkerboard/CheckerboardItem';
import TemplateClient from '@/components/templates/TemplateClient';
import ButtonLink from '@/components/ui/button/ButtonLink';

export default {
    name: 'ClientRecommerceEmails',
    components: {TemplateClient, CheckerboardItem, CheckerboardContainer, PageLoader, ButtonLink},
    data() {
        return {
            stores: null,
            loading: true,
            list: [
                {
                    title: "client.emails.buying_title",
                    id: 'buying',
                    route: { 
                        name: 'client-emails',
                        params: { mail_id: 'buying' } 
                    }
                },
                 {
                    title: 'client.emails.tradin_title',
                    id: 'trade-in',
                    route: { 
                        name: 'client-emails', 
                        params: { mail_id: 'trade-in' } 
                    }
                },

            ]
        }
    },
    computed: {
        ...mapState(['client', 'brandEmailsTranslations', 'clientLocales']),
        ...mapGetters(['roles']),
        id() {
            return this.$route.params.id
        },
        allowed() {
            return this.list.filter(item => this.allRoutes.find(route => route.name === item.route.name)?.roles.find(role => this.roles.includes(role)))
        },
        allRoutes() {
            return this.$router.options.routes.map(route => ({name: route.name, roles: route.meta?.roles || []}))
        },
    },
    methods: {
        ...mapActions(['getBrandEmailsTranslations', 'getClientLocales'])
    },
    watch: {
        brandEmailsTranslations() {
            let translations = this.brandEmailsTranslations;
            let categories = translations.map(translation => translation.category);
            let uniqueCategories = [...new Set(categories)];
            this.list = this.list.filter(item => uniqueCategories.includes(item.id));
            this.loading = false;
        }
    },
    async created() {
        if(this.$route.params.id) {
            await this.getClientLocales(this.$route.params.id);
            const locale = this.clientLocales.find(locale => locale.isDefault === true)?.code || 'fr_FR';
            this.getBrandEmailsTranslations({idClient: this.id, locale });
        }
    },
}
</script>

