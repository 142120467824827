<template>
    <section class="section-table">

        <PageHeader v-if="title" :subtitle="title">
            <template v-slot:actions v-if="exportData">
                <ButtonExport/>
            </template>
        </PageHeader>

        <div class="section-table__container">
            <slot/>
        </div>

    </section>
</template>

<script>
import PageHeader from '@/components/ui/page/PageHeader';
import ButtonExport from '@/components/ui/button/ButtonExport';

export default {
    name: 'SectionTable',
    components: {ButtonExport, PageHeader},
    props: {
        title: String,
        exportData: [Object, Array],
        filename: String
    }
}
</script>

<style lang="scss">
.section-table {
    width: 100%;
    margin-bottom: 30px;

    .page-header {
        margin-bottom: 20px;
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;

        & > * {
            flex-grow: 1;
            max-width: 100%;
        }
    }
}
</style>