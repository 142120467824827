<template>
    <div class="field-schedule">
        <span v-if="label" class="field__label">{{ label }}</span>
        <div v-for="day in days" :key="day" class="field-schedule__date">
            <label class="field-schedule__label">{{ $t(`days.${day}`) }}</label>
            <FieldCheckbox
                    :name="`${name}-isOpen-${day}`"
                    :model="schedule[day] !== 'Fermé'"
                    :label="schedule[day] === 'Fermé' ? $t('global.closed') : $t('global.opened')"
                    @change="e => handleCheck(e, day)"
            />
            <div v-if="schedule[day] !== 'Fermé'" class="field-schedule__times">
                <select required v-model="schedule[day].start" :name="`${name}-start-${day}`" :id="`${name}-start-${day}`">
                    <option v-for="time in times" :key="`start-${time}`" :value="time">{{ time }}</option>
                </select>
                <span> - </span>
                <select required v-model="schedule[day].end" :name="`${name}-end-${day}`" :id="`${name}-end-${day}`">
                    <option v-for="time in times" :key="`end-${time}`" :value="time">{{ time }}</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
import FieldCheckbox from '@/components/ui/form/fields/FieldCheckbox';

export default {
    components: { FieldCheckbox },
    props: {
        model: String,
        name: String,
        label: String,
    },
    data() {
        return {
            defaultDayValue: { start: '9:00', end: '17:00' },
            days: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
            times: [],
            schedule: {},
        };
    },
    watch: {
        schedule: {
            handler() {
                let scheduleFormatted = { ...this.schedule };
                this.days.forEach(day => {
                    const value = scheduleFormatted[day];
                    if (typeof value === 'object') scheduleFormatted[day] = `${value.start} - ${value.end}`;
                });
                this.$emit("change", scheduleFormatted);
            },
            immediate: true,
            deep: true,
        }
    },
    methods: {
        handleCheck(isOpen, day) {
            this.$set(this.schedule, day, !isOpen ? 'Fermé' : { ...this.defaultDayValue });
        }
    },
    beforeMount() {
        this.times = [];
        for (let i = 6; i < 24; i++) {
            this.times.push(`${i}:00`);
            this.times.push(`${i}:30`);
        }
        console.log('Test 11');
        const modelSchedule = this.model ? JSON.parse(this.model) : null;
        console.log(modelSchedule);
        this.days.forEach(day => {
            let scheduleValue = { ...this.defaultDayValue };
            if (modelSchedule&& modelSchedule[day]) {
                scheduleValue = modelSchedule[day] === 'Fermé' ? 'Fermé' : {
                    start: modelSchedule[day].split(' - ')[0],
                    end: modelSchedule[day].split(' - ')[1],
                };
            }
            this.$set(this.schedule, day, scheduleValue);
        });
    }
};
</script>


<style lang="scss">
.field-schedule {
    background-color: #fff;
    position: relative;
    border-radius: 25px;
    padding: 20px;
    margin-top: 20px;

    .field__label {
        top: 0;
        font-size: 1.1rem;
    }

    &__times {
        display: flex;
        gap: 10px;
        align-items: flex-start;
    }

    select {
        width: 70px;
        background-color: #fff;
        border-radius: var(--border-radius-1);
        border: var(--border-1);
        transition: border ease .2s;
        cursor: pointer;
        padding: 4px 0 4px 8px;

        &:hover {
            border: var(--border-2);
        }
    }

    &__date {
        display: flex;
        align-items: center;
        gap: 30px;

        &:not(:first-of-type) {
            margin-top: 10px;
            padding-top: 10px;
            border-top: var(--border-1);
        }
    }

    &__label {
        width: 100px;
    }

    .field-checkbox__input {
        margin-right: 5px;
    }

    .field-checkbox {
        margin-top: 0;
    }
}
</style>
