<template>
    <Field class="form-field-search-select" :class="{'is-uniq': !this.isMultiple, 'is-text-disabled': disabledText}" icon="chevron" :label="label" @mouseover.native="displayList = true" @mouseleave.native="displayList = false">

        <div class="form-field-search-select__container">
            <FormSelected v-for="item in listSelected" :key="item" :name="item" @click.native="removeValueByName(item)"/>
            <input type="text" v-model="search" :disabled="disabledText" :readonly="disabledText">
        </div>

        <ul v-if="!listSelected.length || availableOptions.length" class="form-field-search-select__list" :class="{'is-visible': displayList}">
            <li v-for="option in availableOptions" :key="option.value" @click.prevent="change(option.value)">
                {{ option.name }}
            </li>
            <li v-if="!availableOptions.length" class="form-field-search-select__empty">
                <TextSimple size="s" color="grey">{{ $t('global.no-results-found') }}</TextSimple>
            </li>
        </ul>

    </Field>
</template>

<script>
import Field from '@/components/ui/form/fields/Field';
import FormSelected from '@/components/ui/form/FormSelected';
import TextSimple from '@/components/ui/text/TextSimple';
export default {
    name: 'FieldSearchSelect',
    components: {TextSimple, FormSelected, Field},
    props: {
        label: String,
        options: Array,
        model: [String, Number, Array],
        disabledText: Boolean
    },
    data() {
        return {
            value: this.model,
            search: '',
            displayList: false
        }
    },
    computed: {
        isMultiple() {
            return typeof this.value === 'object'
        },
        availableOptions() {
            let options = this.options;
            const q = this.search.toLowerCase().trim();
            if (this.isMultiple && q && q !== '') options = options.filter(o => o.name.toLowerCase().includes(q));
            if (this.isMultiple && this.value.length) options = options.filter(o => !this.value.includes(o.value));
            if (!this.isMultiple && (this.value && this.value !== '')) options = options.filter(o => o.value !== this.value)
            return options;
        },
        listSelected() {
            if (!this.isMultiple || !this.value) return [];
            // Return only an array of the name of value
            return this.value.map(v => this.options.find(o => o.value === v)?.name)
        }
    },
    methods: {
        change(value) {
            if (this.isMultiple) {
                this.value.push(value);
                this.search = ''
            }
            else this.value = value
        },
        removeValueByName(name) {
            const option = this.options.find(o => o.name === name);
            if (option) this.value = this.value.filter(v => v !== option.value)
        }
    },
    watch: {
        model(e) {
            this.value = e;
            if (!this.isMultiple) {
                this.search = this.options.find(o => o.value === e)?.name;
            }
        },
        value() {
            this.$emit('change', this.value);
        }
    },
    beforeMount() {
        if (!this.isMultiple) {
            const option = this.options.find(o => o.value === this.value);
            if (option) this.search = option.name;
        }
    }
}
</script>

<style lang="scss">
.form-field-search-select {
    width: 100%;
    margin: 5px 0 20px;

    &__container {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 10px;
        overflow-x: auto;

        .form-selected {
            flex-shrink: 0;
            user-select: none;
        }
    }

    &.is-text-disabled {
        cursor: pointer;

        input {
            pointer-events: none;
        }
    }

    & > .icon {
        width: 15px;

        svg {
            width: 100%;
        }
    }

    input {
        height: var(--field-height);
        width: auto;
        flex-grow: 1;
    }

    &__list {
        position: absolute;
        top: 100%;
        left: 0;
        background-color: #fff;
        border-radius: var(--border-radius-2);
        z-index: 6;
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        max-height: 300px;
        overflow-y: auto;
        box-shadow: var(--box-shadow-2);
        transition: opacity ease .3s, transform ease .3s;

        &:not(.is-visible) {
            opacity: 0;
            pointer-events: none;
            transform: translateY(10px);
        }

        &::-webkit-scrollbar {
            width: 5px;
            border-radius: 3px;
            overflow: hidden;
        }

        &::-webkit-scrollbar-track {
            background: var(--color-bg-grey);
            border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb,
        &::-webkit-scrollbar-thumb:hover {
            background: var(--color-text-grey);
            border-radius: 10px;
        }

        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 var(--field-padding);
            height: var(--field-height);
            border-radius: var(--field-height);
            max-width: 100%;
            width: 200px;
            min-width: fit-content;
        }

        li:not(.form-field-search-select__empty) {
            cursor: pointer;
            transition: background-color ease .3s;
            flex-shrink: 0;

            &:after {
                content: '+';
                font-size: 2rem;
                color: var(--color-green);
                opacity: 0;
                transition: opacity ease .3s;
                margin-left: 5px;
            }

            @media screen and (min-width: 1025px) {
                &:hover {
                    background-color: var(--color-green-light);

                    &:after {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
</style>