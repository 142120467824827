<template>
    <div class="warehouse-operators">

        <PageHeader :title="$t('menu.operators')"/>

        <PageFilters v-if="operators">
            <template v-slot:left>
                <FieldDate :label="$t('global.date')" :model="date" @change="e => {date = e; getData()}"/>
                <Field v-if="view === 'detail'" type="select" :options="operators.map((o, i) => ({name: beautifyName(o.name), value: `${i}`}))" :label="$t('menu.operator')" :model="selectedOperator" @change="e => selectedOperator = e"/>
            </template>
            <template v-slot:right>
                    <FieldSwitch :model="view" :title_1="$t('warehouse.operator')" value_1="detail" :title_2="$t('warehouse.global')" value_2="global" @change="e => view = e"/>
            </template>
        </PageFilters>

        <PageLoader v-if="!warehouseHours || !categories || !operators || !selectedCategory"/>

        <div v-else>

            <SectionCategories v-if="view === 'detail'"
                    :categories="categories.map(c => ({title: $t(`warehouse.${c}`), name: c}))"
                    :selected="selectedCategory"
                    :legend1="beautifyName(operators[selectedOperator].name)"
                    legend2="Moyenne"
                    @change="e => selectedCategory = e">

                <ChartBarRow v-for="(hour, index) in hours"
                             :key="hour + operators[selectedOperator].name + selectedCategory"
                             :text="hour"
                             :legend1="beautifyName(operators[selectedOperator].name)"
                             :legend2="$t('chart.average')"
                             :value1="operators[selectedOperator][selectedCategory][index]"
                             :value2="sum[selectedCategory][index].medium"
                             :category="$t(`warehouse.${selectedCategory}`)"
                             :max="maxChartValue"
                             :statistic="getCategoryStatistic(operators[selectedOperator][selectedCategory][index], sum[selectedCategory][index].medium)"/>

            </SectionCategories>

            <GridCard v-if="datasetsOperator.length && view === 'detail'">
                <GridContent>
                    <ChartLines :datasets="datasetsOperator" :axis="hours" type="line"/>
                </GridContent>
            </GridCard>

            <TableSticky v-if="datasetsOperator.length && view === 'detail'" :fields="[{name: 'client', size: 'm'}, ...categories.map(c => ({name: c, translationKey: 'warehouse'}))]">
                <TableStickyRow v-for="(hour, i) in hours" :key="i">
                    <TableCell :value="hour" class="is-size--m"/>
                    <TableCell v-for="(category, y) in categories" :key="y" :value="operators[selectedOperator][category][i]"/>
                </TableStickyRow>
                <TableStickyRow>
                    <TableCell :value="$t('global.total')" class="is-size--m"/>
                    <TableCell v-for="(category, y) in categories" :key="y" :value="operators[selectedOperator][category].reduce((a, b) => a + b, 0)"/>
                </TableStickyRow>
            </TableSticky>

            <SectionCategories v-if="view !== 'detail'"
                    :categories="categories.filter(c => sum[c].total > 0).map(c => ({title: $t(`warehouse.${c}`), name: c}))"
                    :selected="selectedCategory"
                    :legend1="beautifyName(operators[selectedOperator].name)"
                    legend2="Moyenne"
                    :display-legend="false"
                    @change="e => selectedCategory = e">

                <ChartLines :datasets="datasetsOperators" :axis="hours" type="line"/>

            </SectionCategories>

            <TableSticky v-if="view !== 'detail'" :fields="[{name: 'client', size: 'm'}, ...categories.map(c => ({name: c, translationKey: 'warehouse'}))]">
                <TableStickyRow v-for="(operator, i) in operators" :key="i">
                    <TableCell :value="beautifyName(operator.name)" class="is-size--m"/>
                    <TableCell v-for="(category, y) in categories" :key="y" :value="operator[category].reduce((a, b) => a + b, 0)"/>
                </TableStickyRow>
                <TableStickyRow>
                    <TableCell :value="$t('global.total')" class="is-size--m"/>
                    <TableCell v-for="(category, y) in categories" :key="y" :value="operators.map(o => o[category].reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0)"/>
                </TableStickyRow>
            </TableSticky>

        </div>

    </div>
</template>

<script>
import PageHeader from '@/components/ui/page/PageHeader';
import SectionCategories from '@/components/sections/SectionCategories';
import ChartBarRow from '@/components/ui/chart/ChartBarRow';
import Field from '@/components/ui/form/fields/Field';
import PageFilters from '@/components/ui/page/PageFilters';
import FieldDate from '@/components/ui/form/fields/FieldDate';
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import PageLoader from '@/components/ui/page/PageLoader';
import TableSticky from '@/components/ui/table-sticky/TableSticky';
import TableStickyRow from '@/components/ui/table-sticky/TableStickyRow';
import TableCell from '@/components/ui/table/cell/TableCell';
import ChartLines from '@/components/ui/chart/ChartLines';
import GridCard from '@/components/ui/grid/GridCard';
import GridContent from '@/components/ui/grid/GridContent';
import FieldSwitch from '@/components/ui/form/fields/FieldSwitch';

export default {
    components: {FieldSwitch, GridContent, GridCard, ChartLines, TableCell, TableStickyRow, TableSticky, PageLoader, FieldDate, PageFilters, Field, ChartBarRow, SectionCategories, PageHeader},
    data() {
        return {
            view: 'detail',
            selectedCategory: null,
            selectedOperator: '0',
            date: moment().format('YYYY-MM-DD')
        }
    },
    computed: {
        ...mapState(['warehouseHours']),
        categories() {
            return this.warehouseHours?.categories
        },
        operators() {
            return this.warehouseHours?.operators
        },
        hours() {
            return this.warehouseHours?.hours
        },
        selectedOperatorName() {
            return this.selectedOperator ? this.operators[this.selectedOperator]?.name : null
        },
        sum() {
            if (!this.operators || !this.categories) return [];
            let sum = {};
            this.categories.forEach(category => {
                sum[category] = {};
                this.hours.forEach((h, i) => {
                    sum[category][i] = {};
                    sum[category][i].total = this.operators.map(o => o[category][i]).reduce((a, b) => a + b, 0);
                    sum[category][i].medium = sum[category][i].total / this.operators.filter(o => o[category][i]).length;
                });
                sum[category].total = this.operators.map(o => o[category].reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0);
                sum[category].medium = sum[category].total / this.operators.filter(o => o[category].reduce((a, b) => a + b, 0) > 0).length;
            });
            return sum
        },
        maxChartValue() {
            if (!this.operators || !this.categories) return 50;
            let max = 0;
            this.operators.forEach(operator => {
                this.categories.forEach(category => {
                    this.hours.forEach((h, i) => {
                        const value = operator[category][i];
                        if (value > max) max = value
                    })
                })
            })
            return max * 1.2
        },
        datasetsOperators() {
            if (!this.selectedCategory || !this.operators || !this.categories) return []
            let datasets = [];
            this.operators.filter(o => o[this.selectedCategory].reduce((a, b) => a + b, 0) > 0).forEach(operator => {
                datasets.push({
                    name: this.beautifyName(operator.name),
                    data: operator[this.selectedCategory]
                })
            });
            return datasets
        },
        datasetsOperator() {
            if (!this.selectedCategory || !this.operators || !this.categories) return []
            const operator = this.operators[this.selectedOperator];
            let datasets = [];
            this.categories.filter(c => operator[c].reduce((a, b) => a + b, 0) > 0).forEach(category => {
                datasets.push({
                    name: this.$t(`warehouse.${category}`),
                    data: operator[category]
                })
            });
            return datasets
        }
    },
    methods: {
        ...mapActions(['getWarehouseHours']),
        getMediumByHour(hour) {
            if (!this.operators || !this.selectedOperator || !this.selectedCategory) return 0;
            let value = 0;
            this.operators.forEach(o => {
                value += o[this.selectedCategory][hour]
            });
            return value / this.operators.length
        },
        getCategoryStatistic(value, medium) {
            if (value === 0) return 0;
            return Math.round(((value - medium) / medium) * 100)
        },
        getPlayload() {
            return `?start[gte]=${this.date}%2000:00:00&end[lte]=${this.date}%2023:59:59`
        },
        getData() {
            this.getWarehouseHours({
                playload: this.getPlayload()
            })
        },
        beautifyName(name) {
            if (!name) return '';
            name = name.split('.');
            let newName = [];
            name.forEach(n => newName.push(n.toLowerCase().replace(/\w/, firstLetter => firstLetter.toUpperCase())));
            return newName.join(' ')
        },
        updateSelectedCategory(i) {
            this.selectedCategory = this.categories[i]
        }
    },
    watch: {
        categories() {
            this.updateSelectedCategory(0)
        }
    },
    beforeMount() {
        if (this.categories) this.updateSelectedCategory(0);
        if (!this.warehouseHours) this.getData()
    }
}
</script>

<style lang="scss">
.warehouse-operators {
    .chart-bar-row > .text {
        width: 80px;
    }

    .table-sticky-row:last-of-type td {
        font-weight: 500;
        border-top: solid 4px var(--color-bg-light);
    }

    .table-sticky__header {
        height: 55px;
    }
}
</style>