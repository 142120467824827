<template>
    <TemplateClient :hide-back="true">
        <PageLoader v-if="!translations" ref="back"/>
        <div v-else>
            <PageBack/>
            <PageHeader :title="client.name" :subtitle="subtitle" :text="noTranslations ? $t('translations.empty') : null">
                <template v-slot:actions>
                    <ButtonCustom v-if="publishRoute && emails_category != 'trade-in'" @click.native.prevent="publish" size="m" color="black" icon="shopify">{{ $t('global.publish') }}</ButtonCustom>
                </template>
            </PageHeader>
            <FieldSelect
                :multiple="false"
                :border="true"
                :selected="langueSelected"
                :options="avaibleLocales"
                :label="'Langue'"
                @input="e => langueSelected = e" />
            <Table>
                <Tbody>
                <Row v-for="(email, i) in filtredEmails" :key="i">
                    <TableCell :value="email.title" :type="'emailKey'"/>
                    <TableCell :value="email.description" :type="'emailDescription'"/>
                    <!-- <TableCell :value="`${$t('client.emails.label_status')} En attente gestion Trigger`" :type="'emailStatus'"/> -->
                    <td class="table-cell table-cell--emailCta">
                        <div>
                            <div>
                                <ButtonCustom color="white" size="s" @click.native.prevent="openEmailSendTest(email.mailKey)">{{ $t('client.emails.cta_sendtest') }}</ButtonCustom>
                                <ButtonCustom color="white" size="s" @click.native.prevent="openEmailPreview(email.mailKey)">{{ $t('client.emails.cta_preview') }}</ButtonCustom>
                            </div>
                            <div>
                                <ButtonLink color="black" size="s" :to="{name: 'client-edit-emails', params: {mailKey: email.mailKey, mailCategory: emails_category}, query: {mailKey: email.mailKey, mailCategory: emails_category}}">{{ $t('client.emails.cta_edit') }}</ButtonLink>
                            </div>
                            <TextSimple v-if="email.updated_at">{{ $t('client.emails.label_update_date') + ' ' + formatDate(email.updated_at) }}</TextSimple>
                        </div>
                    </td>
                </Row>
                </Tbody>
            </Table>
            <Popin ref="popinEmailSendTest" class="popin-email-send-test" :center="true" :title="$t('client.emails.send_test_popin_title')" :subtitle="$t('client.emails.send_test_popin_texte')">
                <template v-slot:content>
                    <div v-if="!brandEmailsSendTestEmailMessage">
                        <Field
                            v-for="(field, index) in emailTestfields"
                            :key="index"
                            type="text"
                            :placeholder="$t(field.placeholder)"
                            :required="field.required"
                            :model="field.model"
                            @change="e => field.model = e"
                        />
                        <ButtonCustom color="black" size="m" @click.native.prevent="sendEmailTest(getEmailAddresses())">{{ $t('client.emails.cta_sendtest') }}</ButtonCustom>
                    </div>
                    <div v-else>
                        <TextSimple class="confirmation-text"><SvgIcon name="check"/><br>{{ brandEmailsSendTestEmailMessage }}</TextSimple>
                    </div>
                </template>
            </Popin>
            <Popin
                ref="popinEmailPreview"
                :center="true"
                :fullScreen="true"
                class="popin-email-preview"
                @close="closePopin">
                <template v-slot:content>
                    <div ref="shadowHost" v-if="renderShadowHost"></div>
                </template>
            </Popin>
        </div>
    </TemplateClient>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import TemplateClient from '@/components/templates/TemplateClient';
import PageLoader from '@/components/ui/page/PageLoader';
import PageHeader from '@/components/ui/page/PageHeader';
import ButtonCustom from '@/components/ui/button/Button';
import ButtonLink from '@/components/ui/button/ButtonLink';
import PageBack from '@/components/ui/page/PageBack';
import Row from "@/components/ui/table/row/Row";
import TableCell from "@/components/ui/table/cell/TableCell";
import Table from "@/components/ui/table/Table";
import Tbody from "@/components/ui/table/body/Tbody";
import FieldSelect from '@/components/ui/form/fields/FieldSelect';
import Field from '@/components/ui/form/fields/Field';
import Popin from '@/components/ui/popin/Popin';
import ApiAdmin from '@/api/api-admin';
import TextSimple from '@/components/ui/text/TextSimple';
import SvgIcon from '@/components/ui/svg/SvgIcon';
import moment from 'moment';

export default {
    name: 'TemplateEmailsManagement',
    components: {TemplateClient, Popin, PageBack, ButtonCustom, ButtonLink, PageHeader, PageLoader, Row, TableCell, Table, Tbody, FieldSelect, TextSimple, Field, SvgIcon},
    props: {
        title: String,
        translations: [Array, Object],
        importRoute: String,
        publishRoute: String,
        exportRoute: String,
        errorMsg: String,
        subtitle: String,
        defaultLocale: String,
        avaibleLocales: Array
    },
    data() {
        return {
            error: false,
            langueSelected: null,
            renderShadowHost: false, 
            adressEmail1: null,
            adressEmail2: null,
            adressEmail3: null,
            currentMailKeyOpened: null,
            emailTestfields: [
                { placeholder: 'client.emails.send_test_placeholder_mail1', required: true, model: null },
                { placeholder: 'client.emails.send_test_placeholder_mail2', required: false, model: null },
                { placeholder: 'client.emails.send_test_placeholder_mail2', required: false, model: null },
            ],
        }
    },
    computed: {
        ...mapState(['brandEmailsPreview', 'brandEmailsSendTestEmailMessage']),
        id() {
            return this.$route.params.id
        },
        emails_category() {
            return this.$route.query.emails_category
        },
        client() {
            return this.$store.getters.getClientById(this.id)
        },
        filtredEmails() {
            return this.emails_category 
                ? this.translations.filter(email => email.category === this.emails_category)
                : this.translations
        },
        noTranslations() {
            return this.translations && Array.isArray(this.translations) && !this.translations.length
        }
    },
    methods: {
        ...mapActions(['getBrandEmailsPreview', 'postBrandEmailsSendTest', 'setNotificationMessage', 'setTranslationsNotPublished']),
        publish() {
            ApiAdmin.get(this.publishRoute.replace(':id', this.client.id))
                    .finally(() => {
                        this.setNotificationMessage(this.$t('translations.publish_message'));
                        this.setTranslationsNotPublished(false);
                    })
        },
        getEmailAddresses() {
            let addresses = [];
            this.emailTestfields.forEach(field => {
                if (field.model) {
                    addresses.push(field.model);
                }
            });
            return addresses.length > 0 ? addresses : null;
        },
        openEmailSendTest(data){
            this.currentMailKeyOpened = data;
            this.$refs.popinEmailSendTest.open();
        },
        async sendEmailTest(data) {
            await this.postBrandEmailsSendTest({idClient: this.id, locale: this.langueSelected, mailKey: this.currentMailKeyOpened, adressEmail: data});
            setTimeout(() => {
                this.$refs.popinEmailSendTest.close();
                this.$store.commit('SET_EMAILS_SEND_TEST_MESSAGE', null);
            }, 3000);
        },
        async openEmailPreview(data) {
            this.renderShadowHost = true;
            await this.getBrandEmailsPreview({idClient: this.id, locale: this.langueSelected, mailKey: data});
            const shadowRoot = this.$refs.shadowHost.attachShadow({ mode: 'open' });
            shadowRoot.innerHTML = this.brandEmailsPreview.html;
            this.$refs.popinEmailPreview.open();
        },
        publishAndLeave() {
            this.publish();
            this.leave();
        },
        formatDate(date) {
            return moment(date).format('DD/MM/YY');
        },
        routeLeaving(to, from, next) {
            next();
        },
        closePopin(){
            this.renderShadowHost = false;
        }
    },
    mounted() {
        this.langueSelected = this.defaultLocale;
    },
}
</script>

<style lang="scss">
.template-client{

    .field-select{
        margin-bottom: 10px;
        width: 25%;
        margin-left: auto;

        &__label{
            background-color: transparent !important;
        }
    }

    .table-item{
        position: relative;
        display: block;

        table{
            position: relative;
            display: block;
            width: 100%;

            tbody{
                position: relative;
                display: block;
                width: 100%;

                .table-row{
                    position: relative;
                    border: var(--border-1);
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                    width: fit-content;
                    padding: 10px;
                    background-color: var(--color-bg-light);
                    min-width: 100%;

                    td{
                        height: auto;
                        white-space: initial;
                        text-align: left;
                        border-radius: 0 !important;
                        border-top: 0 !important;
                        background-color: var(--color-bg-light);
                        min-width: 300px;
                    }

                    .table-cell--emailKey{
                        width: 25%;
                    }
                    .table-cell--emailDescription{
                        width: 50%;
                        padding: 5px 50px;
                    }
                    .table-cell--emailStatus{
                        width: 25%;

                        span {
                            display: block;
                            width: fit-content;
                            margin-left: auto;
                            margin-right: auto;
                        }
                    }
                    .table-cell--emailCta{
                        width: 25%;

                        & > div{
                            display: flex;
                            flex-direction: column;
                            
                            & > div:first-of-type{
                                display: flex;
                                flex-direction: row;
                                margin-bottom: 10px;

                                .button{
                                    border: 1px solid black;
                                    width: calc(50% - 5px);

                                    &:first-of-type{
                                        margin-right: 5px;
                                    }
                                    &:last-of-type{
                                        margin-left: 5px;
                                    }
                                }
                            } 
                            & > div:last-of-type{
                                .button{
                                    width: 100%;
                                }
                            }
                        }

                        .text{
                           margin-top: 10px;
                           margin-left: auto; 
                        }

                    }
                }
            }
        }
    }

    .popin-email-send-test{
        .field__input{
            border: 1px solid black;
        }
        .button{
            margin-top: 20px;
        }
        .confirmation-text{
            color: var(--color-text-green);
            margin-top: 20px;
            margin-bottom: 20px;

            .icon{
                svg {
                    path{
                        fill: var(--color-text-green);
                    }
                }
            }
        }
    }
}
</style>