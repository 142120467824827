<template>
    <p class="tag" :class="extraClass" v-if="text || iconLeft || iconRight">
        <SvgIcon v-if="iconLeft" :name="iconLeft"/>
        <span v-if="text">{{ text }}</span>
        <SvgIcon v-if="iconRight" :name="iconRight"/>
    </p>
</template>

<script>
import SvgIcon from '@/components/ui/svg/SvgIcon';
export default {
    name: 'TagItem',
    components: {SvgIcon},
    props: {
        text: [String, Number],
        color: String,
        iconLeft: String,
        iconRight: String,
        border: Boolean
    },
    computed: {
        extraClass() {
            let className = this.color ? `tag--${this.color}` : '';
            if (this.border) className += ' tag--border'
            return className
        }
    }
}
</script>

<style lang="scss">
.tag {
    word-break: break-all;
    padding: 4px 10px;
    display: flex;
    justify-content: center;
    gap: 5px;
    border-radius: var(--border-radius-2);
    overflow: hidden;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 1rem;
    background-color: var(--color-bg-grey);
    margin: 0 auto;
    text-decoration: none !important;
    text-align: center;

    .icon svg {
        width: 8px;
        height: auto;
    }

    &--white {
        background-color: #fff;
    }

    &--black {
        background-color: #000;
    }

    &--red {
        background-color: var(--color-red-light);
        --svg-color: var(--color-red);
    }

    &--green {
        background-color: var(--color-green-light);
        --svg-color: var(--color-green);
    }

    &--purple {
        background-color: var(--color-purple);
    }

    &--blue {
        background-color: var(--color-blue);
    }

    &--blue2 {
        background-color: var(--color-blue2);
    }

    &--orange {
        background-color: var(--color-orange);
    }

    &--yellow {
        background-color: var(--color-yellow);
    }

    &--yellow2 {
        background-color: var(--color-yellow2);
    }

    &--green2 {
        background-color: var(--color-green2);
    }

    &--pink {
        background-color: var(--color-pink);
    }

    &--pink2 {
        background-color: var(--color-pink2);
    }

    &--red2 {
        background-color: var(--color-red2);
    }

    &--pink,
    &--black,
    &--yellow,
    &--green2,
    &--red2,
    &--purple,
    &--blue,
    &--blue2,
    &--orange {
        color: #fff;
        --svg-color: #fff;
    }

    &--border {
        border: var(--border-1);
        padding-right: 5px;
        padding-left: 5px;
        gap: 3px;
        transition: border ease .2s;
        cursor: pointer;

        @media screen and (min-width: 1025px) {
            &:hover {
                border: var(--border-2);
            }
        }

        .icon {
            transform: scale(.9);
        }
    }
}
</style>