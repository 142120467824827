<template>
    <section class="login">

        <Form :text="$t('login.title')"
              :center="true"
              :logo="true"
              :fields="fields"
              :action="login"
              :button="$t('login.button')"
              @success="$router.push({name: 'admin-home'})"
        >
        </Form>

        <LinkUnderline :to="{name: 'password'}" :center="true">{{ $t('login.password') }}</LinkUnderline>

    </section>
</template>

<script>
import { mapActions } from 'vuex';
import Form from '@/components/ui/form/Form';
import LinkUnderline from '@/components/ui/link/LinkUnderline';

export default {
    name: 'LoginPage',
    components: {LinkUnderline, Form},
    data() {
        return {
            fields: [
                {name: 'email', type: 'email', testid: 'login-email', required: true},
                {name: 'password', type: 'password', testid: 'login-password', required: true},
            ]
        }
    },
    methods: {
        ...mapActions(['login'])
    }
}
</script>

<style lang="scss">
.login {
    .button {
        width: 100%;
    }
}
</style>