<template>
    <div class="buttons-with-label">
        <div class="buttons-with-label__element" v-for="(data,index) in datas" :key="`${data}-${index}`">
            <Button @click.prevent.native="e => sendData(data)" :icon="icon" :color="color" :size="size">
                {{ data }}
            </Button>
            <div v-if="index < datas.length - 1">
                <span>{{labelText}}</span>
            </div>
        </div>
    </div>
</template>

<script>
import Button from './Button.vue'
export default {
    name: 'ButtonWithLabel',
    components: {
        Button,
    },
    props: {
        datas: Array,
        icon: String,
        color: String,
        size: String,
        labelText : String
    },
    methods: {
        sendData(e) {
            this.$emit('change',e)
        }
    },


}
</script>

<style lang="scss" scoped>
.buttons-with-label {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;
    margin-bottom: 20px;
    &__element {
        display: flex;
        align-items: center;
        gap: 5px;
        span {
            font-size: 1rem;
            text-transform: lowercase;
        }
    }
    @media screen and (max-width: 720px) {
        margin-top: 20px;
    }
}
</style>