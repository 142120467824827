<template>
    <div>
        <TemplateDetails :data="catalogues" :loading="cataloguesLoading" :get-function="getCatalogues">
            
            <div v-if="client">
                <PageBack/>
                <PageHeader :title="title">
                    <template v-slot:actions>
                        <ButtonLink :to="{name:'config-website-catalogue-create'}" icon="right" color="grey" size="m">{{ $t('catalogue.import') }}</ButtonLink>
                    </template>
                </PageHeader>
            
                <GridContainer>
                    <GridCard v-if="currentCatalogues ? currentCatalogues.length : null">
                        <GridContent>
                            <GridHeader :title="$t('catalogue.imported_catalogues')"/>
                            <CatalogueList :catalogues="currentCatalogues" />
                        </GridContent>
                    </GridCard>
                    <div v-else>
                        {{ $t('catalogue.no_catalogue_archived') }}
                    </div>
                </GridContainer>

            </div>

            <PageError v-else title="404" :subtitle="$t('website.not-found')" :button="$t('website.return')" :to="{name: 'config-websites'}"/>

        </TemplateDetails>
    </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import PageError from '@/components/ui/page/PageError.vue';
import PageHeader from '@/components/ui/page/PageHeader.vue';
import PageBack from '@/components/ui/page/PageBack.vue';
import GridContainer from '@/components/ui/grid/GridContainer.vue';
import GridCard from '@/components/ui/grid/GridCard.vue';
import GridContent from '@/components/ui/grid/GridContent.vue';
import GridHeader from '@/components/ui/grid/GridHeader.vue';

// Catalogue
import TemplateDetails from '@/components/templates/TemplateDetails.vue';
import CatalogueList from '@/components/ui/catalogue/CatalogueList.vue';
import ButtonLink from '@/components/ui/button/ButtonLink.vue';

export default {
    name: 'ConfigWebsiteCataloguesView',
    components: {
        GridHeader,
        GridContent,
        GridCard,
        GridContainer,
        PageBack,
        PageHeader,
        PageError,
        CatalogueList,
        ButtonLink,
        TemplateDetails,
    },
    data() {
        return {
            stores: null,
            addLoading: false,
            deleteLoading:false,
            currentCatalogueID: null,
        }
    },
    computed: {
        ...mapState(['catalogues','cataloguesLoading',]),
        ...mapGetters(['getCataloguesSorted']),
        id() {
            return this.$route.params.id    
        },
        client() {
            return this.$store.getters.getClientById(this.id)
        },
        currentCatalogues() {
            return this.getCataloguesSorted(this.$route.params.lang)
        },
        title(){
            return `${this.$t('catalogue.archived_list')} ${this.client.name}`
        }
    },
    methods: {
        ...mapActions(['fetchCatalogues']),
        getCatalogues() {
            this.fetchCatalogues(this.id)
        },
    },
}
</script>

<style lang="scss">
.config-website {
    &__actions {
        display: flex;
        gap: 15px;
        flex-direction: column;

        & > * {
            width: 100%;
        }
    }
}
</style>