<template>
    <form class="form" :class="{'is-center': center, 'is-full': full, 'is-bg': background}" @submit.prevent="submit">

        <div class="form__heading">
            <SvgLogo v-if="logo"/>
            <PageHeader v-if="title || subtitle" :title="title" :subtitle="subtitle" :text="text"/>
            <FormMessage v-if="error" :error="true">{{ errorMsg || error }}</FormMessage>
            <FormMessage v-if="success && typeof success === 'string'" :success="true">{{ success }}</FormMessage>
            <FormMessage v-if="alert && alert !== ''">{{ alert }}</FormMessage>
        </div>

        <slot name="top"/>

        <div class="form__container">

            <component v-for="field in fields"
                       :is="field.type === 'checkbox' ? 'field-checkbox' : field.type === 'date' ? 'field-date' : field.type === 'schedule' ? 'field-schedule' : 'field'"
                       :key="field.name + '_' + (model ? model[field.name] : '')"
                       :set="label = $t(`global.${field.label || field.name}`)"
                       :model="model ? model[field.name] : null"
                       :name="field.name"
                       :testid="field.testid ? field.testid : null"
                       :type="field.type"
                       :filetext="filetext"
                       :options="field.options"
                       :required="field.required"
                       :placeholder="field.placeholder"
                       :min="field.min"
                       :max="field.max"
                       :step="field.step"
                       :disabled="field.disabled"
                       :accept="field.accept"
                       :label="label + `${field.required && !label.includes('*') ? ' *' : ''}`"
                       :displayHours="field.displayHours"
                       @change="e => change(field.name, e)"/>

            <ButtonForm v-if="!slotHasButtonForm" :loading="loading">{{ button || $t('global.validate') }}</ButtonForm>

            <slot/>

        </div>

    </form>
</template>

<script>
import FormMessage from '@/components/ui/form/FormMessage';
import SvgLogo from '@/components/ui/svg/SvgLogo';
import PageHeader from '@/components/ui/page/PageHeader';
import Field from '@/components/ui/form/fields/Field';
import FieldCheckbox from '@/components/ui/form/fields/FieldCheckbox';
import FieldDate from '@/components/ui/form/fields/FieldDate';
import FieldSchedule from '@/components/ui/form/fields/FieldSchedule';
import ButtonForm from '@/components/ui/button/ButtonForm';

export default {
    name: 'FormContainer',
    components: {ButtonForm, Field, FieldSchedule, FieldDate, FieldCheckbox, PageHeader, SvgLogo, FormMessage},
    props: {
        title: String,
        filetext: String,
        text: String,
        subtitle: String,
        alert: String,
        logo: Boolean,
        center: Boolean,
        full: Boolean,
        fields: {
            type: Array,
            default: () => []
        },
        model: Object,
        placeholder:String,
        action: Function,
        button:String,
        background: Boolean,
        initOnSuccess: Boolean,
        successMsg: String,
        errorMsg: String,
        displayHours: Boolean
    },
    data() {
        return {
            formData: this.model
                    ? {...this.model}
                    : this.fields.reduce((o, key) => ({ ...o, [key.name]: null}), {}),
            error: false,
            success: false,
            loading: false
        }
    },
    computed: {
        slotHasButtonForm() {
            const slotContent = this.$slots.default;
            if (slotContent) {
                for (let i = 0; i < slotContent.length; i++) {
                    const slotElement = slotContent[i];
                    if (slotElement.componentOptions && slotElement.componentOptions.tag === 'ButtonForm') {
                        return true;
                    }
                }
            }
            return false;
        },
    },
    watch: {
        model: {
            handler(){
                if (this.model) this.formData = {...this.model}
            },
            deep: true
        },
        formData: {
            handler(){
                this.error = false;
                // this.success = false;
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        updateProp(name, value) {
            this.$set(this.model, name, value);
        },
        change(name, value) {
            this.$set(this.formData, name, value);
            this.$emit('change', {name, value});
        },
        init() {
            this.formData = this.model
                    ? {...this.model}
                    : this.fields.reduce((o, key) => ({ ...o, [key.name]: null}), {});
        },
        submit() {
            this.loading = true;
            this.error = false;
            this.success = false;
            this.$emit('form-before-submit')
            this.action({...this.formData})
                    .then(data => {
                        const msg = typeof data === 'string' ? data : this.successMsg;
                        this.$emit('success', data);
                        if (this.initOnSuccess) this.init();
                        this.success = msg;
                        setTimeout(() => this.success = false, 5000)
                    })
                    .catch(msg => {
                        this.error = msg || true;
                        window.scrollTo(0,0);
                    })
                    .finally(() => this.loading = false)
        },
    }
}
</script>

<style lang="scss">
.form {
    --max-width: 600px;

    &.is-full {
        --max-width: 1200px;
    }

    .button {
        margin: 30px 0 15px;
    }

    .link-underline {
        margin: 15px 0;
    }

    &.is-center {
        text-align: center;

        .form-message,
        .button,
        .link-underline {
            margin-right: auto;
            margin-left: auto;
        }
    }

    &__container {
        max-width: var(--max-width);
    }

    &.is-center .form__container {
        margin: 0 auto;
    }

    &.is-bg {
        background-color: #fff;
        border-radius: var(--border-radius-2);
        padding: var(--page-margin);
    }

    .field-date {
        width: 100%;
    }
}
</style>