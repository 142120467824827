<template>
    <GridCard class="info-box">
        <GridContent class="info-box__container">
            <div class="info-box__infos">
                <i>i</i>
                <TextSimple :size="size || 'm'" weight="bold">{{ text }}</TextSimple>
            </div>
            <Button v-if="button" color="blue" size="s" @click.native.prevent="buttonFunction">{{ $t('merchandising.load_schema') }}</Button>
        </GridContent>
    </GridCard>
</template>

<script>
import GridCard from '@/components/ui/grid/GridCard.vue';
import GridContent from '@/components/ui/grid/GridContent.vue';
import TextSimple from '@/components/ui/text/TextSimple.vue';
import Button from '@/components/ui/button/Button.vue';
export default {
    name: 'InfoBox',
    components: {
        TextSimple,
        GridCard,
        GridContent,
        Button
    },
    props: {
        text: String,
        size: String,
        button:Boolean,
        buttonFunction:Function
    },
}
</script>

<style lang="scss" scoped>
.info-box {
    max-width: 1030px;
    margin: 10px 0;
    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        i {
            font-style: normal;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px;
            border-radius: 50%;
            color: #fff;
            font-size: 20px;
            width: 40px;
            line-height: 1;
            text-align: center;
            background: dodgerblue;
            aspect-ratio: 1;
        }
    }
    &__infos {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
    }
}
</style>