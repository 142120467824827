<template>
    <TemplateDashboard
            :client-is-required="false"
            :route="$listRoutes.dashboardOperations"
            :filters-route="$listRoutes.dashboardOperationsFilters"
            :comparaison="true"
            :title="$t('dashboard-operations.title')"/>
</template>

<script>
import TemplateDashboard from '@/components/templates/TemplateDashboard';

export default {
    name: 'DashboardOperations',
    components: {TemplateDashboard},
}
</script>
