<template>
    <div class="form-columns">
        <slot/>
    </div>
</template>

<script>
export default {
    name: 'formColumns'
}
</script>

<style lang="scss">
.form-columns {
    @media screen and (min-width: 801px) {
        display: flex;

        & > * {
            width: 100%;

            &:not(:first-of-type) {
                margin-left: var(--page-margin);
            }
        }
    }
}
</style>