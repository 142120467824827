<template>
    <div class="field-mapper" :class="{ 'disabled': disabled, 'is-edited': edit }">
        <div class="field-mapper__container" :class="this.editable ? 'remove-margin' : ''">
            <Field :class="`fields-mapper__is-size--${size[0] || 's'}`" type="text" :model="value[valueKeyToDisplay]" :disabled="true" />
            <img :class="`fields-mapper__is-size--${size[1] || 's'}`" :src="isDataExists ? check : fail">
            <Field
                ref="select"
                v-if="!edit"
                :class="`fields-mapper__is-size--${size[2] || 's'}`" 
                type="select"
                :model="isOptional && selectedField !== null && selectedField.length ? selectedField : selectFieldModel"
                :options="options"
                @change="e => emitDatasToParent(e)"
            />
            <Field
                ref="text"
                v-else
                :class="`fields-mapper__is-size--${size[2] || 's'}`" 
                type="text"
                :model="textFieldModel"
                @change="e => emitDatasToParent(e)"
            />
        </div>
        <div class="field-mapper__buttons">
            <Button 
                class="field-mapper__button" 
                :size="'m'" 
                v-show="this.editable && !this.edit" 
                :color="'grey'" 
                :icon="'edit'" 
                @click.native.prevent="edit = !edit"/>
            <Button 
                v-show="this.edit" 
                :class="{'disabled':!textFieldModelLength}" 
                :noEvent="textFieldModelLength ? false : true" 
                size="s" 
                :color="textFieldModelLength ? 'green' : 'grey'" 
                @click.native.prevent="addField">
                {{$t('global.validate')}}
            </Button>

            <Button 
                v-show="isOptional && !this.edit"  
                :disabled="!model?.[selectedField]?.length"
                icon="trash"
                size="s" 
                color="red" 
                @click.native.prevent="deleteKey"></Button>
            <Button 
                v-show="this.edit"  
                icon="cross"
                size="s" 
                color="red" 
                @click.native.prevent="edit = !edit"></Button>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import Field from '@/components/ui/form/fields/Field.vue';
import Button from '@/components/ui/button/Button.vue';

export default {
    name:'CatalogueFieldMapper',
    components: {
        Field,
        Button
    },

    props: {
        value: Object,
        valueKeyToDisplay:String,
        options:Array,
        size:Array,
        model:Object,
        editable: {
            type: Boolean,
            default: false
        },
        disabled:{
            type: Boolean,
            default:false
        },
        isOptional:Boolean
    },
    data() {
        return {
            filled:false,
            check :'/img/icons/check.svg',
            fail :'/img/icons/fail.svg',
            edit:false,
            textFieldModel: '',
            selectedField: null
        }
    },
    computed: {
        ModelKeyByValue(){
            return this.model && this.value.name ? Object.keys(this.model).find(key => this.model[key]) : ''
        },
        isDataExists(){
            return this.value.name in this.model || Object.values(this.model).includes(this.value.name);
        },
        isValueInOptions() {
            // Check if this.value exists in the model
            if (this.model[this.value.name]) {
                // Find the value from the model
                const valueFromModel = this.model[this.value.name];
                
                // Check if the value from the model exists in the options array
                const matchingOption = this.options.find(option => option.value === valueFromModel);
                
                // Return true if a matching option is found, otherwise return false
                return !!matchingOption;
            }
            return false;
        },
        selectFieldModel() {
            return !this.isOptional 
                ? this.model[this.value.name]
                : Object.keys(this.model).find(elem => this.model[elem] === this.value.name)
        },
        textFieldModelLength(){
            return this.textFieldModel.length
        }
    },
    methods: {
        ...mapActions(['cataloguePostField']),
        emitDatasToParent(e){
            if(this.$refs.select && e?.length){
                this.filled = true
                this.selectedField = e
                if(!this.isOptional){
                    this.$emit('change',{name:this.value.name,value:e,type:'mandatory'})
                } else {
                    this.$emit('change',{name:e,value:this.value.name,type:'optional'})
                }
            }
            if(this.$refs.text && e?.length){
                this.textFieldModel = e
            }
        },
        sanitizeString(inputStr) {
            // Remove accents
            let sanitizedStr = inputStr.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    
            // Remove special characters, but allow a to z in uppercase or lowercase, all numbers, _ and space
            sanitizedStr = sanitizedStr.replace(/[^a-zA-Z0-9_ ]/g, '');
                
            // Replace multiple spaces with a single space
            sanitizedStr = sanitizedStr.replace(/\s+/g, ' ');

            // Trim both ends
            sanitizedStr = sanitizedStr.trim();
            
            // Replace spaces with underscores
            sanitizedStr = sanitizedStr.replace(/ /g, "_");
            
            return sanitizedStr.toLowerCase();
        },

        addField(){
            if(this.textFieldModel.length){
                this.cataloguePostField({clientID: parseInt(this.$route.params.id) ,name:this.sanitizeString(this.textFieldModel), label:this.textFieldModel, })
                    .then(data => {
                        this.edit = false
                        this.$emit('change',{name:data.name,value:this.value.name,type:'optional'})
                        this.selectedField = data.name
                        this.filled = true
                    })
                }
                
        },
        deleteKey(){
            this.$emit('delete_key_of_model', this.selectedField)
            this.selectedField = ''
        }
    },
}
</script>

<style lang="scss" scoped>
.field-mapper {
    position: relative;
    display: flex;
    align-items: center;
    min-width: 100%;
    background:var(--color-bg-light);
    justify-content: center;
    &.is-edited {
        padding: 0 10px 0 84px
    }
    &.disabled {
        opacity: .5;
        pointer-events: none;
    }

    &__container {
        display: inline-flex;
        gap: 20px;
        padding: 10px;
        align-items: center;
        .field {
            margin-top: 0;
            .field__input {
                background: red;
                input {width: 40%;}
            }
        }
    }
    .disabled {
        opacity: .5;
    }
    @media screen and (max-width:800px){
        flex-direction: column;
        padding: 20px 0;
        &__container {
            flex-direction: column;
        }
        &.is-edited {
            padding:  20px 0;
        }
        &:nth-child(2n+1) {
           background-color: var(--color-bg-grey);
        }
    }
    &__buttons {
        display: flex;
        align-items: center;
        gap: 10px;
        position: absolute;
        right: 10px;
        @media screen and (max-width:800px){
            position: initial;
        }
        &__button {
            margin-top: 0;
        }
    }
}
</style>