<template>
    <TemplateTable
            :title="$t('menu.recom-resales-stocks')"
            :text="$t('resalesStocks.description')"
            name="resalesStocks"
            :url="$listRoutes.recomResalesStock"
            :table="resalesStocks"
            :filters="resalesStocksFilters"
            :fields="fields"
            :display-date="true"
    />
</template>

<script>
import TemplateTable from '@/components/templates/TemplateTable';
import { mapState } from 'vuex';
export default {
    name: 'RecomResalesStocks',
    components: {TemplateTable},
    data() {
        return {
            fields: [
                {key: 'brand', type: 'client', size: 'l'},
                {key: 'reference', size: 'l', translationKey: "article_id"},
                {key: 'controlReview.ean', size: 'l'},
                {key: 'controlReview.price', type: 'formatPrice', size: 'l', translationKey: 'tradeinPrice'},
                {key: 'priceResale', type: 'formatPrice', size: 'l'},
                {key: 'controlReview.createdAt', type: 'date', size: 'm', translationKey: 'creditedAt'},
                {key: 'published'},
                {key: 'lastSell'},
                {key: 'lastReturn'},
                {key: 'tradeInCart.source', type: 'tagType', translationKey: 'articleSource', size: 'm'},
                {key: 'controlReview.condition', type: 'tagType'},
                {key: 'tradeInCart.customer.countryCode'},
            ]
        }
    },
    computed: {
        ...mapState(['resalesStocks', 'resalesStocksFilters'])
    }
}
</script>