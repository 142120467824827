<template>
    <SvgLoader class="page-loader"/>
</template>
<script>
import SvgLoader from '@/components/ui/svg/SvgLoader';
export default {
    components: {SvgLoader}
}
</script>

<style lang="scss">
.page-loader {
    width: 100px;
    height: auto;
    max-width: 100%;
    display: block;
    margin: 20vh auto;

    &,
    path {
        fill: var(--color-text-grey);
    }
}
</style>