<template>
    <TemplateTable
            :title="$t('menu.orders')"
            :text="$t('orders.description')"
            name="orders"
            :url="$listRoutes.orders"
            :table="orders"
            :filters="ordersFilters"
            :fields="fields"
    />
</template>

<script>
import { mapState } from 'vuex';
import TemplateTable from '@/components/templates/TemplateTable';
export default {
    name: 'OrdersPage',
    components: {TemplateTable},
    data() {
        return {
            fields: [
                {key: 'order.id', type: 'eye', route: 'order', size: 'xs'},
                {key: 'orderReference', type: 'shopifyOrder', size: 'm'},
                {key: 'client', type: 'client', size: 'l'},
                {key: 'user', type: 'userEmail', size: 'xl'},
                {key: 'orderCreatedAt', type: 'date'},
                {key: 'itemNb'},
                {key: 'amountWithoutTax', type: 'formatPrice'},
                {key: 'amountWithTax', type: 'formatPrice'},
                {key: 'returnItemNb'},
                {key: 'saleType', type: 'tagType'},
                {key: 'paymentStatus', type: 'tagType', size: 'm'},
                {key: 'saleCountry'},
            ]
        }
    },
    computed: {
        ...mapState(['orders', 'ordersFilters'])
    }
}
</script>