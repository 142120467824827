<template>
    <div class="markets-selector">
        <TextSimple size="m" weight="normal">{{ $t(`collections.in_popin_markets`) }}</TextSimple>
        <MarketsSwitcher v-for="markets in marketsSelector" :key="markets.countryCode" 
            :data="markets"
            :toggle="markets.isPresent"
            :text="markets.countryCode"
            @change="catalogueCollectionUpdateStateMarkets"
        />
    </div>
</template>

<script>
import { mapState,mapActions } from 'vuex';
import MarketsSwitcher from './MarketsSwitcher.vue';
import TextSimple from '../text/TextSimple.vue';
export default {
    name: 'MarketsSelector',
    components: {
        TextSimple,
        MarketsSwitcher,
    },
    props: {
        marketsToCompare:Array,
        marketsSelector:Array
    },
    computed: {
        ...mapState(['configMarkets','catalogueCollection']),
    },
    methods: {
        ...mapActions(['catalogueCollectionUpdateStateMarkets']),
    },
}
</script>

<style lang="scss" scoped>
.markets-selector {
    & > div {
        display: flex;
        gap: 20px;
        align-items: center;
        margin-bottom: 20px;
    }
}
</style>