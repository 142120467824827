<template>
    <div class="form-subtitle">
        <TextSimple size="xs" color="grey"><slot/></TextSimple>
    </div>
</template>

<script>
import TextSimple from '@/components/ui/text/TextSimple';
export default {
    name: 'FormSubtitle',
    components: {TextSimple}
}
</script>

<style lang="scss">
.form-subtitle {
    position: relative;
    margin-top: 20px;

    & > * {
        position: relative;
        z-index: 2;
        background-color: var(--color-bg-light);
        padding: 2px 5px;
        display: block;
        width: fit-content;
        max-width: 100%;
        margin: 0 auto;
    }

    &:before {
        content: '';
        display: block;
        width: 100%;
        border-top: var(--border-1);
        position: absolute;
        left: 0;
        top: 50%;
        z-index: 1;
    }
}
</style>