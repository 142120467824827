<template>
    <div class="collections-condition">
        <div v-if="index > 0" class="collections-condition-header">
            <TextSimple size="m" weight="bold">{{ $t('collections.and')}}</TextSimple>
        </div>
        <div class="collections-condition-datafields" :class="warning ? 'warning' : false">        
            <div class="collections-condition-datafields__fields">
                <div class="collections-condition-datafields__inputs">  
                                    
                    <FieldSelect :multiple="false" :selected="selectedField" :border="true" :options="fields"  @input="e => selectedField = e"/>
                    
                    <FieldSelect v-if="operatorsValue && displaySelect" :multiple="false" :selected="selectedOperator" :border="true" :options="operatorsValue"  @input="e => selectedOperator = e"/>
                    <TextSimple 
                        v-if="loading"
                        :class="{'loading': loading}"
                        weight="bold"
                        >{{$t('collections.data_loading')}}</TextSimple>
                    <div class="collections-condition-datafields__fields">
                        <FieldSelect v-if="selectedOperator && !loading" :multiple="true" :selected="selectedValue" :border="true" :options="catalogueFieldListValueFormated"  @input="e => selectedValue = e || []"/>
                        <TextSimple 
                            v-if="(selectedOperator && !loading) && catalogueFieldListValueFormated.length === 0"
                            weight="bold"
                            >{{$t('collections.no_values_to_display')}}</TextSimple>
                    </div>

                </div>
            
                <div v-show="index > 0" class="collections-condition-datafields__button">
                    <Button @click.prevent.native="deleteItem" icon="cross" color="red" size="s">{{ $t('global.delete') }}</Button>
                </div>
            </div>
            <TextSimple v-if="warning" weight="bold" color:red>{{$t('collections.no_values_selected')}}</TextSimple>
            <div v-if="selectedValue.length && !loading" class="collections-condition-datafields__badges">
                
                <ButtonWithLabel
                    :datas="selectedValue"
                    color="grey"
                    size="s"
                    icon="cross"
                    :labelText="$t('collections.or')"
                    @change="e => deleteFromSelectedValue(e)"
                />

            </div>
        </div>
    </div>
</template>

<script>
import FieldSelect from '@/components/ui/form/fields/FieldSelect.vue';
import Button from '@/components/ui/button/Button.vue';
import ButtonWithLabel from '@/components/ui/button/ButtonWithLabel.vue';
import TextSimple from '@/components/ui/text/TextSimple.vue';
import { mapActions, mapState } from 'vuex';
export default {
    name: 'CollectionsConditionsCatalogueDataFields',
    components: {
        FieldSelect,
        TextSimple,
        Button,
        ButtonWithLabel
    },
    props: {
        fieldsValues: Array,
        model:Object,
        index: Number
    },
    data() {
        return {
            selectedField: null,
            selectedOperator: null,
            selectedValue:[],
            displaySelect: false,
            loading:false,
            warning:false,
        }
    },
    watch: {
        selectedField: {
            handler(newValue, oldValue) {
                // console.log('selectedField', oldValue, newValue);

                // Réinitialise l'avertissement si on passe de null à une chaîne vide
                if (oldValue === null && newValue === '') {
                    this.warning = false;
                }
                // Si newValue n'est pas vide et oldValue est null, ou si newValue est différent de oldValue
                // et oldValue n'est pas null, alors effectuer les actions.
                if ((!oldValue && newValue.length) || (newValue !== oldValue && oldValue)) {
                    // Si oldValue est truthy et différent de newValue, réinitialiser selectedOperator et selectedValue
                    if (oldValue) {
                        this.selectedOperator = '';
                        this.selectedValue = [];
                        this.catalogueCollectionsEmptyCondition(this.index);
                        this.warning = false;
                    }

                    // Obtenir les valeurs disponibles pour le champ sélectionné
                    this.getAvailableValuesFromSelectedField();
                    // this.$set(this,'labelValue', this.fields.filter(elem => elem.id === this.selectedField)[0].label)
                }
            }
        },

        payload: {
            handler(newVal){
                if(newVal) this.emitData()
            }
        },
    },
    computed: {
        ...mapState([
            'catalogueCollection',
            'catalogueFieldListValue'
        ]),
        defaultLocale(){
            return this.$route.params.lang
        },
        fields() {
            return this.fieldsValues?.map(elem => ({ id: elem.name, label: elem.label })) || [];
        },

        operators() {
            return this.selectedField && this.fieldsValues?.find(elem => elem.name === this.selectedField)?.operators || [];
        },
        operatorsValue() {
            return this.operators.length && this.transformFieldsValuesOperators(this.operators) || [];
        },

        filterType() {
            return this.fieldsValues?.find(elem => elem.name === this.selectedField)?.filter_type || '';
        },
        catalogueFieldListValueFormated() {
            return this.catalogueFieldListValue && this.selectedField && this.catalogueFieldListValue[this.selectedField]?.map(elem => ({ id: elem, label: elem })) || '';
        },
        isConditionsExists(){
            return !!Object.keys(this.model)?.length
        },
        payload(){
            return {
                    field: this.selectedField,
                    operator: this.selectedOperator,
                    label:this.label,
                    value: this.selectedValue,
                    index:this.index
                }
        },
        conditions(){
            return this.isConditionsExists && this.model || false
        },
        conditionsField(){
            return this.conditions && this.conditions.field || ''
        },
        conditionsOperator(){
            return this.conditions && this.conditions.operator || ''
        },
        conditionsValue(){
            return this.conditions && this.conditions.value || []
        },
        label() {
            return this.selectedField && this.fields.find(elem => elem.id === this.selectedField)?.label || '';
        },
    },
    methods: {
        ...mapActions([
            'catalogueGetFieldListValue',
            'catalogueCollectionsEmptyCondition'
        ]),
        transformFieldsValuesOperators(an_array){
            return an_array.map(item => ({
                label: this.$t(`collections.collections_operator_${item}`),
                id: item
            }));
        },
        findOperators(){
            this.getOperators = this.fieldsValues.find(elem => elem.name === this.selectedField).operators
        },
        getAvailableValuesFromSelectedField(){
            const payload = {
                locale:this.defaultLocale,
                brand_id:this.$route.params.id,
                field: this.selectedField
            }
            this.loading = true
            this.catalogueGetFieldListValue(payload)
                .then(res =>{
                    if(res.data.length > 0){
                        this.loading = false
                        this.displaySelect = true
                    }
                })
        },
        emitData() {
            if (this.selectedField && this.selectedOperator && this.selectedValue ) {
                this.$emit('change', {payload: this.payload, type: "conditions"});
            }
        },
        deleteItem(){
            this.$emit('removeCondition', this.index);
        },
        deleteFromSelectedValue(e){
            this.selectedValue = this.selectedValue.filter(elem => elem !==e)
        }
    },
    
    mounted () {
        if(this.$route.params.collectionID && this.defaultLocale){
            this.selectedField = this.conditionsField
            this.selectedOperator = this.conditionsOperator
            this.selectedValue = this.conditionsValue
        }
    },

}
</script>

<style lang="scss" scoped>
.collections-condition {
    &-header {
        margin-left: 20px;
        margin-bottom: 5px;
        text-transform: uppercase;
    }
    &-datafields {
        display: flex;
        flex-direction: column;
        border: var(--border-2);
        border-radius: var(--border-radius-2);
        margin-bottom: 20px;
        padding: 0 20px;
        &.warning {
            background: var(--color-red-light);
        }
        &__fields {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &__inputs {
            display: flex;
            gap: 20px;
            & > * {
                margin: 20px 0;
                width: 200px;
            }
        }
        @media screen and (max-width: 720px) {
            &__inputs {
                flex-direction: column;
                padding-top: 20px;
                flex:1;
                & > * {
                    margin: 0;
                    width: 100%;
                }
            }
        }
        @keyframes fade {
            0%, 100% { opacity: 0; }
            50% { opacity: 1; }
        }
        .loading {
            animation: fade 2s infinite;
        }
    }
    
}
</style>