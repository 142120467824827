<template>
    <section class="section-categories">
        <Field v-if="options" :model="selected" type="select" :options="options" @change="e => $emit('change', e)"/>
        <ul v-if="categories">
            <li v-for="category in categories"
                :class="{'is-active': selected === category.name}"
                @click.prevent="$emit('change', category.name)"
                :key="category.name" :data-testid="'chart-lines-categories-filter' + category.name">{{ category.title }}</li>
        </ul>
        <div class="section-categories__container" v-if="legend1">
            <ul class="section-categories__legend" v-if="displayLegend">
                <li>{{ legend1 }}</li>
                <li v-if="legend2">{{ legend2 }}</li>
            </ul>
            <ul class="section-categories__lines">
                <slot/>
            </ul>
        </div>
    </section>
</template>

<script>
import Field from '@/components/ui/form/fields/Field';
export default {
    name: 'SectionCategories',
    components: {Field},
    props: {
        categories: Array,
        selected: String,
        legend1: String,
        legend2: String,
        displayLegend: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        options() {
            if (!this.categories) return [];
            return this.categories.map(c => ({name: c.title, value: c.name}))
        }
    }
}
</script>

<style lang="scss">
.section-categories {
    background-color: #ffff;
    border-radius: var(--border-radius-1);
    margin-bottom: 30px;

    @media screen and (min-width: 801px) {
        display: flex;
        align-items: flex-start;
    }

    & > ul {
        flex-shrink: 0;
        padding: 15px;
        position: sticky;
        top: calc(80px + var(--height-header));

        @media screen and (max-width: 1199px) {
            top: 0;
        }

        @media screen and (max-width: 800px) {
            display: none;
        }

        li {
            padding: 10px 15px;
            border-radius: 40px;
            margin-bottom: 5px;
            transition: background-color ease .3s;
            cursor: pointer;

            @media screen and (min-width: 1025px) {
                &:hover {
                    background-color: var(--color-bg-light);
                }
            }

            &.is-active {
                background-color: var(--color-bg-grey);
            }
        }
    }

    &__container {
        flex-grow: 1;

        @media screen and (min-width: 801px) {
            border-left: var(--border-1);
        }
    }

    .apexcharts-legend,
    &__legend {
        padding: 30px;
        display: flex;
        align-items: center;
        gap: 30px;
        z-index: 2;
        background-color: #fff;
        position: sticky;
        top: calc(80px + var(--height-header));

        @media screen and (max-width: 1199px) {
            top: var(--height-header);
        }

        @media screen and (max-width: 550px) {
            padding: 10px 15px;
            margin-bottom: 10px;
        }

        li {
            display: flex;
            align-items: center;

            &:before {
                display: block;
                content: '';
                width: 16px;
                height: 16px;
                margin-right: 5px;
                border-radius: 100%;
                background: linear-gradient(135deg,#fff 7.5%,var(--color-text-grey) 0,var(--color-text-grey) 42.5%,#fff 0,#fff 57.5%,var(--color-text-grey) 0,var(--color-text-grey) 92.5%,#fff 0);
                background-size: 5px 5px;
                background-position: 5px 5px;
            }

            &:first-of-type:before {
                background: var(--color-text-grey);
            }
        }
    }

    & > .field {
        padding: 15px 30px 0;

        @media screen and (max-width: 550px) {
            padding: 15px;
        }

        @media screen and (min-width: 801px) {
            display: none;
        }

        .field__input {
            border: var(--border-1);
        }
    }
}
</style>