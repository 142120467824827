<template>
    <StoreForm
            :title="$t('store.create_btn')"
            :action="createStore"
            @success="handleSuccess"
    />
</template>

<script>
import StoreForm from '@/components/ui/stores/StoreForm';
import ApiAdmin from '@/api/api-admin';
export default {
    components: {StoreForm},
    computed: {
        id() {
            return this.$route.params.id
        }
    },
    methods: {
        createStore(data) {
            return new Promise((resolve, reject) => {
                let playload = {...data};
                ["isActive", "isTest", "salesPoint", "resalesPoint"].forEach(name => {
                    if (!playload[name]) playload[name] = false;
                });
                playload.schedule = JSON.stringify(playload.schedule);
                playload.bookingSchedule = JSON.stringify(playload.bookingSchedule);
                ApiAdmin.post(this.$listRoutes.stores.replace(':id', this.id), playload)
                        .then(() => resolve())
                        .catch((err) => reject(err))
            })
        },
        handleSuccess() {
            this.$router.push({name: 'client-stores', params: {id: this.id}})
        }
    }
}
</script>
