<template>
    <TemplateTable
            :title="$t('menu.bills')"
            name="bills"
            :url="$listRoutes.invoices"
            :table="bills"
            :filters="billsFilters"
            :fields="fields"
    />
</template>

<script>
import { mapState } from 'vuex';
import TemplateTable from '@/components/templates/TemplateTable';
export default {
    name: 'InvoicesPage',
    components: {TemplateTable},
    data() {
        return {
            fields: [
                {key: 'id', type: 'eye', route: 'invoice', size: 'xs'},
                {key: 'client', type: 'client', size: 'l'},
                {key: 'invoiceNumber', type: 'copy', size: 'm'},
                {key: 'clientAgent', size: 'm'},
                //{key: 'clientName'},
                //{key: 'clientCity'},
                {key: 'invoiceDate', type: 'date'},
                {key: 'period', type: 'invoicePeriod', noSortable: true, size: 'l'},
                {key: 'invoicePreviousBalance', type: 'formatPrice'},
                {key: 'invoiceType', type: 'tagType', size: 'm'},
                {key: 'clientCountry'},
                {key: 'id', type: 'download', route: this.$listRoutes.financeinvoicePdfInvoicePdf, size: 'xs'},
            ]
        }
    },
    computed: {
        ...mapState(['bills', 'billsFilters'])
    }
}
</script>