<template>
    <div class="page-stores">
        <PageLoader v-if="!clients"/>
        <PageError v-else-if="!client" :subtitle="$t('client.not-found')" :button="$t('client.return')" :to="{name: 'clients'}"/>
        <div v-else>

            <PageBack/>

            <PageHeader :title="client.name" subtitle="Les boutiques">
                <template v-slot:actions>
                    <Field v-if="stores?.length" :label="$t('global.name')" :model="search" @change="e => search = e" icon="search"/>
                    <ButtonLink size="m" icon="plus" color="black" :to="{name: 'client-store-create', params: {id: id}}">{{ $t('store.create_btn') }}</ButtonLink>
                </template>
            </PageHeader>


            <PageLoader v-if="!stores"/>

            <p v-else-if="!stores.length">{{ $t('store.no_store') }}</p>

            <CheckerboardContainer v-else>
                <CheckerboardItem v-for="(store, i) in storesSorted" :key="i">
                    <TextTitle size="xs">{{ store.name }}</TextTitle>
                    <ButtonLink :to="{name: 'client-store', params: {id: id, storeId: store.id}}" icon="right" size="s" color="black">
                        {{ $t('store.see_store') }}
                    </ButtonLink>
                    <ButtonLink :to="{name: 'client-store-edit', params: {id: id, storeId: store.id}}" icon="edit" size="s" color="grey">
                        {{ $t('global.edit') }}
                    </ButtonLink>
                </CheckerboardItem>
            </CheckerboardContainer>

            <TablePagination v-if="pagination" :pagination="pagination"/>
        </div>
    </div>
</template>

<script>
import PageLoader from '@/components/ui/page/PageLoader';
import { mapState } from 'vuex';
import PageError from '@/components/ui/page/PageError';
import PageHeader from '@/components/ui/page/PageHeader';
import ButtonLink from '@/components/ui/button/ButtonLink';
import PageBack from '@/components/ui/page/PageBack';
import ApiAdmin from '@/api/api-admin';
import CheckerboardContainer from '@/components/ui/checkerboard/Checkerboard';
import CheckerboardItem from '@/components/ui/checkerboard/CheckerboardItem';
import TextTitle from '@/components/ui/text/TextTitle';
import Field from '@/components/ui/form/fields/Field';
import TablePagination from '@/components/ui/table/TablePagination';

export default {
    name: 'StoresPage',
    components: {
        TablePagination,
        Field, TextTitle, CheckerboardItem, CheckerboardContainer, PageBack, ButtonLink, PageHeader, PageError, PageLoader},
    data() {
        return {
            stores: null,
            search: '',
            pagination: null,
            fields: [
                {key: 'name'},
                {key: 'nameUrl'},
                {key: 'emailLogistic'},
                {key: 'frontUrl', type: 'url'},
                {key: 'frontUrlClient', type: 'url'},
                {key: 'shopifyUrl', type: 'url'},
                {key: 'domain', type: 'url'},
                {key: 'codeTypeReal'},
                {key: 'autoCreditedWallet', type: 'boolean'},
                {key: 'isRef', type: 'boolean'},
                {key: 'promoByProduct', type: 'boolean'},
                {key: 'resellActivated', type: 'boolean'},
                {key: 'shopClientActivated', type: 'boolean'},
                {key: 'shopIRLActivated', type: 'boolean'},
                {key: 'skipStep1', type: 'boolean'},
                {key: 'status'},
            ]
        }
    },
    computed: {
        ...mapState(['clients']),
        id() {
            return this.$route.params.id
        },
        client() {
            return this.$store.getters.getClientById(this.id)
        },
        storesSorted() {
            if (!this.stores?.length) return []
            let stores = [...this.stores];
            if (this.search && this.search !== '') {
                const terms = this.search.toLowerCase().trim();
                stores = stores.filter(s => s.name.toLowerCase().includes(terms))
            }
            return stores.sort((a, b) => a.name.localeCompare(b.name))
        },
        page() {
            return this.$route?.query?.page || 1
        },
    },
    watch: {
      page() {
          this.getStores();
      },
    },
    methods: {
        getStores() {
            ApiAdmin.get(this.$listRoutes.stores.replace(':id', this.id) + `?page=${this.page}&limit=20`)
                    .then(res => {
                        this.stores = res.data.items;
                        this.pagination = res.data.pagination;
                    })
        }
    },
    mounted() {
        this.getStores()
    }
}
</script>


<style lang="scss">
.page-stores {
    .page-header {
        @media screen and (max-width: 500px) {
            flex-direction: column;

            &__actions {
                margin-left: 0;
            }
        }

        .field {
            margin-top: 0;
        }
    }

    .checkerboard-item {
        .button ~ .button {
            margin-top: 0;
        }
    }
}
</style>