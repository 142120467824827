<template>
    <FieldFile v-if="type === 'file'" :name="name" :allowedExtensions="accept" @change="e => value = e" :text="filetext" :required="required" />
    <label v-else class="field" :class="{'has-icon': icon, 'is-switch': isSwitch, 'has-border': border, 'is-disabled': disabled}">
        <span class="field__input" :class="[{'is-textarea': type === 'textarea'}, {'is-color': type === 'color'}]">
            <select v-if="type === 'select'" :name="name" :required="required" v-model="value" :autocomplete="autocomplete ? 'on' : 'off'">
                 <option v-for="option in optionsSorted" :disabled="option.disabled" :key="option.value || option.id" :value="option.value || option.id" @click.prevent="value = option.value || option.id">{{ option.name || option.label }}</option>
            </select>
            <textarea v-else-if="type === 'textarea'" :name="name" :required="required" v-model="value" :autocomplete="autocomplete ? 'on' : 'off'"></textarea>
            <InputBoolean v-else-if="type === 'boolean'" :model="value" @change="e => value = e"/>
            <template v-else-if="type === 'color'">
                <input type="text" v-model="value" @change="e => value = e.target.value" />
                <input type="color" :name="name" v-model="value" @change="e => value = e.target.value" :placeholder="placeholder"/>
            </template>
            <template v-else-if="type === 'customDate'">
                <input type="datetime-local" :name="name" v-model="value" @change="e => value = e.target.value" :placeholder="placeholder"/>
            </template>
            <input v-else-if="!$slots || !$slots.default" :type="type" :disabled="disabled" :class="disabled ? 'disabled' : ''" :name="name" :min="min" :data-testid="testid" :max="max" :step="step" :required="required" v-model="value" :placeholder="placeholder" :autocomplete="autocomplete ? 'on' : 'off'">
            <slot/>
        </span>
        <span v-if="label" class="field__label" :class="{'is-up': value || value === 0 || !model}">{{ label }}</span>
        <SvgIcon v-if="icon" :name="icon"/>
    </label>
</template>

<script>
import SvgIcon from '@/components/ui/svg/SvgIcon';
import InputBoolean from '@/components/ui/form/inputs/InputBoolean';
import FieldFile from './FieldFile.vue';

export default {
    name: 'FieldGlobal',
    components: {
        InputBoolean,
        SvgIcon,
        FieldFile
    },
    props: {
        type: {
            type: String,
            default: 'text'
        },
        filetext: String,
        name: String,
        label: String,
        options: Array,
        model: [String, Number, Boolean],
        placeholder: String,
        required: Boolean,
        step: Number,
        max: Number,
        min: Number,
        testid: String,
        icon: String,
        isSwitch: Boolean,
        border: Boolean,
        noSort: Boolean,
        autocomplete: {
            type: Boolean,
            default: true
        },
        disabled:Boolean,
        accept:Array,
        fileName: String,
    },
    data() {
        return {
            value: this.model,
        }
    },
    watch: {
        model(e) {
            if (e !== this.value) this.value = e
        },
        value() {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(() => {
                this.$emit('change', this.value)
            }, 300);
        }
    },
    computed: {
        optionsSorted() {
            if (!this.options) return null;
            if (!this.options.length) return [];
            if (!this.noSort) return this.options;
            return [...this.options].sort((a, b) => {
                let x = a.label ? a.label.toLowerCase() : a.name ? a.name.toLowerCase() : '';
                let y = b.label ? b.label.toLowerCase() : b.name ? b.name.toLowerCase() : '';
                return x < y ? -1 : x > y ? 1 : 0
            })
        }
    }
}
</script>

<style lang="scss">
.field {
    position: relative;
    margin-top: 20px;
    display: block;

    @media screen and (min-width: 1025px) {

        &:hover {
            .field__label {
                color: #000;
            }
        }
    }

    &.is-switch {

        .field__input {
            background-color: #fff;
            padding: 0;
        }

        .form-switch {
            border-color: #fff;
            //border-width: 10px;

            &__bg {
                background-color: var(--color-bg-grey);
            }
        }
    }

    &.has-border {
        .field__input {
            border: var(--border-2);
        }

        .field__label {
            background-color: #fff;
            padding: 0 4px;
        }
    }

    & > .icon {
        width: 13px;
        position: absolute;
        pointer-events: none;
        top: 0;
        right: var(--field-padding);
        height: 100%;
        display: flex;
        align-items: center;
    }

    &__label {
        position: absolute;
        left: var(--field-padding);
        top: calc(var(--field-height) / 2);
        transform: translateY(-50%);
        pointer-events: none;
        transition: top ease-out .2s, font-size ease-out .2s, color ease-out .2s;
        font-size: 1.3rem;
        color: #999;
        font-weight: 400;

        &.is-up {
            top: 0;
            font-size: 1.1rem;
        }
    }

    &__input {
        width: 100%;
        background-color: #fff;
        padding: 0 var(--field-padding);
        display: block;
        border-radius: var(--field-height);
        min-height: var(--field-height);

        & > * {
            display: block;
            width: 100%;
            font-size: 1.4rem;
            background-color: transparent;
        }

        select, input, textarea {
            width: 100%;
            height: var(--field-height);
        }

        &:focus ~ .field__label {
            top: 0;
            font-size: 1.1rem;
        }

        &.is-textarea {
            border-radius: var(--border-radius-2);
        }

        &.is-color {
            display: flex;
            align-items: center;
        }
    }

    &.has-icon {
        .field__input {
            padding-right: calc(var(--field-padding) + 13px + 10px);
        }
    }

    input {
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px white inset;
        }

        &::placeholder {
            color: #ccc;
            font-size: 1.3rem;
            letter-spacing: .2px;
        }

        &[type="color"]{
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            width: 33px;
            height: 33px;
            padding: 0px;
            background-color: transparent;
            border: none;
            cursor: pointer;
            border-radius: 50px;

            &::-webkit-color-swatch{
                border-radius: 50px;
                border: none;
            }

            &::-moz-color-swatch{
                border-radius: 50px;
                border: none;
            }
        }
    }

    /*
    input, select {
        height: var(--field-height);
        border-radius: var(--field-height);
    }

     */

    select {
        cursor: pointer;
    }

    textarea {
        padding: 15px 0;
        min-width: 100%;
        max-width: 100%;
        min-height: 80px;
    }

    &.is-disabled {
        pointer-events: none;

        input, select, textarea, input::placeholder,
        .field__label {
            color: var(--color-text-grey);
        }
    }
}
</style>