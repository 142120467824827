<template>
    <TemplateTable
            :title="$t('menu.photos')"
            :text="$t('resalesPhotos.description')"
            name="resalesPhotos"
            :url="$listRoutes.resalesPhotos"
            :table="resalesPhotos"
            :filters="resalesPhotosFilters"
            :fields="fields"
    />
</template>

<script>
import { mapState } from 'vuex';
import TemplateTable from '@/components/templates/TemplateTable';
export default {
    name: 'ResalesPhotos',
    components: {TemplateTable},
    data() {
        return {
            fields: [
                //{key: 'id', type: 'eye', route: 'resale', size: 'xs'},
                //{key: 'shopifyIds', type: 'shopifyProduct'},
                {key: 'client', type: 'client', size: 'l'},
                {key: 'type'},
                {key: 'shopifyIds', type: 'copy', size: 'm'},
                {key: 'url', type: 'imgUrl', size: 'm'},
                {key: 'shootedAt', type: 'date'},
                {key: 'width'},
                {key: 'height'},
                {key: 'irl', type: 'boolean'},
                {key: 'realStore', type: 'realStore', size: 'xxl'},
            ]
        }
    },
    computed: {
        ...mapState(['resalesPhotos', 'resalesPhotosFilters'])
    }
}
</script>