<template>
    <Tag :color="tagColor" :text="`${value || 0} %`" :icon-left="icon"/>
</template>

<script>
import Tag from '@/components/ui/tag/Tag';
export default {
    name: 'TagStatistic',
    components: {Tag},
    props: {
        value: [Number, String],
        color: String
    },
    computed: {
        tagColor() {
            if (this.color) return this.color;
            return this.value > 0 ? 'green' : this.value < 0 ? 'red' : ''
        },
        icon() {
            return `arrow right${this.value > 0 ? ' up' : this.value < 0 ? ' down' : ''}`;
        }
    }
}
</script>