<template>
    <div class="template-confirmation">
        <TextTitle size="l">{{ title }}</TextTitle>
        <TextSimple>{{ text }}</TextSimple>
        <slot/>
        <ButtonLink v-if="button && to" :to="to" size="m" color="black" icon="right">{{ button }}</ButtonLink>
        <slot name="bottom"/>
    </div>
</template>

<script>
import TextTitle from '@/components/ui/text/TextTitle';
import TextSimple from '@/components/ui/text/TextSimple';
import ButtonLink from '@/components/ui/button/ButtonLink';
export default {
    name: 'TemplateConfirmation',
    components: {ButtonLink, TextSimple, TextTitle},
    props: {
        title: String,
        text: String,
        button: String,
        to: [Object, String]
    }
}
</script>

<style lang="scss">
.template-confirmation {
    text-align: center;
    margin: auto;
    max-width: 1000px;

    .button {
        margin: 30px auto 15px;
    }

    .form-message,
    .button,
    .link-underline {
        margin-right: auto;
        margin-left: auto;
    }

    .title {
        margin-bottom: 20px;
    }
}
</style>