<template>
    <div class="products-listing">
        <slot/>
    </div>
</template>

<script>
export default {
    name: 'ProductListing'
}
</script>

<style lang="scss">
.products-listing {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 25px 15px;

    @media screen and (min-width: 1200px) {
        grid-template-columns: repeat(6, 1fr);
    }

    @media screen and (min-width: 1500px) {
        grid-template-columns: repeat(7, 1fr);
    }

    @media screen and (max-width: 800px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 400px) {
        grid-template-columns: repeat(2, 1fr);
    }

    .product-card {
        height: 100%;
    }
}
</style>