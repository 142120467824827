<template>
    <TemplateDetails :data="configMarkets" :loading="configMarketsLoading" :get-function="getMarketsConfigs">

        <PageBack/>
    
        <PageHeader :title="$t('market.titleConfigMarket') | replace('{{name}}', client.name + ' ' + marketName)">
            <template v-slot:text>
                <div class="market-country__default">
                    <ButtonCustom v-if="market?.isDefault" icon="stars" color="yellow" size="s" :no-event="true" :big-icon="true">{{ $t('market.default')}}</ButtonCustom>
                </div>
            </template>
            <template v-slot:actions v-if="!market?.isDefault">
                <ButtonCustom @click.native.prevent="$refs.popinDelete.open()" size="m" color="red" icon="trash">{{ $t('global.delete') }}</ButtonCustom>
            </template>
        </PageHeader>

        <GridContainer>
            <Checkerboard>
                <CheckerboardItem v-for="card in cards" :key="card.title">
                    <TextTitle size="s">{{ card.title }}</TextTitle>
                    <SvgIcon :name= "card.imageSrc "/>
                    <ButtonLink size="s" icon="right" color="grey" :to="card.route">{{ $t('menu.settings') }}</ButtonLink>
                </CheckerboardItem>
            </Checkerboard>
        </GridContainer>

        <Popin
            :title="$t('market.popinNoSaveTitle')" 
            :subtitle="$t('market.popinDeleteMarket')"
            :center="true"
            ref="popinDelete">
            <template #bottom>
                <ButtonCustom color ="red" @click.native.prevent="$refs.popinDelete.close()">{{ $t('global.cancel') }}</ButtonCustom>
                <ButtonCustom @click.native="deleteSelectedMarket">{{ $t('global.delete') }}</ButtonCustom>
            </template>
        </Popin>
        
    </TemplateDetails>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import PageHeader from '@/components/ui/page/PageHeader';
import ButtonLink from '@/components/ui/button/ButtonLink';
import PageBack from '@/components/ui/page/PageBack';
import GridContainer from '@/components/ui/grid/GridContainer.vue';
import Checkerboard from '@/components/ui/checkerboard/Checkerboard.vue';
import CheckerboardItem from '@/components/ui/checkerboard/CheckerboardItem.vue';
import TextTitle from '@/components/ui/text/TextTitle.vue';
import SvgIcon from '@/components/ui/svg/SvgIcon.vue';
import ButtonCustom from '@/components/ui/button/Button';
import TemplateDetails from '@/components/templates/TemplateDetails.vue';
import Popin from '@/components/ui/popin/Popin.vue';

export default {
    name: 'ConfigWebsiteMarketsCountry',
    components: {Popin,TemplateDetails,ButtonCustom,SvgIcon, TextTitle, ButtonLink, GridContainer, Checkerboard,CheckerboardItem, PageBack, PageHeader},
    data() {
        return {
            selected: null,
            searchTerm: null,
            translationsSearched: null,
            showAddMarketPopin: false,
            availableMarkets: [],
        }
    },
    computed: {
        ...mapState(['configMarkets','configMarketsLoading']),
        id() {
            return this.$route.params.id
        },
        client() {
            return this.$store.getters.getClientById(this.id)
        },
        marketName(){
            return this.$route.params.marketName
        },
        market() {
            return this.configMarkets?.items.find(item => item.name === this.marketName)
        },
        cards() {
            return [
                { 
                    title: this.$t('market.regionAndLanguages'), 
                    imageSrc: 'language', 
                    route: { 
                        name: 'config-website-markets-regions', 
                        params: { id: this.id, marketName: this.marketName, mode: 'modifications'  } 
                    } 
                },
                { title:  this.$t('market.taxAndCustoms'), imageSrc: 'taxes', route: 'route2' },
                { title: this.$t('market.deliveryAndReturn'), imageSrc: 'shipping', route: 'route3' },
                { title: this.$t('market.Currency'), imageSrc: 'devise', route: 'route4' },
                { title: this.$t('market.Domain'), imageSrc: 'domaine', route: 'route5' },
            ];
        },
        selectedMarket() {
            if (this.configMarkets && this.configMarkets.items) {
                return this.configMarkets.items.find(m => m.name === this.marketName);
            } else {
                return null;
            }
        },
        selectedMarketId() {
            if(this.selectedMarket){
                return this.selectedMarket.id
            } else {
                return null
            }
        }
    },
    methods: {
        ...mapActions(['getMarketsConfigs', 'setSelectedMarket','deleteMarketStore','setNotificationMessage']),
       
        async deleteSelectedMarket() {
            await this.deleteMarketStore(this.selectedMarketId); 
            this.$refs.popinDelete.close();
            this.setNotificationMessage(this.$t('market.deletedMarket'));
            this.$router.push({ name: 'config-website-markets' });
        },
    },
    mounted() {
        this.getMarketsConfigs({id: this.id});
    }
}
</script>

<style lang="scss">

</style>