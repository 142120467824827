<template>
    <div class="popin-notification" :class="[ color, {'is-open': isOpen}]" @click.prevent="close">
        <div>
            <TextSimple v-if="message">{{ message }}</TextSimple>
            <slot/>
        </div>
        <SvgIcon name="cross"/>
    </div>
</template>

<script>
import SvgIcon from '@/components/ui/svg/SvgIcon';
import TextSimple from '@/components/ui/text/TextSimple';

export default {
    name: 'PopinNotification',
    components: {TextSimple, SvgIcon},
    props: {
        text: [String, Boolean, Object],
    },
    data() {
        return {
            isOpen: false
        }
    },
    computed: {
        message(){
            if(!this.text) return null
            if(typeof this.text === 'string') return this.text
            if(typeof this.text === 'object' && this.text.text) return this.text.text
            return null
        },  
        color() {
            return this.text && typeof this.text === 'object' ? this.text.color : null
        },
        confirm(){
            return this.text && typeof this.text === 'object' && this.text.confirmation
        }

    },
    methods: {
        open() {
            this.isOpen = true;
            if(!this.confirm){
                setTimeout(() => {
                    this.isOpen = false;
                }, 5000)
            }
        },
        close() {
            this.isOpen = false
        }
    }
}
</script>

<style lang="scss">
.popin-notification {
    position: fixed;
    top: 20px;
    right: 20px;
    width: 100%;
    max-width: 300px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    background-color: #fff;
    box-shadow: var(--box-shadow-1);
    transition: opacity ease-out .3s, transform ease-out .3s;
    text-align: left;
    border-radius: var(--border-radius-2);
    white-space: normal;
    z-index: 9999;

    &.red {
        color: var(--color-red);
        background-color: var(--color-red-light);
    }

    &:not(.is-open) {
        opacity: 0;
        transform: translateY(5px);
        pointer-events: none;
    }

    .icon {
        flex-shrink: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;

        svg {
            width: 12px;
        }
    }
}
</style>