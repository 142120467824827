<template>
    <TemplateDetails :get-function="getInvoicesFolder" :loading="invoicesFolderLoading" :data="invoicesFolder">

        <div v-if="folder">

            <PageBack/>

            <PageHeader :title="$t('finance_invoices_folder.title')">
                <template v-slot:text>
                    <TextSimple color="grey">{{ folder.startDate | date }} - {{ folder.endDate | date }}</TextSimple>
                    <TagItem color="grey" :text="folder.state"/>
                </template>
                <template v-slot:actions>
                    <Button v-if="isAdmin" :loading="financeFolderGenerating" size="m" color="blue2" icon="refresh" @click.native.prevent="regenerateFinanceFolder">{{ $t('global.regenerate') }}</Button>
                    <Button v-if="isAdmin" size="m" color="black" icon="edit" @click.native.prevent="$refs.popinCreateInvoice.open()">{{ $t('invoice.create') }}</Button>
                    <ButtonDownload size="m" color="grey" :route="$listRoutes.invoicesFolderPdf.replace(':id', id)" :object="invoicesFolder">PDF</ButtonDownload>
                </template>
            </PageHeader>

            <div v-if="folder.invoice?.length">
                <TextTitle size="m" :margin="true">{{ $t('menu.bills') }}</TextTitle>
                <TableSticky :fields="fields" :data="folder.invoice"/>
            </div>

        </div>

        <Popin v-if="invoicesFolder" :title="$t('invoice.create')" :subtitle="$t('invoice.create_invoice_confirm')"  :center="true" ref="popinCreateInvoice">
            <template v-slot:content>
                <Button :loading="addLoading" color="blue" size="m" @click.native.prevent="transmitInvoice('OneshotInvoice')">{{ $t('global.OneshotInvoice') }}</Button>
                <Button :loading="addLoading" color="green2" size="m" @click.native.prevent="transmitInvoice('CreditInvoice')">{{ $t('global.CreditInvoice') }}</Button>
            </template>
            <template v-slot:bottom>
                <LinkUnderline @click.native.prevent="$refs.popinCreateInvoice.close()">{{ $t('global.cancel') }}</LinkUnderline>
            </template>
        </Popin>

    </TemplateDetails>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import TemplateDetails from '@/components/templates/TemplateDetails';
import PageBack from '@/components/ui/page/PageBack';
import PageHeader from '@/components/ui/page/PageHeader';
import TableSticky from '@/components/ui/table-sticky/TableSticky';
import TextSimple from '@/components/ui/text/TextSimple';
import TagItem from '@/components/ui/tag/Tag';
import Popin from '@/components/ui/popin/Popin';
import Button from '@/components/ui/button/Button';
import LinkUnderline from '@/components/ui/link/LinkUnderline'
import TextTitle from '@/components/ui/text/TextTitle';
import ButtonDownload from '@/components/ui/button/ButtonDownloadPdf';

export default {
    name: 'InvoicesFolder',
    components: {ButtonDownload, TextTitle, TagItem, TextSimple, TableSticky, PageHeader, PageBack, TemplateDetails, Popin, Button, LinkUnderline},
    data() {
        return {
            id: this.$route.params.id,
            addLoading: false,
            addMessage: null,
            fields2: [
                {key: 'client', type: 'client', size: 'l'},
                {key: 'startDate', type: 'date', size: 'm', translationKey: 'invoiceStartDate'},
                {key: 'endDate', type: 'date', size: 'm', translationKey: 'invoiceEndDate'},
            ]
        }
    },
    computed: {
        ...mapState(['invoicesFolder', 'invoicesFolderLoading', 'invoiceExportLoading', 'financeFolderGenerating']),
        ...mapGetters(['isAdmin']),
        folder() {
            return this.invoicesFolder
        },
        payload() {
            return {
                client: parseInt(this.folder.client.id),
                invoiceType: 'CreditInvoice',
                invoiceFolder: parseInt(this.folder.id)
            }
        },
        fields() {
            let fields = [
                {key: 'id', type: 'eye', route: 'invoice', size: 'xs'},
                {key: 'id', size: 'xs'},
                {key: 'client', type: 'client', size: 'l'},
                {key: 'invoiceNumber', type: 'copy', size: 'm'},
                // {key: 'clientAgent', size: 'm'},
                //{key: 'clientName'},
                //{key: 'clientCity'},
                {key: 'invoiceDate', type: 'date'},
                {key: 'period', type: 'invoicePeriod', noSortable: true, size: 'l'},
                // {key: 'invoicePreviousBalance', type: 'formatPrice'},
                {key: 'invoiceType', type: 'tagType', size: 'm'},
                // {key: 'clientCountry'},
                {key: 'id', type: 'download', route: this.$listRoutes.invoicePdf, size: 's'},
            ]
            if (!this.isAdmin) fields = fields.slice(1);
            return fields
        }
    },
    methods: {
        ...mapActions(['getInvoicesFolder', 'createInvoice', 'exportInvoiceFolder', 'regenerateFinanceFolder', 'setNotificationMessage']),
        transmitInvoice(type) {
            this.addLoading = false
            this.createInvoice({
                ...this.payload,
                invoiceType: type || 'CreditInvoice'
            })
                .then(data => {
                    this.addMessage = data.message 
                    this.addLoading = true;
                    this.$refs.popinCreateInvoice.close();
                    this.setNotificationMessage(data.message || this.$t('invoice.create_success'))
                    setTimeout(() => this.$router.push({name: 'invoice', params: {id: data.id }}), 1000)
            })
        },
    }
}
</script>