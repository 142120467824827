<template>
    <TemplateDetails :get-function="getRecomResale" :data="resale" :loading="resaleLoading">

        <PageBack/>

        <div v-if="resale">
            <PageHeader :title="$t('recom_resale.title') + resale.shopifyId">
                <template v-slot:text>
                    <TextSimple color="grey">{{ resale.createdAt | dateHour }}</TextSimple>
                    <TagList>
                        <Tag v-if="isCancelled" color="red" :text="$t('global.cancelled')"/>
                        <TagType v-else-if="resale.logisticianCondition" :value="resale.logisticianCondition"/>
                        <Tag v-if="status && !isCancelled" :text="status" color="grey"/>
                        <Tag v-if="resale.inStock" :text="$t('global.inStock')" color="blue2"/>
                        <Tag v-if="resale.shopifyPublished" :text="$t('global.published')" color="blue"/>
                    </TagList>
                </template>
                <template v-slot:actions>
                    <Button v-if="canBeCancel && !isCancelled" @click.native.prevent="$refs.popinCancel.open()" icon="trash" size="m" color="red">{{ $t('resale.remove_user.title') }}</Button>
                    <ButtonLink :to="resale.printVoucherUrl" icon="download" size="m" color="grey">{{ $t("resale.print-voucher") }}</ButtonLink>
                    <ButtonLink :to="shopifyProductUrl" icon="tshirt" size="m" color="grey">{{ $t("resale.see-product") }}</ButtonLink>
                </template>
            </PageHeader>
            <GridContainer>
                <GridCard>
                    <GridContent>
                        <GridHeader :title="$t('resale.informations')"/>
                        <SectionTableInfos :object="resale" :fields="informationsFields"/>
                    </GridContent>
                </GridCard>
                <ResaleItemsGrid :resale="resale" />
                <template v-slot:aside>
                    <GridCard>
                        <GridContent>
                            <GridHeader :title="$t('global.customer')" size="xs"/>
                            <SectionTableInfos :object="resale" :fields="customerFields"/>
                        </GridContent>
                    </GridCard>

                    <GridContent>
                        <GridHeader :title="$t('resale.calendar')"/>
                        <GridCalendar>
                            <GridCalendarDate
                                    v-for="item in resale.activities"
                                    :key="item.event"
                                    :title="$t(`recom_resale.states.${item.event}.title`)"
                                    :set="infosKey = `recom_resale.states.${item.event}.text`"
                                    :comment="$t(infosKey) !== infosKey ? $t(infosKey) : null"
                                    :date="item.createdAt"
                            />
                        </GridCalendar>
                    </GridContent>
                </template>
            </GridContainer>
        </div>
        
    </TemplateDetails>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import PageHeader from "@/components/ui/page/PageHeader";
import TemplateDetails from "@/components/templates/TemplateDetails";
import PageBack from "@/components/ui/page/PageBack";
import GridContainer from "@/components/ui/grid/GridContainer";
import GridCard from "@/components/ui/grid/GridCard";
import GridHeader from "@/components/ui/grid/GridHeader";
import GridContent from "@/components/ui/grid/GridContent";
import TextSimple from "@/components/ui/text/TextSimple";
import GridCalendar from "@/components/ui/grid/GridCalendar";
import TagType from "@/components/ui/tag/TagType";
import ButtonLink from "@/components/ui/button/ButtonLink";
import TagList from "@/components/ui/tag/TagList";
import Tag from "@/components/ui/tag/Tag";
import SectionTableInfos from "@/components/sections/SectionTableInfos";
import Button from '@/components/ui/button/Button';
import ResaleItemsGrid from '@/components/ui/resale/ResaleItemsGrid';
import GridCalendarDate from '@/components/ui/grid/GridCalendarDate';

export default {
    name: "ResalePage",
    components: {GridCalendarDate, Button,ResaleItemsGrid, SectionTableInfos, Tag, TagList, ButtonLink, GridCalendar, TextSimple, GridContent, GridHeader, GridCard, GridContainer, TagType, PageBack, TemplateDetails, PageHeader},
    data() {
        return {
            loading: true,
            canceling: false,
            cancelled: false,
            informationsFields: [
                {key: 'reference', translationKey: 'id' },
                {key: 'itemsProposedAmount', translationKey: 'credit_price', type: 'formatPrice', force: true },
                {key: 'state', type: 'tagType' },
                {key: 'itemsCreditedAmount', type: 'formatPrice', force: true },
                {key: 'itemsNb' },
                {key: 'source', type: 'tagType'},
                {key: 'channel', type: 'tagType' },
                {key: 'shippingAddress.countryCode' },
                {key: 'shipment.trackingNumber' },
                {key: 'shipment.trackingExternalLink', type: 'url'},
                {key: 'shipment.carrier.name', translationKey: 'shipmentCarrierName' },
            ],
            customerFields: [
                {key: "brand.name", translationKey: 'client'},
                {key: 'customer.firstName'},
                {key: 'customer.lastName'},
                {key: 'customer.id', type: 'CmsCustomer', size: 'xl', translationKey: "email"},
            ],
            ordersFields: [
                { key: 'id', type: 'eye', route: 'recom_tradein-article', size: "xs"},
                {key: 'metadata.fr_FR.title', size: "m"},
                {key: 'size', size: "s"},
                {key: 'ean', translationKey: 'declaredEan', size: "l"},
                {key: 'size', translationKey: 'declaredSize', size: "m" },
                {key: 'color', translationKey: 'declaredColor', size: "m"},
                {key: 'condition', translationKey: 'declaredCondition', size: "s"},
                {key: 'controlReview.ean', translationKey: 'controlReviewEan', size: "l"},
                {key: 'photos', type: 'photos', translationKey: "photosUrl", size: "xl"},
                {key: 'initialReview.price', translationKey: "initialReviewPurposed", type: 'formatPrice', force: true, size: "m"},
                {key: 'ean', size: "l"},
                {key: 'reference', size: "l", translationKey: "article_id"},
                {key: 'state', type: 'tagType', size: "m"},
                {key: 'initialReview.price', translationKey:"initialReviewPrice", type: 'formatPrice', force: true, size: "m"},
                {key: 'controlReview.price', translationKey:"controlReviewPrice", type: 'formatPrice', force: true, size: "m"},
                {key: 'warehouseSlotPosition', size: "xxl"},
                {key: "activities", translationKey: "publicationDate", type: "recom_resale_activities", size: "m"},
                {key: 'warehouseStage', type: 'tagType', size: "l"},
                {key: "activities", translationKey: "published", type: "recom_resale_activities", size: "m"}
            ],
        };
    },
    computed: {
        ...mapState(['resale', 'resaleLoading', 'clients']),
        ...mapGetters(['roles']),
        canBeCancel() {
            return ['ROLE_ADMIN', 'ROLE_BRAND_SAV', 'ROLE_BRAND_STORE'].find(role => this.roles.includes(role)) && this.resale?.irl && this.resale?.user?.id
        },
        isCancelled() {
            return this.cancelled || (this.resale?.irl && !this.resale?.user?.id)
        },
        shopifyId() {
            return this.$route.params.id;
        },
        shopifyProductUrl() {
            if (!this.resale || !this.resale.client) return false;
            return `${this.clients.find((c) => c.id === this.resale.client.id)?.frontUrl}/admin/products/${this.resale.shopifyId}`;
        },
        status() {
            if (!this.resale) return null;
            const validationDate = this.resale.logisticsInformations.warehouseValidationDate;
            const shootingDate = this.resale.logisticsInformations.warehouseShootingDate;
            const openDate = this.resale.logisticsInformations.warehouseOpenDate;
            const stockDate = this.resale.logisticsInformations.warehouseStockDate;
            const receiveDate = this.resale.logisticsInformations.warehouseReceiveDate;
            const sendingDate = this.resale.logisticsInformations.carrierSendingDate;
            const published = this.resale.shopifyPublished;
            const badCondition = this.resale.logisticianCondition === "Mauvais état";
            if (!shootingDate && validationDate && !badCondition)
                return this.$t("resale.status.waiting-shooting");
            if (shootingDate && validationDate && !badCondition && !published)
                return this.$t("resale.status.waiting-publishing");
            if (!validationDate && !shootingDate && !openDate && !receiveDate && !sendingDate)
                return this.$t("resale.status.waiting-shipping");
            if (!validationDate && !shootingDate && !openDate && !receiveDate)
                return this.$t("resale.status.waiting-delivery");
            if (!validationDate && !shootingDate && !openDate)
                return this.$t("resale.status.waiting-open");
            if (!validationDate) return this.$t("resale.status.waiting-validation");
            if (validationDate && !stockDate)
                return this.$t("resale.status.waiting-stock");
            return null;
        },
    },
    methods: {
        ...mapActions(["getRecomResale", 'removeUserFromResale']),
        cancel() {
            this.canceling = true;
            this.removeUserFromResale(this.resale.id)
                    .then(() => {
                        this.cancelled = true;
                        this.$refs.popinCancel.close()
                    })
                    .finally(() => this.canceling = false)
        }
    },
};
</script>
