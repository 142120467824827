import ConfigWebsiteCatalogue from '@/components/pages/admin/config/website/catalogues/ConfigWebsiteCatalogue';
import ConfigWebsiteCatalogueCreate from '@/components/pages/admin/config/website/catalogues/ConfigWebsiteCatalogueCreate';
import ConfigWebsiteCatalogueEdit from '@/components/pages/admin/config/website/catalogues/ConfigWebsiteCatalogueEdit';
import ConfigWebsiteCatalogueUpdate from '@/components/pages/admin/config/website/catalogues/ConfigWebsiteCatalogueUpdate';
import ConfigWebsiteCatalogueActive from '@/components/pages/admin/config/website/catalogues/ConfigWebsiteCatalogueActive';
import ConfigWebsiteCataloguesView from '@/components/pages/admin/config/website/catalogues/ConfigWebsiteCataloguesView';
import ConfigWebsiteCatalogueMapping from '@/components/pages/admin/config/website/catalogues/ConfigWebsiteCatalogueMapping';
import ConfigWebsiteCatalogueValidation from '@/components/pages/admin/config/website/catalogues/ConfigWebsiteCatalogueValidation';

import ConfigWebsiteCatalogueCollectionsConfig from '@/components/pages/admin/config/website/catalogues/ConfigWebsiteCatalogueCollectionsConfig';
import ConfigWebsiteCatalogueProductMerch from '@/components/pages/admin/config/website/catalogues/ConfigWebsiteCatalogueProductMerch';


const cataloguesRoutes = [
    {
        path: '/admin/config/sites/:id/catalogues',
        name: 'config-website-catalogues',
        component: ConfigWebsiteCatalogue,
        meta: {
            roles: ['ROLE_ADMIN','ROLE_BRAND_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/config/sites/:id/catalogues/creer',
        name: 'config-website-catalogue-create',
        component: ConfigWebsiteCatalogueCreate,
        meta: {
            roles: ['ROLE_ADMIN','ROLE_BRAND_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/config/sites/:id/catalogues/:lang/:catalogueID/mettre-a-jour',
        name: 'config-website-catalogue-update',
        component: ConfigWebsiteCatalogueUpdate,
        props:true,
        meta: {
            roles: ['ROLE_ADMIN','ROLE_BRAND_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/config/sites/:id/catalogues/:lang/:catalogueID/editer',
        name: 'config-website-catalogue-edit',
        component: ConfigWebsiteCatalogueEdit,
        props:true,
        meta: {
            roles: ['ROLE_ADMIN','ROLE_BRAND_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/config/sites/:id/catalogues/:lang/:catalogueID/mapping',
        name: 'config-website-catalogue-mapping',
        component: ConfigWebsiteCatalogueMapping,
        props:true,
        meta: {
            roles: ['ROLE_ADMIN','ROLE_BRAND_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/config/sites/:id/catalogues/actifs',
        name: 'config-website-catalogues-update',
        component: ConfigWebsiteCatalogueActive,
        meta: {
            roles: ['ROLE_ADMIN','ROLE_BRAND_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/config/sites/:id/catalogues/:lang/voir',
        name: 'config-website-catalogues-view',
        component: ConfigWebsiteCataloguesView,
        props:true,
        meta: {
            roles: ['ROLE_ADMIN','ROLE_BRAND_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/config/sites/:id/catalogues/:lang/:catalogueID/validation',
        name: 'config-website-catalogue-validation',
        component: ConfigWebsiteCatalogueValidation,
        props:true,
        meta: {
            roles: ['ROLE_ADMIN','ROLE_BRAND_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/config/sites/:id/catalogues/creer-une-collection/:lang',
        name: 'config-website-catalogue-collections-create',
        component: ConfigWebsiteCatalogueCollectionsConfig,
        meta: {
            roles: ['ROLE_ADMIN', 'ROLE_BRAND_ADMIN'],
            cms: ['FAUME', 'SHOPIFY'],
            requiresCheck: true
        }
    },
    {
        path: '/admin/config/sites/:id/catalogues/editer-une-collection/:lang/:collectionID',
        name: 'config-website-catalogue-collections-edit',
        component: ConfigWebsiteCatalogueCollectionsConfig,
        meta: {
            roles: ['ROLE_ADMIN', 'ROLE_BRAND_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/config/sites/:id/catalogues/produit',
        name: 'config-website-catalogue-product',
        component: ConfigWebsiteCatalogueProductMerch,
        props:true,
        meta: {
            roles: ['ROLE_ADMIN','ROLE_BRAND_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },

]
export default cataloguesRoutes;