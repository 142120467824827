<template>

    <Tag
            :color="colors[value] || colors.default"
            :text="$t(`voucher.types.${value}`)"/>

</template>

<script>
import Tag from '@/components/ui/tag/Tag';
export default {
    name: 'TagVoucherType',
    components: {Tag},
    props: {
        value: String
    },
    data() {
        return {
            colors: {
                real: 'purple',
                sale: 'blue',
                client: 'green2',
                default: 'orange',
                published: 'green',
                not_published: 'orange',
                sold: 'green2',
                expired: 'red',
                burned: 'green2',
                available: 'green',
                cancel: 'purple'
            }
        }
    }
}
</script>