<template>
    <TemplateDetails :get-function="getOrder" :data="order" :loading="orderLoading">

        <PageBack/>

        <PageHeader :title="$t('global.order')">
            <template v-slot:text v-if="order">
                <TextSimple color="grey">{{ order.createdAt | dateHour }}</TextSimple>
                <TagType :value="order.stage"/>
            </template>
            <template v-slot:actions>
                <ButtonLink :to="shopifyOrderUrl" icon="bag" size="m" color="grey">{{ $t('order.see') }}</ButtonLink>
            </template>
        </PageHeader>

        <div v-if="order">

            <GridContainer>
                <GridCard>
                    <GridContent>
                        <GridHeader :title="$t('order.general')"/>
                        <SectionTableInfos :fields="fieldsGeneral" :object="order"/>
                    </GridContent>
                </GridCard>

                <GridCard>
                    <GridContent>
                        <GridHeader :title="$t('global.shipping')"/>
                        <SectionTableInfos :fields="fieldsShipping" :object="order"/>
                    </GridContent>
                </GridCard>

                <GridCard>
                    <GridContent>
                        <GridHeader :title="$t('order.articles')"/>
                        <OrderCardItem v-for="(item, i) in order.items" :key="i" :item="item"/>
                    </GridContent>
                </GridCard>

                <template v-slot:aside>

                    <GridCard>
                        <GridContent>
                            <GridHeader :title="$t('global.customer')"/>
                            <SectionTableInfos :fields="fieldsUser" :object="order"/>
                        </GridContent>
                    </GridCard>

                    <GridCalendar :dates="datesFields" :object="order"/>

                </template>

            </GridContainer>

        </div>

    </TemplateDetails>
</template>

<script>
import SectionTableInfos from '@/components/sections/SectionTableInfos';
import TemplateDetails from '@/components/templates/TemplateDetails';
import ButtonLink from '@/components/ui/button/ButtonLink';
import GridCalendar from '@/components/ui/grid/GridCalendar';
import GridCard from '@/components/ui/grid/GridCard';
import GridContainer from '@/components/ui/grid/GridContainer';
import GridContent from '@/components/ui/grid/GridContent';
import GridHeader from '@/components/ui/grid/GridHeader';
import OrderCardItem from '@/components/ui/order/OrderCardItem';
import PageBack from '@/components/ui/page/PageBack';
import PageHeader from '@/components/ui/page/PageHeader';
import TagType from '@/components/ui/tag/TagType';
import TextSimple from '@/components/ui/text/TextSimple';
import { mapActions, mapState } from 'vuex';

export default {
    name: "OrderPage",
    components: {OrderCardItem, TextSimple, TagType, GridCalendar, ButtonLink, GridHeader, GridContent, GridCard, GridContainer, SectionTableInfos, PageHeader, PageBack, TemplateDetails},
    data() {
        return {
            fieldsGeneral: [
                {key: 'id'},
                {key: 'number'},
                {key: 'createdAt', type: 'date'},
                {key: 'price', type: 'money', force: true},
                {key: 'paymentStatus', type: 'tagType'},
                {key: 'stage', type: 'tagType'},
                {key: 'assignedAt'},
            ],
            fieldsUser: [
                {key: 'client', type: 'client'},
                {key: 'user.firstname'},
                {key: 'user.lastname'},
                {key: 'user.email', type: 'userEmail'},
                {key: 'email'},
                {key: 'source', type: 'tagType'},
                {key: 'country'},
            ],
            fieldsShipping: [
                {key: 'carrierService'},
                {key: 'shippyProId'},
                {key: 'trackingId'},
                {key: 'trackingUrl', type: 'url'},
                {key: 'trackingSent', type: 'boolean'},
                {key: 'entryDate', type: 'dateHour'},
                {key: 'estimDate', type: 'dateHour'},
                {key: 'deliveryDate', type: 'dateHour'},
            ],
            datesFields: [
                {key: 'createdAt'},
                {key: 'estimDate'},
                {key: 'deliveryDate'},
            ]
        }
    },
    computed: {
        ...mapState(['order', 'orderLoading', 'clients']),
        shopifyOrderUrl() {
            if (!this.order || !this.order.client) return false;
            return `${this.clients.find(c => c.id === this.order.client.id)?.frontUrl}/admin/orders/${this.order.shopifyId}`
        },
    },
    methods: {
        ...mapActions(['getOrder'])
    }
}
</script>