<template>
    <TemplateDetails :get-function="getVoucher" :data="voucher" :loading="voucherLoading">

        <PageBack/>

        <PageHeader :title="$t('voucher.title')">
            <template v-slot:actions v-if="voucher && (editAllowed || voucher.status !== 'refund')">
                <Button v-if="editAllowed" @click.native="$refs.popinEdit.open" color="black" size="m" icon="edit">{{ $t('voucher.edit.button') }}</Button>
                <Button v-if="voucher.status !== 'refund' && !isUsed" @click.native="$refs.popinRefund.open" color="red" size="m" icon="trash">{{ $t(`voucher.refund.${voucher.type !== 'client' ? 'button' : 'button_1st_hand'}`) }}</Button>
                <Button v-if="voucher.status === 'sync'" @click.native.once="retry" color="green" size="m">{{ $t('voucher.refund.retry') }}</Button>
            </template>
        </PageHeader> 

        <SectionTableInfos :object="voucher" :fields="fields"/>

        <Popin v-if="voucher" :title="$t('voucher.refund.title')" :subtitle="$t(`voucher.refund.${ voucher.type !== 'client' ? 'subtitle' : 'subtitle_1st_hand'}`)" :text="isUsed ? $t('voucher.refund.alert') : null" :center="true" ref="popinRefund">
            <template v-slot:content>
                <Button :loading="refundLoading" color="red" size="m" @click.native.prevent="refund">{{ $t(voucher.type !== 'client' ? 'global.yes' : 'voucher.refund.button_1st_hand' ) }}</Button>
            </template>
            <template v-slot:bottom>
                <LinkUnderline @click.native.prevent="$refs.popinRefund.close">{{ $t('global.cancel') }}</LinkUnderline>
            </template>
        </Popin>

        <Popin :title="$t('voucher.edit.title')" :subtitle="$t('voucher.edit.subtitle')" :center="true" ref="popinEdit" class="popin-edit-voucher">
            <template v-slot:content>
                <Form :fields="[{key: 'code', required: true, type: 'text', label: $t('voucher.edit.input')}]"
                      :action="editVoucher"
                      :model="voucher"
                      @success="$refs.popinEdit.close(); $refs.popinEditNotification.open()"/>
            </template>
            <template v-slot:bottom>
                <LinkUnderline @click.native.prevent="$refs.popinRefund.close">{{ $t('global.cancel') }}</LinkUnderline>
            </template>
        </Popin>

        <PopinNotification ref="popinEditNotification" :text="$t('voucher.edit.success')"/>
        <PopinNotification ref="popinRefundNotification" :text="refundMessage"/>

    </TemplateDetails>
</template>

<script>
import TemplateDetails from '@/components/templates/TemplateDetails';
import PageBack from '@/components/ui/page/PageBack';
import PageHeader from '@/components/ui/page/PageHeader';
import SectionTableInfos from '@/components/sections/SectionTableInfos';
import { mapState, mapActions } from 'vuex';
import Button from '@/components/ui/button/Button';
import Popin from '@/components/ui/popin/Popin';
import LinkUnderline from '@/components/ui/link/LinkUnderline';
import Form from '@/components/ui/form/Form';
import PopinNotification from '@/components/ui/popin/PopinNotification';

export default {
    name: 'VoucherPage',
    components: {PopinNotification, Form, LinkUnderline, Popin, Button, SectionTableInfos, PageHeader, PageBack, TemplateDetails},
    data() {
        return {
            refundLoading: false,
            refundMessage: this.$t('voucher.refund.success'),
            fields: [
                {key: 'id', type: 'voucher'},
                {key: 'shopDiscountId', type: 'copy'},
                {key: 'client', type: 'client'},
                {key: 'user', type: 'userCard'},
                {key: 'amount', type: 'formatPriceNoCurrency'},
                {key: 'currency'},
                {key: 'code', type: 'copy'},
                {key: 'createdAt', type: 'date'},
                {key: 'expiresOn', type: 'date'},
                {key: 'type', type: 'voucherType'},
                {key: 'status', type: 'tagType'},
                {key: 'isActive', type: 'boolean'},
                {key: 'isBurn', type: 'boolean'},
                {key: 'burnedAt', type: 'date'},
                {key: 'country'},
            ]
        }
    }, 
    computed: {
        ...mapState(['voucher', 'voucherLoading']),
        isUsed() {
            return this.voucher?.isBurn
        },
        editAllowed() {
            if (!this.voucher) return false;
            return ([12, 14, 19].includes(this.voucher.client.id)) && this.voucher.status === 'manual' && !this.isUsed
        }
    },
    methods: {
        ...mapActions(['getVoucher', 'refundVoucher', 'editVoucher', 'unsetVoucher', 'updateVoucher','retryVoucher']),
        refund() {
            this.refundLoading = true;
            this.refundVoucher()
                    .then(data => {
                        this.refundMessage = data.message || this.$t('voucher.refund.success');
                        this.refundLoading = true;
                        this.$refs.popinRefund.close();
                        this.$refs.popinRefundNotification.open();
                        this.updateVoucher();
                    })
                    .finally(() => this.refundLoading = true)
        },
        retry() {
            this.retryVoucher()
        }
        
    },
    destroyed() {
        this.unsetVoucher()
    }
}
</script>

<style lang="scss">
.popin-edit-voucher {
    .field__input {
        border: var(--border-2);
    }

    .field__label {
        background-color: #fff;
        padding: 0 4px;
    }
}
</style>