<template>
    <div class="grid-card">
        <slot/>
    </div>
</template>

<script>
export default {
    name: 'GridCard'
}
</script>

<style lang="scss">
.grid-card {
    background-color: #fff;
    border-radius: var(--border-radius-2);
    //box-shadow: var(--box-shadow-1);
    border: var(--border-1);
    margin-bottom: 30px;

    .grid-content {
        &:not(:first-of-type) {
            border-top: var(--border-2);
        }
    }

    .table-row td:first-of-type {
        padding-left: 0;
        white-space: normal;
        word-break: break-word;
    }

    @media screen and (max-width: 400px) {
        max-width: 100%;
        overflow-x: auto;
    }
}
</style>