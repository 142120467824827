<template>
    <slider v-if="products?.length" class="products-carousel">
        <slideritem v-for="(product, i) in products" :key="i">
            <ProductCard :product="product"/>
        </slideritem>
    </slider>
</template>

<script>
import { slider, slideritem } from 'vue-concise-slider'
import ProductCard from '@/components/ui/product/ProductCard';

export default {
    name: 'ProductsCarousel',
    components: {ProductCard, slider, slideritem},
    props: {
        products: Array
    }
}
</script>

<style lang="scss">
.products-carousel {
    overflow: hidden;
    background-color: var(--color-bg-light);
    padding: 10px 10px 35px;
    border-radius: var(--border-radius-2);

    .product-card {
        width: 100px;
    }

    .slider-item {
        color: #000;
        font-size: inherit;
        margin-right: 10px;
        width: 150px;
        align-items: flex-start;
    }

    .product-card {
        border: var(--border-1);
        width: 100%;
        white-space: break-spaces;

        .title--xs {
            font-size: 1.2rem;
        }

        &__eye {
            display: none;
        }
    }

    & > .slider-pagination-bullets {
        bottom: 5px;

        & > * {
            background-color: #fff;
            border: var(--border-2);
            width: 12px;
            height: 12px;
            opacity: 1;
            margin: 0 2px;

            &.slider-pagination-bullet-active {
                background-color: #000;
            }
        }
    }
}
</style>