<template>
  <TemplateTable
    :title="$t('menu.orders-returned')"
    name="ordersReturned"
    :url="$listRoutes.ordersReturned"
    :table="ordersReturned"
    :filters="ordersReturnedFilters"
    :fields="fields"
  />
</template>

<script>
import TemplateTable from "@/components/templates/TemplateTable";
import { mapState } from "vuex";
export default {
    name: "OrderReturned",
    components: {TemplateTable},
    data() {
        return {
            fields: [
                {key: 'originalOrder.id', type: 'eye', route: 'order', size: 'xs'},
                {key: 'client', type: 'client', size: 'l'},
                {key: 'user', type: 'userEmail', size: 'xl'},
                {key: 'returnDate', type: 'date'},
                {key: 'orderId', type: 'shopifyOrder', size: 'm'},
                {key: 'source', type: 'tagType'},
                {key: 'saleType', type: 'tagType'},
                {key: 'saleCountry', size: 'm'},
                {key: 'warehouseCountry', size: 'm'},
                {key: 'itemNb'},
                {key: 'returnItemAmountWithoutTax', type: 'formatPrice', size: 'm'},
                {key: 'returnItemTaxAmount', type: 'formatPrice', size: 'm'},
                {key: 'returnItemAmountWithTax', type: 'formatPrice', size: 'm'},
                {key: 'refundAmountCreditCard', type: 'formatPrice', size: 'm'},
                {key: 'refundAmountGiftCard', type: 'formatPrice', size: 'l'},

            ]
        }
    },
    computed: {
        ...mapState(['ordersReturned', 'ordersReturnedFilters'])
    }
}
</script>
