<!-- Based on ConfigWebsiteCatalogsUpdate --> 
<template>
    <div>
        <div>
            <PageBack/>
            <PageHeader :title="title">
                <template v-slot:actions>
                    <ButtonLink :to="{name:'config-website-cohorts-update'}" icon="right" color="grey" size="m">{{ $t('cohorts.import_pricing') }}</ButtonLink>
                </template>
            </PageHeader>

            <div v-if="cohortsExists">
                <GridContainer>
                    <GridCard>
                        <GridContent>
                            <GridHeader :title="$t('catalogue.title')"/>
                            <!-- <ButtonLink :to="{name: 'config-website-catalogues-view', params:{lang:key}}" :loading="addLoading" color="blue" size="s">Voir tous les catalogues</ButtonLink> -->
                        </GridContent>
                    </GridCard>
                </GridContainer>
            </div>
            <div v-else>{{ $t('cohorts.no_pricing_setup') }}</div>
        </div>
      
        <PageError v-if="!client" title="404" :subtitle="$t('website.not-found')" :button="$t('website.return')" :to="{name: 'config-websites'}"/>
    </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import PageError from '@/components/ui/page/PageError';
import PageHeader from '@/components/ui/page/PageHeader';
import ButtonLink from '@/components/ui/button/ButtonLink';
import PageBack from '@/components/ui/page/PageBack';
import GridContainer from '@/components/ui/grid/GridContainer';
import GridCard from '@/components/ui/grid/GridCard';
import GridContent from '@/components/ui/grid/GridContent';
import GridHeader from '@/components/ui/grid/GridHeader';

// Cohorts
// import TemplateDetails from '@/components/templates/TemplateDetails';


export default {
    name: 'ConfigWebsiteCohorts',
    components: {
        GridHeader,
        GridContent,
        GridCard,
        GridContainer,
        PageBack,
        ButtonLink,
        PageHeader,
        PageError,
        // TemplateDetails,
    },
    data() {
        return {
            stores: null,
            addLoading: false,
        }
    },
    computed: {
        ...mapState(['cohorts','cohortsLoading',]),
        ...mapGetters(['cohortsExists']),
        id() {
            return this.$route.params.id
        },
        client() {
            return this.$store.getters.getClientById(this.id)
        },
        title(){
            return `${this.$t('catalogue.update')} ${this.client.name}`
        },
    },
    methods: {
        ...mapActions(['fetchCohorts']),
        getCohorts() {
            this.fetchCohorts(this.id)
        },

    },
}
</script>

<style lang="scss">
.config-website {
    &__actions {
        display: flex;
        gap: 15px;
        flex-direction: column;

        & > * {
            width: 100%;
        }
    }
}
</style>
<style lang="scss" scoped>
.grid-content {
    .button {
        margin-left: auto;
    }
}
</style>