<template>
    <component class="title" :class="[`title--${size}`, margin ? 'has-margin' : '']" :is="tag"><slot/></component>
</template>

<script>
export default {
    name: 'TextTitle',
    props: {
        tag: {
            type: String,
            default: 'h1'
        },
        size: {
            type: String,
            default: 'l'
        },
        margin: Boolean
    }
}
</script>

<style lang="scss">
.title {
    font-weight: 600;

    &--xl {
        font-size: 5rem;

        @media (min-width: 801px) and (max-width: 1200px) {
            font-size: 3.6rem;
        }

        @media (max-width: 800px) {
            font-size: 3rem;
        }
    }

    &--l {
        font-size: 3.6rem;

        @media (min-width: 801px) and (max-width: 1200px) {
            font-size: 2.5rem;
        }

        @media (max-width: 800px) {
            font-size: 2.2rem;
        }
    }

    &--m {
        font-size: 2.5rem;

        @media (min-width: 801px) and (max-width: 1200px) {
            font-size: 2rem;
        }

        @media (max-width: 800px) {
            font-size: 1.8rem;
        }
    }

    &--s {
        font-size: 2rem;

        @media (max-width: 1200px) {
            font-size: 1.6rem;
        }
    }

    &--xs {
        font-size: 1.6rem;
        font-weight: 500;

        @media (max-width: 1200px) {
            font-size: 1.4rem;
        }
    }

    &.has-margin {
        margin: 40px 0 20px;
    }
}
</style>