<template>
    <div class="translations-keys">
        <div class="translations-keys__header">
            <TextTitle size="xxs" tag="h2">{{ `${path}${isShopify && isHtml ? '_html' : ''}` }}</TextTitle>
            <FieldSelect v-if="languageSelector"
                :options="translations.map(t => ({ id: t.locale, label: $t(`locales.${t.locale.split('_')[0]}`) }))"
                :selected="locale" :border="true" @input="e => locale = e" />
        </div>
        <div v-for="(translation, i) in translations" :key="i">
            <TranslationsKey v-if="translation.locale === locale" :key="locale" :reference="path"
                :templateMail="templateMail" :templateMailFooter="templateMailFooter" :translation="translation" />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import TranslationsKey from '@/components/ui/translations/TranslationsKey';
import TextTitle from '@/components/ui/text/TextTitle';
import FieldSelect from '@/components/ui/form/fields/FieldSelect';

export default {
    name: 'TranslationsKeys',
    components: { FieldSelect, TextTitle, TranslationsKey },
    props: {
        languageSelector: {
            type: Boolean,
            default: true
        },
        templateMail: {
            type: Boolean,
            default: false
        },
        templateMailFooter: {
            type: Boolean,
            default: false
        },
        translations: Array,
        initLocale: String,
        all_translations: [Object, Array]
    },
    data() {
        return {
            locale: this.initLocale ? this.initLocale : this.translations[0].locale,
            name: []
        }
    },
    computed: {
        ...mapState(['marketTranslations', 'client']),
        isShopify() {
            return this.client?.shopifyUrl?.length
        },
        isHtml() {
          return this.translations?.[0]?.syntax === 'markdown'
        },
        path() {
            if (!this.name) return '';
            if (this.templateMail) {
                let currentKey = [...this.name].slice(0, -1).join('.');
                return this.$t(`mailbuilder.labelEdit_${currentKey}`);
            } else {
                return [...this.name].slice(0, -1).join('.');
            }
        },
    },
    methods: {
        ...mapActions(['getClient']),
        findObjectPathById(obj, id, path = []) {
            if (obj.id === id) {
                return path;
            }
            for (let key in obj) {
                if (typeof obj[key] === 'object') {
                    const result = this.findObjectPathById(obj[key], id, [...path, key]);
                    if (result) {
                        return result;
                    }
                }
            }
            return null;
        }
    },
    mounted() {
        this.name = this.findObjectPathById(this.all_translations, this.translations[0].id);
        if (this.client?.id !== this.$route.params?.id) {
            this.getClient();
        }
    }
}
</script>

<style lang="scss">
.translations-keys {
    margin: 15px 0 30px 20px;
    //border-radius: var(--border-radius-1);
    //background-color: #fff;

    @media screen and (max-width: 500px) {
        margin-left: 0;
        margin-bottom: 40px;
    }

    &__header {
        display: flex;
        align-items: flex-end;
        margin: 15px 0;
        justify-content: space-between;

        .field-select {
            width: auto;
            --field-height: 40px;
            --field-padding: 15px;
            position: relative;
            z-index: 3;

            @media screen and (max-width: 500px) {
                background-color: #fff;
                min-width: auto;
            }

            &__selected {
                background-color: #fff;
            }
        }
    }
}
</style>