import axios from 'axios';

const CatalogueAPI = axios.create({
    baseURL: `https://catalogue-manager.faume.co/api/`,
    headers: {
        Authorization: 'Bearer EMIqtAG6oCsUDJyjsbvGPQEeoCoy4Saltsqp3CSpqt2NR9YDreW5wTp9D8eVnaFQ',
        Accept: "application/json",
        'Content-Type': 'application/json'
    },
});

export default CatalogueAPI