<template>
    <slider :options="options" class="images-slider">
        <slideritem v-for="(img, i) in images" :key="i" class="images-slider__slide">
            <img :src="img" alt="Faume">
        </slideritem>
    </slider>
</template>

<script>
import { slider, slideritem } from 'vue-concise-slider'

export default {
    name: 'ImagesSlider',
    props: {
        images: Array
    },
    components: {slider, slideritem},
    data() {
        return {
            options: {
                currentPage: 0
            }
        }
    },
}
</script>

<style lang="scss">
.images-slider {
    overflow: hidden;

    .slider-item {
        width: 100%;
    }

    img {
        width: 100%;
        height: auto;
    }

    &.swiper-container-horizontal > * > .slider-pagination-bullet {
        width: 11px;
        height: 11px;
    }

    &.swiper-container-vertical .slider-pagination-bullet-active,
    &.swiper-container-horizontal .slider-pagination-bullet-active {
        background-color: var(--color-blue);
    }
}
</style>