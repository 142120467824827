<!-- Based on ConfigWebsite.vue -->
<template>
    <TemplateDetails :data="catalogues" :loading="cataloguesLoading" :get-function="this.getCatalogues">
        
        <PageBack/>
        <PageHeader :title="$t('cohorts.step_1')" :subtitle="$t('cohorts.select_catalogue')">
            <template v-slot:actions>
                <Button icon="download" size="m" color="grey" @click.native.prevent="downloadFileTemplateFile('csv')">{{$t('cohorts.download_csv')}}</Button>
                <Button icon="download" size="m" color="grey" @click.native.prevent="downloadFileTemplateFile('json')">{{$t('cohorts.download_json')}}</Button>
            </template>
        </PageHeader>
        
        <CohortsCard 
            v-for="(catalogue, key) in allActiveCatalogues?.filter(elem => elem.lang === merchandisingDefaultLang)" 
            :key="key" 
            :catalogue="catalogue" 
            :model="model" 
            @change="e => appendCatalogsToModel(catalogue.id)" />

        <transition name="fade" mode="out-in">
            <Form v-show="model.catalogs.length" 
                class="catalogue-form" 
                :fields="fields" 
                @change="e => appendFile(e)"
                :action="createCohortsFromForm" 
                @success="createCohortsSuccess"
            />
        </transition>

        <Popin 
            v-if="this.model.catalogs" 
            :title="$t('catalogue.about_to_launch')" 
            :subtitle='$t(`cohorts.generation`)+" "+this.allActiveCataloguesTitle'
            ref="aboutToLaunchCohort" 
            >
            <template v-slot:bottom>
                <p v-if="failedCatalogs.length">failedCatalogs {{(failedCatalogs)}}</p>
                
                <Button v-if="!loading" @click.native.prevent="$refs.aboutToLaunchCohort.close" color="red" size="m">{{ $t('global.cancel') }}</Button>
                <Button v-if="!loading" color="green" size="m" @click.native.prevent="generateThisCatalogue">{{ titleButtonGeneration }}</Button>
                
                <InPopinLoader 
                        v-else
                        :msg="$t('catalogue.please_wait_setup')" 
                        :loading="loading" 
                    />
            </template>
        </Popin>

        <Popin 
            :title="getCatalogueProgressStateValues !== '100%' ? $t('catalogue.generation') : $t('catalogue.generation_end')" ref="popinProgression">
            <template v-slot:content>
                <div v-if="catalogueProgress && !validationSuccess">
                    <ProgressBar 
                        v-if="showProgressBar" 
                        :start_value="catalogueProgress.totalLinesProcessed" 
                        :start_text="$t('catalogue.totalProductsProcessed')" 
                        :total_value="catalogueProgress.totalLines" 
                        :total_text="$t('catalogue.totalProducts')" 
                        :progression="getCatalogueProgressStateValues" 
                    />
                </div>
                <TextTitle v-if="!loading && getCatalogueProgressStateValues === '100%' && !validationSuccess" tag="h2" size="xs">{{ popinValidationTitle }}</TextTitle>
                <TextTitle v-if="validationSuccess" tag="h2" size="xs">{{ $t('catalogue.publish_success') }}</TextTitle>
            </template>
            <template v-slot:bottom v-if="getCatalogueProgressStateValues === '100%' && !validationSuccess" >
                <ButtonLink v-if="!loading" color="grey" size="m" :to="{name:'config-website-catalogues-update'}">{{ $t('global.back') }}</ButtonLink>
                <Button v-if="!loading && model.catalogs.length" color="green" size="m" @click.native.prevent="generateThisCatalogue">{{ titleButtonGeneration }}</Button>
                <InPopinLoader 
                        v-if="loading"
                        :msg="$t('catalogue.please_wait_setup')" 
                        :loading="loading" 
                    />
                <ButtonLink v-if="validationSuccess" color="green" size="m" :to="{name:'config-website-catalogues-update'}">{{ $t('global.back') }}</ButtonLink>
            </template>
        </Popin>
    </TemplateDetails>
</template>
<script>
import TemplateDetails from '@/components/templates/TemplateDetails';

import CohortsCard from '@/components/ui/cohort/CohortsCard'

import { mapState, mapGetters, mapActions } from 'vuex';
import PageHeader from '@/components/ui/page/PageHeader';
import Button from '@/components/ui/button/Button';
import ButtonLink from '@/components/ui/button/ButtonLink';
import Popin from '@/components/ui/popin/Popin';
import PageBack from '@/components/ui/page/PageBack';
import Form from '@/components/ui/form/Form';
import InPopinLoader from '@/components/ui/popin/InPopinLoader.vue';
import ProgressBar from '@/components/ui/progress/ProgressBar';
import TextTitle from '@/components/ui/text/TextTitle';

export default {
    name: 'ConfigWebsiteCohortsUpdate',
    components: {
        TemplateDetails,
        CohortsCard,
        PageBack,
        ButtonLink,
        Button,
        Popin,
        PageHeader,
        InPopinLoader,
        ProgressBar,
        TextTitle,
        Form
    },
    data() {
        return {
            deleteLoading: false,
            show:true,
            model: {
                catalogs:[]
            },
            fields: [
                {
                    name: "file",  
                    type: 'file', 
                    label:"title",
                    translation_1: `${this.$t('cohorts.form_csv_json')}`,
                    translation_2: `${this.$t('cohorts.form_csv_json')}`,
                    required: true,
                    accept:['.csv','.json']
                },
            ],
            showProgressBar:false,
            validationSuccess:false,
            failedCatalogs:[],
            file:null,
            loading:false,
        }
    },
    computed: {
        ...mapState([
            'catalogueProgress',
            'catalogues',
            'cataloguesLoading',
            'merchandisingDefaultLang'
        ]),
        ...mapGetters([
            'getCatalogueProgressStateValues',
            'getCataloguesState',
            'catalogue_isActive',
            'allActiveCatalogues'
        ]),
        id() {
            return this.$route.params.id
        },
        popinValidationTitle(){
            return `${this.$t('cohorts.import_success')}`
        },
        titleButtonGeneration(){
            return this.model.catalogs.length && this.allActiveCataloguesTitle
                ? this.$t('catalogue.generation_start')  +': '+ this.allActiveCataloguesTitle[0]
                : null
        },
        allActiveCataloguesTitle(){
            return this.allActiveCatalogues 
                ? this.allActiveCatalogues.filter(item => this.model.catalogs.includes(item.id)).map(elem => elem.title)
                : []
        }
    },
    methods: {
        ...mapActions([
            'createCohorts',
            'catalogueValidationOrGeneration',
            'catalogueLaunch',
            'fetchCatalogues',
            'fetchMerchandisingDefaultLang'
        ]),
        async getCatalogues(){
            await this.fetchMerchandisingDefaultLang(this.id)
            await this.fetchCatalogues(this.id)
        },
        createCohortsSuccess(data){
            console.log(data)
            this.model.catalogs = data.successfulCatalogs
            // this.failedCatalogs = data.failedCatalogs

            this.$refs.aboutToLaunchCohort.open();
        },
        generateThisCatalogue(){

            this.loading = true

            this.catalogueValidationOrGeneration({id:this.model.catalogs[0],clientID:parseInt(this.id),type:'generation'})
                .then(() => {
                    this.loading = false
                    this.$refs.aboutToLaunchCohort.close()
                    this.$refs.popinProgression.open()
                    this.showProgressBar = true
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(()=> {
                    this.model.catalogs.shift()
                });
        },
        launchThisCatalogue(){

            this.catalogueLaunch({id:this.id, catalogueID:this.catalogueID,fetchAfter:false})
                .then(response => {
                    console.log('launchThisCatalogue', response)
                })
                .catch(err => console.log(err))
                .finally(()=> this.validationSuccess = true)

        },
        downloadFileTemplateFile(e) {
            this.$store.dispatch('downloadCohortsTemplateFile', e);
        },
    
        appendCatalogsToModel(e) {
            const index = this.model.catalogs.indexOf(e);
            if (index > -1) {
                return this.model.catalogs.splice(index, 1);
            }

            return this.model.catalogs.push(e)
            
        },
        appendFile(e){
            this.file = e            
        },
        createCohortsFromForm(){
            return new Promise((resolve,reject)=> {
                this.createCohorts({brandID:[this.id],file:this.file.value})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(err => reject(err))
            })
        }
    

    },
    mounted() {
        this.getCatalogues()
    },
}
</script>
<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s
}
.fade-enter, .fade-leave-to {
    opacity: 0
}
.popin {
    &__content--catalogues {
        display: flex;
        flex-direction: column;
        padding-top: 10px;
        gap: 10px;
        align-items: stretch;
        border-top: var(--border-2);
        margin-top: 20px;
    }
    &__content--row {
        display:flex;
        align-items:center;
        justify-content:center;
        & > .button:is(:only-child) {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

</style>