import SettingsAdmins from '@/components/pages/admin/admins/Admins';
import AdminPage from '@/components/pages/admin/admins/Admin';
import AdminEdit from '@/components/pages/admin/admins/AdminEdit';
import AdminDelete from '@/components/pages/admin/admins/AdminDelete';
import AdminCreate from '@/components/pages/admin/admins/AdminCreate';

const adminsRoutes = [
    {
        path: '/admin/reglages/admins',
        name: 'settings-admins',
        component: SettingsAdmins,
        meta: {
            title: 'admin-access',
            roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_STORE', 'ROLE_SUPER_STORE'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/reglages/admins/nouveau',
        name: 'admin-create',
        component: AdminCreate,
        meta: {
            roles: ['ROLE_ADMIN', 'ROLE_BRAND_ADMIN', 'ROLE_SUPER_STORE', 'ROLE_BRAND_STORE', 'ROLE_SUPER_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/reglages/admins/:id',
        name: 'admin',
        component: AdminPage,
        meta: {
            roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_BRAND_ADMIN', 'ROLE_SUPER_STORE'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/reglages/admins/:id/modifier',
        name: 'admin-edit',
        component: AdminEdit,
        meta: {
            roles: ['ROLE_ADMIN', 'ROLE_BRAND_ADMIN', 'ROLE_SUPER_STORE', 'ROLE_SUPER_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/reglages/admins/:id/supprimer',
        name: 'admin-delete',
        component: AdminDelete,
        meta: {
            roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/reglages',
        name: 'settings',
        redirect: {
            name: 'settings-translations',
        },
        meta: {
            title: 'settings',
            icon: 'config',
            roles: ['ROLE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/reglages/*',
        redirect: {
            name: 'settings-translations',
        }
    }
];

export default adminsRoutes;