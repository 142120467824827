<template>
    <TemplateTable :title="$t('menu.recom_customers-all')" :text="$t('recom_users.description')" name="customers"
        :url="$listRoutes.recomCustomers" :table="customers" :filters="customersFilters" :fields="fields"
        :search="true" />
</template>

<script>
import { mapState } from 'vuex';
import TemplateTable from '@/components/templates/TemplateTable';

export default {
    name: 'RecomCustomersPage',
    components: { TemplateTable },
    data() {
        return {
            fields: [
                { key: 'id', type: 'eye', route: 'recom_customer', size: 'xs' },
                { key: 'brand', type: 'client', translationKey: 'client', size: 'm'},
                { key: 'profile', type: 'tagType', size: 'm'},
                { key: 'isGuest', translationKey: 'state', type: 'isGuest' },
                { key: 'firstName', size: 'm' },
                { key: 'lastName', size: 'm' },
                { key: 'email', type: 'copy', size: 'xl' },
                { key: 'gender', type: 'gender' },
                { key: 'emailSubscription', translationKey: 'optinEmail', type: 'boolean' },
                { key: 'smsSubscription', translationKey: 'optinSMS', type: 'boolean' },
                { key: 'nbOrders', force: true },
                { key: 'nbTradeInCarts', force: true, translationKey: 'nbReprises' },
                { key: 'createdAt', type: 'date' },
                { key: 'updatedAt', type: 'date' },
                { key: 'locale', type: 'flag' },
                { key: 'countryCode', size: 'xs' },
                { key: 'memberAreaUrl', type: 'memberUrl' },
                { key: 'walletAmount', type: 'formatPrice', force: true }
            ]
        }
    },
    computed: {
        ...mapState(['customers', 'customersFilters'])
    }
}
</script>