<template>
    <div class="store-transfer-confirmation">
        <PageBack/>

        <PageHeader
                :title="$t('client.stock.selected_title')"
                :subtitle="$t('client.stock.selected_subtitle', {count: storeArticlesSelected.length, price: totalPrice})">
            <template v-slot:actions v-if="hasProducts">
                <Button @click.native.prevent="submit" size="m" color="black" icon="check" :loading="loading">{{ $t('client.stock.confirm_transfer') }}</Button>
            </template>
        </PageHeader>

        <div v-if="hasProducts">

            <PageFilters>
                <template v-slot:left v-if="view == 3">
                    <FieldSelect
                            :options="selectCategoryOptions"
                            :selected="selectedCategory"
                            :label="$t('global.sort_by')"
                            @input="e => selectedCategory = e"/>

                    <FieldSearchSelect
                            :options="fieldsOptions"
                            :disabled-text="true"
                            :model="selectedKeys"
                            :label="$t('client.stock.values_to_include')"
                            @change="e => selectedKeys = e"/>
                </template>
                <template v-slot:right>
                    <FieldSwitch
                            :title_1="$t('client.stock.switch_table')"
                            :value_1="1"
                            :title_2="$t('client.stock.switch_cards')"
                            :value_2="2"
                            :title_3="$t('client.stock.switch_recap')"
                            :value_3="3"
                            :model="view"
                            @change="e => view = e"/>
                </template>
            </PageFilters>

            <TableSticky v-if="view === 1" :data="products" :fields="fields"/>

            <ProductListing v-else-if="view === 2">
                <ProductCard v-for="(product, i) in products" :key="i" :product="product">
                    <ProductCheckbox :product="product" store-key="storeArticlesSelected"/>
                </ProductCard>
            </ProductListing>

            <TableSticky v-else :fields="productsByCategoryFields" :data="productsByCategory"/>

            <TablePagination v-if="view !== 3" :pagination="pagination"/>

        </div>
        
        <Popin v-if="articlesUnavailable"
               ref="popinError"
               :title="$t('client.transfer.failed.title')"
               :text="$t('client.transfer.failed.subtitle', {count: articlesUnavailable.length, total: storeArticlesSelected.length})"
               :center="true">
            <template v-slot:content>
                <ProductsCarousel :products="articlesUnavailableListing"/>
            </template>
            <template v-slot:bottom>
                <Button @click.native.prevent="backToSelection" size="m" color="red" icon="refresh">{{ $t('client.transfer.failed.back') }}</Button>
                <Button v-if="articlesUnavailable.length < storeArticlesSelected.length" @click.native.prevent="forceConfirmation" size="m" color="green" icon="check">{{ $t('client.transfer.failed.continue') }}</Button>
            </template>
        </Popin>

    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { getPagination } from '@/utils';
import PageHeader from '@/components/ui/page/PageHeader';
import PageBack from '@/components/ui/page/PageBack';
import TableSticky from '@/components/ui/table-sticky/TableSticky';
import FieldSwitch from '@/components/ui/form/fields/FieldSwitch';
import ProductListing from '@/components/ui/product/ProductListing';
import ProductCheckbox from '@/components/ui/product/ProductCheckbox';
import ProductCard from '@/components/ui/product/ProductCard';
import FieldSelect from '@/components/ui/form/fields/FieldSelect';
import TablePagination from '@/components/ui/table/TablePagination';
import FieldSearchSelect from '@/components/ui/form/fields/FieldSearchSelect';
import PageFilters from '@/components/ui/page/PageFilters';
import Button from '@/components/ui/button/Button';
import Popin from '@/components/ui/popin/Popin';
import ProductsCarousel from '@/components/ui/product/ProductsCarousel';

export default {
    name: 'StoreTransferCreateConfirmation',
    components: {
        ProductsCarousel,
        Popin, Button, FieldSearchSelect, TablePagination, FieldSelect, ProductCard, FieldSwitch, TableSticky, PageFilters, PageBack, PageHeader, ProductCheckbox, ProductListing},
    data() {
        return {
            view: 1,
            fields: [
                {key: 'id', type: 'productCheckbox', size: 's', label: 'selected', param: 'storeArticlesSelected'},
                {key: 'id', size: 'xs', type: 'productLightbox', label: ' ', noTranslate: true},
                {key: 'title', size: 'xxl'},
                {key: 'etat', size: 'm', label: 'condition'},
                {key: 'color', size: 'm'},
                {key: 'size', size: 's'},
                {key: 'price', size: 's', type: 'money'},
                {key: 'photo1', size: 'xs', type: 'img', translation: 'photo'},
                {key: 'url', size: 'm', type: 'productUrl'},
            ],
            selectedKeys: ['size', 'color', 'etat', 'tag_type'],
            hiddenKeys: ['price', 'photo', 'url', 'shopify', 'reference', 'ean', 'id', 'title'],
            selectedCategory: 'tag_type',
            articlesUnavailable: null,
            loading: false
        }
    },
    computed: {
        ...mapState(['storeArticlesSelected']),
        articlesUnavailableListing() {
            if (!this.storeArticlesSelected?.length || !this.articlesUnavailable?.length) return null;
            return [...this.storeArticlesSelected].filter(x => this.articlesUnavailable.includes(x.id))
        },
        products() {
            const startIndex = (this.pagination.current - 1) * this.pagination.numItemsPerPage;
            const endIndex = startIndex + this.pagination.numItemsPerPage;
            return [...this.storeArticlesSelected].slice(startIndex, endIndex);
        },
        totalPrice() {
            return [...this.storeArticlesSelected]?.reduce((sum, product) => sum + parseFloat(product.price || 0), 0);
        },
        hasProducts() {
            return this.storeArticlesSelected.length > 0;
        },
        fieldsOptions() {
            return this.storeArticlesSelected?.length
                    ? Object.keys(this.storeArticlesSelected[0])
                            .filter(key => !this.hiddenKeys.find(x => key.toLowerCase().includes(x)))
                            .map(x => ({value: x, name: x.replace('tag_', 'Tag ')}))
                    : []
        },
        selectCategoryOptions() {
            if (!this.hasProducts) return [];
            return Object.keys(this.storeArticlesSelected[0])
                    .filter(key => !this.hiddenKeys.find(x => key.toLowerCase().includes(x)))
                    .map(this.formatKeyForOption);
        },
        productsByCategory() {
            const rawAccumulator = [...this.storeArticlesSelected].reduce((accumulator, product) => {
                const categoryValue = product[this.selectedCategory];
                let categoryObject = accumulator.find(cat => cat[this.selectedCategory] === categoryValue);
                if (!categoryObject) {
                    categoryObject = {
                        [this.selectedCategory]: categoryValue,
                        price: 0,
                        products: 0
                    };
                    accumulator.push(categoryObject);
                }
                categoryObject.products++;
                categoryObject.price += parseFloat(product.price) || 0;
                Object.entries(product).forEach(([key, value]) => {
                    if (key !== this.selectedCategory && this.selectedKeys.includes(key) && value) {
                        if (!categoryObject[key]) categoryObject[key] = [];
                        if (Array.isArray(categoryObject[key])) categoryObject[key].push(value);
                    }
                });
                return accumulator;
            }, []);
            rawAccumulator.forEach(categoryObject => {
                Object.keys(categoryObject).forEach(key => {
                    if (Array.isArray(categoryObject[key])) {
                        const counts = {};
                        categoryObject[key].forEach(val => {
                            counts[val] = (counts[val] || 0) + 1;
                        });
                        categoryObject[key] = Object.entries(counts).map(([value, count]) => `${value} (${count})`);
                    }
                });
            });
            return rawAccumulator;
        },
        productsByCategoryFields() {
            if (!this.productsByCategory?.length) return [];
            let fields = [
                {key: this.selectedCategory, size: 'm', label: this.selectedCategory.replaceAll('tag_', 'Tag '), noTranslate: true},
                {key: 'price', size: 's', type: 'money'},
                {key: 'products', label: 'countArticles', size: 's'}
            ];
            Object.keys(this.productsByCategory[0]).forEach(key => {
                if (this.selectedKeys.includes(key) && key !== this.selectedCategory) {
                    const value = this.productsByCategory[0][key];
                    fields.push({
                        key: key,
                        label: key.replaceAll('tag_', 'Tag '),
                        noTranslate: true,
                        size: Array.isArray(value) ? 'xl' : 'm',
                        type: Array.isArray(value) ? 'tagList' : key.includes('price') ? 'money' : null
                    });
                }
            });
            return fields;
        },
        pagination() {
            return getPagination(this.storeArticlesSelected.length, this.currentPage, this.itemsPerPage)
        },
        currentPage() {
            return Number(this.$route.query.page || 1)
        },
        itemsPerPage() {
            return Number(this.$route.query.limit || 10)
        },
        isKeySelected(key) {
            return this.selectedKeys.some(selected => key.includes(selected));
        }
    },
    methods: {
        ...mapActions(['createStoreTransfer', 'setStoreArticlesSelected']),
        formatKeyForOption(key) {
            return {label: key.replace('tag_', 'Tag '), id: key};
        },
        submit() {
            this.loading = true;
            this.createStoreTransfer(this.storeArticlesSelected.map(x => x.id))
                    .then(data => {
                        if (data?.id) this.$router.push({name: 'client-store-transfer', params: {transferId: data.id}})
                        this.loading = false;
                    })
                    .catch(list => {
                        this.articlesUnavailable = list;
                        setTimeout(() => {
                            this.loading = false;
                            this.$refs.popinError.open()
                        }, 500)
                    })
        },
        removeUnavailableArticles() {
            this.setStoreArticlesSelected([...this.storeArticlesSelected].filter(x => !this.articlesUnavailable.includes(x.id)));
        },
        forceConfirmation() {
            this.removeUnavailableArticles();
            this.submit()
        },
        backToSelection() {
            this.removeUnavailableArticles();
            this.articlesUnavailable = null;
            this.$router.push({name: 'client-store-transfer-create'})
        }
    },
    mounted() {
        if (this.storeArticlesSelected?.length) {
            if (!this.storeArticlesSelected[0][this.selectedCategory]) this.selectedCategory = 'size'
        }
    }
}
</script>

<style lang="scss">
.store-transfer-confirmation {

    .table-sticky .table-sticky-row {
        height: auto;

        & > * {
            padding: 10px 5px;
            align-items: flex-start;
        }
    }

    .filters {
        .field-select {
            margin-top: 0;
            width: 200px;
        }

        .form-field-search-select {
            max-width: calc(100% - 215px);
        }
    }
}
</style>