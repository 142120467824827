<template>
    <TemplateDetails :get-function="getMonitoringTests" :data="monitoringTest" :loading="loading">
        <PageBack/>
        <div v-if="monitoringTest.data">
            <GridContainer>
                <GridCard>
                    <GridContent>
                        <GridHeader :title="$t('global.informations')"/>
                        <SectionTableInfos :object="monitoringTest.data" :fields="infoFields"/>
                    </GridContent>
                </GridCard>
                <template v-slot:aside>
                    <GridCard >
                        <GridContent>
                            <GridHeader :title="'Metadata'"/>
                            <SectionTableInfos :object="metadata" :fields="metadataFields"/>
                        </GridContent>
                    </GridCard>
                </template>
                <template v-slot:bottom>
                    <TableSticky v-if="monitoringTest.data" :fields="fields" :data="monitoringTest.data.tests"/>
                </template>
            </GridContainer>
        </div>
        <TextSimple v-else size="s" weight="medium">{{ $t('admin.create.error') }}</TextSimple>
    </TemplateDetails>
</template>
    
<script>
    import TemplateDetails from '@/components/templates/TemplateDetails';
    import PageBack from '@/components/ui/page/PageBack';
    import GridContainer from '@/components/ui/grid/GridContainer';
    import GridContent from '@/components/ui/grid/GridContent';
    import GridCard from '@/components/ui/grid/GridCard';
    import GridHeader from '@/components/ui/grid/GridHeader';
    import SectionTableInfos from '@/components/sections/SectionTableInfos';
    import TableSticky from '@/components/ui/table-sticky/TableSticky';
    import testsMonitoringAPI from '@/api/api-testmonitoring';
    import listRoutes from '@/api/list-routes';
    import TextSimple from '@/components/ui/text/TextSimple';
    
    export default {
      name: 'monitoringTestPage',
      components: {
        PageBack, 
        TemplateDetails, 
        TableSticky,
        GridContainer,
        GridContent,
        GridHeader,
        GridCard,
        SectionTableInfos,
        TextSimple
      },
      data() {
        return {
            loading: true,
            monitoringTest: {},
            data: {},
            infoFields: [
                {key: 'id', type: 'number', route: 'test', size: 'xs'},
                {key: 'context', type: 'text', translationKey:'type',  size: 'l'},
                {key: 'brand', type: 'client', translationKey:'client',  size: 'l'},
                {key: 'branch', type: 'client', size: 'l'},
                {key: 'created_at', type: 'dateHour', translationKey:'createdAt'},
                {key: 'status', type: 'tagType', size: 'm'},
            ],
            fields: [
                {key: 'status', type: 'tagType', size: 's'},
                {key: 'name', type: 'text', size: 'm'},
            ]
        }
    },
    methods: {
        async getMonitoringTests(id){
            this.monitoringTest = await testsMonitoringAPI.get(listRoutes.monitoringTest.replace(":id",id));
            const baseTime = this.monitoringTest.data.created_at
            this.monitoringTest.data.created_at = new Date(baseTime).setHours(new Date(baseTime).getHours() + 2);
            this.loading = false;
        }
    },
    computed: {
        metadata() {
            // Merge metadata array into a single object
            return Object.assign({}, ...this.monitoringTest.data.metadata);
        },
        metadataFields() {
            // Create the fields list, could be anything
            return Object.keys(this.metadata).map( (key) => {
                return {key: key, size: 'm', noTranslate:true, force:true}
            })
        }
    }
}
</script>
    
<style lang="scss">
</style>