<template>
    <PageLoader/>
</template>

<script>
import PageLoader from '@/components/ui/page/PageLoader';

export default {
    name: 'LoaderPage',
    components: {PageLoader},
}
</script>