<template>
    <section>
        <PageBack/>
        <TemplateDetails  :data="{...merchandisingData, ...merchandisingRules}" :loading="merchandisingDataLoading && merchandisingDefaultLangLoading" :getFunction="getMerchandising">
            <PageHeader :title="title"></PageHeader>

            <form action="" @submit.prevent="verifyDatas(merchandising_rules)">
                <MerchandisingDisplay 
                    :title="$t('merchandising.product_title')"
                    :options="select_options"
                    :model="merchandisingRules?.merchandising?.title"
                    @change="e => addTitleOrDescriptionToMerchandising(e,'title')"
                />
                <MerchandisingDisplay 
                    :title="$t('merchandising.product_description')"
                    :options="select_options"
                    :model="merchandisingRules?.merchandising?.description"
                    @change="e => addTitleOrDescriptionToMerchandising(e,'description')"
                />

                <div class="row" style="display: none;">
                    <GridCard>
                        <GridContent>
                            <GridHeader :title="$t('merchandising.product_photos')" />
                            <img class="merchanding_default-img" src="@/assets/images/merchandising_default_image.svg" alt="">
                        </GridContent>
                    </GridCard>   
                    
                    <GridCard>
                        <GridContent>
                            <GridHeader :title="$t('merchandising.product_identification')" />
                            <MerchandisingProductIdentification :values="merchandising_identification" />
                        </GridContent>
                    </GridCard>  
                </div> 

                <GridCard> 
                    <GridContent v-if="merchandisingRules">
                        <MerchandisingProductCharacteristics 
                            v-for="(chars,index) in merchandising_indentification_and_characteristics" 
                            :key="chars.name" 
                            :index="index"
                            :existingDatas="merchandisingRules"
                            ref="ProductCharacteristics"
                            :characteristics="chars" 
                            @update-parent-model="updateParentModel"
                        />
                    </GridContent>
                </GridCard>
                <ButtonForm >{{ $t('global.validate') }}</ButtonForm>
            </form> 
            
            <Popin v-if="this.missing_fields" :title="$t('merchandising.popin_error_title')" :subtitle="this.submitMerchRulesError === false ? $t('merchandising.popin_error_subtitle') : ''"  :center="true" ref="popinFieldsErrors">
                <template v-slot:content>
                    <div v-if="missing_fields.length > 0">
                        <div v-for="missing_field in missing_fields" :key="missing_field">
                            {{missing_field}}
                        </div>
                    </div>
                    <div v-else>
                        {{$t('merchandising.error')}}
                    </div>
                </template>
            </Popin>

            <Popin :title="$t('merchandising.popin_error_title')" :subtitle="$t('merchandising.before_submit_subtitle')"  ref="popinBeforeSubmit">
                <template v-slot:bottom>
                    <Button @click.prevent.native="$refs.popinBeforeSubmit.close" icon="cross" color="red" size="m">{{ $t('global.cancel') }}</Button>
                    <Button @click.prevent.native="sendMerchDatas(merchandising_rules)" icon="right" color="black" size="m">{{ $t('merchandising.submit_rules') }}</Button>
                </template>
            </Popin>

            <Popin :title="$t('merchandising.test_rules_title')" :large="true" ref="popinShowRandomProduct">
                <template v-slot:content>
                    <div>
                        <MerchandisingRandomProduct
                            v-if="merchandisingRandomProduct"
                            :existingDatas="merchandisingRules"
                            :merchandising_datas="merchandising_rules"
                            :random_product="merchandisingRandomProduct"
                        />
                    </div>
                </template>
            </Popin>
 
        </TemplateDetails>
    </section>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import TemplateDetails from '@/components/templates/TemplateDetails.vue';
import PageHeader from '@/components/ui/page/PageHeader.vue';
import ButtonForm from '@/components/ui/button/ButtonForm.vue';
import Button from '@/components/ui/button/Button.vue';
import PageBack from '@/components/ui/page/PageBack.vue';
import GridCard from '@/components/ui/grid/GridCard.vue';
import GridContent from '@/components/ui/grid/GridContent.vue';
import GridHeader from '@/components/ui/grid/GridHeader.vue';
import MerchandisingDisplay from '@/components/ui/merchandising/MerchandisingDisplay.vue';
import MerchandisingProductCharacteristics from '@/components/ui/merchandising/MerchandisingProductCharacteristics.vue';
import MerchandisingProductIdentification from '@/components/ui/merchandising/MerchandisingProductIdentification.vue';
import Popin from '@/components/ui/popin/Popin.vue';
import MerchandisingRandomProduct from '@/components/ui/merchandising/MerchandisingRandomProduct.vue';

export default {
    name: 'ConfigWebsiteMerchandising',
    components: {
        TemplateDetails,
        GridHeader,
        GridContent,
        GridCard,
        PageBack,
        ButtonForm,
        Button,
        PageHeader,
        MerchandisingDisplay,
        MerchandisingProductCharacteristics,
        MerchandisingProductIdentification,
        Popin,
        MerchandisingRandomProduct,
    },
    data() {
        return {
            value:null,
            missing_fields:[],
            submitMerchRulesError:false,
            merchandising: {
                title: null,
                description: null,
            },
            tags:{},
            metafields:{},
            default_metafields_data: {},
            merchandising_title_data: null,
            merchandising_description_data: null,
        }
    },
    computed: {
        ...mapState([
            'merchandisingData',
            'merchandisingDataLoading',
            'merchandisingRandomProduct',
            'merchandisingRandomProductLoading',
            'merchandisingDefaultLang',
            'merchandisingDefaultLangLoading',
            'merchandisingRules'
        ]),
        id() {
            return this.$route.params.id
        },
        client() {
            return this.$store.getters.getClientById(this.id)
        },
        title(){
            return `${this.$t('merchandising.title')} ${this.client.name}`
        },
        select_options() {

            /**
             * Liste des valeurs sélectionnables par défaut des MerchandisingDisplay
             */
            
            const defaultValues = [
                {id:'ean',label:'ean'},
                {id:'sku',label:'sku'},
                {id:'size',label:'size'},
                {id:'title',label:'title'},
                {id:'color',label:'color'},
                {id:'sellable',label:'sellable'},
                {id:'tradable',label:'tradable'},
                {id:'price',label:'price'},
            ]
            const optionalValues = this.merchandising_characteristics 
                ? this.merchandising_characteristics.map(elem => {
                        return   { 
                            id: elem.name,
                            label:elem.label
                        }
                })
                : []
            return optionalValues ? [...defaultValues,...optionalValues] : []
        },
        merchandising_identification() {
            /**
             * Valeur du state.merchandisingData
             * Utilisé dans les MerchandisingProductIdentification
             * Lecture seulement
             */
            return this.merchandisingData
                // ? Object.keys(this.merchandisingData.identification).map(elem => ({ ...elem, type: 'identification' }))
                ? Object.keys(this.merchandisingData?.identification).map(elem => ({name:elem, label:this.$t(`catalogue.headers.identification.${elem}`)}))
                : null
        },

        test(){
            return this.merchandisingData
        },

        merchandising_characteristics() {

            /**
             * Valeur du state.merchandisingData moins certaines valeurs
             * Utilisé dans les MerchandisingProductCharacteristics (v-for) et dans le watcher
             * Lecture seulement
             */

            return this.merchandisingData
                ? Object.keys(this.merchandisingData.characteristic).filter(elem => {
                        const name = elem;
                        return !(name === 'title' || 
                                 name.startsWith('photo_') ||
                                 name === 'specific_color' || 
                                 name === 'specific_size') && 
                                 elem.label !== null;
                    }).map(elem => ({name:elem, label:this.$t(`catalogue.headers.characteristic.${elem}`)}))
                : null
        },
        merchandising_indentification_and_characteristics(){
            return this.merchandising_identification?.length && this.merchandising_characteristics?.length 
                ? [...this.merchandising_identification,...this.merchandising_characteristics]
                : []
        },
        default_metafields_with_title_and_description(){

            /**
             * Renvoie un {} contenant les datas : metafields, merchandising_title_data, merchandising_description_data
             * Utilisé dans {} final renvoyé par merchandising_rules
             * Lecture et écriture via les datas et la fonction addTitleOrDescriptionToMerchandising
             */

            return {
                ...this.metafields,
                title:{alias:this.merchandising_title_data,active:true},
                description: {alias:this.merchandising_description_data, active:true}
            }
        },
        default_tags_and_metafields(){

            /**
             * Génére un objet à partir des données stockées dans le store.merchandising.identification
             * cette donnée sera utilisée dans {} final renvoyé par merchandising_rules
             * Lecture seulement.
             */

            return this.merchandising_identification
                ? this.merchandising_identification.reduce((result, item) => { 
                        result[item.name] = {active:true, alias:item.label}                    
                        return result;
                    }, {})
                : null

        },
        merchandising_rules() {
            /**
             * Objet final envoyé vers le back pour le call API
             * this.tags et this.metafields sont mis à jour via la fonction updateParentModel puis sont insérés dans l'objet final
             */
            return {
                title: this.merchandising_title_data,
                description: this.merchandising_description_data,
                tags: {...this.default_tags_and_metafields, ...this.tags},
                metafields: {...this.default_tags_and_metafields, ...this.default_metafields_with_title_and_description,}
            }
        },
    },
    watch: {
        merchandising_characteristics(newValue) {
            /**
             * assigne les valeurs à this.metafields et les ajoute à merchandising_rules
             * ces valeurs peuvent être mise à jour avec updateParentModel
             */
            if(newValue){
                this.metafields = this.merchandising_characteristics.reduce((result, item) => { 
                    result[item.name] = {active:false, alias:null} 
                    return result;
                }, {})
            }
        },
    },
    methods: {
        ...mapActions([
            'fetchMerchandisingDatas',
            'fetchCatalogues',
            'submitMerchRules',
            'fetchMerchandisingRandomProduct',
            'fetchMerchandisingDefaultLang',
            'fetchMerchandisingRules']),
        getMerchandising() {
            this.fetchMerchandisingDefaultLang(this.id)
            this.fetchMerchandisingDatas(this.id)
            this.fetchCatalogues(this.id)
            this.fetchMerchandisingRules(this.id)
        },
        addTitleOrDescriptionToMerchandising(e,key){
            /**
             * Assigne les data merchandising_title_data et merchandising_description_data avec la valeur renvoyé par MerchandisingDisplay
             * déclenché par un @change
             * Si la valeur de e est 'description' alors description est ajouté sans son 'label'
             */
             if (e === null) {
                if (key === 'title') this.merchandising_title_data = null;
                if (key === 'description') this.merchandising_description_data = null;
            } else {
                if (key === 'description') {
                    this.merchandising_description_data = e.map(item => 
                        item === 'description' ? `#${item}` : `${item}: #${item}`
                    ).join('; ');
                }
                if (key === 'title') {
                    this.merchandising_title_data = `#${e.join(" #")}`;
                }
            }
        },
        updateParentModel(value) {

            /**
             * Met à jour les datas tags et metafields par les valeurs renvoyés par MerchandisingProductCharacteristics
             * Ces valeurs sont mises à jour dans merchandising_rules
             * Déclenché par un custom-event update-parent-model
             */

            const { name, alias, active, tagsOrMetafield } = value;

            /**
             * updateTagsOrMetafields
             * met à jour la data tags,metafields ou les 2 avec les valeurs destructurées et remise en forme de value
             */

            const updateTagsOrMetafields = (targetObject) => {
                
                this.$set(targetObject, name, { alias: alias?.length === 0 ? null : alias, active });
            };
            
            
            /**
             * On 'observe' les différents cas de la metafields
             */


            switch (tagsOrMetafield) {
                case 'tags':
                    this.$delete(this.metafields, name);
                    updateTagsOrMetafields(this.tags);
                break;

                case 'metafields':
                    this.$delete(this.tags, name);
                    updateTagsOrMetafields(this.metafields);
                break;

                case 'both':
                    updateTagsOrMetafields(this.tags);
                    updateTagsOrMetafields(this.metafields);
                break;

                case 'false' : 
                    this.$delete(this.metafields, name);
                    this.$delete(this.tags, name);
                break

                default:
                break;
            }
        },

        verifyFields(param){
            this.missing_fields.length = 0
            
            param.forEach(elem => {
                this.merchandising_rules[elem] === null
                    ? this.missing_fields.push(elem)
                    : ''
            })

        },
        showPopinErrors(){
            this.$refs.popinFieldsErrors.open()
        },
        sendMerchDatas(merchandising){
            this.submitMerchRules({id:this.id, merchandising:merchandising})
                .then(()=> {
                    this.$refs.popinBeforeSubmit.close()
                    this.$refs.popinShowRandomProduct.open()
                })
                .catch(()=> {
                    this.$refs.popinFieldsErrors.open()
                    this.submitMerchRulesError = true
                })
                .finally(()=> {
                    this.fetchMerchandisingRules(this.id)
                })
        },
        verifyDatas(){
            this.verifyFields(['title','description'])

            this.missing_fields.length === 0 
                ? this.$refs.popinBeforeSubmit.open()
                : this.showPopinErrors()
        }
        
    },
    created () {
        this.getMerchandising()
    },
}
</script>
<style lang="scss" scoped>
    form {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .merchanding_default-img {
        display: flex;
        margin:auto
    }
    .row {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: inherit;
        gap: 10px;
        & > .grid-card {
            &:first-child {
                flex-grow: 5;
            }
            &:last-child {
                flex-grow: 1;
            }
        }
        @media screen and (max-width: 901px) {
            flex-direction: column;
        }
    }


</style>