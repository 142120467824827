<template>
    <component
            :is="tag"
            :type="submit ? 'submit': null"
            :to="to"
            class="button"
            :class="[{'is-loading': loading}, {'is-border': border}, `button--${color}`, `button--${size}`, {'is-hide': hide}, {'is-icon': !hasSlot}, {'is-no-event': noEvent}, {'has-big-icon': bigIcon}, {'is-disabled': disabled}]">
        <SvgLoader v-if="loading"/>
        <span v-else>
            <span v-if="hasSlot">
                <slot/>
            </span>
            <SvgIcon v-if="icon" :name="icon"/>
        </span>
    </component>
</template>

<script>
import SvgLoader from '@/components/ui/svg/SvgLoader';
import SvgIcon from '@/components/ui/svg/SvgIcon';
export default {
    name: 'ButtonCustom',
    components: {SvgIcon, SvgLoader},
    props: {
        tag: {
            type: String,
            default: 'button',
        },
        loading: Boolean,
        to: [Object, String],
        submit: Boolean,
        hide: Boolean,
        disabled: Boolean,
        color: {
            type: String,
            default: 'black'
        },
        size: {
            type: String,
            default: 'l'
        },
        border: Boolean,
        icon: String,
        noEvent: Boolean,
        bigIcon: Boolean
    },
    computed: {
        hasSlot() {
            return !!(this.$slots.default && this.$slots.default.length > 0)
        }
    }
}
</script>

<style lang="scss">
.button {
    min-height: 50px;
    //min-width: 50px;
    padding: 10px 15px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    min-width: 200px;
    border-radius: 50px;
    font-size: 1.5rem;
    letter-spacing: .5px;
    overflow: hidden;
    width: fit-content;
    max-width: 100%;
    transition: transform ease-out .3s, opacity ease .1s, border ease-out .3s;
    --background: transparent;
    --color: #000;
    background-color: var(--background);
    color: var(--color);

    svg {
        fill: var(--color);
        --svg-color: var(--color);
    }

    & > span {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    &.is-hide {
        opacity: 0;
        pointer-events: none;
    }

    &.is-no-event {
        pointer-events: none;
    }

    &.has-big-icon {
        svg {
            width: 15px !important;
            height: auto;
        }
    }

    &.is-icon {
        padding: 0;
    }

    &--l {
        &.is-icon {
            min-width: 50px;
        }
    }

    &--m {
        &.is-icon {
            min-width: 40px;
        }
    }

    &--s {
        &.is-icon {
            min-width: 30px;
        }
    }

    &--yellow,
    &--black,
    &--green,
    &--green2,
    &--purple,
    &--blue2,
    &--orange,
    &--blue {
        --color: #fff;
    }

    &--black {
        --background: #000;
    }

    &--grey {
        --background: var(--color-bg-grey);
    }

    &--light-grey {
        --background: var(--color-bg-light);
    }

    &--white {
        --background: #fff;
    }

    &--purple {
        --background: var(--color-purple);
    }

    &--orange {
        --background: var(--color-orange);
    }

    &--yellow {
        --background: var(--color-yellow);
    }

    &--blue {
        --background: var(--color-blue);
    }

    &--blue2 {
        --background: var(--color-blue2);
    }

    &--green2 {
        --background: var(--color-green2);
    }

    &--green {
        --background: var(--color-green-light);
        --color: var(--color-green);

        svg {
            --svg-color: var(--color-green);
        }
    }

    &--red {
        --background: var(--color-red-light);
        --color: var(--color-red);
    }

    &.is-disabled {
        --background: var(--color-bg-grey);
        --color: #000;
        opacity: .4;
        pointer-events: none;
    }

    &--m {
        padding: 5px 15px;
        min-height: 40px;
        font-size: 1.3rem;
    }

    &--s {
        padding: 5px 10px;
        min-height: 30px;
        font-size: 1.1rem;
        width: fit-content;
        min-width: 30px;

        & > span {
            gap: 5px;

            .icon svg {
                width: 10px;
            }
        }
    }

    @keyframes animBtn {
        0% {
            transform: translateY(0);
            opacity: 1;
        }
        50% {
            transform: translateY(-5px);
            opacity: 0;
        }
        51% {
            transform: translateY(5px);
            opacity: 0;
        }
        100% {
            transform: translateY(0);
            opacity: 1;
        }
    }

    @media screen and (min-width: 1025px) {
        &:hover {
            transform: scale(1.02);
            & > span > span:last-of-type {
                animation: animBtn ease .4s;
            }
        }
    }

    &.is-loading {
        position: relative;
        pointer-events: none;

        svg {
            fill: #fff;
            height: 100%;
            width: auto;
            display: block;
            margin: auto;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &:disabled {
        pointer-events: none;
        opacity: .2;
    }

    &.is-border {
        border: solid 1px var(--background);
        background: transparent;
        --color: var(--background)
    }
}
</style>