<template>
    <ButtonCustom @click.native.prevent="download"
                  :color="color"
                  :size="size"
                  :loading="loading"
                  icon="download">
        <slot/>
    </ButtonCustom>
</template>

<script>
import ButtonCustom from '@/components/ui/button/Button';

export default {
    name: 'ButtonDownload',
    components: {ButtonCustom},
    props: {
        color: String,
        size: String,
        route: String,
        url: String,
        object: Object,
        filename: String
    },
    data() {
        return {
            loading: false
        }
    },
    methods: {
        getDate(dateString) {
            const date = new Date(dateString);
            return date.toISOString().split('T')[0];
        },
        download() {
            if (this.route) {
                this.loading = true;
                this.$API.get(this.route)
                        .then(res => {
                            const data = res.data;

                            const dateStart = this.object?.invoiceStartDate || this.object?.startDate || this.object?.updatedAt || this.object?.date;
                            const dateEnd = this.object?.invoiceEndDate || this.object?.endDate;

                            let filename = 'FAUME-';
                            if (this.object?.client?.name) filename += this.object.client.name.replaceAll(' ', '_');
                            if (dateStart) filename += `-${this.getDate(dateStart)}`;
                            if (dateEnd) filename += `-${this.getDate(dateEnd)}`;
                            filename += '.pdf';

                            const href = URL.createObjectURL(data);
                            const link = document.createElement('a');
                            link.href = href;
                            link.setAttribute('download', filename); // Change with intelligent name
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                            URL.revokeObjectURL(href);
                        })
                        .finally(() => this.loading = false)
            } else if (this.url) {
                window.open(this.url, '_blank');
            }
        }
    }
}
</script>