<template>
    <a :href="item.url" target="_blank" class="order-card">
        <div class="order-card__image">
            <SvgIcon name="tshirt"/>
        </div>
        <div class="order-card__text">
            <TextTitle size="xs">{{ title }}</TextTitle>
            <TextSimple color="grey">{{ infos }}</TextSimple>
        </div>
        <TextSimple>{{ item.price ? item.price : item.total ? item.total : null | formatPrice }}</TextSimple>
    </a>
</template>

<script>
import { mapState } from 'vuex';
import TextTitle from '@/components/ui/text/TextTitle';
import TextSimple from '@/components/ui/text/TextSimple';
import SvgIcon from '@/components/ui/svg/SvgIcon';

export default {
    name: 'OrderCardItem',
    components: {TextSimple, TextTitle, SvgIcon},
    props: {
        item: Object
    },
    computed: {
        ...mapState(['clients']),
        title() {
            return this.item.name ? this.item.name.split(' - ')[0] : this.item.productName ? this.item.productName.split(', ')[0] : null;
        },
        infos() {
            let result = null;
            if (this.item.name) {
                const parts = this.item.name.split(' - ');
                if (parts.length > 1) {
                    result = parts[1];
                }
            } else if (this.item.productName) {
                const parts = this.item.productName.split(', ');
                if (parts.length >= 4) {
                    result = parts.slice(1, 4).join(' / ');
                }
            }
            return result;        
        },
        color() {
            return this.item.name.split(' - ')[1].split(' / ')[0]
        },
        size() {
            return this.item.name.split(' - ')[1].split(' / ')[1]
        },
        condition() {
            return this.item.name.split(' - ')[1].split(' / ')[2]
        }
    }
}
</script>

<style lang="scss">
.order-card {
    text-align: left;
    display: flex;
    align-items: center;
    gap: 15px;

    & ~ .order-card {
        margin-top: 15px;
    }

    &__image {
        width: 45px;
        height: 45px;
        flex-shrink: 0;
        border: var(--border-1);
        transition: border ease .2s;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        --svg-color: var(--color-text-grey);
    }

    &:hover .order-card__image {
        border: var(--border-2);
    }

    &__text {
        display: flex;
        flex-direction: column;
        gap: 3px;
    }

    & > p {
        margin-left: auto;
    }
}
</style>