<template>

    <Tag
            :color="colors[value] || colors.default"
            :text="translation"/>

</template>

<script>
import Tag from '@/components/ui/tag/Tag';
export default {
    name: 'TagType',
    components: {Tag},
    props: {
        value: String,
        translationKey: {
            type: String,
            default: 'global'
        },
        text: String
    },
    computed: {
        translation() {
            if (this.text) return this.text;
            const text = this.$t(`${this.translationKey}.${this.value}`);
            return (!text || text.includes('.')) ? this.value?.replaceAll('_', ' ') : text
        }
    },
    data() {
        return {
            colors: {
                web: 'purple',
                store: 'blue',
                default: 'orange',
                subscriber: 'green',
                "pending confirm": 'grey',
                treated: "green",
                pending: "grey",
                unsubscriber: "red",
                sync: 'grey',
                manual: 'grey',
                pending_confirm: 'grey',
                available: 'green',
                used: 'red',
                ShipmentInvoice: 'blue',
                SalesSummary: 'purple',
                CommissionInvoice: 'blue2',
                ReconditioningInvoice: 'green',
                sav: 'blue',
                return: 'blue2',
                cancel: 'red',
                "Etat neuf": "green",
                OneshotInvoice: "blue",
                "Excellent état": "green",
                "Très bon état": "green",
                "Bon état": "grey",
                "Mauvais état": "red",
                "deprecation": "red",
                "rejected": "red",
                partially_treated: "blue",
                test: "grey",
                user: "purple",
                customer: "orange",
                client: "blue2",
                debit: "green",
                credit: "red",
                brand: "blue",
                paid: "green",
                refunded: "red",
                error: 'red',
                disabled: 'red',
                confirmed: 'green',
                success: 'green',
                failed: 'red',
                "ROLE_CLIENT": "orange",
                "ROLE_ADMIN": "black",
                "ROLE_USER": "grey",
                "ROLE_SUPER_ADMIN": "black",
                "ROLE_LOGISTICIAN": "green2",
                "ROLE_WAREHOUSE_ADMIN": "green",
                "ROLE_FINANCE": "purple",
                "ROLE_BRAND_ADMIN": "blue",
                "ROLE_BRAND_FINANCE": "blue2",
                "ROLE_STORE": "red",
                "ROLE_DISCOUNTCODE": "pink",
                "ROLE_SUPER_STORE": "red2",
                "ROLE_PHOTO": "pink2",
                "ROLE_FAUME_API": "yellow",
                "ROLE_PUBLIC_API": "yellow",
                "CreditInvoice": "green2",
                "DetailedSummary": "pink",
                to_prepare: 'grey',
                "created": 'grey',
                "validated": 'green',
                "prepared": 'purple',
                "to_ship": 'blue',
                "shipped": 'blue2',
                "received": 'pink',
                "to_control": "yellow",
                "controled": "green2",
                "to_return": "orange",
                "returned": 'black',
                canceled: 'red',
                stock: 'grey',
                transfert_prepared: 'purple',
                sent: 'blue',
                cancelled: 'red',
                transfert_sent: 'blue2',
                'SUCCESS': 'green',
                'ERROR': 'red',
                'giftcard': 'red',
                'refund': 'green',
                'support': 'green',
                'partially_accepted': 'orange',
                'refused': 'red',
                'accepted': 'green2',
                'controlling': 'grey',
                'draft': 'grey',
                'info_received': 'blue',
                'in_transit': 'blue2',
                'out_of_delivery': 'purple',
                'exception': 'red',
                'delivered': 'green',
                'expired': 'red',
                'burned': 'green2',
                'incoming': 'blue',
                'flat': 'grey',
                'percent': 'grey2',
                'inactive': 'red',
                'active': 'green',
                'new': 'green2',
                'excellent': 'green',
                'very_good': 'blue',
                'total__appliedTo': 'green',
                'shipping__appliedTo': 'blue',
                'Classique': 'blue',
                'Bienvenue': 'blue2',
                'Commande': 'green',
                'Rendez-vous': 'purple',
                controlled: 'blue',
                sold: 'blue2',
                published: 'green',
                store_controlled: 'blue2',
                return_delivered: 'blue',
                return_refund: 'blue2',
                return_treated: 'blue',
                shipment_delivered: "purple",
                shipment_shipped: "pink",
                return_pending: "grey",
                return_shipped: 'yellow'
            }
        }
    }
}
</script>