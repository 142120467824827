<template>
    <Button class="button-form" :loading="loading" tag="button" :size="size" :submit="true"><slot/></Button>
</template>

<script>
import Button from '@/components/ui/button/Button';
export default {
    name: 'ButtonForm',
    components: {Button},
    props: {
        loading: Boolean,
        size: {
            type: String,
            default: 'l'
        }
    }
}
</script>

<style lang="scss">
.button-form {
    margin-top: 30px;
}
</style>