<template>
    <component :is="tag" :href="href" :to="to" :target="target" class="tag-link">
        <TagItem :text="text" color="white" :icon-right="icon" :border="true"/>
    </component>
</template>

<script>
import TagItem from '@/components/ui/tag/Tag';
export default {
    components: {TagItem},
    props: {
        text: [String, Number],
        url: [String, Object],
        icon: {
            type: String,
            default: 'arrow right up'
        }
    },
    computed: {
        isExternalLink() {
            return typeof this.url === 'string'
        },
        tag() {
            return this.isExternalLink ? 'a' : 'router-link'
        },
        href() {
            return this.isExternalLink ? this.url : null
        },
        to() {
            return this.isExternalLink ? null : this.url
        },
        target() {
            return this.isExternalLink ? '_blank' : null
        }
    }
}
</script>
