<template>
    <TemplateTable
            :title="$t('shippings.title')"
            name="shippings"
            :url="$listRoutes.shipmentManifest"
            :table="shippings"
            :filters="shippingsFilters"
            :fields="fields"
    >
        <template v-slot:actions>
            <ButtonLink size="m" color="blue2" icon="plus" :to="{name: 'shippings-create'}">{{ $t('shippings.cta_create') }}</ButtonLink>
        </template>
    </TemplateTable>
</template>

<script>
import { mapState } from 'vuex';
import TemplateTable from '@/components/templates/TemplateTable';
import ButtonLink from '@/components/ui/button/ButtonLink';
export default {
    name: 'ShippingsPage',
    components: {ButtonLink, TemplateTable},
    data() {
        return {
            fields: [
                {key: 'createdAt', type: 'dateHour', size : 'm'},
                {key: 'brand', type: 'client', size: 'm'},
                {key: 'store.name', size: 'l', translationKey: "store"},
                {key: 'shippingVoucher', type: 'pdf', size : 'm'},
                {key: 'shipment.state', type: 'tagType', size : 'l'},
                {key: 'id', type: 'copy', size: 'xl'},
                {key: 'reference', type: 'copy', size: 'm'},
                {key: 'shipment.trackingNumber', type: 'copy', size : 'l'},
                {key: 'shipment.trackingExternalLink', type: 'url', size : 'xl'},
                {key: 'weight', translationKey: 'shippingKg'},
                {key: 'price', type: 'formatPrice'},
                {key: 'updatedAt', type: 'dateHour', size : 'm'},
            ]
        }
    },
    computed: {
        ...mapState(['shippings', 'shippingsFilters'])
    }
}
</script>