<template>
    <TemplateGoldenmetrics
            :title="$t('menu.resales')"
            :categories="categories"
            :route="$listRoutes.dashboardResales"
    />
</template>

<script>
import TemplateGoldenmetrics from '@/components/templates/TemplateGoldenmetrics';

export default {
    name: 'DashboardResales',
    components: {TemplateGoldenmetrics},
    data() {
        return {
            categories: [
                { name: 'customerReview', icon: 'user-border' },
                { name: 'logisticianReview', icon: 'search-border' },
                { name: 'articleBrandCheckLogistician', icon: 'tshirt-check' },
                { name: 'articleCreditCustomerFromStore', icon: 'search-valid-border' },
                { name: 'articleReview', icon: 'star-border' },
                { name: 'storeReview', icon: 'store-border' },
                { name: 'articleCheckLogistician', icon: 'hashtag-border' },
                { name: 'articleCreditCustomer', icon: 'search-valid' },
                { name: 'articleCreditStore', icon: 'tshirt-check' },
                { name: 'articleCreditBrand', icon: 'tshirt-check' },
                { name: 'articleCredit', icon: 'tshirt-check' },
                { name: 'articleImproper', icon: 'cross' },
                { name: 'improperRate', format: '%', icon: 'percent-border' },
                { name: 'totalOnline', icon: 'tshirt-check' }
            ]
        }
    }
}
</script>