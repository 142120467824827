<template>
    <AdminForm
        :title="$t('admin.create.title')"
        :action="createAdmin"
        :success-msg="$t('admin.create.success')"
        :error-msg="$t('admin.create.error')"
        @next="admin => $router.push({name: 'admin', params: {id: admin.id}})"/>
</template>

<script>
import { mapActions } from 'vuex';
import AdminForm from '@/components/ui/admin/AdminForm';

export default {
    name: 'AdminCreate',
    components: {AdminForm},
    methods: {
        ...mapActions(['createAdmin'])
    }
}
</script>