<template>
    <TemplateDetails :get-function="getInvoiceLine" :data="invoiceLine" :loading="invoiceLineLoading">

        <PageBack/>

        <div v-if="invoiceLine">
            <PageHeader :title="title">
                <template v-slot:actions v-if="canBeEdit">
                    <Button size="m" color="red" icon="trash" @click.native.prevent="$refs.popinDelete.open()">
                        {{ $t('invoice.delete_invoice_line') }}
                    </Button>
                </template>
            </PageHeader>

            <SectionTableInfos v-if="!canBeEdit" :object="invoiceLine" :fields="allFields"/>


            <GridContainer v-else>
                <Form
                      ref="form"
                      :model="customModel"
                      :fields="fields"
                      :edit="true"
                      :action="editInvoiceLine"
                      :success-msg="$t('invoice.edit_values_success')"/>

                <template v-slot:aside>
                    <GridCard>
                        <GridContent>
                            <GridHeader :title="$t('global.informations')"/>
                            <SectionTableInfos :object="invoiceLine" :fields="allFields"/>
                        </GridContent>
                    </GridCard>
                </template>
            </GridContainer>

            <Popin v-if="invoiceLine" :title="$t('invoice.delete_invoice_line')" :subtitle="$t('invoice.delete_invoice_line_confirm')"  :center="true" ref="popinDelete">
                <template v-slot:content>
                    <Button :loading="deleteLoading" color="red" size="m" @click.native.prevent="deleteCurrentLine()">{{ $t('global.yes') }}</Button>
                </template>
                <template v-slot:bottom>
                    <LinkUnderline @click.native.prevent="$refs.popinDelete.close">{{ $t('global.cancel') }}</LinkUnderline>
                </template>
            </Popin>

            <Popin :title="$t('invoice.edit_values_success')" ref="popin">
                <template v-slot:content>
                    <PageBack />
                </template>
            </Popin>

        </div>

    </TemplateDetails>
</template>

<script>
import TemplateDetails from '@/components/templates/TemplateDetails';
import { mapActions, mapGetters, mapState } from 'vuex';
import PageBack from '@/components/ui/page/PageBack';
import Form from '@/components/ui/form/Form';
import PageHeader from '@/components/ui/page/PageHeader';
import Popin from '@/components/ui/popin/Popin';
import LinkUnderline from '@/components/ui/link/LinkUnderline';
import Button from '@/components/ui/button/Button';
import GridContainer from '@/components/ui/grid/GridContainer';
import GridCard from '@/components/ui/grid/GridCard';
import GridContent from '@/components/ui/grid/GridContent';
import GridHeader from '@/components/ui/grid/GridHeader';
import SectionTableInfos from '@/components/sections/SectionTableInfos';

export default {
    name: 'invoiceLine',
    components: {SectionTableInfos, GridHeader, GridContent, GridCard, GridContainer, Form, PageBack, TemplateDetails, PageHeader, Popin, LinkUnderline, Button},
    data() {
        return {
            deleteLoading:false,
            fields: [
                {name: 'product', type: 'text', label:'description', required: true},
                //{name: 'quantity', type: 'number'},
                //{name: 'unitPrice', type: 'number'},
                {name: 'amountWithoutTax', type: 'number', required: true, step: 0.01},
                //{name: 'taxAmount', type: 'number', required: false, step: 0.01, disabled: true},
                //{name: 'amountWithTax', type: 'number', required: false, step: 0.01, disabled: true},
            ],
            allFields: [
                {key: 'id', type: 'copy'},
                {key: 'type', type: 'tagType'},
                {key: 'category'},
                {key: 'product'},
                {key: 'quatity'},
                //{key: 'unitPrice'},
                {key: 'amountWithoutTax', type: 'money'},
                {key: 'taxAmount', type: 'money'},
                {key: 'amountWithTax', type: 'money'},
                //{key: 'toSum', type: 'boolean'},
                //{key: 'rang'},
            ]
        }
    },
    computed: {
        ...mapState(['invoiceLine', 'invoiceLineLoading']),
        ...mapGetters(['isAdmin']),
        id() {
            return this.$route.params.id;
        },
        customModel() {
            const newObject = { ...this.invoiceLine };
            const keysToRemove = ['category', 'type', 'textClass', 'toSum', 'rang'];
            for (const key of keysToRemove) delete newObject[key];
            return newObject;
        },
        canBeEdit() {
            return this.isAdmin && ['CreditInvoice', 'OneshotInvoice'].includes(this.invoiceLine?.invoiceType) && this.invoiceLine?.invoiceStatus === 'draft'
        },
        title() {
            return this.$t(`invoice.${this.canBeEdit ? 'edit_line' : 'line_title'}`) + ' #' + this.invoiceLine?.id
        }
    },
    methods: {
        ...mapActions(['getInvoiceLine', 'editInvoiceLine', 'deleteInvoiceLine', 'setNotificationMessage']),
        deleteCurrentLine(){
            this.deleteLoading = false
            this.deleteInvoiceLine(this.id)
                .then(data => {
                    this.deleteLoading = true;
                    this.$refs.popinDelete.close();
                    this.setNotificationMessage(data.message || this.$t('invoice.delete_invoice_success'))
            })
            .finally(() => this.$router.go(-1))
        }
    }
}
</script>