<template>
    <span>
        <Button class="product-button-lightbox" color="grey" size="s" icon="eye" @click.native.prevent="open"/>
        <ProductLightbox v-if="displayPopin" :product="product" ref="lightbox"/>
    </span>
</template>

<script>
import Button from '@/components/ui/button/Button';
import ProductLightbox from '@/components/ui/product/ProductLightbox';
export default {
    name: 'ProductButtonLightbox',
    components: {Button, ProductLightbox},
    props: {
        product: Object
    },
    data() {
        return {
            displayPopin: false
        }
    },
    methods: {
        open() {
            this.displayPopin = true;
            setTimeout(() => {
                this.$refs.lightbox.open()
            }, 300)
        }
    }
}
</script>

<style lang="scss">
.product-button-lightbox {
    padding: 0;

    svg {
        width: 15px !important;
    }
}
</style>