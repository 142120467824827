<template>
    <section>
        <PageBack/>
        <PageHeader :title="$t('recom_promocode-create.title')"/>
        <Form
                :init-on-success="true"
                :fields="fields"
                ref="form"
                :action="createRecomPromotionCode"
                @success="$router.push({name: 'recom-promo-codes'})"
        />
    </section>
</template>

<script>
import PageHeader from "@/components/ui/page/PageHeader";
import Form from "@/components/ui/form/Form";
import PageBack from "@/components/ui/page/PageBack";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
    name: "RecomPromoCodeCreate",
    components: {PageBack, Form, PageHeader},
    data() {
        return {
            user: null,
            loading: false,
            shippingLabel: null,
            fields: [
                {name: "client", type: "select", required: true, options: []},
                {name: "code", type: "text", required: true},
                {name: "type", type: "select", required: true, options: [
                    {name: 'Pourcentage', value: 'percent'},
                    {name: 'Valeur', value: 'flat'}
                ]},
                {name: "appliedTo", type: "select", required: true, options: [
                    {name: 'Prix total', value: 'total'},
                    {name: 'Frai de livraison', value: 'shipping'},
                ]},
                {name: "minimumAmountItemsTotal", type: "number", required: true},
                {name: "value", type: "number", required: true},
                {name: "startedAt", type: "customDate", required: false, displayHours: true},
                {name: "endedAt", type: "customDate", required: false, displayHours: true},
            ],
        };
    },
    computed: {
        ...mapGetters(["userClients"]),
        ...mapState(["clients"]),
    },
    methods: {
        ...mapActions(["createRecomPromotionCode"]),
        updateClients() {
            let fieldIndex = this.fields.findIndex(field => field.name === 'client');
            if (fieldIndex !== -1) {
                if (this.userClients && this.userClients.length) {
                    this.fields[fieldIndex].options = this.userClients;
                } else {
                    this.fields[fieldIndex].options = this.clients;
                }
            }
        },
    },
    watch: {
        userClients() {
            this.updateClients();
        },
        clients() {
            this.updateClients();
        },
    },
    mounted() {
        this.updateClients();
    },
};
</script>

<style lang="scss">
.resale-shipping-create {
    display: flex;
    align-items: flex-start;

    & > form {
        width: 100%;
        max-width: 600px;
    }

    .grid-card {
        flex-shrink: 0;
        margin-left: 30px;
        position: sticky;
        top: 15px;
        margin-top: 20px;
    }

    .user-card {
        pointer-events: none;
        user-select: text;
    }

    .grid-content {
        & > svg {
            width: 80px;
            margin: auto;
            display: block;
            --svg-color: var(--color-text-grey);
        }
    }
}
</style>
