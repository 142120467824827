<template>
    <InputCheckbox :model="isSelected" @click.native.prevent="select()"/>
</template>

<script>
import InputCheckbox from '@/components/ui/form/inputs/InputCheckbox';

export default {
    components: {InputCheckbox},
    name: 'ProductCheckbox',
    props: {
        product: Object,
        storeKey : {
            default : 'storeArticlesSelected'
        }
    },
    computed: {
        articlesSelected() {
            return this.$store.state[this.storeKey]
        },
        isSelected() {
            return !!this.articlesSelected?.find(x => x.id == this.id)
        },
        id() {
            return this.product?.id
        }
    },
    methods: {
        select() {
            let articles = [...this.articlesSelected];
            if (this.isSelected) articles = articles.filter(a => a.id != this.id);
            else articles.push(this.product);
            // this.setStoreArticlesSelected(articles)
            this.$store.dispatch(`set${this.capitalizeFirstLetter(this.storeKey)}`, articles)
        },
        capitalizeFirstLetter(str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        }
    }
}
</script>