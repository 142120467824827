import WarehouseDashboard from '@/components/pages/admin/warehouse/WarehouseDashboard';
import WarehouseContact from '@/components/pages/admin/warehouse/WarehouseContact';
import WarehouseStocks from '@/components/pages/admin/warehouse/WarehouseStocks';
import WarehouseBills from '@/components/pages/admin/warehouse/WarehouseBills';
import WarehouseOperators from '@/components/pages/admin/warehouse/WarehouseOperators';
import WarehouseStocksRefused from '@/components/pages/admin/warehouse/warehouseStocksRefused';
import WarehouseArticles from '@/components/pages/admin/warehouse/WarehouseArticles';

const warehouseRoutes = [
    {
        path: '/admin/ateliers',
        name: 'warehouse',
        redirect: {
            name: 'warehouse-dashboard',
        },
        meta: {
            title: 'workshop-warehouse-services',
            icon: 'box',
            roles: ['ROLE_ADMIN', 'ROLE_LOGISTICIAN', 'ROLEWAREHOUSE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/ateliers/dashboard',
        name: 'warehouse-dashboard',
        component: WarehouseDashboard,
        meta: {
            title: 'dashboard',
            roles: ['ROLE_ADMIN', 'ROLE_LOGISTICIAN', 'ROLEWAREHOUSE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/ateliers/operateurs',
        name: 'warehouse-operators',
        component: WarehouseOperators,
        meta: {
            title: 'operators',
            roles: ['ROLE_ADMIN', 'ROLE_LOGISTICIAN', 'ROLEWAREHOUSE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/ateliers/inventaire',
        name: 'warehouse-inventory',
        component: WarehouseStocks,
        meta: {
            title: 'stock-good',
            roles: ['ROLE_ADMIN', 'ROLE_LOGISTICIAN', 'ROLEWAREHOUSE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/ateliers/factures',
        name: 'warehouse-bills',
        component: WarehouseBills,
        meta: {
            title: '',
            roles: ['ROLE_ADMIN', 'ROLE_LOGISTICIAN', 'ROLEWAREHOUSE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/ateliers/contact',
        name: 'warehouse-contact',
        component: WarehouseContact,
        meta: {
            title: 'contact',
            roles: ['ROLE_ADMIN', 'ROLE_LOGISTICIAN', 'ROLEWAREHOUSE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/ateliers/articles',
        name: 'warehouse-articles',
        component: WarehouseArticles,
        meta: {
            title: 'warehouse-articles',
            roles: ['ROLE_ADMIN', 'ROLE_LOGISTICIAN', 'ROLEWAREHOUSE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/ateliers/inventaire-mauvais-etat',
        name: 'warehouse-inventory-refused',
        component: WarehouseStocksRefused,
        meta: {
            title: 'stock-bad',
            roles: ['ROLE_ADMIN', 'ROLE_LOGISTICIAN', 'ROLEWAREHOUSE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/ateliers/*',
        redirect: {
            name: 'warehouse'
        }
    },
];

export default warehouseRoutes;