<template>
    <div class="chart-bar">
        <PopinTooltip>
            <template v-slot:button>
                <div class="chart-bar__bar">
                    <span :style="{width: widthA}"/>
                </div>
                <div class="chart-bar__bar" v-if="value2 !== null">
                    <span :style="{width: widthB}"/>
                </div>
            </template>
            <template v-slot:content>
                <slot/>
            </template>
        </PopinTooltip>
    </div>
</template>

<script>
import PopinTooltip from '@/components/ui/popin/PopinTooltip';

export default {
    name: 'ChartBar',
    components: {PopinTooltip},
    props: {
        value1: [String, Number],
        value2: [String, Number],
        max: Number
    },
    computed: {
        widthA() {
            return (this.value1 / this.max) * 100 + '%'
        },
        widthB() {
            if (this.value2 === null) return '0%';
            return (this.value2 / this.max) * 100 + '%'
        }
    }
}
</script>

<style lang="scss">
.chart-bar {
    position: relative;
    --color: var(--color-green);

    &__bar {
        background-color: var(--color-bg-light);
        height: 12px;
        border-radius: var(--border-radius-1);

        &:first-of-type {

            span {
                background-color: var(--color);
            }
        }

        &:last-of-type {
            margin-top: 5px;

            span {
                background: linear-gradient(135deg,#fff 7.5%,var(--color) 0,var(--color) 42.5%,#fff 0,#fff 57.5%,var(--color) 0,var(--color) 92.5%,#fff 0);
                background-size: 5px 5px;
                background-position: 5px 5px;
            }
        }

        &, span {
            border-radius: var(--border-radius-1);
        }

        @keyframes animBar {
            0% {
                width: 0;
            }
        }

        span {
            background-color: #E8F3DA;
            display: block;
            height: 100%;
            animation: animBar ease-in-out 1s;
            width: 0px;
        }
    }

    .tooltip__button {
        padding: 5px 0;
        background-color: #fff;
    }

    .tooltip__content {
        left: 50%;
        transform: translate(-50%, -10px);
        display: flex;
        flex-direction: column;
        gap: 7px;
        min-width: 200px;

        &:after {
            left: calc(50% - 10px);
        }

        @media screen and (min-width: 1025px) {
            &.is-hover {
                transform: translate(-50%, 0);
            }
        }

        @media screen and (max-width: 1024px) {
            &.is-open {
                transform: translate(-50%, 0);
            }
        }
    }
}
</style>