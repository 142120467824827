<template>
    <TemplateDetails :get-function="getInvoice" :data="invoice" :loading="invoiceLoading">

        <PageBack/>

        <div v-if="invoice">
            <PageHeader :title="$t('invoice.title') + ' #' + invoice.id" v-if="invoice">
                <template v-slot:actions>
                    <ButtonLink v-if="canBeEdit" size="m" color="black" icon="edit" :to="{name: 'invoice-edit', params: {id: id}}">{{ $t('global.edit') }}</ButtonLink>
                    <Button v-if="canBeEdit" size="m" color="red" icon="cross" @click.native.prevent="$refs.popinDelete.open()">{{ $t('global.delete') }}</Button>
                    <ButtonDownload size="m" color="grey" :route="$listRoutes.invoicePdf.replace(':id', id)" :object="invoice">PDF</ButtonDownload>
                </template>
            </PageHeader>

            <GridContainer>
                <GridCard>
                    <GridContent>
                        <GridHeader :title="$t('global.informations')"/>
                        <SectionTableInfos :fields="fieldsInvoice" :object="invoice"/>
                    </GridContent>
                </GridCard>

                <template v-slot:aside>
                    <GridCard>
                        <GridContent>
                            <GridHeader :title="$t('global.parameters')"/>
                            <div v-if="invoice.status === 'draft' && isAdmin">
                                <FormInline v-for="(field, i) in fieldsAvailable"
                                            :set="parameter = invoice.parameters.find(p => p.name === field.key)"
                                            :options="field.options"
                                            :route="$listRoutes.invoiceParameterEdit.replace(':id', parameter?.id)"
                                            :label="field.key"
                                            :key="i"
                                            :model="parameter?.value"
                                            :name="parameter?.name"
                                            :playload="{...parameter, invoiceId: invoice.id}"
                                            :type="field.type || 'text'"/>
                            </div>
                            <SectionTableInfos v-else :fields="fieldsParameters" :object="parameters"/>
                        </GridContent>
                    </GridCard>
                </template>
            </GridContainer>

            <PageHeader :title="$t('invoice.detail')" title-size="m" :margin-top="true">
                <template v-slot:actions>
                    <ButtonLink v-if="canBeEdit" size="m" icon="plus" color="black" :to="{name: 'invoice-line-add'}">{{$t('invoice.add_line_to_invoice')}}</ButtonLink>
                </template>
            </PageHeader>

            <TableSticky :fields="fieldsDetails" :data="invoice.invoiceDetails"/>

            <Popin v-if="invoice"
                   :title="$t('invoice.delete_invoice')"
                   :subtitle="$t('invoice.delete_invoice_confirm')"
                   :center="true"
                   ref="popinDelete">
                <template v-slot:content>
                    <Button :loading="deleteLoading" color="red" size="m" @click.native.prevent="deleteInvoice()">{{ $t('global.yes') }}</Button>
                </template>
                <template v-slot:bottom>
                    <LinkUnderline @click.native.prevent="$refs.popinDelete.close()">{{ $t('global.cancel') }}</LinkUnderline>
                </template>
            </Popin>
        </div>

    </TemplateDetails>
</template>

<script>
import TemplateDetails from '@/components/templates/TemplateDetails';
import PageBack from '@/components/ui/page/PageBack';
import PageHeader from '@/components/ui/page/PageHeader';
import SectionTableInfos from '@/components/sections/SectionTableInfos';
import { mapState, mapActions, mapGetters } from 'vuex';
import Button from '@/components/ui/button/Button';
import Popin from '@/components/ui/popin/Popin';
import LinkUnderline from '@/components/ui/link/LinkUnderline';
import TableSticky from '@/components/ui/table-sticky/TableSticky';
import ButtonLink from '@/components/ui/button/ButtonLink';
import GridContainer from '@/components/ui/grid/GridContainer';
import GridCard from '@/components/ui/grid/GridCard';
import GridContent from '@/components/ui/grid/GridContent';
import GridHeader from '@/components/ui/grid/GridHeader';
import FormInline from '@/components/ui/form/FormInline';
import ButtonDownload from '@/components/ui/button/ButtonDownloadPdf';

export default {
    components: {ButtonDownload, FormInline, GridHeader, GridContent, GridCard, GridContainer, LinkUnderline, Popin, ButtonLink, Button, SectionTableInfos, PageHeader, PageBack, TemplateDetails, TableSticky},
    name: "InvoicePage",
    data() {
        return {
            id: this.$route.params.id,
            deleteLoading: false,
            fieldsInvoice: [
                {key: 'id'},
                {key: 'invoiceNumber', type: 'copy'},
                {key: 'invoiceType', type: 'tagType'},
                {key: 'status', type: 'tagType'},
                {key: 'invoicePreviousBalance', type: 'money'},
                {key: 'client', type: 'client'},
                {key: 'clientName'},
                {key: 'clientAgent'},
                {key: 'clientAddress'},
                {key: 'clientCity'},
                {key: 'clientPostalCode'},
                {key: 'clientCountry'},
                {key: 'invoiceDate', type: 'date'},
                {key: 'invoiceStartDate', type: 'date'},
                {key: 'invoiceEndDate', type: 'date'},
                {key: 'invoicePreviousBalance', type: 'money'},
            ],
            fieldsParameters: [
                {key: 'reconditioningPriceUser', type: 'money'},
                {key: 'reconditioningPriceStore', type: 'money'},
                {key: 'reconditioningPriceClient', type: 'money'},
                {key: 'commissionRateOnline', type: 'percent'},
                {key: 'enableTax', type: 'boolean'},
                {key: 'clientVatType', type: 'tagType'},
                {key: 'clientAgent'},
                {key: 'clientName'},
                {key: 'clientAddress'},
                {key: 'clientPostalCode'},
                {key: 'clientCity'},
                {key: 'clientCountry'},
                {key: 'clientBalance', type: 'money'},
            ],
            fieldsForm: [
                {key: 'clientAgent'},
                {key: 'clientName'},
                {key: 'clientAddress'},
                {key: 'clientPostalCode'},
                {key: 'clientCity'},
                {key: 'clientCountry'},
                {key: 'clientBalance', type: 'number'},
                {key: 'clientVatType', type: 'select',
                    options: [
                        {name: this.$t('global.tva_generale'), value: 'tva_generale'},
                        {name: this.$t('global.tva_marge'), value: 'tva_marge'}
                    ]
                },
                {key: 'enableTax', type: 'select',
                    options: [
                        {name: this.$t('global.yes'), value: '1'},
                        {name: this.$t('global.no'), value: '0'}
                    ]
                },
                {key: 'commissionRateOnline', type: 'number'},
                {key: 'reconditioningPriceStore', type: 'number'},
                {key: 'reconditioningPriceUser', type: 'number'},
                {key: 'reconditioningPriceClient', type: 'number'},
            ],
            fieldsDetails: [
                {key: 'id', type: 'eye', route: 'invoice-line', size: 'xs'},
                {key: 'id'},
                {key: 'product', size: 'l'},
                {key: 'quantity'},
                //{key: 'unitPrice', type: 'formatPrice'},
                {key: 'amountWithoutTax', type:'formatPrice'},
                {key: 'taxAmount', type:'formatPrice'},
                {key: 'amountWithTax', type:'formatPrice'}
            ],
        }
    },
    computed: {
        ...mapState(['invoice', 'invoiceLoading']),
        ...mapGetters(['isAdmin']),
        invoiceDetailId() {
            return this.$route.params.id;
        },
        canBeEdit(){
            return this.isAdmin && this.invoice?.status === 'draft' && ['OneshotInvoice', 'CreditInvoice'].includes(this.invoice?.invoiceType)
        },
        details() {
            return this.invoice?.invoiceDetails
        },
        parameters() {
            if (!this.invoice?.parameters) return {};
            return Object.fromEntries(this.invoice.parameters.map(a => ([a.name, a.value])))
        },
        fieldsAvailable() {
            if (!this.parameters) return [];
            return this.fieldsForm.filter(field => Object.hasOwn(this.parameters ,field.key))
        }
    },
    methods: {
        ...mapActions(['getInvoice','deleteInvoice', 'setNotificationMessage']),
        deleteInvoice() {
            this.deleteLoading = false
            this.deleteInvoice(this.invoiceDetailId)
                .then(data => {
                    this.deleteLoading = true;
                    this.$refs?.popinDelete?.close();
                    this.setNotificationMessage(data.message || this.$t('invoice.delete_success'))
            })
            .finally(() => this.$router.replace({name:'invoices-folders'}))
        }
    },
}
</script>