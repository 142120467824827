import API from '@/api/api-admin';
import listRoutes from '@/api/list-routes';
import i18n from '@/i18n';

const state = {
    allLocales: [],
    clientLocales: [],
};

const mutations = {
    SET_ALL_LOCALES: (state, locales) => (state.allLocales = locales),
    SET_CLIENT_LOCALES: (state, locales) => (state.clientLocales = locales),
};

const actions = {
    fetchFromAPI({ commit }, { route, mutationType, method = 'get', payload = {} }) {
        return API[method](route, payload)
            .then(response => {
                commit(mutationType, response.data);
                return response.data;
            })
            .catch(error => {
                console.error(`Erreur lors de la requête ${route} :`, error);
                throw error;
            });
    },

    getAllLocales(context) {
        return context.dispatch('fetchFromAPI', {
            route: listRoutes.websiteMarketLocales,
            mutationType: 'SET_ALL_LOCALES',
        });
    },

    getClientLocales(context, idClient) {
        return context.dispatch('fetchFromAPI', {
            route: listRoutes.clientLanguages.replace(':id', idClient),
            mutationType: 'SET_CLIENT_LOCALES',
        });
    },

    addLocale(context, { idClient, locale }) {
        return context.dispatch('fetchFromAPI', {
            route: listRoutes.clientLanguages.replace(':id', idClient),
            mutationType: 'SET_CLIENT_LOCALES',
            method: 'post',
            payload: { locale },
        });
    },

    deleteLocale(context, { idClient, locale }) {
        return context.dispatch('fetchFromAPI', {
            route: listRoutes.clientLanguagesDelete
                .replace(':id', idClient)
                .replace(':localecode', locale.code),
            mutationType: 'SET_CLIENT_LOCALES',
            method: 'delete',
        });
    },
    
    enableLocale(context, { idClient, locale, enable }) {
        return context.dispatch('fetchFromAPI', {
            route: listRoutes.clientLanguages.replace(':id', idClient),
            mutationType: 'SET_CLIENT_LOCALES',
            method: 'put',
            payload: { locale, enable },
        });
    },

    setClientDefaultLanguage({commit}, { idClient, localeCode }) {
        return new Promise((resolve, reject) => {
            API.put(listRoutes.clientLanguages.replace(':id', idClient), {
                locale: localeCode,
                enable: true,
                isDefault: true
            })
                .then(res => res.data)
                .then(locales => {
                    commit('SET_CLIENT_LOCALES', locales);
                    commit('SET_NOTIFICATION_MESSAGE', i18n.t('languages.default.success'))
                    resolve()
                })
                .catch(err => {
                    if (err?.message && typeof err.message === 'string') {
                        commit('SET_NOTIFICATION_MESSAGE', err.message)
                    }
                    reject()
                })
        })
    }
};

const getters = {
    getClientLocales: state => state.clientLocales,
};

export default {
    state,
    mutations,
    actions,
    getters,
};
