<template>
    <div class="filters">
        <div class="filters__left">
            <slot name="left"/>
        </div>
        <div class="filters__right">
            <slot name="right"/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PageFilters'
}
</script>

<style lang="scss">
.filters {
    background-color: rgba(247,246,242,0.8);
    backdrop-filter: blur(0.5rem);
    z-index: 3;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 10px;
    position: relative;
    margin-bottom: 20px;

    @media screen and (min-width: 1200px) {
        position: sticky;
        top: var(--height-header);
        min-height: var(--filter-height);
    }

    @media screen and (max-width: 1199px) {
        flex-wrap: wrap;
    }

    .form-field-search-select,
    .field {
        margin: 0 !important;
    }

    &__search {
        flex-grow: 1;
        max-width: 350px;
    }

    &__left {
        display: flex;
        gap: 10px;
        align-items: center;
        flex-grow: 1;
        min-width: 0;

        @media screen and (max-width: 1199px) {
            flex-wrap: wrap;
            gap: 20px;
        }

        @media screen and (max-width: 450px) {
            & > * {
                width: 100%;
            }
        }
    }

    &__right {
        display: flex;
        align-items: center;
        gap: 10px;
        flex: 0 0 auto;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: auto;

        @media screen and (max-width: 1199px) {
            .field.is-switch {
                margin-top: 10px;
            }
        }
    }

    .field {
        &:nth-of-type(3) {
            & ~ .field {
                display: none;
            }
        }
    }

    @media screen and (min-width: 1200px) {
        & ~ .section-table {
            .table-item {
                overflow: unset;
            }

            .table-head {
                position: sticky;
                top: 80px;
                z-index: 2;
            }
        }
    }
}
</style>