import Voucher from '@/components/pages/admin/promotions/voucher/Voucher';
import Vouchers from '@/components/pages/admin/promotions/voucher/Vouchers';
import RecomVoucher from '@/components/pages/admin/promotions/voucher/RecomVoucher';
import RecomVouchers from '@/components/pages/admin/promotions/voucher/RecomVouchers';
import RecomPromoCodes from '@/components/pages/admin/promotions/voucher/RecomPromoCodes';
import RecomPromoCodeCreate from '@/components/pages/admin/promotions/voucher/RecomPromoCodeCreate';
import RecomPromoCodeEdit from '@/components/pages/admin/promotions/voucher/RecomPromoCodeEdit.vue';

const promotionsRoutes = [
    {
        path: '/admin/promotions',
        name: 'promotions',
        meta: {
            title: 'promotions',
            icon: 'promotion',
            roles: ['ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE', 'ROLE_BRAND_SAV'],
            cms: ['FAUME', 'SHOPIFY']
        },
        redirect: {
            name: 'vouchers'
        }
    },
    {
        path: '/admin/promotions/bons-d-achat',
        name: 'vouchers',
        component: Vouchers,
        meta: {
            title: 'vouchers',
            icon: 'promotion',
            roles: ['ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE', 'ROLE_BRAND_SAV'],
            cms: ['SHOPIFY']
        }
    },
    {
        path: '/admin/promotions/bons-d-achat-2',
        name: 'recom-vouchers',
        component: RecomVouchers,
        meta: {
            title: 'recom-vouchers',
            icon: 'promotion',
            roles: ['ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE', 'ROLE_BRAND_SAV'],
            cms: ['FAUME']
        }
    },
    {
        path: '/admin/promotions/bons-d-achat/:id',
        name: 'voucher',
        component: Voucher,
        meta: {
            roles: ['ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE', 'ROLE_BRAND_SAV'],
            cms: ['SHOPIFY']
        }
    },
    {
        path: '/admin/promotions/bons-d-achat-2/:id',
        name: 'recom-voucher',
        component: RecomVoucher,
        meta: {
            roles: ['ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE', 'ROLE_BRAND_SAV'],
            cms: ['FAUME']
        }
    },
    {
        path: '/admin/promotions/promocodes-faume',
        name: 'recom-promo-codes',
        component: RecomPromoCodes,
        meta: {
            title: 'recom-promo-codes',
            roles: ['ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE', 'ROLE_BRAND_SAV'],
            cms: ['FAUME']
        }
    },
    {
        path: '/admin/promotions/promocodes-faume-nouveau',
        name: 'recom-promo-codes-create',
        component: RecomPromoCodeCreate,
        meta: {
            title: 'recom-promo-codes-create',
            roles: ['ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE', 'ROLE_BRAND_SAV'],
            cms: ['FAUME']
        }
    },
    {
        path: '/admin/promotions/promocodes-faume-edit/:id',
        name: 'recom-promo-codes-edit',
        component: RecomPromoCodeEdit,
        meta: {
            title: 'recom-promo-codes-edit',
            roles: ['ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE', 'ROLE_BRAND_SAV'],
            cms: ['FAUME']
        }
    },
    {
        path: '/admin/promotions/*',
        redirect: {
            name: 'vouchers'
        }
    }
]

export default promotionsRoutes