<template>
    <TemplateTable
            :title="$t('menu.stock')"
            :text="$t('resalesStocks.description')"
            name="resalesStocks"
            :url="$listRoutes.resalesStocks"
            :table="resalesStocks"
            :filters="resalesStocksFilters"
            :fields="fields"
            :display-date="true"
    />
</template>

<script>
import TemplateTable from '@/components/templates/TemplateTable';
import { mapState } from 'vuex';
export default {
    name: 'ResalesStocks',
    components: {TemplateTable},
    data() {
        return {
            fields: [
                {key: 'id', type: 'eye', route: 'resale', size: 'xs'},
                {key: 'client', type: 'client', size: 'l'},
                {key: 'shopifyId', type: 'shopifyProduct', size: 'm'},
                {key: 'modelCode', type: 'copy', size: 'm'},
                {key: 'name', size: 'xxl'},
                {key: 'creditReview.maxTradeinPrice', translationKey: 'tradeinPrice', type: 'formatPrice'},
                {key: 'lastNonNullPrice', translationKey: 'shopifyPrice', type: 'formatPrice'},
                {key: 'creditReview.createdAt', type: 'date', size: 'm', translationKey: 'creditedAt'},
                {key: 'stockCycle.dispatchedAt', type: 'date', size: 'm'},
                {key: 'stockCycle.receivedAt', type: 'date', size: 'm'},
                {key: 'stockCycle.stockedAt', type: 'date', size: 'm'},
                {key: 'user.type', translationKey: 'resaleSource', type: 'tagType', size: 'm'},
                {key: 'creditReview.declarer', translationKey: 'resaleCreditSource', type: 'tagType', size: 'm'},
                {key: 'creditReview.condition.name', translationKey: 'condition', type: 'tagType'},
                {key: 'lastReview.condition.name', translationKey: 'lastReviewCondition', type: 'tagType', size: 'm'},
                {key: 'realStore', size: 'm', translationKey: 'store'},
                {key: 'shopifyPublished', type: 'boolean', size: 'm'},
                {key: 'country', translationKey: 'tradeinCountry', size: 'm'},
            ]
        }
    },
    computed: {
        ...mapState(['resalesStocks', 'resalesStocksFilters'])
    }
}
</script>