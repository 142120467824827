<template>
  <GridCard>
    <GridContent>
        <GridHeader 
            :title="title" 
            :text="subtitle"
        />
        <FieldSelect
            :multiple="true"
            :options="options"
            :border="true"
            :selected="value"
            :sorted="false"
            @input="e => $emit('change', value = e)"
        />
        <div class="catalogue-product__option" v-if="checkbox">
            <p>{{$t('catalogue_products.display_labels')}}</p>
            <ToggleSwitcher :model="toggled" @change="e => changeAndEmit(e)" />
        </div>
    </GridContent>
  </GridCard>
</template>

<script>
import GridCard from '@/components/ui/grid/GridCard.vue';
import GridContent from '@/components/ui/grid/GridContent.vue';
import GridHeader from '@/components/ui/grid/GridHeader.vue';
import FieldSelect from '@/components/ui/form/fields/FieldSelect.vue';
import ToggleSwitcher from '@/components/ui/switcher/ToggleSwitcher.vue';
export default {
    components: {
        GridCard,
        GridContent,
        GridHeader,
        FieldSelect,
        ToggleSwitcher
    },
    props: {
        title: String,
        options: Array,
        model: Array,
        subtitle: String,
        checkbox: {
            type: Boolean,
            default: false
        },
    },

    data() {
        return {
            value: this.extractValues(this.model),
            toggled: this.checkFormatAndSetFlag(this.model)
        }
    },
    watch: {
        value:{
            handler(newValue){
                if(newValue) this.$emit('change',newValue)
            }, immediate: true
        },
        toggled:{
            handler(newValue){
                if(newValue) this.changeAndEmit(newValue)
            }, immediate: true
        }
    },
    methods: {
        extractValues(arr) {
            // Transforme un tableau avec des éléments de la forme 'key: #value' ou '#value' en un tableau de 'key'.
            return arr.map(item => {
                // Séparation de l'élément en fonction de ': ' et récupération de la deuxième partie si nécessaire
                const parts = item.split(': ');
                const key = parts.length > 1 ? parts[1] : item;
                // Suppression du caractère '#' et retour du résultat
                return key.replace('#', '');
            });
        },
        checkFormatAndSetFlag(arr) {
            // Vérifie si chaque élément du tableau est au format 'key: #key'
            return arr.every(item => {
                const parts = item.split(': ');
                return parts.length === 2 && parts[0] + ': #' + parts[0] === item;
            });
        },
        changeAndEmit(e){
            this.toggled = e
            this.$emit('addCondition',e)
        }
    },


}
</script>

<style lang="scss">
.catalogue-product {

    &__option {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-top: 20px;
        & > * {
            margin: 0;
        }
    }
}

</style>