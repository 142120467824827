<template>
    <TemplateTable
            :title="$t('client.stock.create_transfer')"
            name="clientAvailableProducts"
            :url="$listRoutes.clientAvailableProducts.replace(':id', $route.params.id)"
            :table="clientAvailableProducts"
            :filters="clientAvailableProductsFilters"
            :fields="fields"
            :hide-export="true"
            :default-items-per-page="14"
            :display-page-back="true"
            :page-back-url="{name: 'client-store-transfers'}"
            :no-cache="true"
            :display-table="displayTable">

        <template v-slot:actions v-if="storeArticlesSelected?.length && products?.length">
            <Button size="m" color="red" icon="cross" @click.native.prevent="init">{{ $t('client.stock.cancel_selection') }}</Button>
            <ButtonLink size="m" color="blue2" icon="right" :to="{name: 'client-store-stock-selected'}">{{ $t('client.stock.see_selected') }} ({{storeArticlesSelected.length}})</ButtonLink>
        </template>

        <template v-slot:right>
            <FieldSwitch
                    :title_1="$t('client.stock.switch_table')"
                    :value_1="true"
                    :title_2="$t('client.stock.switch_cards')"
                    :value_2="false"
                    :model="displayTable"
                    @change="e => displayTable = e"/>
        </template>

        <div v-if="!displayTable && products?.length">
            <ProductListing>
                <ProductCard v-for="(product, i) in products" :key="i" :product="product">
                    <ProductCheckbox :product="product" store-key="storeArticlesSelected"/>
                </ProductCard>
            </ProductListing>
            <TablePagination v-if="clientAvailableProducts?.pagination" :pagination="clientAvailableProducts?.pagination" />
        </div>

    </TemplateTable>
</template>

<script>
import TemplateTable from '@/components/templates/TemplateTable';
import { mapState, mapActions } from 'vuex';
import FieldSwitch from '@/components/ui/form/fields/FieldSwitch';
import ProductListing from '@/components/ui/product/ProductListing';
import ProductCard from '@/components/ui/product/ProductCard';
import TablePagination from '@/components/ui/table/TablePagination';
import ProductCheckbox from '@/components/ui/product/ProductCheckbox';
import ButtonLink from '@/components/ui/button/ButtonLink';
import Button from '@/components/ui/button/Button';

export default {
    name: 'StoreTransferCreate',
    components: {Button, ButtonLink, ProductCheckbox, TablePagination, ProductCard, ProductListing, FieldSwitch, TemplateTable},
    data() {
        return {
            displayTable: true,
            fieldsBase: [
                {key: 'id', type: 'productCheckbox', size: 's', label: 'selected', param: 'storeArticlesSelected'},
                {key: 'id', size: 'xs', type: 'productLightbox', label: ' ', noTranslate: true},
                {key: 'title', size: 'xxl'},
                {key: 'etat', size: 'm', label: 'condition'},
                {key: 'color', size: 'm'},
                {key: 'size', size: 's'},
                {key: 'price', size: 's', type: 'money'},
                {key: 'credit_price', size: 's', type: 'money'},
                {key: 'photo1', size: 'xs', type: 'productImg', translation: 'photo'},
                {key: 'url', size: 'm', type: 'productUrl'},
            ],
            fields: []
        }
    },
    watch: {
        clientAvailableProducts() {
            this.getFields()
        }
    },
    computed: {
        ...mapState(['clientAvailableProducts', 'clientAvailableProductsFilters', 'storeArticlesSelected', 'storeArticlesSelectedClient']),
        products() {
            return this.clientAvailableProducts?.items
        }
    },
    methods: {
        ...mapActions(['setStoreArticlesSelected']),
        getFields() {
            let fields = [...this.fieldsBase];
            const product = this.products ? this.products[0] : null;
            if (product) {
                const productKeys = Object.keys(product);
                let optionalFields = [
                    {label: 'gender', values: ['univers', 'genre', 'gender']},
                    {label: 'tag_size', values: ['size', 'pointure', 'taille']},
                    {label: 'tag_color', values: ['couleur', 'color']},
                ];
                optionalFields.forEach(field => {
                    const key = productKeys.find(x => field.values.find(v => x.includes(v)));
                    if (key) fields.push({key: key, size: 'm', label: field.label})
                });
            }
            this.fields = [...fields]
        },
        init() {
            this.setStoreArticlesSelected([]);
        }
    },
    beforeMount() {
        if (this.storeArticlesSelectedClient && this.storeArticlesSelectedClient !== this.$route.params.id) this.init();
        this.getFields();
    }
}
</script>

