<template>
    <div class="page-header" :class="{'has-margin-top': marginTop}">
        <div class="page-header__text">
            <TextTitle :size="titleSize">{{ title }}</TextTitle>
            <TextTitle v-if="subtitle" tag="h2" size="s">{{ subtitle }}</TextTitle>
            <TextSimple v-if="text" size="m" :html-content="text"/>
            <slot name="text"/>
        </div>
        <div class="page-header__actions" v-if="$slots.actions">
            <slot name="actions"/>
        </div>
    </div>
</template>

<script>
import TextTitle from '@/components/ui/text/TextTitle';
import TextSimple from '@/components/ui/text/TextSimple';
export default {
    components: {TextSimple, TextTitle},
    props: {
        title: String,
        subtitle: String,
        text: String,
        titleSize: {
            type: String
        },
        marginTop: Boolean
    }
}
</script>

<style lang="scss">
.page-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 40px;

    @media screen and (max-width: 550px) {
        margin-bottom: 20px;
    }

    &.has-margin-top {
        margin: 40px 0 20px;
    }

    &__text {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    &__actions {
        flex-shrink: 0;
        margin-left: 40px;
        display: flex;
        gap: 10px;
        align-items: center;
        margin-top: 5px;

        @media screen and (max-width: 800px) {
            .button {
                padding: 0;
                min-width: 40px;

                & > span > span {
                    &:first-of-type {
                        display: none;

                        & + span {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }

    .tag {
        padding: 8px 15px;
        margin: 0;
        font-size: 1.2rem;
        border-radius: 35px;
    }
}
</style>