<!-- Based on ConfigWebsite.vue -->
<template>
    <TemplateDetails :data="catalogues" :loading="cataloguesLoading" :getFunction="getCatalogues">
        <div>
            <PageBack/>
            <PageHeader :title="title">
                <template v-slot:actions>   
                    <Button icon="cross" size="m" color="grey" @click.native.prevent="$refs.popinValidationCancel.open">{{ $t('global.cancel') }} </Button>
                    <Button icon="check" v-if="currentCatalogue.pricing" :class="errors ? 'disabled' : ''" size="m" color="blue" @click.native.prevent="$refs.aboutToLaunchCatalogue.open">{{$t('catalogue.validate_and_update')}}</Button>
                    <Button icon="publish" v-if="currentCatalogue && currentCatalogue.configuration && currentCatalogue.status.validation.isValidated && currentCatalogue.status.generation.isGenerated && currentCatalogue.pricing" color="blue" size="m" @click.native.prevent="$refs.launchThisCatalogue.open">{{ $t('catalogue.publish') }}</Button>
                </template>
            </PageHeader>
            <GridContainer>
                
                <ImportStatus 
                    :missingDatas="statusMissingDatas" 
                    :statusLines="statusLines"  
                />
                <Checkerboard>
                    <CheckerboardItem 
                        v-for="(item,keyName) in getCatalogueValidationErrors" 
                        :key="keyName"
                        :title="($t(getCatalogueValidationErrors[keyName].length)).toString()"
                        :subtitle="$t(`catalogue.validation_${keyName.split('-')[1]}`)"
                        :smalltext="$t(`catalogue.${keyName.split('-')[0]}`)"
                        :info="$t(`catalogue.${keyName.split('-')[0]}_infos`)"
                        >
                    </CheckerboardItem>
                </Checkerboard>

            </GridContainer>
            <!-- <TableSticky 
                ref="TableSticky" 
                :fields="criterias" 
                :url="$listRoutes.catalogueCreate"
                :data="errors ? getCatalogueValidationErrors.missing_data :getCatalogueMappingState.products"
            /> -->
        </div>
        
        <!-- <PageError v-else title="404" :subtitle="$t('website.not-found')" :button="$t('website.return')" :to="{name: 'config-websites'}"/> -->
       
        <Popin :title="$t('catalogue.popin_launch_cancel')" ref="popinValidationCancel">
            <template v-slot:bottom>
                <Button @click.native.prevent="$refs.popinValidationCancel.close" color="green" size="m">{{ $t('catalogue.popin_launch_cancel_confirm') }}</Button>
                <ButtonLink color="grey" size="m" :to="{name:'config-website-catalogues-update'}">{{ $t('catalogue.popin_launch_cancel_abort') }}</ButtonLink>
            </template>
        </Popin>

      <Popin :title="$t('catalogue.about_to_launch')" ref="aboutToLaunchCatalogue">
        <template v-slot:bottom>
          
            <Button v-if="!loading" @click.native.prevent="$refs.aboutToLaunchCatalogue.close" color="red" size="m">{{ $t('global.cancel') }}</Button>
            <Button v-if="!loading" color="green" size="m" @click.native.prevent="generateThisCatalogue">{{ $t('catalogue.generation_start') }}</Button>
            <InPopinLoader
                v-if="loading"
                :msg="$t('catalogue.please_wait_setup')" 
                :loading="loading" 
            />
          
        </template>
      </Popin>

      <Popin :title="$t('catalogue.publish')" ref="launchThisCatalogue">
        <template v-slot:content>
          <div v-if="!validationSuccess" class="popin__content--row">
            <Button @click.native.prevent="$refs.launchThisCatalogue.close" color="red" size="m">{{ $t('global.cancel') }}</Button>
            <Button color="green" size="m" @click.native.prevent="launchThisCatalogue">{{ $t('catalogue.publish') }}</Button>
          </div>
          <div v-if="validationSuccess" class="popin__content--catalogues">
            <TextTitle v-if="title" tag="h2" size="xs">{{ $t('catalogue.publish_success') }}</TextTitle>
            <div class="popin__content--row">
                <ButtonLink color="grey" size="m" :to="{name:'config-website-catalogues-update'}">{{ $t('global.back') }}</ButtonLink>
            </div>
          </div>
        </template>
      </Popin>

      <Popin :title="getCatalogueProgressStateValues !== '100%' ? $t('catalogue.generation') : $t('catalogue.generation_end')" ref="popinProgression">
        <template v-slot:content>
          <div v-if="catalogueProgress && !validationSuccess">
            <ProgressBar 
              v-if="showProgressBar" 
              :start_value="catalogueProgress.totalLinesProcessed" 
              :start_text="$t('catalogue.totalProductsProcessed')" 
              :total_value="catalogueProgress.totalLines" 
              :total_text="$t('catalogue.totalProducts')" 
              :progression="getCatalogueProgressStateValues" 
            />
          </div>
            <InPopinLoader 
                v-if="catalogueProgressStatus" 
                :msg="$t('catalogue.please_wait_end')" 
                :loading="catalogueProgressStatus" 
            />
          <div v-if="getCatalogueProgressStateValues === '100%' && !validationSuccess && !catalogueProgressStatus" class="popin__content--catalogues">
            <TextTitle v-if="title" tag="h2" size="xs">{{ popinValidationTitle }}</TextTitle>
            <div class="popin__content--row">
                <ButtonLink color="red" size="m" :to="{name:'config-website-catalogues-update'}">{{ $t('global.back') }}</ButtonLink>
                <Button  color="green" size="m" @click.native.prevent="launchThisCatalogue">{{ $t('catalogue.publish') }}</Button>

            </div>
          </div>
          <div v-if="validationSuccess && !catalogueProgressStatus" class="popin__content--catalogues">
            <TextTitle v-if="title" tag="h2" size="xs">{{ $t('catalogue.publish_success') }}</TextTitle>
            <div class="popin__content--row">
                <ButtonLink color="grey" size="m" :to="{name:'client-catalog'}">{{ $t('global.back') }}</ButtonLink>
            </div>
          </div>
        </template>
      </Popin>
    </TemplateDetails>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import TemplateDetails from '@/components/templates/TemplateDetails.vue';
import PageHeader from '@/components/ui/page/PageHeader.vue';
import Button from '@/components/ui/button/Button.vue';
import ButtonLink from '@/components/ui/button/ButtonLink.vue';
import Popin from '@/components/ui/popin/Popin.vue';
import InPopinLoader from '@/components/ui/popin/InPopinLoader.vue';
import PageBack from '@/components/ui/page/PageBack.vue';
import GridContainer from '@/components/ui/grid/GridContainer.vue';
import ImportStatus from '@/components/ui/status/ImportStatus.vue';
import Checkerboard from '@/components/ui/checkerboard/Checkerboard.vue';
import CheckerboardItem from '@/components/ui/checkerboard/CheckerboardItem.vue';
import ProgressBar from '@/components/ui/progress/ProgressBar.vue';
import TextTitle from '@/components/ui/text/TextTitle.vue';


export default {
    name: 'ConfigWebsiteCatalogueValidation',
    components: {
        TemplateDetails,
        GridContainer,
        PageBack,   
        ButtonLink,
        Button,
        Popin,
        InPopinLoader,
        PageHeader,
        ImportStatus,
        Checkerboard,
        CheckerboardItem,
        ProgressBar,
        TextTitle
    },
    props: {
        catalogueID: {
            type: String,
        },
    },
    data() {
        return {
            deleteLoading: false,
            show:true,
            fields: [
                {key: 'name'},
                {key: 'nameUrl'},
                {key: 'emailLogistic'},
                {key: 'frontUrl', type: 'url'},
                {key: 'frontUrlClient', type: 'url'},
                {key: 'shopifyUrl', type: 'url'},
                {key: 'domain', type: 'url'},
                {key: 'codeTypeReal'},
                {key: 'autoCreditedWallet', type: 'boolean'},
                {key: 'isRef', type: 'boolean'},
                {key: 'promoByProduct', type: 'boolean'},
                {key: 'resellActivated', type: 'boolean'},
                {key: 'shopClientActivated', type: 'boolean'},
                {key: 'shopIRLActivated', type: 'boolean'},
                {key: 'skipStep1', type: 'boolean'},
                {key: 'status'},
            ],
            showProgressBar:false,
            validationSuccess:false,
            loading:false
        }
    },
    computed: {
        ...mapState(['catalogues','cataloguesLoading','catalogueProgress','catalogueProgressStatus']),
        ...mapGetters([
            'getCatalogueValidationErrors',
            'getCurrentCatalogue',
            'getCatalogueMappingState',
            'getCatalogueProgressStateValues'
        ]),
        id() {
            return this.$route.params.id
        },
        lang() {
            return this.$route.params.lang
        },
        client() {
            return this.$store.getters.getClientById(this.id)
        },
        title(){
            return this.currentCatalogue ? `${this.$t('catalogue.update')} ${this.client.name} : ${this.currentCatalogue.title}` : ''
        },
        currentCatalogue(){
            const getCurrentCatalogue = this.getCurrentCatalogue;
            return getCurrentCatalogue 
                ? getCurrentCatalogue(this.lang)(this.catalogueID)
                : null;
        },
        statusLines(){
            return this.currentCatalogue?.status?.validation 
                ? [
                    {
                        traduction:`${this.$t('catalogue.lines_treated')}`,
                        value:this.currentCatalogue.status.validation.totalLinesProcessed
                    },
                    {
                        traduction:`${this.$t('catalogue.lines_missing_infos')}`,
                        value:this.statusMissingDatas
                    },
                ]
                : null
        },
        criterias(){
            const criterias = []
            
            this.getCatalogueMappingState.headers 
                ? this.getCatalogueMappingState.headers.forEach(criteria => criterias.push({key:`criterias.${criteria}`, size:'m', noTranslate:true, label:criteria})) 
                : null
            
            return criterias
        },
        errors(){
            return Object.keys(this.getCatalogueValidationErrors).length > 0
        },
        statusMissingDatas() {
            let totalErrors = 0;
            
            for (const key in this.getCatalogueValidationErrors) {
                if (Array.isArray(this.getCatalogueValidationErrors[key])) {
                    totalErrors += this.getCatalogueValidationErrors[key].length;
                }
            }
            return totalErrors 
        },
        popinValidationTitle(){
            return `${this.$t('catalogue.launch')} ${this.$route.params.lang}`
        },
    },
    methods: {
        ...mapActions(['prefetchCatalogue','getCatalogueValidation','catalogueValidationOrGeneration', 'catalogueLaunch','fetchCatalogues']),
        getCatalogues(){
            this.getCatalogueValidation(this.catalogueID)
            this.prefetchCatalogue(this.catalogueID)
            this.fetchCatalogues(this.id)
        },

        generateThisCatalogue(){
            this.loading = true
            this.catalogueValidationOrGeneration({id:this.catalogueID,clientID:parseInt(this.id),type:'generation'})
                .then(response => {
                    if(response){
                        this.$refs.aboutToLaunchCatalogue.close()
                        this.$refs.popinProgression.open()
                        this.showProgressBar = true
                        this.loading = false
                    }
                })
                .catch(err => {
                    console.log(err)          
                });
        },
        launchThisCatalogue(){
            this.catalogueLaunch({id:this.id, catalogueID:this.catalogueID,fetchAfter:false})
                .then(response => {
                    console.log('launchThisCatalogue', response)
                })
                .catch(err => console.log(err))
                .finally(()=> {
                    this.validationSuccess = true
                })
        },
    },

    
}
</script>
<style lang="scss" scoped>
.popin {
    &__content--catalogues {
        display: flex;
        flex-direction: column;
        padding-top: 10px;
        gap: 10px;
        align-items: stretch;
        border-top: var(--border-2);
        margin-top: 20px;
    }
    &__content--row {
        display:flex;
        align-items:center;
        justify-content:center;
        gap: 20px;
        & > .button:is(:only-child) {
            margin-left: auto;
            margin-right: auto;
        }
        .button + .button {
            margin-top: 0;
        }
    }
}

</style>