import DashboardGoldenmetrics from '@/components/pages/admin/dashboard/DashboardGoldenmetrics';
import DashboardStock from '@/components/pages/admin/dashboard/DashboardStock';
import DashboardResales from '@/components/pages/admin/dashboard/DashboardResales';
import DashboardOrders from '@/components/pages/admin/dashboard/DashboardOrders';
import DashboardPerformances from '@/components/pages/admin/dashboard/DashboardPerformances';
import DashboardSellers from '@/components/pages/admin/dashboard/DashboardSellers';
import DashboardBuyers from '@/components/pages/admin/dashboard/DashboardBuyers';
import DashboardBuyingFunnel from '@/components/pages/admin/dashboard/DashboardBuyingFunnel';
import DashboardSellingFunnel from '@/components/pages/admin/dashboard/DashboardSellingFunnel';
import DashboardOperations from '@/components/pages/admin/dashboard/DashboardOperations';

const dashboardRoutes = [
    {
        path: '/admin/dashboard',
        name: 'dashboard',
        redirect: {
            name: 'dashboard-goldenmetrics'
        },
        meta: {
            title: 'data-analysis',
            icon: 'stars',
            roles: ['ROLE_ADMIN', 'ROLE_FINANCE', 'ROLE_CLIENT', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/dashboard/goldenmetrics',
        name: 'dashboard-goldenmetrics',
        component: DashboardGoldenmetrics,
        meta: {
            title: 'goldenmetrics',
            icon: 'starts',
            roles: ['ROLE_ADMIN', 'ROLE_FINANCE', 'ROLE_CLIENT', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/dashboard/ventes',
        name: 'dashboard-orders',
        component: DashboardOrders,
        meta: {
            title: 'orders',
            roles: ['ROLE_ADMIN', 'ROLE_FINANCE', 'ROLE_CLIENT', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/dashboard/reprises',
        name: 'dashboard-resales',
        component: DashboardResales,
        meta: {
            title: 'resales',
            roles: ['ROLE_ADMIN', 'ROLE_FINANCE', 'ROLE_CLIENT', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/dashboard/stock',
        name: 'dashboard-stock',
        component: DashboardStock,
        meta: {
            title: 'stock',
            roles: ['ROLE_ADMIN', 'ROLE_FINANCE', 'ROLE_CLIENT', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/dashboard/performances',
        name: 'dashboard-performances',
        component: DashboardPerformances,
        meta: {
            title: 'performances',
            //roles: ['ROLE_ADMIN', 'ROLE_FINANCE', 'ROLE_CLIENT', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE']
            roles: ['ROLE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/dashboard/vendeurs',
        name: 'dashboard-sellers',
        component: DashboardSellers,
        meta: {
            title: 'sellers',
            roles: ['ROLE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/dashboard/acheteurs',
        name: 'dashboard-buyers',
        component: DashboardBuyers,
        meta: {
            title: 'buyers',
            roles: ['ROLE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/dashboard/funnel-achat',
        name: 'dashboard-buying-funnel',
        component: DashboardBuyingFunnel,
        meta: {
            title: 'funnel-buying',
            roles: ['ROLE_ADMIN', 'ROLE_BRAND_ADMIN', 'ROLE_CLIENT'],
            cms: ['FAUME']
        }
    },
    {
        path: '/admin/dashboard/funnel-vente',
        name: 'dashboard-selling-funnel',
        component: DashboardSellingFunnel,
        meta: {
            title: 'funnel-selling',
            roles: ['ROLE_ADMIN', 'ROLE_BRAND_ADMIN', 'ROLE_CLIENT'],
            cms: ['FAUME']
        }
    },
    {
        path: '/admin/dashboard/goldenmetrics-operations',
        name: 'dashboard-operations',
        component: DashboardOperations,
        meta: {
            title: 'goldenmetrics-operations',
            icon: 'starts',
            roles: ['ROLE_ADMIN', 'ROLE_FINANCE', 'ROLE_CLIENT', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE'],
            cms: ['SHOPIFY']
        }
    },
]

export default dashboardRoutes