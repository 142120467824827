import DashboardAPI from '@/api/api-dahsboard';
import listRoutes from '@/api/list-routes';
import ApiAdmin from '@/api/api-admin';

const state = {
    goldenMetrics: null,
    goldenMetricsListClients: null,
    goldenMetricsLastRequest: null
};

const mutations = {
    SET_GOLDEN_METRICS_LIST_CLIENTS(state, data) {
        state.goldenMetricsListClients = data;
    },
    SET_GOLDEN_METRICS(state, data) {
        state.goldenMetrics = data;
        state.goldenMetrics.clients = state.goldenMetrics.clients.filter(c => c.client)
    },
    UNSET_GOLDEN_METRICS(state) {
        state.goldenMetrics = null
    },
    SET_GOLDEN_METRICS_LAST_REQUEST(state, value) {
        state.goldenMetricsLastRequest = value
    }
};

const actions = {
    getGoldenMetricsClients({commit}) {
        return new Promise(resolve => {
            DashboardAPI(listRoutes.goldenmetricsClients)
                .then(res => commit('SET_GOLDEN_METRICS_LIST_CLIENTS', res.data))
                .finally(() => resolve())
        })
    },
    getGoldenMetrics({commit, state}, data) {
        commit('UNSET_GOLDEN_METRICS');
        const url = `${data.route}?${data.filters}`;
        commit('SET_GOLDEN_METRICS_LAST_REQUEST', url)
        ApiAdmin.get(url)
            .then(res => {
                if (res && res.data && url === state.goldenMetricsLastRequest) {
                    commit('SET_GOLDEN_METRICS', res.data)
                }
            })
    },
    exportGoldenMetrics(_, data) {
        ApiAdmin.get(`${data.route}?${data.filters}&locale=${data.lang}&format=csv`)
            .then(res => res.data)
            .then(res => {
                const blob = new Blob([res], {type: 'text/csv;charset=utf-8'});
                const a = document.createElement('a');
                a.download = `${data.route.replace('/', '_')}.csv`;
                a.href = URL.createObjectURL(blob);
                a.click()
            })
    }
}

const getters = {
    period1(state) {
        return state.goldenMetrics ? state.goldenMetrics.sum[0].period : null
    },
    period2(state) {
        return state.goldenMetrics ? state.goldenMetrics.sum[1].period : null
    },
    sumEvolPercent(state) {
        return state.goldenMetrics && state.goldenMetrics.sum ? state.goldenMetrics.sum.evol_percent : null
    }
}

const goldenmetrics = {
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default goldenmetrics