<template>
    <div>
        <CataloguesCard v-for="catalogue in catalogues" :key="catalogue.id" :catalogue="catalogue"></CataloguesCard>
    </div>
</template>

<script>
import CataloguesCard from '@/components/ui/catalogue/CataloguesCard'
export default {
    name: 'CatalogueList',
    components: {
        CataloguesCard,
    },
    props: {
        catalogues: Array
    }
}
</script>

