import moment from 'moment';
import i18n from '@/i18n.js'

const filters = {

    // Format product price
    money: value => {
        value = value ? Number(value) : 0;
        value = value % 1 === 0 ? value : value.toFixed(2);
        return `${(value).toLocaleString('fr-FR')}€`
    },

    formatPrice: (value, noCurrency) => {
        value = value ? Number(value) : 0;
        value = value / 100;
        value = value % 1 === 0 ? value : value.toFixed(2);
        return noCurrency ? (value).toLocaleString('fr-FR') : `${(value).toLocaleString('fr-FR')}€`
    },

    date: value => {
        if (!value) return '';
        return moment(value).format(window.i18n.locale === 'fr' ? 'DD/MM/YYYY' : 'MM/DD/YYYY')
    },

    dateHour: value => {
        if (!value) return '';
        return moment(value).format(window.i18n.locale === 'fr' ? 'DD/MM/YYYY - HH:mm' : 'MM/DD/YYYY - hh:mm')
    },

    dateHourFromUTC: value => {
        if (!value) return '';
        return moment.utc(value).local().format(window.i18n.locale === 'fr' ? 'DD/MM/YYYY - HH:mm' : 'MM/DD/YYYY - hh:mm A');
    },

    momentDate: value => {
        if (!value) return '';
        return moment(value, 'YYYY-MM-DD').format(window.i18n.locale === 'fr' ? 'DD/MM/YYYY' : 'MM/DD/YYYY')
    },

    momentDateTime: value => {
        if (!value) return '';
        return moment(value, 'YYYY-MM-DD HH:mm:ss').format(window.i18n.locale === 'fr' ? 'DD/MM/YYYY HH:mm' : 'MM/DD/YYYY h:mm a')
    },

    // First letter uppercase
    firstUp: value => {
        if (!value || value === '') return '';
        return value.charAt(0).toUpperCase() + value.slice(1)
    },

    voucherType: value => {
        if (!value || value === '') return '';
        return value.charAt(0).toUpperCase() + value.slice(1)
    },

    replace: (text, x, y = '') => {
        if (!text || !x || !y) return text;
        return text.replaceAll(x, y)
    },

    goldenMetrics: (value, format) => {
        if (value && typeof value === 'number'&& (format === '€' || format === 'money')) value = Math.round(value * 100) / 100
        if (!format) {
            if (!value) return '0';
            return (value).toLocaleString('fr-FR')
        }
        value = value || 0;
        if (!format || format === 'pièces' || format === 'pièce') return value.toLocaleString('fr-FR');
        return format === '%' ? `${value}%` : `${(value).toLocaleString('fr-FR')}€`
    },

    percent: value => {
        if (value && typeof value === 'number') value = Math.round(value * 100) / 100;
        value = value || 0;
        return `${value}%`
    },

    fieldValue: (item, field) => {
        if (!field || !item) return undefined;
        const pathArray = field.key?.split('.');
        if (!pathArray) return undefined;
        let object = item;
        for (const propertyName of pathArray) {
            if (object === null || object === undefined) return undefined;
            object = object[propertyName];
        }
        if (object && ['firstname', 'lastname'].includes(field.key)) {
            let count = object.length;
            return `${object.slice(0, 40)}${count > 40 ? '...' : ''}`
        }
        return object;
    },

    fieldLabel: field => {
        if (!field) return  '';
        if (field.translation) return i18n.t(field.translation);
        let label = field.label || field.name || field.key;
        if (field.noTranslate || !label) return label || '';
        return i18n.t(`global.${field.translationKey || label.split('.').pop()}`)
    },

    append: (string = '', value = '') => {
        return `${string}${value}`
    },

    tableFilter: filter => {
        if (!filter) return '';
        return `${filter.key} ${filter.operator} ${filter.value}`
    },

    translationsKey: key => {
        if (!key) return '';
        if (typeof key !== 'string') return key
        const translation = i18n.t(`translations.components.${key}`);
        if (!translation.includes('.')) return translation
        const text = key ? key.replaceAll('_', ' ') : '';
        return `${text.charAt(0).toUpperCase()}${text.slice(1)}`
    },

    getFlagEmoji: countryCode => {
        const offset = 127397;
        if (countryCode.includes('_')) countryCode = countryCode.split('_')[1]
        return countryCode
            .toUpperCase()
            .split('')
            .map(char => String.fromCodePoint(char.charCodeAt(0) + offset))
            .join('');
    },

    capitalize: text => text ? `${text.charAt(0).toUpperCase()}${text.slice(1)}` : '',
};

export default filters