<template>
    <div class="mailbuilder-parameters">
        <PageBack/>
        <PageHeader :title="$t('mailbuilder.cta')" :text="$t('global.editAt') + ' ' + formatDate(brandEmailsPreview?.updated_at)">
            <template v-if="emailsListHasUnpublished" v-slot:text>
                <TagType :value="$t('global.ready_to_publish')" />
            </template>
            <template v-slot:actions>
                <Button size="m" color="black" :disabled="!emailsListHasUnpublished" @click.native.prevent="handlePublishParameter" icon="publish">{{ $t('global.publish') }}</Button>
            </template>
        </PageHeader>
        <div class="mailbuilder-parameters__container">
            <div class="mailbuilder-parameters__container__wrapper">
                <div class="mailbuilder-parameters__container__content" v-for="(content, key) in parametersContent" :key="key">
                    <div class="mailbuilder-parameters__container__content--title">
                        <p>{{ $t(content.title) }}</p>
                        <FieldSelect v-if="content.langue"
                            :multiple="false"
                            :border="true"
                            :selected="langueSelected"
                            :options="avaibleLocales"
                            :label="$t('global.locale')"
                            @input="e => changeLangue(e)" 
                        />
                    </div>
                    <div class="mailbuilder-parameters__container__content--text">
                        <div v-for="(params, index) in content.content" :key="index" class="mailbuilder-parameters__container__content--select-container" :class="params.class">
                            <TextSimple>{{ $t(params.title) }}</TextSimple>
                            <div class="select">
                                <template v-for="(param, i) in params.input">
                                    <Field 
                                        v-if="param.type === 'number'"
                                        :key="i"
                                        @change="e => handleParamChange({content: e, mailKey: param.key})"
                                        :label="$t('global.size')"
                                        :border="true"
                                        :class="'field__input--number'"
                                        :disabled="false"
                                        :model="emailsParameters ? emailsParameters[param.key] : null"
                                        type="number"
                                    />
                                    <FieldSelect
                                        v-else-if="param.type === 'select'"
                                        :key="i"
                                        :multiple="false"
                                        :border="true"
                                        :selected="emailsParameters ? emailsParameters[param.key] : null"
                                        :options="param.options !== 'activeFontWeights' ? param.options : activeFontWeights"
                                        :label="$t(param.title)"
                                        @input="e => handleParamChange({content: e, mailKey: param.key})"
                                    />
                                    <Field 
                                        v-else-if="param.type === 'color'"
                                        :key="i"
                                        @change="e => handleParamChange({content: e, mailKey: param.key})" 
                                        :label="$t('global.color')"
                                        :border="true" 
                                        :disabled="false"
                                        :model="emailsParameters ? emailsParameters[param.key] : null" 
                                        type="color"
                                    />
                                    <Field 
                                        v-else-if="param.type === 'file'"
                                        :key="`${i}-${params.key}`"
                                        @change="e => handleImageChange({content: e, key: param.key})"
                                        :border="true" 
                                        :disabled="false"
                                        :model="emailsParameters ? emailsParameters[param.key] : null"
                                        :filetext="$t('mailbuilder.textFileField')"
                                        :max="10000000"
                                        :min="50000"
                                        :accept="['.png', '.jpeg', '.jpg', '.webp']"
                                        type="file"
                                        :name="param.key"
                                    />
                                    <HtmlEditor
                                        :key="i"
                                        v-else-if="param.type === 'html'"
                                        :keyContent="param.key" 
                                        :content="emailByKey && emailByKey[param.key] ? emailByKey[param.key] : ''" 
                                        :toolbar="false"
                                        :autoSubmit="true"
                                        :loading="false"
                                        @submit="e => handleFooterChange({content: e.content, mailKey: e.key})"
                                    />
                                    <Field 
                                        :key="i"
                                        v-else
                                        @change="e => handleFooterChange({content: e, mailKey: param.key})" 
                                        :label="$t(`global.${param.type}`)"
                                        :border="true" 
                                        :disabled="false"
                                        :model="emailByKey ? emailByKey[param.key] : null" 
                                    />
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mailbuilder-parameters__container__preview" :key="previewIndex">
                <iframe :srcdoc="brandEmailsPreview?.html"></iframe>
            </div>
        </div>
        <Popin
            ref="contentTagPopin"
            :center="true"
            :fullScreen="true"
            :title="$t('mailbuilder.title_variable_popin')"
            class="popin-email-variable"
            >
            <template v-slot:content>
                <TableSticky v-if="variables" :fields="fields" :data="variables" />
            </template>
        </Popin>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import PageBack from '@/components/ui/page/PageBack';
import PageHeader from '@/components/ui/page/PageHeader';
import TextSimple from '@/components/ui/text/TextSimple';
import Button from '@/components/ui/button/Button';
import Popin from '@/components/ui/popin/Popin';
import FieldSelect from '@/components/ui/form/fields/FieldSelect';
import Field from '@/components/ui/form/fields/Field';
import GoogleFonts from '@/assets/fonts/GoogleFonts';
import moment from 'moment';
import HtmlEditor from '@/components/ui/html-editor/HtmlEditor';
import TagType from '@/components/ui/tag/TagType';
import TableSticky from '@/components/ui/table-sticky/TableSticky';

export default {
    name: 'ClientMailbuilderParameters',
    components: {PageHeader, Button, Popin, FieldSelect, Field, TextSimple, PageBack, HtmlEditor, TagType, TableSticky},
    data() {
        return {
            langueSelected: null,
            googleFonts: GoogleFonts,
            fields: [
                { key: 'description', size: "xxl" },
                { key: 'variable', size: "xxl"}
            ],
            previewIndex: 0,
            mailKey: 'email.account.welcome', // email.trade-in.exchange_accepted
            variables: [
                {
                    'description': "Prénom de l'utilisateur",
                    'variable': "{{firstname}}",
                },
                {
                    'description': "Nom de l'utilisateur",
                    'variable': "{{lastname}}",
                },
                {
                    'description': "Email de l'utilisateur",
                    'variable': "{{email}}",
                },
                {
                    'description': "Nom de la marque",
                    'variable': "{{clientName}}",
                },
                {
                    'description': "Url du site de seconde main",
                    'variable': "{{url_front}}",
                },
                {
                    'description': "Numéro de commande",
                    'variable': "{{order_id}}",
                },
                {
                    'description': "Raison d'annulation de la commande",
                    'variable': "{{order_cancel_reason}}",
                },
                {
                    'description': "Numéro de reprise",
                    'variable': "{{trade-in_cart_id}}",
                }
            ],
            parametersContent: [
                {
                    title: 'mailbuilder.labelEdit_graphic-charter',
                    content: [
                        {
                            title: 'mailbuilder.labelEdit_typography',
                            class: 'typographie',
                            input: [
                                {
                                    key: 'mailbuilder_template_font_family',
                                    type: 'select',
                                    options: GoogleFonts.items   
                                }
                            ]
                        },
                        {
                            title: 'mailbuilder.labelEdit_title',
                            class: 'titre',
                            input: [
                                {
                                    key: 'mailbuilder_template_title_font_size',
                                    type: 'number'
                                },
                                {
                                    key: 'mailbuilder_template_title_font_weight',
                                    type: 'select',
                                    options: 'activeFontWeights'
                                },
                                {
                                    key: 'mailbuilder_template_title_font_color',
                                    type: 'color'
                                }
                            ]
                        },
                        {
                            title: 'mailbuilder.labelEdit_paragraphe',
                            class: 'paragraphe',
                            input: [
                                {
                                    key: 'mailbuilder_template_content_font_size',
                                    type: 'number'
                                },
                                {
                                    key: 'mailbuilder_template_content_font_weight',
                                    type: 'select',
                                    options: 'activeFontWeights'
                                },
                                {
                                    key: 'mailbuilder_template_content_font_color',
                                    type: 'color'
                                }
                            ]
                        },
                        {
                            title: 'mailbuilder.labelEdit_button',
                            class: 'bouton',
                            input: [
                                {
                                    key: 'mailbuilder_template_btn_background_color',
                                    type: 'color'
                                },
                                {
                                    key: 'mailbuilder_template_btn_border_color',
                                    type: 'color'
                                },
                                {
                                    key: 'mailbuilder_template_btn_border_radius',
                                    type: 'number'
                                },
                                {
                                    key: 'mailbuilder_template_btn_border_width',
                                    type: 'number'
                                },
                                {
                                    key: 'mailbuilder_template_btn_font_color',
                                    type: 'color'
                                },
                                {
                                    key: 'mailbuilder_template_btn_font_size',
                                    type: 'number'
                                }
                            ]
                        },
                        {
                            title: 'mailbuilder.labelEdit_footer',
                            class: 'footer',
                            input: [
                                {
                                    key: 'mailbuilder_template_footer_font_size',
                                    type: 'number'
                                },
                                {
                                    key: 'mailbuilder_template_footer_font_weight',
                                    type: 'select',
                                    options: 'activeFontWeights'
                                },
                                {
                                    key: 'mailbuilder_template_footer_font_color',
                                    type: 'color'
                                }
                            ]
                        }
                    ]
                },
                {
                    title: 'mailbuilder.labelEdit_custom-image',
                    content: [
                        {
                            title: 'mailbuilder.labelEdit_image_banner',
                            input: [
                                {
                                    key: 'header_img',
                                    type: 'file'
                                },
                            ]
                        },
                        {
                            title: 'mailbuilder.labelEdit_image_welcome-left',
                            input: [
                                {
                                    key: 'welcome_left_img',
                                    type: 'file'
                                },
                            ]
                        },
                        {
                            title: 'mailbuilder.labelEdit_image_welcome-right',
                            input: [
                                {
                                    key: 'welcome_right_img',
                                    type: 'file'
                                },
                            ]
                        },
                    ]
                },
                {
                    title: 'mailbuilder.labelEdit_footer',
                    langue: true,
                    content: [
                        {
                            title: 'mailbuilder.labelEdit_text-footer',
                            class: 'footer-signature',
                            input: [
                                {
                                    key: 'email.mailbuilder.footer.signature',
                                    type: 'html'
                                }
                            ]
                        },
                        {
                            title: 'global.link1',
                            class: 'footer-link',
                            input: [
                                {
                                    key: 'email.mailbuilder.footer.menu_1',
                                    type: 'text'
                                },
                                {
                                    key: 'email.mailbuilder.footer.menu_1_url',
                                    type: 'link'
                                }
                            ]
                        },
                        {
                            title: 'global.link2',
                            class: 'footer-link',
                            input: [
                                {
                                    key: 'email.mailbuilder.footer.menu_2',
                                    type: 'text'
                                },
                                {
                                    key: 'email.mailbuilder.footer.menu_2_url',
                                    type: 'link'
                                }
                            ]
                        },
                        {
                            title: 'global.link3',
                            class: 'footer-link',
                            input: [
                                {
                                    key: 'email.mailbuilder.footer.menu_3',
                                    type: 'text'
                                },
                                {
                                    key: 'email.mailbuilder.footer.menu_3_url',
                                    type: 'link'
                                }
                            ]
                        },
                        {
                            title: 'global.link4',
                            class: 'footer-link',
                            input: [
                                {
                                    key: 'email.mailbuilder.footer.menu_4',
                                    type: 'text'
                                },
                                {
                                    key: 'email.mailbuilder.footer.menu_4_url',
                                    type: 'link'
                                }
                            ]
                        },
                        {
                            title: 'global.link5',
                            class: 'footer-link',
                            input: [
                                {
                                    key: 'email.mailbuilder.footer.menu_5',
                                    type: 'text'
                                },
                                {
                                    key: 'email.mailbuilder.footer.menu_5_url',
                                    type: 'link'
                                }
                            ]
                        },
                        {
                            title: 'global.link6',
                            class: 'footer-link',
                            input: [
                                {
                                    key: 'email.mailbuilder.footer.menu_6',
                                    type: 'text'
                                },
                                {
                                    key: 'email.mailbuilder.footer.menu_6_url',
                                    type: 'link'
                                }
                            ]
                        },
                    ]
                }
            ]
        }
    },
    computed: {
        ...mapState(['client', 'clientLocales', 'emailsList', 'brandEmailsPreview', 'emailsParameters', 'emailByKey']),
        ...mapGetters(['roles']),
        id() {
            return this.$route.params.id
        },
        defaultLocale() {
            return this.clientLocales.find(locale => locale.isDefault === true)?.code ? this.clientLocales.find(locale => locale.isDefault === true)?.code : 'fr_FR';
        },
        avaibleLocales() {
            return this.clientLocales.map(locale => {
                return {
                    label: this.$t('locales.' + locale.code.replace(/_.*/, '')) || locale.code.replace(/_.*/, ''),
                    id: locale.code
                }
            })
        },
        emailsListHasUnpublished() {
            return this.emailsList.some(email => email.state !== 'published');
        },
        activeFontWeights() {
            const activeFont = this.googleFonts.items.find(font => font.id === this.emailsParameters?.mailbuilder_template_font_family);
            return activeFont ? activeFont.variants : [];
        }
    },
    methods: {
        ...mapActions(['getClientLocales', 'getEmailParameters', 'getBrandEmailsPreview', 'getEmailsList', 'getEmailByKey', 'putEmailByKey', 'putEmailParameters', 'postEmailImage', 'getEmailsModificationsPublish']),
        updatePreview() {
            this.getBrandEmailsPreview({idClient: this.id, locale: this.langueSelected, mailKey: this.mailKey})
                    .then(() => this.previewIndex++)
        },
        handleParamChange(e) {
            if(this.emailsParameters[e.mailKey] !== e.content && e.content !== "") {
                this.putEmailParameters({idClient: this.id, key: e.mailKey,  data: {content: e.content}})
                    .then(() => this.updatePreview());
            }
        },
        handleImageChange(e){
            this.postEmailImage({idClient: this.id, image: e.content, key: e.key})
                .then(() => {
                    this.updatePreview();
                    setTimeout(() => {
                        this.updatePreview();
                    }, 1000); // Sécurité car l'api ne renvoie pas immédiatement l'html mis à jour
                });
        },
        handleFooterChange(e){
            if(this.emailByKey[e.mailKey] !== e.content) {
                this.putEmailByKey({idClient: this.id, locale: this.langueSelected, wordingKey: e.mailKey, data: {content: e.content}})
                    .then(() => this.updatePreview());
            }
        },
        handlePublishParameter(){
            this.getEmailsModificationsPublish({idClient: this.id, locale: this.langueSelected})
                .then(() => {
                    this.getEmailsList({idClient: this.id, locale: this.defaultLocale});
                });
        },
        formatDate(date) {
            return moment(date).format(`DD/MM/YY ${this.$t('global.at')} HH:mm`);
        },
        openTagPopin(){
            this.$refs.contentTagPopin.open();
        },
        changeLangue(e){
            this.langueSelected = e;
            this.getEmailByKey({idClient: this.id, locale: this.langueSelected, key: 'email.mailbuilder.footer'})
            this.updatePreview();
        }
    },
    mounted() {
        this.langueSelected = this.defaultLocale;
        this.getEmailParameters({idClient: this.id});
        this.getEmailByKey({idClient: this.id, locale: this.langueSelected, key: 'email.mailbuilder.footer'});
        this.getEmailsList({idClient: this.id, locale: this.defaultLocale})
                .then((list) => {
                    if (!list.find(x => x.mailKey === this.mailKey)) this.mailKey = 'email.trade-in.exchange_accepted';
                    if (!list.find(x => x.mailKey === this.mailKey)) this.mailKey = list[0].mailKey;
                    this.updatePreview();
                })
    },
    async created() {
        if(this.$route.params.id) {
            await this.getClientLocales(this.$route.params.id);
        }
    },
}
</script>

<style lang="scss">
.mailbuilder-parameters{
    .page-header {
        &__actions {
            .field-select__label{
                background-color: var(--color-bg-light);
            }

            @media screen and (max-width: 500px){
                margin-left: 0px;
                margin-top: 20px;
            }
        }

        @media screen and (max-width: 500px){
            flex-wrap: wrap;
        }
    }

    &__container{
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 50px;
        justify-content: space-between;
        align-items: flex-start;

        @media screen and (max-width: 1024px){
            flex-wrap: wrap;
        }

        &__wrapper{
            display: flex;
            flex-direction: column;
            gap: 24px;
            flex-grow: 1;
            width: 100%;
        }

        &__content{
            background-color: white;
            border: var(--border-2);
            border-radius: var(--border-radius-2);
            flex-grow: 1;
            width: 100%;

            &--title{
                border-bottom: var(--border-2);
                padding: var(--field-padding);
                background-color: var(--color-bg-light);
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                border-top-left-radius: var(--border-radius-2);
                border-top-right-radius: var(--border-radius-2);

                p{
                    font-size: 1.5rem;
                    font-weight: 500;
                }

                .tooltip {
                    cursor: pointer;
                    padding: 0px 4px;
                    border: 1px solid var(--color-text-grey);
                    margin-left: 5px;
                    border-radius: 50px;
                    font-size: 1rem;
                    color: var(--color-text-grey);
                }

                .field-select{
                    width: 15%;
                    
                    span{
                        background-color: var(--color-bg-light) !important;
                    }

                    @media screen and (max-width: 500px) {
                        width: 100%;
                    }
                }
            }

            &--text{
                padding: var(--field-padding);
                display: flex;
                flex-direction: column;

                p{
                    margin-bottom: calc(var(--field-padding) / 2);
                }

                .field__file{
                    border: dotted 1px var(--color-text-grey);

                    span{
                        font-size: 1.4rem;
                        font-weight: 600;
                        width: 50%;
                        text-align: center;
                        white-space: wrap;
                        margin-bottom: 10px;

                        @media screen and (max-width: 500px) {
                            width: 100%;
                        }
                    }
                }

                .field__input{
                    &--number::before{
                        content: "px";
                        position: absolute;
                        right: 40px;
                        top: 50%;
                        transform: translateY(-50%);
                        display: block;
                        color: var(--color-text-grey);
                    }
                }
            }

            &--html-container{
                margin: var(--field-padding) 0;
            }

            &--select-container{
                display: flex;
                flex-direction: row;
                gap: 24px;
                margin-top: 25px;
                margin-bottom: 25px;

                @media screen and (max-width: 500px){
                    flex-wrap: wrap;
                }

                .text{
                    width: 15%;

                    @media screen and (max-width: 500px) {
                        width: 100%;
                    }
                }

                .select{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                }

                &.typographie{
                    .select{
                        width: 100%;
                    }
                }

                &.titre{
                    .select{
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        gap: 24px;

                        .field, .field-select {
                            width: 30%;
                            margin: 0;

                            @media screen and (max-width: 500px) {
                                width: 100%;
                            }
                        }
                    }
                }

                &.paragraphe{
                    .select{
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        gap: 24px;

                        .field, .field-select {
                            width: 30%;
                            margin: 0;

                            @media screen and (max-width: 500px) {
                                width: 100%;
                            }
                        }
                    }
                }

                &.bouton{
                    .select{
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        gap: 24px;

                        .field, .field-select {
                            width: 30%;
                            margin: 0;

                            @media screen and (max-width: 500px) {
                                width: 100%;
                            }
                        }
                    }
                }

                &.footer {
                    .select{
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        gap: 24px;

                        .field, .field-select {
                            width: 30%;
                            margin: 0;

                            @media screen and (max-width: 500px) {
                                width: 100%;
                            }
                        }
                    }

                    &-signature{
                        .select{
                            width: 100%;

                            .field, .field-select {
                                width: 100%;
                                margin: 0;
                            }

                            .markdown-editor{
                                width: 100%;
                            }
                        }
                    }

                    &-link{
                        .select{
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            gap: 24px;

                            .field, .field-select {
                                width: calc(50% - 12px);
                                margin: 0;
                            }
                        }
                    }
                
                }

                .field__input{
                    border-radius: 10px;
                }
            }
        }

        &__preview{
            background-color: white;
            border: var(--border-2);
            border-radius: var(--border-radius-2);
            position: sticky;
            top: var(--field-padding);
            flex-shrink: 1;
            min-width: 450px;
            height: calc(100vh - var(--field-padding) * 2);
            overflow: hidden;

            @media screen and (max-width: 500px) {
                min-width: 275px;
                max-width: 275px;
                height: calc(75vh - var(--field-padding) * 2);
            }

            iframe{
                width: 600px;
                height: calc((100vh - var(--field-padding) * 2) * 1.336);
                border: none;
                transform: scale(0.746);
                transform-origin: top left;

                @media screen and (max-width: 500px) {
                    transform: scale(0.46);
                    height: calc((100vh - var(--field-padding) * 2) * 1.6);
                }
            }
        }
    }

    .popin-email-variable{
        .title{
            margin: 50px 0px;
        }

        .table-sticky{
            width: 90%;
            margin: 0 auto;
            text-align: left;
        }
    }
}
</style>

