<template>

    <TemplateConfirmation
            v-if="isConfirm"
            :title="$t('password.title')"
            :text="$t('password.success')"
            :button="$t('password.back')"
            :to="{name: 'home'}"/>

    <div v-else>
        <Form :center="true"
              :title="$t('password.title')"
              :text="$t('password.subtitle')"
              :fields="fields"
              :action="forgotPassword"
              @success="isConfirm = true">

            <LinkUnderline :to="{name: 'home'}">{{ $t('password.back') }}</LinkUnderline>

        </Form>
    </div>

</template>

<script>
import Form from '@/components/ui/form/Form';
import LinkUnderline from '@/components/ui/link/LinkUnderline';
import { mapActions } from 'vuex';
import TemplateConfirmation from '@/components/templates/TemplateConfirmation';

export default {
    name: 'PasswordForgot',
    components: {TemplateConfirmation, LinkUnderline, Form},
    data() {
        return {
            isConfirm: false,
            fields: [
                {name: 'email', type: 'email', required: true},
            ]
        }
    },
    methods: {
        ...mapActions(['forgotPassword'])
    }
}
</script>

