import API from '@/api/api-admin';
import listRoutes from '@/api/list-routes';

const state = {
    order: null,
    orderLoading: false,
    orderRefunded: null,
    orderRefundedLoading: false,
}

const mutations = {
    SET_ORDER(state, { key, value }) {
        state[key] = value;
    },
    SET_LOADING(state, { key, value }) {
        state[key] = value;
    }
}

const performApiCall = async (commit, route, dataKey, loadingKey, id) => {
    commit('SET_ORDER', { key: dataKey, value: null });
    commit('SET_LOADING', { key: loadingKey, value: true });
    try {
        console.log('route', route, id);
        const res = await API.get(route.replace(':id', id));
        console.log('res', res);
        if (!res.data || !res.data.id) throw new Error('Invalid response');
        commit('SET_ORDER', { key: dataKey, value: res.data });
    } finally {
        commit('SET_LOADING', { key: loadingKey, value: false });
    }
}

const actions = {
    async getOrder({ commit }, shopifyId) {
        await performApiCall(commit, listRoutes.order, 'order', 'orderLoading', shopifyId);
    },
    async getRecomOrder({ commit }, shopifyId) {
        await performApiCall(commit, listRoutes.recomOrder, 'order', 'orderLoading', shopifyId);
    },
    async getOrderRefunded({ commit }, shopifyId) {
        console.log('get', shopifyId);
        await performApiCall(commit, listRoutes.orderRefunded, 'orderRefunded', 'orderRefundedLoading', shopifyId);
    }
}

const getters = {

}

const orders = {
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default orders