<template>
    <div class="mailbuilder-listing">
        <PageBack/>
        <PageHeader v-if="client" :title="client?.name" :text="$t('mailbuilder.warning')">
            <template v-slot:actions>
                <ButtonLink size="m" color="black" :icon="'edit'" :to="{name: 'client-new-recommerce-emails-parameters', params: {id: id}}">{{ $t('mailbuilder.cta') }}</ButtonLink>
            </template>
        </PageHeader>
        <PageHeader :title="$t('mailbuilder.title')" title-size="m" class="page-header-mailbuilder">
            <template v-slot:actions>
                <FieldSelect
                :multiple="false"
                :border="true"
                :selected="langueSelected"
                :options="avaibleLocales"
                :label="'Langue'"
                @input="e => changeLangue(e)" />
            </template>
        </PageHeader>

        <template v-if="Object.keys(emailsByCategory).length > 0" >
            <div v-for="(value, key) in emailsByCategory" :key="key">
                <div class="email-tab" @click="openCurrentList(key)" :class="selectedTab.includes(value[0].category) ? 'is-open' : ''">
                    <TextSimple>{{ $t(`mailbuilder.tab.${key}`) }} <span>{{ value.length }} {{ $t(`global.email${value.length > 1 ? 's' : ''}`) }}</span></TextSimple>
                    <SvgIcon name="chevron"/>
                </div>
                <TableSticky v-if="selectedTab.includes(value[0].category)" :fields="fields" :data="value" class="email-list" />
            </div>
        </template>
        <Popin ref="popinEmailSendTest" class="popin-email-send-test" :center="true" :title="$t('client.emails.send_test_popin_title')" :subtitle="$t('client.emails.send_test_popin_texte')">
            <template v-slot:content>
                <div v-if="!brandEmailsSendTestEmailMessage">
                    <Field
                        v-for="(field, index) in emailTestfields"
                        :key="index"
                        type="text"
                        :placeholder="$t(field.placeholder)"
                        :required="field.required"
                        :model="field.model"
                        @change="e => field.model = e"
                    />
                    <ButtonCustom color="black" size="m" @click.native.prevent="sendEmailTest(getEmailAddresses())">{{ $t('client.emails.cta_sendtest') }}</ButtonCustom>
                </div>
                <div v-else>
                    <TextSimple class="confirmation-text"><SvgIcon name="check"/><br>{{ brandEmailsSendTestEmailMessage }}</TextSimple>
                </div>
            </template>
        </Popin>
        <Popin
            ref="popinEmailPreview"
            :center="true"
            :fullScreen="false"
            class="popin-email-preview">
            <template v-slot:content>
                <iframe :srcdoc="brandEmailsPreview?.html"></iframe>
            </template>
        </Popin>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import PageBack from '@/components/ui/page/PageBack';
import TableSticky from '@/components/ui/table-sticky/TableSticky';
import SvgIcon from '@/components/ui/svg/SvgIcon';
import PageHeader from '@/components/ui/page/PageHeader';
import FieldSelect from '@/components/ui/form/fields/FieldSelect';
import Field from '@/components/ui/form/fields/Field';
import TextSimple from '@/components/ui/text/TextSimple';
import ButtonLink from '@/components/ui/button/ButtonLink';
import Popin from '@/components/ui/popin/Popin';
import ButtonCustom from '@/components/ui/button/Button';

export default {
    name: 'ClientMailbuilderEmails',
    components: {TableSticky, SvgIcon, TextSimple, PageHeader, FieldSelect, ButtonLink, Popin, ButtonCustom, Field, PageBack},
    data() {
        return {
            loading: true,
            langueSelected: null,
            currentMailKeyOpened: null,
            emailTestfields: [
                { placeholder: 'client.emails.send_test_placeholder_mail1', required: true, model: null },
                { placeholder: 'client.emails.send_test_placeholder_mail2', required: false, model: null },
                { placeholder: 'client.emails.send_test_placeholder_mail2', required: false, model: null },
            ],
            selectedTab: [],
            fields: [
                { key: 'active', type: 'switcher', handler: (payload) => (this.toogleActiveMail(payload)), size: "s"},
                { key: 'title', type: 'routerLink', size: "xl", handler: (payload) => (this.$router.push({name: 'client-new-recommerce-emails-edit', params: {id: this.$route.params.id, mailKey: payload.key}}))  },
                { key: 'description', size: "xxl"},
                { key: 'trigger', size: "xxl"},
                { key: 'template', type: 'tagType', size: "s"},
                { key: 'mailKey', type: 'actions', size: "xs", translationKey: 'empty', actions: 
                    { 
                        title: 'Options', 
                        options: [
                            {title: this.$t('mailbuilder.list.sendTest'), icon: "email", handler: (payload) => (this.openEmailSendTest(payload))},
                            {title: this.$t('mailbuilder.list.previewMail'), icon: "eye", handler: (payload) => (this.openEmailPreview(payload))}, 
                            {title: this.$t('mailbuilder.list.editMail'), icon: "edit", handler: (payload) => (this.$router.push({name: 'client-new-recommerce-emails-edit', params: {id: this.$route.params.id, mailKey: payload.key}}))},
                        ]
                    }
                }
            ],
        }
    },
    computed: {
        ...mapState(['client', 'clientLocales', 'emailsList', 'brandEmailsPreview', 'brandEmailsSendTestEmailMessage']),
        ...mapGetters(['roles', 'emailsByCategory']),
        id() {
            return this.$route.params.id
        },
        defaultLocale() {
            return this.clientLocales.find(locale => locale.isDefault === true)?.code ? this.clientLocales.find(locale => locale.isDefault === true)?.code : 'fr_FR';
        },
        avaibleLocales() {
            return this.clientLocales.map(locale => {
                return {
                    label: this.$t('locales.' + locale.code.replace(/_.*/, '')) || locale.code.replace(/_.*/, ''),
                    id: locale.code
                }
            })
        },
    },
    methods: {
        ...mapActions(['getClient', 'getClientLocales', 'getEmailsList', 'getBrandEmailsPreview', 'postBrandEmailsSendTest']),
        getMailsTranslation() {
            this.getEmailsList({idClient: this.id, locale: this.defaultLocale});
        },
        openCurrentList(key) {
            let index = this.selectedTab.indexOf(key);
            if (index !== -1) {
                this.selectedTab.splice(index, 1);
            } else {
                this.selectedTab.push(key);
            }
        },
        openEmailSendTest(data){
            this.currentMailKeyOpened = data.key;
            this.$refs.popinEmailSendTest.open();
        },
        getEmailAddresses() {
            const addressesMail = this.emailTestfields
                .filter(field => field.model)
                .map(field => field.model);

            return addressesMail.length > 0 ? addressesMail : null;
        },
        async sendEmailTest(data) {
            await this.postBrandEmailsSendTest({idClient: this.id, locale: this.langueSelected, mailKey: this.currentMailKeyOpened, adressEmail: data});
            setTimeout(() => {
                this.$refs.popinEmailSendTest.close();
                this.$store.commit('SET_EMAILS_SEND_TEST_MESSAGE', null);
            }, 3000);
        },
        async openEmailPreview(data) {
            await this.getBrandEmailsPreview({idClient: this.id, locale: this.langueSelected, mailKey: data.key});
            this.$refs.popinEmailPreview.open();
        },
        toogleActiveMail(data) {
            console.log('toogleActiveMail', data);
        },
        changeLangue(e) {
            this.langueSelected = e;
            this.getEmailsList({idClient: this.id, locale: this.langueSelected});
        }
    },
    mounted() {
        this.getMailsTranslation();
        this.langueSelected = this.defaultLocale;
    },
    watch: {
        emailsList(){
            this.loading = false
        }
    },
    async created() {
        if(this.$route.params.id) {
            await this.getClient(this.$route.params.id);
            await this.getClientLocales(this.$route.params.id);
        }
    },
}
</script>

<style lang="scss">
.mailbuilder-listing{

    .page-header {
        &__actions {
            .field-select__label{
                background-color: var(--color-bg-light);
            }

            @media screen and (max-width: 500px){
                margin-left: 0px;
                margin-top: 20px;
            }
        }

        @media screen and (max-width: 500px){
            flex-wrap: wrap;
        }

        &.page-header-mailbuilder {
            margin-bottom: 20px;
        }
    }

    .email-tab{
        width: 100%;
        top: 0px;
        background: var(--color-bg-light);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        z-index: 1;
        margin-bottom: 24px;
        border-bottom: var(--border-2);
        cursor: pointer;
        padding-bottom: 24px;

        p{
            font-size: 1.6rem;
            font-weight: 600;

            span {
                font-size: 1.2rem;
                font-weight: 300;
                color: var(--color-text-grey);
                margin-left: 10px;
            }
        }

        .icon {
            transform: rotate(-90deg);
        }

        &.is-open {
            .icon {
                transform: rotate(0deg);
            }
        }
    }

    .email-list {
        .table-sticky__scrollable:nth-child(1) {
            position: relative;
            top: 0;
        }

        .table-sticky-row{
            height: 65px;
            text-align: left;
        }
    }

    .popin__inner {
        iframe{
            background-color: white;
            width: 600px;
            height: calc((100vh - var(--field-padding) * 2) * 1.245);
            border: none;
            transform: scale(0.746) translateX(100px);
            transform-origin: top left;
        }
    }
}
</style>

