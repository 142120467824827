<template>
    <TemplateGoldenmetrics
            :title="$t('menu.stock')"
            :categories="categories"
            :route="$listRoutes.dashboardFinances"
            :is-period="false"
    />
</template>

<script>
import TemplateGoldenmetrics from '@/components/templates/TemplateGoldenmetrics';

export default {
    name: 'DashboardStock',
    components: {TemplateGoldenmetrics},
    data() {
        return {
            categories: [
                { name: 'financialStockBrand', icon: 'tshirt' },
                { name: 'physicalStockBrand', icon: 'tshirt-check' },
                { name: 'financialStockBrandValue', format: 'money', icon: 'money' },
                { name: 'financialStockCustomer', icon: 'tshirt' },
                { name: 'financialStockStore', icon: 'store' },
                { name: 'physicalStockStore', icon: 'tshirt' },
                { name: 'financialStockStoreValue', format: 'money', icon: 'store-border' },
                { name: 'financialStock', icon: 'tshirt' },
                { name: 'financialStockCustomerValue', format: 'money', icon: 'money' },
                { name: 'financialStockValue', format: 'money', icon: 'tshirt' },
                { name: 'physicalStockCustomer', icon: 'tshirt' },
                { name: 'physicalStock', icon: 'tshirt-check' },
                { name: 'physicalStockValue', format: 'money', icon: 'money' }
            ]
        }
    }
}
</script>