<template>
    <TemplateTable
        :title="$t('menu.warehouse-articles')"
        :text="$t('warehouse_articles.description')"
        name="warehouseArticles"
        :url="$listRoutes.warehouseArticles"
        :table="warehouseArticles"
        :filters="warehouseArticlesFilters"
        :fields="fields"
    />
</template>

<script>
import { mapState } from 'vuex';
import TemplateTable from '@/components/templates/TemplateTable'
export default {
    name: 'WarehouseArticlesPage',
    components: {TemplateTable},
    data() {
        return {
            fields: [
                {key: 'id', type: 'eye', route: 'resale', size: 'xs'},
                {key: 'shopifyId', type: 'shopifyProduct', size: 'm'},
                {key: 'client', type: 'client', size: 'l', noSortable: true},
                {key: 'user', type: 'userEmail', size: 'xl'},
                {key: 'createdAt', type: 'date'},
                {key: 'logisticsInformations.warehouseOpenDate', type: 'date', translationKey: "warehouseOpenDateWarehouse", size: 'm'},
                {key: 'logisticsInformations.warehouseValidationDate', type: 'date', translationKey: 'warehouseReviewDateWarehouse', size: 'm'},
                {key: 'logisticsInformations.warehouseShootingDate', type: 'date', translationKey: 'warehouseShootingDateWarehouse', size: 'm'},
                {key: 'shopifyPublishedDate', type: 'date'},
                {key: 'logisticsInformations.isReusePackShot', type: 'boolean', size: 's'},
                {key: 'logisticsInformations.frontPhotoCoreSentAt', type: 'date', size: 'm'},
                {key: 'logisticsInformations.frontPhotoTreatedReceivedAt', type: 'date', size: 'l'},
                {key: 'logisticsInformations.backPhotoCoreSentAt', type: 'date', size: 'm'},
                {key: 'logisticsInformations.backPhotoTreatedReceivedAt', type: 'date', size: 'l'},
                {key: 'inStock', type: 'boolean'},
                {key: 'shopifyPublished', type: 'boolean', size: 's'},
                {key: 'irl', type: 'boolean'},
                {key: 'stage', type: 'tagType', size: 's'},
                {key: 'controlReview.countPhotos', size: 's'},
                {key: 'automerchedAt', size: 'm', type: 'date', translationKey: 'lastUpdate'},
            ]
        }
    },
    computed: {
        ...mapState(['warehouseArticles', 'warehouseArticlesFilters'])
    }
}
</script>