<template>
    <li class="chart-bar-row">
        <TextSimple>{{ text }}</TextSimple>
        <ChartBar :value1="value1" :value2="value2" :max="max">
            <TextTitle size="xs" tag="h3">{{ category }}</TextTitle>
            <TextSimple>{{ legend1 }} : {{ value1 | goldenMetrics(format) }}</TextSimple>
            <TextSimple v-if="value2 !== null">{{ legend2 }} : {{ value2 | goldenMetrics(format) }}</TextSimple>
        </ChartBar>
        <TagStatistic v-if="statistic !== null" :value="statistic" :color="statisticColor"/>
    </li>
</template>
<script>
import TextSimple from '@/components/ui/text/TextSimple';
import ChartBar from '@/components/ui/chart/ChartBar';
import TagStatistic from '@/components/ui/tag/TagStatistic';
import TextTitle from '@/components/ui/text/TextTitle';
export default {
    components: {TextTitle, TagStatistic, ChartBar, TextSimple},
    props: {
        text: String,
        legend1: String,
        legend2: String,
        value1: [Number, String],
        value2: [Number, String],
        max: Number,
        format: [Number, String],
        statistic: Number,
        statisticColor: String,
        category: String
    }
}
</script>

<style lang="scss">
.chart-bar-row {
    display: flex;
    align-items: center;
    padding: 15px 30px;
    @media screen and (max-width: 550px) {
        flex-wrap: wrap;
        padding: 10px 15px;
    }

    & > .text {
        width: 150px;

        @media screen and (max-width: 550px) {
            width: 100%;
        }
    }

    & > div {
        flex-grow: 1;
        margin: 0 30px;

        @media screen and (max-width: 800px) {
            margin: 0 10px;
        }

        @media screen and (max-width: 550px) {
            margin-left: 0;
        }
    }

    &:nth-of-type(7n + 1) .chart-bar {
        --color: var(--color-blue)
    }
    &:nth-of-type(7n + 2) .chart-bar {
        --color: var(--color-purple)
    }
    &:nth-of-type(7n + 3) .chart-bar {
        --color: var(--color-orange)
    }
    &:nth-of-type(7n + 4) .chart-bar {
        --color: var(--color-blue2)
    }
    &:nth-of-type(7n + 5) .chart-bar {
        --color: var(--color-yellow)
    }
    &:nth-of-type(7n + 6) .chart-bar {
        --color: var(--color-green2)
    }
    &:nth-of-type(7n + 7) .chart-bar {
        --color: var(--color-red)
    }
}
</style>