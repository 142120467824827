import admins from '@/store/modules/admins';
import articles from '@/store/modules/articles';
import catalogue from '@/store/modules/catalogue';
import clients from '@/store/modules/clients';
import cohorts from '@/store/modules/cohorts';
import collections from '@/store/modules/collections';
import countries from '@/store/modules/countries';
import customers from '@/store/modules/customers';
import dashboard from '@/store/modules/dashboard';
import faumeMailbuilder from '@/store/modules/faumeMailbuilder';
import filters from '@/store/modules/filters';
import funnelDashboard from '@/store/modules/funnel-dashboard';
import goldenmetrics from '@/store/modules/goldenmetrics';
import invoices from '@/store/modules/invoices';
import languages from '@/store/modules/languages';
import mailbuilder from '@/store/modules/mailbuilder';
import markets from '@/store/modules/markets';
import menu from '@/store/modules/menu';
import merchandising from '@/store/modules/merchandising';
import monitorings from '@/store/modules/monitorings';
import notifications from '@/store/modules/notification';
import orders from '@/store/modules/orders';
import productsMerchandising from '@/store/modules/products-merchandising';
import resales from '@/store/modules/resales';
import retails from '@/store/modules/retails';
import returns from '@/store/modules/returns';
import stores from '@/store/modules/stores';
import tables from '@/store/modules/tables';
import translations from '@/store/modules/translations';
import user from '@/store/modules/user';
import vouchers from '@/store/modules/vouchers';
import wallets from '@/store/modules/wallets';
import walletTransactions from '@/store/modules/walletTransactions';
import warehouse from '@/store/modules/warehouse';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

let state = {};
let mutations = {};
let actions = {};
let getters = {};

const list = [
    user,
    tables,
    monitorings,
    clients,
    translations,
    goldenmetrics,
    resales,
    warehouse,
    admins,
    vouchers,
    customers,
    wallets,
    walletTransactions,
    returns,
    orders,
    menu,
    filters,
    stores,
    retails,
    countries,
    catalogue,
    collections,
    productsMerchandising,
    cohorts,
    mailbuilder,
    faumeMailbuilder,
    funnelDashboard,
    merchandising,
    markets,
    languages,
    invoices,
    notifications,
    dashboard,
    articles
];

list.forEach(item => {
    state = {...state, ...item.state};
    mutations = {...mutations, ...item.mutations};
    actions = {...actions, ...item.actions};
    getters = {...getters, ...item.getters};
})


export default new Vuex.Store({
    state,
    mutations,
    actions,
    getters
});