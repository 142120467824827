<template>
    <TemplateDetails :data="catalogues" :loading="!loading" :get-function="getCatalogues">
        
        <div>
            <PageBack/>
            <PageHeader :title="title">
                <template v-slot:actions>
                    <!-- <Button icon="cross" size="m" color="grey" @click.native.prevent="$refs.popinValidationCancel.open">{{$t('global.cancel')}}</Button> -->
                    <!-- <Button :class="errors ? 'disabled' : ''" size="m" color="blue" @click.native.prevent="$refs.aboutToLaunchCatalogue.open">{{$t('catalogue.validate_and_update')}}</Button> -->
                    <Button icon="publish" v-if="currentCatalogue && currentCatalogue.configuration && currentCatalogue.status.validation.isValidated && currentCatalogue.status.generation.isGenerated" color="blue" size="m" @click.native.prevent="$refs.launchThisCatalogue.open">{{ $t('catalogue.publish') }}</Button>
                </template>
            </PageHeader>
            <GridContainer>
                <InPopinLoader
                    v-if="!loading"
                    :loading="!loading"
                    :msg="$t('catalogue.inPopinLoading')"
                 />
                <ImportStatus 
                    v-if="loading"
                    :missingDatas="0" 
                    :statusLines="statusLines"  
                />
                
                <Checkerboard>
                    <CheckerboardItem 
                        v-for="(item,keyName) in getCatalogueValidationErrors" 
                        :key="keyName"
                        :title="$t(`catalogue.${keyName}`)"
                        :subtitle="($t(getCatalogueValidationErrors[keyName].length)).toString()"
                        :info="$t(`catalogue.${keyName}_infos`)"
                        >
                        <ButtonLink size="s" icon="right" color="grey" to="#">{{$t('website.see')}}</ButtonLink>
                    </CheckerboardItem>
                </Checkerboard>

            </GridContainer>
            <div v-if="loading">
                <GridHeader>
                    <Button size="s" color="grey" @click.native.prevent="loadNewSample">Recharger un échantillon</Button>
                </GridHeader>
                
                <TableSticky 
                    v-if="fields && fields?.length && datas && datas?.length"
                    :fields="fields" 
                    :data="datas" 
                />
            </div>

        </div>
        
       
        <Popin :title="$t('catalogue.popin_launch_cancel')" ref="popinValidationCancel">
            <template v-slot:bottom>
                <Button @click.native.prevent="$refs.popinValidationCancel.close" color="grey" size="m">{{ $t('catalogue.popin_launch_cancel_confirm') }}</Button>
                <ButtonLink color="green" size="m" :to="{name:'config-website-catalogues-update'}">{{ $t('catalogue.popin_launch_cancel_abort') }}</ButtonLink>
            </template>
        </Popin>

        <Popin :title="$t('catalogue.about_to_launch')" ref="aboutToLaunchCatalogue">
            <template v-slot:bottom>
                <Button @click.native.prevent="$refs.aboutToLaunchCatalogue.close" color="red" size="m">{{ $t('global.cancel') }}</Button>
                <Button color="green" size="m" @click.native.prevent="generateThisCatalogue">{{ $t('catalogue.validation') }}</Button>
            </template>
        </Popin>

        <Popin :title="$t('catalogue.publish')" ref="launchThisCatalogue">
            <template v-slot:content>
                <div v-if="!validationSuccess" class="popin__content--row">
                    <Button @click.native.prevent="$refs.launchThisCatalogue.close" color="red" size="m">{{ $t('global.cancel') }}</Button>
                    <Button color="green" size="m" @click.native.prevent="launchThisCatalogue">{{ $t('catalogue.publish') }}</Button>
                </div>
                <div v-if="validationSuccess" class="popin__content--catalogues">
                    <TextTitle v-if="title" tag="h2" size="xs">{{ $t('catalogue.publish_success') }}</TextTitle>
                    <div class="popin__content--row">
                        <ButtonLink color="grey" size="m" :to="{name:'config-website-catalogues-update'}">{{ $t('global.back') }}</ButtonLink>
                    </div>
                </div>
            </template>
        </Popin>

      <Popin :title="getCatalogueProgressStateValues !== '100%' ? $t('catalogue.generation') : $t('catalogue.generation_end')" ref="popinProgression">
        <template v-slot:content>
          <div v-if="catalogueProgress && !validationSuccess">
            <ProgressBar 
              v-if="showProgressBar" 
              :start_value="catalogueProgress.totalLinesProcessed" 
              :start_text="$t('catalogue.totalProductsProcessed')" 
              :total_value="catalogueProgress.totalLines" 
              :total_text="$t('catalogue.totalProducts')" 
              :progression="getCatalogueProgressStateValues" 
            />
          </div>
          <div v-if="getCatalogueProgressStateValues === '100%' && !validationSuccess" class="popin__content--catalogues">
            <TextTitle v-if="title" tag="h2" size="xs">{{ popinValidationTitle }}</TextTitle>
            <div class="popin__content--row">
                <ButtonLink color="red" size="m" :to="{name:'config-website-catalogues-update'}">{{ $t('global.back') }}</ButtonLink>
                <Button color="green" size="m" @click.native.prevent="launchThisCatalogue">{{ $t('catalogue.publish') }}</Button>
            </div>
          </div>
          <div v-if="validationSuccess" class="popin__content--catalogues">
            <TextTitle v-if="title" tag="h2" size="xs">{{ $t('catalogue.publish_success') }}</TextTitle>
            <div class="popin__content--row">
                <ButtonLink color="grey" size="m" :to="{name:'config-website-catalogues-update'}">{{ $t('global.back') }}</ButtonLink>
            </div>
          </div>
        </template>
      </Popin>
    </TemplateDetails>
</template>
<script>
import TemplateDetails from '@/components/templates/TemplateDetails.vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import PageHeader from '@/components/ui/page/PageHeader';
import Button from '@/components/ui/button/Button';
import ButtonLink from '@/components/ui/button/ButtonLink';
import Popin from '@/components/ui/popin/Popin';
import PageBack from '@/components/ui/page/PageBack';
import GridContainer from '@/components/ui/grid/GridContainer';
import GridHeader from '@/components/ui/grid/GridHeader';
import ImportStatus from '@/components/ui/status/ImportStatus.vue';
import Checkerboard from '@/components/ui/checkerboard/Checkerboard';
import CheckerboardItem from '@/components/ui/checkerboard/CheckerboardItem';
import TableSticky from '@/components/ui/table-sticky/TableSticky.vue';
import ProgressBar from '@/components/ui/progress/ProgressBar';
import TextTitle from '@/components/ui/text/TextTitle';
import InPopinLoader from '@/components/ui/popin/InPopinLoader.vue';

export default {
    name: 'ConfigWebsiteCohortsValidation',
    components: {
        TemplateDetails,
        GridContainer,
        GridHeader,
        PageBack,
        ButtonLink,
        Button,
        Popin,
        PageHeader,
        ImportStatus,
        Checkerboard,
        CheckerboardItem,
        TableSticky,
        ProgressBar,
        TextTitle,
        InPopinLoader
    },
    props: {
        catalogueID: {
            type: String,
        },
    },
    data() {
        return {
            deleteLoading: false,
            show:true,
            showProgressBar:false,
            validationSuccess:false
        }
    },
    watch: {
        currentCatalogue(newValue) {
            if(newValue?.status?.generation?.isGenerated) this.fetchCohortsSample(this.catalogueID)
        }
    },
    computed: {
        ...mapState([
            'catalogueProgress',
            'cohortsSample',
            'cohorts',
            'catalogues',
            'cataloguesLoading'
        ]),
        ...mapGetters([
            'getCatalogueValidationErrors',
            'getCurrentCatalogue',
            'getCatalogueMappingState',
            'getCatalogueProgressStateValues',
            
            // 'getCohortsSampleStateValue'
        ]),
        id() {
            return this.$route.params.id
        },
        lang() {
            return this.$route.params.lang
        },
        client() {
            return this.$store.getters.getClientById(this.id)
        },
        title(){
            return this.currentCatalogue ? `${this.$t('cohorts.verify_pricing')} : ${this.currentCatalogue.title}` : ''
        },
        currentCatalogue(){
            const getCurrentCatalogue = this.getCurrentCatalogue;
            return getCurrentCatalogue 
                ? getCurrentCatalogue(this.lang)(this.catalogueID)
                : null;
        },
        statusLines(){
            // return []
            return this.cohortsSample
                ? [
                    // {
                    //     traduction:`${this.$t('cohorts.created_cohorts')}`,
                    //     // value: this.cohorts ? this.cohorts.length : null
                    // },
                    {
                        traduction:`${this.$t('cohorts.ean_in_cohort')}`,
                        value:this.cohortsSample.totalArticleWithPrice
                    },
                    {
                        traduction:`${this.$t('cohorts.ean_without_cohort')}`,
                        value:this.cohortsSample.totalArticleWithoutPrice
                    },
                ]
                : []
        },
        fields(){
            return this.cohortsSample 
                ?
                Object.keys(this.cohortsSample?.randomArticleWithPrice[0].article).map(key => {
                    return {
                        key,
                        size: 'l',
                        noTranslate: true,
                        label: key
                    }
                })
                : []
        },
        datas(){
            const datas = {items:[]}
            this.cohortsSample?.randomArticleWithPrice.forEach(elem => {
                    datas.items.push({...elem?.article})
            })
            return datas.items
        },
        errors(){
            return Object.keys(this.getCatalogueValidationErrors).length > 0
        },
        popinValidationTitle(){
            return `${this.$t('catalogue.launch')} ${this.$route.params.lang}`
        },
        loading(){
            return !!this.cohortsSample
        }

    },
    methods: {
        ...mapActions([
            'getCatalogueValidation',
            'catalogueValidationOrGeneration',
            'catalogueLaunch',
            'fetchCatalogues',
            // 'fetchCohorts',
            'fetchCohortsSample'
        ]),
        async getCatalogues(){
            await this.fetchCatalogues(this.id)
            await this.getCatalogueValidation(this.catalogueID)
            // this.fetchCohortsSample(this.catalogueID)
            // this.fetchCohorts(this.catalogueID)
        },

        generateThisCatalogue(){
            this.catalogueValidationOrGeneration({id:this.catalogueID,type:'generation',fetchAfter:false})
            .then(response => {
                console.log('generateThisCatalogue response',response)
                this.$refs.aboutToLaunchCatalogue.close()
                this.$refs.popinProgression.open()
                this.showProgressBar = true
            })
            .catch(err => {
                console.log(err)          
            });
        },
        launchThisCatalogue(){
            this.catalogueLaunch({id:this.id, catalogueID:this.catalogueID,fetchAfter:false})
                .then(response => {
                    console.log('launchThisCatalogue', response)
                })
                .catch(err => console.log(err))
                .finally(()=> this.validationSuccess = true)
        },
        loadNewSample() {
            this.$store.dispatch('fetchCohortsSample', this.catalogueID);
        },

    },
    
}
</script>
<style lang="scss" scoped>
.popin {
    &__content--catalogues {
        display: flex;
        flex-direction: column;
        padding-top: 10px;
        gap: 10px;
        align-items: stretch;
        border-top: var(--border-2);
        margin-top: 20px;
    }
    &__content--row {
        display:flex;
        align-items:center;
        justify-content:space-between;
        & > .button:is(:only-child) {
            margin-left: auto;
            margin-right: auto;
        }
        .button + .button {
            margin-top: 0;
        }
    }
}

</style>