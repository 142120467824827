<template>
    <span class="button-copy">

        <TagItem :border="true"
                 icon-right="copy"
                 :text="cut && text.length > 17 ? `${text.slice(0, 17)}...` : text"
                 color="white"
                 @click.native.prevent="copy"/>

        <input type="text" :value="text" ref="input" v-on:focus="$event.target.select()" readonly>

        <PopinNotification :text="`Vous venez de copier : ${text}`" ref="popin"/>

    </span>
</template>

<script>
import TagItem from '@/components/ui/tag/Tag';
import PopinNotification from '@/components/ui/popin/PopinNotification';

export default {
    name: 'ButtonCopy',
    components: {PopinNotification, TagItem},
    props: {
        text: [String, Number],
        cut: Boolean
    },
    methods: {
        copy() {
            this.$refs.input.focus();
            document.execCommand('copy');
            navigator.clipboard.writeText(this.text);
            this.$refs.popin.open();
        }
    }
}
</script>

<style lang="scss">
.button-copy {
    .tag {
        .icon svg {
            width: 12px;
        }
    }

    & > input {
        position: absolute;
        width: 0;
        height: 0;
        overflow: hidden;
        padding: 0;
        z-index: -9999;
        opacity: 0;
    }
}
</style>