<!-- Based on ConfigWebsite.vue -->
<template>
    <section class="config-website">
        <PageBack/>
        <PageLoader v-if="!clients"/>
        <div v-else-if="client">
            <PageHeader :title="title">
                <template v-slot:actions v-if="!isCataloguesExists">
                  <ButtonLink size="m" color="grey" :to="{name:'config-website-catalogues-update'}">{{$t('catalogue.upload_file')}}</ButtonLink>
                </template>
                <template v-slot:actions v-else>
                    <ButtonLink icon="settings" size="m" color="grey" :to="{name:'config-website-catalogues-update'}">{{$t('catalogue.update_catalogue')}}</ButtonLink>
                    <ButtonLink icon="wallet" size="m" color="grey" :to="{name:'config-website-cohorts'}">{{$t('catalogue.update_pricing')}}</ButtonLink>
                    <ButtonLink icon="tshirt" size="m" color="grey" :to="{name:'config-website-merchandising'}">{{$t('catalogue.update_merchandising')}}</ButtonLink>
                </template>
            </PageHeader>
        </div>
        

        <PageError v-else title="404" :subtitle="$t('website.not-found')" :button="$t('website.return')" :to="{name: 'config-websites'}"/>
    </section>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import PageLoader from '@/components/ui/page/PageLoader.vue';
import PageError from '@/components/ui/page/PageError.vue';
import PageHeader from '@/components/ui/page/PageHeader.vue';
import ButtonLink from '@/components/ui/button/ButtonLink.vue';
import PageBack from '@/components/ui/page/PageBack.vue';

export default {
    name: 'ConfigWebsiteCatalogue',
    components: {
        PageBack,
        ButtonLink,
        PageHeader,
        PageError,
        PageLoader, 
    },
    data() {
        return {
        }
    },
    computed: {
        ...mapState(['clients','catalogues','cataloguesLoading']),
        ...mapGetters(['isAdmin', 'isCataloguesExists']),
        id() {
            return this.$route.params.id
        },
        client() {
            return this.$store.getters.getClientById(this.id)
        },
        title(){
            return `${this.$t('catalogue.title')} ${this.client.name}`
        },
        defaultCatalogueExists(){
            return  this.catalogues 
                        ? Object.keys(this.catalogues).forEach(elem => {
                            return this.catalogues[elem]
                            })
                        : 'void'
            
        }
    },
    methods: {
        ...mapActions(['fetchCatalogues']),
        getCatalogues() {
            this.fetchCatalogues(this.id)
        },
    },
    mounted () {
        
    },
    created () {
        this.getCatalogues()  
 
    },
}
</script>