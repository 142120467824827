<template>
    <TemplateDetails :get-function="getWallet" :data="wallet" :loading="walletLoading">
        
        <PageBack/>
        
        <PageHeader :title="$t('wallet.title')">
            <template v-slot:actions>
                <ButtonExport @click.native.prevent="exportWallet"/>
                <ButtonLink size="m" color="blue2" icon="plus" :to="{name: 'wallet-add', id: $route.params.id}">{{ $t('wallet.add') }}</ButtonLink>
            </template>
        </PageHeader>
        
        <SectionTableInfos :fields="fields" :object="wallet"/>

        <TableSticky v-if="wallet?.lines?.length" :fields="linesFields" :data="wallet.lines"/>
        
    </TemplateDetails>
</template>

<script>
import TemplateDetails from '@/components/templates/TemplateDetails';
import { mapActions, mapState } from 'vuex';
import PageBack from '@/components/ui/page/PageBack';
import PageHeader from '@/components/ui/page/PageHeader';
import SectionTableInfos from '@/components/sections/SectionTableInfos';
import ButtonLink from '@/components/ui/button/ButtonLink';
import ButtonExport from '@/components/ui/button/ButtonExport';
import TableSticky from '@/components/ui/table-sticky/TableSticky';

export default {
    name: 'WalletPage',
    components: {TableSticky, ButtonExport, ButtonLink, SectionTableInfos, PageHeader, PageBack, TemplateDetails},
    data() {
        return {
            linesFields: [
                {key: 'client', type: 'client'},
                {key: 'createdAt', type: 'date'},
                {key: 'amount', type: 'formatPrice'},
                {key: 'type', type: 'tagType'},
                {key: 'description', size: 'xl'},
                {key: 'shopifyId', type: 'copy'},
                {key: 'country'},
            ],
            fields: [
                {key: 'id'},
                {key: 'client', type: 'client'},
                {key: 'user', type: 'userCard'},
                {key: 'source', type: 'tagType'},
                {key: 'walletAmount', type: 'formatPrice'},
                {key: 'credit', type: 'formatPrice'},
                {key: 'debit', type: 'formatPrice'},
                {key: 'nbCredit', force: true},
                {key: 'nbDebit', force: true},
                {key: 'token', type: 'copy'},
                {key: 'country'},
            ]
        }
    },
    computed: {
        ...mapState(['wallet', 'walletLoading'])
    },
    methods: {
        ...mapActions(['getWallet', 'exportWallet'])
    }
}
</script>