<template>
    <div>
        <GridCard>
            <GridContent>
                <GridHeader :title="$t('order.articles')"/>
                <div v-for="(item, i) in resale.items" :key="i" class="resale-item">
                    <RecomOrderCardItem 
                        :item="item" 
                        :return-item="null"
                    />
                </div>
            </GridContent>
        </GridCard>

        <GridCard v-if="resale.warehouseOperations?.length">
            <GridContent>
                <GridHeader :title="$t('global.treatment')"/>
                <div v-for="(operation, i) in resale.warehouseOperations" :key="i" class="warehouse-operation">
                    <TextSimple>{{ operation.label.includes('.') ? $t(operation.label) : operation.label }}</TextSimple>
                    <TextSimple>{{ operation.date | dateHour }}</TextSimple>
                    <TextSimple>{{ operation.operator }}</TextSimple>
                    <TextSimple>{{ operation.comment }}</TextSimple>
                </div>
            </GridContent>
        </GridCard>
    </div>
</template>

<script>
import GridCard from "@/components/ui/grid/GridCard";
import GridContent from "@/components/ui/grid/GridContent";
import GridHeader from "@/components/ui/grid/GridHeader";
import RecomOrderCardItem from '@/components/ui/order/RecomOrderCardItem';
import TextSimple from '@/components/ui/text/TextSimple';

export default {
    name: "ResaleItemsGrid",
    components: {
        GridCard,
        GridContent,
        GridHeader,
        RecomOrderCardItem,
        TextSimple
    },
    props: {
        resale: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.resale-item {
    margin-bottom: 15px;
}

.warehouse-operation {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 10px;
    border-bottom: 1px solid var(--color-border);

    &:last-child {
        border-bottom: none;
    }
}
</style>