<template>
  <Tag
    :color="colors[value] || colors.default"
    :text="$t(`article.states.${value}`)"
  />
</template>

<script>
import Tag from "@/components/ui/tag/Tag";
export default {
  name: "TagArticleState",
  components: { Tag },
  props: {
    value: String,
  },
  data() {
    return {
      colors: {
        stocked: "blue",
        published: "green",
        sold: "red",
      },
    };
  },
};
</script>
