<template>
    <TemplateEmailsManagement :title="'{{name}}'"
        ref="page"
        :subtitle="$t(emailTitle)"
        :translations="brandEmailsTranslations"
        :avaibleLocales="avaibleLocales"
        :defaultLocale="defaultLocale"
        :error-msg="$t('client.emails.error')"
        :publish-route="$listRoutes.websiteEditEmailsPublish"
        />
</template>

<script>
import { mapState, mapActions } from 'vuex';
import TemplateEmailsManagement from '@/components/templates/TemplateEmailsManagement';

export default {
    name: 'ClientEmails',
    components: { TemplateEmailsManagement },
    data() {
        return {
            emailTitles: {
                'buying': 'client.emails.buying_title',
                'trade-in': 'client.emails.tradin_title',
                'default': 'client.emails.general_title'
            },
            translations: null,
        }
    },
    computed: {
        ...mapState(['clientLocales', 'brandEmailsTranslations']),
        id() {
            return this.$route.params.id
        },
        emails_category() {
            return this.$route.query.emails_category
        },
        emailTitle() {
            return this.emailTitles[this.emails_category] || this.emailTitles.default
        },
        avaibleLocales() {
            return this.clientLocales.map(locale => {
                return {
                    label: this.$t('locales.' + locale.code.replace(/_.*/, '')) || locale.code.replace(/_.*/, ''),
                    id: locale.code
                }
            })
        },
        defaultLocale() {
            return this.clientLocales.find(locale => locale.isDefault === true)?.code ? this.clientLocales.find(locale => locale.isDefault === true)?.code : 'fr_FR';
        }
    },
    methods: {
        ...mapActions(['getBrandEmailsTranslations']),
        getMailsTranslation() {
            this.getBrandEmailsTranslations({idClient: this.id, locale: this.defaultLocale});
        }
    },
    mounted() {
        this.getMailsTranslation(this.emails_category)
    },
    watch: {
        brandEmailsTranslations() {
            this.translations = this.brandEmailsTranslations.filter(translation => translation.category === this.emails_category)
        }
    },
    beforeRouteLeave(to, from, next) {
        this.$refs.page.routeLeaving(to, from, next)
    }
}
</script>