<template>
    <TemplateTable
            :title="$t('menu.shipping')"
            :text="$t('transportVouchers.description')"
            name="resalesShipping"
            :url="$listRoutes.resalesShipping"
            :table="resalesShipping"
            :filters="resalesShippingFilters"
            :fields="fields"
    >
        <template v-slot:actions>
            <ButtonLink size="m" color="grey" icon="edit" :to="{name: 'resales-shipping-create'}">{{ $t('resale_shipping.title') }}</ButtonLink>
        </template>
    </TemplateTable>
</template>

<script>
import { mapState } from 'vuex';
import ButtonLink from '@/components/ui/button/ButtonLink';
import TemplateTable from '@/components/templates/TemplateTable';

export default {
    name: 'ResalesShipping',
    components: {TemplateTable, ButtonLink},
    data() {
        return {
            fields: [
                {key: 'client', type: 'client', size: 'l'},
                {key: 'firstName', size: 'm'},
                {key: 'lastName', size: 'm'},
                {key: 'phone', size: 'm'},
                {key: 'email', size: 'l', type: 'copy'},
                {key: 'city', size: 'm'},
                {key: 'country', size: 'm'},
                {key: 'url', size: 'm', type: 'pdf'},
                {key: 'isReturn', type: 'boolean'},
            ]
        }
    },
    computed: {
        ...mapState(['resalesShipping', 'resalesShippingFilters'])
    }
}
</script>