<template>
    <LinkUnderline class="page-back" :border-hover="true" @click.native.prevent="handleClick">
        <SvgIcon name="arrow left"/>
        <span>{{ $t('global.back') }}</span>
    </LinkUnderline>
</template>

<script>
import SvgIcon from '@/components/ui/svg/SvgIcon';
import LinkUnderline from '@/components/ui/link/LinkUnderline';

export default {
    name: 'PageBack',
    components: {LinkUnderline, SvgIcon},
    props: {
        type: String,
        to: Object,
        noAction: Boolean
    },
    methods: {
        handleClick() {
            if (!this.noAction) this.goBack()
        },
        goBack() {
            if (this.to) this.$router.push(this.to);
            else if (this.type === 'error') this.$router.go(-2);
            else this.$router.go(-1);
        }
    }
}
</script>

<style lang="scss">
.page-back {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    gap: 8px;
    text-transform: uppercase;
    letter-spacing: .5px;
    padding-bottom: 5px;
    font-weight: 500;

    svg {
        width: 10px;
        height: auto;
    }
}
</style>
