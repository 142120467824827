<template>
    <TemplateDetails :get-function="() => {}" :data="{}">

        <PageBack/>

        <Form ref="form"
              :title="$t('wallet_transaction.add')"
              :model="model"
              :fields="fields"
              :action="addWalletTransaction"
              :successMsg="$t('wallet.add_success')"
              @success="onSuccess"
              :init-on-success="true" />

    </TemplateDetails>
</template>

<script>
import TemplateDetails from '@/components/templates/TemplateDetails';
import Form from '@/components/ui/form/Form';
import PageBack from '@/components/ui/page/PageBack';
import { mapActions } from 'vuex';

export default {
    name: 'RecomWalletTransactionAdd',
    components: {Form, PageBack, TemplateDetails},
    data() {
        return {
            model: {
                amount: null,
                reason: null,
                description: null,
            },
            fields: [
                { name: 'amount', type: 'number', required: true },
                { name: 'reason', type: 'select', required: true,
                    options: [
                        {name: this.$t('wallet.reasons.commercial_gesture'), value: 'commercial_gesture'},
                        {name: this.$t('wallet.reasons.refund_error'), value: 'refund_error'},
                        {name: this.$t('wallet.reasons.other'), value: 'other'},
                    ]
                },
                { name: 'description', type: 'textarea' },
            ]
        }
    },
    methods: {
        ...mapActions(['addWalletTransaction']),
        onSuccess() {
            this.model = {};
            this.$nextTick(() => {
                this.model = {
                    amount: null,
                    description: null,
                }
            });
        }
    }
}
</script>