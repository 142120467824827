import API from '@/api/api-admin';
import listRoutes from '@/api/list-routes';
import i18n from '@/i18n';
import router from '@/router';

const actions = {
    // eslint-disable-next-line no-unused-vars
    addWalletTransaction({commit}, data) {

        const id = router.currentRoute.params.id;

        return new Promise((resolve, reject) => {
            data.amount = Number(data.amount) * 100;
            API.post(listRoutes.recomCustomerWalletTransactionAdd.replace(':id', id), {...data})
                .then(res => res.data)
                .then(walletTransaction => {
                    if (walletTransaction) {
                        resolve(i18n.t('wallet.add_wallet_transaction.success'));
                    } else {
                        reject(i18n.t('wallet.add_wallet_transaction.error'))
                    }
                })
                .catch(() => reject(i18n.t('wallet.add_wallet_transaction.error')))
        })
    }
}

const walletTransactions = {actions};

export default walletTransactions;
