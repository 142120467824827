<template>
    <component :is="tag || 'th'" :colspan="colspan"
        @click.prevent="updateSort"
        class="table-head-th"
        :class="{
            'is-sort': isSortable,
            'is-asc': !sortDirection || sortDirection === 'asc',
            'is-desc': sortDirection === 'desc',
            'is-active': sortBy === key
        }">
        <span v-if="field.type === 'eye'">{{ $t('global.detail') }}</span>
        <span v-else-if="field.type === 'store'">{{ $t('global.store') }}</span>
        <div v-else-if="!['eye', 'download'].includes(field.type)">
            <span>{{ field | fieldLabel }}</span>
            <SvgIcon v-if="isSortable" name="arrow down fill"/>
        </div>
    </component>
</template>

<script>
import SvgIcon from '@/components/ui/svg/SvgIcon';

export default {
    name: 'TableHeadTh',
    components: {SvgIcon},
    props: {
        field: Object,
        tag: String,
        filters: Array
    },
    computed: {
        isSortable() {
            return this.field.type !== 'eye' && !this.field.noSortable && this.key
        },
        colspan() {
            return this.field.colspan
        },
        sortDirection() {
            return this.$route.query.sort ? this.$route.query.sort.split(':')[1] : null
        },
        sortBy() {
            return this.$route.query.sort ? decodeURIComponent(this.$route.query.sort.split(':')[0]) : null
        },
        key() {
            if (!this.filters || !this.field?.key) return null;
            return this.filters.find(f => this.field.key === f.key)?.key
        }
    },
    methods: {
        updateSort() {
            if (this.isSortable) {
                let query = {...this.$route.query};
                if (this.key === this.sortBy) {
                    if (this.sortDirection === 'desc') delete query.sort;
                    else query.sort = `${this.key}:${this.sortDirection === 'asc' ? 'desc': 'asc'}`;
                } else {
                    query.sort = `${this.key}:asc`;
                }
                this.$router.push({
                    query: {
                        ...query
                    }
                })
            }
        }
    }
}
</script>

<style lang="scss">
.table-head-th.is-sort {

    &.is-sort {
        transition: color ease .2s;
        cursor: pointer;

        &.is-active,
        &:hover {
            color: #000;
        }

        &:not(.is-active) svg {
            --svg-color: var(--color-text-grey);
        }

        &.is-active.is-asc svg {
            transform: rotate(180deg);
        }

        svg {
            margin-top: 3px;
            width: 9px;
        }

        & > div {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
        }
    }
}
</style>