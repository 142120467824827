<template>
    <PopinCalendar :hide-suggestion="hideSuggestion" :disabled-today="disabledToday" :columns="columns" :period1="start" :period2="end" :max="max" :highlight="highlight" @change="e => $emit('change', e)" :dataTestid="dataTestid">
        <Field class="field-period" :label="label" v-if="text">
            <TextSimple>{{ text }}</TextSimple>
        </Field>
        <Field v-else class="field-period" :label="label" icon="calendar">
            <TextSimple :data-testid="dataTestid + '_start'">{{ start | momentDate }}</TextSimple>
            <TextSimple v-if="start || end" color="grey">{{ $t('goldenmetrics.periodTo') }}</TextSimple>
            <TextSimple :data-testid="dataTestid + '_end'">{{ end | momentDate }}</TextSimple>
        </Field>
        <template v-if="$slots.default" v-slot:choices><slot/></template>
    </PopinCalendar>
</template>

<script>
import Field from '@/components/ui/form/fields/Field';
import TextSimple from '@/components/ui/text/TextSimple';
import PopinCalendar from '@/components/ui/popin/PopinCalendar';

export default {
    name: 'FieldPeriod',
    components: {PopinCalendar, TextSimple, Field},
    props: {
        start: String,
        end: String,
        label: String,
        max: String,
        highlight: Object,
        hideSuggestion: Boolean,
        dataTestid: String,
        text: String,
        disabledToday: Boolean,
        columns: {
            type: Number,
            default: 2
        }
    }
}
</script>

<style lang="scss">
.field-period {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: var(--field-height);
    cursor: pointer;
    min-width: 150px;

    .field__input {
        display: flex;
        align-items: center;

        & > * {
            width: auto;
        }
    }

    p:nth-of-type(2) {
        margin: 0 10px;
    }
}
</style>