import API from '@/api/api-admin';

const state = {
    funnelDashboardDataPeriod: null,
    funnelTabDataPeriod: null
}

const mutations = {
    SET_FUNNEL_DATA(state, value) {
        state.funnelDashboardDataPeriod = [value[0], value[1]];
        state.funnelTabDataPeriod = [value[2]];
    }
}

const actions = {
    async getFunnelDashboardData({commit}, routes) {

            const promises = routes.map(async route => {
                return API.get(route)
                    .then(res => res.data)
                    .then(data => {
                        delete data.client;
                        delete data.nbVisit;
                        delete data.convertedVisit;
                        if(Object.keys(data).length === 0){
                            data = null;
                        } 
                        return data;
                    });
            });

            return Promise.all(promises)
                .then(data => {
                    commit('SET_FUNNEL_DATA', data);
                })
                .catch(err => Promise.reject(err?.message || err));
    }
}

const getters = {

}

const funnelDashboard = {state, mutations, actions, getters};

export default funnelDashboard;