<template>
    <div>
        <div v-if="data">
            <slot/>
        </div>
        <PageLoader v-else-if="loading"/>
        <PageError v-else title="404" :subtitle="error"/>
    </div>
</template>

<script>
import PageError from '@/components/ui/page/PageError';
import PageLoader from '@/components/ui/page/PageLoader';

export default {
    components: {PageLoader, PageError},
    props: {
        getFunction: Function,
        data: [Object, Array],
        loading: Boolean,
        error: {
            type: String,
            default: "Aucun donnée ne correspond à cette url"
        }
    },
    mounted() {
        this.getFunction(this.$route.params.id)
    }
}
</script>