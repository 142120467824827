<template>
    <TemplateDetails 
        :data="catalogueDefaultLocale && catalogueCollectionMappedFieldsFromSchema && productMerchandising" :loading="catalogueCollectionMappedFieldsFromSchemaLoading && productMerchandisingLoading && catalogueDefaultLocaleLoading" 
        :getFunction="getDatas">
        <section class="catalogue-product">
            <PageHeader :title="title">
                <template v-slot:actions>
                    <Button icon="settings" size="m" color="black" @click.native.prevent="updateThisMerchandising">{{$t('catalogue_products.save')}}</Button>
                </template>
            </PageHeader>
            
            <MerchandisingProduct 
                :options="catalogueCollectionMappedFieldsFromSchemaFormated"
                :model="productMerchandising.title.split(',')"
                :title="$t('catalogue_products.title')"
                :subtitle="$t('catalogue_products.title_subtitle')"
                @change="e => addTitleOrDescriptionToMerchandising(e,'title')"
            />

            <MerchandisingProduct 
                :options="catalogueCollectionMappedFieldsFromSchemaFormated"
                :model="productMerchandising.description"
                :checkbox="true"
                :title="$t('catalogue_products.description')"
                :subtitle="$t('catalogue_products.description_subtitle')"
                @change="e => addTitleOrDescriptionToMerchandising(e,'description')"
                @addCondition="e => addConditionToDescription(e)"
            />

            <CatalogueProductInformations 
                v-if="valuesForCatalogueProductInformations && productMerchandising?.fields"
                :datas="valuesForCatalogueProductInformations"
                :title="$t('catalogue_products.product_informations')"
                :text="$t('catalogue_products.product_informations_subtitle')"
                :conditions="$t('catalogue_products.product_informations_conditions')"
                @change="e => setProductMerchandisingFields(e)"
                :compare="productMerchandising?.fields"
            />

            <DragAndDrop
                v-if="getImagesFromSchema?.length"
                :values="defaultPhotoOrder"
                @update-order="handlePhotoOrder"
                :title="$t('catalogue_products.photos')"
                :text="$t('catalogue_products.photos_subtitle')"
                :conditions="$t('catalogue_products.photos_conditions')"
            />

            <Popin
                :title="$t('catalogue_products.popin_title')" 
                :subtitle="$t('catalogue_products.popin_text')"
                :center="true"
                ref="popinSuccess">
                <template #bottom>
                    <Button size="m" color ="green" @click.native.prevent="$refs.popinSuccess.close()">{{ $t('global.close') }}</Button>
                </template>
            </Popin>
            
        </section>
    </TemplateDetails>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import TemplateDetails from '@/components/templates/TemplateDetails.vue';
import PageHeader from '@/components/ui/page/PageHeader.vue';
import DragAndDrop from '@/components/ui/drag-and-drop/DragAndDrop.vue'
import MerchandisingProduct from '@/components/ui/merchandising/MerchandisingProduct.vue';
import CatalogueProductInformations from '@/components/ui/catalogue/CatalogueProductInformations.vue';
import Popin from '@/components/ui/popin/Popin.vue';
import Button from '@/components/ui/button/Button.vue';
export default {
    name: 'ConfigWebsiteCatalogueProductMerch',
    components: {
        TemplateDetails,
        PageHeader,
        MerchandisingProduct,
        CatalogueProductInformations,
        DragAndDrop,
        Popin,
        Button
    },
    data() {
        return {
            productTitleData:[],
            productDescriptionData:[],
            newPhotoOrderFromDragAndDrop: [],
            productDescriptionDataCondition:false,
            productDescriptionDataToSend: [],
        }
    },
    watch: {
        defaultLocale(newValue) {
            if(newValue) this.catalogueCollectionGetMappedFieldsFromSchema({id:this.id, lang:this.defaultLocale});
        },
        productDescriptionDataCondition() {
            // Met à jour productDescriptionDataToSend en fonction de la condition
            this.updateProductDescriptionDataToSend();
        },
        productTitleData(newValue){
            if(newValue){
                const productTitleDataToSend = newValue.map(elem => `#${elem}`).join()
                this.setProductMerchandisingTitle(productTitleDataToSend)
            }
        },
        productDescriptionData() {
            // Met à jour productDescriptionDataToSend lorsque productDescriptionData change
            this.updateProductDescriptionDataToSend();
        },
        productDescriptionDataToSend(newValue){
            if(newValue) this.setProductMerchandisingDescription(newValue)
        },
        newPhotoOrderFromDragAndDrop(newValue){
            if(newValue) this.setProductMerchandisingImages(newValue)
        },
        defaultPhotoOrder:{
            handler(newValue){
                if(newValue) {
                    this.setProductMerchandisingImages(newValue)
                    this.handlePhotoOrder(newValue)
                }
            }, immediate:true
        }
    },
    computed: { 
        ...mapState([
            'catalogueDefaultLocale',
            'catalogueDefaultLocaleLoading',
            'productMerchandising',
            'productMerchandisingLoading',
            'catalogueCollectionMappedFieldsFromSchema',
            'catalogueCollectionMappedFieldsFromSchemaLoading',
        ]),
        id() {
            return this.$route.params.id
        },
        client() {
            return this.$store.getters.getClientById(this.id)
        },
        title(){
            return `${this.$t('merchandising.title')} ${this.client.name}`
        },
        defaultLocale(){
            return this.catalogueDefaultLocale?.defautLocale
        },
        catalogueCollectionMappedFieldsFromSchemaFormated(){
            return this.catalogueCollectionMappedFieldsFromSchema?.map(elem => ({id:elem.name, label:elem.label}))
        },
        getImagesFromSchema(){ 
            return this.catalogueCollectionMappedFieldsFromSchemaFormated?.length
                ?  this.catalogueCollectionMappedFieldsFromSchemaFormated
                    .filter(elem => elem.id.includes('photo_'))
                    .map((elem,index) => ({id:index+1,name:elem.id,label:elem.label,order:index+1}))
                : []
        },
        defaultPhotoOrder(){
            return this.productMerchandising?.images?.length
                ? this.productMerchandising.images
                : this.getImagesFromSchema
        },

        valuesForCatalogueProductInformations(){
            return this.catalogueCollectionMappedFieldsFromSchema?.map(item => {
                // Vérifie si le nom de l'élément est 'title', 'color', 'size', ou 'description'
                if (['title', 'color', 'size', 'description'].includes(item.name)) {
                    item.active = true; // Définit 'active' à vrai
                    item.default = true;
                }
                return item;
            });
        },
    },
    methods: {
        ...mapActions([
            'setProductMerchandising',
            'updateProductMerchandising',
            'getCatalogueDefaultLocale',
            'catalogueCollectionGetMappedFieldsFromSchema',
            'setProductMerchandisingTitle',
            'setProductMerchandisingDescription',
            'setProductMerchandisingFields',
            'setProductMerchandisingImages'
        ]),
        getDatas(){
            this.getCatalogueDefaultLocale(this.id)
            this.setProductMerchandising(this.id)
        },
        handlePhotoOrder(newOrder) {
            this.$set(this, 'newPhotoOrderFromDragAndDrop', newOrder);
        },
        addTitleOrDescriptionToMerchandising(e,key){
            /**
             * Assigne les data merchandising_title_data et merchandising_description_data avec la valeur renvoyé par MerchandisingDisplay
             * déclenché par un @change
             * Si la valeur de e est 'description' alors description est ajouté sans son 'label'
             */
             if (e === null) {
                if (key === 'title') this.productTitleData = null;
                if (key === 'description') this.productDescriptionData = null;
            } else {
                if (key === 'description') {
                    this.$set(this, 'productDescriptionData', e)
                }
                if (key === 'title') {
                    this.$set(this, 'productTitleData', e)
                }
            }
        },
        addConditionToDescription(e){
            // e est un boolean renvoyé par l'enfant
            this.productDescriptionDataCondition = e
        },
        updateProductDescriptionDataToSend() {
            // Met à jour productDescriptionDataToSend en fonction de la condition
            // utilisé pour ajouter elem: #elem dans le cas de la description
            if(this.productDescriptionDataCondition) {
                this.productDescriptionDataToSend = this.productDescriptionData.map(item => `${item.replace('#','')}: #${item}`);
            } else {
                this.productDescriptionDataToSend = this.productDescriptionData.map(elem => `#${elem}`);
            }
        },
        updateThisMerchandising(){
            this.updateProductMerchandising({brandID:this.id,values:this.productMerchandising})
                .then(response => {
                    if(response) this.$refs.popinSuccess.open()
                })
        }


    },
}
</script>

<style lang="scss" scoped>
</style>