<template>
    <section>

        <PageBack/>
        <PageHeader :title="$t('invoice.create')"/>

        <Form
                :init-on-success="true"
                :fields="fields"
                :model="model"
                ref="form"
                :action="createInvoice"
                @success="submit"
        />

        <Popin :title="$t('invoice.create_success')" ref="popin">
            <template v-slot:content>
                <PageBack/>
            </template>
        </Popin>

    </section>
</template>

<script>
import PageHeader from "@/components/ui/page/PageHeader";
import Popin from "@/components/ui/popin/Popin";
import Form from "@/components/ui/form/Form";
import PageBack from "@/components/ui/page/PageBack";
// import { mapActions, mapGetters, mapState } from "vuex";
import { mapActions } from "vuex";

export default {
    name: "InvoiceCreate",
    components: {PageBack, Form, PageHeader, Popin},
    data() {
        return {
            user: null,
            loading: false,
            model: {
                client: this.$route.params.id,
                invoiceType: "CreditInvoice",
                invoiceFolder: null,
            },
            fields: [
                {
                    name: "invoiceFolder",
                    type: "select",
                    required: true,
                    options: [
                        {name: 'Oui', value: parseInt(this.$route.params.folder)},
                        {name: 'Non', value: null},
                    ],
                },
            ],
        };
    },
    methods: {
        ...mapActions(["createInvoice"]),
        submit() {
            this.$refs.popin.open();
        },
    },
};
</script>
