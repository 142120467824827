
<template>
    <Button class="button--export" :loading="loading || exportLoading" :hide="hide" size="m" color="grey" :disabled="disabled" icon="download" data-testid="export-btn">{{ $t(text) }}</Button>
</template>

<script>
import Button from '@/components/ui/button/Button';
import { mapState } from 'vuex';

export default {
    name: 'ButtonExport',
    components: {Button},
    props: {
        hide: Boolean,
        loading: Boolean,
        disabled: Boolean,
        text: {
            type: String,
            default: 'table.export-data'
        }
    },
    computed: {
        ...mapState(['exportLoading'])
    }
}
</script>