<template>
    <TemplateDetails :data="client" :loading="clientLoading" :get-function="getCatalogueUpdateDatas">
        <PageBack/>
        <PageHeader :title="title"></PageHeader>

        <Form class="catalogue-update-form" 
        :fields="reorderedArray" 
        :model="currentCatalogue" 
        :action="catalogueUpdate" 
        @form-before-submit="$refs.popinProgressCatalogue.open()"
        @success="e => updateCatalogueSuccess(e)" 
        />
        
        <Popin :title="catalogueEdit ? $t('catalogue.edit_success') : $t('catalogue.fail') " ref="popinEditCatalogue">
        <template v-slot:content>
            <ButtonLink :to="{name: 'config-website-catalogue-mapping', params:{ catalogueID: catalogueUpdateID }}" :loading="addLoading" color="blue" size="m" >{{ $t('catalogue.next_step') }}</ButtonLink>
        </template>
        </Popin>

        <Popin :title="$t('catalogue.catalogue_upload')" ref="popinProgressCatalogue">
        <template v-slot:content>
                <ProgressBar 
                    :start_value="catalogueProgress.totalLinesProcessed" 
                    :start_text="'Total MB chargé'" 
                    :total_value="catalogueProgress.totalLines" 
                    :total_text="'Total MB'" 
                    :progression="getCatalogueProgressStateValues" 
                />
                <InPopinLoader
                    v-if="getCatalogueProgressStateValues === '100%'"
                    :msg="$t('catalogue.please_wait_copy')" 
                    :loading="catalogueLoading" 
                />
        </template>
        </Popin>
  </TemplateDetails>
</template>

<script>
import TemplateDetails from '@/components/templates/TemplateDetails.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import PageHeader from '@/components/ui/page/PageHeader';

import PageBack from '@/components/ui/page/PageBack';
import Popin from '@/components/ui/popin/Popin';
import InPopinLoader from '@/components/ui/popin/InPopinLoader';
import ButtonLink from '@/components/ui/button/ButtonLink';

import Form from '@/components/ui/form/Form';
import ProgressBar from '@/components/ui/progress/ProgressBar';


export default {
  name: 'ConfigWebsiteCatalogueUpdate',
  components: { 
    TemplateDetails,
    PageHeader,
    PageBack,
    Popin,
    InPopinLoader,
    ButtonLink,
    Form,
    ProgressBar
  },
  props: {
    catalogueID: {
      type: String,
    },
  },
  data() {
    return {
      addLoading:false,
      model: {
        file: null,
        title: null,
        lang: null,
        delimiter: null,
        header:1,
        client_id:parseInt(this.$route.params.id)
      },
      fields: [
          {name: "title", type: 'text', label:"title", required: true},
          {
            name: "delimiter",
            label:"delimiter",
            type: "select",
            required: true,
            options: [
                {name:`${this.$t('catalogue.create_comma')}`, value:','},
                {name:`${this.$t('catalogue.create_semicolon')}`, value:';'},
                {name:`${this.$t('catalogue.create_tab')}`, value:'\t'},
                {name:`${this.$t('catalogue.create_pipe')}`, value:'|'},
            ],
          },
          {
            name: "file",  
            type: 'file', 
            label:"title", 
            required: true,
            accept:['.csv']
        },
          
      ],
      errorFileFormat:false,
      catalogueEdit:false,
      catalogueUpdateID:null,
    }
  },
  computed: {
    ...mapState(['clients','catalogueProgress','catalogueLoading','catalogueLocales']),
    ...mapGetters(['getCurrentCatalogue','getCatalogueProgressStateValues']),
    id() {
      return this.$route.params.id
    },
    lang() {
      return this.$route.params.lang
    },
    client() {
      return this.$store.getters.getClientById(this.id)
    },
    clientLoading(){
        return Object.keys(this.client).length ? true : false
    },
    title(){
      return !this.catalogueID && this.currentCatalogue ? `${this.$t('catalogue.create')} ${this.client.name} : ${this.currentCatalogue.title}` : `${this.$t('catalogue.update')} ${this.client.name} : ${this.currentCatalogue.title}`
    },
    clientLocalesOptions(){
      return this.catalogueLocales 
            ? this.catalogueLocales.map(elem => {
                return {name:`${this.$t('countries_code')[elem.split('_')[1]]}`, value:elem}
            })
            : null
    },
    clientSelectLang(){
      return {
            name: "lang",
            label:"lang",
            type: "select",
            required: true,
            options: this.clientLocalesOptions
          }
    },
    fieldsForForm(){
      return [...this.fields,this.clientSelectLang]
    },
    reorderedArray() {
      // Find the index of the "title" object
      const titleIndex = this.fieldsForForm.findIndex(item => item.name === "title");
      // Find the index of the "lang" object
      const langIndex = this.fieldsForForm.findIndex(item => item.name === "lang");

      if (titleIndex !== -1 && langIndex !== -1) {
        // Create a copy of the original array
        const newArray = [...this.fieldsForForm];

        // Remove the "lang" object from its current position
        const langObject = newArray.splice(langIndex, 1)[0];

        // Insert the "lang" object after the "title" object
        newArray.splice(titleIndex + 1, 0, langObject);

        return newArray;
      }

      // Return the original array if either "title" or "lang" is not found
      return this.originalArray;
    },
    currentCatalogue(){
      const newObject = { ...this.getCurrentCatalogue(this.lang)(this.catalogueID) };
  
      const keysToRemove = ['configuration', 'active', 'validation', 'generation', 'client', 'update_at'];

      for (const key of keysToRemove) {
          delete newObject[key];
      }

      newObject.client_id = parseInt(this.id)
      newObject.file = null
      newObject.header = 1

      return newObject;
    },    
  },
  methods: {
    ...mapActions(['fetchCatalogues','catalogueUpdate','getCatalogueLocales']),
    updateCatalogueSuccess(data){
        this.catalogueEdit = true
        this.catalogueUpdateID = data.id
        this.$refs.popinProgressCatalogue.close()
        this.$refs.popinEditCatalogue.open();
    },
    getCatalogueUpdateDatas() {
      this.fetchCatalogues(this.id)
      this.getCatalogueLocales(this.id)
    },

  },
  mounted () {
    this.$store.commit('SET_CATALOGUE_PROGRESS_BAR', {totalLines:0, totalLinesProcessed:0});
  },
  


}
</script>
<style lang="scss" scoped>
.catalogue-update-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 440px;
}
</style>