<template>
    <div class="collections-conditions__catalogue-data" v-if="catalogueCollectionLoading && defaultLocale.length && catalogueCollectionMappedFieldsFromSchema">
        <CollectionsConditionsCatalogueDataFields
            v-for="(item,index) in conditionsFromStateLength" :key="index"
            :fieldsValues="catalogueFieldsFilteredByExclusion"
            :index="index"
            :model="conditionsFromState[index]"
            @change="catalogueCollectionUpdateStateConditions"
            @removeCondition="catalogueCollectionRemoveCondition(index)"
        />
        <Button :disabled="!allConditionsValuesAreFilled" @click.prevent.native="catalogueCollectionAddConditions" icon="plus" color="black" size="m">{{ $t('collections.add_another_condition') }}</Button>
        
    </div>
    <div v-else-if="catalogueCollectionMappedFieldsFromSchema === undefined">
        <TextSimple color="red">{{ $t('collections.no_catalogue_in_default_locale') }}</TextSimple>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import CollectionsConditionsCatalogueDataFields from '@/components/ui/collections/CollectionsConditionsCatalogueDataFields.vue';
import Button from '@/components/ui/button/Button.vue';
import TextSimple from '@/components/ui/text/TextSimple.vue';
export default {
    name:'CollectionsConditionsCatalogueData',
    components: {
        CollectionsConditionsCatalogueDataFields,
        Button,
        TextSimple
    },
    computed: {
        ...mapState([
            'catalogueCollectionMappedFieldsFromSchema',
            'catalogueCollectionMappedFieldsFromSchemaLoading',
            'catalogueCollection',
            'catalogueCollectionLoading',
        ]),
        id(){
            return this.$route.params.id
        },
        conditionsFromState(){
            return this.catalogueCollection.parameters.conditions
        },
        conditionsFromStateLength(){
            return this.conditionsFromState.length
        },
        allConditionsValuesAreFilled(){
            return this.conditionsFromStateLength
                ? this.conditionsFromState.every(item => item?.value?.length)
                : false
        },
        defaultLocale(){
            return this.$route.params.lang
        },
        catalogueFieldsFilteredByExclusion(){
            const excludeNames = ['ean', 'photo_', 'description']
            return this.catalogueCollectionMappedFieldsFromSchema?.length
                ? this.catalogueCollectionMappedFieldsFromSchema.filter(item => {
                    return !excludeNames.some(excludeName => {
                        return item.name === excludeName || item.name.startsWith(excludeName);
                    })
                })
                : []
        }
    },
    methods: {
        ...mapActions([
            'catalogueCollectionGetMappedFieldsFromSchema',
            'catalogueCollectionUpdateStateConditions',
            'catalogueCollectionAddConditions',
            'catalogueGetFieldListValue',
            'catalogueCollectionRemoveCondition',
        ]),
    },
    updated () {
        if(this.catalogueCollection?.id?.length && this.conditionsFromStateLength === 0){
            this.catalogueCollectionAddConditions()
        }
    },
    mounted () {
        this.catalogueCollectionGetMappedFieldsFromSchema({id:this.id, lang:this.defaultLocale});
    },
    
}
</script>

<style lang="sass" scoped>

</style>