<template>
    <div>
        <input type="file" ref="fileInput" :accept="accept" @change="handleFileInputChange" style="opacity: 0; pointer-events: none; position: absolute;">
        <ButtonCustom :loading="loading" :size="size" :color="color" icon="import" @click.native.prevent="openFileInput">{{ $t('global.import') }}</ButtonCustom>
        <PopinNotification ref="popin" :text="success || $t('translations.import_success')"/>
    </div>
</template>

<script>
import ButtonCustom from '@/components/ui/button/Button';
import PopinNotification from '@/components/ui/popin/PopinNotification';
export default {
    name: 'ButtonImport',
    components: {PopinNotification, ButtonCustom},
    data() {
        return {
            loading: false,
            success: null
        }
    },
    props: {
        accept: String,
        size: {
            type: String,
            default: 'm'
        },
        color: {
            type: String,
            default: 'grey'
        },
        action: {
            type: Function
        },
        importRoute: String
    },
    methods: {
        openFileInput() {
            this.$refs.fileInput.click();
        },
        handleFileInputChange(event) {
            const file = event.target.files[0];
            this.loading = true;
            this.action({
                id: this.$route.params.id,
                route: this.importRoute,
                file: file
            })
                    .then(msg => {
                        this.success = msg;
                        this.$refs.popin.open();
                    })
                    .finally(() => this.loading = false)
        }
    }
}
</script>