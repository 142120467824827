<template>
    <div class="status" :class="{'is-valid':valid,'border':border,'padding':padding}">
        <TextSimple color="black" :size="size">{{ text }}</TextSimple>
    </div>
</template>

<script>
import TextSimple from '@/components/ui/text/TextSimple.vue';
export default {
    name:'StatusDefault',
    components: {
        TextSimple,
    },
    props: {
        text: String,
        valid: Boolean,
        size: {
            type: String,
            default: 'xxxs'
        },
        border: {
            type: Boolean,
            default: true
        },
        padding: {
            type: Boolean,
            default: true
        },
    },
}
</script>

<style lang="scss" scoped>
.status {
    display: inline-flex;
    align-items: center;
    gap: 6px;
    // text-transform: uppercase;
    color:var(--color-bg-grey);
    flex-wrap: wrap;
    margin-right: 4px;
    &.padding {
        padding: 4px 8px;
    }
    &.border {
        border-bottom: 1px solid var(--color-bg-light);
        border-right: var(--border-2);
        border-color: var(--color-bg-light);
    }
    &:after {
            content:'';
            width:10px;
            height:10px;
            border-radius: 50px;
        }
    &:after {
                background:var(--color-red);
            }
    &.is-valid {
        &:after {
            background:var(--color-green);
        }
    }
    
    // span {
    //     font-size: .9rem;
    //     display: flex;
    //     align-items: center;
        
    //     
    //     border-radius: 50%;
    //     text-transform: uppercase;
    //     color:var(--color-text-black);
        
    // }
}
</style>