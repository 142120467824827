<template>
    <TemplateTable
            :title="$t('menu.admin-access')"
            name="admins"
            :url="$listRoutes.admins"
            :table="admins"
            :filters="adminsFilters"
            :fields="fields"
            :actions="actions">

        <template v-slot:actions>
            <ButtonLink v-if="creationIsAllowed" size="m" icon="plus" color="grey" :to="{name: 'admin-create'}">{{ $t('admin.create.title') }}</ButtonLink>
        </template>

    </TemplateTable>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import TemplateTable from '@/components/templates/TemplateTable';
import ButtonLink from '@/components/ui/button/ButtonLink';
export default {
    name: 'AdminsPage',
    components: {ButtonLink, TemplateTable},
    data() {
        return {
            fields: [
                {key: 'id', type: 'eye', route: 'admin', size: 'xs'},
                {key: 'client', type: 'client', size: 'l'},
                {key: 'email', size: 'xl'},
                {key: 'firstname', size: 'l'},
                {key: 'lastname', size: 'l'},
                {key: 'roles', type: 'roles', size: 'xxl'},
                {key: 'defaultLocale.code', type: 'locale', size: 'm', translation: this.$t('global.locale')},
            ],
            actions: [
                {
                    name: 'edit',
                    route: 'admin-edit',
                    color: 'green',
                    prop: 'id'
                },
                {
                    name: 'delete',
                    route: 'admin-delete',
                    color: 'red',
                    prop: 'id'
                }
            ]
        }
    },
    computed: {
        ...mapState(['admins', 'adminsFilters']),
        ...mapGetters(['roleIsAllowed']),
        creationIsAllowed() {
            return this.roleIsAllowed(['ROLE_ADMIN', 'ROLE_BRAND_ADMIN', 'ROLE_SUPER_STORE', 'ROLE_SUPER_ADMIN'])
        }
    }
}
</script>