<template>
    <div class="markets-status">
        <TextTitle size="s">{{ $t(`market.markets`) }}</TextTitle>
        <Status v-for="status in marketStatus" :key="status.countryCode" 
            :text="$t(`countries_code.${status.countryCode}`)" 
            :valid="status.isPresent" 
            :border="false"
            size="m"
        />
    </div>
</template>

<script>
import TextTitle from '@/components/ui/text/TextTitle.vue';
import Status from '@/components/ui/status/Status.vue';
export default {
    name: 'MarketsStatus',
    components: {
        TextTitle,
        Status,
        
    },
    props: {
        marketStatus: Array,
    },
}
</script>

<style lang="scss" scoped>
.markets-status {
    display: flex;
    gap: 10px;
    flex-direction: column;
    margin-bottom: 20px;
}
</style>