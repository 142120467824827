<template>
    <div class="checkerboard">
        <slot/>
    </div>
</template>

<script>
export default {
    name: 'CheckerboardContainer'
}
</script>

<style lang="scss">
.checkerboard {
    border-radius: var(--border-radius-2);
    //overflow: hidden;
    display: grid;
    gap: 1px;
    --columns: 5;
    grid-template-columns: repeat(var(--columns), minmax(0, 1fr));
    margin-bottom: 30px;

    @media screen and (min-width: 801px) and (max-width: 1280px) {
        --columns: 4;
    }

    @media screen and (min-width: 601px) and (max-width: 800px) {
        --columns: 3;
    }

    @media screen and (max-width: 600px) {
        --columns: 2;
    }
}
</style>

