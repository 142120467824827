<template>
    <li class="grid-calendar-date" :class="{'is-active': date, 'is-error': error}">
        <span></span>
        <div>
            <TextSimple :color="date ? 'black' : 'grey'">{{ title }} {{ date | dateHour }}</TextSimple>
            <TextSimple :color="date ? 'black' : 'grey'" size="s" v-if="info">{{ info }}</TextSimple>
            <TextSimple size="s" color="grey" v-if="comment">{{ comment }}</TextSimple>
        </div>
    </li>
</template>

<script>
import TextSimple from '@/components/ui/text/TextSimple';
export default {
    name: 'GridCalendarDate',
    components: {TextSimple},
    props: {
        date: String,
        title: String,
        info: String,
        comment: String,
        error: Boolean
    }
}
</script>

<style lang="scss">
.grid-calendar-date {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    gap: 15px;

    & > span {
        display: block;
        width: 20px;
        height: 20px;
        background-color: #ddd;
        border-radius: 50%;
        border: 4px solid var(--color-bg-light);
        flex-shrink: 0;
    }

    &.is-active > span {
        background-color: #000;
    }

    &.is-error > span {
        background-color: var(--color-red2);

        & + div > p:first-of-type {
            color: var(--color-text-red) !important;
        }
    }

    & > div {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 8px
    }
}
</style>