import HomePage from '@/components/pages/admin/Home';

const homeRoutes = [
    {
        path: '/admin/bienvenue',
        name: 'admin-home',
        component: HomePage,
        meta: {
            icon: 'home',
            title: 'home'
        }
    },
    {
        path: '/admin',
        redirect: {
            name: 'admin-home'
        },
    },
    {
        path: '/admin/*',
        redirect: {
            name: 'admin-home'
        }
    }
];

export default homeRoutes;