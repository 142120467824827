<template>
    <main class="simple-layout">
        <div class="simple-layout__container">
            <slot/>
        </div>
        <div class="simple-layout__image">
            <img src="@/assets/images/home.png" alt="Faume dashboard">
        </div>
        <LayoutSwitchLang/>
    </main>
</template>

<script>
import LayoutSwitchLang from '@/components/ui/layouts/LayoutSwitchLang';

export default {
    name: 'SimpleLayout',
    components: {LayoutSwitchLang}
}
</script>

<style lang="scss">
.simple-layout {
    display: flex;
    width: 100%;
    background-color: var(--color-bg-light);
    position: relative;
    min-height: 100vh;

    &__container {
        margin: auto;
        width: 100%;
        padding: 40px 15px;
        max-width: 450px;
        position: relative;
    }

    &__image {
        flex-shrink: 0;
        width: 25%;
        min-height: 100vh;
        min-width: 250px;

        @media screen and (max-width: 800px) {
            display: none;
        }
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    & > .form-switch {
        position: absolute;
        z-index: 3;
        top: 10px;
        right: 10px;
        width: 100px;
        height: 40px;
    }
}
</style>