<template>
    <div class="markets-switcher">
        <ToggleSwitcher :model="toggled" @change="e => toggled= !toggled" />
        <TextSimple size="m" weight="bold">{{ $t(`countries_code.${text}`) }}</TextSimple>
    </div>
</template>

<script>
import TextSimple from '@/components/ui/text/TextSimple.vue';
import ToggleSwitcher from '@/components/ui/switcher/ToggleSwitcher.vue';
export default {
    name: 'MarketsSwitcher',
    components: {
        TextSimple,
        ToggleSwitcher,
    },
    data() {
        return {
            toggled: null
        }
    },
    props: {
        text: String,
        toggle: Boolean,
        data: Object
    },
    watch: {
        toggled:'emitData'
        
    },
    computed: {
        updateMarkets() {
            return {val:this.text, type: 'markets' }
        }
    },
    methods: {
        emitData(){
            const payload = this.toggled ? { addItem: this.text } : { removeItem: this.text };
            this.$emit('change', { payload, type: "markets" });
        }
    },
    mounted () {
        this.toggled = this.toggle;
    },
}
</script>

<style lang="scss" scoped>
.markets-switcher {
    margin: 20px 0;
}
</style>