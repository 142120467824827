<template>
    <div class="filter-field">

        <FieldSelect :auto="true"
                     :border="true"
                     :selected="selectedField"
                     :options="filtersOptions"
                     @input="e => selectedField = e"/>

        <FieldSelect :auto="true"
                     :border="true"
                     :selected="selectedOperator"
                     :options="operators?.map(o => ({id: o, label: $t(`global.${o}`)}))"
                     @input="e => selectedOperator = e"/>

        <FieldSelect v-if="type === 'select' || type === 'boolean'"
                     :border="true"
                     :selected="value"
                     :options="values"
                     :multiple="selectedOperator === 'in' || selectedOperator === 'nin'"
                     @input="e => value = e"/>

        <PopinCalendar v-else-if="type === 'date'"
                       :display-hours="true"
                       :is-date="true"
                       :date="value"
                       :columns="2"
                       :hide-suggestion="true"
                       @change="e => value = e">
            <span class="filter-field__input">{{ value | momentDateTime }}<SvgIcon name="calendar"/></span>
        </PopinCalendar>

        <input v-else
               :disabled="!type && !selectedOperator"
               v-model="value"
               :data-testid="`${testid}-filterinput`"
               autofocus
               ref="input"
               :type="type">

        <Button color="white"
                icon="trash"
                @click.native.prevent="$emit('delete')"/>

    </div>
</template>

<script>
import FieldSelect from '@/components/ui/form/fields/FieldSelect';
import Button from '@/components/ui/button/Button';
import PopinCalendar from '@/components/ui/popin/PopinCalendar';
import SvgIcon from '@/components/ui/svg/SvgIcon';
export default {
    name: 'FilterField',
    components: {PopinCalendar, Button, FieldSelect, SvgIcon},
    props: {
        filters: Array,
        model: Object,
        noTranslate: Boolean,
        testid: String
    },
    data() {
        return {
            booleanValues: [
                {id: '1', label: this.$t('global.yes')},
                {id: '0', label: this.$t('global.no')},
            ],
            listOperators: {
                date: ['gte', 'lte'],
                string: ['like', 'nlike', 'eq', 'neq'],
                select: ['in', 'nin'],
                number: ['eq', 'neq', 'gte', 'lte'],
                boolean: ['eq']
            },
            selectedField: this.model?.key,
            selectedOperator: this.model?.operator,
            value: this.model?.value
        }
    },
    computed: {
        type() {
            if (!this.selectedField) return null;
            return this.filters.find(f => f.key === this.selectedField)?.type
        },
        operators() {
            return this.type ? this.listOperators[this.type] : []
        },
        values() {
            const field = this.filters.find(f => f.key === this.selectedField);
            if (field?.type === 'boolean') return this.booleanValues;
            if (!field?.options?.length) return [];
            return [...field.options].map(x => {
                const translationKey = `global.${x.label}`;
                const translation = this.$t(translationKey);
                return {
                    ...x,
                    label: translation === translationKey ? x.label : translation
                }
            })
        },
        fieldValue() {
            return {
                field: this.selectedField,
                key: this.selectedOperator,
                value: this.value
            }
        },
        filtersOptions() {
            return this.filters?.map(f => ({
                id: f.key,
                label: f.key.includes('tag_') ? f.key.replace('tag_', 'Tag ') : this.noTranslate ? f.label : this.$t(`global.${f.key}`)
            }))
        }
    },
    methods: {
        emitChange() {
            this.$emit('change', {key: this.selectedField, operator: this.selectedOperator, value: this.value})
        }
    },
    watch: {
        selectedField() {
            this.selectedOperator = null;
            this.value = null;
        },
        model() {
            const key = this.model?.key;
            const operator = this.model?.operator;
            const value = this.model?.value;
            if (value != this.value || operator !== this.selectedOperator || key !== this.selectedField) {
                this.selectedField = key;
                this.selectedOperator = operator;
                this.value = value;
            }
        },
        fieldValue() {
            this.emitChange();
        }
    },
    mounted() {
        if (this.$refs.input) this.$refs.input.focus();
    }
}
</script>

<style lang="scss">
.filter-field {
    display: flex;
    gap: 4px;
    --field-padding: 10px;
    position: relative;

    .button {
        min-width: 20px;
        height: var(--field-height);
        padding: 0;
        border: 0;
        flex-shrink: 0;
    }

    input {
        margin: 0;
    }

    .popin-calendar {
        position: initial;
    }

    .field-select {

        &:nth-of-type(2) {

            .field-select__selected {
                text-align: center;
                justify-content: center;
            }
        }
    }

    & > * {
        &:nth-child(1) {
            width: 140px;
            flex-shrink: 0;
        }
        &:nth-child(2) {
            flex-shrink: 0;
            width: auto;
            min-width: 100px;

            @media screen and (min-width: 1025px) {
                width: 160px;
            }

        }
        &:nth-child(3) {
            flex-grow: 1;
            width: 0;
        }
    }

    &__input,
    input {
        height: var(--field-height);
        padding: 0 var(--field-padding);
        border: var(--border-1);
        border-radius: var(--border-radius-2);
        transition: border ease .2s;

        &:hover {
            border: var(--border-2);
        }
    }

    &__input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5px;
        --svg-color: #aaa;

        svg {
            width: 12px;
        }
    }
}
</style>