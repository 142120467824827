<template>
    <div class="grid-header">
        <div>
            <div class="grid-header__title">
                <SvgIcon v-if="icon" :icon="icon"/>
                <TextTitle v-if="title" :size="size">{{ title }}</TextTitle>
                <TextSimple v-if="count">{{ `(${count})` }}</TextSimple>
            </div>
            <TextSimple v-if="text">{{ text }}</TextSimple>
        </div>
        <div class="grid-header__right" v-if="$slots.default">
            <slot/>
        </div>
    </div>
</template>

<script>
import SvgIcon from '@/components/ui/svg/SvgIcon';
import TextSimple from '@/components/ui/text/TextSimple';
import TextTitle from '@/components/ui/text/TextTitle';
export default {
    name: 'GridHeader',
    components: {TextSimple, TextTitle, SvgIcon},
    props: {
        icon: String,
        title: String,
        text: String,
        count: [String, Number],
        size: {
            type: String,
            default: 's'
        }
    }
}
</script>

<style lang="scss">
.grid-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;

    &:not(:last-child) {
        margin-bottom: 20px;
    }

    &__right {
        display: flex;
        gap: 6px;
        flex-shrink: 0;

        @media screen and (max-width: 800px) {
            .button {
                padding: 0;
                min-width: 40px;

                & > span > span {
                    &:first-of-type {
                        display: none;

                        & + span {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }

    &__title {
        display: flex;
        gap: 10px;
        align-items: center;

        & + .text {
            margin-top: 10px;
        }

        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            flex-shrink: 0;

            svg {
                height: 100%;
                width: auto;
            }
        }
    }
}
</style>