<template>
    <component :is="to ? 'router-link' : 'p'" :to="to" class="link-with-hover">
        <slot/>
    </component>
</template>

<script>
export default {
    name: 'LinkWithHover',
    props: {
        to: Object
    }
}
</script>

<style lang="scss">
.link-with-hover {
    padding: 10px 7px;
    border-radius: var(--border-radius-1);
    transition: background-color ease-out .3s;
    cursor: pointer;

    @media screen and (min-width: 1025px) {
        &:hover {
            background-color: var(--color-bg-light);
        }
    }
}
</style>