<template>
    <p class="form-message" :class="{'is-success': success, 'is-error': error}">
        <slot/>
    </p>
</template>

<script>
export default {
    name: 'FormMessage',
    props: {
        error: [Boolean, String],
        success: [Boolean, String]
    }
}
</script>

<style lang="scss">
.form-message {
    display: block;
    font-size: 1.2rem;
    margin: 20px 0 30px;

    &.is-error {
        color: var(--color-red);
        background-color: var(--color-red-light);
    }

    &.is-success {
        color: var(--color-green);
        background-color: var(--color-green-light);
    }

    &.is-success,
    &.is-error {
        width: fit-content;
        padding: 10px;
        border-radius: var(--border-radius-1);
    }
}
</style>