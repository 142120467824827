<template>
    <label class="field-checkbox">
        <input type="checkbox" :required="required" v-model="value">
        <span class="field-checkbox__input" :class="{'is-checked': value}">
            <SvgIcon v-if="value" name="check"/>
        </span>
        <span class="field-checkbox__label">{{ label }}</span>
    </label>
</template>

<script>
import SvgIcon from '@/components/ui/svg/SvgIcon';
export default {
    name: 'FieldCheckbox',
    components: {SvgIcon},
    props: {
        required: Boolean,
        model: Boolean,
        label: String
    },
    data() {
        return {
            value: this.model
        }
    },
    watch: {
        model(e) {
            this.value = e
        },
        value() {
            this.$emit('change', this.value);
        }
    }
}
</script>

<style lang="scss">
.field-checkbox {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;

    @media screen and (min-width: 1025px) {
        &:hover {
            .field-checkbox__input {
                border-color: #ccc;
            }
        }
    }

    &__input {
        flex-shrink: 0;
        width: 25px;
        height: 25px;
        border-radius: var(--border-radius-1);
        border: var(--border-1);
        margin-right: 15px;
        background-color: #fff;
        cursor: pointer;
        transition: border ease-out .2s;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 10px;
        }
    }

    input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        pointer-events: none;
    }
}
</style>