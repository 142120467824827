import API from '@/api/api-admin';
import listRoutes from '@/api/list-routes';
import i18n from '@/i18n';

const state = {
    emailsList: null,
    emailByKey: null,
    emailsParameters: null,
    brandEmailsPreview: null,
}

const mutations = {
    SET_EMAILS_LIST(state, data){
        state.emailsList = data
    },
    SET_EMAILS_BY_KEY(state, data){
        state.emailByKey = data
    },
    SET_PUT_EMAILS_BY_KEY(state, data){
        state.emailByKey[data.reference.messageId] = data.content
    },
    SET_EMAILS_PARAMETERS(state, data){
        state.emailsParameters = data
    },
    SET_PUT_EMAILS_PARAMETERS(state, data){
        state.emailsParameters[data.reference.key] = data.content
    },
    SET_EMAILS_MODIFICATIONS_PUBLISH(state, data){
        state.emailsModificationsPublish = data
    },
    SET_EMAILS_PREVIEW(state, data) {
        state.brandEmailsPreview = data
    },
}

const actions = {
    getEmailsList({commit}, { idClient, locale }) {
        return new Promise((resolve, reject) => {
            if(state.emailsList){
                commit('SET_EMAILS_LIST', state.emailsList)
            } else {
                API.get(listRoutes.websiteEmails.replace(':id', idClient).replace(':locale', locale))
                    .then(res => {
                        commit('SET_EMAILS_LIST', res.data);
                        resolve(res.data);
                    })
                    .catch(() => reject())
            }

        })
    },
    getEmailByKey({commit}, { idClient, locale, key }) {
        return new Promise((resolve, reject) => {
            API.get(listRoutes.mailbuilderEmailContent.replace(':id', idClient).replace(':locale', locale).replace(':key', key))
                .then(res => commit('SET_EMAILS_BY_KEY', res.data))
                .catch(() => reject())
                .finally(() => resolve())
        })
    },
    putEmailByKey({commit}, { idClient, locale, wordingKey, data }) {
        return new Promise((resolve, reject) => {
            API.put(listRoutes.mailbuilderEmailContent.replace(':id', idClient).replace(':locale', locale).replace(':key', wordingKey), data)
                .then(res => commit('SET_PUT_EMAILS_BY_KEY', res.data))
                .catch(() => reject())
                .finally(() => resolve())
        })
    },
    getEmailParameters({commit}, { idClient }) {
        return new Promise((resolve, reject) => {
            API.get(listRoutes.mailbuilderEmailsGraphicsParameters.replace(':id', idClient))
                .then(res => commit('SET_EMAILS_PARAMETERS', res.data))
                .catch(() => reject())
                .finally(() => resolve())
        })
    },
    putEmailParameters({commit}, { idClient, key, data }) {
        return new Promise((resolve, reject) => {
            API.put(listRoutes.mailbuilderEmailGraphicsParameters.replace(':id', idClient).replace(':key', key), data)
                .then(res => commit('SET_PUT_EMAILS_PARAMETERS', res.data))
                .catch(() => reject())
                .finally(() => resolve())
        })
    },
    postEmailImage(_, { idClient, image, key }) {
        return new Promise((resolve, reject) => {
            let playload = {};
            playload[key] = image;
            API.post(listRoutes.mailbuilderEmailsImages.replace(':id', idClient), {...playload}, {headers: {'Content-Type': 'multipart/form-data'}})
                .catch(() => reject())
                .finally(() => resolve())
        })
    },
    getEmailsModificationsPublish({commit}, { idClient, locale}) {
        return new Promise((resolve, reject) => {
            API.get(listRoutes.mailbuilderEmailsPublish.replace(':id', idClient).replace(':locale', locale))
                .then(res => {
                    commit('SET_EMAILS_MODIFICATIONS_PUBLISH', res.data);
                    commit('SET_NOTIFICATION_MESSAGE', i18n.t('translations.updated_message'))
                })
                
                .catch(() => reject())
                .finally(() => resolve())
        })
    },
    getBrandEmailsPreview({commit}, { idClient, locale, mailKey }) {
        return new Promise((resolve, reject) => {
            API.get(listRoutes.mailbuilderEmailsPreview.replace(':id', idClient).replace(':locale', locale).replace(':mailKey', mailKey))
                .then(res => commit('SET_EMAILS_PREVIEW', res.data))
                .catch(() => reject())
                .finally(() => resolve())
        })
    },
}

const getters = {
    emailsByCategory: state => {
        let emailsByCategory = {};
        if(state.emailsList && state.emailsList.length){
            state.emailsList.forEach(email => {
                if (!emailsByCategory[email.category]) {
                    emailsByCategory[email.category] = [];
                }
                emailsByCategory[email.category].push(email);
            });
        }
        return emailsByCategory;
    }
}

const faumeMailbuilder = {
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
};

export default faumeMailbuilder;