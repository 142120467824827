import ConfigWebsiteMerchandising from '@/components/pages/admin/config/website/merchandising/ConfigWebsiteMerchandising';

const merchandisingRoutes = [
    {
        path: '/admin/config/sites/:id/merchandising',
        name: 'config-website-merchandising',
        component: ConfigWebsiteMerchandising,
        meta: {
            roles: ['ROLE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
]

export default merchandisingRoutes;