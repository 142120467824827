import API from '@/api/api-admin';
import listRoutes from '@/api/list-routes';

const state = {
    brandEmailsTranslations: null,
    brandEmailsEditTranslations: null,
    brandEmailsSendTestEmailMessage: null,
}

const mutations = {
    SET_BRAND_EMAILS_TRANSLATIONS(state, data) {
        state.brandEmailsTranslations = data
    },
    SET_BRAND_EMAILS_EDIT_TRANSLATIONS(state, data) {
        state.brandEmailsEditTranslations = data
    },
    SET_EMAILS_SEND_TEST_MESSAGE(state, data) {
        state.brandEmailsSendTestEmailMessage = data
    },
}

const actions = {
    getBrandEmailsTranslations({commit}, { idClient, locale }) {
        return new Promise((resolve, reject) => {
            if(state.brandEmailsTranslations){
                commit('SET_BRAND_EMAILS_TRANSLATIONS', state.brandEmailsTranslations)
            } else {
                API.get(listRoutes.websiteEmails.replace(':id', idClient).replace(':locale', locale))
                    .then(res => commit('SET_BRAND_EMAILS_TRANSLATIONS', res.data))
                    .catch(() => reject())
                    .finally(() => resolve())
            }

        })
    },
    getBrandEmailsEditTranslations({commit}, id) {
        return new Promise((resolve, reject) => {
            API.get(listRoutes.websiteEditEmails.replace(':id', id))
                .then(res => commit('SET_BRAND_EMAILS_EDIT_TRANSLATIONS', res.data))
                .catch(() => reject())
                .finally(() => resolve())
        })
    },
    postBrandEmailsSendTest({commit}, { idClient, locale, mailKey, adressEmail }) {
        return new Promise((resolve, reject) => {
            API.post(listRoutes.websiteEmailsSendTest.replace(':id', idClient).replace(':locale', locale).replace(':mailKey', mailKey), {"emails": adressEmail})
                .then(res => commit('SET_EMAILS_SEND_TEST_MESSAGE', res.data.message))
                .catch(() => reject())
                .finally(() => resolve())
        })
    },
}
const getters = {
}

const mailbuilder = {
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
};

export default mailbuilder;