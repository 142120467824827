<template>
    <TemplateClient :subtitle="$t('client.seo.title')">

        <p>Contenu en cours de développement</p>

    </TemplateClient>
</template>

<script>
import TemplateClient from '@/components/templates/TemplateClient';
import { mapState } from 'vuex';

export default {
    name: 'ClientSEO',
    components: {TemplateClient},
    computed: {
        ...mapState(['client'])
    }
}
</script>
