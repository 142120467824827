<template>
    <div class="catalogue-filters" @click.self="$emit('close')" v-if="show">
        <div class="catalogue-filters__container" :class="{'show':show}">
            <div class="catalogue-filters__header">
                <TextTitle size="s">{{ $t('global.filter') }}</TextTitle>
                <Button icon="cross" color="white" @click.native.prevent="$emit('close')"/>
            </div>
            <div class="catalogue-filters__inner">
                <CatalogueFilter v-for="(filter,index) in catalogueFilters" :key="index"
                    :model="datas"
                    :options="filterDataWithDisabledItems"
                    :apiLanguage="apiLanguage"
                    :currentIndex="index"
                    :filter="filter"
                    @delete="e => deleteFilter(e)"
                    />
                <Button size="m" color="grey" icon="plus" @click.native.prevent="addFilter">{{ $t('global.add_filter') }}</Button>
            </div>
            <div class="catalogue-filters__footer">
                <Button size="l" color="black" @click.native.prevent="$emit('close')" data-testid="filter-btn-validate">{{ $t('global.validate') }}</Button>
                <Button color="white" size="s" @click.native.prevent="reset">{{ $t('global.reset') }}</Button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Button from '../button/Button.vue'
import TextTitle from '../text/TextTitle.vue'
import CatalogueFilter from './CatalogueFilter.vue'
export default {
    name: 'CatalogueFilters',
    components: {
        TextTitle,
        Button,
        CatalogueFilter,
    },
    props: {
        datas: Array,
        show: Boolean,
        apiLanguage: String
    },
    data() {
        return {
            loading:false,
        }
    },
    computed: {
        ...mapState(['catalogueFilters']),
        filterDataWithDisabledItems(){
            return this.datas.map(data => {
                const isDisabled = this.catalogueFilters.some(filter => filter.name && data.name === filter.name);
                return {
                  ...data,
                  disabled: isDisabled ? 'disabled' : undefined
                };
              })
        }
    },
    methods: {
        ...mapActions([
            'addCatalogueFilterItem',
            'deleteCatalogueFilter',
            'resetCatalogueFilters'
        ]),
        addFilter() {
            this.addCatalogueFilterItem()
        },
        deleteFilter(index) {
            this.deleteCatalogueFilter(index)
        },
        reset() {
            this.resetCatalogueFilters()
        }
    },
}
</script>

<style lang="scss" scoped>
.catalogue-filters{
    position: fixed;
    inset: 0;
    z-index: 6;
    --filter-padding: 30px;
    @media screen and (max-width: 800px) {
        --filter-padding: 15px;
    }
    &__container {
        background:#fff;
        display: flex;
        flex-direction: column;
        max-width: 600px;
        position: relative;
        z-index: 12;
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow: scroll;
        display: flex;
        flex-direction: column;
        opacity: 1;
        transition: all ease-out 0.4s;
        animation-delay: 250ms;
        box-shadow: var(--box-shadow-2);
        position: fixed;
        animation-name: slideIn;
        transform: translateX(100%);
        top: 0;
        right: 0%;
        bottom: 0%;
        &.show {
            opacity: 1;
            transform: translateX(0%);
        }
    }
    @keyframes slideIn {
        from {
            transform: translateX(100%);
        }
        to {
            transform: translateX(0%);
        }
    }
    &__header {
        padding: 15px var(--filter-padding);
        border-bottom: var(--border-1);
        text-align: left;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .button {
            min-width: 35px;
            min-height: 35px;
            padding: 0;
            border: var(--border-1);

            @media screen and (min-width: 1025px) {
                &:hover {
                    border: var(--border-2);
                }
            }

            span {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0;
            }

            svg {
                width: 12px;
            }
        }
    }
    &__inner {
        padding: var(--filter-padding);
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    &__footer {
        padding: 15px var(--filter-padding);
        border-top: var(--border-1);
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        margin-top: auto;
        gap: 5px;
        & > * {
            width: 100%;
        }
    }
}
</style>