<template>
    <TemplateDetails :get-function="getAdmin" :data="admin" :loading="adminLoading">
        <PageBack/>

        <Form :title="$t('admin.delete.title')"
              :text="$t('admin.delete.subtitle')"
              :success-msg="$t('admin.delete.success')"
              :button="$t('global.permanently_delete')"
              :action="deleteAdmin">

            <template v-slot:top>
                <UserCardAdmin :admin="admin"/>
            </template>

        </Form>
    </TemplateDetails>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import TemplateDetails from '@/components/templates/TemplateDetails';
import UserCardAdmin from '@/components/ui/user/UserCardAdmin';
import Form from '@/components/ui/form/Form';
import PageBack from '@/components/ui/page/PageBack';

export default {
    name: 'AdminDelete',
    components: {PageBack, Form, UserCardAdmin, TemplateDetails},
    computed: {
        ...mapState(['admin', 'adminLoading'])
    },
    methods: {
        ...mapActions(['getAdmin', 'deleteAdmin'])
    }
}
</script>