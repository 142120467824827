<template>
    <div class="chart-lines">
        <slot/>
        <ApexChart :type="type" :options="options" :series="datasets"/>
    </div>
</template>

<script>

export default {
    name: 'ChartLines',
    props: {
        datasets: Array,
        axis: Array,
        type: {
            type: String,
            default: 'area'
        }
    },
    computed: {
        options() {
            return {
                ...this.parameters,
                xaxis: {
                    categories: this.axis
                }
            }
        },
    },
    data() {
        return {
            parameters: {
                colors: ['#5ccae6', '#c573ff', '#e28f2d', '#5f89e8', '#edcd1d', '#44c699', '#EA6138', '#E8F3DA', '#FDE0D9', '#85847A', '#ff73e7', "#73fff6", "#617c55", "#debadf"],
                chart: {
                    height: 500,
                    type: this.type,
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    }
                },
                fontFamily: 'HKNova, sans-serif',
                plotOptions: {
                    bar: {
                        endingShape: 'rounded',
                        dataLabels: {
                            position: 'top',
                        }
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: this.type === 'bar' ? {
                    show: true,
                    width: 1,
                    colors: ['#fff']
                } : {
                    width: 3,
                    curve: 'smooth'
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'left'
                },
                grid: {
                    row: {
                        colors: ['transparent'],
                        opacity: 0.5
                    },
                },
                yaxis: [
                    {
                        labels: {
                            formatter: (val) => val && val % 1 !== 0 ? val.toFixed(2) : val
                        }
                    }
                ]
            },
        }
    }
}
</script>

<style lang="scss">
.chart-lines {
    position: relative;

    .apexcharts-legend {
        gap: 30px;

        span {
            font-family: "HKNova", sans-serif !important;
            font-size: 1.3rem !important;
        }

        .apexcharts-legend-marker {
            width: 16px !important;
            height: 16px !important;
            margin-right: 5px !important;
            vertical-align: bottom;
        }
    }

    .apexcharts-toolbar {
        padding: 5px !important;
        right: 5px !important;
        top: 5px !important;
    }

    .apexcharts-xaxistooltip {
        display: none;
    }

    .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
        background-color: var(--color-bg-light);
    }

    text {
        font-family: "HKNova", sans-serif !important;
        font-size: 1.1rem !important;
    }

    .field {
        margin: 0;
        width: 120px;
        position: absolute;
        top: 20px;
        right: 30px;
        z-index: 3;
    }
}
</style>