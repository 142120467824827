<template>
    <TemplateTable :title="$t('menu.recom_subscribers')" :text="$t('recom_subscribers.description')" name="recomSubscribers"
        :url="$listRoutes.recomSubscribers" :table="recomSubscribers" :filters="recomSubscribersFilters" :fields="fields"
        :search="true" />
</template>

<script>
import { mapState } from 'vuex';
import TemplateTable from '@/components/templates/TemplateTable';

export default {
    name: 'RecomSubscribersPage',
    components: { TemplateTable },
    data() {
        return {
            fields: [
                { key: 'client', type: 'client', size: 'l' },
                { key: 'email', type: 'userEmail', size: 'xl' },
                { key: 'gender', type: 'gender', size: 'm' },
                { key: 'createdAt', type: 'date', size: 'm', translationKey: 'subscribed_date' },
                { key: 'locale', type: 'flag', size: 'm' },
                { key: 'country', size: 's' }
            ]
        }
    },
    computed: {
        ...mapState(['recomSubscribers', 'recomSubscribersFilters'])
    }
}
</script>