<template>
    <div class="fields-mapper">
        <scroll-sync :group="`${_uid}`" horizontal :proportional="true" class="fields-mapper__header" ref="thead">
            <div 
                v-for="(info,index) in infos" :key="index">
                <strong :class="`fields-mapper__is-size--${info.size || 's'}`">{{info.name}}</strong>
            </div>
        </scroll-sync>
        <CatalogueFieldMapper  
            v-for="(value,index) in values"
            :key="value.id || index"
            :value="value"
            :valueKeyToDisplay="valueKeyToDisplay"
            :model="model"
            :size="size"
            :disabled="disabled"
            :options="options"
            :isOptional="isOptional"
            :editable="editable"
            @change="e => $emit('change',e) "
            @delete_key_of_model="e => $emit('delete_key_of_model',e)"
        />
    </div>
</template>

<script>
import CatalogueFieldMapper from './CatalogueFieldMapper.vue';
export default {
    name:'CatalogueFieldsMapper',
    components: {
        CatalogueFieldMapper,
    },
    props: {
        values:Array,
        valueKeyToDisplay:String,
        options:Array,
        model:Object,
        editable:{
            type: Boolean,
            default:false
        },
        infos:{},
        disabled:{
            type: Boolean,
            default:false
        },
        isOptional:{
            type: Boolean,
            default:false
        },
        
    },
    data() {
        return {
            
        }
    },
    computed: {
        size() {
            return this.infos.map(elem => elem.size)
        }
    },
    methods: {
        // emitKeyToDelete(e) {
        //     this.$emit('send_key_to_delete',e)
        // }
    },
}
</script>

<style lang="scss" scoped>
.fields-mapper {
    border-radius: var(--border-radius-2);
    &__header  {
        background-color: var(--color-bg-grey);
        position: sticky;
        top: 10px;
        z-index: 1;
        padding: 15px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        gap: 20px;
        & div {
            display: flex;
            align-items: center;
        }
        @media screen and (max-width:800px){ 
            flex-direction: column;
            & div {
                justify-content: center;
            }
        }
    }
    

    &__is-size--m {
        width: 90px;
    }

    &__is-size--l {
        width: 240px;
    }
    
}
</style>
<style lang="scss">
.fields-mapper {
    &__is-size--s {
        width: 45px;
    }
    &__is-size--m {
        width: 90px;
    }

    &__is-size--l {
        width: 240px;
    }
}
</style>
