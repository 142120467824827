<template>

    <TemplateConfirmation
            v-if="isConfirm"
            :title="$t('password_reset.title')"
            :text="$t('password_reset.success')"
            :button="$t('password_reset.back')"
            :to="{name: 'home'}"/>

    <Form v-else
          :center="true"
          :title="$t('password_reset.title')"
          :text="$t('password_reset.subtitle')"
          :fields="fields"
          :action="resetPassword"
          @success="isConfirm = true">

        <LinkUnderline :to="{name: 'home'}">{{ $t('password_reset.back') }}</LinkUnderline>

    </Form>
</template>

<script>
import Form from '@/components/ui/form/Form';
import { mapActions } from 'vuex';
import LinkUnderline from '@/components/ui/link/LinkUnderline';
import TemplateConfirmation from '@/components/templates/TemplateConfirmation';
export default {
    name: 'PasswordReset',
    components: {TemplateConfirmation, LinkUnderline, Form},
    data() {
        return {
            isConfirm: false,
            fields: [
                {name: 'password', type: 'password', required: true},
                {name: 'passwordConfirm', type: 'password', required: true},
            ]
        }
    },
    methods: {
        ...mapActions(['resetPassword'])
    }
}
</script>