<template>

    <TemplateDetails :data="catalogues" :loading="cataloguesLoading" :getFunction="fetchInitialData">
    
        <PageBackWithLink :to="{name:'client'}" :text="$t('global.back')"/>
 
        <PageHeader :title="title">
            <template v-slot:actions>
                <ButtonLink icon="settings" size="m" color="grey" :to="{name:'config-website-catalogues-update'}">{{$t('catalogue.update_catalogue')}}</ButtonLink>

                <ButtonLink 
                    v-if="catalogue_isDefault?.[0]?.id?.length" 
                    icon="wallet" 
                    size="m" 
                    color="grey" 
                    :to="{name:'config-website-cohorts-create', params:{id:id, catalogueID: catalogueIDForPricing ,lang:merchandisingDefaultLang}}">{{$t('catalogue.update_pricing')}}</ButtonLink>

                <ButtonLink 
                    icon="tshirt"
                    size="m"
                    color="grey"
                    :to="{name:'config-website-merchandising'}">{{$t('catalogue.update_merchandising')}}</ButtonLink>

                <ButtonLink v-if="areCataloguesAvailable" icon="tshirt" size="m" color="grey" :to="{name:'config-website-catalogue-product'}">WIP MERCH</ButtonLink>
            </template>
        </PageHeader>
        
        <div v-if="areCataloguesAvailable && apiLanguage.length">

            <PageFilters>
                <template v-slot:left>
                    <Field type="select" :options="languageOptionsForSelect" :model="apiLanguage" @change="apiLanguage = $event"/>
                </template>
            </PageFilters>

            <PageFilters>
                <template v-slot:left>
                    <Field class="template-table__search" :placeholder="quickSearchPlaceholder" type="text" :model="searchQuery" :label="$t('global.search')" @change="searchQuery = $event"/>
                </template>
                <template v-slot:right>
                    <Button icon="download" :loading="loading" size="m" color="black" @click.native.prevent="downloadCatalogue">{{ downloadButtonLabel }}</Button>
                    <Button icon="download" :loading="loading" size="m" color="black" @click.native.prevent="downloadBrandPricing">Télécharger le pricing</Button>
                    <FiltersButton size="m" color="black" @click.native.prevent="toggleFiltersVisibility">{{ $t('global.filter') }}</FiltersButton>
                </template>
            </PageFilters>

            <CatalogueFilters
                v-if="!catalogueCollectionMappedFieldsFromSchemaLoading"
                :datas="catalogueCollectionMappedFieldsFromSchema"
                :defaultLocale="defaultLocaleForMarket"
                :apiLanguage="apiLanguage"
                :show="areFiltersVisible"
                @close="toggleFiltersVisibility"
                />

            <TableSticky
                :data="catalogueProductsByLangItemsConverted"
                :fields="activeCatalogueFields"
            />

            <CataloguePagination 
                :limit="limit"
                :offset="offset"
                :total="catalogueProductsByLangTotalItems"
                :optionsAvailable="[10, 20, 30, 50, 100]"
                @change="e => handlePaginationUpdate(e)"
                />
            
        </div>
    
    </TemplateDetails>
</template>
<script>
import _ from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import TemplateDetails from '@/components/templates/TemplateDetails.vue';
import PageHeader from '@/components/ui/page/PageHeader';
import Button from '@/components/ui/button/Button.vue';
import ButtonLink from '@/components/ui/button/ButtonLink';
import FiltersButton from '@/components/ui/filters/FiltersButton.vue';
import PageBackWithLink from '@/components/ui/page/PageBackWithLink.vue';
import Field from '@/components/ui/form/fields/Field.vue';
import PageFilters from '@/components/ui/page/PageFilters';
import TableSticky from '@/components/ui/table-sticky/TableSticky.vue';
import CataloguePagination from '@/components/ui/catalogue/CataloguePagination.vue';
import CatalogueFilters from '@/components/ui/catalogue/CatalogueFilters.vue';


export default {
    name: 'ClientCatalog',
    components: {
        TemplateDetails,
        PageBackWithLink,
        Button,
        ButtonLink,
        FiltersButton,
        PageHeader,
        Field,
        PageFilters,
        TableSticky,
        CataloguePagination,
        CatalogueFilters,
    },
    data() {
        return {
            apiLanguage:'',
            searchQuery: this.$route.query.search || '' ,
            loading:false,
            limit: Number(this.$route.query.limit || 10),
            offset: Number(this.$route.query.offset || 0),
            areFiltersVisible:false
        }
    },
    watch: {
        lang: {
            handler: _.debounce(function (newValue) {
                if (newValue) this.apiLanguage = this.lang;
            }, 300),
            immediate: true,
        },
        apiLanguage:{
            handler(newValue){
                if(newValue && newValue.length) {
                    this.resetCatalogueFilters()
                    this.loadCatalogueData()
                    if(newValue) this.catalogueCollectionGetMappedFieldsFromSchema({id:this.id, lang:newValue});
                }
            }
        },
        cataloguesExists:{
            handler(newValue){
                if(newValue) {
                    // this.catalogueGetAllFields(this.id)
                    if(this.catalogue_isDefault.length > 0){
                        this.fetchMerchandisingDatas(this.id)
                            .catch(err => {
                                this.setNotificationMessage( {text:this.$t(err.response.data), color:'red', confirmation: true});
                        })
                    }
                }
            }
        },

        areCataloguesAvailable:{
            handler(newValue) {
                if (newValue) {
                    this.handleCataloguesExistence(); 
                }
            }
        },
        payloadForResearch: {
            handler: _.debounce(function (newValue,oldValue) {
                if(newValue.lang?.length && Object.keys(newValue)?.length && JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                    this.searchActiveCatalogue(newValue)
                }
            }, 500),
        },
        quickSearchParameters: {
            handler: _.debounce(function (newValue) {
                if(newValue && this.defaultActiveLanguage?.length) {
                    this.offset = 0;
                    this.searchActiveCatalogue(this.payloadForResearch)
                }
            }, 500),
        },
        limitToSend:{
            handler(newValue) {
                this.updateQueryParametersAndSearch('limit', newValue);
            }
        },
        offsetToSend:{
            handler(newValue) {
                this.updateQueryParametersAndSearch('offset', newValue);
            }
        },
    },
    computed: {
        ...mapState([
            'catalogues',
            'cataloguesLoading',
            'configMarkets',
            'catalogueProductsByLang',
            'merchandisingData',
            //'catalogueFields',
            'catalogueCollectionMappedFieldsFromSchema',
            'catalogueCollectionMappedFieldsFromSchemaLoading',
            'catalogueFieldsListValues',
            'catalogueFilters',
            'merchandisingDefaultLang',
            'merchandisingDefaultLangLoading'
        ]),
        filteredCatalogueSearchCriteria(){
            return this.catalogueFilters.reduce((acc,elem) => {
                if (elem.name?.length && (elem?.value || elem?.textValue)) {
                    if(elem.type=== 'value_select') {
                        acc[elem.name] = {value:elem.value, operator:elem.operator}
                    } else {
                        acc[elem.name] = {value:elem.textValue,operator:elem.operator}
                    }
                }
                return acc
            },{})
        },
        ...mapGetters([
            'allActiveCatalogues',
            'catalogue_isDefault'
        ]),
        id() {
            return this.$route.params.id
        },
        client() {
            return this.$store.getters.getClientById(this.id)
        },
        title(){
            return `${this.$t('catalogue.title')} ${this.client.name}`
        },

        /**
         * Langue pour le call API
         */

        defaultLocaleForMarket(){
            // Retourne la locale par défaut définie par le marché
            return this.configMarkets?.items.filter(market => market.isDefault).map(market => market.defaultLocale.code)[0] 
        },
        defaultActiveLanguage(){
            // Retourne la première langue des catalogues par defaut
            return this.allActiveCatalogues && Object.keys(this.allActiveCatalogues).length
                ? this.allActiveCatalogues.filter(activeCatalogue => activeCatalogue.lang)[0].lang
                : []
        },
        activeCatalogueLanguages(){
            // Retourne l'ensemble des langues des catalogues actifs
            return this.allActiveCatalogues
                ? this.allActiveCatalogues.map(activeCatalogue => activeCatalogue.lang)
                : ''
        },
        defaultLocale(){
            // Est ce qu'un des catalogues actifs contient la langue définie par le marché ?
            return this.activeCatalogueLanguages
                ? this.activeCatalogueLanguages.filter(lang => lang === this.defaultLocaleForMarket)
                : ''
        },
        lang(){
            // Si le catalogue actif contient la valeur définie par le marché on renvoie sa valeur sinon on choisit la première langue trouvée
            return  this.defaultLocale.toString() || this.defaultActiveLanguage
        },
        // END Langue pour le call API
       
        languageOptionsForSelect(){
            return this.activeCatalogueLanguages 
                ? this.activeCatalogueLanguages.map(item => ({ label: this.$t(`locales.${item.split('_')[0]}`), value: item }))
                : []
        },
        
        /**
         * Start Fields for table
         */

        // 
        tableHeadersFromTranslations(){
            return this.$t('catalogue.headers') 
        },
        tableHeadersFromTranslationsCharacteristic(){
            return this.tableHeadersFromTranslations.characteristic
        },

        // 
        activeCatalogueFields() {
            // Vérifie si obj1 et obj2 existent
            if (!this.tableHeadersFromTranslations || !this.merchandisingData) {
                // Retourne un tableau vide si l'un des objets n'existe pas
                return [];
            }

            let obj1 = this.tableHeadersFromTranslations; // Récupère l'ensemble des traductions
            let obj2 = this.merchandisingData; // Récupère l'ensemble le merchandisingData du store (criterias)

            const sizeSettings = {
                title: 'xl',
                description: 'l',
                sellable: 's',
                tradable: 's',
                size: 's',
                price: 's',
            };

            // Étendre pour inclure toutes les clés de merchandisingData, en utilisant les valeurs comme labels par défaut
            let allKeys = [...new Set([...Object.keys(obj2.identification), ...Object.keys(obj2.characteristic)])];

            let finalArray = allKeys.map(key => {
                let label = (obj1.identification && obj1.identification[key]) || 
                            (obj1.characteristic && obj1.characteristic[key]) || 
                            key; // Utilise la clé comme label si non trouvé dans les traductions
                const size = sizeSettings[key] || 'm';

                const labelCapitalized = label.charAt(0).toUpperCase() + label.slice(1);

                return {
                    key: key,
                    label: labelCapitalized,
                    type: (key === 'sellable' || key === 'tradable') ? 'boolean' : key,
                    size: size,
                    noTranslate: true
                };
            });

            // Ordre personnalisé
            const customOrderKeys = ['ean', 'sku', 'color', 'size', 'title', 'tradable', 'sellable', 'price'];

            // Fonction de tri
            finalArray.sort((a, b) => {
                let indexA = customOrderKeys.indexOf(a.key);
                let indexB = customOrderKeys.indexOf(b.key);

                if (indexA !== -1 && indexB !== -1) {
                    return indexA - indexB;
                } else if (indexA !== -1) {
                    return -1;
                } else if (indexB !== -1) {
                    return 1;
                } else {
                    return a.label.localeCompare(b.label);
                }
            });

            return finalArray;
        },

        quickSearchParameters(){
            return this.searchQuery.length 
                ? {
                    ean:{
                        value:this.searchQuery,
                        operator:'ct'
                    },
                    sku:{
                        value:this.searchQuery,
                        operator:'ct'
                    },
                    title:{
                        value:this.searchQuery,
                        operator:'ct'
                    },
                }
                : {}
        },
        areCataloguesAvailable(){
            return this.catalogues && Object.keys(this.catalogues).length > 0
        },
        quickSearchPlaceholder(){
            return this.$t('catalogue.quick_search_placeholder')
        },
        downloadButtonLabel(){
            return `${this.$t('catalogue.download')} : ${this.$t(`locales.${this.apiLanguage.split('_')[0]}`)}`
        },
        page(){
            return this.$route.query.page
        },
        limitToSend(){
            return this.limit
        },
        offsetToSend(){
            return this.offset
        },
        catalogueProductsByLangItemsConverted(){
            return this.catalogueProductsByLang?.items?.length > 0
                ? this.catalogueProductsByLang?.items.map(item => ({ ...item, sellable: parseInt(item.sellable, 10), tradable: parseInt(item.tradable, 10) }))
                : []
        },
        catalogueProductsByLangTotalItems(){
            return this.catalogueProductsByLang?.pagination
        },
        // filtersFromStateFormated() {
        //     return this.catalogueFilters.reduce((acc, filter) => {
        //         if (filter.type === 'value_select' && filter?.value) {
        //             acc[filter.name] = { operator: filter.operator, label: filter.value.label };
        //         } else {
        //             acc[filter.name] = { operator: filter.operator, textValue: filter.textValue };
        //         }
        //         return acc;
        //     }, {});
        // },
        payloadForResearch() {
            let condition = '';
            if (Object.keys(this.quickSearchParameters).length) {
                condition = 'or';
            } 
            if (Object.keys(this.filteredCatalogueSearchCriteria).length) {
                condition = 'and';
            }
            return {
                lang: this.apiLanguage,
                id: this.id,
                pagination :{
                    limit: this.limitToSend,
                    offset: this.offsetToSend,
                },
                ...(condition && { condition }),
                parameters: { ...this.quickSearchParameters, ...this.filteredCatalogueSearchCriteria }
            };
        },
        catalogueIDForPricing(){
            return this.catalogue_isDefault?.filter(elem => elem.lang === this.merchandisingDefaultLang)?.[0]?.id
        }

    },
    methods: {
        ...mapActions([
            'fetchCatalogues',
            'getMarketsConfigs',
            'searchActiveCatalogue',
            'fetchMerchandisingDatas',
            // 'catalogueGetAllFields',
            'catalogueDownload',
            'brandPricingDownload',
            'catalogueCollectionGetMappedFieldsFromSchema',
            'updateField',
            'setNotificationMessage',
            'fetchMerchandisingDefaultLang',
            'resetCatalogueFilters',
            'catalogueResetFieldListValues'
        ]),
        handlePaginationUpdate(pagination){
            this.limit = pagination.limit
            this.offset = pagination.offset
        },
        async fetchInitialData(){
            await this.catalogueResetFieldListValues()
            await this.fetchCatalogues(this.id)
            .catch(err => {
                this.setNotificationMessage( {text:this.$t(err.response.data), color:'red', confirmation: true});
            })
            await this.getMarketsConfigs({id:this.id})
            await this.fetchMerchandisingDefaultLang(this.id)
            
        },
        handleCataloguesExistence() {
            // this.catalogueGetAllFields(this.id);
            if (this.catalogue_isDefault.length > 0) {
                this.fetchMerchandisingDatas(this.id)
                    .catch(err => {
                        this.setNotificationMessage( {text:this.$t(err.response.data), color:'red', confirmation: true});
                    });
            }
        },
        loadCatalogueData(){
            if(this.areCataloguesAvailable && this.defaultActiveLanguage) {
                this.searchActiveCatalogue(this.payloadForResearch)
            }
        },
        downloadCatalogue(){
            this.loading = true
            this.catalogueDownload({lang:this.apiLanguage, id:this.id, client:this.client.name})
                .finally(()=> this.loading = false)
                .catch((err)=> {
                    console.log('there', err.response)
                    this.setNotificationMessage( {text:this.$t('catalog_error.catalog_not_found'), color:'red', confirmation: true});
                })
        },
        downloadBrandPricing(){
            this.loading = true
            this.brandPricingDownload({id:this.id, client:this.client.name})
                .finally(()=> this.loading = false)
                .catch((err)=> {
                    console.log('there', err.response)
                    this.setNotificationMessage( {text:this.$t('catalog_error.catalog_not_found'), color:'red', confirmation: true});
                })
        },
        toggleFiltersVisibility(){
            this.areFiltersVisible = !this.areFiltersVisible
        },
        updateQueryParametersAndSearch(param, newValue) {
            if (newValue !== undefined && newValue !== this.$route.query[param]) {
                let newQuery = { ...this.$route.query };
                newQuery[param] = newValue;
                this.$router.push({ query: newQuery });
                this.searchActiveCatalogue(this.payloadForResearch);
            }
        },

    },
    mounted () {
        this.apiLanguage = this.lang ? this.lang : '';
        if(!(this.$route.query.limit && this.$route.query.offset)) this.$router.push({query: {...this.$route.query, limit: this.limitToSend, offset:this.offset}})
    },


}
</script>
