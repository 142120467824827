<template>
    <PageError :title="$t('error.403.title')"
               :subtitle="$t('error.403.subtitle')"/>
</template>

<script>
import PageError from '@/components/ui/page/PageError';
export default {
    name: 'Error403Page',
    components: {PageError}
}
</script>