<template>
    <div class="table-sticky-row">
        <slot/>
    </div>
</template>

<script>
export default {
    name: 'TableStickyRow'
}
</script>

<style lang="scss">
.table-sticky-row {
    border-bottom: solid 1px var(--color-bg-light);

    &:nth-of-type(even) > * {
        background-color: var(--color-bg-very-light);
    }
}
</style>