<template>
    <div class="grid-calendar">
        <ul>
            <GridCalendarDate v-for="(field, i) in dates" :key="i" :date="object | fieldValue(field)" :title="field | fieldLabel"/>
            <slot/>
        </ul>
    </div>
</template>

<script>
import GridCalendarDate from '@/components/ui/grid/GridCalendarDate';
export default {
    name: 'GridCalendar',
    components: {GridCalendarDate},
    props: {
        dates: Array,
        object: Object
    }
}
</script>

<style lang="scss">
.grid-calendar {
    position: relative;

    &:before {
        content: '';
        display: block;
        width: 1px;
        height: 100%;
        z-index: 0;
        position: absolute;
        top: 0;
        left: 8px;
        border-right: var(--border-2);
    }

    ul {
        padding: 30px 0;
        display: flex;
        flex-direction: column;
        gap: 30px
    }
}
</style>