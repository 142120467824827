<template>
    <div class="merchandising-product-characteristics">

        <scroll-sync v-if="index===0" :group="`${_uid}`" horizontal :proportional="true" class="merchandising-product-characteristics__header" ref="thead">
            <div 
                class="merchandising-product-characteristics__cell" 
                :class="`is-size--${info.size || 's'}`"
                v-for="(info,index) in infos" :key="index">
                <strong>{{info.name}}</strong>
                <ToolTip v-if="info.infos" :infos="info.infos" />
            </div>
        </scroll-sync>
        <div class="merchandising-product-characteristics__line"> 

            <div class="merchandising-product-characteristics__cell" :class="`is-size--${infos[0].size || 's'}`">
                <strong>{{characteristics.name}}</strong>
                <span>{{ characteristics.label }}</span>
            </div>
            <Field class="merchandising-product-characteristics__cell" :class="`is-size--${infos[1].size || 's'}`" type="text" :model="alias" @change="e=> alias = e" />
            <Field class="merchandising-product-characteristics__cell" :class="`is-size--${infos[2].size || 's'}`" type="select" :options="activeOptions" :model="active" @change="e=> active = JSON.parse(e)"/>
            <Field class="merchandising-product-characteristics__cell" :class="`is-size--${infos[3].size || 's'}`" type="select" :options="tagsOrMetafieldsOptions" :model="tagsOrMetafield" @change="e => tagsOrMetafield = e"/>
            <Button :disabled="!characteristics_is_not_identification" class="merchandising-product-characteristics__cell" :class="`is-size--${infos[4] || 's'}`" @click.prevent.native="openPopin" :icon="doesNameExistInCategories ? 'edit' : 'plus'" color="grey" size="l">{{ doesNameExistInCategories ? $t('merchandising.edit_property') : $t('merchandising.add_property') }}</Button>
        </div>
        <Popin 
            class="merchandisingPopin" 
            :large="true" 
            :title="`${this.$t('merchandising.property_linked_to_field')} ${this.characteristics.name}` " 
            :subtitle="subtitle"
            ref="popinEditPropertiesCatalogue"
        >
            <template v-if="loading" v-slot:content>
                <MerchandisingFieldsMapper 
                    :categories="merchandisingCategories"
                />
            </template>
        </Popin>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Button from '@/components/ui/button/Button.vue';
import Popin from '@/components/ui/popin/Popin.vue';
import MerchandisingFieldsMapper from './MerchandisingFieldsMapper.vue';
import Field from '@/components/ui/form/fields/Field.vue';
import ToolTip from '@/components/ui/tootip/ToolTip.vue';

export default {
    components: {
        Button,
        Popin,
        Field,
        MerchandisingFieldsMapper,
        ToolTip,
    },
    props: {
        characteristics:  Object,
        index:Number,
        existingDatas: Object
    },
    data() {
        return {
            name: this.characteristics.name,
            alias: '',
            active: null,
            tagsOrMetafield: '',
            activeOptions: [
                {label:"Actif", value:'true'},
                {label:"Inactif", value:'false'}
            ],
            tagsOrMetafieldsOptions: [
                {label:"Metafields", value:'metafields'},
                {label:"Tags", value:'tags'},
                {label:"Metafields, Tags", value:'both'}
            ],
        }
    },
    watch: {
        // value: 'emitData',
        alias: {
            handler(newValue){
                if(newValue.length > 0){
                    this.active = true
                }
                if(newValue === ''){
                    this.active = false
                }
            }
        },
        active:'emitData'
    },
    computed:{
        ...mapState(['merchandisingCategories','merchandisingCategoriesLoading','merchandisingTagCategories']),
        id() {
            return this.$route.params.id
        },
        value(){ 
            return {
                name: this.name,
                alias: this.alias,
                active: this.active,
                tagsOrMetafield: this.tagsOrMetafield,
            }
        },
        loading(){
            return !this.merchandisingCategoriesLoading
        },
        doesNameExistInCategories() {
            const nameToFind = this.characteristics.name;
            const categories = this.merchandisingTagCategories;
            if(nameToFind && categories){
                return Object.values(categories).some((langCategories) =>
                    langCategories.some((category) => category.name.includes(nameToFind))
                );
            }
            return null
        },
        subtitle(){
            return this.alias ? `${this.$t('merchandising.popin_new_label')} ${this.alias}` : null
        },
        infos() {
            return [
                {name:`${this.$t('merchandising.characteristics_name_0')}`, infos:`${this.$t('merchandising.characteristics_infos_0')}`,size:'xxl'},
                {name:`${this.$t('merchandising.characteristics_name_1')}`, infos:`${this.$t('merchandising.characteristics_infos_1')}`,size:'xl'},
                {name:`${this.$t('merchandising.characteristics_name_2')}`, infos:`${this.$t('merchandising.characteristics_infos_2')}`,size:'xl'},
                {name:`${this.$t('merchandising.characteristics_name_3')}`, infos:`${this.$t('merchandising.characteristics_infos_3')}`,size:'xl'}
            ]
        },
        isTypeIsIdentification(){
            return this.characteristics.type === 'identification'
        },
        existingTags(){
            return this.existingDatas?.merchandising?.tags[this.characteristics.name]
        },
        existingMetafields(){
            return this.existingDatas?.merchandising?.metafields[this.characteristics.name]
        },
        existingTagsOrMetafield(){
            
            const tagsActive = !!this.existingTags;
            const metafieldsActive = !!this.existingMetafields

            if (tagsActive && metafieldsActive || this.isTypeIsIdentification) return 'both';
            if (tagsActive) return 'tags';
            if (metafieldsActive) return 'metafields';
            

            return 'metafields';  // or any default value you want to use if neither is active
        },
        existingAlias(){
            const tagsAlias = this.existingTags?.alias;
            const metafieldsAlias = this.existingMetafields?.alias;

            return tagsAlias || metafieldsAlias
        },
        existingState(){
            if(this.existingAlias?.length > 0){
                const tagsActive = !!this.existingTags?.active;
                const metafieldsActive = !!this.existingMetafields?.active;

                if(tagsActive && metafieldsActive) return true
                if(tagsActive) return  tagsActive 
                if(metafieldsActive) return metafieldsActive 
            }
            if(this.isTypeIsIdentification) return true
            return false;

        },
        characteristics_is_not_identification(){
            return this.characteristics.type !== 'identification'
        }
    },
    methods: {
        ...mapActions(['fetchMerchandisingCategories','fetchMerchandisingTagCategories']),
        openPopin(){
            this.$refs.popinEditPropertiesCatalogue.open()
            this.getCategories({id:parseInt(this.id),name:this.characteristics.name});
        },
        emitData() {
            // console.log('mpc',this.value)
            this.$emit('update-parent-model', this.value );
        },
        getCategories(payload){
            this.fetchMerchandisingCategories(payload)
        },
    },
    created () {
        this.fetchMerchandisingTagCategories(this.id)
        this.active = this.existingState
        this.tagsOrMetafield = this.existingTagsOrMetafield
        this.alias = this.existingAlias || '';
    },
    updated () {
        this.emitData(this.value);
    },

}
</script>

<style lang="scss" scoped>
.merchandising-product-characteristics {
    position: relative;
    z-index: 0;
    &__header  {
        background-color: var(--color-bg-grey);
        position: sticky;
        top: 10px;
        z-index: 1;
        margin-bottom: 10px;
        padding: 15px;
        border-radius: 4px;
        display: flex;
        gap: 20px;
        & div {
            display: flex;
            align-items: center;
        }
    }

    &__line {
        display: flex;
        gap: 20px;
        align-items: center;
        padding: 15px;
        border-bottom: var(--border-1);
        border-radius: 4px;
        background: var(--color-bg-light);

        .field {
            margin-top: 0;
        }

        & > div {
            &::first-letter {
                text-transform: uppercase;
            }
            strong {
                margin-right: 10px;
            }
            span {
                font-style: italic;
            }

        }
    }

    &__cell {
        position: relative;

        &.is-size--xs {
            width: 50px;
        }

        &.is-size--m {
            width: 150px;
        }

        &.is-size--l {
            width: 200px;
        }

        &.is-size--xl {
            width: 250px;
        }

        &.is-size--xxl {
            width: 300px;
        }
    }


    @media screen and (max-width: 901px) {
        &__header {
            display: none;
        }
        &__line {
            flex-direction: column;
            align-content: center;
        }

        &__cell {
            &.is-size--xs,
            &.is-size--m,
            &.is-size--l,
            &.is-size--xl,
            &.is-size--xxl {
                width: initial;
            }
        }
        
    }

}

</style>