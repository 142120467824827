import API from '@/api/api-admin';
import datasMonitoringAPI from '@/api/api-datamonitoring';

const apiMapping = {
    'monitoringDatas': datasMonitoringAPI
};

const state = {
    monitoringDatas: null,
    monitoringDatasLoading: null
}

const mutations = {
    SET_MONITORING_DATAS(state, data) {
        state[`${data.name}`] = {...data.value};
    },
    SET_MONITORING_DATAS_LOADING(state, data) {
        state.monitoringDatasLoading = data;
    }
}

const actions = {
    getMonitoringDatas({commit}, data) {
        const url = `${data.url}`;
        const currentApi = apiMapping[data.name] || API;
        commit('SET_MONITORING_DATAS_LOADING', true)
        
        currentApi.get(url)
            .then(res => {
                if (res && res.data) {
                    if (!res.data.items) res.data = {
                        items: res.data
                    }
                    commit('SET_MONITORING_DATAS', {
                        name: data.name,
                        value: res.data
                    })
                }
            })
            .catch(err => {
                commit('SET_MONITORING_DATAS', {
                    name: data.name,
                    value: []
                });
                console.error('Impossible de récupérer la table', err)
            })
            .finally(() => {
                commit('SET_MONITORING_DATAS_LOADING', false)
            })
    }
}

const getters = {
    getCurrentMonitoringDataById: state => id =>{
        if (state.monitoringDatas !== null) {
            return state.monitoringDatas.items.filter(elem => elem.id === id)[0];
        } else {
            return null
        }
    },
}

const monitorings =  {
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default monitorings