<template>
    <div class="photos-manager" :class="{'is-disabled': disabled}">
        <draggable v-model="localPhotos" tag="ul" :animation="300" group="photos" @change="emitPhotosUpdate()">
            <li v-for="(photo, i) in localPhotos" :key="`photo-${photo}`" class="photo-item" :class="{'is-error': errors.includes(photo)}">
                <img :src="photo" :alt="photo?.split('/').pop() || `Photo ${i + 1}`" @error="errors.push(photo)"/>
                <Button icon="hide" size="s" color="white" :bigIcon="true" @click.native.prevent="removePhoto(i)" />
            </li>
        </draggable>
        <div v-if="deletedPhotos?.length" class="photos-manager__deleted">
            <GridHeader title="Photos supprimées" size="xs">
                <Button
                        @click.native.prevent="displayDeletedPhotos = !displayDeletedPhotos"
                        :icon="displayDeletedPhotos ? 'minus' : 'plus'"
                        size="s"
                        color="grey"
                        :bigIcon="true"
                />
            </GridHeader>
            <ul v-if="displayDeletedPhotos">
                <li v-for="(photo, i) in deletedPhotos" :key="`deleted-photo-${photo.url}`" class="deleted-photo-item" :class="{'is-error': photo?.url && errors.includes(photo.url)}">
                    <img :src="photo?.url" :alt="photo?.url?.split('/').pop() || `Deleted Photo ${i + 1}`"  @error="errors.push(photo?.url)"/>
                    <Button icon="show" size="s" color="white" :bigIcon="true" @click.native.prevent="restorePhoto(photo.url)" />
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import Button from "@/components/ui/button/Button.vue";
import GridHeader from '@/components/ui/grid/GridHeader.vue';

export default {
    name: 'ArticlePhotos',
    components: {
        draggable,
        Button,
        GridHeader
    },
    props: {
        photos: {
            type: Array,
            required: true
        },
        deletedPhotos: {
            type: Array,
            required: false,
            default: () => []
        },
        disabled: Boolean
    },
    data() {
        return {
            localPhotos: [...this.photos],
            displayDeletedPhotos: false,
            errors: []
        };
    },
    watch: {
        photos: {
            handler(newPhotos) {
                this.localPhotos = [...newPhotos];
            },
            immediate: true
        }
    },
    methods: {
        removePhoto(index) {
            this.localPhotos.splice(index, 1);
            this.emitPhotosUpdate();
        },
        restorePhoto(photoUrl) {
            if (!this.localPhotos.includes(photoUrl)) {
                this.localPhotos.push(photoUrl);
            }
            this.emitPhotosUpdate();
        },
        emitPhotosUpdate() {
            this.$emit('updatePhotos', this.localPhotos);
        }
    }
};
</script>

<style lang="scss" scoped>
.photos-manager {
    @keyframes animPhotosLoading {
        from {
            opacity: .5;
        }
        to {
            opacity: .3;
        }
    }
    &.is-disabled {
        pointer-events: none;
        animation: animPhotosLoading ease infinite alternate-reverse .5s;
    }

    ul {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 10px;
        margin-bottom: 20px;
    }

    .photo-item {
        cursor: grab;
    }

    .photo-item,
    .deleted-photo-item {
        position: relative;
        border: var(--border-1);
        border-radius: var(--border-radius-1);
        overflow: hidden;
        background-color: var(--color-bg-light);
        aspect-ratio: .75;

        &.is-error {
            border-color: #f8caca;
            background: #fff9f9;

            img::before {
                content: attr(alt);
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                color: #000;
                text-align: center;
                visibility: visible;
                font-size: 10px;
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
        }

        button {
            position: absolute;
            top: 5px;
            right: 5px;
            z-index: 2;
            border: var(--border-1);
        }
    }

    &__deleted {
        margin-top: 20px;
        padding: 15px;
        border-radius: var(--border-radius-1);
        border: var(--border-1);
        overflow: hidden;
        gap: 10px;

        .grid-header:not(:last-child) {
            margin-bottom: 0;
        }

        ul {
            margin: 10px 0 0;
        }
    }
}
</style>
