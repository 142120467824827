import axios from 'axios';

const PublicAPI = axios.create({
    baseURL: `${process.env.VUE_APP_API}/api/public/`,
    headers: {
        Accept: "application/json",
        'Content-Type': 'application/json'
    },
});

export default PublicAPI