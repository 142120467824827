<template>
    <div>
        <!-- <label class="field has-border" for="id.name" v-for="(id,index) in values" :key="index">
            <span class="field__input">
                <input type="text" name="id.name" disabled id="id.label" :placeholder="id.label">
            </span>
            <span class="field__label is-up">{{ id.name }}</span>
        </label> -->
        <Field 
            v-for="(id,index) in values" :key="index"
            :model="id.label" 
            type="text" 
            :label="id.name" 
            :disabled="true" 
            :border="true"/>
    </div>
</template>

<script>
import Field from '@/components/ui/form/fields/Field.vue';
export default {
    components: {
        Field
    },
    props: {
        values: Array
    },
}
</script>