<template>
    <div class="field-search">
        <TextSimple v-if="text" size="s" weight="bold">{{ $t(`${text}`) }}</TextSimple>
        <div class="field-search__message" v-if="doSearchTextExists && !doResultsMatchesSearchText">
            {{ infos }}
        </div>
        
        <div class="field-search-container">
            <div class="field-search-container__inputs">
                <Field 
                    :border="true"  
                    type="text" 
                    :model="searchText" 
                    @change="searchText = $event"
                />
                
                <div v-if="showDropdown" class="field-search__list" :class="{'is-visible': showDropdown}">
                    <ul>
                        <li v-for="(choice, index) in tagsFilteredBySearchText" :key="index">
                            <span :class=" choice.disabled && 'disabled'" @click="selectChoice(choice.tag)" class="field-search__list-value">
                                {{ choice.tag }}
                            </span>
                            <Button 
                                v-if="choice.showInDropdown"
                                class="xs"
                                :disabled="choice.fromCollection"
                                size="s" 
                                :color="choice.fromCollection ? 'black' : 'red'" 
                                @click.native.prevent="sendDatasToModal(choice.tag)"
                                >{{ choice.fromCollection ? $t('articles.delete_impossible') : $t('articles.delete_definitely') }}
                            </Button>
                        </li>
                    </ul>
                </div>
            </div>
            <Button 
                v-if="conditions !== 'from_collections'"
                :disabled="!doSearchTextExists || doResultsMatchesSearchText"
                size="m" 
                color="black" 
                @click.native.prevent="addNewChoice(searchText)" 
                icon="plus"></Button>
        </div>
    </div>
</template>

<script>
import TextSimple from '../text/TextSimple.vue';
import Field from '@/components/ui/form/fields/Field.vue';
import Button from '@/components/ui/button/Button.vue';
import { mapActions } from 'vuex';
export default {
    name: 'CustomTags',
    components: {
        TextSimple,
        Field,
        Button
    },
    props: {
        text:String,
        infos:String,
        datas: Object,
        tags: Array,
        defaultSearchText: String,
        conditions: String
    },
    watch: {
        searchText(newValue, oldValue) {
            if(this.conditions === 'from_collections') this.emitData(newValue)
            if(newValue.length === 0 || newValue.length < oldValue.length) this.hideDropDownOnSelectChoice = false
        },
        
    },
    data() {
        return {
            searchText: this.defaultSearchText || '',
            hideDropDownOnSelectChoice: this.defaultSearchText?.length ? true :false,
            shallowCopyTags: this.tags
        }
    },
    computed: {
        fromProduct(){
            return this.conditions === 'from_product'
        },
        fromCollections(){
            return this.conditions === 'from_collections'
        },
        // ean(){
        //     return this.fromCollections
        //         ? this.datas.ean
        //         : false
        // },
        tagsFilteredBySearchText(){
            return this.doSearchTextExists && this.shallowCopyTags?.filter(elem =>  elem.tag && typeof elem.tag === 'string' && elem.tag.includes(this.searchText))
        },
        doSearchTextExists(){
            return !!this.searchText?.length
        },
        doResultsExists(){
            return !!this.tagsFilteredBySearchText?.length
        },
        doResultsMatchesSearchText(){
            return this.doSearchTextExists && this.tagsFilteredBySearchText?.some(elem => elem?.tag === this.searchText)
        },
        showDropdown(){
            return this.doSearchTextExists && this.doResultsExists && !this.hideDropDownOnSelectChoice;
        },  
    },
    methods: {
        ...mapActions([
            'catalogueCollectionUpdateTags',
            'getAllCustomTags',
            'postCustomTag'
        ]),
        selectChoice(choice){
            console.log('selectChoice',choice)
            // const areTagsAndEanPresent = this.localChoices.filter(elem => elem.tag === choice && elem.ean === this.ean)?.length
            // if(areTagsAndEanPresent === 0) this.addNewChoice(choice)
            if(this.conditions === 'from_product') this.addNewChoice(choice)
            this.hideDropDownOnSelectChoice = true
            this.searchText = choice
        },
        emitData(data){
            this.searchText = data
            this.catalogueCollectionUpdateTags(data)
        },
        addNewChoice(searchText) {
            // Ajoute une valeur personnalisée aux choix
            if (!this.shallowCopyTags.includes(searchText)) {
                this.postCustomTag({
                    tag:searchText,
                    ean:this.datas.ean,
                    brandID:this.datas.brandID,
                    id:this.datas.articleID,
                    state:this.datas.state
                })
            }
            this.searchText = searchText;
        },
        sendDatasToModal($event){
            console.log('sendDatasToModal',$event)
            this.$emit('sendDatas', $event)
        }
    },
    updated () {
        this.shallowCopyTags= this.tags;
    },
}
</script>

<style lang="scss" scoped>
.field-search {
    margin-top: 10px;
    &__message {
        margin-top: 10px;
    }

    &-container{
        margin: 10px 0;
        display: flex;
        align-items: center;
        gap: 10px;
        &__inputs {
            position: relative;
            width: 100%;
            & > * {
                margin-top: 0;
            }
        }
    }
    &__list {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: #fff;
        border-radius: var(--border-radius-2);
        z-index: 6;
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        max-height: 300px;
        overflow-y: auto;
        box-shadow: var(--box-shadow-2);
        transition: opacity ease .3s, transform ease .3s;

        &:not(.is-visible) {
            opacity: 0;
            pointer-events: none;
            transform: translateY(10px);
        }

        &::-webkit-scrollbar {
            width: 5px;
            border-radius: 3px;
            overflow: hidden;
        }

        &::-webkit-scrollbar-track {
            background: var(--color-bg-grey);
            border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb,
        &::-webkit-scrollbar-thumb:hover {
            background: var(--color-text-grey);
            border-radius: 10px;
        }
        ul {
            width: 100%;
            li {
                display: flex;
                min-width: fit-content;
                justify-content: space-between;
                align-items: center;  
            }
        }
        &-value {
            display: flex;
            align-items: center;
            padding: 0 var(--field-padding);
            height: var(--field-height);
            border-radius: var(--field-height);
            flex: 1;
            justify-content: space-between;
            cursor: pointer;
            transition: background-color ease .3s;
            &:after {
                content: '+';
                font-size: 2rem;
                color: var(--color-green);
                opacity: 0;
                transition: opacity ease .3s;
                margin-left: 5px;
            }
            &.disabled {
                opacity: .5;
                pointer-events: none;
            }
            &:hover {
                background-color: var(--color-green-light);
                &:after {
                    opacity: 1;
                }
            }
            &+button {
                position: relative;
                z-index: 12;
                &.xs {
                    transform: scale(.8);
                }
            }
        }
        
    }
}
</style>
