<template>
    <router-link :to="to" class="user-card" :class="{'has-bg': background}">
        <img :src="image" :alt="name">
        <div>
            <TextSimple size="s">{{ fullname }}</TextSimple>
            <TextSimple size="xs" color="grey">{{ email }}</TextSimple>
            <TextSimple size="xs" color="grey" v-if="info">{{ info }}</TextSimple>
            <slot/>
        </div>
    </router-link>
</template>

<script>
import TextSimple from '@/components/ui/text/TextSimple';
export default {
    name: 'UserCard',
    components: {TextSimple},
    props: {
        name: String,
        email: String,
        firstname: String,
        lastname: String,
        info: String,
        background: Boolean,
        image: {
            type: String,
            default: require('../../../assets/images/user-profile.svg')
        },
        to: Object
    },
    computed: {
        fullname() {
            if (this.name) return this.name.trim();
            let name = '';
            if (this.firstname) name += ` ${this.firstname}`;
            if (this.lastname) name += ` ${this.lastname}`;
            return name.trim()
        }
    }
}
</script>

<style lang="scss">
.user-card {
    display: flex;
    align-items: center;
    line-height: 1.4;
    text-align: left;
    text-decoration: none !important;

    &.has-bg {
        background-color: #fff;
        width: fit-content;
        padding: 10px 20px 10px 10px;
        border-radius: var(--border-radius-2);
        border: var(--border-1);
        transition: border ease-out .2s;

        @media screen and (min-width: 1025px) {
            &:hover {
                border: var(--border-2);
            }
        }
    }

    img {
        width: 45px;
        height: 45px;
        margin-right: 15px;
        object-fit: cover;
        border-radius: 50px;
        overflow: hidden;
        flex-shrink: 0;
    }

    p {
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
</style>