import router from '@/router';

function extractFilenameFromHeader(response) {
    const contentDisposition = response.headers['content-disposition'];
    const filenameRegex = /filename[^;=\n]*=["']?([^"';\n]*)["']?/;
    const matches = filenameRegex.exec(contentDisposition);
    if (matches != null && matches[1]) {
        return matches[1];
    }
    return 'translations.xml'; // Fallback si le nom de fichier n'est pas trouvé dans l'en-tête
}

const getFiltersUrl = (forExport) => {
    let url = [];
    for (let query in router.currentRoute.query) {
        const key = query.split(':')[0];
        const operator = query.split(':')[1];
        const value = router.currentRoute.query[query];
        if (key === 'sort') {
            url.push(`sort[${value.split(':')[0]}]=${value.split(':')[1]}`);
        } else if (value && value !== '' && value !== 'null' && value !== 'undefined') {
            if (!operator) {
                if (!forExport || (key !== 'page' && key !== 'limit')) url.push(`${key}=${encodeURIComponent(value)}`)
            } else {
                url.push(`${key}[${operator}]=${encodeURIComponent(value)}`)
            }
        }
    }
    return url.join('&')
}

const getPagination = (totalCount, current = 1, numItemsPerPage = 10) => {
    const pageRange = 5;
    const pageCount = Math.ceil(totalCount / numItemsPerPage);
    let startPage = Math.max(1, current - Math.floor(pageRange / 2));
    let endPage = Math.min(pageCount, current + Math.floor(pageRange / 2));
    if (current <= Math.floor(pageRange / 2)) {
        endPage = Math.min(pageCount, startPage + pageRange - 1);
    } else if (current > pageCount - Math.floor(pageRange / 2)) {
        startPage = Math.max(1, endPage - pageRange + 1);
    }
    const pagesInRange = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    const currentItemCount = (current === pageCount) ? totalCount - ((current - 1) * numItemsPerPage) : numItemsPerPage;
    const firstItemNumber = ((current - 1) * numItemsPerPage) + 1;
    const lastItemNumber = firstItemNumber + currentItemCount - 1;
    return {
        last: pageCount,
        current,
        numItemsPerPage,
        first: 1,
        pageCount,
        totalCount,
        pageRange,
        startPage,
        endPage,
        next: (current < pageCount) ? current + 1 : null,
        pagesInRange,
        firstPageInRange: pagesInRange[0],
        lastPageInRange: pagesInRange[pagesInRange.length - 1],
        currentItemCount,
        firstItemNumber,
        lastItemNumber
    };
}

export {extractFilenameFromHeader, getFiltersUrl, getPagination}