<template>
    <Button
            :loading="loading"
            :to="isExternalLink ? null : to"
            :href="isExternalLink ? to : null"
            :size="size"
            :color="color"
            :icon="icon"
            :target="target"
            :border="border"
            :tag="tag">
        <slot/>
    </Button>
</template>

<script>
import Button from '@/components/ui/button/Button';
export default {
    name: 'ButtonLink',
    components: {Button},
    props: {
        loading: Boolean,
        to: [Object, String],
        size: String,
        color: String,
        icon: String,
        border: Boolean,
        openInSameWindow: Boolean
    },
    computed: {
        isExternalLink() {
            if (!this.to || typeof this.to === 'object') return false;
            return !!this.to.includes('https')
        },
        target() {
            return this.isExternalLink && !this.openInSameWindow ? '_blank' : null
        },
        tag() {
            return this.isExternalLink ? 'a' : 'router-link'
        }
    }
}
</script>