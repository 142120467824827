<template>
  <div class="catalogues-card__content">
    <div v-for="(line,index) in lines" :key="index">
        <strong>{{ fields[index] }} :</strong> {{Object.keys(line).includes('lang') ? `${$t('locales')[`${Object.values(line)[0].split('_')[0]}`]}` : Object.values(line)[0]}} </div>
  </div>
</template>

<script>
export default {
    props: {
        lines: {
            type: Array,
        },
    },
    data() {
        return {
            
        }
    },
    computed: {
        fields() {
            return [
                `${this.$t('catalogue.lang')}`,
                `${this.$t('catalogue.file_name')}`,
                `${this.$t('catalogue.last_updated_at')}`,
            ]
        }
    },
}
</script>

<style lang="scss" scoped>
.catalogues-card {
    &__content {
        border-radius: 3px;
        div {padding: 7px;}
        div:nth-child(odd) { background: var(--color-bg-very-light);}
        div:nth-child(even) { background: var(--color-bg-light);}

    }
}
</style>