<template>
    <div class="page-account">
        <PageHeader :title="$t('account.title')">
            <template v-slot:actions>
                <Button size="m" color="red" @click.native.prevent="logout" icon="logout">{{ $t('menu.logout') }}</Button>
            </template>
        </PageHeader>
        <GridContainer>
            <GridCard v-if="temporaryUser">
                <GridContent>
                    <GridHeader :title="$t('account.loggedAs.title', {name: user.full_name})" :text="$t('account.loggedAs.text', {name: temporaryUserFullName})"/>
                    <Button @click.native.prevent="unsetTemporaryUser" size="m" color="black">{{ $t('account.loggedAs.button', {name: user.full_name}) }}</Button>
                </GridContent>
            </GridCard>

            <GridCard>
                <GridContent>
                    <SectionTableInfos :fields="fields" :object="activeUser"/>
                </GridContent>
            </GridCard>

            <GridCard>
                <GridContent>
                    <GridHeader :title="$t('account.language.title')" :text="$t('account.language.text')"/>
                    <FieldSelect
                            :selected="$i18n.locale"
                            :multiple="false"
                            :border="true"
                            :label="$t('global.locale')"
                            :options="locales"
                            @input="lang => $i18n.locale = lang"
                    />
                </GridContent>
            </GridCard>

            <template v-slot:aside v-if="displayAdmins || displayInvoices">
                <GridCard v-if="displayAdmins">
                    <CheckerboardItem title-size="s" :title="$t('admin.title')" icon="collaboration" :route="{name: 'settings-admins'}">
                        <Button size="s" color="grey" icon="right">{{ $t('global.see') }}</Button>
                    </CheckerboardItem>
                </GridCard>
                <GridCard v-if="displayInvoices">
                    <CheckerboardItem title-size="s" :title="$t('menu.invoices-folders')" icon="folders" :route="{name: 'invoices-folders'}">
                        <Button size="s" color="grey" icon="right">{{ $t('global.see') }}</Button>
                    </CheckerboardItem>
                </GridCard>
            </template>
        </GridContainer>
    </div>
</template>

<script>
import PageHeader from '@/components/ui/page/PageHeader';
import { mapActions, mapState, mapGetters } from 'vuex';
import SectionTableInfos from '@/components/sections/SectionTableInfos';
import GridContainer from '@/components/ui/grid/GridContainer';
import GridCard from '@/components/ui/grid/GridCard';
import GridContent from '@/components/ui/grid/GridContent';
import CheckerboardItem from '@/components/ui/checkerboard/CheckerboardItem';
import Button from '@/components/ui/button/Button';
import GridHeader from '@/components/ui/grid/GridHeader';
import FieldSelect from '@/components/ui/form/fields/FieldSelect';

export default {
    name: 'ProfileSettings',
    components: {
        FieldSelect,
        GridHeader,
        Button, CheckerboardItem, GridContent, GridCard, GridContainer, SectionTableInfos, PageHeader},
    data() {
        return {
            fields: [
                {key: 'avatar', type: 'img'},
                {key: 'full_name'},
                {key: 'organization'},
                {key: 'defaultLocale', type: 'flag'},
                {key: 'roles', type: 'roles'},
            ],
            locales: [
                {
                    id: 'fr',
                    label: '🇫🇷 France'
                },
                {
                    id: 'en',
                    label: '🇬🇧 English'
                }
            ]
        }
    },
    computed: {
        ...mapState(['user', 'temporaryUser']),
        ...mapGetters(['roles', 'activeUser', 'temporaryUserFullName', 'roleIsAllowed']),
        displayInvoices() {
            return this.roleIsAllowed(['ROLE_CLIENT', 'ROLE_ADMIN', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE', 'ROLE_BRAND_SAV', 'ROLE_SUPER_ADMIN'])
        },
        displayAdmins() {
            return this.roleIsAllowed(['ROLE_ADMIN', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_STORE', 'ROLE_SUPER_STORE', 'ROLE_SUPER_ADMIN'])
        }
    },
    methods: {
        ...mapActions(['logout', 'unsetTemporaryUser']),
        logoutUser() {
            this.logout();
            this.$router.push({name: 'home'})
        }
    },
    watch: {
        '$i18n.locale': function(lang) {
            window.localStorage.setItem('lang', lang)
        }
    },
}
</script>

<style lang="scss">
.page-account {

    .grid-aside {
        flex-wrap: initial;
    }

    .grid__aside {
        flex-wrap: initial;

        .grid-card {
            overflow: hidden;
        }

        @media screen and (max-width: 1024px) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
        }

        @media screen and (min-width: 1025px) {
            width: 200px;
        }

        @media screen and (min-width: 1400px) {
            width: 500px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;

            .grid-card {
                max-width: 250px;
            }
        }
    }

    .grid {
        max-width: 2000px;
    }
}
</style>