<template>
    <TemplateDetails :get-function="getAdmin" :data="admin" :loading="adminLoading">
        <PageBack/>

        <PageHeader :title="$t('admin.title')">
            <template v-slot:actions>
                <Button v-if="temporaryUser && temporaryUser.id == admin.id" size="m" color="red" icon="cross" @click.native.prevent="unsetTemporaryUser">{{ $t('temporary_user.cta_logout') }}</Button>
                <Button v-else-if="isAdmin" size="m" color="green2" icon="user" @click.native.prevent="setTemporaryUser(admin)">{{ $t('temporary_user.cta_login') }}</Button>
                <ButtonLink v-if="modificationIsAllowed" size="m" color="black" icon="edit" :to="{ name: 'admin-edit', params: { id: id } }">
                    {{ $t("global.edit") }}
                </ButtonLink>
                <ButtonLink v-if="false" size="m" icon="cross" color="red" :to="{ name: 'admin-delete', params: { id: id } }">
                    {{ $t("global.delete") }}
                </ButtonLink>
            </template>
        </PageHeader>

        <GridContainer>
            <GridCard>
                <GridContent>
                    <GridHeader :title="$t('global.informations')"/>
                    <SectionTableInfos :object="admin" :fields="fields"/>
                </GridContent>
            </GridCard>

            <template v-slot:aside>
                <GridCard>
                    <GridContent>
                        <GridHeader :title="$t('global.roles')"/>
                        <TagList>
                            <TagType
                                    v-for="(role, i) in admin.roles"
                                    :key="i"
                                    :value="role"
                                    translation-key="roles"
                            />
                        </TagList>
                    </GridContent>
                </GridCard>
            </template>
        </GridContainer>
    </TemplateDetails>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import PageHeader from "@/components/ui/page/PageHeader";
import SectionTableInfos from "@/components/sections/SectionTableInfos";
import TemplateDetails from "@/components/templates/TemplateDetails";
import ButtonLink from "@/components/ui/button/ButtonLink";
import PageBack from "@/components/ui/page/PageBack";
import TagList from "@/components/ui/tag/TagList";
import TagType from "@/components/ui/tag/TagType";
import GridContainer from "@/components/ui/grid/GridContainer";
import GridCard from "@/components/ui/grid/GridCard";
import GridContent from "@/components/ui/grid/GridContent";
import GridHeader from "@/components/ui/grid/GridHeader";
import Button from '@/components/ui/button/Button';

export default {
    name: "AdminPage",
    components: {
        Button,
        GridHeader,
        GridContent,
        GridCard,
        GridContainer,
        TagType,
        TagList,
        PageBack,
        ButtonLink,
        TemplateDetails,
        SectionTableInfos,
        PageHeader,
    },
    data() {
        return {
            fields: [
                {key: "avatar", type: "img"},
                {key: "id"},
                {key: "firstname"},
                {key: "lastname"},
                {key: "email"},
                {key: "clients", type: "client"},
                {key: "organization"},
                {key: "defaultLocale", type: "flag"},
                {key: "chatbotKey"},
            ],
            id: this.$route.params.id,
        };
    },
    computed: {
        ...mapState(['admin', 'adminLoading', 'temporaryUser']),
        ...mapGetters(['roleIsAllowed', 'isAdmin']),
        modificationIsAllowed() {
            return this.roleIsAllowed(['ROLE_ADMIN', 'ROLE_BRAND_ADMIN', 'ROLE_SUPER_STORE', 'ROLE_SUPER_ADMIN'])
        }
    },
    methods: {
        ...mapActions(['getAdmin', 'setTemporaryUser', 'unsetTemporaryUser']),
    },
};
</script>
