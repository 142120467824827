<template>
    <TemplateDashboard
            :client-is-required="false"
            :route="$listRoutes.dashboardSellers"
            :filters-route="$listRoutes.dashboardSellersFilters"
            :comparaison="true"
            :title="$t('dashboard-sellers.title')"/>
</template>

<script>
import TemplateDashboard from '@/components/templates/TemplateDashboard';

export default {
    name: 'DashboardSellers',
    components: {TemplateDashboard},
}
</script>
