<template>
    <form class="form-inline"
          @submit.prevent="submit"
          :class="{
              'is-changed': isChanged && value !== initialValue,
              'is-success': success,
              'is-error': error,
              'is-loading': loading
          }">

        <TextSimple v-if="label" class="form-inline__label">{{ $t(`global.${label}`) }}</TextSimple>

        <div class="form-inline__input">

            <select v-if="type === 'select'" :name="name" v-model="value"
                    @change="isChanged = true; success = false; error = false;">
                <option v-for="(o, i) in options" :key="i" :value="o.value">{{ o.name }}</option>
            </select>
            <input v-else :name="name" v-model="value" :placeholder="placeholder"
                   @keydown="isChanged = true; success = false; error = false;"/>

            <div class="form-inline__button">
                <button type="submit">
                    <SvgLoader v-if="loading"/>
                    <span v-else>{{ $t('global.ok') }}</span>
                </button>
            </div>
        </div>

        <PopinNotification ref="popinSuccess" :text="success"/>

        <PopinNotification ref="popinError" :text="error"/>

    </form>
</template>

<script>
import PopinNotification from '@/components/ui/popin/PopinNotification';
import SvgLoader from '@/components/ui/svg/SvgLoader';
import API from '@/api/api-admin';
import TextSimple from '@/components/ui/text/TextSimple';

export default {
    components: {TextSimple, SvgLoader, PopinNotification},
    props: {
        name: String,
        type: {
            type: String,
            default: 'text'
        },
        model: [Number, String],
        options: [Array, Object],
        route: String,
        successMsg: String,
        errorMsg: String,
        placeholder: String,
        label: String,
        playload: Object
    },
    data() {
        return {
            initialValue: this.model,
            value: this.model,
            loading: false,
            error: false,
            success: false,
            isChanged: false
        }
    },
    methods: {
        submit() {
            this.loading = true;
            const data = this.playload
                    ? {
                        ...this.playload,
                        value: this.value
                    }
                    : {[this.name]: this.value};
            API.put(this.route, {...data})
                    .then(res => res.data)
                    .then(() => {
                        this.initialValue = this.value;
                        this.isChanged = false;
                        this.success = this.successMsg || 'La modification a bien été prise en compte';
                        this.$refs.popinSuccess.open();
                        setTimeout(() => this.success = false, 5000)
                    })
                    .catch(() => {
                        this.error = this.errorMsg || 'Une erreur est survenue';
                        this.$refs.popinError.open();
                        setTimeout(() => this.error = false, 5000)
                    })
                    .finally(() => this.loading = false)
        }
    }
}
</script>

<style lang="scss">
.form-inline {

    &__input {
        display: flex;
        max-width: 200px;
        width: 100%;
    }

    &__label {
        margin-top: 10px;
        margin-bottom: 5px;

        & + .form-inline__input {
            max-width: 100%;
        }
    }

    select,
    input {
        border: var(--border-1);
        padding: 0 10px;
        font-size: 1.2rem;
        flex-grow: 1;
        color: var(--color-text-grey);;
        transition: background-color ease .2s, border ease .2s, color ease .3s;

        @media screen and (min-width: 1025px) {
            &:hover {
                border: var(--border-2);
            }
        }
    }

    button {
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--color-bg-grey);
        flex-shrink: 0;
        cursor: pointer;
        margin-left: 5px;
        color: #000;
        transition: background-color ease .2s;

        @media screen and (min-width: 1025px) {
            &:hover {
                background-color: var(--color-green-light);
            }
        }
    }

    input, button {
        height: 30px;
        border-radius: var(--border-radius-1);
    }

    &:not(.is-changed) {
        .form-inline__button {
            max-width: 0;
        }
    }

    &.is-loading {
        pointer-events: none;

        input {
            color: var(--color-text-grey);
        }
    }

    &.is-changed input {
        background-color: var(--color-bg-very-light);
        color: #000;
    }

    &.is-success input {
        background-color: var(--color-green-light);
    }

    &.is-error input {
        background-color: var(--color-red-light);
    }

    &__button {
        max-width: 35px;
        flex-shrink: 0;
        overflow: hidden;
        transition: max-width ease-in-out .3s;

        svg {
            transform: scale(2);
        }
    }
}
</style>