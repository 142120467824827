import API from '@/api/api-admin';
import listRoutes from '@/api/list-routes';
import router from '@/router';
import i18n from '@/i18n';

const state = {
    marketTranslations: null,
    translationsNotPublished: false
}

const mutations = {
    SET_MARKET_TRANSLATIONS(state, data) {
        state.marketTranslations = data
    },
    UNSET_TRANSLATIONS(state) {
        //state.brandEmailsTranslations = null;
        state.marketTranslations = null;
    },
    UPDATE_TRANSLATION(state, data) {
        let path = data?.reference?.messageId?.split('.') || [];
        let translation = data.type === 'email' ? state.brandEmailsEditTranslations : state.marketTranslations;
        translation = {...translation};
        path.forEach(key => {
            if (translation && translation[key]) translation = translation[key]
        });
        if (translation) {
            const item = translation.find(x => x.locale === data.locale.code);
            if (item) {
                item.value = data.content;
                item.updatedAt = `${new Date().toISOString().substring(0, 19)}+00:00`
            }
        }
        state.translationsNotPublished = true
    },
    SET_TRANSLATION_NOT_PUBLISHED(state, value) {
        console.log('SET_TRANSLATION_NOT_PUBLISHED', value)
        state.translationsNotPublished = value
    }
}

const actions = {
    unsetTranslations({commit}) {
        commit('UNSET_TRANSLATIONS')
    },
    getMarketTranslations({commit}, id) {
        return new Promise((resolve, reject) => {
            API.get(listRoutes.websiteTranslations.replace(':id', id))
                .then(res => commit('SET_MARKET_TRANSLATIONS', res.data))
                .catch(() => reject())
        })
    },
    editTranslation({commit, state}, translation) {
        return new Promise((resolve, reject) => {
            const clientId = state.client?.id;
            const isClientRoute = router.currentRoute.name === 'client-translations';
            const url = clientId && isClientRoute ? listRoutes.websiteTranslationsEdit.replace(':client', clientId) : clientId ? listRoutes.emailTranslation.replace(':client', clientId) : listRoutes.translation;
            API.put(url.replace(':id', translation.id), {content: translation.content})
                .then(res => {
                    commit('UPDATE_TRANSLATION', {...res.data, type: isClientRoute ? 'client' : 'email'});
                    commit('SET_NOTIFICATION_MESSAGE', i18n.t('translations.saved_message'));
                    resolve();
                })
                .catch(() => reject())
            
        })
    },
    importTranslations({dispatch}, data) {
        return new Promise((resolve, reject) => {
            const {id, route, file} = data;
            API.post(route.replace(':id', id), {file: file})
                .then(res => {
                    dispatch(route.includes('email') ? 'getBrandEmailsTranslations' : 'getMarketTranslations', id)
                    resolve(res.data.message)
                })
                .catch(() => reject())
        })
    },
    setTranslationsNotPublished({commit}, value) {
        commit('SET_TRANSLATION_NOT_PUBLISHED', value)
    }
}

const getters = {

}

const translations = {
    state,
    mutations,
    actions,
    getters
}

export default translations