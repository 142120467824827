<template>
    <span class="input-boolean" :class="`is-${value}`">
        <span @click.self="$emit('change', null)">
             <span @click.prevent="$emit('change', value === 0 ? null : 0)">{{ $t('global.no') }}</span>
             <span @click.prevent="$emit('change', value === 1 ? null : 1)">{{ $t('global.yes') }}</span>
        </span>
    </span>
</template>

<script>
export default {
    name: 'InputBoolean',
    props: {
        model: [String, Number]
    },
    data() {
        return {
            value: this.model
        }
    },
    watch: {
        model(e) {
            this.value = e
        }
    }
}
</script>

<style lang="scss">
.input-boolean {
    padding: 10px 0;
    min-height: var(--field-height);
    overflow: hidden;
    background-color: var(--color-bg-light);
    display: flex !important;
    user-select: none;

    & > span {
        display: flex;
        position: relative;
        cursor: pointer;
        flex: 1 1 0;

        &:before {
            content: '';
            display: block;
            border: solid 1px transparent;
            position: absolute;
            top: 0;
            left: 50px;
            transition: left ease .2s, background-color ease .2s, border-color ease .2s;
            z-index: 1;
            width: 50px;
            height: 100%;
            border-radius: 20px;
        }

        span {
            display: flex;
            align-items: center;
            height: 100%;
            width: 50px;
            position: relative;
            z-index: 2;
            text-align: center;
            justify-content: center;
            transition: color ease .2s;

            @media screen and (min-width: 1025px) {
                &:hover {
                    color: #000 !important;
                }
            }
        }
    }

    &.is-0,
    &.is-1 {
        & > span:before {
            background-color: var(--color-bg-light);
            border-color: #ECEBE5;
        }
    }

    &.is-0 > span:before {
        left: 0
    }

    &.is-1 > span:before {
        left: 50px;
    }

    &.is-0 span span:last-of-type,
    &.is-1 span span:first-of-type {
        color: var(--color-text-grey);
    }
}
</style>