<template>
    <div class="field-radio" :class="{'is-white': whiteBackground}">
        <TextSimple class="field-radio__label">{{ label }}</TextSimple>
        <ul>
            <li v-for="(field, i) in fields" :key="i">
                <label :set="checked = value && (value === field.value || value === field.id)" :class="{'is-disabled': field.disabled}">
                    <input :disabled="field.disabled" type="radio" :required="required" :name="name" :value="field.value || field.id" v-model="value">
                    <span class="field-checkbox__input" :class="{'is-checked': checked}">
                        <SvgIcon v-if="checked" name="check"/>
                    </span>
                    <TextSimple :color="field.disabled ? 'grey' : 'black'">{{ field.label || field.title }}</TextSimple>
                </label>
            </li>
        </ul>
    </div>
</template>
<script>
import TextSimple from '@/components/ui/text/TextSimple';
import SvgIcon from '@/components/ui/svg/SvgIcon';

export default {
    components: {TextSimple, SvgIcon},
    props: {
        label: String,
        name: {
            type: String,
            default: 'radio'
        },
        fields: Array,
        required: {
            type: Boolean,
            default: true
        },
        whiteBackground: {
            type: Boolean,
            default: true
        },
        model: [String, Number]
    },
    data() {
        return {
            value: this.model
        }
    },
    watch: {
        model(model) {
            this.value = model
        },
        value(value) {
            this.$emit('change', value)
        }
    }
}
</script>

<style lang="scss">
.field-radio {
    position: relative;
    margin-top: 20px;
    display: block;

    &__label {
        position: absolute;
        top: 0;
        left: var(--field-padding);
        transform: translateY(-50%);
        pointer-events: none;
        color: #999;
        font-weight: 400;
        font-size: 1.1rem;
    }

    ul {
        background-color: var(--color-bg-light);
        padding: var(--field-padding);
        display: flex;
        flex-direction: column;
        border-radius: calc(var(--field-height) / 2);
        min-height: var(--field-height);
        overflow: hidden;
        gap: 5px;
    }

    &.is-white {
        ul {
            background-color: #fff;
        }
    }

    input {
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        opacity: 0;
    }

    li {
        width: 100%;
    }

    label {
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;

        &.is-disabled {
            pointer-events: none;
        }
    }

    .field-checkbox__input {
        border-radius: 50%;
    }
}
</style>