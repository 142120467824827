<template>
    <span class="form-selected" v-if="name">
        <TextSimple size="xs">{{ name }}</TextSimple>
        <SvgIcon name="cross"/>
    </span>
</template>
<script>
import TextSimple from '@/components/ui/text/TextSimple';
import SvgIcon from '@/components/ui/svg/SvgIcon';
export default {
    components: {TextSimple, SvgIcon},
    props: {
        name: String
    }
}
</script>

<style lang="scss">
.form-selected {
    display: flex;
    align-items: center;
    height: 30px;
    border-radius: 30px;
    padding: 0 15px;
    background-color: var(--color-bg-grey);
    cursor: pointer;
    transition: background-color ease .3s;
    width: fit-content;

    @media screen and (min-width: 1025px) {
        &:hover {
            background-color: var(--color-red-light);
        }
    }

    .icon {
        margin-left: 10px;
        display: flex;
        align-items: center;

        svg {
            width: 7px;
        }
    }
}
</style>