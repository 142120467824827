<template>
    <TemplateDetails class="catalogue-mapping" :data="catalogueMapping" :loading="catalogueMappingLoading" :get-function="catalogueForMapping">
        <PageBack/>
        
        <PageHeader :title="title" :subtitle="$t('catalogue.setup_fields')">
            <template v-slot:actions>
                <Button color="red" size="s" @click.native.prevent="$refs.popinCreatedFields.open">{{ $t('catalogue.mapping_delete_fields_title') }}</Button>
            </template>
        </PageHeader>
        <form @submit.prevent="submitThisMapping">
            <div>
                <TextTitle class="catalogue-mapping__title" :size="'s'" weight="bold">{{ $t('catalogue.mapping_mandatory_fields_title') }}</TextTitle>
                <InfoBox v-if="Object.keys(schemaExists).length === 0" :text="$t('catalogue.mapping_remembers')" />
                <InfoBox v-if="Object.keys(schemaExists).length && !loaded_mandatory" :text="$t('catalogue.mapping_schema_found_mandatory')" :button="true" :buttonFunction="loadSchema_mandatory"/>
                <TextSimple :size="'s'" weight="light"><span v-html="$t('catalogue.mapping_mandatory_fields_text')"></span></TextSimple>
                <GridCard class="catalogue-mapping__card">
                    <GridContent style="width: 100%;">
                        <CatalogueFieldsdMapper
                            v-if="schemaExists"
                            :disabled="disabled_mandatory"
                            :values="mandatoryFields"
                            valueKeyToDisplay="label"
                            :infos="infos_mandatory"
                            :editable="false"
                            :options="optionsForMandatoryFieldsFiltered"
                            :model="model"
                            @change="e => appendToModel(e)"
                        />
                        <Button class="catalogue-mapping__edit" v-if="disabled_mandatory" color="blue" size="s" @click.native.prevent="activateMandatoryFields">{{ $t('global.edit')}}</Button>
                        <Button class="catalogue-mapping__edit" v-if="!disabled_mandatory && edit_mandatory_fields" color="red" size="s" @click.native.prevent="activateMandatoryFields">{{ $t('global.cancel')}}</Button>
                    </GridContent>   
                </GridCard>
            </div>
            <div v-show="!disabled_optional">
                <TextTitle ref="optionalFields"  class="catalogue-mapping__title" :size="'s'" weight="bold">{{ $t('catalogue.mapping_optional_fields_title') }}</TextTitle>
                <InfoBox  v-if="Object.keys(modelMandatory).length" :text="$t('catalogue.mapping_schema_found_mandatory')" :button="true" :buttonFunction="loadSchema_optional"/>
                <TextSimple :size="'s'" weight="light"><span v-html="$t('catalogue.mapping_optional_fields_text')"></span></TextSimple>
                <GridCard class="catalogue-mapping__card">
                    <GridContent style="width: 100%;">
                        <CatalogueFieldsdMapper 
                            :disabled="disabled_optional"
                            :values="valuesForOptionalFields"
                            valueKeyToDisplay="name"
                            :infos="infos_optional"
                            :options="optionsForOptionalFieldsFiltered"
                            :isOptional="true"
                            :editable="true"
                            :model="model"
                            @change="e => appendToModel(e)"
                            @delete_key_of_model="e => deleteKeyOfModel(e)"
                            />
                    </GridContent>   
                </GridCard>
            </div>
            <ButtonForm v-show="!disabled_optional"  >{{ $t('global.validate') }}</ButtonForm>
        </form>       
            
        <Popin :title="$t('catalogue.mapping_delete_fields_title')" 
            ref="popinCreatedFields">
            <template v-slot:content>
                <div class="catalogue-mapping__fields">
                    <div v-for="optionalField in catalogueCreatedFieldsForMapping" :key="optionalField.id">
                        <TextSimple :size="'s'" weight="bold"> {{ optionalField.name }}  </TextSimple>
                        
                        <Button color="red" icon="trash" size="s" @click.native.prevent="deleteThisField({clientID:parseInt(id),fieldID:optionalField.id})"></Button>
                    </div>
                </div>
            </template>
        </Popin>
        <Popin :title="submittedFieldsResponse ? $t('catalogue.mapping_fields') : $t('catalogue.fail')" ref="popinSubmittedFieldsResponse">
            <template v-slot:bottom>
                <Button v-if="!loading" color="green" size="m" @click.native.prevent.once="validateThisCatalogue">{{ $t('catalogue.validation') }}</Button>
                <InPopinLoader 
                v-if="loading" 
                :msg="$t('catalogue.please_wait_setup')" 
                :loading="loading" 
                />
            </template>
        </Popin>

        <Popin 
            :title="popinTitle" 
            :subtitle="currentCatalogue?.title"
            ref="popinProgression">
                
            <template v-slot:content>
                <div v-if="catalogueProgress">
                    <ProgressBar 
                        v-if="showProgressBar" 
                        :start_value="catalogueProgress.totalLinesProcessed" 
                        :start_text="$t('catalogue.totalLinesProcessed')" 
                        :total_value="catalogueProgress.totalLines" 
                        :total_text="$t('catalogue.totalLines')" 
                        :progression="getCatalogueProgressStateValues"
                    />
                </div>
                <InPopinLoader 
                    v-if="catalogueProgressStatus" 
                    :msg="$t('catalogue.please_wait_end')" 
                    :loading="catalogueProgressStatus" 
                />

            </template>
            <template v-slot:bottom>
                <div v-if="getCatalogueProgressStateValues === '100%' && !catalogueProgressStatus">  
                    <ButtonLink 
                        v-if="doCreatedCatalogueIsInDefaultLocale && currentCatalogue?.status?.validation?.isValidated"
                        color="green" 
                        size="m" 
                        :to="{name:'config-website-cohorts-create', params:{id, catalogueID}}">{{ $t('cohorts.import_pricing') }}</ButtonLink>
                    <Button
                        v-if="currentCatalogue?.status?.validation?.isValidated && !currentCatalogue?.status?.generation?.isGenerated && !doCreatedCatalogueIsInDefaultLocale"  
                        color="green" size="m" @click.native.prevent="generateThisCatalogue">{{ $t('catalogue.generation_start') }}</Button>
                        
                    <Button 
                        v-if="currentCatalogue?.status?.validation?.isValidated && currentCatalogue?.status?.generation?.isGenerated && !currentCatalogue?.active && !doCreatedCatalogueIsInDefaultLocale"
                        color="green" size="m" @click.native.prevent="launchThisCatalogue">{{ $t('catalogue.publish') }} </Button>
                    <ButtonLink 
                        v-if="currentCatalogue?.status?.validation?.isValidated && currentCatalogue?.active && !doCreatedCatalogueIsInDefaultLocale"
                        color="grey" size="m" :to="{name:'client-catalog', params:{id:id}}">{{ $t('global.back')}} </ButtonLink>
                    <div class="catalogue-mapping__errors" v-if="!currentCatalogue?.status?.validation?.isValidated">
                        <p>{{ $t('catalogue.validation_error') }}</p>
                        <ButtonLink color="red" size="m" :to="{name:'config-website-catalogue-validation', params:{catalogueID: currentCatalogue?.id, lang: currentCatalogue?.lang}}">{{ $t('catalogue.validation_results') }}</ButtonLink>
                    </div>
                </div>
            </template>
        </Popin>


    </TemplateDetails>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex';
import PageHeader from '@/components/ui/page/PageHeader.vue';
import GridCard from '@/components/ui/grid/GridCard.vue';
import GridContent from '@/components/ui/grid/GridContent.vue';
import PageBack from '@/components/ui/page/PageBack.vue';
import TemplateDetails from '@/components/templates/TemplateDetails.vue';
import Button from '@/components/ui/button/Button.vue';
import ButtonLink from '@/components/ui/button/ButtonLink.vue';
import ButtonForm from '@/components/ui/button/ButtonForm.vue';
import Popin from '@/components/ui/popin/Popin.vue';
import InPopinLoader from '@/components/ui/popin/InPopinLoader.vue';
import ProgressBar from '@/components/ui/progress/ProgressBar.vue';
import CatalogueFieldsdMapper from '@/components/ui/catalogue/CatalogueFieldsMapper.vue';
import InfoBox from '@/components/ui/infobox/InfoBox.vue';
import TextTitle from '@/components/ui/text/TextTitle.vue';
import TextSimple from '@/components/ui/text/TextSimple.vue';


export default {
    name: 'CatalogueMapping',
    components: { 
        TemplateDetails,
        GridCard,
        GridContent,
        PageHeader,
        PageBack,
        Popin,
        InPopinLoader,
        Button,
        ButtonForm,
        ProgressBar,
        ButtonLink,
        CatalogueFieldsdMapper,
        InfoBox,
        TextTitle,
        TextSimple,
    },
    props: {
        catalogueID: {
            type: String,
        },
    },
    data() {
        return {
            modelMandatory: {},
            modelOptional: {},
            submittedFieldsResponse:false,
            loaded_mandatory:false,
            showProgressBar:false,
            disabled_mandatory:false,
            disabled_optional:true,
            loading:false,
            valuesForOptionalFields:[],
            edit_mandatory_fields:false
        }
    },
    computed: {
        ...mapState([
            'catalogueMapping',
            'catalogueMappingLoading',
            'catalogueProgress',
            'catalogueProgressStatus',
            'catalogueFields',
            'catalogueSchema',
            'catalogueSchemaLoading',
            'catalogueDefaultLocale'
        ]),
        ...mapGetters([
            'getCatalogueMappingState',
            'getCatalogueProgressStateValues',
            'getCurrentCatalogue',
            'catalogueBasicFieldsForMapping',
            'catalogueCreatedFieldsForMapping'
        ]),
        id() {
            return this.$route.params.id
        },
        lang() {
            return this.$route.params.lang
        },
        client() {
            return this.$store.getters.getClientById(this.id)
        },
        title(){
            return this.client?.name?.length 
                ? `${this.$t('catalogue.mapping')} ${this.client.name}` 
                : ''
        },
        mandatoryFields(){
            return this.catalogueBasicFieldsForMapping('mandatory')
        },
        optionalFields(){
            return [
                ...this.catalogueBasicFieldsForMapping('optional'), 
                ...this.catalogueCreatedFieldsForMapping
            ]
        },
        model(){
            return {catalogId:this.catalogueID,...this.modelMandatory,...this.modelOptional}
        },
        criterias(){
            const criterias = []
            
            this.getCatalogueMappingState.headers 
            ? this.getCatalogueMappingState.headers.forEach(criteria => criterias.push({key:`criterias.${criteria}`, size:'m', noTranslate:true, label:criteria})) 
            : null

            return criterias
        },

        /**
         * optionsForMandatoryFields() & optionsForOptionalFields
         * "Fabriquent" la base de ce que seront les options des selects
         */
        optionsForMandatoryFields(){
            return this.getCatalogueMappingState?.headers 
                ? this.getCatalogueMappingState.headers.map(criteria => ({name:criteria, value:criteria})) 
                : []
        },
        optionsForOptionalFields(){
            return this.optionalFields 
                ? this.optionalFields.map(elem => ({name:elem.label,value:elem.name}))
                : []
        },
        
        /**
         * selectedValuesForMandatoryFieldsOptions & selectedKeysForOptionalFieldsOptions
         * Retournent soit les valeurs soit les clés du modele
         */

        selectedValuesForMandatoryFieldsOptions(){
            return Object.values(this.model);
        },
        selectedKeysForOptionalFieldsOptions(){
            return Object.keys(this.model);
        },

        /**
         * optionsForMandatoryFieldsFiltered & optionsForOptionalFieldsFiltered
         * Vérifient si dans le modele (via selectedValuesForMandatoryFieldsOptions & selectedKeysForOptionalFieldsOptions) que le nom ou la valeur de l'option sont présents
         * Si oui, alors cette option passe en disabled
         */
        optionsForMandatoryFieldsFiltered(){
            return this.optionsForMandatoryFields.map((option)=>{
                option.disabled = this.selectedValuesForMandatoryFieldsOptions.includes(option.name)
                return option
            }).sort((a, b) => a.value.localeCompare(b.value));
        },
        optionsForOptionalFieldsFiltered(){
            return this.optionsForOptionalFields.map((option)=>{
                option.disabled = this.selectedKeysForOptionalFieldsOptions.includes(option.value) 
                return option
            }).sort((a, b) => a.name.localeCompare(b.name));
        },
        currentCatalogue(){
            const getCurrentCatalogue = this.getCurrentCatalogue;
            return getCurrentCatalogue 
                ? getCurrentCatalogue(this.lang)(this.catalogueID)
                : null;
        },
        schemaExists(){
            // Est ce que le schema existe ?
            return this.catalogueSchema ? this.catalogueSchema : {}
        },
        filteredOptionalFields() {
            // const startDate = new Date("27/02/2023");
            return this.optionalFields
            // .filter(field => {
            //     const fieldDate = new Date(field.update_at);
            //     return fieldDate >= startDate;
            // });
        },
        mandatoryFieldsFromSchemaExists(){
            if(this.schemaExists){
                // Filtre et Renvoie les valeurs obligatoires de schemaExists
                const valuesToKeep = this.mandatoryFields.map(elem => elem.name)
                const filteredSchema = Object.keys(this.schemaExists).reduce((acc, key) => {
                    if (valuesToKeep.includes(key)) {
                        acc[key] = this.schemaExists[key];
                    }
                    return acc;
                }, {});
                
                //Vérifie et filtre si la valeur des options Optionnelles est dans le précédent objet
                const allowedKeys = this. optionsForMandatoryFieldsFiltered.map(elem => elem.name)
                const filteredObj = Object.keys(filteredSchema)
                    .filter(key => allowedKeys.includes(filteredSchema[key]))
                    .reduce((acc, key) => {
                        acc[key] = filteredSchema[key];
                        return acc;
                }, {});
                return filteredObj
            } else {
                return {}
            }
        },
        transformedMandatoryFieldForAppendToModel(){
            return Object.entries(this.mandatoryFieldsFromSchemaExists).map(elem => {
                return {name:elem[0],value:elem[1],type:"mandatory"}
            })
        },  
        optionalFieldsFromSchemaExists(){
            if(this.schemaExists){
                // Filtre et Renvoie les valeurs optionnelles de schemaExists
                const valuesToKeep = this.optionalFields.map(elem => elem.name)
                const filteredSchema = Object.keys(this.schemaExists).reduce((acc, key) => {
                    if (valuesToKeep.includes(key)) {
                        acc[key] = this.schemaExists[key];
                    }
                    return acc;
                }, {});
                //Vérifie et filtre si la valeur des options Optionnelles est dans le précédent objet
                const allowedKeys = this. valuesForOptionalFields.map(elem => elem.name)
                const filteredObj = Object.keys(filteredSchema)
                    .filter(key => allowedKeys.includes(filteredSchema[key]))
                    .reduce((acc, key) => {
                        acc[key] = filteredSchema[key];
                        return acc;
                }, {});
                return filteredObj
            } else {
                return {}
            }
        },
        transformedOptionalFieldForAppendToModel(){
            return Object.entries(this.optionalFieldsFromSchemaExists).map(elem => {
                return {name:elem[0],value:elem[1],type:"optional"}
            })
        },  
        infos_mandatory() {
            return [
                {name:this.$t('catalogue.mapping_faume_label'),size:'l'},
                {name:this.$t('catalogue.mapping_mapping'),size:'m'},
                {name:this.$t('catalogue.mapping_catalogue_field'),size:'l'},   
            ]
        },
        infos_optional() {
            return [
                {name:this.$t('catalogue.mapping_catalogue_field'),size:'l'},   
                {name:this.$t('catalogue.mapping_mapping'),size:'m'},
                {name:this.$t('catalogue.mapping_faume_label'),size:'l'},
                {name:this.$t('catalogue.mapping_catalogue_field_edit'),size:'s'},   
            ]
        },
        doCreatedCatalogueIsInDefaultLocale(){
            return this.catalogueDefaultLocale?.defautLocale === this.lang
        },
        popinTitle() {
            const isProgressComplete = this.getCatalogueProgressStateValues === '100%';
            const isValidated = this.currentCatalogue?.status?.validation?.isValidated;
            const isGenerated = this.currentCatalogue?.status?.generation?.isGenerated;

            if (!isProgressComplete && !isValidated && !isGenerated) {
                return this.$t('catalogue.progressionValidation');
            }
            if (isProgressComplete && isValidated && !isGenerated) {
                return this.$t('catalogue.progressionValidationEnd');
            }
            if (!isProgressComplete && isValidated && !isGenerated) {
                return this.$t('catalogue.progressionGeneration');
            }
            if (isProgressComplete && isValidated && isGenerated) {
                return this.$t('catalogue.progressionGenerationEnd');
            }
            if(isProgressComplete && !isValidated && !isGenerated){
                return this.$t('catalogue.mapping_error');
            }
            return '';
        },
    },
    methods: {
        ...mapActions([
                'prefetchCatalogue',
                'catalogueGetSchema',
                'catalogueGetAllFields',
                'catalogueMappedfieldsSubmission',
                'catalogueValidationOrGeneration',
                'fetchCatalogues',
                'deleteField',
                'getCatalogueDefaultLocale',
                'catalogueLaunch'
            ]),
        deleteThisField(arg){
            this.deleteField(arg)
        },  
        async catalogueForMapping(){
            
            await this.catalogueGetAllFields(this.id)
            await this.fetchCatalogues(this.id)
            await this.catalogueGetSchema({id:this.id, lang:this.lang})
            await this.getCatalogueDefaultLocale(this.id)
            await this.prefetchCatalogue(this.catalogueID)
        },
        appendToModel(value){
            if(value.type==='optional'){
                for (let key in this.model) {
                    if (this.modelOptional[key] === value.value) {
                        // delete this.modelOptional[key];
                        this.$delete(this.modelOptional, key)
                    }
                }
                this.$set(this.modelOptional, value.name, value.value);
            } else {
                this.$set(this.modelMandatory, value.name, value.value);
                this.valuesForOptionalFields = this.optionsForMandatoryFieldsFiltered.filter(elem => !elem.disabled)
            }
        },
        validateThisCatalogue(){
            this.loading = true
            this.catalogueValidationOrGeneration({id:this.catalogueID,type:'validation',clientID:this.id,fetchAfter:true})
                .then(response => {
                    if(response){
                        this.loading = false
                        this.$refs.popinSubmittedFieldsResponse.close()
                        this.$refs.popinProgression.open()
                        this.showProgressBar=true
                    }
                })
                .catch(err => {
                    console.log(err); 
                    this.$store.commit('SET_NOTIFICATION_MESSAGE', {text:this.$t(err.response.data), color:'red', confirmation: true})
                })
        },
        submitThisMapping(){
            this.catalogueMappedfieldsSubmission(this.model)
                .then(res => {
                    if(res.status === 204){
                        this.mappingCatalogueSuccess(res)
                    }
                })
                .catch(err => {
                    this.$store.commit('SET_NOTIFICATION_MESSAGE', {text:this.$t(err.response.data), color:'red', confirmation: true})
                })
        },
        mappingCatalogueSuccess(data){
            console.log('mappingCatalogueSuccess',data)
            this.submittedFieldsResponse = true
            this.$refs.popinSubmittedFieldsResponse.open()
        },
        deleteKeyOfModel(key_to_delete){
            this.$delete(this.modelOptional, key_to_delete);
        },
        loadSchema_mandatory(){
            this.loaded_mandatory = true
            this.transformedMandatoryFieldForAppendToModel.forEach(elem => this.appendToModel(elem))
            
        },
        loadSchema_optional(){
            this.transformedOptionalFieldForAppendToModel.forEach(elem => this.appendToModel(elem))
        },
        watchModel() {
            /**
             * Permet de vérifier si toutes les entrées de mandatoryFields sont dans le modele
             * Si ok, le second fieldMapper est actif et on arrête d'observer la fonction
             */
            const unwatch = this.$watch('model', (newValue) => {
                if(this.mandatoryFields.length){
                    const keysToCheck = this.mandatoryFields.map(elem => elem.name);
                    const hasAllKeys = keysToCheck.every(key => key in newValue);
                    if (hasAllKeys) {
                        unwatch();
                        this.disabled_mandatory = true
                        this.disabled_optional = false
                        setTimeout(()=> {
                            this.$refs.optionalFields.$el.scrollIntoView({behavior:'smooth',block: "start"})

                        },500)
                    } else {
                        this.watchModel()
                    }
                }
            }, {
                deep: true
            });
        },
        activateMandatoryFields(){
            this.disabled_mandatory = false
            this.disabled_optional = true
            this.edit_mandatory_fields = true
            this.modelOptional = {}
            this.watchModel()
        },
        cancelEditMandatory(){
            this.disabled_mandatory = true
            this.disabled_optional = false
            this.edit_mandatory_fields = false
        },
        generateThisCatalogue(){
            this.loading = true
            this.catalogueValidationOrGeneration({id:this.catalogueID, clientID:parseInt(this.id), type:'generation',fetchAfter:true})
            .then(response => {
                if(response){
                    this.loading = false
                    this.$refs.popinSubmittedFieldsResponse.close()
                    this.$refs.popinProgression.open()
                    this.showProgressBar=true
                }
            })
            .catch(err => {
                this.$store.commit('SET_NOTIFICATION_MESSAGE', {text:this.$t(err.response.data), color:'red', confirmation: true})
            });
        },
        launchThisCatalogue(){
            this.catalogueLaunch({id:this.id, catalogueID:this.catalogueID, fetchAfter:true})
                .then(response => {
                    console.log('launchThisCatalogue', response)
                })
                .catch(err => console.log(err))
                // .finally(()=> this.validationSuccess = true)
        },
    },
    mounted () {
        this.watchModel();
    },
}
</script>
<style lang="scss" scoped>
.catalogue-mapping {
    &__card {
        display: flex;
        max-width: 1030px;
        text-align: center;
        margin-top: 20px;
    }
    &__fields {
        div {
            border-radius: var(--border-radius-1);
            display:flex;
            align-items: center;
            justify-content:space-between;
            gap: 10px;
            padding: 10px;
        }
        div:nth-child(odd) { background: var(--color-bg-light);}
        div:nth-child(even) { 
            border-top: var(--border-1);
            background: var(--color-bg-very-light)
        }
    }
    &__title {
        margin-bottom: 5px;
    }
    &__edit {
        margin: 10px auto;
    }
    span {
        display: inline-block;
        max-width: 800px;
    }
    &__errors {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
</style>