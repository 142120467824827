<template>
    <GridCard>
        <GridContent>
            <GridHeader 
                :title="title" 
                :text="text"
            />
            <TextSimple>{{ conditions }}</TextSimple>
            <div class="list" :class="{'list--dragging': isDragging}">
                <div
                    v-for="(item, index) in items"
                    :key="item.id"
                    :class="handleDropClasses(item)"
                    draggable
                    @dragstart.self="pickupElem($event, item, index);"
                    @dragover.prevent="showDropPlace($event, item, index);"
                    @dragenter.prevent
                    @drop="moveElem($event, item, index);"
                    @dragend.prevent="dragEndClear();"
                >
                <div class="list__elem" :class="{'list__elem--is-dragged': dragedElem && item.id === dragedElem.id}">
                    <div>{{ item.label }} <button class="list__elem--btn-drag">↹</button></div>
                </div>
                </div>
            </div>
        </GridContent>
    </GridCard>
</template>




<script>
/**
 * Source : https://codesandbox.io/p/sandbox/nnvxromq94
 */
import GridCard from '@/components/ui/grid/GridCard.vue';
import GridContent from '@/components/ui/grid/GridContent.vue';
import GridHeader from '@/components/ui/grid/GridHeader.vue';
import TextSimple from '../text/TextSimple.vue';
export default {
    name: "DragAndDrop",
    components: {
        GridCard,
        GridContent,
        GridHeader,
        TextSimple
    },
    props: {
        values: Array,
        title: String,
        text: String,
        conditions: String,
    },
    data() {
        return {
            items: this.values,
            dragedElem: null,
            overElem: null
        };
    },
    computed: {
        handleDropClasses() {
            return item => {
                console.log(this.overElem ? this.overElem.id : '')
                if (!this.overElem || !this.overElem.id) {
                    return "";
                }
                if (this.overElem.id === item.id && item.order < this.dragedElem.order) {
                    return "drop-place drop-place--before";
                }
                if (this.overElem.id === item.id && item.order > this.dragedElem.order) {
                    return "drop-place drop-place--after";
                }
            };
        },
        isDragging() {
            return this.dragedElem !== null;
        }
    },
    methods: {
        dragEndClear() {
            this.dragedElem = null;
            this.overElem = null;
        },
        pickupElem(event, elem) {
            event.dataTransfer.dropEffect = "move";
            this.dragedElem = { ...elem };
        },
        showDropPlace(event, elem) {
            if (elem.id !== this.dragedElem.id) {
                this.overElem = { ...elem };
            } else {
                this.overElem = null;
            }
        },
        moveElem(event, elem, index) {
            this.items = this.items.filter((item) => item.id !== this.dragedElem.id);
            this.items.splice(index, 0, { ...this.dragedElem });
            this.items.forEach((item, index) => (item.order = index));
            this.updateOrder()
        },
        updateOrder() {
            this.$emit('update-order', this.items);
        },
    }, 
    mounted () {
        // this.updateOrder()
    },
};
</script>

<style lang="scss" scoped>
  .list {
    display: flex;
    gap: 20px;
    cursor: grab;
    margin-top: 20px;
    padding-bottom: 40px;
    
    &--dragging {
      cursor: grabbing;
    }

    .list__elem {
      border-radius: var(--field-height);
      background: var(--color-bg-grey);
      padding: 5px 20px;
      margin-bottom: 5px;
      transition: all 0.3s ease;

      & > div {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      &--btn-drag {
        font-size: 1.8rem;
      }

      &--is-dragged {
        opacity: 0.7;
        border: 1px dashed var(--color-bg-grey);
      }
    }
  }

  .drop-place {
    position: relative;
    transition: all 0.3s ease;

    &--before,
    &--after {
      padding-bottom: 50px;

      &:before,
      &:after {
        position: absolute;
        border-radius: var(--field-height);
        bottom: 5px;
        left: 0;
        text-align: center;
        content: "≚";
        padding-top: 5px;
        font-size: 2rem;
        width: 100%;
        opacity: 0.7;
        border: var(--border-1);
        background: var(--color-bg-grey);
      }
    }
  }
</style>