<template>
    <TemplateTable
        :title="$t('menu.test')"
        name="monitoringTests"
        :url="$listRoutes.monitoringTests"
        :table="monitoringTests"
        :filters="monitoringTestsFilters"
        :fields="fields"
    />
</template>

<script>
import { mapState } from 'vuex';
import TemplateTable from '@/components/templates/TemplateTable';

export default {
    name: 'monitoringTestsPage',
    components: { TemplateTable },
    data() {
        return {
            fields: [
                {key: 'id', type: 'eye', route: 'test', size: 'xs'},
                {key: 'context', type: 'text', translationKey:'type',  size: 'l'},
                {key: 'brand', type: 'client', translationKey:'client',  size: 'm'},
                {key: 'branch', type: 'text',  size: 'm'},
                {key: 'created_at', type: 'dateHour', translationKey:'createdAt'},
                {key: 'status', type: 'tagType', size: 'm'},
            ]
        }
    },
    computed: {
        ...mapState(['monitoringTests', 'monitoringTestsFilters']),
        monitoringTestsDate(){
            return this.monitoringTests.map((item)=>{
                item.created_at = new Date(item.created_at).setHours(new Date(item.created_at).getHours() + 2);
                return item;
            })
        }
    }
}
</script>

<style lang="scss">

</style>