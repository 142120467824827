<template>
    <TagList v-if="selected?.length" class="filters-selected">
        <ButtonCustom v-for="(filter, i) in selected"
                      :key="`${filter.key}-${filter.operator}-${filter.value}-${i}`" v-show="filter.operator && filter.value && filter.key"
                      @click.native.prevent="$emit('delete', i)"
                      size="s"
                      color="grey"
                      icon="cross">
            {{ getValue(filter.key, filter.value, filter.operator) }}
        </ButtonCustom>
    </TagList>
</template>

<script>
import TagList from '@/components/ui/tag/TagList';
import ButtonCustom from '@/components/ui/button/Button';
export default {
    name: 'FiltersSelected',
    components: {ButtonCustom, TagList},
    props: {
        selected: Array,
        filters: Array
    },
    methods: {
        getValue(key, value, operator) {
            if (!value || !key) return value;
            const filter = this.filters.find(f => f.key == key);
            if (filter) {
                if (filter.type === 'boolean') return this.$t(`global.${value == '1' ? 'yes' : 'no'}`);
                if (filter.type === 'select') {
                    if (Array.isArray(value)) {
                        return value.map(v => {
                            const option = filter.options.find(o => o.id == v);
                            if (option) {
                                let label = this.$t(`global.${option.label}`);
                                if (label === `global.${option.label}`) label = option.label;
                                return label;
                            }
                            return value;
                        }).join(', ');
                    } else {
                        const option = filter.options.find(o => o.id == value);
                        if (option) {
                            let label = this.$t(`global.${option.label}`);
                            if (label === `global.${option.label}`) label = option.label;
                            return label;
                        }
                        return value;
                    }
                }
            }
            if (value.includes(':00')) return `${operator === 'lte' ? '< ' : operator === 'gte' ? '> ' : ''}${this.$options.filters.momentDateTime(value)}`;
            return Array.isArray(value) ? value.join(', ') : value;
        }
    },
    watch: {
        selected: {
            handler() {
                this.$forceUpdate();
            },
            deep: true
        }
    }
}
</script>

<style lang="scss">
.filters-selected {
    @media screen and (max-width: 1024px) {
        display: none;
    }
}
</style>
