<template>
    <TemplateDetails :data="configMarkets" :loading="configMarketsLoading" :get-function="getMarketsConfigs">
        <PageBack/>

        <PageHeader :title="$t('market.titleConfigMarket') | replace('{{name}}', client.name)">
            <template v-slot:actions>
                <ButtonCustom @click.native.prevent="$refs.popin.open()" size="m" color="yellow" icon="stars">{{$t('market.edit_default.title')}}</ButtonCustom>
                <ButtonCustom @click.native.prevent="addMarket" size="m" color="black" icon="plus">{{$t('market.addMarket')}}</ButtonCustom>
            </template>
        </PageHeader>

        <GridContainer>
            <Checkerboard v-if="configMarkets?.items" class="markets-checkerboard">
                <CheckerboardItem v-for="market in configMarkets.items" :key="market.id">
                    <TextTitle size="s">{{ market.name }}</TextTitle>
                    <TextTitle size="l">{{ market.countries[0].country.flag }}</TextTitle>
                    <ToggleSwitcher :label="market.enabledAt ? $t('market.activated') : $t('market.deactivated')" :model="!!market.enabledAt" @change="value => setMarketEnabledAt({marketId: market.id, value: value})"/>
                    <ButtonLink size="s" icon="right" color="grey" :to="{name: 'config-website-markets-country', params: { id: client.id, marketName: market.name }}">{{ $t('menu.settings') }}</ButtonLink>
                    <ButtonCustom v-if="market.isDefault" class="markets-checkerboard__default" icon="stars" color="yellow" size="s" :no-event="true" :big-icon="true">{{ $t('market.default') }}</ButtonCustom>
                </CheckerboardItem>
            </Checkerboard>
            <div class="markets__pagination-controls">
                <ButtonCustom size="s" @click.native.prevent="changePage(currentPage - 1)" :disabled="currentPage <= 1 || !configMarkets?.pagination">Précédent</ButtonCustom>
                <span>Page {{ currentPage }} sur {{ configMarkets && configMarkets.pagination ? configMarkets.pagination.pageCount : 0 }}</span>
                <ButtonCustom  size="s" @click.native.prevent="changePage(currentPage + 1)" :disabled="currentPage >= configMarkets?.pagination?.pageCount || !configMarkets?.pagination">Suivant</ButtonCustom>
            </div>
        </GridContainer>

        <Popin ref="popin" :title="$t('market.edit_default.title')" class="market-edit-default">
            <template v-slot:content>
                <form @submit.prevent="editDefault">
                    <FieldSelect :border="true" :options="marketsOptions" :selected="defaultMarket" :label="$t('market.default')" @input="e => defaultMarket = e"/>
                    <br>
                    <TextSimple v-html="$t('market.edit_default.text')"/>
                    <ButtonForm :loading="loading">{{ $t("market.edit_default.button") }}</ButtonForm>
                </form>
            </template>
        </Popin>

    </TemplateDetails>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import PageHeader from '@/components/ui/page/PageHeader';
import ButtonCustom from '@/components/ui/button/Button';
import ButtonLink from '@/components/ui/button/ButtonLink';
import PageBack from '@/components/ui/page/PageBack';
import GridContainer from '@/components/ui/grid/GridContainer.vue';
import Checkerboard from '@/components/ui/checkerboard/Checkerboard.vue';
import CheckerboardItem from '@/components/ui/checkerboard/CheckerboardItem.vue';
import TextTitle from '@/components/ui/text/TextTitle.vue';
import TemplateDetails from '@/components/templates/TemplateDetails.vue';
import Popin from '@/components/ui/popin/Popin';
import FieldSelect from '@/components/ui/form/fields/FieldSelect';
import ButtonForm from '@/components/ui/button/ButtonForm';
import TextSimple from '@/components/ui/text/TextSimple';
import ToggleSwitcher from '@/components/ui/switcher/ToggleSwitcher';

export default {
    name: 'ConfigWebsiteMarkets',
    components: {
        TextSimple,
        ButtonForm,
        FieldSelect,
        Popin,
        TextTitle,
        ButtonLink,
        GridContainer,
        Checkerboard,
        CheckerboardItem,
        PageBack,
        ButtonCustom,
        PageHeader,
        TemplateDetails,
        ToggleSwitcher
    },
    data() {
        return {
            defaultMarket: null,
            loading: false,
            currentPage: 1,
            perPage: 10,
            items: [],
            pagination: { pageCount: 1 } 
        }
    },
    computed: { 
        ...mapState(['configMarkets', 'configMarketsLoading']),
        id() {
            return this.$route.params.id
        },
        client() {
            return this.$store.getters.getClientById(this.id)
        },
        marketsOptions() {
            return this.configMarkets?.items ? this.configMarkets.items.map(x => ({id: x.id, label: x.name})) : []
        }
    },
    methods: {
        ...mapActions(['getMarketsConfigs', 'setSelectedMarket', 'setMarketEnabledAt']),
        addMarket() {
            this.$router.push({name: 'config-website-markets-regions-add', params: {id: this.client.id}});
        },
        editDefault() {
            this.loading = true;
            this.setSelectedMarket(this.defaultMarket)
                    .then(() => this.$refs.popin?.close())
                    .finally(() => this.loading = false)
        },
        loadPage(page) {
            this.currentPage = page;
            this.getMarketsConfigs({ id: this.client.id, page: this.currentPage });
        },
        changePage(page) {
            if (page < 1 || page > this.configMarkets.pagination.pageCount) {
                return; // Éviter de changer de page si la nouvelle page est hors limites
            }
            this.currentPage = page;
            this.loadMarketData();
        },
        loadMarketData() {
            this.getMarketsConfigs({ id: this.client.id, page: this.currentPage });
        },
    },
    watch: {
        configMarkets: {
            handler() {
                this.defaultMarket = this.configMarkets?.items?.find(x => x.isDefault)?.id
            },
            deep: true
      }
    },
    mounted() {
        this.defaultMarket = this.configMarkets?.items?.find(x => x.isDefault)?.id;
        this.loadPage(this.currentPage);
        this.loadMarketData();
    }
}
</script>

<style lang="scss">
.markets-checkerboard {
    .button--yellow {
        width: max-content;
        margin-top: 0;
        font-size: 1rem;
        min-height: 20px;
        position: absolute;
        left: 50%;
        top: 10px;
        transform: translateX(-50%);
    }

    .checkerboard-item {
        aspect-ratio: initial;
        padding-top: 60px;
    }
}

.market-edit-default .field-select__items {
    max-height: 200px;
}

.market-edit-default .popin__inner {
    overflow-y: auto;
}

.markets__pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    button {
        margin: 0 10px;
    }
}
</style>