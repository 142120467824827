<template>
    <div v-if="Object.keys(item).length">
        <div v-for="(value,key) in item" :key="key">
            <strong>{{key}} :</strong> {{value ? value : `${$t('merchandising.no_value_assigned')}`}}
        </div>
    </div>
    <div v-else >{{$t('merchandising.no_value_assigned')}}</div>
</template>

<script>
export default {
    name:'MerchandisingItemAliases',
    props: {
        item:Object
    },
}
</script>

<style>

</style>