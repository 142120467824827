<template>
    <a :href="'/admin/liste-des-articles/single/article/'+item.id" target="_blank" class="order-card">
        <div :class="item.metadata?.photos.length ? 'order-card__photo' : 'order-card__image'">
            <img v-if="item.metadata?.photos.length" :src="item.metadata?.photos[0]" alt="photo">
            <SvgIcon v-else name="tshirt"/>
        </div>
        <div class="order-card__text">
            <div class="order-card__text-title">
                <TextTitle size="xs">{{ title }}</TextTitle>
                <TextSimple color="grey">{{ color }} / {{ size }} / {{ $t(`global.${condition}`) }}</TextSimple>
            </div>
            <TextSimple color="grey">{{ $t("global.reference") }}: {{ reference }}</TextSimple>
            <TextSimple v-if="returnItem" color="grey" size="xs">{{ $t("order.return_reason") }}: {{ $t(`order.${returnItem.reason}`) }}</TextSimple>
            <TagType v-if="returnItem" :value="returnItem.state" :text="`${$t('global.return')}: ${$t('global.'+returnItem.state)}`"/>
        </div>
        <TextSimple>{{ item.priceResale || null | formatPrice }}</TextSimple>
    </a>
</template>

<script>
import { mapState } from 'vuex';
import TextTitle from '@/components/ui/text/TextTitle';
import TextSimple from '@/components/ui/text/TextSimple';
import SvgIcon from '@/components/ui/svg/SvgIcon';
import TagType from '@/components/ui/tag/TagType';

export default {
    name: 'RecomOrderCardItem',
    components: {TextSimple, TextTitle, SvgIcon, TagType},
    props: {
        item: Object,
        returnItem: Object
    },
    computed: {
        ...mapState(['clients']),
        title() {
            return this.getMetadataValue('title');
        },
        color() {
            return this.getMetadataValue('color');
        },
        size() {
            return this.getMetadataValue('size');
        },
        condition() {
            return this.item.condition || null;
        },
        ean() {
            return this.item.ean || null;
        },
        sku() {
            return this.item.sku || null;
        },
        reference() {
            return this.item.reference || null;
        },
        idfaume() {
            return this.item.id || null;
        }
    },
    methods: {
        getMetadataValue(key) {
            const metadata = this.item.metadata;
            return metadata.fr_FR?.[key] || metadata.en_GB?.[key] || metadata.de_DE?.[key] || null;
        }
    }
}
</script>

<style lang="scss">
.order-card {
    text-align: left;
    display: flex;
    align-items: center;
    gap: 15px;

    & ~ .order-card {
        margin-top: 15px;
    }

    .tag {
        margin-left: 0;
        margin-top: 5px;
    }

    &__image {
        width: 45px;
        height: 45px;
        flex-shrink: 0;
        border: var(--border-1);
        transition: border ease .2s;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        --svg-color: var(--color-text-grey);
    }

    &__photo {
        width: 60px;
        height: 60px;
        flex-shrink: 0;
        transition: border ease .2s;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &:hover .order-card__image {
        border: var(--border-2);
    }

    &__text {
        display: flex;
        flex-direction: column;
        gap: 3px;

        &-title {
            display: flex;
            align-items: flex-start;
            column-gap: 10px;
            @media screen and (max-width: 700px) {
                row-gap: 5px;
                flex-direction: column;
            }
        }
    }

    & > p {
        margin-left: auto;
    }
}
</style>