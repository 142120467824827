<template>
    <FieldSwitch
            :model="$i18n.locale"
            :title_1="languages[0].toUpperCase()"
            :title_2="languages[1].toUpperCase()"
            :value_1="languages[0]"
            :value_2="languages[1]"
            ref="switch"
            @change="lang => $i18n.locale = lang"
    />
</template>

<script>
import FieldSwitch from '@/components/ui/form/fields/FieldSwitch';
export default {
    name: 'LayoutSwitchLang',
    components: {FieldSwitch},
    data() {
        return {
            languages: ['fr', 'en']
        }
    },
    watch: {
        '$i18n.locale': function(lang) {
            window.localStorage.setItem('lang', lang)
        }
    },
    methods: {
        resize() {
            const interval = setInterval(() => {
                if (this.$refs.switch) this.$refs.switch.resize()
            }, 100);
            setTimeout(() => clearInterval(interval), 1000);
        }
    }
}
</script>