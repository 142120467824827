<template>
    <TemplateConversionFunnel
            :client-is-required="true"
            :route="$listRoutes.dashboardsFunnel"
            :filters-route="'#'"
            :events="['purchase_view_item', 'purchase_add_to_cart', 'purchase_checkout_started', 'purchase_completed']"
            :comparaison="true"
            :title="$t('dashboard-funnel-buying.title')"/>
</template>

<script>
import TemplateConversionFunnel from '@/components/templates/TemplateConversionFunnel';

export default {
    name: 'DashboardBuyingFunnel',
    components: {TemplateConversionFunnel},
}
</script>