import axios from 'axios';
import store from '@/store/index';
import router from '@/router';

let DashboardAPI = axios.create({
    baseURL: `${process.env.VUE_APP_API}/api/v2/admin/dashboard/`,
    headers: {
        Accept: "application/json",
        'Content-Type': 'application/json'
    },
});

DashboardAPI.interceptors.request.use((config) => {
    if (store.state.user) {
        config.headers['Authorization'] = `Bearer ${store.state.temporaryUser ? store.state.temporaryUser.token : store.state.user.access_token}`;
    }
    return config;
});

DashboardAPI.interceptors.response.use(response => response, error => {
    if (error && error.response && error.response.data && error.response.data.message && error.response.data.message.includes('JWT Token')) {
        router.push({name: 'loader'})
    }
    return Promise.reject(error);
});

export default DashboardAPI

