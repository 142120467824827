<template>
    <div>
        <default-layout v-if="loading">
            <PageLoader/>
        </default-layout>
        <component v-else :is="layout">
            <router-view/>
        </component>
        <PopinNotification ref="notification" :text="notificationMessage"/>
        <ContextualMenu />
    </div>
</template>

<script>
import DefaultLayout from '@/components/layouts/DefaultLayout';
import SimpleLayout from '@/components/layouts/SimpleLayout';
import PageLoader from '@/components/ui/page/PageLoader';
import PopinNotification from '@/components/ui/popin/PopinNotification';
import ContextualMenu from '@/components/ui/contextuals/ContextualMenu';
import { mapState } from 'vuex';

export default {
    name: 'App',
    components: {
        PopinNotification,
        PageLoader,
        SimpleLayout,
        DefaultLayout,
        ContextualMenu
    },
    data() {
        return {
            loading: true,
            intercom: false
        }
    },
    computed: {
        ...mapState(['user', 'notificationMessage']),
        layout() {
            return this.$route.meta && this.$route.meta.public ? 'simple-layout' : 'default-layout'
        }
    },
    methods: {
        initIntercom() {
            if (!this.intercom && this.user && this.user.chatbot_key) {
                this.intercom = true;
                const script1 = document.createElement('script')
                script1.innerHTML = `window.intercomSettings = {
                    api_base: "https://api-iam.intercom.io/",
                    app_id: "ncolcas7",
                    name: "${this.user.full_name}",
                    email: "${this.user.email}",
                    company_id: "${this.user.chatbot_key}",
                    user_id: "${this.user.chatbot_auth_key}"
                }`;
                document.head.appendChild(script1)
                const script2 = document.createElement('script')
                script2.innerHTML = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/ncolcas7';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`
                document.head.appendChild(script2)
            }
        },
        updateLang() {
            let lang = this.user?.default_locale;
            if (lang) {
                lang = lang.split('_')[0];
                if (!['fr', 'en'].includes(lang)) lang = 'en';
                this.$i18n.locale = lang
            }
        }
    },
    watch: {
        user() {
            this.initIntercom();
            this.updateLang();
        },
        notificationMessage(value) {
            if (value) this.$refs.notification.open()
        }
    },
    mounted() {
        if (this.user) {
            this.loading = false;
            this.initIntercom();
            this.updateLang();
        } else {
            this.loading = false
            this.$router.push({name: 'login'}).catch(()=>{});
        }
    },
    updated() {
        if(this.$route.name === 'home' && this.user) this.$router.push({name:'admin-home'});
    }
}
</script>

<style lang="scss">
@import "assets/scss/fonts";
@import "assets/scss/variables";
@import "assets/scss/init";
</style>


