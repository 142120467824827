<template>
    <div>
        <div class="table-item" v-if="$slots.default || fields">
            <table>
                <slot/>
            </table>
        </div>

        <PageLoader v-else/>

        <TablePagination v-if="table && table.pagination" :pagination="table.pagination"/>
    </div>
</template>

<script>
import PageLoader from '@/components/ui/page/PageLoader';
import TablePagination from '@/components/ui/table/TablePagination';

export default {
    name: 'TableItem',
    components: {TablePagination, PageLoader},
    props: {
        fields: Array,
        table: Object,
        sort: Boolean
    }
}
</script>


<style lang="scss">
.table-item {
    width: 100%;

    @media screen and (max-width: 1199px) {
        overflow-x: auto;
    }

    &::-webkit-scrollbar {
        height: 8px;
        border-radius: 10px;
        overflow: hidden;
    }

    &::-webkit-scrollbar-track {
        background: var(--color-bg-grey);
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-thumb:hover {
        background: var(--color-text-grey);
        border-radius: 10px;
    }

    table {
        min-width: 100%;
        border-collapse: collapse;
    }
}
</style>