<template>
  <div class="in-popin-loader" :class="loading ? 'loading' : ''">
    {{ msg }}
  </div>
</template>

<script>
export default {
    name:'InPopinLoader',
    props: {
        loading:Boolean,
        msg:String
    },
}
</script>

<style lang="scss" scoped>
.in-popin-loader {
    text-align: center;
    &.loading {
        animation: load 2s infinite;
    }
}
@keyframes load { 
    0% {
        opacity: 0.25;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.25;
    }
}
</style>