<template>
    <div class="merchandising__field-details" v-if="merchandisingTagCategories">
        <GridContainer>
            <GridCard>
                <GridContent>
                    <GridHeader :title="tagCategoriesByLang_labelList ? title : congratulations" />
                    <Form 
                        class="merchandising__form"
                        v-if="tagCategoriesByLang_labelList"
                        :fields="fields" 
                        :model="model"
                        @change="e => appendToModel(e)"
                        :action="e => submitTagCategory({lang:lang,id:$route.params.id,tagCategory:model})" 
                        @success="e => submitCategorySuccess(e)"
                    >
                    </Form>
                    <div class="merchandising__tag-category" v-if="merchandisingTagCategoriesByLang">
                        <TextTitle tag="h2" size="xs">{{ $t('merchandising.tag_category_assigned') }}</TextTitle>
                        <div 
                            v-for="tagCategory in merchandisingTagCategoriesByLang_in_labelList" 
                            :key="tagCategory.id"
                            class="merchandising__tag-category--values"
                            >
                            <Button @click.prevent.native="deleteThisTagCategory({tagCategoryID:tagCategory.id,id:id})" color="white" size="s" icon="trash"></Button>
                            <div>
                                <span><strong>{{$t('merchandising.initial_name')}}</strong>{{ tagCategory.name.replace(`${type}_`,'') }}</span>
                                <span><strong>{{$t('merchandising.new_label')}}</strong> {{ tagCategory.label.replace(`${type}_`,'') }}</span>
                            </div>
                        </div>
                    </div>
                </GridContent>
            </GridCard>
        </GridContainer>
    </div>
    
</template>

<script>
import { mapActions, mapState } from 'vuex'
import GridContainer from '@/components/ui/grid/GridContainer.vue';
import GridCard from '@/components/ui/grid/GridCard.vue';
import GridContent from '@/components/ui/grid/GridContent.vue';
import GridHeader from '@/components/ui/grid/GridHeader.vue';
import Button from '@/components/ui/button/Button.vue'
import Form from '@/components/ui/form/Form.vue'
import TextTitle from '@/components/ui/text/TextTitle.vue'

export default {
    components: {
        GridContainer,
        GridCard,
        GridContent,
        GridHeader,
        Form,
        TextTitle,
        Button
    },
    props: {
        category:Object,
        lang:String,
    },
    data() {
        return {
            show:false,
            model: {},
            checkValues: [],
        }
    },
    watch: {
        optionsFiltered: {
            immediate: true,
            handler(newValue) {    
                if (newValue) {
                    this.fields.map(el => el.options = [...this.optionsFiltered])
                }
            }
        },
    },
    computed: {
        ...mapState(['merchandisingTagCategories','merchandisingTagCategoriesLoading']),
        title(){
            return `${this.$t('merchandising.tag_category_title')} ${this.lang}`
        },
        congratulations(){
            return `${this.$t('merchandising.congratulations')} ${this.lang}`
        },
        id(){
            return this.$route.params.id
        },
        labelList() {
            // Retourne les options disponibles de category
            return this.category.labelList
        },
        type() {
            return this.category.type
        },
        fieldsSelect(){
            return {
                name: this.category.labelList[0],
                required:true,
                type:'select',
                options:this.labelList.map(elem => {
                    return {
                        name:elem.replace(`${this.type}_`,''),
                        value:elem,
                    }
                }),
            }
        },
        fieldsInput(){
            return {
                name:'tags',
                label:'label',
                type:'text',
                required: true
            }
        },
        fields(){
            return [this.fieldsSelect, this.fieldsInput]
        },
        options(){
            const options = []
            this.labelList.forEach(criteria => options.push({name:criteria.replace(`${this.type}_`,''), value:criteria})) 

            return options
        },
        selectedOptions(){
            return Object.values(this.model);
        },
        merchandisingTagCategoriesByLang(){
            // Retourne les valeurs de la clé lang de merchandisingTagCategories
            return this.lang !== null && this.merchandisingTagCategories !== null 
                ? this.merchandisingTagCategories[this.lang] 
                    ? this.merchandisingTagCategories[this.lang] 
                    : false
                : false
        },

        optionsFiltered() {
            // Parcourir les options pour définir initialement 'disabled' en fonction de 'selectedOptions'
            const optionsFiltered = this.options.map((option) => {
                return {
                    ...option,
                    disabled: this.selectedOptions.includes(option.name),
                };
            });

            // Boucle sur 'merchandisingTagCategoriesByLang' pour mettre à jour le statut 'disabled'
            if (this.merchandisingTagCategoriesByLang) {
                this.merchandisingTagCategoriesByLang.forEach(category => {
                    // Trouver une option correspondante basée sur le nom de la catégorie et la valeur de l'option
                    const matchingOption = optionsFiltered.find(option => option.value === category.name);
                    if (matchingOption) matchingOption.disabled = true; // Désactiver l'option correspondante
                });
            }
            return optionsFiltered; // Retourne les options filtrées
        },

        merchandisingTagCategoriesByLang_in_labelList(){
            return this.merchandisingTagCategoriesByLang 
                ? this.merchandisingTagCategoriesByLang.filter(item => this.labelList.includes(item.name))
                : false
        },
        tagCategoriesByLang_labelList(){
            return !(this.merchandisingTagCategoriesByLang_in_labelList.length === this.labelList.length)
        }

    },
    methods: {
        ...mapActions(['submitTagCategory','deleteTagCategory']),
        appendToModel(value){
            value.name==='tags'
                ? this.model = {...this.model, label:this.type+'_'+value.value}
                : this.model = {...this.model, name:value.value},this.checkValues.push(this.type+'_'+value.value)          
        },
        submitCategorySuccess(data){
            console.log('submitCategorySuccess',data)
        },
        deleteThisTagCategory(payload){
            this.deleteTagCategory(payload)
        }

    },
}
</script>

<style lang="scss" >
.merchandising {
    &__field-details {

        display: flex;
        flex-direction: column;
        gap: 20px;

        .form__container {
            display: flex;
            align-items: center;
            min-width: 100%;
            justify-content: space-between;
            border-radius: var(--border-radius-2);
            border: var(--border-1);
            background: var(--color-bg-light);
            padding: 0 10px;
            .field {
                margin-top: 10px;
            }
        }
    }
    &__form {
        padding-bottom:10px;
        
        .select_input {
            display: flex;
        }
    }
    &__tag-category {
        & > div {
            display:flex;
            align-items: center;
            padding: 10px;
            gap: 10px;
            border-radius: var(--border-radius-2);
            border: var(--border-1);
            margin-bottom: 10px;

            &:hover {
                background: var(--color-bg-light)
            }
            div {
                display: flex;
                gap: 10px;
            }
        }
        &--values {
            margin-top: 10px;
        }

    }
}
</style>


