<template>
    <PageHeader title="Collections" text="Bientôt disponibles"/>
</template>

<script>
import PageHeader from '@/components/ui/page/PageHeader';
export default {
    components: {PageHeader},
    name: "CollectionsPage"
}
</script>