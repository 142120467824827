<template>
    <TemplateDetails :get-function="getOrderRefunded" :data="orderRefunded" :loading="orderRefundedLoading">
        <PageBack/>
        <PageHeader :title="$t('orderRefunded.title')">
            <template #actions>
                <ButtonLink :to="{name: 'order', params: {id: orderRefunded.id}}" icon="bag" size="m" color="black">{{ $t('orderRefunded.see_order') }}</ButtonLink>
            </template>
        </PageHeader>
        <SectionTableInfos v-if="orderRefunded" :fields="fieldsInfos" :object="orderRefunded"/>
        <TableSticky v-if="orderRefunded?.refunds?.length" :fields="fieldsRefunds" :data="orderRefunded.refunds"/>
    </TemplateDetails>
</template>

<script>
import TemplateDetails from '@/components/templates/TemplateDetails';
import PageBack from '@/components/ui/page/PageBack';
import PageHeader from '@/components/ui/page/PageHeader';
import { mapActions, mapState } from 'vuex';
import SectionTableInfos from '@/components/sections/SectionTableInfos';
import TableSticky from '@/components/ui/table-sticky/TableSticky';
import ButtonLink from '@/components/ui/button/ButtonLink';
export default {
    name: "OrderRefunded",
    components: {ButtonLink, TableSticky, SectionTableInfos, PageHeader, PageBack, TemplateDetails},
    data() {
        return {
            fieldsInfos: [
                {key: 'id', type: 'copy'},
                {key: 'shopifyId', type: 'copy'},
                {key: 'number', type: 'copy'},
                {key: 'price', translationKey: 'initialPrice', type: 'money', size: 's'},
                {key: 'requestedRefundAmount', type: 'money'},
                {key: 'refundedAmount', type: 'money'},
                {key: 'retainedReturnFee', type: 'money'},
                {key: 'client', type: 'client'},
            ],
            fieldsRefunds: [
                {key: 'refundDate', type: 'date', size: 'm'},
                {key: 'id', type: 'copy', size: 'l'},
                {key: 'requestedAmount', type: 'money', size: 'l', translationKey: 'requestedRefundAmount'},
                {key: 'refundedAmount', type: 'money', size: 'm'},
                {key: 'feeAmount', type: 'money', size: 'l', translationKey: 'retainedReturnFee'},
                {key: 'cardRefundedAmount', type: 'money', size: 'l'},
                {key: 'walletRefundedAmount', type: 'money', size: 'l'},
                {key: 'cashRefundedAmount', type: 'money', size: 'l'},
            ]
        }
    },
    computed: {
        ...mapState(['orderRefunded', 'orderRefundedLoading', 'clients']),
    },
    methods: {
        ...mapActions(['getOrderRefunded'])
    }
}
</script>