<template>
    <TemplateDetails :get-function="getInvoice" :data="invoice" :loading="invoiceLoading">

        <PageBack/>

        <Form v-if="invoice"
              :title="$t('invoice.edit_global')"
              :fields="fields"
              :action="editInvoice"
              :model="invoice"
              @success="$router.push({name: 'invoice', params: {id: $route.params.id}})"
        />

    </TemplateDetails>
</template>

<script>
import TemplateDetails from '@/components/templates/TemplateDetails';
import PageBack from '@/components/ui/page/PageBack';
import Form from '@/components/ui/form/Form';
import { mapState, mapActions } from 'vuex';

export default {
    name: 'InvoiceEdit',
    components: {Form, PageBack, TemplateDetails},
    data() {
        return {
            fields: [
                {name: 'clientName'},
                {name: 'clientAddress'},
                {name: 'clientPostalCode'},
                {name: 'clientCity'},
                {name: 'clientCountry'},
                {name: 'clientAgent'},
                {name: 'invoiceDate', required: true, type: 'date'},
                {
                    name: 'invoiceType', required: true, type: 'select', options: [
                        {name: this.$t('global.OneshotInvoice'), value: 'OneshotInvoice'},
                        {name: this.$t('global.CreditInvoice'), value: 'CreditInvoice'},
                    ]
                },
                {
                    name: 'status', required: true, type: 'select', options: [
                        {name: this.$t('global.draft'), value: 'draft'},
                        {name: this.$t('global.sent'), value: 'sent'},
                    ]
                },
            ]
        }
    },
    computed: {
        ...mapState(['invoice', 'invoiceLoading']),
    },
    methods: {
        ...mapActions(['getInvoice','editInvoice']),
    }
}
</script>