<template>
    <TemplateDetails :get-function="initGetMonitoringDatas" :data="currentMonitoringData" :loading="monitoringDatasLoading">
        <PageBack/>
        <div v-if="currentMonitoringData">
            <GridContainer>
                <GridCard>
                    <GridContent>
                        <GridHeader :title="$t('global.informations')"/>
                        <SectionTableInfos :object="currentMonitoringData" :fields="infoFields"/>
                    </GridContent>
                </GridCard>
                <template v-slot:bottom>
                    <TableSticky v-if="currentMonitoringData.metadata" :fields="fields" :data="currentMonitoringData.metadata.items"/>
                </template>
            </GridContainer>
        </div>
        <TextSimple v-else size="s" weight="medium">{{ $t('admin.create.error') }}</TextSimple>
    </TemplateDetails>
</template>
    
<script>
    import { mapState, mapActions, mapGetters } from 'vuex';
    import TemplateDetails from '@/components/templates/TemplateDetails';
    import PageBack from '@/components/ui/page/PageBack';
    import GridContainer from '@/components/ui/grid/GridContainer';
    import GridContent from '@/components/ui/grid/GridContent';
    import GridCard from '@/components/ui/grid/GridCard';
    import GridHeader from '@/components/ui/grid/GridHeader';
    import SectionTableInfos from '@/components/sections/SectionTableInfos';
    import TableSticky from '@/components/ui/table-sticky/TableSticky';
    import TextSimple from '@/components/ui/text/TextSimple';
    
    export default {
      name: 'monitoringDataPage',
      components: {
        PageBack, 
        TemplateDetails, 
        TableSticky,
        GridContainer,
        GridContent,
        GridHeader,
        GridCard,
        SectionTableInfos,
        TextSimple
      },
      data() {
        return {
            loading: true,
            infoFields: [
                {key: 'id', type: 'text', route: 'test', size: 'm'},
                {key: 'name', type: 'text', size: 'l'},
                {key: 'last_update', type: 'dateHour', translationKey: 'lastUpdate', size: 'l'},
                {key: 'count', type: 'number'},
                {key: 'success', type: 'boolean', size: 'l'}
            ],
            fields: [
                {key: 'client_id', type: 'number', translationKey: 'clientId', size: 's'},
                {key: 'order_id', type: 'text', translationKey: 'idOrder', size: 'm'},
                {key: 'return_id', type: 'text', translationKey: 'returnId', size: 'm'},
                {key: 'shopify_id', type: 'text', translationKey: 'shopifyId', size: 'm'},
                {key: 'command_date', type: 'dateHour', translationKey: 'orderDate', size: 's'},
                {key: 'updated_at', type: 'dateHour', translationKey: 'orderUpdate', size: 'm'},
                {key: 'stage', type: 'text', size: 'm'},
                {key: 'slot', type: 'text', size: 'm'}
            ]
        }
    },
    computed: {
        ...mapState(['monitoringDatas', 'monitoringDatasLoading']),
        ...mapGetters(['getCurrentMonitoringDataById']),
        currentMonitoringData(){
            return this.getCurrentMonitoringDataById(this.$route.params.id)
        }
    },
    methods: {
        ...mapActions(['getMonitoringDatas']),
        initGetMonitoringDatas(){
                const data = {
                    name: 'monitoringDatas',
                    url: this.$listRoutes.monitoringDatas
                };
                this.getMonitoringDatas({...data});
        }
    },
    watch: {
        currentMonitoringData(e) {
            this.fields = this.fields.filter(item => e.metadata.items[0][item.key] !== undefined);
        }
    }
}
</script>
    
<style lang="scss">
</style>