<template>
  <GridCard>
        <CohortsCardContent :catalogueTitle="catalogue.title" :lines="cohortsLines" />
        <FieldCheckbox @change="e => $emit('change',e)" />
  </GridCard>
</template>

<script>
import GridCard from '@/components/ui/grid/GridCard';
import CohortsCardContent from '@/components/ui/cohort/CohortsCardContent'
import FieldCheckbox from '@/components/ui/form/fields/FieldCheckbox'

export default {
    data() {
        return {
            cohortsLines: [
                {lang: this.catalogue.lang},
                {name: this.catalogue.file.name},
                {update_at: this.catalogue.update_at},
            ]
        }
    },
    props: {
        catalogue: Object,
        model: Object,
    },
    components: {
        GridCard,
        CohortsCardContent,
        FieldCheckbox
    },
}
</script>

<style lang="scss" scoped>
.grid-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    max-width: 600px;
    padding: 10px 15px;
    background: #fff;
    margin-bottom: 20px;
    border-radius: var(--border-radius-2);
    border: var(--border-1);
    @media screen and (max-width: 550px) {
        padding: 10px;
    }
}
</style>