<template>
    <TemplateTable
            :title="$t('menu.users')"
            :text="$t('users.description')"
            name="customers"
            :url="$listRoutes.customers"
            :table="customers"
            :filters="customersFilters"
            :fields="fields"
            :search="true"
    />
</template>

<script>
import { mapState } from 'vuex';
import TemplateTable from '@/components/templates/TemplateTable';

export default {
    name: 'CustomersPage',
    components: {TemplateTable},
    data() {
        return {
            fields: [
                {key: 'user.id', type: 'eye', route: 'customer', size: 'xs'},
                {key: 'firstname', size: 'm'},
                {key: 'lastname', size: 'm'},
                {key: 'email', type: 'copy', size: 'xl'},
                {key: 'civility'},
                {key: 'registration', type: 'boolean'},
                {key: 'client', type: 'client', size: 'l'},
                {key: 'optinEmail', type: 'tagType'},
                {key: 'optinSMS', type: 'boolean'},
                {key: 'nbOrders'},
                {key: 'nbReprises'},
                //{key: 'nbReturns'},
                {key: 'shopifyId', type: 'shopifyCustomer', size: 'm'},
                {key: 'user.customerClientId', type: 'copy', size: 'l'},
                {key: 'createdAt', type: 'date'},
                {key: 'updatedAt', type: 'date'},
                {key: 'locale'},
                {key: 'country', size: 'xs'},
                {key: 'memberAreaUrl', type: 'memberUrl'},
            ]
        }
    },
    computed: {
        ...mapState(['customers', 'customersFilters'])
    }
}
</script>