<template>
    <div v-if="categories">
        <MerchandisingFieldsDetail 
            v-for="(lang,index) in categories_key" 
            :key="index"
            :lang="lang"
            :category="categories[lang]" 
        />
    </div>
</template>

<script>
import MerchandisingFieldsDetail from './MerchandisingFieldsDetail.vue'

export default {
    components: {
        MerchandisingFieldsDetail,
    },
    props: {
        categories: Object
    },
    computed: {
        categories_key() {
            return Object.keys(this.categories) 
        },
    },
}
</script>

<style>

</style>