import Invoices from '@/components/pages/admin/invoices/Invoices';
import InvoicesFolders from '@/components/pages/admin/invoices/InvoicesFolders';
import InvoicesFolder from '@/components/pages/admin/invoices/InvoicesFolder';
import Invoice from '@/components/pages/admin/invoices/Invoice';
import InvoiceLine from '@/components/pages/admin/invoices/InvoiceLine';
import InvoiceCreate from '@/components/pages/admin/invoices/InvoiceCreate';
import InvoiceAdd from '@/components/pages/admin/invoices/InvoiceAdd';
import InvoiceEdit from '@/components/pages/admin/invoices/InvoiceEdit';

const customersRoutes = [
    {
        path: '/admin/factures',
        name: 'invoices',
        component: Invoices,
        meta: {
            title: 'bills',
            roles: ['ROLE_ADMIN', 'ROLE_CLIENT'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/factures/:id',
        name: 'invoice',
        component: Invoice,
        props: true,
        meta: {
            roles: ['ROLE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/factures/:id/modifier',
        name: 'invoice-edit',
        component: InvoiceEdit,
        meta: {
            roles: ['ROLE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/factures/lignes/:id',
        name: 'invoice-line',
        component: InvoiceLine,
        meta: {
            roles: ['ROLE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/factures/:id/ajouter-ligne',
        name: 'invoice-line-add',
        component: InvoiceAdd,
        meta: {
            roles: ['ROLE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/factures/:id/:folder/nouveau/',
        name: 'invoice-create',
        component: InvoiceCreate,
        props:true,
        meta: {
            title: 'nouveau',
            roles: ['ROLE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/dossiers-facturation',
        name: 'invoices-folders',
        component: InvoicesFolders,
        meta: {
            title: 'invoices-folders',
            roles: ['ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE', 'ROLE_BRAND_SAV'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/dossiers-facturation/:id/',
        name: 'invoices-folder',
        component: InvoicesFolder,
        meta: {
            title: 'invoices-folder',
            roles: ['ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE', 'ROLE_BRAND_SAV'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/dossiers-facturation',
        name: 'finance',
        redirect: {
            name: 'orders',
        },
        meta: {
            title: 'customers-finance',
            icon: 'bag',
            roles: ['ROLE_ADMIN', 'ROLE_FINANCE', 'ROLE_CLIENT', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE', 'ROLE_BRAND_SAV'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/dossiers-facturation/*',
        redirect: {
            name: 'orders',
        }
    }
];

export default customersRoutes;