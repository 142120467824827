<template>
    <section class="config-client">
        <PageLoader v-if="!clients"/>
        <div v-else-if="client">
            <Form :title="client.name" class="website-edit" :full="true">

                <FormColumns>
                    <div>
                        <FormSubtitle>{{ $t('global.global') }}</FormSubtitle>
                        <Field :model="formData.name" type="text" :label="$t('global.name')" @change="e => formData.name = e"/>
                        <FieldCheckbox :model="formData.shopClientActivated" :label="$t('global.shopClientActivated')" @change="e => formData.shopClientActivated = e"/>
                        <Field :model="formData.emailLogistic" type="email" :label="$t('global.emailLogistic')" @change="e => formData.emailLogistic = e"/>
                        <Field :model="formData.domain" type="text" :label="$t('global.domain')" @change="e => formData.domain = e"/>
                        <Field :model="formData.frontUrl" type="text" :label="$t('global.frontUrl')" @change="e => formData.frontUrl = e"/>
                        <Field :model="formData.frontUrlClient" type="text" :label="$t('global.frontUrlClient')" @change="e => formData.frontUrlClient = e"/>
                        <Field :model="formData.shopifyUrl" type="text" :label="$t('global.shopifyUrl')" @change="e => formData.shopifyUrl = e"/>
                    </div>
                    <div>
                        <FormSubtitle>{{ $t('global.resell') }}</FormSubtitle>
                        <Field :model="formData.deprecationRate" type="number" :label="$t('global.deprecationRate')" @change="e => formData.deprecationRate = e"/>
                        <FieldCheckbox :model="formData.resellActivated" :label="$t('global.resellActivated')" @change="e => formData.resellActivated = e"/>
                        <FormSubtitle>{{ $t('global.promoCode') }}</FormSubtitle>
                        <Field :model="formData.promoCodeMax" type="number" :label="$t('global.promoCodeMax')" @change="e => formData.promoCodeMax = e"/>
                        <Field :model="formData.promoCodeDuration" type="number" :label="$t('global.promoCodeDuration')" @change="e => formData.promoCodeDuration = e"/>
                        <Field :model="formData.promoCodeOrderMin" type="number" :label="$t('global.promoCodeOrderMin')" @change="e => formData.promoCodeOrderMin = e"/>
                    </div>
                </FormColumns>

                <ButtonForm :loading="loading">{{ $t('client.edit.button') }}</ButtonForm>

            </Form>
        </div>
        <PageError v-else title="404" :subtitle="$t('client.not-found')" :button="$t('client.return')" :to="{name: 'clients'}"/>
    </section>
</template>

<script>
import { mapState } from 'vuex';
import PageLoader from '@/components/ui/page/PageLoader';
import PageError from '@/components/ui/page/PageError';
import Form from '@/components/ui/form/Form';
import Field from '@/components/ui/form/fields/Field';
import ButtonForm from '@/components/ui/button/ButtonForm';
import FormSubtitle from '@/components/ui/form/FormSubtitle';
import FieldCheckbox from '@/components/ui/form/fields/FieldCheckbox';
import FormColumns from '@/components/ui/form/FormColumns';

export default {
    name: 'ClientEdit',
    components: {PageError, PageLoader, FormColumns, FieldCheckbox, FormSubtitle, ButtonForm, Field, Form},
    data() {
        return {
            loading: false,
            success: false,
            error: false,
            formData: {
                name: null,
                deprecationRate: null,
                promoCodeDuration: null,
                promoCodeMax: null,
                promoCodeOrderMin: null,
                emailLogistic: null,
                resellActivated: false,
                domain: null,
                frontUrl: null,
                frontUrlClient: null,
                shopifyUrl: null,
                shopClientActivated: null,
            }
        }
    },
    computed: {
        ...mapState(['clients']),
        id() {
            return this.$route.params.id
        },
        client() {
            return this.$store.getters.getClientById(this.id)
        },
    },
    beforeMount() {
        // On met à jour les valeurs du formulaire
        Object.keys(this.formData).forEach(key => {
            this.formData[key] = this.client[key]
        })
    }
}
</script>

<style lang="scss">
.config-client {
    height: 100%;
}
</style>