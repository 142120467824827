import axios from 'axios';

const testsMonitoringAPI = axios.create({
    baseURL: `${process.env.VUE_APP_TESTSMONITORING_API}`,
    headers: {
        Accept: "application/json",
        'Content-Type': 'application/json'
    },
});

export default testsMonitoringAPI;

