import listRoutes from '@/api/list-routes';
import API from '@/api/api-admin';

const get = (commit, state, id, isRecom) => {
    if (!state.customer || state.customer.id != id) {
        commit('SET_CUSTOMER_LOADING', true);
        commit('SET_CUSTOMER', null);
        API.get(listRoutes[isRecom ? 'recomCustomer' : 'customer'].replace(':id', id))
            .then(res => res.data)
            .then(data => commit('SET_CUSTOMER', data))
            .finally(() => commit('SET_CUSTOMER_LOADING', false))
    } else {
        commit('SET_CUSTOMER_LOADING', false);
    }
}

const state = {
    customer: null,
    customerLoading: null,
    customerOrders: null
}

const mutations = {
    SET_CUSTOMER(state, data) {
        state.customer = data;
    },
    SET_CUSTOMER_LOADING(state, value) {
        state.customerLoading = value;
    },
    SET_CUSTOMER_ORDERS(state, data) {
        state.customerOrders = data
    }
}

const actions = {
    getCustomer({commit, state}, id) {
        return get(commit, state, id, false);
    },
    getRecomCustomer({commit, state}, id) {
        return get(commit, state, id, true);
    },
    editCustomer({commit, state}, data) {
        return new Promise((resolve, reject) => {
            delete data.password;
            API.get(listRoutes.customer.replace(':id', state.customer.id))
                .then(res => res.data)
                .then(customer => {
                    if (customer) {
                        commit('SET_CUSTOMER', customer);
                        resolve("L'utilisateur a bien été modifié")
                    } else {
                        reject("L'api ne fonctionne pas:", listRoutes.customer)
                    }
                })
                .catch(() => reject("L'api ne fonctionne pas:", listRoutes.customer))
        })
    },
}

const getters = {
}

const customer = {state, mutations, actions, getters};

export default customer;