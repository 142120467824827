<template>
    <TemplateDetails :data="store" :loading="storeLoading" :get-function="getStore">
        <PageBack/>
        <PageHeader :title="title">
            <template v-slot:actions>
                <ButtonLink :to="{name: 'client-store-transfer-create'}" size="m" color="grey" icon="plus">{{ $t('client.stock.create_transfer') }}</ButtonLink>
                <ButtonLink :to="{name: 'client-store-transfers'}" size="m" color="grey" icon="refresh">{{ $t('client.stock.see_transfers') }}</ButtonLink>
            </template>
        </PageHeader>
        <div>
            <PageLoader v-if="!storeStock"/>
            <TableSticky v-else-if="storeStock.length && fields?.length" :fields="fields" :data="storeStock"/>
        </div>
    </TemplateDetails>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import TemplateDetails from '@/components/templates/TemplateDetails';
import PageBack from '@/components/ui/page/PageBack';
import PageHeader from '@/components/ui/page/PageHeader';
import TableSticky from '@/components/ui/table-sticky/TableSticky';
import PageLoader from '@/components/ui/page/PageLoader';
import ButtonLink from '@/components/ui/button/ButtonLink';

export default {
    name: 'StoreStock',
    components: {ButtonLink, PageLoader, TableSticky, PageHeader, PageBack, TemplateDetails},
    computed: {
        ...mapState(['store', 'storeLoading', 'storeStock']),
        title() {
            return this.$t('client.stock.title') + ' ' + this.store?.name
        },
        client() {
            return this.$store.getters.getClientById(this.$route.params.id)
        },
        fields() {
            let fields = [
                {key: 'id', type: 'eye', route: 'resale', size: 'xs'},
                {key: 'title', size: 'xxl'},
                {key: 'condition', size: 'm'},
                {key: 'color', size: 'm'},
                {key: 'size', size: 's'},
                {key: 'price', size: 's', type: 'money'},
                {key: 'photo1', size: 'xs', type: 'img', translation: 'photo'},
                {key: 'url', size: 'm', type: 'productUrl'},
            ]
            if (this.storeStock?.length) {
                Object.keys(this.storeStock[0]).forEach(key => {
                    if (key.includes('tag_')) {
                        fields.push({key: key, noTranslate: true, size: 'm', label: key.replace('tag_', 'Tag ')})
                    }
                })
            }
            return fields;
            //if (!this.storeStock?.length) return [];
            //return Object.keys(this.storeStock[0]).map(x => ({key: x}))
        }
    },
    methods: {
        ...mapActions(['getStore', 'getStoreStock'])
    },
    mounted() {
        this.getStoreStock()
    }
}
</script>