<template>
    <div class="config-client">
        <PageLoader v-if="!clients"/>
        <div v-else-if="client">
            <PageBack/>
            <PageHeader :title="client.name">
                <template v-slot:actions>
                    <ButtonLink size="m" icon="shopify" color="grey" :to="client.shopifyUrl + '/admin'">Admin Shopify</ButtonLink>
                    <ButtonLink size="m" icon="home" color="grey" :to="client.frontUrl">Boutique</ButtonLink>
                </template>
            </PageHeader>
            <GridContainer>
                <GridCard>
                    <GridContent>
                        <GridHeader title="Informations"/>
                        <SectionTableInfos :fields="fields" :object="client"/>
                    </GridContent>
                </GridCard>
                <template v-slot:aside v-if="isAdmin">
                    <GridCard v-if="stores && stores.length">
                        <GridContent>
                            <GridHeader :title="$t('global.stores')"/>
                            <TagList>
                                <ButtonLink v-for="(store, i) in [...stores].slice(0, 10)" :key="i" :to="{name: 'client-store', params: {id: id, storeId: store.id}}" icon="right" size="s" color="light-grey">{{ store.name }}</ButtonLink>
                            </TagList>
                            <br>
                            <ButtonLink :to="{name: 'client-stores'}" icon="right" color="black" size="m">Voir toutes les boutiques</ButtonLink>
                        </GridContent>
                    </GridCard>
                </template>
            </GridContainer>
        </div>
        <PageError v-else title="404" :subtitle="$t('client.not-found')" :button="$t('client.return')" :to="{name: 'clients'}"/>
    </div>
</template>

<script>
import PageLoader from '@/components/ui/page/PageLoader';
import { mapGetters, mapState } from 'vuex';
import PageError from '@/components/ui/page/PageError';
import PageHeader from '@/components/ui/page/PageHeader';
import ButtonLink from '@/components/ui/button/ButtonLink';
import SectionTableInfos from '@/components/sections/SectionTableInfos';
import PageBack from '@/components/ui/page/PageBack';
import GridContainer from '@/components/ui/grid/GridContainer';
import GridCard from '@/components/ui/grid/GridCard';
import GridContent from '@/components/ui/grid/GridContent';
import GridHeader from '@/components/ui/grid/GridHeader';
import ApiAdmin from '@/api/api-admin';
import TagList from '@/components/ui/tag/TagList';

export default {
    name: 'ClientDetails',
    components: {GridHeader, TagList, GridContent, GridCard, GridContainer, PageBack, SectionTableInfos, ButtonLink, PageHeader, PageError, PageLoader},
    data() {
        return {
            stores: null,
            fields: [
                {key: 'name'},
                {key: 'nameUrl'},
                {key: 'emailLogistic', type: 'copy'},
                {key: 'frontUrl', type: 'url'},
                {key: 'frontUrlClient', type: 'url'},
                {key: 'shopifyUrl', type: 'url'},
                {key: 'domain', type: 'url'},
                {key: 'codeTypeReal'},
                {key: 'autoCreditedWallet', type: 'boolean'},
                {key: 'isRef', type: 'boolean'},
                {key: 'promoByProduct', type: 'boolean'},
                {key: 'resellActivated', type: 'boolean'},
                {key: 'shopClientActivated', type: 'boolean'},
                {key: 'shopIRLActivated', type: 'boolean'},
                {key: 'skipStep1', type: 'boolean'},
                {key: 'status'},
            ]
        }
    },
    computed: {
        ...mapState(['clients']),
        ...mapGetters(['isAdmin']),
        id() {
            return this.$route.params.id
        },
        client() {
            return this.$store.getters.getClientById(this.id)
        }
    },
    methods: {
        getStores() {
            ApiAdmin.get(this.$listRoutes.stores.replace(':id', this.id))
                    .then(res => this.stores = res.data)
        }
    },
    mounted() {
        this.getStores()
    }
}
</script>

<style lang="scss">
.config-client {
    &__actions {
        display: flex;
        gap: 15px;
        flex-direction: column;

        & > * {
            width: 100%;
        }
    }
}
</style>