<template>
    <label class="toggle-switcher" @click.prevent="$emit('change', !value)">
        <span class="toggle-switcher__label" v-if="label">
            <TextSimple size="s">{{ label }}</TextSimple>
        </span>
        <span class="toggle-switcher__slider" :class="{ 'is-active': value, 'is-disable': isDisable }"></span>
    </label>
</template>

<script>
import TextSimple from '@/components/ui/text/TextSimple';
export default {
    name: 'ToggleSwitcher',
    components: {TextSimple},
    props: {
        model: Boolean,
        label: String,
        isDisable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            value: this.model
        }
    },
    watch: {
        model() {
            this.value = this.model
        }
    }
}
</script>

<style lang="scss">
.toggle-switcher {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
    cursor: pointer;

    &__slider {
        cursor: pointer;
        background-color: var(--color-text-grey);
        transition: background-color ease .4s;
        border-radius: 22px;
        width: 40px;
        position: relative;
        height: 22px;

        &.is-active {
            background-color: var(--color-green-light);

            &:before {
                transform: translateX(18px);
                background: var(--color-green);
            }
        }

        &.is-disable {
            opacity: .3;
            cursor: not-allowed;
        }

        &:before {
            position: absolute;
            content: "";
            height: 18px;
            width: 18px;
            left: 2px;
            bottom: 2px;
            background-color: white;
            transition: background-color ease .4s, transform ease .4s;
            border-radius: 50%;
        }
    }
}
</style>
  
  