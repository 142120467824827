<template>
    <div class="layout-menu-collapse">
        <div class="layout-menu-collapse__button" :class="{'is-open': isOpen}" @click.prevent="isOpen = !isOpen" :data-testid="`${to}-menu`">
            <LayoutMenuLink :icon="icon" :title="title" :to="to" :disabled="true"/>
            <SvgIcon name="chevron"/>
        </div>
        <div class="layout-menu-collapse__content" ref="content" :style="{maxHeight: maxHeight}">
            <slot/>
        </div>
    </div>
</template>

<script>
import LayoutMenuLink from '@/components/ui/layouts/LayoutMenuLink';
import SvgIcon from '@/components/ui/svg/SvgIcon';
export default {
    name: 'LayoutMenuCollapse',
    components: {SvgIcon, LayoutMenuLink},
    props: {
        icon: String,
        title: String,
        to: String
    },
    data() {
        return {
            isOpen: false,
            height: 0
        }
    },
    computed: {
        maxHeight() {
            return this.isOpen ? `${this.height}px` : '0px';
        }
    },
    watch: {
        isOpen() {
            if (this.$refs.content) this.height = this.$refs.content.scrollHeight
        }
    }
}
</script>

<style lang="scss">
.layout-menu-collapse {
    &__button {
        position: relative;
        cursor: pointer;

        .router-link-active {
            background-color: var(--color-bg-grey);
        }

        & > .icon {
            position: absolute;
            right: 5px;
            top: 0;
            height: 100%;
            display: flex;
            align-items: center;
            z-index: 2;
        }

        &.is-open > .icon {
            transform: rotate(180deg);
        }
    }

    &__content {
        transition: max-height linear .3s;
        overflow: hidden;

        & > * {
            padding: 0 0 10px 20px;
        }
    }
}
</style>