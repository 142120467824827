<template>
    <TemplateTable
            :title="$t('menu.resales-not-shooting')"
            name="resalesNotShooting"
            :url="$listRoutes.resalesWaitingShooting"
            :table="resalesNotShooting"
            :filters="resalesNotShootingFilters"
            :fields="fields"
    />
</template>

<script>
import { mapState } from 'vuex';
import TemplateTable from '@/components/templates/TemplateTable';
export default {
    name: 'ResaleWaitingShooting',
    components: {TemplateTable},
    data() {
        return {
            fields: [
                {key: 'id', type: 'eye', route: 'resale', size: 'xs'},
                {key: 'client', type: 'client', size: 'l', noSortable: true},
                {key: 'user', type: 'userEmail', size: 'xl'},
                {key: 'shopifyId', type: 'shopifyProduct', size: 'm'},
                {key: 'createdAt', type: 'date'},
                {key: 'tradeinPrice', type: 'formatPrice'},
                // {key: 'logisticsInformations.carrierSendingDate', type: 'date'},
                // {key: 'logisticsInformations.carrierDeliveryDate', type: 'date'},
                {key: 'logisticsInformations.warehouseReceiveDate', type: 'date'},
                {key: 'logisticsInformations.warehouseOpenDate', type: 'date'},
                {key: 'logisticsInformations.warehouseValidationDate', type: 'date'},
                {key: 'irl', type: 'boolean'},
                {key: 'realStore', type: 'realStore', size: 'xxl'},
                {key: 'country', size: 'xs', noSortable: true},
                {key: 'postChecking', noSortable: true},

                //{key: 'postOpening'},
                //{key: 'postChecking'}
            ]
        }
    },
    computed: {
        ...mapState(['resalesNotShooting', 'resalesNotShootingFilters'])
    }
}
</script>