<template>
    <TemplateTable
            :title="$t('menu.resales-not-review')"
            name="resalesNotReview"
            :url="$listRoutes.resalesWaitingReview"
            :table="resalesNotReview"
            :filters="resalesNotReviewFilters"
            :fields="fields"
    />
</template>

<script>
import { mapState } from 'vuex';
import TemplateTable from '@/components/templates/TemplateTable';
export default {
    name: 'ResalesWaitingReview',
    components: {TemplateTable},
    data() {
        return {
            fields: [
                {key: 'id', type: 'eye', route: 'resale', size: 'xs'},
                {key: 'client', type: 'client', size: 'l', noSortable: true},
                {key: 'user', type: 'userEmail', size: 'xl'},
                {key: 'shopifyId', type: 'shopifyProduct', size: 'm'},
                {key: 'createdAt', type: 'date'},
                {key: 'tradeinPrice', type: 'formatPrice'},
                // {key: 'logisticsInformations.carrierSendingDate', type: 'date'},
                // {key: 'logisticsInformations.carrierDeliveryDate', type: 'date'},
                {key: 'logisticsInformations.warehouseReceiveDate', type: 'date'},
                {key: 'logisticsInformations.warehouseOpenDate', type: 'date'},
                {key: 'country', size: 'xs', noSortable: true},
                {key: 'postOpening', noSortable: true},
            ]
        }
    },
    computed: {
        ...mapState(['resalesNotReview', 'resalesNotReviewFilters'])
    }
}
</script>