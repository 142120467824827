import Vue from 'vue';
import router from '@/router';
import * as Sentry from "@sentry/vue";

export default Sentry.init({
    Vue,
    dsn: "https://cea4581bbdae3aec0119139dff8ee700@o423180.ingest.sentry.io/4505884838330368",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    environment: "production",
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    //replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
// Retrieve user data from localStorage
const userDataString = localStorage.getItem('user');
if (userDataString) {
    const userData = JSON.parse(userDataString);
  
    // Set user data in Sentry context
    Sentry.configureScope(scope => {
      scope.setUser({
        email: userData.email,
        full_name: userData.full_name,
        organization: userData.organization,
        last_login: userData.last_login,
      });
    });
  }
  