<template>
    <TemplateClient :hide-back="true"
        :class="templateMail ? 'template-edit-mail' : templateHtmlMail ? 'template-edit-html-mail' : ''">
        <PageError v-if="!client || error" title="404" :subtitle="errorMsg" />
        <PageLoader v-if="!translations" ref="back" />
        <div v-else>
            <PageBack @click.native="back" :no-action="!!selected" />
            <PageHeader :title="title | replace('{{name}}', client.name)" :subtitle="subtitle"
                :text="noTranslations ? $t('translations.empty') : null">
                <template v-if="templateMail" v-slot:actions>
                    <div>
                        <ButtonCustom v-if="sendTestAvaible" color="white" :border="true" size="m"
                            @click.native.prevent="openEmailSendTest(subtitle)">{{ $t('client.emails.cta_sendtest') }}
                        </ButtonCustom>
                        <ButtonCustom v-if="previewMailAvaible" color="white" :border="true" size="m"
                            @click.native.prevent="internePreview ? interneOpenEmailPreview() : openEmailPreview(subtitle)">
                            {{ $t('client.emails.cta_preview') }}</ButtonCustom>
                    </div>
                    <div v-if="languageSelector">
                        <FieldSelect :multiple="false" :border="true" :selected="langueSelected"
                            :options="avaibleLocales" :label="'Langue'" @input="e => langueSelected = e" />
                    </div>
                </template>
                <template v-else v-slot:actions>
                    <ButtonImport v-if="importRoute" :import-route="importRoute" :action="importTranslations"
                        accept=".xlsx" />
                    <ButtonExport v-if="exportRoute" :text="$t('global.export')" @click.native.prevent="exportCsv" />
                    <ButtonCustom v-if="publishRoute && mailCategory != 'trade-in'" @click.native.prevent="publish"
                        size="m" color="black" icon="shopify">{{ $t('global.publish') }}</ButtonCustom>
                </template>
            </PageHeader>
            <template v-if="templateHtmlMail">
                <TranslationsKey v-for="(translation, i) in translations" :key="i" :templateHtmlMail="templateHtmlMail"
                    @change="currentHtmlContent" :reference="null" :translation="translation" />
            </template>

            <template v-else-if="Object.keys(translations).length">
                <TranslationsGroup class="translations-group-container" v-if="!displayMenu" :translations="translations"
                    :all_translations="translations" :templateMail="templateMail" :initLocale="langueSelected"
                    :templateMailFooter="templateMailFooter" />

                <div v-else-if="!selected">
                    <TranslationTab v-for="(translation, key, i) in translations" :key="i" icon="right"
                        :text="key | translationsKey" @click.native.prevent="selected = key" />
                </div>

                <div v-else>
                    <Field v-show="false" v-if="!noTranslations" type="text" name="term"
                        :label="$t('translations.searchKey')" :model="searchTerm" @change="e => searchTerm = e" />
                    <div v-if="!noTranslations" class="translations">
                        <div class="translations__menu">
                            <ul>
                                <li v-for="(translation, key, i) in translations" :key="i"
                                    :class="{'is-selected': selected === key}" @click.prevent="selected = key">
                                    {{ key | translationsKey }}
                                </li>
                            </ul>
                        </div>
                        <div v-for="(translation, key, i) in translations" :key="i">
                            <TranslationsGroup v-if="selected === key" :translations="translation"
                                :all_translations="translations" />
                        </div>
                    </div>
                </div>

                <Popin v-if="!templateHtmlMail && !templateMail" ref="popinNotPublised"
                    :title="$t('translations.popin_not_publised.title')"
                    :text="$t('translations.popin_not_publised.text')">
                    <template v-slot:bottom>
                        <ButtonCustom @click.native.prevent="leave" size="m" color="red">{{
                            $t('translations.popin_not_publised.cta_ignore') }}</ButtonCustom>
                        <ButtonCustom @click.native.prevent="publishAndLeave" size="m" color="black">{{
                            $t('translations.popin_not_publised.cta_publish') }}</ButtonCustom>
                    </template>
                </Popin>
            </template>

            <template v-if="templateMail || templateHtmlMail">
                <TextSimple v-if="templateMailWarningMessage">{{ templateMailWarningMessage }}</TextSimple>
                <Popin ref="popinEmailSendTest" class="popin-email-send-test" :center="true"
                    :title="$t('client.emails.send_test_popin_title')"
                    :subtitle="$t('client.emails.send_test_popin_texte')">
                    <template v-slot:content>
                        <div v-if="!brandEmailsSendTestEmailMessage">
                            <Field v-for="(field, index) in emailTestfields" :key="index" type="text"
                                :placeholder="$t(field.placeholder)" :required="field.required" :model="field.model"
                                @change="e => field.model = e" />
                            <ButtonCustom color="black" size="m"
                                @click.native.prevent="sendEmailTest(getEmailAddresses())">{{
                                $t('client.emails.cta_sendtest') }}</ButtonCustom>
                        </div>
                        <div v-else>
                            <TextSimple class="confirmation-text">
                                <SvgIcon name="check" /><br>{{ brandEmailsSendTestEmailMessage }}
                            </TextSimple>
                        </div>
                    </template>
                </Popin>
                <Popin ref="popinEmailPreview" :center="true" :fullScreen="true" class="popin-email-preview"
                    @close="closePopin">
                    <template v-slot:content>
                        <div ref="shadowHost" v-if="renderShadowHost"></div>
                    </template>
                </Popin>
            </template>
        </div>
    </TemplateClient>
</template>

<script>

import TemplateClient from '@/components/templates/TemplateClient';
import TranslationsGroup from '@/components/ui/translations/TranslationsGroup';
import PageLoader from '@/components/ui/page/PageLoader';
import PageError from '@/components/ui/page/PageError';
import PageHeader from '@/components/ui/page/PageHeader';
import TextSimple from '@/components/ui/text/TextSimple';
import ButtonExport from '@/components/ui/button/ButtonExport';
import ButtonCustom from '@/components/ui/button/Button';
import Field from '@/components/ui/form/fields/Field';
import FieldSelect from '@/components/ui/form/fields/FieldSelect';
import PageBack from '@/components/ui/page/PageBack';
import TranslationTab from '@/components/ui/translations/TranslationTab';
import TranslationsKey from '@/components/ui/translations/TranslationsKey';
import ApiAdmin from '@/api/api-admin';
import ButtonImport from '@/components/ui/button/ButtonImport';
import Popin from '@/components/ui/popin/Popin';
import { mapActions, mapState } from 'vuex';

export default {
    name: 'TemplateTranslations',
    components: { TemplateClient, Popin, ButtonImport, TranslationTab, PageBack, Field, ButtonCustom, ButtonExport, PageHeader, PageError, PageLoader, TranslationsGroup, TextSimple, FieldSelect, TranslationsKey },
    props: {
        getTranslations: Function,
        translations: [Array, Object],
        title: String,
        subtitle: String,
        importRoute: String,
        publishRoute: String,
        exportRoute: String,
        errorMsg: String,
        displayMenu: Boolean,
        displayTab: Boolean,
        defaultLocale: String,
        avaibleLocales: Array,
        languageSelector: {
            type: Boolean,
            default: true
        },
        templateMail: Boolean,
        templateHtmlMail: Boolean,
        templateMailFooter: {
            type: Boolean,
            default: false
        },
        sendTestAvaible: Boolean,
        previewMailAvaible: Boolean,
        templateMailWarningMessage: String,
        mailCategory: String,
        internePreview: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            error: false,
            selected: null,
            searchTerm: null,
            translationsSearched: null,
            resolveNavigation: null,
            langueSelected: null,
            currentMailKeyOpened: null,
            renderShadowHost: false,
            emailTestfields: [
                { placeholder: 'client.emails.send_test_placeholder_mail1', required: true, model: null },
                { placeholder: 'client.emails.send_test_placeholder_mail2', required: false, model: null },
                { placeholder: 'client.emails.send_test_placeholder_mail2', required: false, model: null },
            ],
            htmlContent: null
        }
    },
    computed: {
        ...mapState(['translationsNotPublished', 'brandEmailsPreview', 'brandEmailsSendTestEmailMessage']),
        id() {
            return this.$route.params.id
        },
        client() {
            return this.$store.getters.getClientById(this.id)
        },
        noTranslations() {
            if (this.translations && Array.isArray(this.translations)) {
                return !this.translations.length;
            } else if (this.translations && typeof this.translations === 'object') {
                return !Object.keys(this.translations).length;
            }
            return false;
        }
    },
    methods: {
        ...mapActions(['importTranslations', 'unsetTranslations', 'setTranslationsNotPublished', 'setNotificationMessage', 'getBrandEmailsPreview', 'postBrandEmailsSendTest', 'setNotificationMessage', 'setTranslationsNotPublished']),
        getEmailAddresses() {
            let addresses = [];
            this.emailTestfields.forEach(field => {
                if (field.model) {
                    addresses.push(field.model);
                }
            });
            return addresses.length > 0 ? addresses : null;
        },
        openEmailSendTest(data) {
            this.currentMailKeyOpened = data;
            this.$refs.popinEmailSendTest.open();
        },
        async sendEmailTest(data) {
            await this.postBrandEmailsSendTest({ idClient: this.id, locale: this.langueSelected, mailKey: this.currentMailKeyOpened, adressEmail: data });
            setTimeout(() => {
                this.$refs.popinEmailSendTest.close();
                this.$store.commit('SET_EMAILS_SEND_TEST_MESSAGE', null);
            }, 3000);
        },
        interneOpenEmailPreview() {
            this.renderShadowHost = true;
            setTimeout(() => {
                const shadowRoot = this.$refs.shadowHost.attachShadow({ mode: 'open' });
                shadowRoot.innerHTML = this.htmlContent ? this.htmlContent : this.translations[0].html;
                this.$refs.popinEmailPreview.open();
            }, 500);
        },
        async openEmailPreview(data) {
            this.renderShadowHost = true;
            await this.getBrandEmailsPreview({ idClient: this.id, locale: this.langueSelected, mailKey: data });
            const shadowRoot = this.$refs.shadowHost.attachShadow({ mode: 'open' });
            shadowRoot.innerHTML = this.brandEmailsPreview.html;
            this.$refs.popinEmailPreview.open();
        },
        closePopin() {
            this.renderShadowHost = false;
        },
        publish() {
            ApiAdmin.get(this.publishRoute.replace(':id', this.client.id))
                .finally(() => {
                    this.setNotificationMessage(this.$t('translations.publish_message'));
                    this.setTranslationsNotPublished(false)
                })
        },
        exportCsv() {
            ApiAdmin.get(this.exportRoute.replace(':id', this.client.id), { responseType: 'blob' })
                .then(res => {
                    let filename = (res.filename || 'translations').split('.')[0] + '.xlsx';
                    let blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    if (navigator.msSaveBlob) {
                        navigator.msSaveBlob(blob, filename);
                    } else {
                        let link = document.createElement('a');
                        if (link.download !== undefined) {
                            let url = URL.createObjectURL(blob);
                            link.setAttribute('href', url);
                            link.setAttribute('download', filename);
                            link.style.display = 'none';
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                            URL.revokeObjectURL(url);
                        }
                    }
                });
        },
        back(e) {
            if (this.selected) {
                e.preventDefault();
                this.selected = null;
            }
        },
        publishAndLeave() {
            this.publish();
            this.leave();
        },
        leave() {
            this.setTranslationsNotPublished(false);
            this.$refs.popinNotPublised.close();
            if (this.resolveNavigation) {
                this.resolveNavigation();
            }
        },
        routeLeaving(to, from, next) {
            if (this.translationsNotPublished && (!this.templateMail && !this.templateHtmlMail)) {
                this.$refs.popinNotPublised.open();
                next(false);
                new Promise(resolve => {
                    this.resolveNavigation = resolve;
                }).then(() => {
                    next();
                });
            } else {
                next();
            }
        },
        currentHtmlContent(content) {
            this.htmlContent = content;
        }
    },
    mounted() {
        this.langueSelected = this.defaultLocale;
        if (this.getTranslations && this.id) {
            this.getTranslations(this.id)
                .catch(() => this.error = true)
        }
    },
    beforeMount() {
        this.unsetTranslations();
    }
}
</script>

<style lang="scss">
.translations {
    &__menu {
        width: 100%;
        position: sticky;
        top: 0;
        z-index: 4;
        border-bottom: var(--border-1);
        margin: 30px 0;
        background-color: var(--color-bg-light);

        ul {
            display: flex;
            overflow-x: auto;
            padding: 10px 0;
            gap: 5px;

            &::-webkit-scrollbar {
                height: 14px;
                border-radius: 10px;
                overflow: hidden;
                cursor: pointer;
            }

            &::-webkit-scrollbar-track {
                background: var(--color-bg-grey);
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb,
            &::-webkit-scrollbar-thumb:hover {
                background: var(--color-text-grey);
                border-radius: 10px;
                border: solid 4px var(--color-bg-grey);
            }
        }

        li {
            cursor: pointer;
            padding: 7px 10px;
            color: var(--color-text-grey);
            flex-shrink: 0;
            border-radius: 20px;
            font-size: 1.3rem;
            border: solid 1px transparent;
            transition: color ease .2s, background-color ease .2s, border ease .2s;

            &:hover {
                color: #000;
                border: var(--border-1);
            }

            &.is-selected {
                color: #000;
                background-color: #fff;
                border: var(--border-2);
            }
        }
    }
}

.template-edit-mail{
    .text {
        margin: 40px 0 80px 0;
    }
    .page-header{
        margin-bottom: 0px;

        &__actions{
            display: flex;
            flex-direction: column;
            gap: 20px;

            & > div {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                justify-content: end;
                width: 100%;
                gap: 20px;

                .field-select {
                    width: 100%;
                }
            }
        }
    }
    .button-form{
        margin-top: 0px;
    }
    .markdown-editor .ql-toolbar.ql-snow{
        position: initial;
    }
    .translations-group-container{
        & > div {
            display: flex;
            flex-direction: column;
        }
    }

    .translations-key__updated{
        margin: initial;
    }
}
</style>