import API from '@/api/api-admin';
import listRoutes from '@/api/list-routes';

const state = {
    ordersReturn: null,
    ordersReturnLoading: false,
}

const mutations = {
    SET_FINANCE_RETURN(state, data) {
        state.ordersReturn = data
    },
    SET_FINANCE_RETURN_LOADING(state, value) {
        state.ordersReturnLoading = value
    }
}

const actions = {
    getReturn({commit}, shopifyId) {
        commit('SET_FINANCE_RETURN', null);
        commit('SET_FINANCE_RETURN_LOADING', true);
        return new Promise((resolve, reject) => {
            API.get(listRoutes.ordersReturn.replace(':id', shopifyId))
                .then(res => {
                    if (!res.data || !res.data.id) reject();
                    else {
                        commit('SET_FINANCE_RETURN', res.data);
                        resolve()
                    }
                })
                .catch(() => reject())
                .finally(() => commit('SET_FINANCE_RETURN_LOADING', false))
        })
    }
}

const getters = {

}

const returns = {
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default returns