<template>
    <div>
        <div class="grid-card__header">
            <div class="grid-card__title">
                <TextTitle v-if="currentCatalogue.title" size="s" >{{ currentCatalogue.title }}</TextTitle>
                <TagItem v-if="isCurrentCatalogueIsInDefaultLang" :text="$t('catalogue.default')" color="blue" icon-left="stars"/>
            </div>
            <div class="grid-card__row" v-if="!currentCatalogue.active">
                <ButtonLink 
                    v-for="(button,index) in buttons" :key="index"
                    v-show="button.condition"
                    :to="button.to"
                    :icon="button.icon" 
                    :size="button.size"
                    :isAnimated="button.isAnimated"
                    :color="button.color"
                    :title="button.translation"
                >
                </ButtonLink>
                <Button 
                    @click.native="$refs.popinDeleteCatalogue.open" 
                    color="red" 
                    size="s" 
                    icon="trash"
                    :title="$t('catalogue.delete')">
                </Button>
            </div>
            <div class="grid-card__row" v-else>
                <!-- :to="{name: 'config-website-catalogue-update', params:{catalogueID: this.currentCatalogue.id, lang: this.currentCatalogue.lang}}"  -->
                <ButtonLink 
                    :to="{name:'config-website-catalogue-create'}"
                    icon="edit" 
                    color="grey" 
                    size="s"
                    :isAnimated="true"
                    :title="$t('catalogue.edit')">{{ $t('catalogue.edit') }}</ButtonLink>
                <Button 
                    @click.native="$refs.popinDeleteCatalogue.open" 
                    color="red" 
                    size="s" 
                    icon="trash"
                    :title="$t('catalogue.delete')"
                    ></Button>
            </div>
        </div>

        <CatalogueStatus :status="currentCatalogue" />

        <Popin :title="$t('catalogue.delete')" :subtitle="$t('catalogue.about_to_delete')" :center="true" ref="popinDeleteCatalogue">
            <template v-slot:content>
                <Button :loading="deleteLoading" color="red" size="m" @click.native.prevent="deleteThisCatalogue">{{ $t('global.yes') }}</Button>
            </template>
            <template v-slot:bottom>
                <LinkUnderline @click.native="$refs.popinDeleteCatalogue.close">{{ $t('global.cancel') }}</LinkUnderline>
            </template>
        </Popin>        

        <Popin :title="$t('catalogue.delete_success')" ref="popinDeleteConfirmCatalogue">
            <template v-slot:bottom>
                <LinkUnderline @click.native="$refs.popinDeleteConfirmCatalogue.close">{{ $t('global.close') }}</LinkUnderline>
            </template>
        </Popin>
    </div>
</template>

<script>
import TextTitle from '@/components/ui/text/TextTitle';
import Button from '@/components/ui/button/Button';
import ButtonLink from '@/components/ui/button/ButtonLink';
import CatalogueStatus from '@/components/ui/catalogue/CatalogueStatus';
import Popin from '@/components/ui/popin/Popin';
import CatalogueAPI from '@/api/api-catalogue';
import LinkUnderline from '@/components/ui/link/LinkUnderline'
import TagItem from '@/components/ui/tag/Tag';

export default {
    components: {
        TextTitle,
        Button,
        ButtonLink,
        CatalogueStatus,
        Popin,
        LinkUnderline,
        TagItem
    },
    props: {
        currentCatalogue: Object,
        title: String,
        lang: String,
    },
    data() {
        return {
            deleteLoading:false,
            hoverStates: []
        }
    },
    computed: {
        buttons() {
            if(this.currentCatalogue !== null){
                return [
                        // {
                        //     translation:this.currentCatalogue.status.validation.isValidated ? `${this.$t('catalogue.validation_results')}` :`${this.$t('catalogue.generation_btn_errors')}`,    
                        //     condition:this.currentCatalogue.status.validation.isValidated,
                        //     icon:"dots_horizontal", 
                        //     to:{name: 'config-website-catalogue-validation', params:{catalogueID: this.currentCatalogue.id, lang: this.currentCatalogue.lang}}, 
                        //     size:"s", 
                        //     color:this.currentCatalogue.status.validation.isValidated && this.currentCatalogue.status.validation.status === 'succeeded' ? "grey" : "red" ,
                        //     hover:false,
                        //     isAnimated:true,
                        // },
                        // {
                        //     translation:`${this.$t('catalogue.generation_btn')} test++`,    
                        //     // condition:this.currentCatalogue.cohorts.length > 0,
                        //     condition:true,
                        //     icon:"publish", 
                        //     to:{name: 'config-website-catalogue-validation', params:{catalogueID: this.currentCatalogue.id, lang: this.currentCatalogue.lang}}, 
                        //     size:"s",
                        //     color:"grey",
                        //     hover:false,
                        //     isAnimated:true,
                        // },
                        // {
                        //     translation:`${this.$t('cohorts.verify')} test`,    
                        //     // condition:this.currentCatalogue.cohorts.length > 0,
                        //     condition:true,
                        //     icon:"form", 
                        //     to:{name: 'config-website-cohorts-validation', params:{catalogueID: this.currentCatalogue.id, lang: this.currentCatalogue.lang}}, 
                        //     size:"s", 
                        //     color:"grey",
                        //     hover:false,
                        //     isAnimated:true,
                        // },
                        // {   
                        //     translation:`${this.$t('catalogue.setup')}`,
                        //     condition:true,
                        //     icon:"check", 
                        //     to:{name: 'config-website-catalogue-mapping', params:{catalogueID: this.currentCatalogue.id, lang: this.currentCatalogue.lang}}, 
                        //     size:"s", 
                        //     color:"grey",
                        //     hover:false,
                        //     isAnimated:true,
                        // },
                        // {
                        //     translation:`${this.$t('catalogue.edit')}`,    
                        //     condition:true,
                        //     icon:"edit", 
                        //     to:{name: 'config-website-catalogue-edit', params:{catalogueID: this.currentCatalogue.id, lang: this.currentCatalogue.lang}}, 
                        //     size:"s", 
                        //     color:"grey",
                        //     hover:false,
                        //     isAnimated:true,
                        // },
                        // {
                        //     translation:`${this.$t('cohorts.import_pricing')}`,
                        //     condition:this.currentCatalogue.configuration,
                        //     icon:"wallet", 
                        //     to:{name: 'config-website-cohorts-create', params:{catalogueID: this.currentCatalogue.id, lang: this.currentCatalogue.lang}}, 
                        //     size:"s", 
                        //     color:"grey",
                        //     hover:false,
                        //     isAnimated:true,
                        // },
                ]
            }
            return null
        },
        isCurrentCatalogueIsInDefaultLang(){
            return this.currentCatalogue.lang === this.lang
        }

    },
    methods: {
        deleteThisCatalogue(){     
              
            const lang = this.$route.params.lang ?? this.currentCatalogue.lang

            CatalogueAPI.delete(this.$listRoutes.catalogueDelete.replace(':id',this.currentCatalogue.id))
                .then(() => this.$refs.popinDeleteCatalogue.close())
                .finally(()=> {
                    this.$refs.popinDeleteConfirmCatalogue.open()
                    this.$store.commit('DELETE_CATALOGUE',{id:this.currentCatalogue.id,lang:lang})
                })
        },
        setHoverState(index, isHovered) {
            this.$set(this.hoverStates, index, isHovered);
        },
        getHoverState(index) {
            return this.hoverStates[index] || false;
        }
    },
}
</script>

<style lang="scss" scoped>
.grid-card {
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid var(--color-bg-light);
        padding-bottom: var(--field-padding);;
        flex-wrap: wrap;
        @media screen and (max-width: 550px) {
        }
    }
    &__title {
        display: flex;
        align-items: center;
        gap: 15px;
        & > svg {
            --svg-color:red
            fill: var(--svg-color);
        }
    }
    &__row {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
        @media screen and (max-width: 550px) {
            margin: 1rem auto;
        }
    }
    &__catalogue {
        &--btn {
            span > span{
                display: none;
            }
        }
    }
    // .button.button--grey.button--s span {display:none}
}
</style>
