<template>
  <TemplateDetails :loading="retailTransferLoading" :data="retailTransfer" :get-function="getRetailTransfer">
      <div v-if="transfer">

          <PageBack /> 
          <TemplateTable
                :title="$t('client.transfer.title', {id: $route.params.transferId})"
                :subtitle="subtitle"
                :url="$listRoutes.retailTransferArticles.replace(':transferId', $route.params.transferId)"
                :fields="fieldsArticles"
                name="retailTransferArticles"
                :filters="retailTransferArticlesFilters"
                :table="retailTransferArticles">

              <template v-slot:top>
                  <GridContainer>
                      <GridCard>
                          <GridContent>
                              <SectionTableInfos :object="transfer" :fields="fields"/>
                          </GridContent>
                      </GridCard>
                      <template v-slot:aside>
                          <GridCard>
                              <GridContent>
                                  <GridHeader :title="$t('global.event')"/>
                                  <GridCalendar :dates="timeline" :object="eventsObject"/>
                              </GridContent>
                          </GridCard>
                      </template>
                  </GridContainer>
              </template>

          </TemplateTable>
      </div>
  </TemplateDetails>
</template>

<script>
import TemplateDetails from '@/components/templates/TemplateDetails';
import { mapState, mapActions } from 'vuex';
import PageBack from '@/components/ui/page/PageBack';
import GridContainer from '@/components/ui/grid/GridContainer';
import GridCard from '@/components/ui/grid/GridCard';
import GridContent from '@/components/ui/grid/GridContent';
import SectionTableInfos from '@/components/sections/SectionTableInfos';
import TemplateTable from '@/components/templates/TemplateTable';
import GridHeader from '@/components/ui/grid/GridHeader';
import GridCalendar from '@/components/ui/grid/GridCalendar';

export default {
  name: 'RetailTransfer',
  components: { GridCalendar, GridHeader, TemplateTable, SectionTableInfos, GridContent, GridCard, GridContainer, PageBack, TemplateDetails},
  data() {
      return {
          deleting: false,
          fields: [
              {key: 'id', type: 'copy'},
              {key: 'store.name', label: 'store'},
              {key: 'countArticles'},
          ],
          fieldsIsInit: false,
          fieldsArticles: [
              {key: 'id', size: 'xs', type: 'productLightbox', label: ' ', noTranslate: true},
              {key: 'shopify_id', label: 'shopifyId', size: 'm', type: 'copy'},
              {key: 'stage', label: 'status', size: 'm', type: 'transferStage'},
              {key: 'title', size: 'xl'},
              {key: 'color', size: 's'},
              {key: 'size', size: 's'},
              {key: 'etat', label: 'condition', size: 's'},
              {key: 'price', size: 's', type: 'money'},
              {key: 'origin_price', size: 's', type: 'money'},
              {key: 'photo1', size: 'xs', type: 'productImg', translation: 'photo'},
              {key: 'url', size: 'm', type: 'productUrl'},
          ]
      }
  },
  computed: {
      ...mapState(['retailTransferLoading', 'retailTransfer', 'retailTransferArticles', 'retailTransferArticlesFilters', 'retailTransferTimeline']),
      transfer() {
          let retailTransfer = {...this.retailTransfer}
          if( retailTransfer.events ) {
              retailTransfer.events = Array
                  .from(new Set(retailTransfer?.events.map(a => a.createdAt.substr(0, 16)+a.event)))
                  .map((id)=>{
                      return retailTransfer.events.find(a => a.createdAt.substr(0, 16)+a.event === id)
                  })
          }
          return retailTransfer;
      },
      subtitle() {
          return this.transfer?.store?.name
      },
      status() {
          if (!this.transfer?.events) return null;
          return this.transfer?.events[this.transfer?.events?.length - 1].event
      },
      timeline() {
          const timeline = this.transfer?.events?.map((e) => {
              return {
                  key: e.event+e.createdAt, 
                  translation: `client.transfers.stages.${e.event}`
              }
          });
          return timeline
      },
      eventsObject() {
          if (!this.transfer || !this.transfer.events) return {};
          let events = {};
          this.transfer?.events?.forEach((e) => {
              events[e.event+e.createdAt] = e.createdAt
          });
          return events
      },
      canBeDeleted() {
          return this.transfer?.events?.length < 2;
      },
  },
  methods: {
      ...mapActions(['getRetailTransfer', 'getRetailTransferTimeline', 'deleteRetailTransfer']),
      getExtraFields() {
          const articles = this.retailTransferArticles?.items;
          if (!this.fieldsIsInit && articles?.length) {
              const newFields = Object.keys(articles[0])
                      .filter(x => x.includes('tag_'))
                      .map(x => ({
                          key: x,
                          size: 'm',
                          label: x.replace('tag_', 'Tag '),
                          noTranslate : true
                      }));
              this.fieldsArticles = [...this.fieldsArticles, ...newFields]
          }
      }
  },
  watch: {
      retailTransferArticles() {
          //this.getExtraFields()
      },
  },
  mounted() {
    //   if (!this.retailTransferTimeline) this.getRetailTransferTimeline();
      //this.getExtraFields()
  }
}
</script>

