<template>
    <div class="tooltip" @mouseleave="isHover = false">
        <div class="tooltip__button" @click.prevent="isOPen = !isOPen" @mouseenter="isHover = true">
            <slot name="button"/>
        </div>
        <div class="tooltip__content" :class="{'is-open': isOPen, 'is-hover': isHover}" @mouseleave="isHover = false">
            <button class="tooltip__close" @click.prevent="isOPen = false">
                <SvgIcon name="cross"/>
            </button>
            <slot name="content"/>
        </div>
    </div>
</template>

<script>
import SvgIcon from '@/components/ui/svg/SvgIcon';
export default {
    name: 'PopinTooltip',
    components: {SvgIcon},
    data() {
        return {
            isOPen: false,
            isHover: false,
            position: String
        }
    }
}
</script>

<style lang="scss">
.tooltip {
    position: relative;

    &__button {
        position: relative;
        z-index: 0;
        padding-top: 10px;
    }

    &__close {
        margin-bottom: 10px;
        margin-right: 0;
        margin-left: auto;

        @media screen and (min-width: 1025px) {
            display: none;
        }
    }

    &__content {
        position: absolute;
        z-index: 5;
        background-color: #fff;
        box-shadow: var(--box-shadow-1);
        border-radius: var(--border-radius-2);
        width: fit-content;
        max-width: 100%;
        padding: 20px;
        bottom: 100%;
        left: 0;
        opacity: 0;
        pointer-events: none;
        transform: translateY(10px);
        transition: opacity ease-out .3s, transform ease-out .3s;

        @media screen and (min-width: 1025px) {
            &.is-hover {
                opacity: 1;
                transform: translateY(0);
                pointer-events: auto;
            }
        }

        @media screen and (max-width: 1024px) {
            &.is-open {
                opacity: 1;
                transform: translateY(0);
                pointer-events: auto;
            }
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 20px;
            bottom: -5px;
            background-color: #fff;
            width: 10px;
            height: 10px;
            transform: rotate(45deg);
            z-index: 2;
        }
    }
}
</style>