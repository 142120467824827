<template>
    <TemplateDetails :data="catalogues" :loading="cataloguesLoading" :get-function="getCatalogues">

        <PageBack/>
        <PageHeader :title="title"></PageHeader>

        <Form class="catalogue-form" 
            :fields="fields" 
            :model="model" 
            :filetext="$t('catalogue.form_csv')"
            :required="true"
            :action="catalogueEdit" 
            @success="e => editCatalogueSuccess(currentCatalogue)" 
        />

        <Popin :title="catalogueEdition ? $t('catalogue.edit_success') : $t('catalogue.fail') " ref="popinEditCatalogue">
            <template v-slot:content>
                <ButtonLink :to="{name: 'config-website-catalogue-mapping', params:{ catalogueID: catalogueEditID }}" :loading="addLoading" color="green" size="m" >{{ $t('catalogue.next_step') }}</ButtonLink>
            </template>
        </Popin>

    </TemplateDetails>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import PageHeader from '@/components/ui/page/PageHeader.vue';
import TemplateDetails from '@/components/templates/TemplateDetails.vue';

import PageBack from '@/components/ui/page/PageBack.vue';
import Popin from '@/components/ui/popin/Popin.vue';
import ButtonLink from '@/components/ui/button/ButtonLink.vue';

import Form from '@/components/ui/form/Form.vue';

export default {
    name:'ConfigWebsiteCatalogueEdit',
    components: { 
        TemplateDetails,
        PageHeader,
        PageBack,
        Popin,
        ButtonLink,
        Form,
    },
    props: {
        catalogueID: {
            type: String,
        },
    },
    data() {
        return {
            addLoading:false,
            model: {
            title: null,
            lang: null,
            delimiter: null,
            header:1,
            client_id:parseInt(this.$route.params.id)
            },
            fields: [
                {name: "title", type: 'text', label:"title", required: true},
                {
                    name: "lang",
                    label:"lang",
                    type: "select",
                    required: true,
                    options: [
                        { name:'France', value:'fr_FR'},
                        { name:'Royaume Uni', value:'en_GB'},
                        { name:'Hollande', value:'nl_NL'},
                        { name:'Belgique', value:'nl_BE'},
                        { name:'Espagne', value:'es_ES'},
                    ],
                },
                {
                    name: "delimiter",
                    label:"delimiter",
                    type: "select",
                    required: true,
                    options: [
                        {name:`${this.$t('catalogue.create_comma')}`, value:','},
                        {name:`${this.$t('catalogue.create_semicolon')}`, value:';'},
                        {name:`${this.$t('catalogue.create_tab')}`, value:'\t'},
                        {name:`${this.$t('catalogue.create_pipe')}`, value:'|'},
                    ],
                },
                {
                    name: "file",  
                    type: 'text', 
                    label:"file",
                    disabled:true
                },

            ],
                errorFileFormat:false,
                catalogueEdition:false,
                catalogueEditID:null,
            }
    },
    watch: {
        currentCatalogue(newValue) {
            this.model = newValue;
        },
    },
    computed: {
        ...mapState(['clients','catalogues','cataloguesLoading']),
        ...mapGetters(['getCurrentCatalogue']),
        id() {
            return this.$route.params.id
        },
        lang() {
            return this.$route.params.lang
        },
        client() {
            return this.$store.getters.getClientById(this.id)
        },
        title(){
            return !this.catalogueID 
            ? `${this.$t('catalogue.create')} ${this.client.name}` 
            : `${this.$t('catalogue.update')} ${this.client.name}`
        },
        currentCatalogue(){
            const originalObject = { ...this.getCurrentCatalogue(this.lang)(this.catalogueID) };

            const fileName = originalObject.file && originalObject.file.name ? originalObject.file.name : '';

            const transformedObject = {
            id: originalObject.id,
            title: originalObject.title,
            lang: originalObject.lang,
            file: fileName,
            };

            return transformedObject;
        },    
        },
        methods: {
            ...mapActions(['fetchCatalogues','catalogueEdit']),

            editCatalogueSuccess(data){
                this.catalogueEdition = true
                this.catalogueEditID = data.id
                this.$refs.popinEditCatalogue.open();
            },
            getCatalogues() {
                this.fetchCatalogues(this.id)
            },

        },
}
</script>
<style lang="scss" scoped>
    .catalogue-form {
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-width: 440px;
    }
</style>