<template>
    <img class="product-image" :class="{'is-placeholder': src === defaultImage}" :src="src || defaultImage" alt="Faume" @error="src = defaultImage">
</template>

<script>
export default {
    name: 'ImageProduct',
    props: {
        image: String
    },
    data() {
        return {
            defaultImage: '/img/placeholder/placeholder-tshirt.png',
            src: this.image
        }
    }
}
</script>

<style lang="scss">
.product-image {
    &.is-placeholder {
        object-position: center !important;
    }
}
</style>