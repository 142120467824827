<template>
    <TemplateTranslations :title="'{{name}}'"
        ref="page"
        :subtitle="$route.query.mailKey"
        :mailCategory="$route.query.mailCategory"
        :get-translations="getBrandEmailsEditTranslations"
        :translations="filteredEmails"
        :displayMenu="false"
        :displayTab="false"
        :defaultLocale="defaultLocale"
        :avaibleLocales="avaibleLocales"
        :languageSelector="true"
        :templateMail="true"
        :templateHtmlMail="false"
        :sendTestAvaible="true"
        :previewMailAvaible="true"
        :templateMailWarningMessage="$t('global.templateMailWarningMessage')"
        :error-msg="$t('client.emails.error')"
        :publish-route="$listRoutes.websiteEditEmailsPublish"/> 
</template>

<script>

import { mapActions, mapState } from 'vuex';
import TemplateTranslations from '@/components/templates/TemplatesTranslations';

export default {
    name: 'ClientEditEmails',
    components: { TemplateTranslations },
    computed: {
        ...mapState(['brandEmailsEditTranslations', 'clientLocales']),
        filteredEmails() {
            return this.getNestedProperty(this.brandEmailsEditTranslations, this.$route.query.mailKey) || {};
        },
        avaibleLocales() {
            return this.clientLocales.map(locale => {
                return {
                    label: this.$t('locales.' + locale.code.replace(/_.*/, '')) || locale.code.replace(/_.*/, ''),
                    id: locale.code
                }
            })
        },
        defaultLocale() {
            return this.clientLocales.find(locale => locale.isDefault === true)?.code ? this.clientLocales.find(locale => locale.isDefault === true)?.code : 'fr_FR';
        }
    },
    methods: {
        ...mapActions(['getBrandEmailsEditTranslations', 'getClientLocales']),
        getNestedProperty(obj, path) {
            return path.split('.').reduce((o, p) => (o ? o[p] : null), obj);
        }
    },
    created() {
        if(this.$route.params.id) {
            this.getClientLocales(this.$route.params.id);
        }
    },
    beforeRouteLeave(to, from, next) {
        this.$refs.page.routeLeaving(to, from, next)
    }
}
</script>