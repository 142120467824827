<template>
  <GridCard>
    <GridContent>
        <GridHeader :title="title" />
        <FieldSelect
            :multiple="true"
            :options="options"
            :border="true"
            :selected="value"
            :sorted="false"
            @input="e => $emit('change', value = e)"
            />
    </GridContent>
  </GridCard>
</template>

<script>
import GridCard from '@/components/ui/grid/GridCard.vue';
import GridContent from '@/components/ui/grid/GridContent.vue';
import GridHeader from '@/components/ui/grid/GridHeader.vue';
import FieldSelect from '@/components/ui/form/fields/FieldSelect.vue';
export default {
    components: {
        GridCard,
        GridContent,
        GridHeader,
        FieldSelect
    },
    props: {
        title: String,
        options: Array,
        model: String,
    },
    data() {
        return {
            value: []
        }
    },
    watch: {
        previouslyDefinedValues: {
            handler(newValue){
                if(newValue) {
                    this.value = this.previouslyDefinedValues;
                    this.$emit('change', this.value)
                }
            }
        }
    },
    methods: {
        extractValues(str) {
            // This regex will match words that are after # and end before ; or a space
            const regex = /#(\w+)(;|\s|$)/g;
            const matches = [];
            let match;

            // Loop over the string to find all matches
            while ((match = regex.exec(str)) !== null) {
                matches.push(match[1]);
            }

            return matches;
        }
    },
    computed: {
        previouslyDefinedValues() {
            return this.model ? this.extractValues(this.model) : []
        }
    },


}
</script>

<style lang="scss">
</style>