<template>
    <div class="input-calendar">
        <v-date-picker :key="period1+' '+period2+ ' '+date"
                v-model="model"
                color="gray"
                :locale="$i18n.locale"
                :columns="$screens({ xl: columns }, 1)"
                :rows="$screens({md: rows }, 1)"
                :model-config="config"
                :mode="displayHours ? 'dateTime': 'date'"
                is-expanded
                :is-range="!date && !isDate"
                :max-date="max"
                :attributes="attrs"
                :is24hr="$i18n.locale === 'fr' && displayHours"
                :first-day-of-week="$i18n.locale === 'fr' ? 2 : 1"/>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: 'InputCalendar',
    props: {
        columns: {
            type: Number,
            default: 1
        },
        rows: {
            type: Number,
            default: 1
        },
        date: String,
        isDate: Boolean,
        period1: String,
        period2: String,
        highlight: Object,
        displayHours: Boolean,
        disabledToday: Boolean
    },
    data() {
        return {
            config: {
                type: 'string',
                mask: this.displayHours ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD'
            },
            model: this.date ? this.date : {
                start: this.period1,
                end: this.period2
            }
        }
    },
    computed: {
        max() {
            if (!this.disabledToday) return new Date();
            return moment().subtract(1, 'd').toDate()
        },
        range() {
            return {
                start: this.period1,
                end: this.period2
            }
        },
        attrs() {
            if (!this.highlight) return [];
            return [
                {
                    dot: true,
                    dates: this.highlight
                }
            ]
        }
    },
    watch: {
        period1(e) {
            this.model.start = e
        },
        period2(e) {
            this.model.end = e
        },
        model() {
            this.$emit('change', this.date || this.isDate ? this.model : {
                start: this.model.start,
                end: this.model.end
            })
        }
    }
}
</script>

<style lang="scss">
.input-calendar {
    width: 100%;

    .vc-container {
        border: 0;
        --gray-500: var(--color-text-grey);
        --gray-600: var(--color-text-grey);
        --gray-700: var(--color-text-grey);
        --gray-400: var(--color-bg-light);
        --gray-300: var(--color-bg-light);
        --gray-200: var(--color-bg-light);
        --gray-100: var(--color-bg-light);
    }

    .vc-header {
        margin-bottom: 15px;
    }

    .vc-title {
        font-size: 1.6rem;
        color: #000;
        font-weight: 400;
    }

    .vc-arrow {
        border-radius: 100%;
        border: var(--border-1);

        svg {
            transform: scale(.7);
        }
    }

    .vc-weekday {
        font-size: 1.2rem !important;
    }

    .vc-day-content {

        &:hover,
        &:focus {
            //background-color: var(--color-green-light) !important;
        }
    }

    .vc-dot {
        background-color: var(--color-green) !important;
    }

    .vc-time-picker {
        justify-content: center;
        padding-bottom: 0 !important;

        .vc-date {
            display: none;
        }
    }
}
</style>