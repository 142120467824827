<template>
    <div class="grid-content">
        <slot/>
    </div>
</template>

<script>
export default {
    name: 'GridContent'
}
</script>

<style lang="scss">
.grid-content {
    padding: 20px;

    .table-row:first-of-type td {
        border-top: 0;
    }

    .table-row:last-of-type td {
        border-bottom: 0;
    }
}
</style>