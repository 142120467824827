import RetailStock from '@/components/pages/admin/retail/RetailStock';
import RetailStockTransfers from '@/components/pages/admin/retail/RetailStockTransfers';
import RetailTransfer from '@/components/pages/admin/retail/RetailTransfer';

const retailRoutes = [
    {
        path: '/admin/retail',
        name: 'retail',
        meta: {
            title: 'stock-management',
            icon: 'hanger',
            roles: ['ROLE_ADMIN_BRAND_STORE', 'ROLE_SUPER_STORE', 'ROLE_STORE'],
            cms: ['FAUME', 'SHOPIFY']
        },
        redirect: {
            name: 'retail-stock'
        },
    },
    {
        path: '/admin/retail/retail-stock',
        name: 'retail-stock',
        component: RetailStock,
        meta: {
            title: 'retail-stock',
            roles: ['ROLE_ADMIN_BRAND_STORE', 'ROLE_SUPER_STORE', 'ROLE_STORE'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/retail/transfers',
        name: 'retail-stock-transfers',
        component: RetailStockTransfers,
        meta: {
            title: 'retail-stock-transfers',
            roles: ['ROLE_ADMIN_BRAND_STORE', 'ROLE_SUPER_STORE', 'ROLE_STORE'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/retail/transfers/:transferId',
        name: 'retail-transfer',
        component: RetailTransfer,
        meta: {
            icon: 'hanger',
            roles: ['ROLE_ADMIN_BRAND_STORE', 'ROLE_SUPER_STORE', 'ROLE_STORE'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/retail/*',
        redirect: {
            name: 'retail'
        }
    }
];

export default retailRoutes;