<template>
    <div class="collections-markets__list">
        <GridCard>
            <GridContent>
                <MarketsStatus 
                    :marketStatus="marketStatuses"
                />
                <Button @click.native.prevent="$refs.popinEditMarkets.open" size="m" color="black" icon="edit">{{ $t('global.edit') }}</Button>
            </GridContent>
        </GridCard>
        <Popin class="collections-markets__popin" ref="popinEditMarkets">
            <template v-slot:bottom>
                <MarketsSelector
                    :marketsSelector="marketStatuses"
                 />
                <Button @click.prevent.native="$refs.popinEditMarkets.close" color="green" size="m" >{{ $t('market.btnSave') }}</Button>
            </template>
        </Popin>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import GridCard from '@/components/ui/grid/GridCard.vue';
import GridContent from '@/components/ui/grid/GridContent.vue';
import Popin from '@/components/ui/popin/Popin.vue';
import MarketsStatus from '@/components/ui/markets/MarketsStatus.vue';
import Button from '@/components/ui/button/Button.vue';
import MarketsSelector from '@/components/ui/markets/MarketsSelector.vue';
export default {
    name: 'CollectionsMarketsList',
    props: {
        model: Array,
    },
    data() {
        return {
            modelModified: false,
        }
    },
    components: {
        GridCard,
        GridContent,
        Popin,
        Button,
        MarketsStatus,
        MarketsSelector
    },
    watch: {
        model(newValue,oldValue) {
            if (newValue.length === 0 && oldValue.length >= 0) {
                return;
            }
            this.modelModified = true;                
        }
    },
    computed: {
        ...mapState(['marketsClient']),
        marketsCountryCodes(){
            return this.marketsClient
                ? this.marketsClient?.markets.map(elem => elem.country[0].code)
                : []
        },

        marketStatuses() {
            return this.marketsCountryCodes.map(code => {
                let isPresent;

                if (this.firstCollection && !this.modelModified) {
                    // Si `firstCollection` est vrai et `model` n'a pas été modifié, `isPresent` est vrai
                    isPresent = true;
                } else {
                    // Sinon, `isPresent` dépend de la présence du code de pays dans `model`
                    isPresent = this.model.includes(code);
                }
                return {
                    countryCode: code,
                    isPresent: isPresent
                };
            });
        },

        firstCollection(){
            return !!this.$route.meta.requiresCheck
        }
    },
    methods: {
        ...mapActions(['fetchMarketsForClient']),
    },
    created () {
        this.fetchMarketsForClient({brandID: this.$route.params.id});
    },
}
</script>

<style lang="scss" scoped>
.collections-markets {
    &__list {
        position: relative;
        z-index: 3;
    }
} 

</style>