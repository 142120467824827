<template>    
    <div class="pagination-catalogue" data-testid="pagination-bar">
        <select @change="emitLimit" v-model="paginationLimit">
            <option v-for="option in optionsAvailable" :key="option" :value="option">{{ option }}</option>
        </select>
        <div class="pagination-catalogue__navigation" v-if="total">
            <button :class="{'disabled': currentPage === 1}" @click.prevent="prev" :disabled="currentPage === 1" data-testid="pagination-arrow-prev">
                <SvgIcon name="chevron"/>
            </button>
            <span v-if="total && total !== 1" @click="goToPage(1)" :class="{'pagination-number': currentPage === 1}">1</span>
            <span v-if="showLeadingEllipsis">...</span>
            <span v-for="page in surroundingPages" :key="page" @click="goToPage(page)" :class="{'pagination-number': currentPage === page}">
                {{ page }}
            </span>
            <span v-if="showTrailingEllipsis">...</span>
            <span v-if="totalPages > 1" @click="goToPage(totalPages)" :class="{'pagination-number': currentPage === totalPages}">
                {{ totalPages }}
            </span>
            <button :class="{'disabled': currentPage >= totalPages}" @click.prevent="next" :disabled="currentPage >= totalPages" data-testid="pagination-arrow-next">
                <SvgIcon name="chevron"/>
            </button>
        </div>
        <div v-if="total">
            <TextSimple color="grey" size="xs">{{ offset + 1 }} - {{ limit + offset > total ? total : limit + offset }} of {{ total  }}</TextSimple>
        </div>
    </div>    
</template>


<script>
import SvgIcon from '@/components/ui/svg/SvgIcon';
import TextSimple from '@/components/ui/text/TextSimple.vue';

export default {
    name: 'CataloguePagination',
    components: {
        SvgIcon,
        TextSimple,
    },
    props: {
        limit: Number,
        offset: Number,
        optionsAvailable: Array,
        total: Number
    },
    watch: {
        offset(newOffset) {
            this.currentPage = Math.floor(newOffset / this.limit) + 1;
        },
        limit() {
            this.adjustOffset();
        },
        total(newValue){
            if(newValue) this.emitLimit();
        }
    },
    data() {
        return {
            paginationLimit: this.limit,
            currentPage: Math.floor(this.offset / this.limit) + 1,
        }
    },
    computed: {
        totalPages() {
            // Calcule le nombre total de pages
            return Math.ceil(this.total / this.paginationLimit);
        },
        showLeadingEllipsis() {
            // Affiche l'ellipse initiale si nécessaire
            return this.currentPage > 3;
        },
        showTrailingEllipsis() {
            // Affiche l'ellipse finale si nécessaire
            return this.currentPage < this.totalPages - 2;
        },
        surroundingPages() {
            // Calcule les pages à afficher autour de la page actuelle
            const pages = [];
            let start = this.currentPage - 1;
            let end = this.currentPage + 1;

            if (this.currentPage === 1) {
                start = 2;
                end = 4;
            } else if (this.currentPage === this.totalPages) {
                start = this.totalPages - 3;
                end = this.totalPages - 1;
            }

            for (let i = start; i <= end; i++) {
                if (i > 1 && i < this.totalPages) {
                    pages.push(i);
                }
            }

            return pages;
        }
    },
    methods: {
        adjustOffset() {
            // Ajuste l'offset si nécessaire
            if (this.currentPage < 1) {
                this.currentPage = 1;
            } else if (this.currentPage > this.totalPages) {
                this.currentPage = this.totalPages;
            }

            this.paginationOffset = (this.currentPage - 1) * this.paginationLimit;

            // Assure que l'offset ne dépasse jamais le total
            if (this.paginationOffset > this.total) {
                this.paginationOffset = this.total - this.paginationLimit;
                // S'assure que l'offset n'est pas négatif
                if (this.paginationOffset < 0) {
                    this.paginationOffset = 0;
                }
                // Ajuste la page courante en fonction du nouvel offset
                this.currentPage = Math.floor(this.paginationOffset / this.paginationLimit) + 1;
            }
        },

        emitLimit() {
            // Émet la limite et l'offset ajustés
            this.adjustOffset();
            this.$emit('change', { limit: this.paginationLimit, offset: this.paginationOffset });
        },
        next() {
            // Passe à la page suivante
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
                this.emitLimit();
            }
        },
        prev() {
            // Retourne à la page précédente
            if (this.currentPage > 1) {
                this.currentPage--;
                this.emitLimit();
            }
        },
        goToPage(page) {
            // Va à la page spécifiée
            this.currentPage = page;
            this.emitLimit();
        }
    },
}
</script>
<style lang="scss" scoped>
.pagination-catalogue {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    select, .pagination-number {
        border: var(--border-1);
        background: #fff;
        font-size: 1.2rem;
        padding: 4px 5px;
        cursor: pointer;
        transition: border ease 0.3s;
    }
    select {
        border-radius: var(--border-radius-1);
        order: 0;
    }
    &__navigation {
        display: flex;
        align-items: center;
        margin: auto;
        @media screen and (max-width: 1024px){
            order: 3;
        }
        
        & > *{
            min-width: 30px;
            min-height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2px 6px;
            border-radius: 20px;
            cursor: pointer;
        }
    }
    &__total {
        order: 1;
    }
    button:hover:enabled, .pagination-number:hover {
        cursor: pointer;
    }
    .disabled {
        opacity: .5;
    }
    button:first-of-type {
        transform: rotate(90deg);
    }
    button:last-of-type {
        transform: rotate(-90deg);
    }
}

</style>
