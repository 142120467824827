import Orders from '@/components/pages/admin/orders/Orders';
import RecomOrders from '@/components/pages/admin/orders/RecomOrders';
import Order from '@/components/pages/admin/orders/Order';
import RecomOrder from '@/components/pages/admin/orders/RecomOrder';
import Returns from '@/components/pages/admin/orders/Returns';
import ReturnsTreated from '@/components/pages/admin/orders/ReturnsTreated';
import Return from '@/components/pages/admin/orders/Return';
import OrdersReturned from '@/components/pages/admin/orders/OrdersReturned';
import OrdersArticles from '@/components/pages/admin/orders/OrdersArticles';
import OrdersRefunded from '@/components/pages/admin/orders/OrdersRefunded';
import OrderRefunded from '@/components/pages/admin/orders/OrderRefunded';
import RecomSoldArticles from '@/components/pages/admin/orders/RecomSoldArticles';

const ordersRoutes = [
    {
        path: '/admin/commandes',
        name: 'orders-all',
        redirect: {
            name: 'orders'
        },
        meta: {
            title: 'orders',
            icon: 'bag',
            roles: ['ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_SUPER_STORE', 'ROLE_STORE', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE', 'ROLE_BRAND_SAV', 'ROLE_BRAND_STORE', 'ROLE_LOGISTICIAN', 'ROLE_WAREHOUSE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/commandes',
        name: 'orders',
        component: Orders,
        meta: {
            title: 'orders',
            icon: 'bag',
            roles: ['ROLE_ADMIN', 'ROLE_FINANCE', 'ROLE_CLIENT', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE', 'ROLE_BRAND_SAV'],
            cms: ['SHOPIFY']
        }
    },
    {
        path: '/admin/commandes-faume',
        name: 'recom_orders',
        component: RecomOrders,
        meta: {
            title: 'recom_orders',
            roles: ['ROLE_ADMIN', 'ROLE_FINANCE', 'ROLE_CLIENT', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE', 'ROLE_BRAND_SAV'],
            cms: ['FAUME']
        }
    },
    {
        path: '/admin/commandes/articles-vendus',
        name: 'orders-articles',
        component: OrdersArticles,
        meta: {
            title: 'soldArticles',
            roles: ['ROLE_ADMIN', 'ROLE_FINANCE', 'ROLE_CLIENT', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE', 'ROLE_BRAND_SAV'],
            cms: ['SHOPIFY']
        }
    },
    {
        path: '/admin/articles-vendus',
        name: 'recom_articles',
        component: RecomSoldArticles,
        meta: {
            title: 'recom_articles',
            roles: ['ROLE_ADMIN', 'ROLE_FINANCE', 'ROLE_CLIENT', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE', 'ROLE_BRAND_SAV'],
            cms: ['FAUME']
        }
    },
    {
        path: '/admin/commandes/retours',
        name: 'orders-returns',
        component: Returns,
        meta: {
            title: 'returns',
            roles: ['ROLE_ADMIN', 'ROLE_FINANCE', 'ROLE_CLIENT', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE', 'ROLE_BRAND_SAV'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/commandes/retours-traites',
        name: 'orders-returns-treated',
        component: ReturnsTreated,
        meta: {
            title: 'returns-treated',
            roles: ['ROLE_ADMIN', 'ROLE_FINANCE', 'ROLE_CLIENT', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE', 'ROLE_BRAND_SAV'],
            cms: ['SHOPIFY']
        }
    },
    {
        path: '/admin/commandes/retours/:id',
        name: 'orders-return',
        component: Return,
        meta: {
            title: 'return',
            roles: ['ROLE_ADMIN', 'ROLE_FINANCE', 'ROLE_CLIENT', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE', 'ROLE_BRAND_SAV'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/commandes/commandes-retournees',
        name: 'orders-returned',
        component: OrdersReturned,
        meta: {
            title: 'orders-returned',
            roles: ['ROLE_ADMIN', 'ROLE_FINANCE', 'ROLE_CLIENT', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE', 'ROLE_BRAND_SAV'],
            cms: ['SHOPIFY']
        }
    },
    {
        path: '/admin/commandes/commandes-remboursees',
        name: 'orders-refunded',
        component: OrdersRefunded,
        meta: {
            title: 'orders-refunded',
            roles: ['ROLE_ADMIN', 'ROLE_FINANCE', 'ROLE_CLIENT', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE', 'ROLE_BRAND_SAV'],
            cms: ['SHOPIFY']
        }
    },
    {
        path: '/admin/commandes/commandes-remboursees/:id',
        name: 'order-refunded',
        component: OrderRefunded,
        meta: {
            title: 'order-refunded',
            roles: ['ROLE_ADMIN', 'ROLE_FINANCE', 'ROLE_CLIENT', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE', 'ROLE_BRAND_SAV'],
            cms: ['SHOPIFY']
        }
    },
    {
        path: '/admin/commandes/:id',
        name: 'order',
        component: Order,
        meta: {
            title: 'order',
            roles: ['ROLE_ADMIN', 'ROLE_FINANCE', 'ROLE_CLIENT', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE', 'ROLE_BRAND_SAV'],
            cms: ['SHOPIFY']
        }
    },
    {
        path: '/admin/commandes-faume/:id',
        name: 'recom_order',
        component: RecomOrder,
        meta: {
            roles: ['ROLE_ADMIN', 'ROLE_FINANCE', 'ROLE_CLIENT', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE', 'ROLE_BRAND_SAV'],
            cms: ['FAUME']
        }
    },
    {
        path: '/admin/commandes/*',
        redirect: {
            name: 'orders',
        }
    },
]

export default ordersRoutes