<template>
    <GridCard>
        <GridContent>
            <GridHeader 
                :title="title" 
                :text="text"
            />
            <TextSimple v-for="(condition,index) in updateActiveStatus" :key="index">{{ condition }}</TextSimple>
            <div class="catalogue-product-informations">
                <CatalogueProductInformationsItem 
                    v-for="(modifiedData,index) in datas" :key="`${index}${modifiedData.id}`" 
                    :data="modifiedData"
                    @change="e => $emit('change',e)"
                    />
            </div>
        </GridContent>
    </GridCard>
</template>

<script>
import GridCard from '@/components/ui/grid/GridCard.vue';
import GridContent from '@/components/ui/grid/GridContent.vue';
import GridHeader from '@/components/ui/grid/GridHeader.vue';
import TextSimple from '@/components/ui/text/TextSimple.vue';
import CatalogueProductInformationsItem from '@/components/ui/catalogue/CatalogueProductInformationsItem.vue';

export default {
    name: 'CatalogueProductInformations',
    components: {
        GridCard,
        GridContent,
        GridHeader,
        TextSimple,
        CatalogueProductInformationsItem,
    },
    props: {
        datas: Array,
        compare: Array,
        title: String,
        text: String,
        conditions: Array
    },
    computed: {
        updateActiveStatus() {
            // Parcourir chaque élément de 'compare'
            return this.compare.forEach(compItem => {
                // Vérifier si l'élément est actif
                if (compItem.active) {
                    // Trouver l'élément correspondant dans 'datas' et mettre à jour 'active'
                    const dataItem = this.datas.find(data => data.name === compItem.name);
                    if (dataItem) {
                        dataItem.active = true; // Mettre à jour 'active' à true
                    }
                }
            });
        }
    },
}
</script>

<style lang="scss" scoped>
.catalogue-product-informations {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 20px;
    .item {
        display: flex;
        align-items: center;
        gap: 20px;
        border: var(--border-1);
        padding: 10px;
        background: #fff;
    }
    .disabled {
        transition: all ease-in .5s;
        opacity: .3;
    }
}
</style>
