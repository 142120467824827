<template>
    <div class="popin-calendar" @mouseleave="leave">
        <div class="popin-calendar__button" @click.prevent="displayCalendar = true" :data-testid="dataTestid">
            <slot/>
        </div>
        <div class="popin-calendar__inner" :class="{'is-open': displayCalendar}" @mouseenter="isHover = true" @mouseleave="displayCalendar = false; isHover = false;">
            <ul class="popin-calendar__choices" v-if="(!date && !hideSuggestion) || $slots.choices">
                <li v-show="!hideSuggestion && !date" v-for="choice in choices" :key="choice.name" :class="{'is-active': choice.value === activeChoice}" @click.prevent="selectPeriod(choice)" :data-testid="dataTestid + choice.name">
                    <SvgIcon name="check"/>
                    <TextSimple>{{ $t(`global.${choice.name}`) }}</TextSimple>
                </li>
                <slot name="choices"/>
            </ul>
            <FieldSearchSelect v-if="!hideSuggestion"
                    :model="activeChoice"
                    :options="[...choices].map(c => ({value: c.value, name: $t(`global.${c.name}`)}))"
                    :disabled-text="true"
                    @change="e => selectPeriod(choices.find(c => c.value === e))"
            />
            <InputCalendar :disabled-today="disabledToday" :display-hours="displayHours" :is-date="isDate" :columns="columns" :date="date" :period1="period1" :period2="period2" :highlight="highlight" @change="e => $emit('change', e)"/>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import InputCalendar from '@/components/ui/form/inputs/InputCalendar';
import TextSimple from '@/components/ui/text/TextSimple';
import SvgIcon from '@/components/ui/svg/SvgIcon';
import FieldSearchSelect from '@/components/ui/form/fields/FieldSearchSelect';
export default {
    name: 'PopinCalendar',
    components: {FieldSearchSelect, TextSimple, InputCalendar, SvgIcon},
    props: {
        columns: Number,
        date: String,
        period1: String,
        period2: String,
        highlight: Object,
        hideSuggestion: Boolean,
        isDate: Boolean,
        dataTestid: String,
        displayHours: Boolean,
        disabledToday: Boolean
    },
    data() {
        return {
            lastPeriod: {},
            isHover: false,
            displayCalendar: false,
            activeChoice: 0,
            choices: [
                {
                    name: 'today',
                    value: 1,
                    period1: moment().format('YYYY-MM-DD'),
                    period2: moment().format('YYYY-MM-DD'),
                    comparaison: {
                        start: moment().subtract(1, 'days').format('YYYY-MM-DD'),
                        end: moment().subtract(1, 'days').format('YYYY-MM-DD'),
                    }
                },
                {
                    name: 'last_week',
                    value: 2,
                    period1: moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'),
                    period2: moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD'),
                    comparaison: {
                        start: moment().subtract(2, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'),
                        end: moment().subtract(2, 'weeks').endOf('isoWeek').format('YYYY-MM-DD')
                    }
                },
                {
                    name: 'this_month',
                    value: 3,
                    period1: moment().startOf('months').format('YYYY-MM-DD'),
                    period2: moment().format('YYYY-MM-DD'),
                    comparaison: {
                        start: moment().subtract(1, 'months').startOf('months').format('YYYY-MM-DD'),
                        end: moment().subtract(1, 'months').endOf('months').format('YYYY-MM-DD'),
                    }

                },
                {
                    name: 'last_month',
                    value: 4,
                    period1: moment().subtract(1, 'months').startOf('months').format('YYYY-MM-DD'),
                    period2: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
                    comparaison: {
                        start: moment().subtract(2, 'months').startOf('months').format('YYYY-MM-DD'),
                        end: moment().subtract(2, 'months').endOf('months').format('YYYY-MM-DD')
                    }
                },
                {
                    name: 'this_year',
                    value: 5,
                    period1: moment().startOf('year').format('YYYY-MM-DD'),
                    period2: moment().format('YYYY-MM-DD'),
                    comparaison: {
                        start: moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD'),
                        end: moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD')
                    }
                },
                {
                    name: 'last_year',
                    value: 6,
                    period1: moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD'),
                    period2: moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD'),
                    comparaison: {
                        start: moment().subtract(2, 'years').startOf('year').format('YYYY-MM-DD'),
                        end: moment().subtract(2, 'years').endOf('year').format('YYYY-MM-DD')
                    }
                },
                {
                    name: 'custom',
                    value: 0
                },
            ]
        }
    },
    computed: {
        isCustom() {
            return !this.choices.find(c => c.period1 === this.period1 && c.period2 === this.period2)
        }
    },
    methods: {
        leave() {
            setTimeout(() => {
                if (!this.isHover) this.displayCalendar = false
            }, 500)
        },
        selectPeriod(choice) {
            this.activeChoice = choice.value;
            const start = choice.period1;
            const end = choice.period2;
            // Avoid double event detection with -> lastPeriod
            if (start && end && this.lastPeriod.start !== start && this.lastPeriod.end !== end) {
                this.lastPeriod.start = start;
                this.lastPeriod.end = end;
                this.$emit('change', {
                    start: choice.period1,
                    end: choice.period2,
                    comparaison: choice.comparaison
                })
            }
        }
    },
    watch: {
        isCustom(e) {
            if (e) this.activeChoice = 0
        }
    }
}
</script>

<style lang="scss">
.popin-calendar {
   position: relative;

    &__button {
        cursor: pointer;
    }

    &__inner {
        background-color: #fff;
        position: absolute;
        top: calc(100% + 10px);
        left: 0;
        z-index: 4;
        border-radius: var(--border-radius-2);
        padding: 15px;
        box-shadow: var(--box-shadow-1);
        transition: opacity ease .3s, transform ease .3s;
        display: flex;
        width: max-content;
        align-items: flex-start;

        @media screen and (max-width: 600px) {
            flex-direction: column;
            width: 100%;
        }

        &:not(.is-open) {
            opacity: 0;
            transform: translateY(-10px);
            pointer-events: none;

            .vc-arrow {
                pointer-events: none;
            }
        }

        .field {
            margin-bottom: 15px;

            &__input {
                border: var(--border-1);
            }

            @media screen and (min-width: 601px) {
                display: none;
            }
        }
    }

    &__choices {
        flex-shrink: 0;
        margin-right: 10px;

        @media screen and (max-width: 600px) {
            display: none;
        }

        svg {
            transition: opacity ease .3s;
            opacity: 0;
            width: 10px;
        }

        li {
            border-radius: var(--border-radius-2);
            padding: 8px 12px;
            display: flex;
            align-items: center;
            gap: 8px;
            transition: background-color ease .3s;
            cursor: pointer;
            margin-bottom: 5px;

            &.is-active {
                background-color: var(--color-green-light);

                svg {
                    opacity: 1;
                }
            }

            @media screen and (min-width: 1025px) {
                &:hover {
                    background-color: var(--color-green-light);
                }
            }
        }
    }
}
</style>