<template>
    <TemplateDetails :data="store" :loading="storeLoading" :get-function="getStore">
        <StoreForm
                 v-if="store"
                :title="$t('store.edit_btn')"
                 :model="store"
                :action="editStore"
                @success="handleSuccess"
        />
    </TemplateDetails>
</template>

<script>
import StoreForm from '@/components/ui/stores/StoreForm';
import TemplateDetails from '@/components/templates/TemplateDetails';
import { mapActions, mapState } from 'vuex';

export default {
    components: {TemplateDetails, StoreForm},
    computed: {
        ...mapState(['store', 'storeLoading']),
        id() {
            return this.$route.params.id
        }
    },
    methods: {
        ...mapActions(['getStore', 'editStore']),
        handleSuccess() {
            this.$router.push({name: 'client-store', params: {id: this.id, storeId: this.$route.params.storeId}})
        }
    }
}
</script>
