import ShippingsPage from '@/components/pages/admin/shippings/Shippings';
import ShippingCreate from '@/components/pages/admin/shippings/ShippingCreate';

const shippingsRoutes = [
    {
        path: '/admin/bons-de-transport/nouveau',
        name: 'shippings-create',
        meta: {
            title: 'shippings',
            icon: 'shipping',
            roles: ['ROLE_ADMIN_BRAND_STORE', 'ROLE_SUPER_STORE', 'ROLE_STORE', 'ROLE_BRAND_ADMIN', 'ROLE_STORE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        },
        component: ShippingCreate
    },
    {
        path: '/admin/bons-de-transport',
        name: 'shippings',
        meta: {
            title: 'shippings',
            icon: 'shipping',
            roles: ['ROLE_ADMIN_BRAND_STORE', 'ROLE_SUPER_STORE', 'ROLE_STORE', 'ROLE_BRAND_ADMIN', 'ROLE_STORE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        },
        component: ShippingsPage
    },
    {
        path: '/admin/bons-de-transport/*',
        redirect: {
            name: 'shippings'
        }
    }
];

export default shippingsRoutes;