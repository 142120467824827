import CatalogueAPI from '@/api/api-catalogue';
import listRoutes from '@/api/list-routes';
import i18n from '@/i18n';


const state = {
    merchandisingData: [],
    merchandisingDataLoading: null,

    merchandisingCategories:null,
    merchandisingCategoriesLoading:null,

    merchandisingTagCategories:null,
    merchandisingTagCategoriesLoading:null,
    
    merchandisingRandomProduct:null,
    merchandisingRandomProductLoading:null,

    merchandisingDefaultLang: null,
    merchandisingDefaultLangLoading: null,

    merchandisingRules: null
}

const mutations = {
    SET_MERCHANDISING_DATA(state,data){
        state.merchandisingData = data
    },
    SET_MERCHANDISING_DATA_LOADING(state,data){
        state.merchandisingDataLoading = data
    },
    SET_MERCHANDISING_CATEGORIES(state,data){
        state.merchandisingCategories = data
    },
    SET_MERCHANDISING_CATEGORIES_LOADING(state,data){
        state.merchandisingCategoriesLoading = data
    },
    SET_MERCHANDISING_TAG_CATEGORIES(state,data){
        state.merchandisingTagCategories = data
    },
    SET_MERCHANDISING_TAG_CATEGORIES_LOADING(state,data){
        state.merchandisingTagCategoriesLoading = data
    },
    SET_MERCHANDISING_RANDOM_PRODUCT(state,data){
        state.merchandisingRandomProduct = data
    },
    SET_MERCHANDISING_RANDOM_PRODUCT_LOADING(state,data){
        state.merchandisingRandomProductLoading = data
    },
    SET_MERCHANDISING_DEFAULT_LANG(state,data){
        state.merchandisingDefaultLang = data
    },
    SET_MERCHANDISING_DEFAULT_LANG_LOADING(state,data){
        state.merchandisingDefaultLangLoading = data
    },
    SET_MERCHANDISING_RULES(state,data){
        state.merchandisingRules = data
    }
}

const actions = {
    fetchMerchandisingDefaultLang({commit},id){
        commit('SET_MERCHANDISING_DEFAULT_LANG', null);
        commit('SET_MERCHANDISING_DEFAULT_LANG_LOADING', true);

        return new Promise((resolve, reject) => {
            CatalogueAPI.get(`${listRoutes.merchandisingDefaultLang.replace(':id',id)}`)
                .then(res => {
                    return res.data
                })
                .then(data => {
                    commit('SET_MERCHANDISING_DEFAULT_LANG', data.defautLocale)
                    resolve(data)
                })
                .catch(err => reject(err))
                .finally(()=> {
                    commit('SET_MERCHANDISING_DEFAULT_LANG_LOADING', false);
                })
        })

    },
    fetchMerchandisingDatas({commit},id) {
        commit('SET_MERCHANDISING_DATA_LOADING', true);
        commit('SET_MERCHANDISING_DATA', null);

        return new Promise((resolve, reject) => {
            CatalogueAPI.get(`${listRoutes.merchandisingCriterias.replace(':id',id)}`)
                .then(res => {
                    return res.data
                })
                .then(data => {
                    commit('SET_MERCHANDISING_DATA', data)
                    resolve(data)
                })
                .catch(err => {
                    console.log(err.response.data)
                    reject(err)
                })
                .finally(() => {
                    commit('SET_MERCHANDISING_DATA_LOADING', false)
                })
        })
    },
    fetchMerchandisingRules({commit},id) {
        commit('SET_MERCHANDISING_RULES', null);

        return new Promise((resolve, reject) => {
            CatalogueAPI.get(`${listRoutes.merchandisingGet.replace(':id',id)}`)
                .then(res => {
                    return res.data
                })
                .then(data => {
                    commit('SET_MERCHANDISING_RULES', data)
                    resolve(data)
                })
                .catch(err => {
                    console.log(err.response.data)
                    reject(err)
                })
        })
    },
    submitMerchRules({dispatch}, payload){
        const {id,merchandising} = payload
        
        return new Promise((resolve, reject) => {
            CatalogueAPI.put(`${listRoutes.merchandisingPut.replace(':id',id)}`,{merchandising})
                .then(res => {
                    return res.data
                })
                .then(data => {
                    resolve(data)
                })
                .catch(err => {
                    console.log('submitmerchrulez',err)
                    reject(err)
                })
                .finally(()=> {
                    dispatch('fetchMerchandisingRandomProduct',id)
                })
        })
    },
    fetchMerchandisingCategories({commit},payload) {
        commit('SET_MERCHANDISING_CATEGORIES_LOADING', true);
        commit('SET_MERCHANDISING_CATEGORIES', null);

        const {id,name} = payload

        return new Promise((resolve, reject) => {
            CatalogueAPI.post(`${listRoutes.merchandisingCategories.replace(':id',id)}`,{name:name})
                .then(res => {
                    return res.data
                })
                .then(data => {
                    commit('SET_MERCHANDISING_CATEGORIES', data)
                    resolve(data)
                })
                .catch(err => reject(err))
                .finally(() => {
                    commit('SET_MERCHANDISING_CATEGORIES_LOADING', false)
                })
        })
    },
    fetchMerchandisingTagCategories({commit},id) {
        
        commit('SET_MERCHANDISING_TAG_CATEGORIES_LOADING', true);
        commit('SET_MERCHANDISING_TAG_CATEGORIES', null);

        return new Promise((resolve, reject) => {
            CatalogueAPI.get(`${listRoutes.merchandisingGetTagCategory.replace(':id',id)}`)
                .then(res => {
                    return res.data
                })
                .then(data => {
                    commit('SET_MERCHANDISING_TAG_CATEGORIES', data)
                    resolve(data)
                })
                .catch(err => reject(err))
                .finally(() => {
                    commit('SET_MERCHANDISING_TAG_CATEGORIES_LOADING', false)
                })
        })
    },
    fetchMerchandisingRandomProduct({commit},id) {
        commit('SET_MERCHANDISING_RANDOM_PRODUCT_LOADING', true);
        commit('SET_MERCHANDISING_RANDOM_PRODUCT', null);

        return CatalogueAPI.get(`${listRoutes.merchandisingRandomProduct.replace(':id',id)}`)
                .then(res => {
                    commit('SET_MERCHANDISING_RANDOM_PRODUCT', res.data)
                    
                })
                .catch(err => {
                    
                    commit('SET_NOTIFICATION_MESSAGE', {text:i18n.t(err.response.data.message), color:'red', confirmation: false})
                    // throw err
                    // Promise.reject(err)
                    // return err
                    // console.log('catch',err)
                    // reject(err)
                })
                .finally(() => {
                    commit('SET_MERCHANDISING_RANDOM_PRODUCT_LOADING', false)
                })
        
        
    },
    submitTagCategory({dispatch},payload){
        const {lang, id, tagCategory} = payload

        return new Promise((resolve, reject) => {
            CatalogueAPI.post(`${listRoutes.merchandisingPostTagCategory.replace(':lang',lang).replace(':id',id)}`,tagCategory)
                .then(res => {
                    return res.data
                })
                .then(data => {
                    return resolve(data)
                })
                .catch(err => reject(err))
                .finally(()=> {
                    dispatch('fetchMerchandisingTagCategories',id)
                })
        })

    },
    deleteTagCategory({dispatch},payload) {

        const {tagCategoryID, id} = payload

        CatalogueAPI.delete(`${listRoutes.merchandisingDeleteTagCategory.replace(':id',id).replace(':tagCategoryID',tagCategoryID)}`)
            .then(res => {
                return res.data
            })
            .catch(err => console.log(err))
            .finally(()=>{
                dispatch('fetchMerchandisingTagCategories',id)
            })
    }
}

const getters = {

}

const merchandising =  {
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default merchandising