<template>
    <CustomTags
        :tags="tagsWithoutDuplicates"
        :conditions="'from_collections'"
        :defaultSearchText="tagFromCollection"
        :infos="$t('articles.no_tag_found_in_collections')"
        />
</template>

<script>
import { mapActions, mapState } from 'vuex';

import CustomTags from '../custom-tags/CustomTags.vue';

export default {
    name: 'CollectionsConditionsCatalogueManualTags',
    components: {
        CustomTags,
    },
    computed: {
        ...mapState([
            'catalogueCollection',
            'customTags'
        ]),
        id(){
            return this.$route.params.id   
        },
        tagFromCollection(){
            return this.catalogueCollection && this.catalogueCollection?.tags[0]
        },
        tags(){
            const customTags = this.customTags?.customTags?.map(elem => ({tag:elem.tag})) || []
            const collectionsTags = this.customTags?.collectionTags?.map(elem => ({tag:elem.name,fromCollection:true})) || []
            const allTags = [...customTags, ...collectionsTags]
            return allTags
        },
        tagsWithoutDuplicates(){
            return this.tags.flat().filter((elem, index, self) => index === self.findIndex((t) => (t.tag === elem.tag)))
        }


    },
    methods: {
        ...mapActions([
            'catalogueCollectionUpdateTags',
            'getAllCustomTags'
        ]),
        emitData(data){
            this.searchText = data
            this.catalogueCollectionUpdateTags(data)
        },
        selectChoice(tag){
            console.log('seletChoice',tag)
            this.searchText = tag
        }
    },
    mounted () {
        this.getAllCustomTags(this.id)        
    },
}
</script>

<style lang="scss" scoped>
.collections-fields {
    display: flex;
    align-items: center;
    gap: 10px;
    & > label {
        flex: 1;
        margin: unset;
    }
}
</style>