<template>
    <div>
        <TemplateTable 
        name="retailTransfers"
        :title="$t('retail.transfers.title')"
        :url="$listRoutes.retailTransfers"
        :filters="retailTransfersFilters"
        :table="retailTransfers"
        :display-page-back="true"
        :hide-export="true"
        :no-cache="true"
        :fields="fields">
        
        </TemplateTable>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import TemplateTable from '@/components/templates/TemplateTable';

export default {
    name: 'RetailTransfers',
    components: {TemplateTable},
    data() {
        return {
            fields: [
                {key: 'id', type: 'eye', size: 'xs', route: 'retail-transfer', param: 'transferId'},
                {key: 'countArticles', size: 'xs'},
                {key: 'currentEvent.event', label: 'currentEvent', size: 's', type: 'transferStage'},
                {key: 'currentEvent.createdAt', label: 'date', size: 's', type: 'dateHour'},
                {key: 'store.name', size: 'l'},
            ]
        }
    },
    computed: {
        ...mapState(['retailTransfers', 'retailTransfersFilters', 'user']),
    }
}
</script>

