<template>
    <div class="table-sticky-bar"
         v-if="scrollWidth > clientWidth"
         @mousemove="mouseMove"
         @mousedown="mouseDown"
         @mouseup="mouseUp">

        <div :style="{width: width, left: `${left}px`}"></div>

    </div>
</template>

<script>
export default {
    name: 'TableStickyBar',
    props: {
        group: String
    },
    data() {
        return {
            width: "0px",
            left: 0,
            topNode: null,
            visible: false,
            dragging: false,
            startX: 0,
            maxLeft: 0,
            clientWidth: 0,
            scrollWidth: 0,
        }
    },
    mounted() {
        let parent = this.$parent
        while (parent) {
            this.topNode = parent
            parent = this.topNode.$parent
        }
        this.topNode.$on('scroll-sync', data => {
            if (!this.dragging && data.group === this.group) {
                this.clientWidth = data.clientWidth;
                this.scrollWidth = data.scrollWidth;
                if (this.scrollWidth > this.clientWidth) {
                    this.visible = true;
                    this.width = `${(this.clientWidth / this.scrollWidth) * 100}%`;
                    this.maxLeft = this.clientWidth - (this.clientWidth * (this.clientWidth / this.scrollWidth));
                    this.left = (data.scrollLeft / this.scrollWidth) * this.clientWidth;
                } else {
                    this.visible = false;
                }
            }
        })
    },
    methods: {
        mouseMove(e) {
            if (this.dragging) {
                e.preventDefault();
                window.requestAnimationFrame(() => {
                    const x = e.pageX;
                    if (x !== this.startX && this.startX !== 0) {
                        const left = this.left + x - this.startX;
                        this.left = left < 0 ? 0 : left > this.maxLeft ? this.maxLeft : left;
                        this.startX = x;
                        this.$parent?.$refs?.thead.$el.scroll((this.left / this.clientWidth) * this.scrollWidth, 0);
                    }
                })
            }
        },
        mouseDown(e) {
            this.dragging = true;
            this.startX = e.pageX;
        },
        mouseUp() {
            this.startX = 0;
            setTimeout(() => this.dragging = false, 500)
        }
    }
}
</script>

<style lang="scss">
.table-sticky-bar {
    width: 100%;
    margin: 5px 0;
    background-color: #fff;
    border: var(--border-1);
    padding: 10px 5px;
    overflow: hidden;
    border-radius: var(--border-radius-1);

    @media screen and (max-width: 1024px) {
        display: none;
    }

    div {
        cursor: grab;
        height: 10px;
        border: var(--border-1);
        background-color: var(--color-text-grey);
        border-radius: 15px;
        transition: background-color ease .2s, transform ease .2s;
        position: relative;
        user-select: all;

        &:hover {
            background-color: #333;
            transform: scale(1, 1.5);
        }
    }
}
</style>