<template>
    <div class="dashboard-performances">
        <PageHeader :title="title" :subtitle="subtitle"/>

        <FieldSelect v-if="clientsOptions?.length && clientIsRequired"
                     :key="client"
                     :selected="client"
                     :options="clientsOptions"
                     :label="$t('global.client')"
                     @input="e => client = e"/>

        <PageFilters class="hight-filters">
            <template v-slot:left>
                <FieldSearchSelect v-if="clientCountries?.length"
                        :model="selectedCountries"
                        :options="clientCountries"
                        :label="$t('global.country')"
                        @change="e => selectedCountries = e"/>
                <FieldPeriod
                        :start="period1.start"
                        :end="period1.end"
                        :label="$t('funnel.periode1')"
                        :disabled-today="true"
                        @change="e => {period1.start = e.start; period1.end = e.end; updatePeriod2(e)}"/>

                <FieldPeriod
                        v-if="comparaison"
                        :start="period2.start"
                        :end="period2.end"
                        :label="$t('funnel.periode2')"
                        :disabled-today="true"
                        :hide-suggestion="true"
                        :text="comparaisonType === 'period' ? null : $t('global.none')"
                        @change="e => {period2.start = e.start; period2.end = e.end}"
                >
                    <li :class="{'is-active': comparaisonType === 'period'}" @click.prevent="comparaisonType = 'period'">{{ $t('global.custom') }}</li>
                    <li :class="{'is-active': comparaisonType === 'none'}" @click.prevent="comparaisonType = 'none'">{{ $t('global.none') }}</li>
                </FieldPeriod>

            </template>
        </PageFilters>

        <PageLoader v-if="loading"/>

        <BarChart v-if="chartData && chartOptions" :chartData="chartData" :chartOptions="chartOptions" class="bar-chart-container" />

        <template v-if="tabFormatedData && tabFormatedData.length">
            <PageFilters v-if="availablePeriodGranularity">
                <template v-slot:left>
                    <TextTitle :size="'m'" :margin="true">{{ $t('global.conversionFunnelSub_title') }}</TextTitle>
                </template>
                <template v-slot:right>
                    <FieldSelect :key="currentPeriodGranularity" :selected="currentPeriodGranularity" :options="availablePeriodGranularity" :label="$t('global.granularity')" @input="e => currentPeriodGranularity = e" class="granularity-select" />
                </template>
            </PageFilters>
            <TableSticky :data="tabFormatedData" :fields="tableFields" />
        </template>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import PageHeader from '@/components/ui/page/PageHeader';
import PageFilters from '@/components/ui/page/PageFilters';
import moment from 'moment';
import FieldPeriod from '@/components/ui/form/fields/FieldPeriod';
import FieldSearchSelect from '@/components/ui/form/fields/FieldSearchSelect';
import FieldSelect from '@/components/ui/form/fields/FieldSelect';
import PageLoader from '@/components/ui/page/PageLoader';
import TableSticky from '@/components/ui/table-sticky/TableSticky.vue';
import BarChart from '@/components/ui/funnel/funnelBarChart.vue';
import TextTitle from '@/components/ui/text/TextTitle';
import _ from 'lodash';

export default {
    name: 'TemplateConversionFunnel',
    components: { PageLoader, FieldSelect, FieldSearchSelect, FieldPeriod, PageFilters, PageHeader, BarChart, TableSticky, TextTitle},
    props: {
        title: String,
        clientIsRequired: Boolean,
        route: String,
        events: [String, Array],
        filtersRoute: String,
        comparaison: Boolean
    },
    data() {
        return {
            client: null,
            selectedCountries: [],
            period1: {
                start: moment().subtract(8, 'd').format('YYYY-MM-DD'),
                end: moment().subtract(1, 'd').format('YYYY-MM-DD')
            },
            period2: {
                start: moment().subtract(16, 'd').format('YYYY-MM-DD'),
                end: moment().subtract(9, 'd').format('YYYY-MM-DD')
            },
            loading: false,
            selectedClients: [],
            comparaisonType: 'period',
            totalValueArray: [],
            currentChart: null,
            tableFields: [],
            chartData: null,
            chartOptions: null,
            currentPeriodGranularity: 'day',
            debouncedGetData: _.debounce(this.getData, 300),
        }
    },
    methods: {
        ...mapActions(['getFunnelDashboardData', 'getClients']),
        createChart() {
            this.chartData = {
                labels: this.chartBarFormatedData.translatedFunnelLabels,
                datasets: [
                    {
                    label: `${this.$t('funnel.conversion')} ${this.$t('funnel.periode1')}`,
                    data: this.chartBarFormatedData.conversionArrayFunnelValue[0],
                    backgroundColor: '#8DC0D0',
                    stack: 'Stack 0',
                    },
                    {
                    label: `${this.$t('funnel.perte')} ${this.$t('funnel.periode1')}`,
                    data: this.chartBarFormatedData.lostArrayFunnelValue[0],
                    backgroundColor: '#BED8E0',
                    stack: 'Stack 0',
                    },
                    {
                    label: `${this.$t('funnel.conversion')} ${this.$t('funnel.periode2')}`,
                    data: this.chartBarFormatedData.conversionArrayFunnelValue[1],
                    backgroundColor: '#AEDB7A',
                    stack: 'Stack 1',
                    },
                    {
                    label: `${this.$t('funnel.perte')} ${this.$t('funnel.periode2')}`,
                    data: this.chartBarFormatedData.lostArrayFunnelValue[1],
                    backgroundColor: '#D0E7B3',
                    stack: 'Stack 1',
                    }
                ],
            };
            this.chartOptions = {
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        stacked: true,
                        ticks: {
                        beginAtZero: true,
                        max: 100,
                        stepSize: 10, 
                        callback: function(value) {
                            return `${value} %`;
                        }
                        }
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: function(context) {
                                let label = context.dataset.label || '';
                                let data = context.dataset.data[context.dataIndex] || 0;

                                return `${label}: ${data} %`;
                            }
                        }
                    }
                }
            };
        },
        getData() {
            if (this.client && this.comparaison && this.period1.start && this.period1.end && this.period2.start && this.period2.end) {
                this.loading = true;
                const periods = [
                    { start: this.period1.start, end: this.period1.end },
                    { start: this.period2.start, end: this.period2.end }
                ];
                const payload = {
                    clientId: this.client,
                    countryCodes: this.selectedCountries?.length != this.clientCountries?.length ? this.selectedCountries : [],
                    events: this.events.join(","),
                    periods: periods,
                    granularity: this.currentPeriodGranularity
                };

                const routes = this.apiRoute({ payload });

                this.getFunnelDashboardData(routes)
                    .catch(err => console.error(err))
                    .finally(() => {
                        this.loading = false;
                        if(this.chartBarFormatedData) {
                            this.createChart();
                        }
                    });
            }
        },
        updatePeriod2(periode1) {
            if (this.comparaison && periode1.start === this.period2.start && periode1.end === this.period2.end){
                const start = moment(periode1.start, 'YYYY-MM-DD');
                const end = moment(periode1.end, 'YYYY-MM-DD');
                const days = end.diff(start, 'days') + 1;
                this.period2.start = start.clone().subtract(days, 'd').format('YYYY-MM-DD');
                this.period2.end = start.clone().subtract(1, 'd').format('YYYY-MM-DD');
            }
        },
        apiRoute({payload}) {
            const {clientId, events, periods, granularity, countryCodes} = payload;
            let routes = [];

            periods.forEach((period) => {
                let newRoute = this.route.replace(':id', clientId).replace(':events', events).replace(':startDate', period.start).replace(':endDate', period.end);
                if(countryCodes.length > 0){
                    const segment = countryCodes.map(code => `countryCode==${code}`).join(',');
                    newRoute = newRoute+`&segment=${segment}`;
                }
                routes.push(newRoute);
            });

            let periodeRoute = this.route.replace(':id', clientId).replace(':events', events).replace(':startDate', periods[0].start).replace(':endDate', periods[0].end)+`&period=${granularity}`;
            routes.push(periodeRoute);

            return routes;
        }
    },
    computed: {
        ...mapState(['funnelDashboardDataPeriod', 'funnelTabDataPeriod', 'clients']),
        ...mapGetters(['userClientsList']),
        clientCountries() {
            if (this.clientIsRequired && !this.client) return [];
            if (!this.userClientsList?.length) return [];
            let clients = this.userClientsList;
            if (this.clientIsRequired) clients = this.clients.filter(client => client.id == this.client);
            if (!this.clientIsRequired && this.selectedClients?.length) clients = clients.filter(client => this.selectedClients.includes(client.id));
            if (this.selectedCountries?.length) {
                clients = clients.filter(client => this.selectedCountries.find(countryCode => client.countries.includes(countryCode)))
            }
            return clients
                    .map(client => client.countries)
                    .reduce((allCountries, countries) => {
                        countries.forEach(country => {
                            if (!allCountries.includes(country)) allCountries.push(country);
                        });
                        return allCountries;
                    }, [])
                    .map(country => ({value: country, name: this.$t(`countries_code.${country}`)}))
        },
        subtitle() {
            return this.client && this.clientIsRequired ? this.clientsOptions?.find(x => x.id == this.client)?.label : null
        },
        clientsOptions() {
            if (this.clientIsRequired) return this.userClientsList?.map(x => ({id: x.id, label: x.name})) || [];
            let clients = this.userClientsList;
            if (this.selectedCountries?.length) clients = clients?.filter(client => this.selectedCountries.find(country_code => client.countries.includes(country_code)))
            return clients?.map(x => ({value: x.id, name: x.name})) || []
        },
        rangePeriod() {
            return {
                start: moment(this.period1.start, 'YYYY-MM-DD').toDate(),
                end: moment(this.period1.end, 'YYYY-MM-DD').toDate()
            }
        },
        availablePeriodGranularity() {
            const diffDays = moment(this.period1.end).diff(moment(this.period1.start), 'days');
            if (diffDays < 14) {
                return [{id: 'day', label: this.$t('chart.days') }];
            } else if (diffDays < 60) {
                return [{id: 'day', label: this.$t('chart.days') }, {id:'week', label: this.$t('chart.weeks') }];
            } else {
                return [{id: 'day', label: this.$t('chart.days') }, {id:'week', label: this.$t('chart.weeks') }, {id:'month', label: this.$t('chart.months') }];
            }
        },
        chartBarFormatedData() {
            if (!this.funnelDashboardDataPeriod.length || !this.funnelDashboardDataPeriod[0] || !this.funnelDashboardDataPeriod[1]) {
                return null;
            }
            const funnelLabels = [...new Set(this.funnelDashboardDataPeriod.flatMap(element => Object.values(element).map(x => x.label)))];
            const translatedFunnelLabels = funnelLabels.map(label => this.$t(`funnel.${label}`));

            const volumeArrayFunnelValue = this.funnelDashboardDataPeriod.map(period => Object.values(period).map(x => x.nb_visits));
            const conversionArrayFunnelValue = volumeArrayFunnelValue.map(volumeArray => volumeArray.map((num, idx) => {
                if (idx === 0) return (num / num) * 100;
                return Number((num / (idx === 0 ? num : volumeArray[0]) * 100).toFixed(2));
            }));
            const lostArrayFunnelValue = conversionArrayFunnelValue.map(conversionArray => conversionArray.map((num, idx) => {
                if (idx === 0) return num - num;
                return Number((idx === 0 ? num : conversionArray[idx - 1] - num).toFixed(2));
            }));
            return {
                translatedFunnelLabels,
                conversionArrayFunnelValue,
                lostArrayFunnelValue
            };
        },
        tabFormatedData() {
            if (!this.funnelTabDataPeriod || !this.funnelTabDataPeriod.length) {
                return null;
            }
            let initialDataByDateArray = [];
            for (let key in this.funnelTabDataPeriod) {
                for (let key2 in this.funnelTabDataPeriod[key]) {
                    for (let key3 in this.funnelTabDataPeriod[key][key2]) {
                        let labelDate = moment(key3).format(window.i18n.locale === 'fr' ? 'DD/MM/YYYY' : 'MM/DD/YYYY');
                        if(this.currentPeriodGranularity === 'week'){ 
                            labelDate = `${this.$t('chart.weekOf')} ${moment(key3.split(',')[0]).format(window.i18n.locale === 'fr' ? 'DD/MM/YYYY' : 'MM/DD/YYYY')}`;
                        } else if(this.currentPeriodGranularity === 'month'){
                            labelDate = `${this.$t('chart.monthOf')} ${moment(key3).format(window.i18n.locale === 'fr' ? 'DD/MM/YYYY' : 'MM/DD/YYYY')}`;
                        }

                        initialDataByDateArray.push({date: labelDate, [`${this.funnelTabDataPeriod[key][key2][key3].label}`]: Number(this.funnelTabDataPeriod[key][key2][key3].nb_visits) });
                    }
                }
            }
            let initialFormatedData = Object.values(initialDataByDateArray.reduce((acc, {date, ...rest}) => {
                if (!acc[date]) {
                    acc[date] = {date, ...rest};
                } else {
                    Object.keys(rest).forEach(key => {
                        if (!acc[date][key]) {
                            acc[date][key] = rest[key];
                        } else {
                            acc[date][key].volume += rest[key].volume;
                        }
                    });
                }
                return acc;
            }, {}));

            let formatedPreconversionData = initialFormatedData.map(obj => 
                this.events.map(event => obj[event])
            );

            let preformatedConversionData = [];

            formatedPreconversionData.forEach(event => {
                let conversion = [];
                event.forEach((e, idx) => {
                    if (idx === 0) {
                        conversion.push((e / e) * 100);
                    } else {
                        conversion.push((e / event[0]) * 100).toFixed(2);
                    }
                });
                preformatedConversionData.push(conversion);
            });

            let formatedConversionData = preformatedConversionData.map(conversion => {
                let obj = {};
                this.events.forEach((event, j) => {
                    obj[`${event}_conversion`] = conversion[j].toFixed(2);
                });
                return obj;
            });

            let finalMergedData = initialFormatedData.map((obj, i) => 
                Object.assign({}, obj, formatedConversionData[i])
            );

            return finalMergedData;
        }
    },
    watch: {
        client() {
            if (this.clientIsRequired) this.selectedCountries = [...this.clientCountries].map(x => x.value);
        },
        selectedCountries() {
            this.getData();
        },
        selectedClients() {
            this.getData();
        },
        'period1.start': {
            handler: 'debouncedGetData',
            deep: true
        },
        'period1.end': {
            handler: 'debouncedGetData',
            deep: true
        },
        'period2.start': {
            handler: 'debouncedGetData',
            deep: true
        },
        'period2.end': {
            handler: 'debouncedGetData',
            deep: true
        },
        tabFormatedData() {
            this.tableFields = [
                {key: 'date', size: 'l'},
            ];
            this.events.forEach((event, i) => {
                if (i === 0) {
                    this.tableFields.push({key: `${event}`, size: 'm'});
                } else if(i === this.events.length - 1){
                    this.tableFields.push({key: `${event}`, size: 'm'}, {key: `${event}_conversion`, type: 'percent', size: 'm', param: 'grey-background'});
                } else {
                    this.tableFields.push({key: `${event}_conversion`, type: 'percent', size: 'm', param: 'grey-background'}, {key: `${event}`, size: 'm'});
                }
            });
        },
        currentPeriodGranularity() {
            this.getData();
        }
    },
    async mounted() {
        if (!this.clients) this.getClients();
        if (this.clientIsRequired) {
            this.client = this.userClientsList?.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0)[0].id;
        } else {
            this.getData();
        }
    }
}
</script>

<style lang="scss">
.dashboard-performances {
    & > .field-select {
        position: relative;
        z-index: 5;
    }

    .hight-filters {
        z-index: 4;
    }

    .filters__left {
        @media screen and (min-width: 1200px) {
            .popin-calendar__inner {
                left: auto;
                right: 0;
            }
        }
    }

    @media screen and (max-width: 1199px) {
        .filters {
            align-items: flex-start;
        }
    }
}
</style>