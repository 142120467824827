<template>
    <TemplateTable
            :title="$t('menu.invoices-folders')"
            name="invoicesFolders"
            :url="$listRoutes.invoicesFolders"
            :table="invoicesFolders"
            :filters="invoicesFoldersFilters"
            :fields="fields"
    />
</template>

<script>
import { mapState } from 'vuex';
import TemplateTable from '@/components/templates/TemplateTable';
export default {
    name: 'InvoicesFolders',
    components: {TemplateTable},
    data() {
        return {
            fields: [
                {key: 'id', type: 'eye', route: 'invoices-folder', size: 'xs'},
                {key: 'client', type: 'client', size: 'l'},
                {key: 'updatedAt', type: 'date', size: 'm'},
                {key: 'startDate', type: 'date', size: 'm', translationKey: 'invoiceStartDate'},
                {key: 'endDate', type: 'date', size: 'm', translationKey: 'invoiceEndDate'},
                {key: 'state', size: 'm', translationKey: 'status'},
                {key: 'id', type: 'download', route: this.$listRoutes.invoicesFolderPdf, size: 's'},
            ]
        }
    },
    computed: {
        ...mapState(['invoicesFolders', 'invoicesFoldersFilters'])
    }
}
</script>