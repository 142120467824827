<template>
    <div class="translation-tab" :class="{'is-selected': selected}">
        <span>{{ text }}</span>
        <LinkUnderline v-if="button">{{ button }}</LinkUnderline>
        <SvgIcon v-if="icon" :name="icon"/>
    </div>
</template>

<script>
import LinkUnderline from '@/components/ui/link/LinkUnderline';
import SvgIcon from '@/components/ui/svg/SvgIcon';
export default {
    name: 'TranslationTab',
    components: {SvgIcon, LinkUnderline},
    props: {
        text: [String, Number],
        selected: Boolean,
        icon: String,
        button: String
    }
}
</script>

<style lang="scss">
.translation-tab {
    padding: 15px 10px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    color: #555;
    background-color: #fff;
    border-radius: var(--border-radius-1);
    margin: 5px 0;
    transition: color ease .2s;

    &.is-selected {
        color: #000;
        svg {
            transform: rotate(180deg);
        }
    }

    @media screen and (min-width: 1025px) {
        &:hover {
            color: var(--color-orange);
        }
    }

    .link-underline {
        color: #000;
    }
}
</style>