<template>
    <div class="catalogue-product-informations-item" :class="classes">
        <ToggleSwitcher :model="toggled" @change="emitData" />
        {{data.label}}
    </div>
</template>

<script>
import ToggleSwitcher from '@/components/ui/switcher/ToggleSwitcher.vue';
export default {
    name: 'CatalogueProductInformationsItem',
    components: {
        ToggleSwitcher,
    },
    props: {
        data: Object
    },
    data() {
        return {
            toggled: this.data.active
        }
    },
    watch: {
        // Surveille les changements de 'data.disabled'
        'toggled'(newVal) {
            if(newVal){
                this.toggled = true
                this.$emit('change',this.payload)
            }
        },
        data: {
            handler(newVal){
                if(newVal){
                    this.$emit('change',this.payload)
                }
            },
            immediate:true
        }
    },
    computed: {
        classes() {
            if (this.data.default) {
                return 'disabled_from_source';
            }
            if (this.toggled) {
                return 'disabled_from_data';
            }
            return '';
        },
        payload(){
            return {
                name:this.data.name,
                label:this.data.label,
                active: !!this.toggled
            }
        }
    },
    methods: {
        emitData() {
            this.toggled= !this.toggled
            this.$emit('change',this.payload)
        }
    },

}
</script>

<style lang="scss" scoped>
.catalogue-product-informations-item {
    display: flex;
    align-items: center;
    gap: 20px;
    border: var(--border-1);
    padding: 10px;
    background: #fff;
    transition: opacity ease-in .5s;
    opacity: .5;
    &.disabled_from_source {
        opacity: .5;
        pointer-events: none;
    }
    &.disabled_from_data {
        opacity: 1;
    }
}
</style>