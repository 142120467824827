<template>
    <TemplateTable
            :title="$t('menu.recom_orders')"
            :text="$t('recom_orders.description')"
            name="orders"
            :url="$listRoutes.recomOrders"
            :table="orders"
            :filters="ordersFilters"
            :fields="fields"
    />
</template>

<script>
import { mapState } from 'vuex';
import TemplateTable from '@/components/templates/TemplateTable';
export default {
    name: 'RecomOrdersPage',
    components: {TemplateTable},
    data() {
        return {
            fields: [
                {key: 'id', type: 'eye', route: 'recom_order', size: 'xs'},
                {key: 'reference', translationKey: 'id', size: 'm'},
                {key: 'brand.name', type: 'client', size: 'l', translationKey: 'client'},
                {key: 'createdAt', translationKey: 'orderDate', type: 'date'},
                {key: 'customer.id', type: 'CmsCustomer', size: 'xl', translationKey: "email"},
                {key: 'customer.profile', type: 'tagType', size: 'm'},
                {key: 'channel', type: 'tagType', translationKey: 'canal'},
                {key: 'total', type: 'formatPrice', translationKey: 'totalttc'},
                {key: 'payments', type: 'tagTypePayments', size: 'm'},
                {key: 'state', translationKey: "orderStatus", type: 'tagType', size: 'm'},
                {key: 'shipment.state', translationKey: "shipmentStatus", type: 'tagType', size: 'm'},
                {key: 'orderReturns', type: 'tagTypeReturns', size: 'm'},
                {key: 'itemsNb', translationKey: 'countArticles'},
                {key: 'shippingAddress.countryCode'}
            ]
        }
    },
    computed: {
        ...mapState(['orders', 'ordersFilters'])
    }
}
</script>