<template>
  <div class="progress-bar">
    <div class="progress-bar__values">
        <div>
            <TextSimple weight="bold" size="xs">{{ start_value }} :</TextSimple>
            <TextSimple weight="normal" size="xs">{{ start_text }}</TextSimple>
        </div>
        <div>
            <TextSimple weight="bold" size="xs">{{ total_value }} :</TextSimple>
            <TextSimple weight="normal" size="xs">{{ total_text }}</TextSimple>
        </div>
    </div>
    <div class="progress-bar__container">
        <div class="progress-bar__percentage">{{progression}}</div>
        <div class="progress-bar__progression" :style="{width:progression}"></div>
    </div>
  </div>
</template>

<script>
import TextSimple from '@/components/ui/text/TextSimple.vue';
export default {
    components: {
        TextSimple,
    },
    props: {
        start_value: Number,
        start_text: String,
        total_value: Number,
        total_text: String,
        progression: String
    },
}
</script>

<style lang="scss" scoped>
.progress-bar {
    display:flex;
    flex-direction: column;
    margin:40px 0 10px;
    min-width: 100%;
    
    &__values {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;
        & > div {
            display: flex;
            gap: 4px;
        }
    }

    &__container {
        position: relative;
        min-height: 14px;
        display: flex;
        justify-content: center;
    }
    &__percentage {
        position: relative;
        z-index: 1;
        background: #fff;
        margin: 2px;
        padding: 2px 4px;
        border-radius: var(--border-radius-1);
    }
    &__progression {
        position: absolute;
        transition: all ease-in .35s;
        border-radius: var(--border-radius-1);
        top:0;
        left: 0;
        bottom: 0;
        background:var(--color-blue)
    }
}
</style>