<template>
    <Popin :title="$t('metrics.title')" :subtitle="$t('metrics.subtitle')" ref="popin">

        <template v-slot:content>
            <div class="metrics">

                <div class="metrics__col" v-for="(group, i) in [available, selected]" :key="group.name">
                    <TextSimple size="xxs" color="grey">
                        {{ $t(`metrics.${group.name}`) }}
                        <span v-if="i === 1">{{ selected.fields.length }}</span>
                    </TextSimple>

                    <draggable :list="group.fields" class="metrics__list" group="field" @change="limit" :data-testid="'popin-filter-categorie-list-' + group.name">
                        <transition-group>
                            <div class="metrics__item" v-for="field in group.fields" :key="field.name" :class="{'is-hover': isHover === field.name}" :data-testid="'popin-filter-categorie-' + field.name">
                                <SvgIcon name="drag"/>
                                <span>{{ field.title }}</span>
                                <SvgIcon :name="i === 0 ? 'plus' : 'cross'"
                                    :data-testid="'popin-filter-categorie-' + field.name + '-close'"
                                    @mouseover.native="isHover = field.name"
                                    @mouseleave.native="isHover = null"
                                    @click.native.prevent="inverse(i, field)"/>
                            </div>
                        </transition-group>
                    </draggable>

                </div>

            </div>
        </template>

        <template v-slot:bottom>
            <LinkUnderline @click.native.prevent="cancel">{{ $t('global.cancel') }}</LinkUnderline>
            <Button size="m" color="black" @click.native.prevent="$emit('change', [...selected.fields]); $refs.popin.close()">{{ $t('metrics.button') }}</Button>
        </template>

    </Popin>
</template>

<script>
import draggable from 'vuedraggable'
import Popin from '@/components/ui/popin/Popin';
import Button from '@/components/ui/button/Button';
import TextSimple from '@/components/ui/text/TextSimple';
import SvgIcon from '@/components/ui/svg/SvgIcon';
import LinkUnderline from '@/components/ui/link/LinkUnderline';

export default {
    name: 'PopinMetrics',
    components: {LinkUnderline, TextSimple, Button, Popin, SvgIcon, draggable},
    props: {
        fields: Array,
        allFields: Array
    },
    data() {
        return {
            isHover: null,
            available: {
                name: 'available',
                fields: [...this.allFields.filter(c => !this.fields.find(x => x.name ===  c.name))]
            },
            selected: {
                name: 'selected',
                fields: [...this.fields]
            }
        }
    },
    methods: {
        inverse(i, field) {
            if (i === 0) {
                this.selected.fields.push(field)
            } else {
                this.selected.fields = this.selected.fields.filter(f => f.name !== field.name)
            }
            this.available.fields = this.allFields.filter(c => !this.selected.fields.find(x => x.name ===  c.name));
            this.isHover = null;
            //this.limit();
        },
        limit() {
            /*
            if (this.selected.fields.length > 10) {
                this.selected.fields = this.selected.fields.slice(0, 10);
                this.available.fields = this.allFields.filter(c => !this.selected.fields.find(x => x.name ===  c.name));
            }
             */
        },
        cancel() {
            this.$refs.popin.close();
            this.available.fields = this.allFields.filter(c => !this.fields.find(x => x.name ===  c.name));
            this.selected.fields = this.fields;
        },
        open() {
            this.$refs.popin.open()
        }
    },
    watch: {
        '$i18n.locale': function() {
            this.available.fields = [...this.allFields.filter(c => !this.fields.find(x => x.name ===  c.name))];
            this.selected.fields = [...this.fields];
            this.$emit('change', [...this.fields]);
        }
    }
}
</script>

<style lang="scss">
.metrics {
    display: flex;
    gap: 10px;

    &__col {
        width: 50%;
        border-radius: var(--border-radius-2);
        border: var(--border-1);

        & > p {
            background-color: var(--color-bg-light);
            padding: 10px;
            display: flex;
            justify-content: space-between;
        }

        &:first-of-type {
            .metrics__item.is-hover {
                background-color: var(--color-green-light) !important;
            }
        }

        &:nth-of-type(2) {
            & > p {
                background-color: var(--color-green-light);
            }

            .metrics__item.is-hover {
                background-color: var(--color-red-light) !important;
            }
        }
    }

    &__list {
        padding: 10px;
        height: 100%;
        max-height: calc(100vh - 450px);
        overflow-y: auto;

        & > span {
            display: block;
            height: 100%;
            width: 100%;
        }
    }

    &__item {
        padding: 10px;
        border-radius: 30px;
        cursor: grab;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        user-select: none;
        transition: background-color ease .2s;
        gap: 10px;

        @media screen and (min-width: 1025px) {
            &:hover {
                background-color: var(--color-bg-light);
            }

            &:not(:hover) .icon:last-of-type {
                opacity: 0;
            }
        }

        .icon:last-of-type {
            margin-left: auto;
            width: 20px;
            height: 20px;
            border-radius: 20px;
            cursor: pointer;
            background-color: #fff;
            transition: transform ease .2s, box-shadow ease .2s, opacity ease .2s;

            svg {
                width: 9px;
            }

            &:hover  {
                transform: translateY(-2px);
                box-shadow: 0px 2px 2px 2px rgba(0,0,0,0.1);;
            }
        }

        .icon {
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: 10px;
            }
        }
    }
}
</style>