<template>
    <div class="collections-data-sources">
        <Field class="collections-data-sources__select" type="select" :border="true" :options="dataSources" :model="dataSource"  @change="e=> dataSource = e"/>
        <TextSimple class="collections-data-sources__description" size="s" weight="regular">{{ descriptionText }}</TextSimple>
    </div>  
</template>

<script>
import { mapState } from 'vuex';
import Field from '@/components/ui/form/fields/Field.vue';
import TextSimple from '@/components/ui/text/TextSimple.vue';
export default {
    name:'CollectionsDataSourcesSelector',
    components: {
        Field,
        TextSimple
    },
    data() {
        return {
            dataSource: ''
        }
    },
    watch: {
        dataSource:'emitData'
    },
    computed: {
        ...mapState(['catalogueCollection']),
        dataSources() {
            return [
                {label:this.$t('collections.catalog_data'), value:'catalog_data'},
                {label:this.$t('collections.manual_tags'), value: 'manual_tags'},
                {label:this.$t('collections.product_lifecyle'), value: 'product_lifecyle'},
            ]
        },
        defaultDataSource(){
            return this.catalogueCollection?.parameters?.source
                ? this.catalogueCollection.parameters.source
                : this.dataSources[0]?.value
        },
        descriptionText(){
            return this.$t(`collections.text_${this.dataSource}`)
        }
    },
    methods: {
        emitData(e) {
            let payload = {data_source:e}
            this.$emit('change', { payload, type: "source" });
        }
    },
    mounted () {
        this.dataSource = this.defaultDataSource;
    },

}
</script>

<style lang="scss" scoped>
.collections-data-sources {
    
    margin: 10px 0 20px;
    display: flex;
    align-items: center;
    gap: 20px;
    
    &__select {
        margin-top: 0;
    }
    &__description {
        flex: 3;
    }
    @media screen and (max-width: 550px) {
        flex-direction: column;
    }
}
</style>