<template>
    <LinkUnderline class="page-back" :to="to" :border-hover="true">
        <SvgIcon name="arrow left"/>
        <span>{{ text }}</span>
    </LinkUnderline>
</template>

<script>
import SvgIcon from '@/components/ui/svg/SvgIcon';
import LinkUnderline from '@/components/ui/link/LinkUnderline';

export default {
    name: 'PageBackWithLink',
    components: {LinkUnderline, SvgIcon},
    props: {
        type: String,
        text: String,
        to: Object,
    },
}
</script>

<style lang="scss">
.page-back {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    gap: 8px;
    text-transform: uppercase;
    letter-spacing: .5px;
    padding-bottom: 5px;
    font-weight: 500;

    svg {
        width: 10px;
        height: auto;
    }
}
</style>
