<template>
    <GridCard>
        <CataloguesCardHeader 
            :currentCatalogue="catalogue"
            :lang="lang"
             />
        <CataloguesCardContent :lines="catalogueLines" />
    </GridCard>
</template>

<script>
import GridCard from '@/components/ui/grid/GridCard';
import CataloguesCardHeader from '@/components/ui/catalogue/CataloguesCardHeader.vue'
import CataloguesCardContent from '@/components/ui/catalogue/CataloguesCardContent'
export default {
    components: {
        CataloguesCardHeader,
        CataloguesCardContent,
        GridCard
    },
    data() {
        return {
            catalogueLines: [
                {lang: this.catalogue.lang},
                {name: this.catalogue.file.name},
                {update_at: this.catalogue.update_at},
            ]
        }
    },
    props: {
        catalogue: Object,
        lang: String
    },
    computed: {
        title() {
            return this.catalogue.title 
        }
    },
}
</script>

<style lang="scss" scoped>
.grid-card {
    padding: 10px 20px;
    background: #fff;
    margin-bottom: var(--field-padding);
    border-radius: var(--border-radius-2);
    border: var(--border-1);
    @media screen and (max-width: 550px) {
        padding: 10px;
    }
}
</style>
