<template>
    <TemplateDashboard
            :client-is-required="false"
            :route="$listRoutes.dashboardBuyers"
            :filters-route="$listRoutes.dashboardBuyersFilters"
            :comparaison="true"
            :title="$t('dashboard-buyers.title')"/>
</template>

<script>
import TemplateDashboard from '@/components/templates/TemplateDashboard';

export default {
    name: 'DashboardBuyers',
    components: {TemplateDashboard},
}
</script>
