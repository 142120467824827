<template>
    <label 
        :for="name"
        class="field__file" 
        :class="errorMessage ? 'field__error' : ''"
    >
        <span v-html="text"></span>
        <input required="true" type="file" :name="name" :id="name" :accept="allowedExtensions" @change="onFileSelected" />
        <span class="field__label is-up">{{ label }}</span>
        <div class="error" v-if="errorMessage"><strong>{{ errorMessage }}</strong></div>
    </label>
</template>

<script>
export default {
    props: {
        allowedExtensions: Array,
        maxSizeFile: Number,
        label: String,
        text: String,
        name: String,
    },
    data() {
        return {
            errorFileFormat: false,
            errorFileSize: false,
            errorMessage: null,
        }
    },
    methods: {
        onFileSelected(event) {
            const that = this;
            const file = event.target.files[0];
            const fileName = file.name.split('.');
            const fileExt = `.${fileName[fileName.length-1]}`;
            const isFileFormatValid = this.allowedExtensions.includes(fileExt);
            let isFileSizeValid = !this.maxSizeFile || this.maxSizeFile >= file.size;
            
            if(fileExt === '.jpg' || fileExt === '.jpeg' || fileExt === '.png' || fileExt === '.webp'){
                const reader = new FileReader();
                reader.onload = function(event) {
                    const img = new Image();
                    img.src = event.target.result;
                    let width = null;
                    let height = null;

                    img.onload = function() {
                        width = img.width;
                        height = img.height;

                        if(width >= 1100 && height >= 600){
                            isFileSizeValid = false;
                        }
                        this.errorFileFormat = !isFileFormatValid;
                        this.errorFileSize = !isFileSizeValid;

                        if (this.errorFileFormat && !this.errorFileSize) {
                            that.errorMessage = that.$t('catalogue.form_filetype');
                        } else if (!this.errorFileFormat && this.errorFileSize) {
                            that.errorMessage = that.$t('catalogue.form_filesize');
                        } else if (this.errorFileFormat && this.errorFileSize) {
                            that.errorMessage = that.$t('catalogue.form_files');
                        } else {
                            that.$emit('change', isFileFormatValid && isFileSizeValid ? file : null);
                            that.errorMessage = null;
                        }
                    };
                };
                reader.readAsDataURL(file)
            } else {
                this.errorFileFormat = !isFileFormatValid;
                this.errorFileSize = !isFileSizeValid;
                if (this.errorFileFormat && !this.errorFileSize) {
                    this.errorMessage = this.$t('catalogue.form_filetype');
                } else if (!this.errorFileFormat && this.errorFileSize) {
                    this.errorMessage = this.$t('catalogue.form_filesize');
                } else if (this.errorFileFormat && this.errorFileSize) {
                    this.errorMessage = this.$t('catalogue.form_files');
                } else {
                    this.$emit('change', isFileFormatValid && isFileSizeValid ? file : null);
                    this.errorMessage = null;
                }
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.field {
    &__file {
        position: relative;
        margin-top: 20px;
        display: flex;
        gap: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 200px;
        padding: 20px;
        border-radius: calc(var(--field-height)/2);
        background: #fff;
        color: var(--color-text-black);
        cursor: pointer;
        transition: background .2s ease-in-out, border .2s ease-in-out;

        [type=file] {
            width: 350px;
            max-width: 100%;
            color: var(--color-text-black);
            padding: 5px;
            background: #fff;
            border-radius: var(--border-radius-2);
            border: var(--border-2);

            &::file-selector-button {
                margin-right: 20px;
                border: none;
                background: var(--color-text-black);
                padding: 10px 20px;
                border-radius: var(--border-radius-2);
                color: #fff;
                cursor: pointer;
                transition: background .2s ease-in-out;
            }

            &::file-selector-button:hover {
                background: var(--color-text-grey);
            }

        }


        &:hover {
        background: #eee;
        }

        &:hover .span {
        color: #222;
        }

        span {
        color: var(--color-text-black);
        font-weight: normal;
        text-align: center;
        transition: color .2s ease-in-out;
        }

        .field__label {
            pointer-events: none;
            transition: top ease-out 0.2s, font-size ease-out 0.2s, color ease-out 0.2s;
            font-size: 1.1rem;
            color: #999;
            font-weight: 400;
        }

    }
    &__error {
        background: var(--color-red-light);

        .error{
            color: var(--color-text-red);
        }
    }
}
</style>