<template>
  <div class="status">
    <div class="status__header" :class="errors ? 'status__errors' : 'status__ok' ">
        <SvgIcon :name="errors ? 'cross' :'check'" />
        <p>{{ errors ? $t('catalogue.import_fail') : $t('catalogue.import_success') }}</p>
    </div>
    <div class="status__content">
        <span v-for="(line,index ) in statusLines" :key="index">{{line.traduction}} : <strong>{{line.value}}</strong></span>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/components/ui/svg/SvgIcon';
export default {
    components: {
        SvgIcon,
    },
    props: {
        missingDatas: Number,
        statusLines: Array,
    },
    computed: {
        errors(){
            return this.missingDatas ? this.missingDatas > 0 : false
        }
    },
}
</script>
<style lang="scss" scoped>
.status {
    padding:1rem;
    &__header {
        display: inline-flex;
        align-items: center;
        gap:.8rem;
        padding:  .8rem ;
        border-radius: 4px;
        margin-bottom: 1rem;
        .icon {
            padding: 1rem;
            border-radius: 50%;
            background: #fff;
        }
    }
    &__ok {
        background:var(--color-green);
        --svg-color: var(--color-green);
        color:#fff
    }
    &__errors {
        background:var(--color-red-light);
        color:var(--color-red);
        --svg-color: var(--color-red);   
    }
    &__content {
        display: flex;
        flex-direction: column;
        gap: .4rem;
    }
}
</style>