<template>
    <TemplateDetails :get-function="getReturn" :data="ordersReturn" :loading="ordersReturnLoading">

        <PageBack/>

        <PageHeader :title="$t('global.return')">
            <template v-slot:text v-if="ordersReturn">
                <TextSimple color="grey">{{ ordersReturn.returnDate | dateHour }}</TextSimple>
                <TagList>
                    <TagType :value="ordersReturn.status"/>
                </TagList>
            </template>
            <template v-slot:actions>
                <ButtonLink :to="shopifyOrderUrl" icon="bag" size="m" color="grey">{{ $t('return.see-order') }}</ButtonLink>
                <ButtonLink :to="shopifyProductUrl" icon="tshirt" size="m" color="grey">{{ $t('return.see-product') }}</ButtonLink>
            </template>
        </PageHeader>

        <GridContainer>
            <GridCard>
                <GridContent>
                    <SectionTableInfos :object="ordersReturn" :fields="fields"/>
                </GridContent>
            </GridCard>

            <template v-slot:aside>
                <GridCard>
                    <GridContent>
                        <GridHeader :title="$t('global.user')"/>
                        <SectionTableInfos :object="ordersReturn" :fields="customerFields"/>
                    </GridContent>
                </GridCard>
                <GridCard>
                    <GridContent>
                        <GridHeader :title="$t('global.resale')"/>
                        <SectionTableInfos :object="ordersReturn" :fields="resaleFields"/>
                    </GridContent>
                </GridCard>
            </template>

        </GridContainer>

    </TemplateDetails>
</template>

<script>
import TemplateDetails from '@/components/templates/TemplateDetails';
import { mapState, mapActions } from 'vuex';
import PageBack from '@/components/ui/page/PageBack';
import PageHeader from '@/components/ui/page/PageHeader';
import SectionTableInfos from '@/components/sections/SectionTableInfos';
import GridContainer from '@/components/ui/grid/GridContainer';
import GridCard from '@/components/ui/grid/GridCard';
import GridContent from '@/components/ui/grid/GridContent';
import GridHeader from '@/components/ui/grid/GridHeader';
import TextSimple from '@/components/ui/text/TextSimple';
import TagList from '@/components/ui/tag/TagList';
import TagType from '@/components/ui/tag/TagType';
import ButtonLink from '@/components/ui/button/ButtonLink';

export default {
    components: {ButtonLink, TagType, TagList, TextSimple, GridHeader, GridContent, GridCard, GridContainer, SectionTableInfos, PageHeader, PageBack, TemplateDetails},
    name: "ReturnPage",
    data() {
        return {
            fields: [
                {key: 'id'},
                {key: 'returnDate', type: 'date'},
                {key: 'status', type: 'tagType'},
                {key: 'shopifyTitle'},
                {key: 'shopifyPriceNoTax', type: 'formatPrice'},
                {key: 'shopifyPriceTax', type: 'formatPrice'},
                {key: 'shopifyPriceWithTax', type: 'formatPrice'},
                {key: 'shopifyId', type: 'shopifyProduct'},
                {key: 'shopifyOrderId', type: 'shopifyOrder'},
                {key: 'sourceAchat', type: 'tagType'},
                {key: 'saleType', type: 'tagType'},
                {key: 'saleCountry'},
                {key: 'warehouseCountry'},
            ],
            customerFields: [
                {key: 'client', type: 'client'},
                {key: 'user.firstname'},
                {key: 'user.lastname'},
                {key: 'user.email', type: 'userEmail'}
            ],
            resaleFields: [
                {key: 'condition.name', translationKey: 'condition'},
                {key: 'amountCredit', type: 'formatPrice'},
                {key: 'sourceVente', type: 'tagType'},
                {key: 'tradeinCountry'},
            ]
        }
    },
    computed: {
        ...mapState(['ordersReturn', 'ordersReturnLoading', 'clients']),
        shopifyProductUrl() {
            if (!this.ordersReturn || !this.ordersReturn.client) return false;
            return `${this.clients.find(c => c.id === this.ordersReturn.client.id)?.frontUrl}/admin/products/${this.ordersReturn.shopifyId}`
        },
        shopifyOrderUrl() {
            if (!this.ordersReturn || !this.ordersReturn.client) return false;
            return `${this.clients.find(c => c.id === this.ordersReturn.client.id)?.frontUrl}/admin/orders/${this.ordersReturn.shopifyOrderId}`
        }
    },
    methods: {
        ...mapActions(['getReturn'])
    }
}
</script>