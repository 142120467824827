<template>
    <div class="layout-profile" @mouseenter="resizeSwitch" v-if="user">
        <PopinTooltip>
            <template v-slot:button>
                <UserCard :name="$t('account.title')" :email="user.email" :image="user.avatar" :to="{name: 'profile'}"/>
                <SvgIcon name="dots"/>
            </template>
            <template v-slot:content>
                <LayoutSwitchLang ref="switch"/>
                <ul class="layout-profile__menu">
                    <li>
                        <LinkWithHover :to="{name: 'profile'}">
                            <TextSimple>{{ $t('menu.account-parameters') }}</TextSimple>
                        </LinkWithHover>
                    </li>
                    <li>
                        <LinkWithHover @click.native.prevent="logoutUser">
                            <TextSimple>{{ $t('menu.logout') }}</TextSimple>
                        </LinkWithHover>
                    </li>
                </ul>
            </template>
        </PopinTooltip>
    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import UserCard from '@/components/ui/user/UserCard';
import PopinTooltip from '@/components/ui/popin/PopinTooltip';
import TextSimple from '@/components/ui/text/TextSimple';
import LinkWithHover from '@/components/ui/link/LinkWithHover';
import LayoutSwitchLang from '@/components/ui/layouts/LayoutSwitchLang';
import SvgIcon from '@/components/ui/svg/SvgIcon';
export default {
    name: 'LayoutProfile',
    components: {SvgIcon, LayoutSwitchLang, LinkWithHover, TextSimple, PopinTooltip, UserCard},
    computed: {
        ...mapState(['user'])
    },
    methods: {
        ...mapActions(['logout']),
        logoutUser() {
            this.logout();
            this.$router.push({name: 'home'})
        },
        resizeSwitch() {
            if (this.$refs.switch) this.$refs.switch.resize()
        }
    }
}
</script>

<style lang="scss">
.layout-profile {
    margin-top: auto;
    padding-top: 20px;
    border-top: var(--border-1);
    flex-shrink: 0;

    &__menu {
        padding-top: 10px;
        margin-top: 20px;
        border-top: var(--border-1);
    }

    .tooltip__button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        & > .icon {
            flex-shrink: 0;
            margin-left: 10px;

            svg {
                height: 12px;
                width: auto;

                path {
                    fill: var(--color-text-grey);
                }
            }
        }
    }
}
</style>