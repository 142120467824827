<template>
    <div>
    <TemplateTable
        :title="pageTitle"
        name="retailAvailableProducts"
        :url="$listRoutes.retailAvailableProducts"
        :table="!processing ? retailAvailableProducts : null"
        :filters="retailAvailableProductsFilters"
        :fields="fieldsToShow"
        :hide-export="true"
        :default-items-per-page="40"
        :no-cache="true"
        :auto-refresh="!selectionMode"
        :display-select="selectData">

        <template v-slot:actions>
            <Button v-if="selectionMode" size="m" color="red" icon="cross" @click.native.prevent="cancelSelectedArticles">{{ $t('global.cancel') }}</Button>
            <Button v-if="!selectionMode" size="m" icon="arrow right up" @click.native.prevent="selectionMode = true">{{ $t("retail.return-unsold") }}</Button>
            <Button v-else size="m" icon="arrow right" @click.native.prevent="sendSelectedArticles">{{ $t("retail.return-this-unsold") }} ({{ retailArticlesSelected.length }})</Button>
        </template>

        <template v-slot:right>
            <Button v-if="selectionMode && retailArticlesSelected.length !== retailAvailableProducts.pagination.totalCount" :loading="processing" size="l" @click.native.prevent="selectAll">{{ $t("global.selectAll") }}</Button>
        </template>

    </TemplateTable>
</div>
</template>

<script>
import moment from 'moment';
import TemplateTable from '@/components/templates/TemplateTable';
import { mapState, mapActions } from 'vuex';
import Button from '@/components/ui/button/Button';

export default {
    name: 'RetailStock',
    components: {Button, TemplateTable},
    data() {
        return {
            fieldsBase: [
                {key: 'id', size: 'xs', type: 'productLightbox', label: ' ', noTranslate: true},
                {key: 'transfert_date', size: 'm', type: 'date'},
                {key: 'title', size: 'xxl'},
                {key: 'etat', size: 'm', label: 'condition'},
                {key: 'color', size: 'm'},
                {key: 'size', size: 's'},
                {key: 'price', size: 's', type: 'money'},
                {key: 'photo1', size: 'xs', type: 'productImg', translation: 'photo'},
                {key: 'url', size: 'm', type: 'productUrl'},
            ],
            fields: [],
            processing: false,
            selectionMode: false,
            selectedItem: this.$route.query.transfert_date || "",
            dataSinceOptionsDefault: [
                { label: 'retail.lte3d', id: moment().subtract(3, 'd').format('YYYY-MM-DD 00:00:00') },
                { label: 'retail.lte1w', id: moment().subtract(1, 'w').format('YYYY-MM-DD 00:00:00') },
                { label: 'retail.lte2w', id: moment().subtract(2, 'w').format('YYYY-MM-DD 00:00:00') },
                { label: 'retail.lte1m', id: moment().subtract(1, 'M').format('YYYY-MM-DD 00:00:00') },
                { label: 'retail.lte2m', id: moment().subtract(2, 'M').format('YYYY-MM-DD 00:00:00') },
                { label: 'retail.lte3m', id: moment().subtract(3, 'M').format('YYYY-MM-DD 00:00:00') },
                { label: 'retail.lte6m', id: moment().subtract(6, 'M').format('YYYY-MM-DD 00:00:00') },
                { label: 'retail.lte9m', id: moment().subtract(9, 'M').format('YYYY-MM-DD 00:00:00') },
                { label: 'retail.lte1y', id: moment().subtract(1, 'y').format('YYYY-MM-DD 00:00:00') },
                { label: 'retail.lte2y', id: moment().subtract(2, 'y').format('YYYY-MM-DD 00:00:00') },
                { label: 'retail.all-products', id: moment('2000-01-01').format('YYYY-MM-DD 00:00:00') },
            ]
        }
    },
    watch: {
        retailAvailableProducts() {
            this.getFields()
        },
        selectedItem(value) {
            // If the filter exist retreive its index
            let index = this.filtersSelected.findIndex( x => x.key === 'transfert_date' && x.operator === 'lte');

            // Else create a new filter (empty by default)
            // Then grab its index
            if (index == -1) {
                this.addFilter();
                index = this.filtersSelected.length-1
            }
            
            // Fill the filter value
            this.updateFilter({i: index, field: {key: 'transfert_date', operator:'lte', value: value}});
        },
        
        'transfertDateQuery': {
            handler(x) {
                this.selectedItem = x ?? null;
            },
            deep: true,
            immediate: true
        },
    },
    computed: {
        selectData(){
            return {
                options: this.dataSinceOptions,
                selectedItem: this.selectedItem,
                label: this.$t('retail.stock-entry-date'),
                sorted: false,
                updateFn: (value) => {
                    this.selectedItem = value;
                }
            }
        },
        transfertDateQuery() {
            return this.$route.query['transfert_date:lte'];
        },
        dataSinceOptions(){
            return this.dataSinceOptionsDefault.map(option => {
                return {
                    ...option,
                    label: this.$t(option.label)
                }
            })
        },
        pageTitle(){
            if( this.retailAvailableProducts?.pagination ){
                return `${this.$t('client.stock.title')} (${this.retailAvailableProducts.pagination.totalCount})`;
            }else{
                return this.$t('client.stock.title');
            }
        },
        fieldsToShow(){
            if( this.selectionMode ){
                return [
                    {key: 'id', type: 'productCheckbox', size: 's', label: 'selected', param: 'retailArticlesSelected'},
                    ...this.fields
                ]
            }else{
                return this.fields
            }
        },
        ...mapState(['retailAvailableProducts', 'retailAvailableProductsFilters', 'retailArticlesSelected', 'retailArticlesSelectedClient', 'filtersSelected']),
        products() {
            return this.retailAvailableProducts?.items
        }
    },
    methods: {
        ...mapActions(['setRetailArticlesSelected', 'returnRetailArticles', 'addFilter', 'updateFilter', 'setAllArticlesAsSelected']),
        getFields() {
            let fields = [...this.fieldsBase];
            const product = this.products ? this.products[0] : null;
            if (product) {
                const productKeys = Object.keys(product);
                let optionalFields = [
                    {label: 'gender', values: ['univers', 'genre', 'gender']},
                    {label: 'tag_size', values: ['size', 'pointure', 'taille']},
                    {label: 'tag_color', values: ['couleur', 'color']},
                ];
                optionalFields.forEach(field => {
                    const key = productKeys.find(x => field.values.find(v => x.includes(v)));
                    if (key) fields.push({key: key, size: 'm', label: field.label})
                });
            }
            this.fields = [...fields]
        },
        cancelSelectedArticles(){
            this.setRetailArticlesSelected([]);
            this.selectionMode = false;
        },
        async sendSelectedArticles(){
            const returnCall = await this.returnRetailArticles(this.retailArticlesSelected);
            if( returnCall ){
                this.$nextTick(() => {
                    // Empty the current selection 
                    this.setRetailArticlesSelected([]);
                    // Close the selection mode, this will re-enable the auto-refresh that will refresh the table data
                    this.selectionMode = false;
                });
            }else{
                console.error('Error while returning retail articles');
            }
        },
        init() {
            this.setRetailArticlesSelected([]);
        },
        async selectAll(){
            this.processing = true;
            await this.setAllArticlesAsSelected();
            this.processing = false;
        }
    },
    beforeMount() {
        this.init();
        this.getFields();
    }
}
</script>

