<template>
    <p class="text" :style="customStyles"><span v-if="htmlContent" v-html="htmlContent.replaceAll('\n', '<br>')"></span><slot/></p>
</template>

<script>
export default {
    name: 'TextSimple',
    props: {
        weight: String,
        color: String,
        up: Boolean,
        italic: Boolean,
        size: String,
        htmlContent: String
    },
    data() {
        return {
            weights: {
                bold: 600,
                medium: 500,
                normal: 400,
                light: 100
            },
            colors: {
                black: 'var(--color-text-black)',
                grey: 'var(--color-text-grey)',
                red: 'var(--color-text-red)',
                green: 'var(--color-text-green)'
            },
            sizes: {
                xxxs: '.9rem',
                xxs: '1.1rem',
                xs: '1.2rem',
                s: '1.3rem',
                m: '1.4rem',
                l: '1.5rem',
                xl: '1.8rem'
            }
        }
    },
    computed: {
        customStyles() {
            let styles = {};
            if (this.weight && this.weights[this.weight]) styles.fontWeight = this.weights[this.weight];
            if (this.color && this.colors[this.color]) styles.color = this.colors[this.color];
            if (this.size && this.sizes[this.size]) styles.fontSize = this.sizes[this.size];
            if (this.up) {
                styles.textTransform = 'uppercase';
                styles.letterSpacing = '.05em';
            }
            if (this.italic) styles.fontStyle = 'italic';
            return styles
        }
    }
}
</script>

<style lang="scss">
.text {
    ul {
        list-style-type: disc;
        list-style-position: inside;
        margin: 7px 0 10px;
    }

    li {
        margin: 4px 0;
    }
}
</style>