<template>
    <div class="page-error">
        <PageBack type="error" />
        <TextTitle v-if="title" size="xl">{{ title }}</TextTitle>
        <TextSimple v-if="subtitle">{{ subtitle }}</TextSimple>
        <ButtonLink v-if="button && to" :to="to" icon="right" size="m" color="grey">{{ button }}</ButtonLink>
    </div>
</template>

<script>
import TextTitle from '@/components/ui/text/TextTitle';
import TextSimple from '@/components/ui/text/TextSimple';
import ButtonLink from '@/components/ui/button/ButtonLink';
import PageBack from '@/components/ui/page/PageBack';
export default {
    name: 'PageError',
    components: {ButtonLink, TextSimple, TextTitle, PageBack},
    props: {
        title: String,
        subtitle: String,
        button: String,
        to: [String, Object]
    }
}
</script>

<style lang="scss">
.page-error {
    max-width: 550px;

    .title {
        margin-bottom: 20px;
    }

    .button {
        margin-top: 30px;
        width: fit-content;
    }
}
</style>