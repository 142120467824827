import API from '@/api/api-admin';
import listRoutes from '@/api/list-routes';

const state = {
    admin: null,
    adminLoading: null,
    roles: [
        //'ROLE_ADMIN',
        //'ROLE_SUPER_ADMIN',
        'ROLE_LOGISTICIAN',
        'ROLE_WAREHOUSE_ADMIN',
        //'ROLE_USER',
        'ROLE_CLIENT',
        //'ROLE_FINANCE',
        'ROLE_STORE',
        //'ROLE_PHOTO',
        //'ROLE_DISCOUNTCODE',
        'ROLE_BRAND_ADMIN',
        'ROLE_BRAND_FINANCE',
        'ROLE_BRAND_SAV',
        //'ROLE_FAUME_API',
        //'ROLE_PUBLIC_API',
        'ROLE_SUPER_STORE',
        'ROLE_BRAND_STORE'
    ],
    rolesClient: ['ROLE_CLIENT', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE', 'ROLE_BRAND_SAV'],
    rolesFaume: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_FAUME_API'],
    rolesWarehouse: ['ROLE_WAREHOUSE_ADMIN', 'ROLE_LOGISTICIAN'],
    rolesStore: ['ROLE_SUPER_STORE', 'ROLE_STORE', 'ROLE_BRAND_STORE'],
    adminRoles: null
};

const mutations = {
    SET_ADMIN(state, user) {
        state.admin = user
    },
    SET_ADMIN_LOADING(state, value) {
        state.adminLoading = value
    },
    SET_ADMIN_ROLES(state, roles) {
        state.adminRoles = {...roles}
    }
};

const actions = {
    getAdmin({commit, state}, id) {
        if (!state.admin || state.admin.id != id) {
            commit('SET_ADMIN_LOADING', true);
            commit('SET_ADMIN', null);
            API.get(listRoutes.admin.replace(':id', id))
                .then(res => commit('SET_ADMIN', res.data))
                .finally(() => commit('SET_ADMIN_LOADING', false))
        } else {
            commit('SET_ADMIN_LOADING', false);
        }
    },
    editAdmin({commit, state}, data) {
        return new Promise((resolve, reject) => {
            data = {...data};
            // Date retreived is a wharehouse object but we should send only the id using warehouseId key.
            data.warehouseId = data.warehouse?.id ? data.warehouse?.id : data.warehouse;
            delete data.warehouse;

            Object.keys(data).forEach(key => {
                if (!data[key] && data[key] !== false) delete data[key]
            });
            API.put(listRoutes.admin.replace(':id', state.admin.id), {...data})
                .then(res => res.data)
                .then(admin => {
                    commit('SET_ADMIN', admin);
                    resolve(admin)
                })
                .catch(() => reject())
        })
    },
    deleteAdmin({commit, state}) {
        return new Promise((resolve, reject) => {
            API.delete(listRoutes.admin.replace(':id', state.admin.id))
                .then(res => res.data)
                .then(admin => {
                    commit('SET_ADMIN', admin);
                    resolve()
                })
                .catch(err => reject(err))
        })
    },
    createAdmin({commit}, data) {
        return new Promise((resolve, reject) => {
            commit('SET_ADMIN_LOADING', true);
            data = {...data};
            // Date retreived is a wharehouse object but we should send only the id using warehouseId key.
            data.warehouseId = data.warehouse?.id ? data.warehouse?.id : data.warehouse;
            delete data.warehouse;
            API.post(listRoutes.admins, {...data})
                .then(res => res.data)
                .then(admin => {
                    commit('SET_ADMIN', admin);
                    resolve(admin)
                })
                .catch(() => reject())
                .finally(() => commit('SET_ADMIN_LOADING', false))
        })
    },
    getAdminRoles({commit}) {
        API.get(listRoutes.roles)
            .then(res => res.data)
            .then(roles => commit('SET_ADMIN_ROLES', roles))
    }
};

const getters = {};

const admin = {state, mutations, actions, getters};

export default admin;