<template>
    <section class="section-table-infos">
        <TextTitle v-if="title" size="s" tag="h2">{{ title }}</TextTitle>
        <table>
            <tbody>
            <Row v-for="(field, i) in validFields" :key="i">
                <TableCell :value="field | fieldLabel | append(' :')"/>
                <TableCell :value="object | fieldValue(field)" :type="field.type" :route="field.route" :object="object"/>
            </Row>
            </tbody>
        </table>
        <slot/>
    </section>
</template>

<script>
import TableCell from '@/components/ui/table/cell/TableCell';
import Row from '@/components/ui/table/row/Row';
import TextTitle from '@/components/ui/text/TextTitle';
export default {
    name: 'SectionTableInfos',
    components: {TextTitle, Row, TableCell},
    props: {
        fields: Array,
        object: [Object, Array],
        title: String
    },
    computed: {
        validFields() {
            if (!this.object) return [];
            return this.fields.filter(field =>  !!field.force || field.type === 'boolean' || this.hasValue(this.object, field))
        }
    },
    methods: {
        hasValue(item, field) {
            if (!field || !item) return false;
            const pathArray = field.key?.split('.');
            if (!pathArray) return false;
            let object = item;
            for (const propertyName of pathArray) {
                if (object === null || object === undefined) return undefined;
                object = object[propertyName];
            }
            return !!object;
        }
    }
}
</script>

<style lang="scss">
.section-table-infos {
    margin: 30px 0;

    .title {
        margin-bottom: 10px;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        max-width: 700px;
    }

    td {
        text-align: left;
        height: 40px;
    }

    .table-cell-boolean {
        margin-left: 0;
        padding: 4px 10px;
    }

    & > .button {
        margin-top: 20px;
    }

    .tag {
        margin: 0;
        font-size: 1.1rem;
    }
}
</style>