const state = {
    filtersSelected: [
        {key: null, operator: null, value: null}
    ]
};

const mutations = {
    SET_FILTERS(state, filters) {
        state.filtersSelected = filters.length ? [...filters] : [{key: null, operator: null, value: null}];
    },
    SET_FILTER(state, data) {
        const {i, field} = data;
        let filters = [...state.filtersSelected];
        if (filters[i]) filters[i] = {...field};
        state.filtersSelected = filters
    },
    ADD_FILTER(state) {
        state.filtersSelected = [...state.filtersSelected, {key: null, operator: null, value: null}]
    },
    DELETE_FILTER(state, i) {
        state.filtersSelected = [...state.filtersSelected.filter((a, b) => b !== i)]
    }
};

const actions = {
    getFiltersFromUrl({commit}, data) {
        const {query, filters} = data;
        let selected = [];
        for (let key in query) {
            const field = {
                key: key.split(':')[0],
                operator: key.split(':')[1],
                value: query[key]
            }
            if (filters.find(f => f.key === field.key) && key.includes(':')) selected.push(field)
        }
        commit('SET_FILTERS', selected)
    },
    updateFilter({commit}, data) {
        commit('SET_FILTER', data)
    },
    resetFilters({commit}) {
        commit('SET_FILTERS', {key: 0, operator: null, value: null})
    },
    addFilter({commit}) {
        commit('ADD_FILTER')
    },
    deleteFilter({commit}, i) {
        commit('DELETE_FILTER', i)
    }
};

const getters = {

};

const filtres = {state, mutations, actions, getters};

export default filtres;