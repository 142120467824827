<template>
    <TemplateClient :subtitle="$t('languages.languagesConfigTitle')" class="client-languages">
        <template v-slot:actions v-if="client">
            <ButtonCustom size="m" icon="stars" color="yellow" @click.native="$refs.popinDefaultLanguage.open()">{{ $t('languages.default.button') }}</ButtonCustom>
            <ButtonCustom size="m" icon="plus" color="black" @click.native="$refs.popinLanguages.open()">{{ $t('languages.addlanguages') }}</ButtonCustom>
        </template>

        <section v-if="clientLocales?.length" class="client-languages__list-locales">
            <ul>
                <li v-for="locale in clientLocales" :key="locale.code">
                    <!-- Drapeau et nom de la langue -->
                    {{ getFlagEmoji(locale.code.replace(/_.*/, '')) }}
                    {{ $t('locales.' + locale.code.replace(/_.*/, '')) || locale.code.replace(/_.*/, '') }}
                    <div class="client-languages__list-actions">
                        <TagItem v-if="locale.isDefault" :text="$t('languages.default.tag')" color="yellow" icon-left="stars"/>
                        <ToggleSwitcher :label="getSwitcherLabel(locale)" :model="isActive(locale)" @change="() => handleToggle(locale)"/>
                        <ButtonCustom color="red" size="s" @click.native="promptDelete(locale)">{{$t('global.delete')}}</ButtonCustom>
                    </div>
                </li>
            </ul>
        </section>
        <PageLoader v-else/>

        <!-- ------------------------ -->
        <!-- Popin d'ajout de langues -->
        <!-- -----------------------  -->
        <Popin
                :title="$t('languages.addlanguages')"
                :subtitle="$t('languages.languagesAvailable')"
                :center="true"
                @close="resetAddChecked"
                ref="popinLanguages">
            <template #content>
                <!-- Loader pendant l'ajout de la langue -->
                <div v-if=" addClicked && isLoading" class="loader-container">
                    <div class="loader"></div>
                    <p>{{ loaderMessages[loaderStep] }}<span class="loading-dots"></span></p>
                </div>

                <!-- Message de succès après l'ajout de la langue -->
                <div v-if="addChecked" class="loader-container">
                    <div class="loader-success"></div>
                    <p>{{ getFlagEmoji(currentAddedLocale.replace(/_.*/, '')) }} {{$t('locales.' + currentAddedLocale.replace(/_.*/, '')) || currentAddedLocale.replace(/_.*/, '') }} {{ $t('languages.addlaguageConfirmation') }}</p>
                </div>
            </template>
            <template #bottom>
                <section class="client-languages__list-locales client-languages__popin-add">
                    <!-- Liste des langues disponibles à ajouter -->
                    <ul v-if="availableLocales.length">
                        <li v-for="locale in availableLocales" :key="locale.id">
                            {{ getFlagEmoji(locale.code.replace(/_.*/, '')) }}
                            {{ $t('locales.' + locale.code.replace(/_.*/, '')) || locale.code.replace(/_.*/, '') }}
                            <ButtonCustom class="button button--green button--s" @click.native="add({ idClient: client.id, locale: locale.code })">
                                <span v-if="!isLoading[locale.code]">{{ $t('global.add') }}</span>
                                <span v-else class="loading-dots"></span>
                            </ButtonCustom>
                        </li>
                    </ul>
                    <p v-else>{{ $t('languages.noLanguagesAvailable') }}</p>
                </section>
            </template>
        </Popin>

        <!-- ---------------------------- -->
        <!-- Popin suppression de langues -->
        <!-- ---------------------------- -->
        <Popin
                :title="$t('languages.confirmDeleteTitle')"
                :center="true"
                ref="popinConfirmDelete">
            <template #content>
                <h3 v-if="localeToDelete">
                    {{ getFlagEmoji(localeToDelete.code.replace(/_.*/, '')) }}
                    {{
                        $t('locales.' + localeToDelete.code.replace(/_.*/, '')) || localeToDelete.code.replace(/_.*/, '')
                    }}
                </h3>
                <p>{{ $t('languages.confirmDeleteText1') }}</p>
                <br>
                <p>{{ $t('languages.confirmDeleteText2') }}</p>
                <br>
            </template>
            <template #bottom>
                <ButtonCustom color="red" @click.native="confirmDelete">{{ $t('global.delete') }}</ButtonCustom>
                <ButtonCustom @click.native="cancelDelete">{{ $t('global.cancel') }}</ButtonCustom>
            </template>
        </Popin>

        <!-- --------------------------------------- -->
        <!-- Popin suppression de langues impossible -->
        <!-- --------------------------------------- -->
        <Popin
                :title="$t('languages.cannotDeleteTitle')"
                :center="true"
                ref="popinCannotDelete">
            <template #content>
                <p>{{ $t('languages.cannotDeleteText') }}</p>
            </template>
            <template #bottom>
                <ButtonCustom color="grey" @click.native="closeCannotDeletePopin">
                    {{$t('global.cancelled')}}
                </ButtonCustom>
                <ButtonCustom @click.native="goToMarket">{{ $t('languages.goMarket') }}</ButtonCustom>
            </template>
        </Popin>

        <Popin ref="popinDefaultLanguage"
               :title="$t('languages.default.button')"
               :subtitle="$t('languages.default.text')"
               :text="$t('languages.default.info')"
               :center="true">
            <template #content>
                <form @submit.prevent="setDefault">
                    <FieldRadio
                            :fields="clientLocales.map(locale => (
                                {
                                    label: $t(`locales.${locale.code.split('_')[0]}`),
                                    value: locale.code,
                                    disabled: !locale.enable
                                }
                            ))"
                            :label="$t('languages.default.tag')"
                            :white-background="false"
                            name="defaultLang"
                            :model="defaultLocale"
                            @change="e => newDefaultLocale = e"/>
                    <ButtonForm :loading="defaultLocaleLoader">{{ $t('global.validate') }}</ButtonForm>
                </form>
            </template>
        </Popin>


    </TemplateClient>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import TemplateClient from '@/components/templates/TemplateClient';
import ButtonCustom from '@/components/ui/button/Button.vue';
import ToggleSwitcher from '@/components/ui/switcher/ToggleSwitcher.vue';
import Popin from '@/components/ui/popin/Popin.vue';
import filters from '@/filters.js';
import TagItem from '@/components/ui/tag/Tag';
import ButtonForm from '@/components/ui/button/ButtonForm';
import FieldRadio from '@/components/ui/form/fields/FieldRadio';
import PageLoader from '@/components/ui/page/PageLoader';

export default {
    name: 'ClientLanguages',
    components: {
        PageLoader,
        FieldRadio,
        ButtonForm,
        TagItem,
        TemplateClient,
        Popin,
        ButtonCustom,
        ToggleSwitcher
    },
    data() {
        return {
            selectedLocale: null,
            loading: false,
            errorMessage: null,
            activeLocales: [],
            localeToDelete: null,
            addClicked: false,
            addChecked: false,
            currentAddedLocale: null,
            isLoading: {},
            loaderStep: 0,
            loaderMessages: [
                this.$t('languages.languagesLoaderText1'),
                this.$t('languages.languagesLoaderText2'),
            ],
            defaultLocaleLoader: false,
            newDefaultLocale: null
        };
    },
    computed: {
        ...mapState(['client', 'allLocales', 'clientLocales']),
        availableLocales() {
            return this.allLocales.filter(locale => !this.clientLocales.some(clientLocale => clientLocale.code === locale.code));
        },
        defaultLocale() {
            return this.clientLocales?.find(c => c.isDefault)?.code
        }
    },
    methods: {
        ...mapActions(['addLocale', 'deleteLocale', 'getAllLocales', 'getClientLocales', 'enableLocale', 'setClientDefaultLanguage']),
        getFlagEmoji(localeCode) {
            if (localeCode === 'en') return '🇬🇧';
            return filters.getFlagEmoji(localeCode);
        },
        async add(locale) {
            this.addClicked = true;
            this.addChecked = false;
            this.$set(this.isLoading, locale.locale, true);
            this.loaderStep = 0;
            try {
                // Simuler la création des traductions
                await this.createTranslations();
                this.loaderStep = 1;

                // Simuler la création des fichiers
                await this.createFiles();

                // Ajouter la locale au client
                await this.addLocale({idClient: this.client.id, locale: locale.locale});

                // Rafraîchir la liste des locales du client depuis le serveur
                this.getClientLocales(this.client.id);
            } catch (error) {
                this.errorMessage = error.message || "Une erreur s'est produite lors de l'  ajout de la langue.";
            } finally {
                this.currentAddedLocale = locale.locale;
                this.addClicked = false;
                this.$set(this.isLoading, locale.locale, false);
                this.addChecked = true;
            }
        },

        // Simuler la création des traductions
        async createTranslations() {
            return new Promise(resolve => setTimeout(resolve, 10000));
        },
        // Simuler la création des fichiers
        async createFiles() {
            return new Promise(resolve => setTimeout(resolve, 20000));
        },
        promptDelete(locale) {
            this.localeToDelete = locale;
            this.$refs.popinConfirmDelete.open();
        },
        confirmDelete() {
            this.delete(this.localeToDelete);
            this.$refs.popinConfirmDelete.close();
        },
        cancelDelete() {
            this.localeToDelete = null;
            this.$refs.popinConfirmDelete.close();
        },
        resetAddChecked() {
            this.addChecked = false;
        },
        delete(locale) {
            this.deleteLocale({idClient: this.client.id, locale})
                    .then(() => {
                        const index = this.clientLocales.findIndex(l => l.code === locale.code);
                        if (index > -1) {
                            this.clientLocales.splice(index, 1);
                        }
                    })
                    .catch(error => {
                        this.$refs.popinCannotDelete.open();
                        this.errorMessage = error.message || "Une erreur s'est produite lors de la suppression de la langue.";
                    });
        },
        closeCannotDeletePopin() {
            this.$refs.popinCannotDelete.close();
        },
        goToMarket() {
            const countryCode = this.localeToDelete.code.split('_')[1];
            const country = this.$t(`countries_code.${countryCode}`);
            window.location.href = `/admin/config/sites/${this.client.id}/markets/${country}/region&locals`;
        },
        // langues active/inactive
        isActive(locale) {
            return locale.enable;
        },
        handleToggle(locale) {
            const enable = !locale.enable; // Inverse l'état d'activation actuel
            this.enableLocale({idClient: this.client.id, locale: locale.code, enable})
                    .then(() => {
                        console.log(locale.code + " est " + (enable ? "active" : "inactive"));
                        locale.enable = enable; // Met à jour l'état d'activation dans 'clientLocales'
                    })
                    .catch(error => {
                        this.errorMessage = error.message || "Une erreur s'est produite lors de la modification de l'état d'activation de la langue.";
                    });
        },
        getSwitcherLabel(locale) {
            return this.isActive(locale) ? this.$t('global.isActive') : this.$t('global.noActive');
        },
        setDefault() {
            this.defaultLocaleLoader = true;
            this.setClientDefaultLanguage({
                idClient: this.client.id,
                localeCode: this.newDefaultLocale
            })
                    .then(() => this.$refs.popinDefaultLanguage.close())
                    .finally(() => this.defaultLocaleLoader = false)
        }
    },
    watch: {
        client(newClient, oldClient) {
            if (newClient && (!oldClient || newClient.id !== oldClient.id)) {
                this.getClientLocales(newClient.id);
            }
        }
    },
    created() {
        this.getAllLocales();
        if (this.client) {
            this.getClientLocales(this.client.id)
                    .then(() => {
                        this.activeLocales = this.clientLocales.map(locale => locale.code);
                    });
        }
    }
}
</script>

<style lang="scss">
.client-languages {
    &__list-actions {
        display: flex;
        align-items: center;
        gap: 25px;
    }

    &__popin-add {
        ul {
            padding: 16px;
        }
    }

    &__list-locales {
        ul {
            list-style-type: none;
            background-color: var(--color-bg-light);
        }

        li {
            margin-bottom: 10px;
            padding: 16px;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-width: 300px;
        }
    }

    h3 {
        margin: 16px 0;
        margin-top: 32px;
    }

    .active-color {
        color: var(--color-green);
    }

    .inactive-color {
        color: var(--color-text-grey);
    }

    .loader-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 20px;
    }

    .loader {
        border: 4px solid var(--color-bg-grey);
        border-top: 4px solid var(--color-blue2);
        border-radius: 50%;
        width: 40px;
        height: 40px;
        margin: 0 auto;
        margin-bottom: 16px;
        animation: spin 1s linear infinite;
    }

    .loader-success {
        border: 4px solid var(--color-green);
        border-radius: 50%;
        width: 40px;
        height: 40px;
        margin-bottom: 16px;
        position: relative;
    }

    .loader-success::after {
        content: "✔";
        font-size: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: var(--color-green);
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes ellipsis {
        to {
            content: ".";
        }
        33% {
            content: "..";
        }
        67% {
            content: "...";
        }
    }

    .loading-dots:before {
        content: ".";
        animation: ellipsis 1s steps(3, end) infinite;
    }
}

</style>