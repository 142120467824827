import monitoringTestsPage from '@/components/pages/admin/monitoring/tests/MonitoringTests';
import monitoringTestPage from '@/components/pages/admin/monitoring/tests/MonitoringTest';
import monitoringDatasPage from '@/components/pages/admin/monitoring/datas/MonitoringDatas';
import monitoringDataPage from '@/components/pages/admin/monitoring/datas/MonitoringData';

const monitoringRoutes = [
    {
        path: '/admin/monitoring',
        name: 'monitoring',
        meta: {
            title: 'monitoring',
            icon: 'test',
            roles: ['ROLE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/monitoring/monitoring-tests',
        name: 'monitoringTests',
        component: monitoringTestsPage,
        meta: {
            title: 'test',
            roles: ['ROLE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/monitoring/monitoring-test/:id',
        name: 'test',
        component: monitoringTestPage,
        meta: {
            title: 'test',
            roles: ['ROLE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/monitoring/monitoring-datas',
        name: 'monitoringDatas',
        component: monitoringDatasPage,
        meta: {
            title: 'data',
            roles: ['ROLE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/monitoring/monitoring-data/:id',
        name: 'data',
        component: monitoringDataPage,
        meta: {
            title: 'data',
            roles: ['ROLE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
];

export default monitoringRoutes;