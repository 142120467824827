<template>
    <TemplateTable
            :title="$t('menu.stock-good')"
            name="warehouseStocks"
            :url="$listRoutes.warehouseStocks.replace(':stage', 'stock')"
            :table="warehouseStocks"
            :filters="warehouseStocksFilters"
            :fields="fields"
    />
</template>

<script>
import { mapState } from 'vuex';
import TemplateTable from '@/components/templates/TemplateTable';
export default {
    name: 'WarehouseStocks',
    components: {TemplateTable},
    data() {
        return {
            fields: [
                {key: 'id', type: 'eye', route: 'resale', size: 'xs'},
                {key: 'client', type: 'client', size: 'l'},
                //{key: 'user', type: 'userEmail'},
                {key: 'shopifyId', type: 'shopifyProduct', size: 'm'},
                //{key: 'createdAt', type: 'date'},
                {key: 'logisticsInformations.warehouseStockDate', type: 'date'},
                {key: 'logisticianCondition', type: 'tagType'},
                {key: 'tradeinPrice', type: 'formatPrice'},
                {key: 'shopifyPublished', type: 'boolean'},
                {key: 'warehouse.name', translation: 'global.warehouse'},
                {key: 'slot', translation: 'global.location'},
                {key: 'country'},
            ]
        }
    },
    computed: {
        ...mapState(['warehouseStocks', 'warehouseStocksFilters'])
    }
}
</script>