<template>
    <section>
        <PageBack/>
        <PageHeader :title="$t('recom_promocode-edit.title')">
            <template v-slot:actions>
                <Button size="m" color="red" @click.native.prevent="deletePromoCode">{{ $t('global.delete') }}</Button>
            </template>
        </PageHeader>
        <Form v-if="currentPromotionCode"
                :init-on-success="true"
                :fields="fields"
                ref="form"
                :action="editRecomPromotionCode"
                :model="currentPromotionCode"
        />
    </section>
</template>

<script>
import PageHeader from "@/components/ui/page/PageHeader";
import Form from "@/components/ui/form/Form";
import PageBack from "@/components/ui/page/PageBack";
import Button from "@/components/ui/button/Button";
import { mapActions, mapGetters, mapState } from "vuex";
import moment from "moment";

export default {
    name: "RecomPromoCodeEdit",
    components: {PageBack, Form, PageHeader, Button},
    data() {
        return {
            user: null,
            loading: false,
            shippingLabel: null,
            fields: [
                {name: "client", type: "text", disabled: true},
                {name: "code", type: "text", required: true},
                {name: "type", type: "select", required: true, options: [
                    {name: 'Pourcentage', value: 'percent'},
                    {name: 'Valeur', value: 'flat'}
                ]},
                {name: "appliedTo", type: "select", required: true, options: [
                    {name: 'Prix total', value: 'total'},
                    {name: 'Frai de livraison', value: 'shipping'},
                ]},
                {name: "minimumAmountItemsTotal", type: "number", required: true},
                {name: "value", type: "number", required: true},
                {name: "startedAt", type: "customDate", required: true, displayHours: true},
                {name: "endedAt", type: "customDate", required: true, displayHours: true},
            ],
        };
    },
    computed: {
        ...mapGetters(["userClients"]),
        ...mapState(["clients", "promotionCode"]),
        currentPromotionCode() {
            return {
                appliedTo: this.promotionCode?.appliedTo,
                client: this.promotionCode?.brand?.name,
                code: this.promotionCode?.code,
                startedAt: moment.utc(this.promotionCode?.createdAt).local().format('YYYY-MM-DDTHH:mm'),
                endedAt: moment.utc(this.promotionCode?.endedAt).local().format('YYYY-MM-DDTHH:mm'),
                minimumAmountItemsTotal: this.promotionCode?.minimumAmountItemsTotal,
                type: this.promotionCode?.type,
                value: this.promotionCode?.type === 'flat' ? this.promotionCode?.value / 100 : this.promotionCode?.value,
                currentPromotionCodeId: this.$route.params.id,
            }
        },

    },
    methods: {
        ...mapActions(["editRecomPromotionCode", "getRecomPromotionCode", "removeRecomPromotionCode"]),
        updateClients() {
            let fieldIndex = this.fields.findIndex(field => field.name === 'client');
            if (fieldIndex !== -1) {
                if (this.userClients && this.userClients.length) {
                    this.fields[fieldIndex].options = this.userClients;
                } else {
                    this.fields[fieldIndex].options = this.clients;
                }
            }
        },
        deletePromoCode() {
            this.removeRecomPromotionCode(this.$route.params.id);
            this.$router.push({name: 'recom-promo-codes'})
        }
    },
    watch: {
        userClients() {
            this.updateClients();
        },
        clients() {
            this.updateClients();
        },
    },
    mounted() {
        this.updateClients();
        this.getRecomPromotionCode(this.$route.params.id);
    },
};
</script>

<style lang="scss">
.resale-shipping-create {
    display: flex;
    align-items: flex-start;

    & > form {
        width: 100%;
        max-width: 600px;
    }

    .grid-card {
        flex-shrink: 0;
        margin-left: 30px;
        position: sticky;
        top: 15px;
        margin-top: 20px;
    }

    .user-card {
        pointer-events: none;
        user-select: text;
    }

    .grid-content {
        & > svg {
            width: 80px;
            margin: auto;
            display: block;
            --svg-color: var(--color-text-grey);
        }
    }
}
</style>
