<template>
    <TemplateTable
            :title="$t('menu.data')"
            name="monitoringDatas"
            :url="$listRoutes.monitoringDatas"
            :table="monitoringDatas"
            :filters="monitoringDatasFilters"
            :fields="fields"
    />
</template>

<script>
import { mapState } from 'vuex';
import TemplateTable from '@/components/templates/TemplateTable';

export default {
    name: 'monitoringDatasPage',
    components: { TemplateTable },
    data() {
        return {
            fields: [
                {key: 'id', type: 'eye', route: 'data', size: 'xs'},
                {key: 'name', type: 'text', translationKey:'name', size: 'xl'},
                {key: 'success', type: 'boolean', translationKey:'status', text:['OK', 'KO'], size: 'xs'}
            ]
        }
    },
    computed: {
        ...mapState(['monitoringDatas', 'monitoringDatasFilters'])
    }
}
</script>

<style lang="scss">

</style>