<template>
    <GridCard v-if="currentLocale">
        <TextTitle size="s">{{ localeConverted }}</TextTitle>
        <Field class="red" :model="titleModel" :border="true" :label="titleLabel" type="text"  @change="title = $event"/>
        <Field :model="descriptionModel" :border="true" :label="descriptionLabel"  type="text"  @change="description = $event"/>
    </GridCard>
</template>

<script>
import GridCard from '@/components/ui/grid/GridCard';
import TextTitle from '@/components/ui/text/TextTitle.vue';
import Field from '@/components/ui/form/fields/Field.vue';
export default {
    name: 'CollectionsIdentificationFields',
    components: {
        GridCard,
        TextTitle,
        Field
    },
    props: {
        localeToSend:String,
        model:Object
    },
    data() {
        return {
            title: this.model[this.localeToSend]?.title,
            description: this.model[this.localeToSend]?.description,
            isMounted: false,
        }
    },
    watch: {
        title_and_description:'emitData'
    },
    computed: {
        titleLabel(){
            return this.$t('collections.collections_title')
        },
        titleModel(){
            return this.model && this.currentLocale ? this.model[this.currentLocale]?.title : ''
        },
        descriptionLabel(){
            return this.$t('collections.collections_description')
        },
        descriptionModel(){
            return this.model && this.currentLocale ? this.model[this.currentLocale]?.description : ''
        },
        title_and_description() {
            let result = {};
            
            result.title = this.title
            result.description = this.description

            return result;
        },
        currentLocale(){
            return this.localeToSend
        },
        localeConverted(){
            return this.$t(`locales.${this.currentLocale.split('_')[0]}`)
        }
    },
    methods: {
        emitData(){
            let payload = {
                [this.currentLocale]: this.title_and_description
            };
            this.$emit('change', {payload:payload, type:"identification"});
        }
    },
    updated () {
        this.title = this.titleModel;
        this.description = this.descriptionModel;
    },
}
</script>

<style>
</style>