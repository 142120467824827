import API from '@/api/api-admin';
import listRoutes from '@/api/list-routes';
import router from '@/router';


const state = {
    configMarkets: {
        items: [],
        pagination: { pageCount: 0, currentPage: 1 }
    },
    configMarketsLoading: null,
    selectedMarket: null,
    availableMarkets: [],
    marketLocales: [],
    
}

const mutations = {
    SET_MARKET_CONFIGS(state, data) {
        if (data && data.items && data.items.length > 0) {
            state.configMarkets = data;
        }
    },
    SET_MARKET_CONFIGS_LOADING(state, data) {
        state.configMarketsLoading = data;
    },
    SET_SELECTED_MARKET(state, market) {
        state.selectedMarket = market;
    },
    SET_AVAILABLE_MARKETS(state, markets) {
        state.availableMarkets = markets;
    },
    SET_MARKET_LOCALES(state, locales) {
        state.marketLocales = locales;
    },
    SET_CURRENCY_ID(state, currencyId) {
        if (!state.selectedMarket) {
            state.selectedMarket = {};
        }
        state.selectedMarket.currency = {id: currencyId};
    },
    ADD_MARKET(state, market) {
        state.configMarkets.items.push(market);
    },
    REMOVE_MARKET(state, marketId) {
        const index = state.configMarkets.items.findIndex(market => market.id === marketId);
        if (index !== -1) {
            state.configMarkets.items.splice(index, 1);
        }
    },
    SET_MARKET_ENABLED_AT(state, data) {
        if (state.configMarkets?.items?.length) {
            const items = [...state.configMarkets.items];
            const {marketId, value} = data;
            items.forEach(market => {
                if (market.id === marketId) {
                    if (value) {
                        const dateActuelle = new Date();
                        const annee = dateActuelle.getFullYear();
                        const mois = String(dateActuelle.getMonth() + 1).padStart(2, '0');
                        const jour = String(dateActuelle.getDate()).padStart(2, '0');
                        const heures = String(dateActuelle.getHours()).padStart(2, '0');
                        const minutes = String(dateActuelle.getMinutes()).padStart(2, '0');
                        const secondes = String(dateActuelle.getSeconds()).padStart(2, '0');
                        const offset = dateActuelle.getTimezoneOffset() / 60;
                        market.enabledAt = `${annee}-${mois}-${jour}T${heures}:${minutes}:${secondes}${offset >= 0 ? '+' : '-'}${String(Math.abs(offset)).padStart(2, '0')}:00`
                    } else {
                        market.enabledAt = null
                    }
                }
            });
            state.configMarkets.items = items
        }
    },
    SET_MARKET(state, market) {
        if (state.configMarkets?.items?.length) {
            const items = [...state.configMarkets.items];
            items.forEach(item => {
                if (item.id === market.id) {
                   item = market
                }
            });
            state.configMarkets.items = items
        }
    }
}

const actions = {
    getMarketsConfigs({ commit }, { id, page = 1 }) {
        commit('SET_MARKET_CONFIGS_LOADING', true);
        commit('SET_MARKET_CONFIGS', null);
    
        return API.get(`${listRoutes.websiteMarkets.replace(':id', id)}&page=${page}`)
            .then(response => {
                const data = response.data;
                commit('SET_MARKET_CONFIGS', data);
            })
            .catch(error => {
                console.error('Erreur lors de l\'appel à l\'API :', error);
            })
            .finally(() => {
                commit('SET_MARKET_CONFIGS_LOADING', false);
            });
    },
    

    getAvailableMarkets({commit}, { id }) {
        API.get(listRoutes.websiteMarketOrderCountries.replace(':id', id))
            .then(response => {
                commit('SET_AVAILABLE_MARKETS', response.data);
            })
            .catch(error => {
                console.error("Erreur lors de la récupération des marchés disponibles:", error);
            });
    },

    fetchMarketLocales({commit}) {
        API.get(listRoutes.websiteMarketLocales)
            .then(response => {
                commit('SET_MARKET_LOCALES', response.data);
            })
            .catch(error => {
                console.error("Erreur lors de la récupération des locales de marché:", error);
            });
    },

    fetchCurrencyId({commit}) {
        API.get(listRoutes.websiteMarketCurrencies)
            .then(response => {
                if (response && response.data && response.data.length > 0) {
                    const currencyId = response.data[0].id;
                    commit('SET_CURRENCY_ID', currencyId);
                }
            })
            .catch(error => {
                console.error("Erreur lors de la récupération de l'ID de la monnaie:", error);
            });
    },

    addMarketStore({commit}, marketData) {
        API.post(listRoutes.websiteMarketsAll, marketData)
            .then(response => {
                commit('ADD_MARKET', response.data);
                router.push({name: 'config-website-markets'});
            })
            .catch(error => {
                console.error('Erreur lors de l\'ajout du marché :', error);
            });
    },


    updateMarketStore({commit}, marketData) {
        API.put(listRoutes.websiteMarketsUpdate.replace(':id', marketData.id), marketData)
            .then(response => {
                commit('SET_SELECTED_MARKET', response.data);
            })
            .catch(error => {
                console.error(error);
            });
    },
    deleteMarketStore({commit}, marketId) {

        API.delete(listRoutes.websiteMarketsUpdate.replace(':id', marketId))
            .then(() => {
                commit('REMOVE_MARKET', marketId);
            })
            .catch(error => {
                console.error('Error while deleting market with ID:', marketId, error);
            });
    },
    setSelectedMarket({state, dispatch}, marketId) {
        return new Promise((resolve, reject) => {
            let market = state.configMarkets?.items?.find(x => x.id == marketId) || {};
            if (!market) reject();
            const playload = {
                name: market.name,
                client: market.client.id,
                isDefault: 1
            }
            API.put(listRoutes.websiteMarketsUpdate.replace(':id', marketId), {...playload})
                .then(() => {
                    dispatch('getMarketsConfigs', market.client.id)
                    resolve()
                })
                .catch(err => reject(err))
        })
    },
    setMarketEnabledAt({commit}, data) {
        const {marketId, value} = data;
        commit('SET_MARKET_ENABLED_AT', {marketId, value})
        API.put(listRoutes.websiteMarketsUpdate.replace(':id', marketId), {
            enable: value
        })
            .then(market => commit('SET_MARKET', market))
            .catch(err => commit('SET_NOTIFICATION_MESSAGE', err.message || err))
    }
}

const getters = {
    getMarkets: state => state.configMarkets,
    getSelectedMarket: state => {
        return state.selectedMarket;
    },
}

const markets = {
    state,
    mutations,
    actions,
    getters
}

export default markets