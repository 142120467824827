<template>
    <div class="collections-identification__header">
        <TextTitle size="s" weight="bold">{{$t('collections.collections_identification')}}</TextTitle>
        <div class="collections-identification__edit">
            <div v-for="locale in catalogueLocales" :key="locale">
                <TextTitle :class="!!model[locale]?.title  ? '' :'bew'" size="l" weight="bold">{{ locale | getFlagEmoji }}</TextTitle>
            </div>
            <Button v-if="catalogueLocales?.length > 1" color="blue" size="s" @click.native="openModal">{{ $t('collections.translate')}}</Button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import TextTitle from '@/components/ui/text/TextTitle.vue';
import Button from '@/components/ui/button/Button.vue';
export default {
    name : 'CollectionsIdentificationHeader',
    components: {
        TextTitle,
        Button
    },
    props: {
        model: Object
    },
    methods: {
        ...mapActions(['getCatalogueDefaultLocale','getCatalogueLocales']),
        getLocales(){
            this.getCatalogueDefaultLocale(this.$route.params.id);
            this.getCatalogueLocales(this.$route.params.id);
        },
        openModal(){
            this.$emit('open-modal')
        },
    },
    computed: {
        ...mapState(['catalogueLocales']),
    },
    created () {
        this.getLocales()
    },
}
</script>

<style lang="scss" scoped>
.collections-identification {
    &__header {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 20px;
    }
    &__edit{
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: 10px;
    }
    @media screen and (max-width: 550px) {
        &__header {
            flex-direction: column;
        }
        &__edit {
            margin-left: initial;
        }
    }
}
.bew {
    filter: grayscale(100);
}
</style>