<template>
    <div class="collections-datasource-conditions"> 
        
        <CollectionsConditionsCatalogueData 
            v-if="whichConditionsToShow === 'catalog_data'"
        />
        <CollectionsConditionsCatalogueManualTags
            v-if="whichConditionsToShow === 'manual_tags'"
         />
        
        <div v-if="whichConditionsToShow === 'product_lifecyle'" style="background: goldenrod;">
            LES CONDITIONS POUR product_lifecyle
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import CollectionsConditionsCatalogueData from '@/components/ui/collections/CollectionsConditionsCatalogueData.vue';
import CollectionsConditionsCatalogueManualTags from '@/components/ui/collections/CollectionsConditionsCatalogueManualTags.vue';
export default {
    name: 'CollectionsDataSourcesConditions',
    components: {
        CollectionsConditionsCatalogueData,
        CollectionsConditionsCatalogueManualTags
    },
    computed: {
        ...mapState(['catalogueCollection']),
        whichConditionsToShow(){
            return this.catalogueCollection
                ? this.catalogueCollection?.parameters?.source
                : ''
        }
    },

}
</script>

<style lang="sass" scoped>

</style>