<template>
    <TemplateDetails :data="configMarkets" :loading="configMarketsLoading" :get-function="getMarketsConfigs">

        <PageBack/>
       
            <PageHeader :title="shouldAdd ? $t('market.addMarket') : $t('market.regionAndLanguages') + ' ' + marketName">
                <template v-slot:actions>
                    <ButtonCustom v-if="!shouldAdd" :disabled="!isLocaleModified" @click.native="updateMarket" size="m" color="black" icon="edit">{{ $t('market.btnSave') }}</ButtonCustom>
                </template>
            </PageHeader> 
            <GridContainer class="market">
                <GridCard>
                    <GridContent>
                        <GridHeader :title="$t('market.region')"/>
                        <TextSimple>{{ $t('market.SelectRegionDescription') }}</TextSimple>
                        <br>
                        <FieldSelect
                            :multiple="false"
                            :border="true"
                            :selected="modelMarketName"
                            :options="marketOptions"
                            :label="$t('market.region')"
                            @input="onCountryInput"
                            :disabled="!shouldAdd"
                        />
                    </GridContent>

                </GridCard>
                
                <GridCard v-if="selectedMarket">
                    <GridContent>
                        <GridHeader :title="$t('market.marketsDefaultLocale')"/>
                        <TextSimple>{{ $t('market.marketsDefaultLocaleDesicription') }}</TextSimple>
                        <br>
                        <FieldSelect
                            :multiple="false"
                            :border="true"
                            :selected="selectedDefaultLocaleCode+''"
                            :options="localeOptions"
                            :label="$t('global.defaultLocale')"
                            @input="e => selectedDefaultLocaleCode = e"
                        />
                    </GridContent>
                </GridCard>
                
                <ButtonCustom v-if="shouldAdd" @click.native="addMarket" size="m" color="black" icon="plus">{{ $t('market.addMarket') }}</ButtonCustom>

            </GridContainer>

            <Popin
                :title="$t('market.popinNoSaveTitle')" 
                :subtitle="$t('market.popinNoSave')"
                :center="true"
                ref="popinOut">
                <template #bottom>
                    <ButtonCustom color ="red" @click.native.prevent="confirmLeave()">{{ $t('market.popinNoSaveBtnQuit') }}</ButtonCustom>
                    <ButtonCustom @click.native="updateMarket">{{ $t('market.btnSave') }}</ButtonCustom>
                </template>
            </Popin>

        </TemplateDetails>
</template>

<script>

import { mapActions, mapState,mapGetters } from 'vuex';
import PageHeader from '@/components/ui/page/PageHeader';
import ButtonCustom from '@/components/ui/button/Button';
import GridContainer from '@/components/ui/grid/GridContainer.vue';
import PageBack from '@/components/ui/page/PageBack';
import GridCard from '@/components/ui/grid/GridCard.vue';
import GridHeader from '@/components/ui/grid/GridHeader.vue';
import GridContent from '@/components/ui/grid/GridContent.vue';
import TextSimple from '@/components/ui/text/TextSimple.vue';
import FieldSelect from '@/components/ui/form/fields/FieldSelect.vue';
import TemplateDetails from '@/components/templates/TemplateDetails.vue';
import Popin from '@/components/ui/popin/Popin.vue';


export default {
    name: 'ConfigWebsiteMarketRegions',
    components: {
        Popin,TemplateDetails, GridCard,GridHeader,GridContent,FieldSelect,TextSimple, PageBack,PageHeader,ButtonCustom,GridContainer
    },
    data() {
        return {
            mode: this.$route.params.mode,
            selectedDefaultLocaleCode: [],
            selectedMarket: null,
            modelMarketName: null,
            initialDefaultLocaleCode: null,
            resolveNavigation: null,
            popinIsOpen: false,
            isDefaultMarket: false,
        };
    },
    computed: {
        ...mapState(['configMarkets','configMarketsLoading','availableMarkets']),
        ...mapGetters(['getSelectedMarket']),

        id() {
            return this.$route.params.id
        },
        client() {
            return this.$store.getters.getClientById(this.id)
        },
        marketName(){
            return this.$route.params.marketName
        },
        marketItems() {
            return this.configMarkets ? this.configMarkets.items : [];
        },
        marketOptions() {
            let existingMarkets = new Set(this.marketItems.map(x => x.name));
            
            if (this.shouldAdd) {
                return this.availableMarkets
                    .filter(x => !existingMarkets.has(x.name))
                    .map(x => ({
                        id: x.name,
                        label: `${this.$options.filters.getFlagEmoji(x.code)} ${x.name}`
                    }));
            } else {
                return this.marketItems.map(x => ({
                    id: x.name,
                    label: `${x.countries[0].country.flag} ${this.$t(`countries_code.${x.countries[0].country.code}`)}`
                }));
            }
        },

        localeOptions() {
            let locales = [];

            if (this.shouldAdd) {
                locales = this.$store.state.marketLocales;
            } else if (this.selectedMarket && this.selectedMarket.locales) {
                locales = this.selectedMarket.locales;
            }

            locales = locales.filter(locale => locale.code !== 'nl_BE');
            
            return locales.map(x => ({
                id: x.id + '',
                label: `${this.$options.filters.getFlagEmoji(x.code.split('_')[1])} ${this.$t(`locales.${x.code.split('_')[0]}`)}`
            }));
        },

        shouldAdd(){
            return typeof this.marketName == "undefined"
        },
        isLocaleModified() {
            return this.selectedDefaultLocaleCode !== this.initialDefaultLocaleCode;
        },
    },
    methods: {
        ...mapActions(['getMarketsConfigs','getAvailableMarkets','fetchCurrencyId', 'fetchMarketLocales','setNotificationMessage','updateMarketStore','addMarketStore']),
        
        generateMarketData() {
            return {
                client: this.client.id,
                defaultLocale: Number(this.selectedDefaultLocaleCode),
                currency: this.shouldAdd ? this.$store.state.selectedMarket.currency.id : this.selectedMarket.currency.id,
                country: this.shouldAdd ? this.selectedMarket.id : this.selectedMarket.country.id,
                name: this.modelMarketName,
                isDefault: this.isDefaultMarket
            };
        },
        updateMarket() {    
            const marketData = this.generateMarketData();
            marketData.id = this.selectedMarket.id; 
            this.updateMarketStore(marketData);
            this.selectedDefaultLocaleCode = this.initialDefaultLocaleCode;
            this.setNotificationMessage(this.$t('market.updateMessage'));
            if(this.popinIsOpen){
                this.$refs.popinOut.close();
                this.popinIsOpen = false;
                if (this.resolveNavigation) {
                    this.resolveNavigation();
                }
            }
        },
    
        addMarket() {
            const marketData = this.generateMarketData();
            this.addMarketStore(marketData);
        },
       
        onCountryInput(e){
            if( this.shouldAdd ){
                this.modelMarketName = e;
                this.selectedMarket = this.availableMarkets.find(market => market.name === this.modelMarketName);
            }
        },
        confirmLeave() {
            this.$refs.popinOut.close();
            if (this.resolveNavigation) {
                this.resolveNavigation();
            }
        },
    },
    watch: {
        configMarkets() {
            if (!this.configMarkets) {
                return;
            }

            if (!this.shouldAdd) {
                this.selectedMarket = this.marketItems.find(market => market.name === this.marketName);
               
                if (this.selectedMarket) {
                    this.selectedDefaultLocaleCode = this.selectedMarket.defaultLocale.id;
                    this.initialDefaultLocaleCode = this.selectedDefaultLocaleCode;
                }
            }
        },
    },
    created() {
        this.modelMarketName = this.marketName;
        this.getMarketsConfigs({id: this.id});

        if (this.shouldAdd) {
            this.fetchCurrencyId();
            this.getAvailableMarkets(this.client);
            this.fetchMarketLocales();
        }
    },
    beforeRouteLeave(to, from, next) {
        if (this.isLocaleModified && !this.shouldAdd) {
            this.$refs.popinOut.open();
            this.popinIsOpen = true;
            next(false);
            new Promise(resolve => {
                this.resolveNavigation = resolve;
            }).then(() => {
                next();
            });
        } else {
            next();
        }
    }

}
</script>

<style lang="scss">

.market{

    .field-select {
        .field-select__label{
            background: #fff;
        }
    }
    button{
        margin: 3.6rem auto;
    }

}
    
</style>
