<template>
    <TemplateDashboard
            :client-is-required="true"
            :route="$listRoutes.dashboardPerformances"
            :filters-route="$listRoutes.dashboardPerformancesFilters"
            :title="$t('menu.performances')"/>
</template>

<script>
import TemplateDashboard from '@/components/templates/TemplateDashboard';

export default {
    name: 'DashboardPerformances',
    components: {TemplateDashboard},
}
</script>
