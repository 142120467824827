<template>
    <span>
        <span v-if="!client || !isAllowed">{{ name }}</span>
        <router-link v-else :to="{name: 'client', params: {id: id}}"><span>{{ name }}</span></router-link>
    </span>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'LinkClient',
    props: {
        name: String
    },
    data() {
        return {
            routes: this.$router?.options?.routes || [],
        }
    },
    computed: {
        ...mapState(['user', 'temporaryUser']),
        client() {
            return this.$store.getters.getClientByName(this.name)
        },
        id() {
            return this.client ? this.client.id: null
        },
        isAllowed() {
            const route = this.routes.find(r => r.name === 'config-client');
            if (route) {
                return !!this[this.temporaryUser ? 'temporaryUser' : 'user'].roles.find(r => route.meta.roles.includes(r))
            }
            return true
        }
    }
}
</script>