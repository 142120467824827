<template>
    <div class="pagination">
        <div class="pagination__top">
            <select v-model="limit" v-if="!empty">
                <option v-for="(option, i) in options" :key="i" :value="option">{{ option }}</option>
            </select>
            <div class="pagination__resume">
                <TextSimple color="grey" size="xs">{{ firstItemNumber }} - {{ lastItemNumber }} {{ $t('pagination.on') }} {{ totalCount }}</TextSimple>
            </div>
        </div>

        <div class="pagination__pages" v-if="!empty">
            <button @click.prevent="currentPage -= 1" :disabled="currentPage === first" data-testid="pagination-arrow-prev">
                <SvgIcon name="chevron"/>
            </button>
            <ol>
                <li :class="{'is-active': currentPage === first}" @click.prevent="currentPage = first">{{ first }}</li>
                <li v-if="hasPagesBefore" class="pagination__dots">...</li>
                <li v-for="page in pages" :class="{'is-active': currentPage === page}" :key="page" @click.prevent="currentPage = page" :data-testid="`pagination-page-${page}`">{{ page }}</li>
                <li v-if="hasPagesAfter" class="pagination__dots">...</li>
                <li v-if="last && last !== first" :class="{'is-active': currentPage === last}" @click.prevent="currentPage = last">{{ last }}</li>
            </ol>
            <button @click.prevent="currentPage += 1" :disabled="currentPage === last" data-testid="pagination-arrow-next">
                <SvgIcon name="chevron"/>
            </button>
        </div>

    </div>
</template>

<script>
import SvgIcon from '@/components/ui/svg/SvgIcon';
import TextSimple from '@/components/ui/text/TextSimple';
export default {
    name: 'TablePagination',
    components: {TextSimple, SvgIcon},
    props: {
        pagination: Object,
        defaultValue: Number,
    },
    data() {
        return {
            limit: Number(this.$route.query.limit || 10),
            currentPage: this.pagination.current || this.$route.query.page || 1,
        }
    },
    computed: {
        options() {
            let options = [10, 25, 50, 100];
            if (this.defaultValue && !options.find(x => x === this.defaultValue)) {
                options.push(this.defaultValue);
                options = options.sort((a,b) => a - b)
            }
            return options;
        },
        first() {
            return this.pagination.first
        },
        last() {
            return this.pagination.last
        },
        empty() {
            return this.pagination.lastItemNumber === 0
        },
        pages() {
            let pages = [this.currentPage - 1, this.currentPage, this.currentPage + 1];
            if (this.currentPage === this.first) pages.push(this.currentPage + 2);
            if (this.currentPage === this.last) pages.unshift(this.currentPage - 2);
            pages = pages.filter(p => p > this.first && p < this.last);
            return pages
        },
        firstItemNumber() {
            return this.pagination.firstItemNumber
        },
        lastItemNumber() {
            return this.pagination.lastItemNumber
        },
        totalCount() {
            return this.pagination.totalCount
        },
        hasPagesBefore() {
            if (!this.pages || !this.pages.length || this.pages.length === 1) return false;
            const first = this.pages[0];
            return !!(first && first > (this.first + 1));
        },
        hasPagesAfter() {
            if (!this.pages || !this.pages.length || this.pages.length === 1) return false;
            const last = this.pages[this.pages.length - 1];
            return !!(last && last < (this.last - 1));
        }
    },
    watch: {
        limit(i) {
            this.$router.push({query: {...this.$route.query, limit: i}})
        },
        currentPage(i) {
            this.$router.push({query: {...this.$route.query, page: i}})
        }
    },
    mounted() {
        if (this.defaultValue && this.limit === 10) {
            this.limit = this.defaultValue
        }
    }
}
</script>

<style lang="scss">
.pagination {
    margin-top: 15px;
    user-select: none;

    &__pages {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    ol {
        display: flex;
        align-items: center;
        font-size: 1.2rem;
    }

    li, button {
        min-width: 30px;
        min-height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 6px;
        border-radius: 20px;
        cursor: pointer;
    }

    li {
        transition: color ease-out .3s, background-color ease-out .3s;

        &.is-active {
            background-color: #fff;
            border: var(--border-1);
        }

        @media screen and (min-width: 1025px) {
            &:not(.is-active):hover {
                color: var(--color-text-grey);
            }
        }
    }

    &__dots {
        pointer-events: none;
    }

    button {
        padding: 0;

        &:disabled {
            pointer-events: none;
            opacity: 0;
        }

        &:first-of-type {
            transform: rotate(90deg);
        }

        &:last-of-type {
            transform: rotate(-90deg);
        }

        svg {
            width: 15px;
        }
    }

    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    select {
        border: var(--border-1);
        background: #fff;
        font-size: 1.2rem;
        padding: 4px 5px;
        border-radius: var(--border-radius-1);
        cursor: pointer;
        transition: border ease .3s;

        @media screen and (min-width: 1025px) {
            &:hover {
                border: var(--border-2);
            }
        }
    }

    &__resume {
        margin-left: auto;
    }
}
</style>