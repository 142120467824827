<template>
    <thead class="table-head">
    <tr v-if="fields">
        <TheadTh v-for="(field, i) in fields" :key="`${i}+${field.key}+${field.type}`" :field="field"/>
        <th class="table-head__actions" v-if="actions"></th>
    </tr>
    <slot/>
    </thead>
</template>

<script>
import TheadTh from '@/components/ui/table/thead/TheadTh';
export default {
    name: 'TableThead',
    components: {TheadTh},
    props: {
        fields: Array,
        actions: Boolean
    }
}
</script>

<style lang="scss">
.table-head {
    tr {
        border-bottom: solid 2px var(--color-bg-light);
    }

    td, th {
        background-color: var(--color-bg-grey);
        padding: 13px 5px;
        text-align: center;
        font-size: 1.1rem;
        color: var(--color-text-grey);
        font-weight: 100;
        page-break-inside: avoid;

        @media screen and (max-width: 1200px) {
            white-space: nowrap;
        }

        &:first-of-type {
            border-top-left-radius: var(--border-radius-2);
            border-bottom-left-radius: var(--border-radius-2);
            text-align: left;
            padding-left: 10px;
        }

        &:last-of-type {
            border-top-right-radius: var(--border-radius-2);
            border-bottom-right-radius: var(--border-radius-2);
            padding-right: 10px;
        }
    }

    &__actions {
        @media screen and (max-width: 1024px) {
            display: none;
        }
    }
}
</style>