<template>
    <TemplateTable
            :title="$t('menu.returns-treated')"
            :text="$t('returnedArticles.description')"
            name="ordersReturnsTreated"
            :url="$listRoutes.ordersReturns"
            :table="ordersReturnsTreated"
            :filters="ordersReturnsTreatedFilters"
            :fields="fields"
    />
</template>

<script>
import { mapState } from 'vuex';
import TemplateTable from '@/components/templates/TemplateTable';
export default {
    name: 'ReturnsTreated',
    components: {TemplateTable},
    data() {
        return {
            fields: [
                {key: 'id', type: 'eye', route: 'orders-return', size: 'xs'},
                {key: 'client', type: 'client', size: 'l'},
                {key: 'user', type: 'userEmail', size: 'xl'},
                {key: 'returnDate', type: 'date'},
                {key: 'shopifyTitle', type: 'productTitle', size: 'l'},
                {key: 'shopifyPriceNoTax', type: 'formatPrice'},
                {key: 'shopifyPriceTax', type: 'formatPrice'},
                {key: 'shopifyPriceWithTax', type: 'formatPrice'},
                {key: 'amountCredit', type: 'formatPrice', size: 'm'},
                {key: 'shopifyId', type: 'shopifyProduct', size: 'm'},
                {key: 'shopifyOrderId', type: 'shopifyOrder', size: 'm'},
                {key: 'status', type: 'tagType'},
                //{key: 'source'},
                {key: 'saleCountry'},
                {key: 'tradeinCountry', size: 'm'}
            ]
        }
    },
    computed: {
        ...mapState(['ordersReturnsTreated', 'ordersReturnsTreatedFilters'])
    }
}
</script>