<template>
    <UserCard v-if="admin"
              :name="name"
              :email="admin.email"
              :info="admin.organization"
              :background="true"
              :image="admin.avatar"
              :to="{name: 'settings-admin', params: {id: admin.id}}"/>
</template>
<script>
import UserCard from '@/components/ui/user/UserCard';
export default {
    name: 'UserCardAdmin',
    components: {UserCard},
    props: {
        admin: Object
    },
    computed: {
        name() {
            return this.admin ? `${this.admin.firstname} ${this.admin.lastname}` : ''
        }
    }
}
</script>