<template>
    <tr class="table-row">
        <slot/>
    </tr>
</template>

<script>
export default {
    name: 'TableRow'
}
</script>

<style lang="scss">
.table-row {
    text-align: center;
    font-size: 1.2rem;

    &:first-of-type {

        td {
            border-top: solid 4px var(--color-bg-light);

            &:first-of-type {
                border-top-left-radius: var(--border-radius-2);
            }

            &:last-of-type {
                border-top-right-radius: var(--border-radius-2);
            }
        }
    }

    &:last-of-type {
        td {
            border-bottom: solid 4px var(--color-bg-light);

            &:first-of-type {
                border-bottom-left-radius: var(--border-radius-2);
            }

            &:last-of-type {
                border-bottom-right-radius: var(--border-radius-2);
            }
        }
    }

    td {
        padding: 5px 5px;
        height: 45px;
        background-color: #fff;
        border-bottom: solid 1px var(--color-bg-light);
        white-space: nowrap;
        text-overflow: ellipsis;

        &:first-of-type {
            text-align: left;
            padding-left: 10px;
            page-break-inside: avoid;
            white-space: nowrap;
            min-width: 80px;
        }

        &:last-of-type {
            padding-right: 10px;
        }
    }

    &:nth-of-type(even) td {
        background-color: var(--color-bg-very-light);
    }
}
</style>