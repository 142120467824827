import API from '@/api/api-admin';
import listRoutes from '@/api/list-routes';
import router from '@/router';

const state = {
    retailArticlesSelected: [],
    retailTransfers: null,
    retailTransfersFilters: null,
    retailTransfersLoading: true,
    retailTransfer: null,
    retailTransferLoading: false,
    allArticles: [],
}

const mutations = {
    SET_RETAIL_ARTICLES_SELECTED(state, listId = []) {
        state.retailArticlesSelected = [...listId];
    },
    SET_RETAIL_TRANSFER(state, value) {
        state.retailTransfer = value;
        state.retailTransferLoading = false;
    },
    SET_RETAIL_TRANSFERS(state, value) {
        state.retailTransfers = value
    },
    SET_RETAIL_TRANSFER_LOADING(state, value) {
        state.retailTransferLoading = value;
    },
    SET_RETAIL_TRANSFER_TIMELINE(state, value) {
        state.storeTransferTimeline = value
    },
    SET_ALL_ARTICLES(state, value) {
        state.allArticles = value
    }
};

const actions = {
    setRetailArticlesSelected({commit}, articlesId) {
        commit('SET_RETAIL_ARTICLES_SELECTED', articlesId)
    },

    async setAllArticlesAsSelected({commit}) {
        /*
        * Fetch all articles by crawling all pages recursively.
        * This is a temporary solution to quickly implement the solution without requiring a backend change.
        * It is quite slow and could be improved by:
        *   - Using a backend route that returns all articles at once (currently limited to 100 per page).
        *   - Making the call in parallel: first call to get the number of pages, then make all the calls in parallel based on the number of pages.
        */
        const fetchArticles = async (page = 1, articles = []) => {
            try {
                const res = await API.get(listRoutes.retailAvailableProducts, { params: { page, limit: 100 } });
                articles.push(...res.data.items);
                if (res.data.pagination.next) {
                    return await fetchArticles(res.data.pagination.next, articles);
                }
                return articles;
            } catch (err) {
                console.log("error in setAllArticlesAsSelected api call", err);
            }
        }

        const allArticles = await fetchArticles();
        commit('SET_RETAIL_ARTICLES_SELECTED', allArticles);
    },

    returnRetailArticles({commit}, articlesId) {
        return API.post(listRoutes.retailReturnProducts, { articles: [...articlesId.map(article => article.id )]})
            .then(res => {
                if (res?.data?.length) {
                    commit('SET_RETAIL_ARTICLES_SELECTED', [])
                    return res.data;
                }
            })
            .catch(err => {
                console.log("error in returnRetailArticles api call", err)
                return false
            });
    },

    getRetailTransfer({commit, state}, id) {
        if (!state.retailTransfer || this.state.retailTransfer.id !== id) {
            commit('SET_RETAIL_TRANSFER_LOADING', true);
            API.get(listRoutes.retailTransfer.replace(':transferId', router.currentRoute.params.transferId))
                .then(res => {
                    console.log(res.data);
                    return res.data;
                })
                .then(data => commit('SET_RETAIL_TRANSFER', data))
                .catch(() => commit('SET_RETAIL_TRANSFER', null))
        }
    },

    getRetailTransferTimeline({commit}) {
        API.get(listRoutes.transfersTimeline)
            .then(res => res.data)
            .then(data => commit('SET_RETAIL_TRANSFER_TIMELINE', data))
            .catch(err => {
                console.log('Impossible de récupérer la timeline', err);
                commit('SET_RETAIL_TRANSFER_TIMELINE', [
                    "created",
                    "validated",
                    "to_prepare",
                    "prepared",
                    "to_ship",
                    "shipped",
                    "received",
                    "to_control",
                    "controled",
                    "to_return",
                    "returning",
                    "returned"
                ])
            })
    }
}
            
const getters = {};

const retails = {state, mutations, actions, getters};

export default retails