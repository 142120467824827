<template>
    <div class="collection-identification">
        
        <CollectionsIdentificationHeader
            :model="catalogueCollection?.identification"
            @open-modal="openModal" 
        />
        <CollectionsIdentificationField 
            :model="catalogueCollection?.identification"
            :localeToSend="currentLocale"
            @change="catalogueCollectionUpdateStateIdentification"
        />
        <Popin ref="popinHandleTranslation" 
            :title="$t('market.edit_default.title')" 
            class="market-edit-default" 
            @close="closeModal"
            >
            <template v-slot:content>
                <CollectionsIdentificationField v-for="locale in otherLocales" :key="locale"
                    :model="catalogueCollection?.identification"
                    :localeToSend="locale"
                    @change="catalogueCollectionUpdateStateIdentification"
                />
                <Button color="blue" size="m" @click.native.prevent="$refs.popinHandleTranslation.close">{{ $t('global.validate')}}</Button>
            </template>
        </Popin>
    </div>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import CollectionsIdentificationHeader from '@/components/ui/collections/CollectionsIdentificationHeader.vue';
import CollectionsIdentificationField from '@/components/ui/collections/CollectionsIdentificationField.vue';
import Button from '@/components/ui/button/Button.vue';
import Popin from '@/components/ui/popin/Popin.vue';
export default {
    name: 'CollectionsIdentification',
    components: {
        CollectionsIdentificationHeader,
        CollectionsIdentificationField,
        Popin,
        Button
    },
    watch: {
        catalogueLocales(newValue){
            if(newValue?.length){
                 this.$emit('emitOtherLocales', newValue)
            }
        }
    },
    data() {
        return {
            payload:{},
        }
    },
    computed: {
        ...mapState([
            'catalogueLocales',
            'catalogueCollection'
        ]),
        currentLocale(){
            return this.$route.params.lang
        },
        currentLocaleExists(){
            return !!this.currentLocale
        },
        otherLocales(){
            return this.catalogueLocales?.filter(elem => elem !== this.currentLocale) || []
        },

    },
    methods: {
        ...mapActions(['catalogueCollectionUpdateStateIdentification']),
        openModal(){
            this.$refs.popinHandleTranslation.open()
            this.$emit('modalOpened', true)
        },
        closeModal(){
            this.$emit('modalOpened', false)
        }
    },


}
</script>

<style lang="scss" scoped>
.grid-card {
    padding: 10px 20px;
    background: #fff;
    margin-bottom: var(--field-padding);
    border-radius: var(--border-radius-2);
    border: var(--border-1);
    @media screen and (max-width: 550px) {
        padding: 10px;
    }
}
</style>
<style lang="scss">
.edit-translations {
    .grid__aside {
        z-index: -1;
    }
}
</style>