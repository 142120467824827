<template>
    <div class="collections-data-sources">
        <TextTitle size="s" weight="bold">{{$t('collections.parameters')}}</TextTitle>
        <CollectionsDataSourcesSelector @change="catalogueCollectionUpdateStateSource" />
        <CollectionsDataSourcesConditions />
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import TextTitle from '@/components/ui/text/TextTitle.vue';
import CollectionsDataSourcesSelector from '@/components/ui/collections/CollectionsDataSourcesSelector.vue';
import CollectionsDataSourcesConditions from '@/components/ui/collections/CollectionsDataSourcesConditions.vue';

export default {
    name: 'CollectionsDataSources',
    components: {
        TextTitle,
        CollectionsDataSourcesSelector,
        CollectionsDataSourcesConditions
    },
    methods: {
        ...mapActions(['catalogueCollectionUpdateStateSource'])
    },
}
</script>

<style lang="scss" scoped>

</style>