import CatalogueAPI from '@/api/api-catalogue';
import PublicAPI from '@/api/api-public';
import listRoutes from '@/api/list-routes';
import moment from 'moment';
import i18n from '@/i18n';
import Vue from 'vue';

const state = {
    
    catalogues: null,
    cataloguesLoading: false,

    catalogueMapping: {},
    catalogueMappingLoading: null,

    catalogueFields: {},
    catalogueSchema: {},
    catalogueSchemaLoading: null,
    
    catalogueProgress: {
        totalLines:0,
        totalLinesProcessed:0
    },
    
    catalogueProgressStatus: null,

    catalogueValidation:null,
    catalogueValidationLoading:null,

    catalogueLocales:null,
    catalogueLocalesLoading:null,

    catalogueProductsByLang:null,

    // catalogueFieldListValue: {},

    catalogueFieldsListValues: {},
    catalogueFieldsListValuesLoading: null,
    
    catalogueDefaultLocale:null,
    catalogueDefaultLocaleLoading:null,

    catalogueFilters:[{}]


}

const mutations = {

    SET_CATALOGUES(state, data){
        state.catalogues = data
    },
    SET_CATALOGUES_LOADING(state, data){
        state.cataloguesLoading = data
    },

    SET_CATALOGUE_MAPPING(state, data){
        state.catalogueMapping = data
    },
    SET_CATALOGUE_MAPPING_LOADING(state, data){
        state.catalogueMappingLoading = data
    },

    SET_CATALOGUE_FIELDS(state, data){
        Vue.set(state, 'catalogueFields', data)
    },

    SET_CATALOGUE_FIELD_LIST_VALUE(state, payload){
        state.catalogueFieldListValue = {...state.catalogueFieldListValue, ...payload}
    },

    SET_CATALOGUE_SCHEMA(state, data){
        state.catalogueSchema = data
    },
    SET_CATALOGUE_SCHEMA_LOADING(state, data){
        state.catalogueSchemaLoading = data
    },


    CREATE_CATALOGUE(state, data){        
        if(data !== null ) {
            state.catalogues = state.catalogues || {}
            state.catalogues[data.lang] = state.catalogues[data.lang] || []
            state.catalogues[data.lang] = [...state.catalogues[data.lang], data]
        }
    },
    CREATE_CATALOGUE_LOADING(state, data){
        state.catalogueLoading = data
    },

    EDIT_CATALOGUE(state, data){

        if(data !== null ) {

        const {payload, id} = data
        
            const itemIndex = state.catalogues[payload.lang].findIndex(item => item.id === id);
        
            if (itemIndex !== -1) {
                state.catalogues[payload.lang][itemIndex].title = payload.title;
                state.catalogues[payload.lang][itemIndex].lang = payload.lang;
            }
        }
        
    },

    EDIT_CATALOGUE_LOADING(state, data){
        state.catalogueLoading = data
    },

    DELETE_CATALOGUE(state, payload){
        const {lang, id} = payload

        const index = state.catalogues[lang].findIndex(item => item.id === id);
        if (index !== -1) {
            state.catalogues[lang].splice(index, 1);
        }
        
    },

    SET_CATALOGUE_PROGRESS_BAR(state, data) {

        const {totalLines, totalLinesProcessed} = data
        
        state.catalogueProgress.totalLines = totalLines
        state.catalogueProgress.totalLinesProcessed = totalLinesProcessed
        
    },
    SET_CATALOGUE_PROGRESS_STATUS(state,data) {
        state.catalogueProgressStatus = data
    },

    SET_CATALOGUE_VALIDATION(state, data){
        state.catalogueValidation = data
    },
    SET_CATALOGUE_VALIDATION_LOADING(state, data){
        state.catalogueValidationLoading = data
    },

    SET_CATALOGUE_LOCALES(state, data){
        if(data){
            state.catalogueLocales = data.locales
        }
    },
    SET_CATALOGUE_LOCALES_LOADING(state, data){
        state.catalogueLocalesLoading = data
    },

    SET_CATALOGUE_DEFAULT_LOCALE(state, data){
        if(data !== null) {
            state.catalogueDefaultLocale = data
        }
    },
    SET_CATALOGUE_DEFAULT_LOCALE_LOADING(state, data){
        state.catalogueDefaultLocaleLoading = data
    },

    SET_CATALOGUE_PRODUCTS_BY_LANG(state, data){
        state.catalogueProductsByLang = data
    },
    // Utilisé pour les filtres
    SET_CATALOGUE_FIELDS_LIST_VALUES(state, payload){
        if(payload !== null) {
            const {lang, ...rest} = payload;
    
            // Assurez-vous que la langue spécifiée existe déjà dans l'état ou initialisez-la avec un objet vide
            if (!state.catalogueFieldsListValues[lang]) {
                Vue.set(state.catalogueFieldsListValues, lang, {});
            }
    
            // Parcours chaque clé dans `rest` pour ajouter ou mettre à jour les valeurs
            Object.keys(rest).forEach(key => {
                // Si la clé existe déjà pour la langue, on fusionne les tableaux pour éviter les doublons
                // Sinon, on initialise la clé avec le tableau de valeurs
                if (state.catalogueFieldsListValues[lang][key]) {
                    // Fusionne sans doublons
                    const existingValues = new Set([...state.catalogueFieldsListValues[lang][key], ...rest[key]]);
                    state.catalogueFieldsListValues[lang][key] = Array.from(existingValues);
                } else {
                    // Initialise la clé avec le tableau de valeurs
                    Vue.set(state.catalogueFieldsListValues[lang], key, rest[key]);
                }
            });
        }
    },
    
    SET_CATALOGUE_FIELDS_LIST_VALUES_LOADING(state, data){
        state.catalogueFieldsListValuesLoading = data
    },

    RESET_CATALOGUE_FIELDS_LIST_VALUES(state){
        state.catalogueFieldsListValues = {}
    },


    
    SET_CATALOGUE_FILTER(state, payload){
        const { index, ...datas } = payload
        if(datas.name.includes('photo_') && datas.operator?.length) datas.value = ' '
        Vue.set(state.catalogueFilters, index, datas)
    },
    ADD_CATALOGUE_FILTERS_ITEM(state){
        state.catalogueFilters.push({})
    },
    RESET_CATALOGUE_FILTERS(state){
        Vue.set(state, 'catalogueFilters', [{}])
    },
    DELETE_CATALOGUE_FILTERS(state, index){
        Vue.delete(state.catalogueFilters, index)
    },

    
}

const actions = {
    fetchCatalogues({commit}, id) {
        commit('SET_CATALOGUES_LOADING', true);
        commit('SET_CATALOGUES', null)
        return new Promise((resolve, reject) => {
            CatalogueAPI.get(`${listRoutes.cataloguesGroupByLang.replace(':id',id)}`)
                .then(res => {
                    return res.data
                })
                .then(data => {
                    commit('SET_CATALOGUES', data)
                    resolve(data)
                })
                .catch(err => {
                    commit('SET_NOTIFICATION_MESSAGE', {text:i18n.t(err.response.data), color:'red', confirmation: true})
                    reject(err)
                })
                .finally(() => {
                    commit('SET_CATALOGUES_LOADING', false)
                })
        })
    },
    
    prefetchCatalogue({commit}, id){
        commit('SET_CATALOGUE_MAPPING_LOADING', true);
        commit('SET_CATALOGUE_MAPPING', {headers:null, products:null})

        return new Promise((resolve) => {
            CatalogueAPI.post(
                `${listRoutes.cataloguePrefetch.replace(':id', id)}`,
                {
                    'catalogId': id,
                    'numberLines': 20
                }
            )
            .then(res => {
                return res.data
            })
            .then(data => {
                commit('SET_CATALOGUE_MAPPING', data)
                resolve()
            })
            .catch(err => {
                commit('SET_NOTIFICATION_MESSAGE', {text:i18n.t(err.response.data.message), color:'red', confirmation: true})
                // reject(err)
            })
            .finally(() => commit('SET_CATALOGUE_MAPPING_LOADING', false))
        })


    },

    createCatalogue({commit},payload){
        commit('SET_CATALOGUE_PROGRESS_BAR', {totalLines:0, totalLinesProcessed:0})
        
        return new Promise((resolve, reject) => {
            commit('CREATE_CATALOGUE_LOADING', true);
            commit('CREATE_CATALOGUE', null)


            CatalogueAPI.post(`${listRoutes.catalogueCreate}`, payload,
                {
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data',
                  },
                  onUploadProgress: uploadEvent =>  {
                    commit('SET_CATALOGUE_PROGRESS_BAR', {totalLines: parseFloat((uploadEvent.total / 1000000).toFixed(2)) , totalLinesProcessed: parseFloat((uploadEvent.loaded / 1000000).toFixed(2)) })
                  }
                })
                .then(res => {
                    return res.data
                })
                .then(data => {
                    commit('CREATE_CATALOGUE', data)
                    resolve(data)
                })
                .catch(err => {
                    commit('SET_NOTIFICATION_MESSAGE', {text:i18n.t(err.response.data), color:'red', confirmation: true})
                    reject(err)
                })
                .finally(() => commit('CREATE_CATALOGUE_LOADING', false))
        })
    },
    
    catalogueEdit({commit},payload){
        commit('EDIT_CATALOGUE_LOADING', true);
        commit('EDIT_CATALOGUE', null);

        const id = payload.id

        delete payload.id
        delete payload.file
        
        return new Promise((resolve, reject) => {
            CatalogueAPI.patch(
                `${listRoutes.catalogueEdit.replace(':id',id)}`, payload)
                .then(res => {
                    commit('EDIT_CATALOGUE', {payload:payload, id:id})
                    resolve(res.status)
                })
                .catch(err => reject(err))
                .finally(() => commit('EDIT_CATALOGUE_LOADING', false))
        })

    },

    catalogueUpdate({commit},payload){
        
        return new Promise((resolve, reject) => {
            commit('CREATE_CATALOGUE_LOADING', true);
            commit('CREATE_CATALOGUE', null);
            
            const { id } = payload
            
            delete payload.id
            delete payload.cohorts
            delete payload.pricing
            delete payload.status
            delete payload.default
            
            CatalogueAPI.post(`${listRoutes.catalogueUpdate.replace(':catalogueID', id)}`, payload,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: uploadEvent =>  {
                        commit('SET_CATALOGUE_PROGRESS_BAR', {totalLines: parseFloat((uploadEvent.total / 1000000).toFixed(2)) , totalLinesProcessed: parseFloat((uploadEvent.loaded / 1000000).toFixed(2)) })
                      }
                })
                .then(res => {
                    return  res.data
                })
                .then(data => {
                    resolve(data)
                })
                .catch(err => {
                    reject(err)
                })
                .finally(() => commit('CREATE_CATALOGUE_LOADING', false))
        })

    },

    catalogueGetAllFields({commit}, id){
        commit('SET_CATALOGUE_FIELDS', null)
        
        return CatalogueAPI.get(`${listRoutes.catalogueGetAllFields.replace(':id', id)}`)
                .then(res => {
                    commit('SET_CATALOGUE_FIELDS',res.data)
                    return true
                })
                .catch(err => {
                    commit('SET_NOTIFICATION_MESSAGE', {text:i18n.t(err.message), color:'red', confirmation: true})
                })
    },

    catalogueGetSchema({commit}, payload){
        const {lang,id} = payload

        commit('SET_CATALOGUE_SCHEMA_LOADING', true)
        commit('SET_CATALOGUE_SCHEMA', null)

        return new Promise((resolve,reject) => {
            CatalogueAPI.get(`${listRoutes.catalogueGetSchemaForMapping.replace(':lang',lang).replace(':id', id)}`)
                .then(res => {
                    const result = res?.data?.schema?.reduce((acc, elem) => {
                        acc[elem['name']] = elem['label'];
                        return acc;
                    }, {});
                    commit('SET_CATALOGUE_SCHEMA', result)
                    resolve(res)
                })
                .catch(err => reject(err))
                .finally(()=> {
                    commit('SET_CATALOGUE_SCHEMA_LOADING', false)
                })
        })
    },
    cataloguePostField({commit, dispatch}, payload){
        const {clientID,...rest} = payload
        return new Promise((resolve, reject) => {
            CatalogueAPI.post(`${listRoutes.cataloguePostField.replace(':id', clientID)}`, rest)
                .then(res => {
                    resolve(res.data)
                    dispatch('catalogueGetAllFields',clientID)
                })
                .catch(err => {
                    reject(err)
                    commit('SET_NOTIFICATION_MESSAGE', {text:i18n.t(err.response.data), color:'red', confirmation: true})
                })
        })
    },
    deleteField({dispatch},payload){
        const {clientID, fieldID} = payload
        CatalogueAPI.delete(`${listRoutes.catalogueDeleteField.replace(':id', clientID).replace(':fieldID',fieldID)}`)
            .then(()=> dispatch('catalogueGetAllFields',clientID))

    },

    catalogueMappedfieldsSubmission(_, payload){

        const {catalogId, ...rest} = payload
    
        return new Promise((resolve, reject) => {
            CatalogueAPI.post(`${listRoutes.catalogueConfiguration.replace(':catalogID', catalogId)}`, rest)
                .then(res => {
                    resolve(res)
                })
                .catch(err => reject(err))
        })
    },

    
    catalogueValidationOrGeneration({dispatch,commit},payload){
        
        const {id, type, clientID,fetchAfter} = payload


        const datas = {
            'validation':{
                'route':`${listRoutes.catalogueValidation.replace(':id', id)}`,
                'values':['totalLines','totalLinesProcessed']
            },
            'generation':{
                'route':`${listRoutes.catalogueGeneration.replace(':id', id)}`,
                'values':['totalProduct','totalProductProcessed']
            }
        }

        return new Promise((resolve, reject) => {
            CatalogueAPI.get(datas[type]['route'])
                .then(response => {
                        try {
                            dispatch('getCatalogueProgression', {
                                id:id, 
                                type:type,
                                total_key: datas[type]['values'][0],
                                totalProcessed_key: datas[type]['values'][1],
                                clientID: clientID,
                                fetchAfter: fetchAfter
                            });
                            resolve(response)
                        }catch(err) {
                            commit('SET_NOTIFICATION_MESSAGE', {text:i18n.t(err.response.data), color:'red', confirmation: true})
                            reject(err)
                        }
                })
                .catch(err => {
                    commit('SET_NOTIFICATION_MESSAGE', {text:i18n.t(err.response.data), color:'red', confirmation: true})
                    reject('catalogueValidation error',err)
                })
        })

    },

    getCatalogueProgression({ commit,dispatch }, payload){
        let {id, type, total_key, totalProcessed_key,clientID,fetchAfter} = payload

        commit('SET_CATALOGUE_PROGRESS_BAR', {totalLines:0, totalLinesProcessed:0})

        let i = 0

        const interval = setInterval(() => {
            CatalogueAPI.get(`${listRoutes.catalogueByID.replace(':id', id)}`)
              .then(response => {
                i++


                const totalLines = response.data.status[type][total_key]
                const totalLinesProcessed = response.data.status[type][totalProcessed_key]

                commit('SET_CATALOGUE_PROGRESS_BAR', {totalLines:totalLines, totalLinesProcessed:totalLinesProcessed});
                
                let progress = state.catalogueProgress;
                console.log(`${i} progress`,progress, type)
      
                if (progress.totalLines === progress.totalLinesProcessed) {
                    clearInterval(interval);

                    commit('SET_CATALOGUE_PROGRESS_STATUS',true)

                    setTimeout(()=> {
                        commit('SET_CATALOGUE_PROGRESS_STATUS',false)
                    },2000)

                    if(fetchAfter) {
                        dispatch('fetchCatalogues',clientID)
                    }
                }
                
              })
              .catch(error => {
                console.log(error)
              });
          }, 3000);

    },

    getCatalogueValidation({commit}, id){
        commit('SET_CATALOGUE_VALIDATION', null)
        commit('SET_CATALOGUE_VALIDATION_LOADING', true)
        

        return new Promise((resolve, reject)=> {
            CatalogueAPI.get(`${listRoutes.catalogueInvalidLines.replace(':id', id)}`)
                .then(res => {
                    commit('SET_CATALOGUE_VALIDATION', res.data)
                    resolve()
                })
                .catch(err => reject(err))
                .finally(() => commit('SET_CATALOGUE_VALIDATION_LOADING', false))
        })
    },

    catalogueLaunch({dispatch},payload){
        const {id, catalogueID,fetchAfter} = payload

        return new Promise((resolve,reject)=> {
            CatalogueAPI.post(`${listRoutes.catalogueLaunch.replace(':id',id).replace(':catalogueID', catalogueID)}`)
                .then(res => {
                    resolve(res.data)
                    if(fetchAfter){
                        dispatch('fetchCatalogues',id)
                    }
                })
                .catch(err => reject(err))
        })
    },

    getCatalogueLocales({commit}, id) {

        if (!state.catalogueLocales) {
            commit('SET_CATALOGUE_LOCALES_LOADING', true);
            commit('SET_CATALOGUE_LOCALES', null);
            
            PublicAPI.get(listRoutes.catalogueLocales.replace(':id', id))
            .then(response => {
                const data = response.data;
                commit('SET_CATALOGUE_LOCALES', data);
                // return data;
            })
            .catch(error => {
                console.error('Erreur lors de l\'appel à l\'API :', error);
            })
            .finally( () => {commit('SET_CATALOGUE_LOCALES_LOADING', false);})
        }   else{
                commit('SET_CATALOGUE_LOCALES_LOADING', true)
        }
    },

    getCatalogueDefaultLocale({commit}, id) {

        if (!state.catalogueDefaultLocale) {
            commit('SET_CATALOGUE_DEFAULT_LOCALE_LOADING', true);
            commit('SET_CATALOGUE_DEFAULT_LOCALE', null);
            
            CatalogueAPI.get(listRoutes.catalogueDefaultLocale.replace(':id', id))
            .then(response => {
                const data = response.data;
                console.log(data)
                commit('SET_CATALOGUE_DEFAULT_LOCALE', data);
            })
            .catch(error => {
                console.error('Erreur lors de l\'appel à l\'API :', error);
            })
            .finally( () => {commit('SET_CATALOGUE_DEFAULT_LOCALE_LOADING', false);})
        }   else{
                commit('SET_CATALOGUE_DEFAULT_LOCALE_LOADING', true)
        }
    },
    
    searchActiveCatalogue({commit},payload){
        
        commit('SET_CATALOGUE_PRODUCTS_BY_LANG', null);
        
        
        const {lang,id,parameters,condition,pagination} = payload

        const transformValue = (value) => {
            if (['Non', 'No'].includes(value)) {
                return "0";
            } else if (['Yes', 'Oui'].includes(value)) {
                return "1";
            }
            return value;
        };

        const transformedParameters = Object.keys(parameters).reduce((acc, key) => {
            const param = parameters[key];
            acc[key] = { ...param, value: param === 'sellable' || param === 'tradable' ? transformValue(param.value) : param.value };
            return acc;
        }, {});
        
        
        const paramsToSend = {
            condition: condition || 'and',
            pagination: pagination,
            parameters: transformedParameters
        };
                
        CatalogueAPI.post(`${listRoutes.catalogueSearchByLangAndID.replace(':lang',lang).replace(':id',id)}`,paramsToSend)
            .then(response => {
                const datas = response.data.data
                const pagination = response.data.pagination.totalLines
                const items = {
                    items:datas,
                    pagination:pagination
                };
                commit('SET_CATALOGUE_PRODUCTS_BY_LANG', items);
            })
            .catch(err => {
                commit('SET_NOTIFICATION_MESSAGE', {text:i18n.t(err.response.data), color:'red', confirmation: true})
            })
        
    },
    catalogueDownload(_,payload){
        const {lang, id, client } = payload

        return new Promise((resolve,reject) => {
            CatalogueAPI.get(`${listRoutes.catalogueDownload.replace(':lang',lang).replace(':id',id)}`, {
                responseType: 'blob' 
            })
            .then(response => {   
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${client}-${lang}.csv`); 
    
                document.body.appendChild(link);
                link.click();
    
                window.URL.revokeObjectURL(url);
                resolve()
            })
            .catch(err => {
                reject(err)
            });
        })
        

    },
    /**
     * PRICING
     */
    brandPricingDownload(_,payload){
        const {client,id } = payload

        return new Promise((resolve,reject) => {
            CatalogueAPI.get(`${listRoutes.brandPricingDownload.replace(':id',id)}`, {
                responseType: 'blob' 
            })
            .then(response => {   
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${client}-pricing.csv`); 
    
                document.body.appendChild(link);
                link.click();
    
                window.URL.revokeObjectURL(url);
                resolve()
            })
            .catch(err => {
                reject(err)
            });
        })
        

    },
    /**
     * COLLECTIONS
     */
    catalogueGetFieldsListValues({ commit }, payload) {
        const { locale, brand_id, field } = payload;
    
        const existingData = state.catalogueFieldsListValues?.[locale]?.[field]?.length;
        if (existingData) {
            return
        } else {
    
            // Continue avec l'appel API si les données n'existent pas dans le store
            commit('SET_CATALOGUE_FIELDS_LIST_VALUES_LOADING', true);
            commit('SET_CATALOGUE_FIELDS_LIST_VALUES', null);
        
            if (field.length && locale.length) {
                return CatalogueAPI.get(`${listRoutes.catalogueFieldListValue.replace(':lang', locale).replace(':id', brand_id).replace(':field', field)}`)
                    .then(res => {
                        commit('SET_CATALOGUE_FIELDS_LIST_VALUES', { lang:locale, [field]: res.data });
                        return res;
                    })
                    .catch(err => {
                        commit('SET_NOTIFICATION_MESSAGE', { text: i18n.t(err.response.data.message), color: 'red', confirmation: true });
                    })
                    .finally(()=> {
                        commit('SET_CATALOGUE_FIELDS_LIST_VALUES_LOADING', false);
                    })
            }
        }
    },

    catalogueResetFieldListValues({commit}){
        commit('RESET_CATALOGUE_FIELDS_LIST_VALUES')
    },

    // FILTERS
    addCatalogueFilterItem({ commit }, payload) {
        // Ajoute un {} au state.catalogueFilters
        commit('ADD_CATALOGUE_FILTERS_ITEM', payload);
    },
    setCatalogueFilter({ commit }, payload) {
        commit('SET_CATALOGUE_FILTER', payload);
    },
    resetCatalogueFilters({ commit }) {
        commit('RESET_CATALOGUE_FILTERS');
    },
    deleteCatalogueFilter({ commit }, index) {
        commit('DELETE_CATALOGUE_FILTERS', index);
    }
    
}

const getters = {
    
    isCataloguesExists: state => {
        return state.catalogues ? Object.keys(state.catalogues).length > 0 : null
    },

    getCataloguesState: state => {
        return state.catalogues ? state.catalogues : []
    },

    getCataloguesSorted: (state, getters)=> lang => {

        const removeActive = getters.getCataloguesState[lang] 
            ? getters.getCataloguesState[lang].filter(elem => !elem.active)
            : null

        return removeActive 
            ? removeActive.sort((a, b) => moment(b.update_at, 'DD/MM/YY HH:mm').diff(moment(a.update_at, 'DD/MM/YY HH:mm'))) 
            : null
    },

    getCurrentCatalogue: state => lang => id =>{
        if (state.catalogues !== null && state.catalogues[lang]) {
            return state.catalogues[lang].filter(elem => elem.id === id)[0];
          } else {
            return null;
        }
    },
    
    getCatalogueMappingState: state =>  {
        return state.catalogueMapping 
    },
    
    getCatalogueProgressStateValues: state => {
        const values = state.catalogueProgress.totalLines > 0 ? Math.round(Math.ceil(state.catalogueProgress.totalLinesProcessed * 100) / state.catalogueProgress.totalLines) : 0
        return values+'%'
    },

    getCatalogueValidationErrors: state => {
        /**
         * Récupère l'ensemble des valeurs de state.catalogueValidation
         * et le transforme en en nouveaux tableaux par type de données manquantes et label
         * [data_1:{label 1, values}]
         * [data_1:{label 2, values}]
         * [data_2:{label 1, values}]
         * etc…
         */
        const groupedEntries = {};

        if(state.catalogueValidation && state.catalogueValidation.products) {
            state.catalogueValidation.products.forEach(entry => {
                const key = `${entry.errorCode.replaceAll('.','_')}-${entry.label}`;
                if (!groupedEntries[key]) {
                  groupedEntries[key] = [];
                }
                groupedEntries[key].push(entry);
              })
              
              // Convert the grouped entries object to an array
              return groupedEntries;
        } else {
            return null
        }

    },
    catalogue_isActive: (state, getters) => key => {
        return getters.getCataloguesState[key].filter(catalogue => catalogue.active === true)[0]
    },
    catalogue_isDefault:(state) => {
        return state.catalogues
            ? Object.values(state.catalogues).flatMap(el => el).filter(elem => elem.default)
            : null
    },
    allActiveCatalogues: state => {
        return state.catalogues 
            ? Object.values(state.catalogues).flatMap(el => el).filter(el => el.active)
            : null
    },

    catalogueBasicFieldsForMapping: state => type => {
        return state?.catalogueFields?.basic
            ? state.catalogueFields?.basic.filter(field => field.type === type)
            : []
    },
    catalogueCreatedFieldsForMapping: state => {
        return state?.catalogueFields?.created?.length 
            ? state?.catalogueFields?.created
            : []
    },


}

const catalogue =  {
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default catalogue

