<template>
    <TemplateTable class="resales-bookings"
            :title="$t('global.bookings-list')"
           :text="$t('resalesBookings.description')"
            name="resalesBookings"
            :url="$listRoutes.resalesBookings"
            :table="resalesBookings"
            :filters="resalesBookingsFilters"
            :fields="fields"
    />
</template>

<script>
import { mapState } from 'vuex';
import TemplateTable from '@/components/templates/TemplateTable';
export default {
    name: 'ResalesBookings',
    components: {TemplateTable},
    data() {
        return {
            fields: [
                {key: 'realStore.client', type: 'client', size: 'm'},
                {key: 'realStore.name', translationKey: 'realStore', size: 'xxl'},
                {key: 'schedule', type: 'dateHour', size: 'm'},
                {key: 'nbProducts', size: 's'},
                {key: 'firstname', size: 'm'},
                {key: 'lastname', size: 'm'},
                {key: 'phone', size: 'm'},
                {key: 'email', size: 'xl', type: 'copy'},
                {key: 'message', size: 'xxl'},
            ]
        }
    },
    computed: {
        ...mapState(['resalesBookings', 'resalesBookingsFilters'])
    }
}
</script>

<style lang="scss">
.resales-bookings {
    .table-sticky-row {
        min-height: 45px;
        height: auto;
    }
}
</style>