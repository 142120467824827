import ConfigWebsiteCatalogueArticleListingAll from '@/components/pages/admin/config/website/catalogues/ConfigWebsiteCatalogueArticleListingAll'
import ConfigWebsiteCatalogueArticleDetails from '@/components/pages/admin/config/website/catalogues/ConfigWebsiteCatalogueArticleDetails'
import ConfigWebsiteCatalogueCollections from '@/components/pages/admin/config/website/catalogues/ConfigWebsiteCatalogueCollections'

const articlesRoutes = [
    {
        path: '/admin/liste-des-articles',
        name: 'config-website-catalogues-articles-listing',
        component: ConfigWebsiteCatalogueArticleListingAll,
        meta: {
            icon: 'tshirt',
            title: 'articles',
            roles: ['ROLE_ADMIN','ROLE_BRAND_ADMIN'],
            cms: ['FAUME']
        }
    },
    {
        path: '/admin/liste-des-articles/tous-les-articles',
        name: 'config-website-catalogues-articles-listing-all',
        component: ConfigWebsiteCatalogueArticleListingAll,
        meta: {
            title: 'all_articles',
            roles: ['ROLE_ADMIN','ROLE_BRAND_ADMIN'],
            cms: ['FAUME']
        }
    },
    {
        path: '/admin/liste-des-articles/single/article/:id',
        name: 'config-website-catalogues-articles-details',
        component: ConfigWebsiteCatalogueArticleDetails,
        meta: {
            title: 'sold_articles',
            roles: ['ROLE_ADMIN','ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE', 'ROLE_BRAND_SAV'],
            cms: ['FAUME']
        }
    },
    {
        path: '/admin/:brandID/collections',
        name: 'config-website-catalogues-collections-listing',
        component: ConfigWebsiteCatalogueCollections,
        meta: {
            title: 'collections',
            roles: ['ROLE_ADMIN','ROLE_BRAND_ADMIN'],
            cms: ['FAUME']
        }
    },


]

export default articlesRoutes;