<template>
    <Tag class="tag-check" v-if="display" :icon-left="icon" :color="color"/>
</template>
<script>
import Tag from '@/components/ui/tag/Tag';
export default {
    name: "TagCheck",
    components: {Tag},
    props: {
        value: Boolean
    },
    computed: {
        display() {
            return this.value == false || this.value == true
        },
        color() {
            return this.value ? 'green' : 'red'
        },
        icon() {
            return this.value ? 'check' : 'cross'
        }
    }
}
</script>

<style lang="scss">
.tag-check {
    padding: 0;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    margin: auto;

    .icon {
        display: flex;
        align-items: center;
    }
}
</style>