<template>
    <section class="store-form">
        <PageBack/>
        <Form
                :title="title"
                :fields="fields"
                :model="model"
                :action="action"
                @success="$emit('success')"
        />
    </section>
</template>

<script>
import Form from '@/components/ui/form/Form';
import PageBack from '@/components/ui/page/PageBack';
export default {
    components: {PageBack, Form},
    props: {
        model: Object,
        title: String,
        action: Function,
    },
    data() {
        return {
            fields: [
                {name: "name", type: "text", required: true},
                {name: "phone", type: "tel", required: true},
                {name: "email", type: "email", required: true},
                {name: "street", type: "text", required: true},
                {name: "zip", type: "number", required: true},
                {name: "city", type: "text", required: true},
                {name: 'countryCode', type: 'select', required: true, label: 'country',
                    options: [
                        {name: this.$t('countries_code.FR'), value: 'FR'},
                        {name: this.$t('countries_code.BE'), value: 'BE'},
                        {name: this.$t('countries_code.DE'), value: 'DE'},
                        {name: this.$t('countries_code.IT'), value: 'IT'},
                        {name: this.$t('countries_code.NL'), value: 'NL'},
                        {name: this.$t('countries_code.ES'), value: 'ES'},
                        {name: this.$t('countries_code.CH'), value: 'CH'},
                        {name: this.$t('countries_code.LU'), value: 'LU'},
                    ]
                },
                {name: "lat", type: "text", required: true},
                {name: "long", type: "text", required: true},
                {name: "schedule", type: "schedule", required: true, label: 'storeOpening'},
                {name: "bookingSchedule", type: "schedule", required: true},
                {name: "isTest", type: "checkbox"},
                {name: "isActive", type: "checkbox"},
                {name: "salesPoint", type: "checkbox"},
                {name: "resalesPoint", type: "checkbox"},
            ]
        }
    }
}
</script>

<style lang="scss">
.store-form .form__container {

    @media screen and (min-width: 1024px) {
        max-width: 1200px;
        display: grid;
        column-gap: 20px;
        grid-template-columns: 1fr 1fr;

        & > label:first-of-type {
            grid-column-start: 1;
            grid-column: 1 / 3;
        }

        & > .button,
        & > .field-checkbox {
            grid-column-start: 1;
        }
    }
}
</style>