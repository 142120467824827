import listRoutes from '@/api/list-routes';
import API from '@/api/api-admin';

const state = {
    wallet: null,
    walletLoading: null
}

const mutations = {
    SET_WALLET(state, data) {
        state.wallet = data;
    },
    SET_WALLET_LOADING(state, value) {
        state.walletLoading = value;
    },
}

const actions = {
    getWallet({commit, state}, id) {
        if (!state.wallet || state.wallet.id != id) {
            commit('SET_WALLET_LOADING', true);
            commit('SET_WALLET', null);
            API.get(listRoutes.wallet.replace(':id', id))
                .then(res => res.data)
                .then(data => commit('SET_WALLET', data))
                .finally(() => commit('SET_WALLET_LOADING', false))
        } else {
            commit('SET_WALLET_LOADING', false);
        }
    },
    exportWallet({commit, state}) {
        commit('SET_EXPORT_LOADING', true);
        API.get(listRoutes.walletExport.replace(':id', state.wallet.id))
            .then(res => res.data)
            .then(data => {
                const href = URL.createObjectURL(data);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute(`wallet-${state.wallet.id}`, 'export.csv');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
            .finally(() => commit('SET_EXPORT_LOADING', false))
    },
    addToWallet({commit, state}, data) {
        return new Promise((resolve, reject) => {
            data.amount = Number(data.amount) * 100;
            API.post(listRoutes.walletAdd.replace(':id', state.wallet.id), {...data})
                .then(res => res.data)
                .then(wallet => {
                    if (wallet) {
                        resolve(`Opération réussie : ${wallet.amount/100}€`);
                        API.get(listRoutes.wallet.replace(':id', state.wallet.id))
                            .then(res => commit('SET_WALLET', res.data))
                    } else {
                        reject("L'api ne fonctionne pas:", listRoutes.walletAdd)
                    }
                })
                .catch(() => reject("L'api ne fonctionne pas:", listRoutes.walletAdd))
        })
    }
}

const getters = {
}

const wallet = {state, mutations, actions, getters};

export default wallet;