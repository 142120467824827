<template>
    <div class="field-select" @mouseleave="open = false" :class="{'no-choice': noChoice, 'has-border': border, 'is-disabled': disabled}">
        <span v-if="label" class="field-select__label">{{ label }}</span>
        <div class="field-select__selected" :class="{ open: open }" @click="open = noChoice ? false : !open" :data-testid="`filter-type-select-${placeholder}`">
            <span>{{ placeholder }}</span>
            <SvgIcon name="chevron" v-if="!noChoice"/>
        </div>
        <div class="field-select__items" v-show="!noChoice && open">
            <div v-for="(option, i) of optionsSorted" :key="i" :class="{'is-selected': Array.isArray(selected) ? selected.includes(option.id) || selected.includes(`${options.id}`) : option.id == selected, 'is-disabled': option.disabled}" @click.prevent="select(option.id)" :data-testid="`filter-type-option-${option.label}`">
                {{ option.label }}
            </div>
        </div>
    </div>
</template>

<script>
import SvgIcon from '@/components/ui/svg/SvgIcon';
export default {
    components: {SvgIcon},
    props: {
        options: Array,
        selected: [String, Number, Array],
        auto: Boolean,
        multiple: Boolean,
        border: Boolean,
        label: String,
        disabled: Boolean,
        sorted:{
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            open: false,
        }
    },
    computed: {
        noChoice() {
            return !this.options?.length
        },
        placeholder() {
            let selected = this.selected;
            if (this.multiple) {
                if (!selected?.length) return null;
                if (!Array.isArray(selected)) selected = [selected]
                if(this.sorted){
                    return this.options.filter(option => selected.includes(option.id) || selected.includes(`${option.id}`)).map(o => o.label).join(',')
                } else {
                    return selected.join(',')
                }
            }
            return this.options?.find(o => o.id == this.selected)?.label
        },
        optionsSorted() {
            if (!this.options) return [];
            if( !this.sorted ) return this.options;
            return [...this.options]?.sort((a,b) => a.label < b.label ? -1 : a.label > b.label ? 1 : 0)
        }
    },
    methods: {
        select(id) {
            if (this.multiple) {
                if (this.selected?.includes(`${id}`) || this.selected?.includes(id)) {
                    let value = this.selected.filter(o => o != id);
                    this.$emit('input', value.length ? value : null)
                }
                else this.$emit('input', this.selected?.length ? [...this.selected, id] : [id])
            } else {
                this.open = false;
                this.$emit('input', id)
            }
        }
    },
    watch: {
        options() {
            if (!this.selected && this.auto && this.options) this.$emit('input', this.options[0]?.id)
        }
    },
    beforeMount() {
        if (!this.selected && this.auto && this.options) this.$emit('input', this.options[0]?.id)
    }
};
</script>

<style lang="scss">
.field-select {
    position: relative;
    width: 100%;
    text-align: left;
    outline: none;
    margin-top: 20px;
    min-width: 150px;

    &.no-choice {
        pointer-events: none;
    }

    &__label {
        position: absolute;
        left: var(--field-padding);
        top: 0;
        transform: translateY(-50%);
        pointer-events: none;
        transition: color ease-out .2s;
        font-size: 1.1rem;
        color: #999;
        font-weight: 400;
    }

    &:hover .field-select__label {
        color: #000;
    }

    &__selected {
        cursor: pointer;
        user-select: none;
        display: flex;
        align-items: center;
        padding: 0 var(--field-padding);
        justify-content: space-between;
        gap: 3px;
        height: var(--field-height);
        transition: border-color ;
        overflow: auto;
        background-color: #fff;
        border-radius: calc(var(--field-height) / 2);
        transition: box-shadow ease .3s;

        .icon {
            flex-shrink: 0;
            margin-right: 0;
            margin-left: auto;
        }

        &.open {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    &:not(.has-border) {
        .field-select__selected.open {
            box-shadow: 0 0 10px rgba(0,0,0,.1);

            & + div {
                box-shadow: 0 10px 10px rgba(0,0,0,.1);
            }
        }
    }

    &.has-border {
        margin-top: 0;

        .field-select__label {
            background-color: #fff;
            padding: 0 5px;
        }

        .field-select__selected {
            background-color: transparent;
            border: var(--border-1);
            border-radius: var(--border-radius-2);

            &:hover {
                border: var(--border-2);
            }
        }

        .field-select__items {
            border-right: var(--border-1);
            border-left: var(--border-1);
            border-bottom: var(--border-1);
            & > div {
                &.is-disabled {
                    opacity: .5;
                    pointer-events: none;
                    background-color: var(--color-bg-light);
                }
            }
        }
    }

    &__items {
        border-radius: var(--border-radius-2);
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        overflow: hidden;
        position: absolute;
        background-color: #fff;
        left: 0;
        right: 0;
        z-index: 1;
        max-height: 250px;
        overflow-y: auto;
        transition: box-shadow ease .3s;

        div {
            cursor: pointer;
            user-select: none;
            padding: 10px var(--field-padding);

            &:hover {
                background-color: var(--color-bg-light);
            }

            &.is-selected {
                position: relative;

                &:before {
                    content: '';
                    display: block;
                    width: 3px;
                    height: 80%;
                    top: 10%;
                    border-radius: 3px;
                    position: absolute;
                    left: 1px;
                    background-color: var(--color-orange);
                }
            }
        }
    }
    &.is-disabled {
        pointer-events: none;

        .field-select__selected {
            color: var(--color-text-grey);

            .icon{
                display: none;
            }
        }
    }
}
</style>
