<template>
    <div>
        <TemplateDetails :data="catalogues" :loading="cataloguesLoading" :get-function="getCatalogues">
            
            <PageBackWithLink :to="{name:'client-catalog'}" :text="$t('catalogue.back_to_catalogues_active')"/>
            <div>
                <PageHeader :title="title">
                    <template v-slot:actions>
                        <ButtonLink :to="{name:'config-website-catalogue-create'}"  icon="right" color="grey" size="m">{{ $t('catalogue.import') }}</ButtonLink>
                    </template>
                </PageHeader>
                <GridContainer>
                    <GridCard v-for="(value, key) in getCataloguesState" :key="key">
                        <GridContent>
                            <GridHeader :title="$t('catalogue.title')+' '+$t(`locales.${key.split('_')[0]}`)"/>
                            <div v-if="catalogue_isActive(key)">
                                <CataloguesCard
                                    :catalogue='catalogue_isActive(key)'
                                    :lang="merchandisingDefaultLang"
                                />
                            </div>
                            <div v-else>
                                {{ $t('catalogue.no_catalogue_active')}}
                            </div>
                            <ButtonLink :to="{name: 'config-website-catalogues-view', params:{lang:key}}" :loading="addLoading" color="blue" size="s">{{$t('catalogue.see_all_catalogues')}}</ButtonLink>
                        </GridContent>
                    </GridCard>
                </GridContainer>
                <div v-if="getCataloguesState.length === 0">
                    {{$t('catalogue.no_catalogue_archived')}}
                </div>
            </div>
        </TemplateDetails>
    </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import PageHeader from '@/components/ui/page/PageHeader.vue';
import ButtonLink from '@/components/ui/button/ButtonLink.vue';
import PageBackWithLink from '@/components/ui/page/PageBackWithLink.vue';
import GridContainer from '@/components/ui/grid/GridContainer.vue';
import GridCard from '@/components/ui/grid/GridCard.vue';
import GridContent from '@/components/ui/grid/GridContent.vue';
import GridHeader from '@/components/ui/grid/GridHeader.vue';

// Catalogue
import TemplateDetails from '@/components/templates/TemplateDetails.vue';
import CataloguesCard from '@/components/ui/catalogue/CataloguesCard.vue';


export default {
    name: 'ConfigWebsiteCatalogueActive',
    components: {
        GridHeader,
        GridContent,
        GridCard,
        GridContainer,
        PageBackWithLink,
        ButtonLink,
        PageHeader,
        TemplateDetails,
        CataloguesCard,
    },
    data() {
        return {
            stores: null,
            addLoading: false,
            deleteLoading:false,
            currentCatalogueID: null,
        }
    },
    computed: {
        ...mapState([
            'catalogues',
            'cataloguesLoading',
            'merchandisingDefaultLang'
        ]),
        ...mapGetters([
            'getCataloguesState',
            'catalogue_isActive'
        ]),
        id() {
            return this.$route.params.id
        },
        client() {
            return this.$store.getters.getClientById(this.id)
        },
        title(){
            return `${this.$t('catalogue.active_list')} ${this.client.name}`
        },
    },
    methods: {
        ...mapActions([
            'fetchCatalogues',
            'getMarketsConfigs',
            'fetchMerchandisingDefaultLang'
        ]),
        async getCatalogues() {
            await this.getMarketsConfigs({id: this.id})
            await this.fetchCatalogues(this.id)
            await this.fetchMerchandisingDefaultLang(this.id)
        },

    },
    
}
</script>

<style lang="scss">
.config-website {
    &__actions {
        display: flex;
        gap: 15px;
        flex-direction: column;

        & > * {
            width: 100%;
        }
    }
}
</style>
<style lang="scss" scoped>
.grid-content {
    .button {
        margin-left: auto;
    }
}
</style>