<template>
    <TemplateDetails :loading="storeTransferLoading" :data="storeTransfer" :get-function="getStoreTransfer">
        <div v-if="transfer">

            <PageBack :to="{name: 'client-store-transfers'}"/>

            <TemplateTable
                    :title="$t('client.transfer.title', {id: $route.params.transferId})"
                    :subtitle="subtitle"
                    :url="$listRoutes.storeTransferArticles
                    .replace(':transferId', $route.params.transferId)
                    .replace(':storeId', this.$route.params.storeId)
                    .replace(':id', this.$route.params.id)"
                    :fields="fieldsArticles"
                    name="storeTransferArticles"
                    :filters="storeTransferArticlesFilters"
                    :table="storeTransferArticles">

                <template v-slot:actions>
                    <ButtonCustom v-if="canBeDeleted" @click.native.prevent="remove" :loading="deleting" size="m" color="red" icon="trash">{{ $t('global.delete') }}</ButtonCustom>
                </template>

                <template v-slot:top>
                    <GridContainer>
                        <GridCard>
                            <GridContent>
                                <SectionTableInfos :object="transfer" :fields="fields"/>
                            </GridContent>
                        </GridCard>
                        <template v-slot:aside>
                            <GridCard>
                                <GridContent>
                                    <GridHeader :title="$t('global.event')"/>
                                    <GridCalendar :dates="timeline" :object="eventsObject"/>
                                </GridContent>
                            </GridCard>
                        </template>
                    </GridContainer>
                </template>

            </TemplateTable>
        </div>
    </TemplateDetails>
</template>

<script>
import TemplateDetails from '@/components/templates/TemplateDetails';
import { mapState, mapActions } from 'vuex';
import PageBack from '@/components/ui/page/PageBack';
import GridContainer from '@/components/ui/grid/GridContainer';
import GridCard from '@/components/ui/grid/GridCard';
import GridContent from '@/components/ui/grid/GridContent';
import SectionTableInfos from '@/components/sections/SectionTableInfos';
import TemplateTable from '@/components/templates/TemplateTable';
import GridHeader from '@/components/ui/grid/GridHeader';
import GridCalendar from '@/components/ui/grid/GridCalendar';
import ButtonCustom from '@/components/ui/button/Button';

export default {
    name: 'StoreTransfer',
    components: {ButtonCustom, GridCalendar, GridHeader, TemplateTable, SectionTableInfos, GridContent, GridCard, GridContainer, PageBack, TemplateDetails},
    data() {
        return {
            deleting: false,
            fields: [
                {key: 'id', type: 'copy'},
                {key: 'store.name', label: 'store'},
                {key: 'countArticles'},
            ],
            fieldsIsInit: false,
            fieldsArticles: [
                {key: 'id', size: 'xs', type: 'eye', route: 'resale'},
                {key: 'shopify_id', label: 'shopifyId', size: 'm', type: 'copy'},
                {key: 'stage', label: 'status', size: 'm', type: 'transferStage'},
                {key: 'title', size: 'xl'},
                {key: 'color', size: 's'},
                {key: 'size', size: 's'},
                {key: 'etat', label: 'condition', size: 's'},
                {key: 'price', size: 's', type: 'money'},
                {key: 'credit_price', size: 's', type: 'money'},
                {key: 'photo1', size: 'xs', type: 'productImg', translation: 'photo'},
                {key: 'url', size: 'm', type: 'productUrl'},
            ]
        }
    },
    computed: {
        ...mapState(['storeTransferLoading', 'storeTransfer', 'storeTransferArticles', 'storeTransferArticlesFilters', 'storeTransferTimeline']),
        transfer() {
            let storeTransfer = {...this.storeTransfer}
            if( storeTransfer.events ) {
                storeTransfer.events = Array
                    .from(new Set(storeTransfer?.events.map(a => a.createdAt.substr(0, 16)+a.event)))
                    .map((id)=>{
                        return storeTransfer.events.find(a => a.createdAt.substr(0, 16)+a.event === id)
                    })
            }
            return storeTransfer;
        },
        client() {
            return this.$store.getters.getClientById(this.$route.params.id)
        },
        subtitle() {
            return this.client?.name + ' - ' + this.transfer?.store?.name
        },
        status() {
            if (!this.transfer?.events) return null;
            return this.transfer?.events[this.transfer?.events?.length - 1].event
        },
        timeline() {
            const timeline = this.transfer?.events?.map((e) => {
                return {
                    key: e.event+e.createdAt, 
                    translation: `client.transfers.stages.${e.event}`
                }
            });
            return timeline
        },
        eventsObject() {
            if (!this.transfer || !this.transfer.events) return {};
            let events = {};
            this.transfer?.events?.forEach((e) => {
                events[e.event+e.createdAt] = e.createdAt
            });
            return events
        },
        canBeDeleted() {
            return this.transfer?.events?.length < 2
        }
    },
    methods: {
        ...mapActions(['getStoreTransfer', 'getStoreTransferTimeline', 'deleteStoreTransfer']),
        remove() {
            this.deleting = true;
            this.deleteStoreTransfer()
                    .then(() => this.$router.push({name: 'client-store-transfers'}))
                    .finally(() => this.deleting = false)
        },
        getExtraFields() {
            const articles = this.storeTransferArticles?.items;
            if (!this.fieldsIsInit && articles?.length) {
                const newFields = Object.keys(articles[0])
                        .filter(x => x.includes('tag_'))
                        .map(x => ({
                            key: x,
                            size: 'm',
                            label: x.replace('tag_', 'Tag '),
                            noTranslate : true
                        }));
                this.fieldsArticles = [...this.fieldsArticles, ...newFields]
            }
        }
    },
    watch: {
        storeTransferArticles() {
            //this.getExtraFields()
        }
    },
    mounted() {
        if (!this.storeTransferTimeline) this.getStoreTransferTimeline();
        //this.getExtraFields()
    }
}
</script>

