<template>
    <div class="collections-listing">
        <scroll-sync v-if="index===0" :group="`${_uid}`" horizontal :proportional="true" class="collections-listing__header" ref="thead">
            <div :class="`collections-listing__size-${header.size}`" v-for="(header) in headers" :key="header.label">
                {{header.label}}
            </div>
        </scroll-sync>
        <div class="collections-listing__row" @click="navigateToCollection">
            <Button class="collections-listing__size-s" :color="collectionStateColor" size="s">{{ collectionStateValue }}</Button>
            <TextSimple class="collections-listing__size-m">{{ collectionTitle }}</TextSimple>
            <TextSimple class="collections-listing__size-m">{{ collectionSourceText }}</TextSimple>
            <div v-if="collectionSource === 'catalog_data'" class="collections-listing__conditions collections-listing__size-m">
                <div v-for="(condition,index) in collectionConditions" :key="index">
                    <TextSimple weight="bold">{{ condition.label }}:</TextSimple>
                    <TextSimple>{{ condition.values.join(', ') }}</TextSimple>
                </div>
            </div>
            <div v-else class="collections-listing__conditions collections-listing__size-m">
                <TextSimple>{{ collectionTags }}</TextSimple>
            </div>
            <div class="collections-listing__conditions collections-listing__size-s">
                <div v-for="market in collectionMarketsToLocale" :key="market">
                    <TextSimple>{{ market }}</TextSimple>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
  import Button from '@/components/ui/button/Button.vue';
  import TextSimple from '@/components/ui/text/TextSimple.vue';
  import router from '@/router';
  
  export default {
      name: 'CollectionsListing',
      components: {
          Button,
          TextSimple
      },
      props: {
          collection: Object,
          index: Number,
          defaultLocale: String,
          brandID: [Number, String]
      },
      computed: {
        headers() {
            return [
                {label:this.$t('collections.state'),size:'s'},
                {label:this.$t('collections.title'),size:'m'},
                {label:this.$t('collections.source'),size:'m'},
                // {label:this.$t('collections.products'),size:'m'},
                {label:this.$t('collections.conditions'),size:'m'},
                {label:this.$t('collections.markets'),size:'s'},
            ]
        },
        collectionState() {
            return this.collection?.state
        },
        collectionStateColor(){
            return this.collectionState
                ? 'green'
                : 'grey'
        },
        collectionStateValue(){
            return this.collectionState
                ? this.$t('market.activated')
                : this.$t('market.deactivated')
        },
        collectionTitle(){
            return Object.values(this.collection?.identification)[0].title
        },
        collectionSource(){
            return this.collection?.parameters?.source
        },
        collectionSourceText(){
            return this.collectionSource?.length && this.$t(`collections.${this.collection.parameters.source}`)
        },
        collectionConditions(){
            return this.collection?.parameters.conditions.map(condition => ({label:condition.label, values: condition.value}))
        },
        collectionTags(){
            return this.collection?.tags?.length && this.collection.tags[0]
        },
        collectionMarkets(){
            return this.collection?.markets
        },
        collectionMarketsToLocale(){
            return this.collectionMarkets.map(market => this.$t(`countries_code.${market}`))
        }
      },
      methods: {
          navigateToCollection() {
              router.push({name: 'config-website-catalogue-collections-edit', params: {id:this.brandID,collectionID: this.collection.id, lang:this.defaultLocale}});
          }
      }
  }
  </script>
  
  <style lang="scss" scoped>
  .collections-listing {
    max-width: 1280px;
    scrollbar-width: none;
    &:last-of-type {
        .collections-listing__row {
            border-bottom-left-radius: var(--border-radius-2);
            border-bottom-right-radius: var(--border-radius-2);
        }
    }
    &:first-of-type {
        .collections-listing__row {
            border-bottom-left-radius: var(--border-radius-2);
            border-bottom-right-radius: var(--border-radius-2);
        }
    }

    &__header {
        border-radius: var(--border-radius-2);
        display: flex;
        align-items: center;
        background: var(--color-bg-grey);
        padding: 10px;
        gap: 20px;
        font-weight: bold;
        height: 45px;
        margin-bottom: 5px;
    }
    &__size {
        &-s {
            width: 140px;
        }
        &-m {
            width: 320px;
        }
        &-l {
            width: 480px;
        }
    }
    &__row {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 10px;
        gap: 20px;
        background: #fff;
        border-bottom: solid 1px var(--color-bg-light);      
    }
    &__conditions {
        display: flex;
        flex-direction: column;
        & > div {
            display: flex;
            gap: 1ch;
            & > p:last-child{
                flex: 2;
            }
        }
    }
    @media screen and (max-width: 550px) {
        &__header {
            display: none;
        }
        &__row,
        &__condition {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        &__size {
            &-s,
            &-m,
            &-l {
                width: 100%;
            }
        }
    }
}
  </style>
  