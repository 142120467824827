<template>
    <component :is="tag" class="link-underline" :class="{'is-border-hover': borderHover, 'is-center': center}" :to="to" :disabled="!to"><slot/></component>
</template>

<script>
export default {
    name: 'LinkUnderline',
    props: {
        to: [Object, String],
        borderHover: Boolean,
        center: Boolean
    },
    computed: {
        tag() {
            return this.to ? 'router-link' : 'a'
        }
    }
}
</script>

<style lang="scss">
.link-underline {
    font-size: 1.2rem;
    display: block;
    width: fit-content;
    position: relative;
    cursor: pointer;

    &.is-center {
        margin-right: auto;
        margin-left: auto;
    }

    &:after {
        content: '';
        position: absolute;
        pointer-events: none;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #000;
        height: 1px;
        transition: opacity ease-out .3s, transform ease-out .3s;
    }

    @media screen and (min-width: 1025px) {
        &:hover::after {
            transform: translateY(3px);
            opacity: 0;
        }
    }

    &.is-border-hover {
        &:after {
            transform: translateY(3px);
            opacity: 0;
        }

        @media screen and (min-width: 1025px) {
            &:hover::after {
                transform: translateY(0);
                opacity: 1;
            }
        }
    }
}
</style>