<template>
    <TemplateConversionFunnel
            :client-is-required="true"
            :route="$listRoutes.dashboardsFunnel"
            :filters-route="'#'"
            :events="['sell_view', 'trade-in_view_conditions', 'trade-in_view_product_identification', 'trade-in_view_cart', 'trade-in_view_user_identification', 'trade-in_view_shipping_address', 'trade-in_completed']"
            :comparaison="true"
            :title="$t('dashboard-funnel-selling.title')"/>
</template>

<script>
import TemplateConversionFunnel from '@/components/templates/TemplateConversionFunnel';

export default {
    name: 'DashboardSellingFunnel',
    components: {TemplateConversionFunnel},
}
</script>