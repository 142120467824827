import Vue from 'vue';
import App from './App.vue'
import store from '@/store';
import router from '@/router';
import Sentry from './init-sentry'
import filters from '@/filters';
import i18n from '@/i18n';
import './registerServiceWorker'
import VCalendar from 'v-calendar';
import listRoutes from '@/api/list-routes';
import API from '@/api/api-admin';
import ScrollSync from 'vue-scroll-sync';
import VueApexCharts from 'vue-apexcharts';
import vClickOutside from 'v-click-outside';

// Vue editor
/*
Vue.use(Vueditor, {
  toolbar: ['removeFormat', 'undo', 'redo', '|', 'element', 'bold', 'italic', 'underline', 'strikeThrough', 'link', 'unLink', '|', 'insertOrderedList', 'insertUnorderedList']}
);
 */

// Click outside directive
Vue.use(vClickOutside);

// Apex chart
Vue.use(VueApexCharts)
Vue.component('ApexChart', VueApexCharts);

// Calendar module
Vue.use(VCalendar);

Vue.use(ScrollSync);
Vue.component('scroll-sync', ScrollSync)

Vue.config.productionTip = false;

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
});

window.i18n = i18n;

Vue.prototype.$listRoutes = listRoutes;
Vue.prototype.$API = API;

new Vue({
  store,
  router,
  Sentry,
  i18n,
  render: h => h(App),
}).$mount('#app')