<template>
    <tbody>
        <slot/>
    </tbody>
</template>

<script>
export default {
    name: 'TableBody'
}
</script>