<template>
    <PageHeader title="Produits" text="Bientôt disponibles"/>
</template>

<script>
import PageHeader from '@/components/ui/page/PageHeader';
export default {
    name: "ProductsPage",
    components: {PageHeader}
}
</script>