<template>
    <PopinCalendar :date="model" :columns="2" @change="e => change(e)" :disabled-today="disabledToday" :display-hours="displayHours">
        <Field class="field-date" type="text" icon="calendar" :required="required" :name="name" :label="label" :model="dateFormat"/>
    </PopinCalendar>
</template>

<script>
import Field from '@/components/ui/form/fields/Field';
import PopinCalendar from '@/components/ui/popin/PopinCalendar';
import moment from 'moment';
export default {
    components: {PopinCalendar, Field},
    props: {
        name: {
            type: String,
            default: 'date'
        },
        required: Boolean,
        label: String,
        model: String,
        disabledToday: Boolean,
        displayHours: Boolean
    },
    data() {
        return {
            dateFormat: null
        }
    },
    watch: {
        model(value, old) {
            if (value !== old) {
                this.getFormatDate(this.model)
            }
        }
    },
    methods: {
        getFormatDate(date) {
            this.dateFormat = date ? moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY') : null
        },
        change(value) {
            this.$emit('change', value);
            this.getFormatDate(value)
        }
    },
    mounted() {
        this.getFormatDate(this.model)
    }
}
</script>

<style lang="scss">
.field-date {
    cursor: pointer;
    width: fit-content;

    input {
        width: 100px;
        pointer-events: none;
    }
}
</style>