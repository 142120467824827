import { render, staticRenderFns } from "./CohortsCardContent.vue?vue&type=template&id=5375f746&scoped=true"
import script from "./CohortsCardContent.vue?vue&type=script&lang=js"
export * from "./CohortsCardContent.vue?vue&type=script&lang=js"
import style0 from "./CohortsCardContent.vue?vue&type=style&index=0&id=5375f746&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5375f746",
  null
  
)

export default component.exports