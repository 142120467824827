<template>
    <component class="layout-menu-link"
               :is="to ? 'router-link' : 'a'"
               :target="href ? '_blank' : null"
               :to="to ? {name: to} : null"
               :href="href"
               :event="disabled ? '' : 'click'">

        <SvgIcon v-if="icon" :name="icon"/>

        <TextSimple>{{ $t(`menu.${title}`) }}</TextSimple>

    </component>
</template>

<script>
import SvgIcon from '@/components/ui/svg/SvgIcon';
import TextSimple from '@/components/ui/text/TextSimple';
export default {
    name: 'LayoutMenuLink',
    components: {TextSimple, SvgIcon},
    props: {
        title: String,
        href: String,
        icon: String,
        to: String,
        disabled: Boolean
    }
}
</script>

<style lang="scss">
.layout-menu-link {
    padding: 7px;
    border-radius: var(--border-radius-1);
    display: flex;
    align-items: center;
    transition: background-color ease-out .3s;
    margin-bottom: 5px;
    user-select: none;

    @media screen and (min-width: 1025px) {
        &:hover {
            background-color: var(--color-bg-light);
        }
    }

    .icon + p {
        margin-left: 10px;
    }

    &.router-link-exact-active {
        background-color: var(--color-bg-light);
    }
}
</style>