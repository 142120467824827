import ConfigWebsiteCohorts from '@/components/pages/admin/config/website/cohorts/ConfigWebsiteCohorts';
import ConfigWebsiteCohortsCreate from '@/components/pages/admin/config/website/cohorts/ConfigWebsiteCohortsCreate';
import ConfigWebsiteCohortsValidation from '@/components/pages/admin/config/website/cohorts/ConfigWebsiteCohortsValidation';
import ConfigWebsiteCohortsUpdate from '@/components/pages/admin/config/website/cohorts/ConfigWebsiteCohortsUpdate';

const cohortsRoutes = [
    {
        path: '/admin/config/sites/:id/cohortes',
        name: 'config-website-cohorts',
        component: ConfigWebsiteCohorts,
        meta: {
            roles: ['ROLE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/config/sites/:id/cohorts/mettre-a-jour',
        name: 'config-website-cohorts-update',
        component: ConfigWebsiteCohortsUpdate,
        meta: {
            roles: ['ROLE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/config/sites/:id/cohorts/:lang/:catalogueID/creer',
        name: 'config-website-cohorts-create',
        component: ConfigWebsiteCohortsCreate,
        meta: {
            roles: ['ROLE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/config/sites/:id/cohorts/:lang/:catalogueID/validation',
        name: 'config-website-cohorts-validation',
        component: ConfigWebsiteCohortsValidation,
        props:true,
        meta: {
            roles: ['ROLE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },

]
export default cohortsRoutes;