<template>
    <tbody>
        <Row v-for="i in size" :key="i">
            <td :style="{animationDelay: `${i/5}s`}" colspan="100%" class="table-body-loading"></td>
        </Row>
    </tbody>
</template>

<script>
import Row from '@/components/ui/table/row/Row';
export default {
    name: 'TableBodyLoading',
    components: {Row},
    props: {
        size: {
            type: Number,
            default: 10
        }
    }
}
</script>

<style lang="scss">
@keyframes animTbodyLoading {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
.table-body-loading {
    background: rgb(170,170,170);
    background: linear-gradient(-45deg, #fff, var(--color-bg-grey), #fff);
    //background: linear-gradient(90deg, #fff 0%, #fff 25%,  var(--color-bg-grey) 50%, #fff 75%, #fff 100%);
    animation: animTbodyLoading infinite ease-in-out 5s;
    background-size: 400% 400%;
    overflow: hidden;
    height: 43.5px;
}
</style>