import listRoutes from '@/api/list-routes';
import PublicAPI from '@/api/api-public';
import router from '@/router';

let clientInStorage = sessionStorage.getItem('clients');
if (clientInStorage && clientInStorage[0] === "[") clientInStorage = JSON.parse(clientInStorage);
else clientInStorage = null

const state = {
    clients: clientInStorage,
    client: null,
    clientsMarkets: {}
}

const mutations = {
    SET_CLIENTS(state, clients) {
        state.clients = clients.sort((a, b) => {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
        });
    },
    SET_CLIENT(state, client) {
        state.client = {...client}
    },
    SET_CLIENT_MARKETS(state, data) {
        state.clientsMarkets[data.client] = data?.market
    }
}

const actions = {
    getClients({commit}) {
        PublicAPI.get(listRoutes.clients)
            .then(res => {
                sessionStorage.setItem('clients', JSON.stringify(res.data))
                commit('SET_CLIENTS', res.data);
            })
    },
    getClient({commit, state}) {
        const clientId = router.currentRoute.params.id
        commit('SET_CLIENT', state?.clients?.find(x => x.id == clientId))
    },
    getClientMarkets({commit, state}) {
        const clientId = router.currentRoute.params.id;
        if (!state.clientsMarkets[clientId]) {
            PublicAPI.get(listRoutes.clientMarkets.replace(':id', clientId))
                .then(res => res.data)
                .then(data => commit('SET_CLIENT_MARKETS', {
                    client: clientId,
                    market: data
                }))
        }
    }
}
const getters = {
    getClientByName: (state) => (name) => {
        return state.clients ? state.clients.find(client => client.name === name) : null
    },
    getClientById: (state) => (id) => {
        return state.clients ? state.clients.find(client => client.id == id) : null
    },
    getClientByNameUrl: (state) => (nameUrl) => {
        return state.clients ? state.clients.find(client => client.nameUrl === nameUrl) : null
    },
    getClientMarketByCode: (state) => (id, countryCode) => {
        return state.clientsMarkets[id]?.find(market => market.country.find(c => c.code === countryCode))
    }
}

const clients = {
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
};

export default clients;