<template>
    <div class="input-checkbox" :class="{'is-checked': model}">
        <Icon v-if="model" name="check"/>
    </div>
</template>

<script>
import Icon from '@/components/ui/svg/SvgIcon';
export default {
    name: 'InputCheckbox',
    components: {
        Icon
    },
    props: {
        model: Boolean
    }
}
</script>

<style lang="scss">
.input-checkbox {
    background-color: #fff;
    border: solid 1.5px #000;
    border-radius: var(--border-radius-1);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    transition: border-color ease .2s;

    &:not(.is-checked):not(:hover) {
        border-color: var(--color-text-grey);
    }

    &.is-checked {
        background-color: #000;
        --svg-color: #fff;
    }
}
</style>